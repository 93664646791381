export const TemplateFieldsReportsObjectiveBusinessarea = [
  {
    type: 'table',
    settings: {
      /* Group the elements by model */
      groupByModels: true,
      /* Use a thousand seperator for numeric values */
      thousandsSeperator: '´',
      /* How many decimals should be shown on numeric values */
      decimalnumbers: 2,
      /* Show top most header row (grouped heading) */
      showHeading: false,
      /* Show table fields */
      showFields: true,
      /* Show legend dropup */
      showLegend: true,
      /* Show the footer */
      showFooter: true,
      /* The header text color */
      headerTextColor: '#ffffff',
      /* The header background color */
      headerBackgroundColor: '#9ac93e',
      /* The border */
      border: false,
      /* Show top most by sidestep */
      topMostBySidestep: true,
      /* A filter function to filter by */
      filter: 'objectives',
      /* Trigger to load the Businessarea with that table */
      loadBusinessarea: true,
      /* Exports */
      exports: ['excel', 'pdf']
    },
    configuration: {
      fields: [
        { entry: { key: '', label: '', controlType: 'group'}, children: [
            { entry: { key: 'number', controlType: 'primary', label: 'REPORTS.OBJECTIVEBUSINESSAREA.NUMBER', calculated: true, editable: false, width: 5 }},
            { entry: { key: 'stategicClassification', controlType: 'textbox', width: 9, label: 'REPORTS.OBJECTIVEBUSINESSAREA.STRATEGICCLASSIFICATION', editable: false, calculated: true }},
            { entry: { key: 'name', type: 'text', controlType: 'textbox', width: 9, label: 'REPORTS.OBJECTIVEBUSINESSAREA.NAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME' }},
            { entry: { key: 'responsibleId', controlType: 'dropdown', width: 9, label: 'NODE.DATASHEET.FIELDS.RESPONSIBLEID', addNull: true, source: 'humanresources' }},
            { entry: { key: 'budget', type: 'number', controlType: 'textbox', width: 9, label: 'REPORTS.OBJECTIVEBUSINESSAREA.KPI' }},
            { entry: { key: 'budgetCalculated', type: 'number', controlType: 'textbox', width: 9, label: 'NODE.DATASHEET.FIELDS.AGGREGATIONKPI' }},
            { entry: { key: 'targetDate', type: 'datetime', controlType: 'date', width: 9, label: 'REPORTS.OBJECTIVEBUSINESSAREA.TARGETDATE' }},
            { entry: { key: 'actualDate', type: 'datetime', controlType: 'date', width: 9, label: 'REPORTS.OBJECTIVEBUSINESSAREA.ACTUALDATE' }},
            { entry: { key: 'executive', controlType: 'dropdown', label: 'Q1', addNull: false, width: 5, options: [
                  { key: 0, value: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE', color: '#999999' },
                  { key: 1, value: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW', color: '#9ac93e', shape: 'circle' },
                  { key: 2, value: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM', color: '#EDD515', shape: 'triangle' },
                  { key: 3, value: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH', color: '#E50000', shape: 'rect' }
                ] }},
            { entry: { key: 'program', controlType: 'dropdown', label: 'Q2', addNull: false, width: 5, options: [
                  { key: 0, value: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE', color: '#999999' },
                  { key: 1, value: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW', color: '#9ac93e', shape: 'circle' },
                  { key: 2, value: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM', color: '#EDD515', shape: 'triangle' },
                  { key: 3, value: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH', color: '#E50000', shape: 'rect' }
                ] }},
            { entry: { key: 'functional', controlType: 'dropdown', label: 'Q3', addNull: false, width: 5, options: [
                  { key: 0, value: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE', color: '#999999' },
                  { key: 1, value: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW', color: '#9ac93e', shape: 'circle' },
                  { key: 2, value: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM', color: '#EDD515', shape: 'triangle' },
                  { key: 3, value: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH', color: '#E50000', shape: 'rect' }
                ] }},
            { entry: { key: 'resource', controlType: 'dropdown', label: 'Q4', addNull: false, width: 5, options: [
                  { key: 0, value: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE', color: '#999999' },
                  { key: 1, value: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW', color: '#9ac93e', shape: 'circle' },
                  { key: 2, value: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM', color: '#EDD515', shape: 'triangle' },
                  { key: 3, value: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH', color: '#E50000', shape: 'rect' }
                ] }},
            { entry: { key: 'lastStatus', controlType: 'text', calculated: true, minWidth: 300, width: 12, label: 'REPORTS.OBJECTIVEBUSINESSAREA.LASTSTATUS'}}
          ]}
      ]
    }
  }
];
