import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { List } from 'immutable';

import { IPayload, RequestDiffRecord } from './index';
import { AppState } from '../../app.reducer';
import { JSONAPIResponse } from '../connector';

export class SharedService {

  public isBusy: Observable<number>;
  public diff: Observable<RequestDiffRecord>;

  public constructor(protected store: Store<AppState>, protected action: any, protected selectors: any) {
    this.isBusy = this.getAPIState().let(selectors.getIsBusy());
    this.diff = this.getAPIState().let(selectors.getDiff());
  }

  public massGo(id: number | string): void {
    this.store.dispatch(this.action.massGoLoad('' + id));
  }

  public mass(id: number | string): void {
    this.store.dispatch(this.action.massLoad('' + id));
  }

  public loadAll(): void {
    this.store.dispatch(this.action.loadAll());
  }

  public load(id?: string, includes: string[] = []): void {
    this.store.dispatch(this.action.load(id, includes));
  }

  public all(toList = true): Observable<List<any>> {
    return this.getAPIState().let(toList ? this.selectors.getMany() : this.selectors.direct());
  }

  public allLoaded(action: string) {
    return Observable.combineLatest(this.all(), this.diff).filter(d => !!d && !!d[0] && !!d[1] && d[1].action === action).map(d => d[0]);
  }

  public has(id: string | number): Observable<boolean> {
    return this.getAPIState().let(this.selectors.has('' + id));
  }

  public find(id?: string | number): Observable<any> {
    return this.getAPIState().let(this.selectors.get('' + id));
  }

  public create(id: string | IPayload | IPayload[], data?: IPayload | IPayload[]): void {
    this.store.dispatch(this.action.create(id, data));
  }

  public createGo(id: string | IPayload | IPayload[], data?: IPayload | IPayload[]): void {
    this.store.dispatch(this.action.createGo(id, data));
  }

  public update(data: IPayload | IPayload[]): void {
    this.store.dispatch(this.action.update(data));
  }

  public updateGo(data: IPayload | IPayload[]): void {
    this.store.dispatch(this.action.updateGo(data));
  }

  public relationships(id?: string | number): Observable<any> {
    return this.getAPIState().let(this.selectors.getRelationships('' + id));
  }

  public remove(id?: string | number | string[] | number[]): void {
    this.store.dispatch(this.action.remove(id));
  }

  public removeGo(id?: string | number | string[] | number[]): void {
    this.store.dispatch(this.action.removeGo(id));
  }

  public addRelationshipOf(id: string, type: string, relationship: string) {
    this.store.dispatch(this.action.addRelationship(id, type, relationship));
  }

  public removeRelationshipOf(id: string, type: string, relationship: string) {
    this.store.dispatch(this.action.removeRelationship(id, type, relationship));
  }

  public autorefresh(data: any): void {
    this.store.dispatch(this.action.loadSuccess(data));
  }

  public autorefreshCreate(data: any): void {
    this.store.dispatch(this.action.autorefreshCreate(data));
  }

  public autorefreshUpdate(data: any): void {
    this.store.dispatch(this.action.autorefreshUpdate(data));
  }

  public autorefreshRemove(data: any): void {
    this.store.dispatch(this.action.autorefreshRemove(data));
  }

  protected getAPIState() {
    return this.store.select((state: AppState) => state.api);
  }

}
