import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { isNullOrUndefined } from 'util';

import { AppState } from '../../app.reducer';
import { ActivityAction } from './activities.actions';
import * as selectors from './activities.selectors';
import { SharedService } from '../shared/shared.service';
import { IPayload } from '../shared/common.models';

@Injectable()
export class ActivityService extends SharedService {

  public constructor(protected store: Store<AppState>, protected action: ActivityAction) {
    super(store, action, selectors);
  }

  public createOnNodeData(nodeDataId: string, data: IPayload | IPayload[]) {
    this.store.dispatch(this.action.createOnNodeData(nodeDataId, data));
  }

  public createOnInstance(instanceId: string, data: IPayload | IPayload[]) {
    this.store.dispatch(this.action.createOnInstance(instanceId, data));
  }

}
