import { Store } from '@ngrx/store';

import { APIState } from '../reducer';
import { commonSelectors } from '../shared';
import * as fromModels from './models.reducers';
import { ModelState, defaultValues } from './models.models';
import { compose } from '../../utilities/compose';

export function getState() {
    return (state$: Store<APIState>) => state$
        .select((s: APIState) => s.models);
}

export function has(id: string) {
  return compose(commonSelectors.has(id), getState());
}

export function get(id: string) {
  return compose(commonSelectors.getWithDefault(id, defaultValues), getState());
}

export function getMany(ids?: string[]) {
  return compose(commonSelectors.getManyWithDefault(ids, defaultValues, 'name'), getState());
}

export function getIsBusy() {
    return compose(commonSelectors.getIsBusy(), getState());
}

export function getDiff() {
  return compose(commonSelectors.getDiff(), getState());
}

export function getFavoriteModels() {
  return compose((state$: Store<ModelState>) => state$.let(fromModels.getFavorites()), getState());
}

export function getRelationships(id: string) {
  return compose(commonSelectors.getRelationships(id), getState());
}
