import { Map, OrderedMap, Set } from 'immutable';
import { TreeNode } from './tree.node';
import { TreeRelationship } from './tree.relationship';
import { isNullOrUndefined } from 'util';

export class TreeFilter {

  public static byHumanResources(treeNodes: OrderedMap<string, TreeNode>, humanResourceId: string): OrderedMap<string, TreeNode> {
    return treeNodes.filter(treeNode => '' + treeNode.node.responsibleId === '' + humanResourceId).toOrderedMap();
  }

  public static bySubSets(treeNodes: OrderedMap<string, TreeNode>, subsetId: string): OrderedMap<string, TreeNode> {
    if (subsetId === '0') {
      return <OrderedMap<string, TreeNode>> treeNodes.filter(treeNode => isNullOrUndefined(treeNode.node.relationships) || treeNode.node.relationships.subsets.length === 0);
    } else {
      return <OrderedMap<string, TreeNode>> treeNodes.filter(treeNode => isNullOrUndefined(treeNode.node.relationships) || treeNode.node.relationships.subsets.indexOf(subsetId) !== -1);
    }
  }

  public static closed(treeNodes: OrderedMap<string, TreeNode>): OrderedMap<string, TreeNode> {
    return <OrderedMap<string, TreeNode>> treeNodes.filter(treeNode => {
      const targetDate = new Date(treeNode.node.targetDate).valueOf();
      const currentDate = new Date().valueOf();
      return !(treeNode.node.status === 200 && (targetDate !== 0 && targetDate < currentDate));
    });
  }

  public static notClosed(treeNodes: OrderedMap<string, TreeNode>): OrderedMap<string, TreeNode> {
    return <OrderedMap<string, TreeNode>> treeNodes.filter(treeNode => {
      const targetDate = new Date(treeNode.node.targetDate).valueOf();
      const currentDate = new Date().valueOf();
      return treeNode.node.status === 200 && (targetDate !== 0 && targetDate < currentDate);
    });
  }

  public static kaDb(treeNodes: OrderedMap<string, TreeNode>): OrderedMap<string, TreeNode> {
    return <OrderedMap<string, TreeNode>> treeNodes.filter(treeNode => {
      return (treeNode.node.costtype === 0 || treeNode.node.costtype === null) && treeNode.node.businesscalculation === null;
    });
  }

  public static active(treeNodes: OrderedMap<string, TreeNode>): OrderedMap<string, TreeNode> {
    return <OrderedMap<string, TreeNode>> treeNodes.filter(treeNode => {
      return !(treeNode.node.status !== 200 && treeNode.node.status !== 0);
    });
  }

  public static directChain(treeNodes: OrderedMap<string, TreeNode>, ids: string[]) {
    return <OrderedMap<string, TreeNode>> treeNodes.filter(treeNode => isNullOrUndefined(ids) || ids.indexOf(treeNode.id) !== -1);
  }

  public static byNodes(treeNodes: OrderedMap<string, TreeNode>, treeRelationships: Map<string, TreeRelationship>): Map<string, TreeRelationship> {
    return treeRelationships.filter(treeRelationship => treeRelationship.toCreate || (treeNodes.has(treeRelationship.parent) && treeNodes.has(treeRelationship.child))).toMap();
  }

  public static byNode(treeNodes: OrderedMap<string, TreeNode>, treeRelationships: Map<string, TreeRelationship>): Map<string, TreeRelationship> {
    return treeRelationships.filter(treeRelationship => treeNodes.has(treeRelationship.parent) || treeNodes.has(treeRelationship.child)).toMap();
  }

  public static byExplicitNode(treeNodes: OrderedMap<string, TreeNode>, treeRelationships: Map<string, TreeRelationship>): Map<string, TreeRelationship> {
    return treeRelationships.filter(treeRelationship => treeNodes.has(treeRelationship.parent) && treeNodes.has(treeRelationship.child)).toMap();
  }

}
