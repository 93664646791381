export const TemplateFieldsTime = {
  type: 'table',
  settings: {
    /* Group the elements by levels */
    groupByLevels: false,
    /* Show total row after level group */
    showTotalsAfterLevel: false,
    /* Show total row at the end of the table */
    showTotals: false,
    /* Group the elements by model */
    groupByModels: false,
    /* Use a thousand seperator for numeric values */
    thousandsSeperator: false,
    /* How many decimals should be shown on numeric values */
    decimalnumbers: 2,
    /* Show top most header row (grouped heading) */
    showHeading: true,
    /* Show table fields */
    showFields: true,
    /* Show the footer */
    showFooter: false,
    /* Different source then nodes */
    source: '', // '', models, activities
    /* Caption element above the table */
    caption: null,
    /* The header text color */
    headerTextColor: '#ffffff',
    /* The header background color */
    headerBackgroundColor: '#9ac93e',
    /* The border */
    border: false,
    /* A filter function to filter by */
    filter: null,
    /* Show the assigned activities */
    activities: true,
    /* Trigger to load the Businessarea with that table */
    loadBusinessarea: false,
    /* Show sidestep top most / tree below related node */
    showSidestep: false, // false, tree, topmost
    /* Exports */
    exports: [], //  excel, pdf
  },
  configuration: {
    fields: [
      { entry: { key: 'general', label: 'REPORTS.ESTIMATION.GROUPS.GENERAL', controlType: 'group'}, children: [
        { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.NAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME' }}
      ]},
      { entry: { key: 'space', label: '', controlType: 'space', editable: false }},
      { entry: { key: 'absolutebenefit', label: 'REPORTS.ESTIMATION.GROUPS.ABSOLUTEBENEFIT', controlType: 'group'}, children: [
        { entry: { key: 'benefitBudget', type: 'number', controlType: 'textbox', activity: true, label: 'NODE.DATASHEET.FIELDS.BENEFITBUDGET' }},
        { entry: { key: 'benefitActual', type: 'number', controlType: 'textbox', activity: true, label: 'NODE.DATASHEET.FIELDS.BENEFITACTUAL' }},
        { entry: { key: 'benefitRemaining', type: 'number', controlType: 'textbox', activity: true, label: 'NODE.DATASHEET.FIELDS.BENEFITREMAINING' }}
      ]},
      { entry: { key: 'space', label: '', controlType: 'group'}, children: [
        { entry: { key: 'space', label: '', controlType: 'space', editable: false, activity: true }}
      ]},
      { entry: { key: 'absolutecost', label: 'REPORTS.ESTIMATION.GROUPS.ABSOLUTECOST', controlType: 'group'}, children: [
        { entry: { key: 'costBudget', type: 'number', controlType: 'textbox', activity: true, label: 'NODE.DATASHEET.FIELDS.COSTBUDGET' }},
        { entry: { key: 'costActual', type: 'number', controlType: 'textbox', activity: true, label: 'NODE.DATASHEET.FIELDS.COSTACTUAL' }},
        { entry: { key: 'costRemaining', type: 'number', controlType: 'textbox', activity: true, label: 'NODE.DATASHEET.FIELDS.COSTREMAINING' }}
      ]},
      { entry: { key: 'space', label: '', controlType: 'group'}, children: [
        { entry: { key: 'space', label: '', controlType: 'space', editable: false, activity: true }}
      ]},
      { entry: { key: 'absoluteinvest', label: 'REPORTS.ESTIMATION.GROUPS.ABSOLUTEINVEST', controlType: 'group'}, children: [
        { entry: { key: 'investBudget', type: 'number', controlType: 'textbox', activity: true, label: 'NODE.DATASHEET.FIELDS.INVESTBUDGET' }},
        { entry: { key: 'investActual', type: 'number', controlType: 'textbox', activity: true, label: 'NODE.DATASHEET.FIELDS.INVESTACTUAL' }},
        { entry: { key: 'investRemaining', type: 'number', controlType: 'textbox', activity: true, label: 'NODE.DATASHEET.FIELDS.INVESTREMAINING' }}
      ]}
    ]
  }
};
