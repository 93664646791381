import { Record } from 'immutable';
import { RequestDiffRecord } from '../shared';

const myStateRecord = Record({
  isBusy: 0,
  diff: new RequestDiffRecord()
});

export class MyState extends myStateRecord {
  isBusy: number;
  diff: RequestDiffRecord;
}
