import { JSONAPIResponse } from '../connector';
import { Injectable } from '@angular/core';

import { BusinessareaAction } from '../businessareas/businessareas.actions';
import { ModelAction } from '../models/models.actions';
import { SubsetAction } from '../subsets/subsets.actions';
import { NodeDataAction } from '../nodedata/nodedata.actions';
import { NodeStructureAction } from '../nodestructures/nodestructures.actions';
import { RelationshipAction } from '../relationships/relationships.actions';
import { ActivityAction } from '../activities/activities.actions';
import { HumanResourceAction } from '../humanresources/humanresources.actions';
import { ImportSchemeAction } from '../importschemes/importschemes.actions';
import { InstanceAction } from '../instances/instances.actions';
import { GroupAction } from '../groups/groups.actions';
import { HierarchyAction } from '../hierarchy/hierarchy.actions';
import { VersionAction } from '../versions/versions.actions';
import { TemplateAction } from '../templates/templates.actions';
import { AuditAction } from '../audits/audits.actions';
import { data } from 'jquery';

@Injectable()
export class CommonEffects {

  public getObservables(init: Function, includes: string[], response: JSONAPIResponse<any | any[]>, includesFirst = false) {
    const observables = [];
    if (!(response.data instanceof Array)) {
      response.data = [response.data];
    }
    if (!includesFirst) {
      observables.push(init(response.toPayload()));
    }
    const includeString = includes.join(',');
    if (includeString.indexOf('businessareas') !== -1) {
      observables.push(this.businessareaAction.loadSuccess(response.toIncludedByType('businessareas')));
    }
    if (includeString.indexOf('models') !== -1) {
      observables.push(this.modelAction.loadSuccess(response.toIncludedByType('models')));
    }
    if (includeString.indexOf('submodels') !== -1) {
      observables.push(this.modelAction.loadSuccess(response.toIncludedByType('submodels')));
    }
    if (includeString.indexOf('subsets') !== -1) {
      observables.push(this.subsetAction.loadSuccess(response.toIncludedByType('subsets')));
    }
    if (includeString.indexOf('nodedata') !== -1) {
      observables.push(this.nodeDataAction.loadSuccess(response.toIncludedByType('nodedata')));
    }
    if (includeString.indexOf('nodestructures') !== -1) {
      observables.push(this.nodeStructureAction.loadSuccess(response.toIncludedByType('nodestructures')));
    }
    if (includeString.indexOf('relationships') !== -1) {
      observables.push(this.relationshipAction.loadSuccess(response.toIncludedByType('relationships')));
    }
    if (includeString.indexOf('activities') !== -1) {
      observables.push(this.activityAction.loadSuccess(response.toIncludedByType('activities')));
    }
    if (includeString.indexOf('humanresources') !== -1) {
      observables.push(this.humanresourceAction.loadSuccess(response.toIncludedByType('humanresources')));
    }
    if (includeString.indexOf('groups') !== -1) {
      observables.push(this.groupAction.loadSuccess(response.toIncludedByType('groups')));
    }
    if (includeString.indexOf('importschemes') !== -1) {
      observables.push(this.importschemeAction.loadSuccess(response.toIncludedByType('importschemes')));
    }
    if (includeString.indexOf('versions') !== -1) {
      observables.push(this.versionAction.loadSuccess(response.toIncludedByType('versions')));
    }
    if (includeString.indexOf('hierarchy') !== -1) {
      observables.push(this.hierarchyAction.loadSuccess(response.toIncludedByType('hierarchy')));
    }
    if (includeString.indexOf('templates') !== -1) {
      observables.push(this.templateAction.loadSuccess(response.toIncludedByType('templates')));
    }
    if (includeString.indexOf('relatedParents') !== -1) {
      observables.push(this.nodeDataAction.loadSuccess(response.toIncludedByType('nodedata')));
    }
    if (includeString.indexOf('relatedChildren') !== -1) {
      observables.push(this.nodeDataAction.loadSuccess(response.toIncludedByType('nodedata')));
    }
    if (includesFirst) {
      observables.push(init(response.toPayload()));
    }
    return observables;
  }

  public getMassModelObservables(init: Function, response: JSONAPIResponse<any | any[]>) {
    const observables = [];
    if (!(response.data instanceof Array)) {
      response.data = [response.data];
    }
    observables.push(init(response.toPayload()));
    observables.push(this.instanceAction.loadSuccess(response.toIncludedByType('instances')));
    observables.push(this.businessareaAction.loadSuccess(response.toIncludedByType('businessareas')));
    observables.push(this.subsetAction.loadSuccess(response.toIncludedByType('subsets')));
    observables.push(this.nodeDataAction.loadSuccess(response.toIncludedByType('nodedata')));
    observables.push(this.nodeStructureAction.loadSuccess(response.toIncludedByType('nodestructures')));
    observables.push(this.relationshipAction.loadSuccess(response.toIncludedByType('relationships')));
    observables.push(this.activityAction.loadSuccess(response.toIncludedByType('activities')));
    observables.push(this.humanresourceAction.loadSuccess(response.toIncludedByType('humanresources')));
    observables.push(this.groupAction.loadSuccess(response.toIncludedByType('groups')));
    observables.push(this.templateAction.loadSuccess(response.toIncludedByType('templates')));
    observables.push(this.versionAction.loadSuccess(response.toIncludedByType('versions')));
    observables.push(this.modelAction.loadSuccess(response.toIncludedByType('models')));
    observables.push(this.modelAction.massLoadSuccess(response.toPayload()));
    return observables;
  }

  public getMassBusinessareaObservables(init: Function, response: JSONAPIResponse<any | any[]>) {
    const observables = [];
    if (!(response.data instanceof Array)) {
      response.data = [response.data];
    }
    observables.push(init(response.toPayload()));
    observables.push(this.instanceAction.loadSuccess(response.toIncludedByType('instances')));
    observables.push(this.modelAction.loadSuccess(response.toIncludedByType('models')));
    observables.push(this.subsetAction.loadSuccess(response.toIncludedByType('subsets')));
    observables.push(this.nodeDataAction.loadSuccess(response.toIncludedByType('nodedata')));
    observables.push(this.nodeStructureAction.loadSuccess(response.toIncludedByType('nodestructures')));
    observables.push(this.relationshipAction.loadSuccess(response.toIncludedByType('relationships')));
    observables.push(this.activityAction.loadSuccess(response.toIncludedByType('activities')));
    observables.push(this.humanresourceAction.loadSuccess(response.toIncludedByType('humanresources')));
    observables.push(this.groupAction.loadSuccess(response.toIncludedByType('groups')));
    observables.push(this.templateAction.loadSuccess(response.toIncludedByType('templates')));
    observables.push(this.businessareaAction.massLoadSuccess(response.toIncludedByType('businessareas')));
    return observables;
  }

  public createNodesAndRelationships(payload: any, response: JSONAPIResponse<any | any[]>) {
    const observables = [];
    observables.push(this.nodeDataAction.loadSuccess(response.toIncludedByType('nodedata')));
    observables.push(this.nodeStructureAction.createSuccess(payload, response.data));
    observables.push(this.relationshipAction.loadSuccess(response.toIncludedByType('relationships')));
    return observables;
  }

  public getUserObservableWithResponsibleElements(init: Function, response: JSONAPIResponse<any | any[]>) {
    const observables = [];
    if (!(response.data instanceof Array)) {
      response.data = [response.data];
    }
    observables.push(init(response.toPayload()));
    observables.push(this.nodeDataAction.loadSuccess(response.toIncludedByType('nodedata')));
    observables.push(this.nodeStructureAction.loadSuccess(response.toIncludedByType('nodestructures')));
    observables.push(this.activityAction.loadSuccess(response.toIncludedByType('activities')));
    observables.push(this.auditAction.loadSuccess(undefined, response.toIncludedByType('audits')));
    return observables;
  }

  public getInitiativesModelObservables(init: Function, response: JSONAPIResponse<any | any[]>) {
    const observables = [];
    if (!(response.data instanceof Array)) {
      response.data = [response.data];
    }
    observables.push(init(response.toPayload()));
    observables.push(this.nodeDataAction.loadSuccess(response.toIncludedByType('nodedata')));
    return observables;
  }

  public constructor(
    private instanceAction: InstanceAction,
    private businessareaAction: BusinessareaAction,
    private modelAction: ModelAction,
    private subsetAction: SubsetAction,
    private nodeDataAction: NodeDataAction,
    private nodeStructureAction: NodeStructureAction,
    private relationshipAction: RelationshipAction,
    private activityAction: ActivityAction,
    private humanresourceAction: HumanResourceAction,
    private groupAction: GroupAction,
    private importschemeAction: ImportSchemeAction,
    private versionAction: VersionAction,
    private hierarchyAction: HierarchyAction,
    private templateAction: TemplateAction,
    private auditAction: AuditAction
  ) { }

}
