import { List } from 'immutable';

import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { LegendItem } from '../../../../shared/legend/legend.item';
import { Node } from '../../../../shared/api/nodes';

export class ColorLabelProviderServiceRisk extends ColorLabelProviderAbstract {

  private static LOW = '#9ac93e';
  private static MEDIUM = '#EDD515';
  private static HIGH = '#E50000';

  public color(element: any) {
    switch (this.calculate(element)) {
      case 1:
        return [ColorLabelProviderServiceRisk.LOW];
      case 2:
        return [ColorLabelProviderServiceRisk.MEDIUM];
      case 3:
        return [ColorLabelProviderServiceRisk.HIGH];
      default:
        return super.color();
    }
  }

  public legend() {
    return List<LegendItem>([
      new LegendItem({ key: 'risk.low', color: ColorLabelProviderServiceRisk.LOW, label: 'LEGEND.RISK.LOW', field: 'risk', value: 1 }),
      new LegendItem({ key: 'risk.medium', color: ColorLabelProviderServiceRisk.MEDIUM, label: 'LEGEND.RISK.MEDIUM', field: 'risk', value: 2 }),
      new LegendItem({ key: 'risk.high', color: ColorLabelProviderServiceRisk.HIGH, label: 'LEGEND.RISK.HIGH', field: 'risk', value: 3 })
    ]);
  }

  private calculate(node: Node) {
    return node.risk;
  }

}
