export const TemplateFieldsUserSettings = {
  tabs: [
    {
      entry: { key: 'header1', label: '' },
      children: [
        { entry: { key: 'language', controlType: 'dropdown', hasNull: false, label: 'USER.DATASHEET.FIELDS.LANGUAGE.TITLE', options: [
          { key: 'en', value: 'USER.DATASHEET.FIELDS.LANGUAGE.ENGLISH' },
          { key: 'de', value: 'USER.DATASHEET.FIELDS.LANGUAGE.GERMAN' }
        ]}},
        {
          entry: { key: 'clear', controlType: 'button', label: 'USER.DATASHEET.FIELDS.CLEAR' }
        }
      ]
    }
  ]
};
