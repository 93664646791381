import { IModel, IModelAttributes } from '../connector';
import { Record, Set, Map } from 'immutable';
import { DataModelState, RecordFactory, RequestDiffRecord } from '../shared';
import { isString } from 'util';

export const MODEL_TYPE_DEFAULT = 0;
export const MODEL_TYPE_DATASOURCE = 1;
export const MODEL_TYPE_CCM = 2;
export const MODEL_TYPE_SCM = 3;
export const MODEL_TYPE_UCIM = 4;
export const MODEL_TYPE_CWM = 5;
export const MODEL_TYPE_MCM = 6;
export const MODEL_TYPE_LIBRARY = 7;
export const MODEL_TYPE_SPLASH = 8;
export const MODEL_TYPE_STRATEGYMAP = 9;
export const MODEL_TYPE_PROJECTPORTFOLIO = 10;

export const defaultValues = Map<string, any>({
  settings: {
    default: {
      boxWidth: 80,
      wordBreak: true,
      limit: 255,
      lines: 3,
      truncator: '...',
      divider: '-'
    }
  }
});

const stateRecord = Record({
  ids: Set<string>(),
  favorites: Map<string, any>(),
  entities: Map<string, Model>(),
  relationships: Map<string, ModelRelationships>(),
  diff: new RequestDiffRecord(),
  isBusy: 0
});

export class ModelState extends stateRecord implements DataModelState {
  ids: Set<string>;
  favorites: Map<string, any>;
  entities: Map<string, Model>;
  relationships: Map<string, ModelRelationships>;
  diff: RequestDiffRecord;
  isBusy: number;
}

const modelRecord = Record(<IModelAttributes>{
  id: undefined,
  name: undefined,
  reference: '',
  crossReference: '',
  description: '',
  color: undefined,
  date: undefined,
  startDate: undefined,
  status: 0,
  commercialStatus: 0,
  updatedAt: undefined,
  template: null,
  settings: null,
  humanresource: null,
  targetpicture: '',
  hypothesis: '',
  abbreviation: '',
  duplicate_original_id: undefined,
  sorting: 0,
  onstrategyradar: false,
  onreport: false,
  onprogramm: 0,
  parentModel: undefined,
  active: true,
  type: 0,
  icon: '',
  versionname: '',

  relationships: undefined
});

export class Model extends modelRecord implements IModelAttributes {
  id: string;
  name: string;
  reference: string;
  crossReference: string;
  description: string;
  color: string;
  date: string;
  startDate: string;
  status: number;
  commercialStatus: number;
  updatedAt: number;
  template: string;
  settings: any;
  humanresource: number;
  targetpicture: string;
  hypothesis: string;
  abbreviation: string;
  duplicate_original_id?: number;
  sorting: number;
  onstrategyradar: boolean;
  onreport: boolean;
  onprogramm: number;
  parentModel: string;
  active: boolean;
  type: number;
  icon: string;
  versionname: string;

  relationships: ModelRelationships;

  static fromJSONAPI(model: IModel) {
    if (isString(model.attributes.settings)) {
      model.attributes.settings = JSON.parse(model.attributes.settings);
    }
    return RecordFactory.entity.fromJSONAPI<Model>(Model, model);
  }
}

const relationshipsRecord = Record({
  model: undefined,
  submodels: undefined,
  subsets: undefined,
  nodestructures: Set<string>(),
  versions: undefined,
  relationships: Set<string>(),
  businessarea: undefined,
  humanresource: undefined,
  template: undefined,
  parent: undefined,
  parentModel: undefined
});

export class ModelRelationships extends relationshipsRecord {
  model: string;
  submodels: Set<string>;
  subsets: Set<string>;
  nodestructures: Set<string>;
  versions: Set<string>;
  relationships: Set<string>;
  businessarea: string;
  humanresource: string;
  template: string;
  parent: string;
  parentModel: string;

  static fromJSONAPI(model: IModel) {
    return RecordFactory.relationships.fromJSONAPI<ModelRelationships>(ModelRelationships, model);
  }
}

const modelCreateRecord = Record(<IModelAttributes>{
  id: undefined,
  name: undefined,
  color: undefined,
  template: undefined,
  type: 0,
  businessArea: undefined
});

export class ModelCreate extends modelCreateRecord {
  id: string;
  name: string;
  color: string;
  template: string;
  type?: number;
  businessArea?: string;
}
