import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { List } from 'immutable';
import { Observable } from 'rxjs/Observable';

import { AppState } from '../../app.reducer';
import { ModelService } from '../models';

import { Subset } from './subsets.models';
import * as selectors from './subsets.selectors';
import { SubsetAction } from './subsets.actions';
import { SharedService } from '../shared/shared.service';

@Injectable()
export class SubsetService extends SharedService {

  public constructor(protected store: Store<AppState>, protected action: SubsetAction, private modelService: ModelService) {
    super(store, action, selectors);
  }

  public findByModel(id: string): Observable<List<Subset>> {
    return this.all().map((subsets: List<Subset>) => subsets.filter(subset => subset.relationships.model === id).toList());
  }

}
