import { Map, Set } from 'immutable';

import { Action } from '../shared/common.action';
import { NodeDataState, NodeData, NodeDataRelationships } from './nodedata.models';
import { NodeDataAction } from './nodedata.actions';
import { JSONAPIDeserializerService } from '../shared/jsonapi-deserializer.service';
import { Utils } from '../shared/utils';

export default function reducer(state = new NodeDataState(), action: Action): NodeDataState {

  const deserializer = new JSONAPIDeserializerService<NodeData, NodeDataRelationships>(NodeData.fromJSONAPI, NodeDataRelationships.fromJSONAPI);
  switch (action.type) {

    case NodeDataAction.LOAD:
      return <NodeDataState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case NodeDataAction.LOAD_SUCCESS: {
      const { entities, relationships } = deserializer.deserialize(action.payload);
      return <NodeDataState>Utils.updateState<NodeDataState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case NodeDataAction.LOAD_FAIL:
      return <NodeDataState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );

    case NodeDataAction.CREATE_SUCCESS: {
      const { entities, relationships } = deserializer.deserialize(action.payload.response);

      const updatedRelationships = Map<string, NodeDataRelationships>(relationships.map(rel => !rel.get('activities') ?
        rel.set('activities', Set<string>()) : rel));

      return <NodeDataState>Utils.updateState<NodeDataState>(state, entities, updatedRelationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));

    }

    case NodeDataAction.UPDATE:
      return <NodeDataState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case NodeDataAction.UPDATE_SUCCESS: {
      const { entities, relationships } = deserializer.deserialize(action.payload.response);
      return <NodeDataState>Utils.updateState<NodeDataState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case NodeDataAction.UPDATE_FAIL:
      return <NodeDataState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );

    case NodeDataAction.UPDATE_WITH_TOKEN:
      return <NodeDataState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case NodeDataAction.ADD_RELATIONSHIP: {
      let nodeDataRelationships = state.relationships.get('' + action.payload.id);
      const updatedNodeDataRelationships = {};
      let relationships: Set<string> = nodeDataRelationships[action.payload.type];
      if (!relationships) {
        nodeDataRelationships = <any>nodeDataRelationships.set(action.payload.type, Set<string>());
        relationships = nodeDataRelationships[action.payload.type];
      }
      updatedNodeDataRelationships['' + action.payload.id] = nodeDataRelationships.set(action.payload.type, relationships.add(action.payload.relationship));
      state = <NodeDataState>state.set('entities', state.entities.set('' + action.payload.id, <NodeData>state.entities.get('' + action.payload.id).set('updatedAt', new Date().valueOf())));
      return <NodeDataState>Utils.updateState<NodeDataState>(state, state.entities, Map<any, any>(updatedNodeDataRelationships));
    }

    case NodeDataAction.REMOVE_RELATIONSHIP: {
      const nodeDataRelationships = state.relationships.get('' + action.payload.id);
      const updatedNodeDataRelationships = {};
      const relationships: Set<string> = nodeDataRelationships[action.payload.type];
      if (!relationships) {
        return state;
      }
      updatedNodeDataRelationships['' + action.payload.id] = nodeDataRelationships.set(action.payload.type, relationships.remove(action.payload.relationship));
      state = <NodeDataState>state.set('entities', state.entities.set('' + action.payload.id, <NodeData>state.entities.get('' + action.payload.id).set('updatedAt', new Date().valueOf())));
      return <NodeDataState>Utils.updateState<NodeDataState>(state, state.entities, Map<any, any>(updatedNodeDataRelationships));
    }

    case NodeDataAction.LOAD_BY_TYPES:
      return <NodeDataState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy({ type: action.type }, isBusy))
      );

    case NodeDataAction.LOAD_BY_TYPES_SUCCESS: {
      const { entities, relationships } = deserializer.deserialize(action.payload);
      return <NodeDataState>Utils.updateState<NodeDataState>(state, entities, relationships)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case NodeDataAction.LOAD_BY_TYPES_FAIL:
      return <NodeDataState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );

    default:
      return state;
  }
}
