import { Record, Set, Map } from 'immutable';
import { INodeStructure, INodeStructureAttributes } from '../connector';
import { RecordFactory, RequestDiffRecord } from '../shared';

const nodeStructureStateRecord = Record({
  ids: Set<string>(),
  entities: Map<string, NodeStructure>(),
  relationships: Map<string, NodeStructureRelationships>(),
  diff: new RequestDiffRecord(),
  isBusy: 0
});

export class NodeStructureState extends nodeStructureStateRecord {
  ids: Set<string>;
  entities: Map<string, NodeStructure>;
  relationships: Map<string, NodeStructureRelationships>;
  diff: RequestDiffRecord;
  isBusy: number;
}

const nodeStructureRecord = Record(<INodeStructureAttributes>{
  id: undefined,
  tempId: undefined,
  positionX: 0,
  autoPositionX: 0,
  autoPositionUncrossed: 0,
  level: 0,
  sorting: 0,
  active: true,
  isSidestep: undefined,
  updatedAt: undefined,
  modelId: undefined,
  duplicate_original_id: undefined,
  isInput: false,

  relationships: undefined
});

export class NodeStructure extends nodeStructureRecord implements INodeStructureAttributes {
  id: string;
  tempId: string;
  positionX: number;
  autoPositionX: number;
  autoPositionUncrossed: number;
  level: number;
  sorting: number;
  active: boolean;
  isSidestep: boolean;
  updatedAt: number;
  modelId: string;
  duplicate_original_id?: number;
  isInput: boolean;

  relationships: NodeStructureRelationships;

  static fromJSONAPI(nodestructure: INodeStructure) {
    return RecordFactory.entity.fromJSONAPI<NodeStructure>(NodeStructure, nodestructure);
  }
}

const relationshipsRecord = Record({
  nodedata: undefined,
  original: undefined,
  model: undefined,
  parentModel: undefined,
  instance: undefined,
  subsets: Set<string>(),
  sidesteps: Set<string>(),
  templates: undefined
});

export class NodeStructureRelationships extends relationshipsRecord {
  nodedata: string;
  original: string;
  model: string;
  parentModel: string;
  subsets: string[];
  sidesteps: string[];
  instance: string;
  templates: string;

  static fromJSONAPI(nodestructure: INodeStructure) {
    return RecordFactory.relationships.fromJSONAPI<NodeStructureRelationships>(NodeStructureRelationships, nodestructure);
  }
}
