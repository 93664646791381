import { GroupState, Group, GroupRelationships } from './groups.models';
import { Action } from '../shared/common.action';
import { GroupAction } from './groups.actions';
import { JSONAPIDeserializerService } from '../shared/jsonapi-deserializer.service';
import { Utils } from '../shared/utils';

export default function reducer(state = new GroupState(), action: Action): GroupState {

  switch (action.type) {

    case GroupAction.LOAD:
      return <GroupState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case GroupAction.LOAD_SUCCESS: {
      const deserializer = new JSONAPIDeserializerService<Group, GroupRelationships>(Group.fromJSONAPI, GroupRelationships.fromJSONAPI);
      const { entities, relationships } = deserializer.deserialize(action.payload);
      return <GroupState>Utils.updateState<GroupState>(state, entities, relationships)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case GroupAction.LOAD_FAIL:
      return <GroupState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );

    case GroupAction.CREATE:
      return <GroupState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case GroupAction.CREATE_SUCCESS: {
      const deserializer = new JSONAPIDeserializerService<Group, GroupRelationships>(Group.fromJSONAPI, GroupRelationships.fromJSONAPI);
      const {entities, relationships} = deserializer.deserialize(action.payload.response);
      return <GroupState>Utils.updateState<GroupState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case GroupAction.CREATE_FAIL: {
      return <GroupState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case GroupAction.UPDATE:
      return <GroupState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case GroupAction.UPDATE_SUCCESS: {
      const deserializer = new JSONAPIDeserializerService<Group, GroupRelationships>(Group.fromJSONAPI, GroupRelationships.fromJSONAPI);
      const {entities, relationships} = deserializer.deserialize(action.payload.response);

      return <GroupState>Utils.updateState<GroupState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case GroupAction.UPDATE_FAIL: {
      return <GroupState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case GroupAction.REMOVE:
      return <GroupState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case GroupAction.REMOVE_SUCCESS: {
      const deserializer = new JSONAPIDeserializerService<Group, GroupRelationships>(Group.fromJSONAPI, GroupRelationships.fromJSONAPI);
      const {entities, relationships} = deserializer.deserialize(action.payload.response);

      return <GroupState>Utils.deleteFromState<GroupState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case GroupAction.REMOVE_FAIL: {
      return <GroupState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case GroupAction.ASSIGN:
      return <GroupState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case GroupAction.ASSIGN_SUCCESS: {
      const deserializer = new JSONAPIDeserializerService<Group, GroupRelationships>(Group.fromJSONAPI, GroupRelationships.fromJSONAPI);
      const {entities, relationships} = deserializer.deserialize(action.payload.response);

      return <GroupState>Utils.updateState<GroupState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case GroupAction.ASSIGN_FAIL: {
      return <GroupState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case GroupAction.UNASSIGN:
      return <GroupState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case GroupAction.UNASSIGN_SUCCESS: {
      const deserializer = new JSONAPIDeserializerService<Group, GroupRelationships>(Group.fromJSONAPI, GroupRelationships.fromJSONAPI);
      const {entities, relationships} = deserializer.deserialize(action.payload.response);

      return <GroupState>Utils.updateState<GroupState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case GroupAction.UNASSIGN_FAIL: {
      return <GroupState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    default:
      return state;
  }

}
