import { Record, Set, Map } from 'immutable';
import { DataModelState, RequestDiffRecord } from '../shared';
import { RecordFactory } from '../shared/record-factory';
import { environment } from '../../../../environments/environment';
import { ITemplate } from '../connector/models';
import { TemplateFieldsNodesRequirementDefinitionGrid } from '../../../../environments/template/fields/default/template.fields.requirementdefinition';

const stateRecord = Record({
  ids: Set<string>(),
  entities: Map<string, Template>(),
  relationships: Map<string, TemplateRelationships>(),
  diff: new RequestDiffRecord(),
  isBusy: 0,
});

export class TemplateState extends stateRecord implements DataModelState {
  ids: Set<string>;
  entities: Map<string, Template>;
  relationships: Map<string, TemplateRelationships>;
  diff: RequestDiffRecord;
  isBusy: number;
}

export class TemplateCategories {
  static COMPLIANCE = 'compliance';
  static STRATEGYDEPLOYMENT = 'strategydeployment';
  static PROGRAMMANAGEMENT = 'programmanagement';
  static MULTIPROJEKTMANAGEMENT = 'multiprojektmanagement';
  static RISKMANAGEMENT = 'riskmanagement';
  static AGILEMANAGEMENT = 'agilemanagement';
  static TRANSFORMATIONMANAGEMENT = 'transformationmanagement';
  static REQUIREMENTSMANAGEMENT = 'requirementsmanagement';
  static PERSONALMANAGEMENT = 'personalmanagement';
  static PROJECTMANAGEMENT = 'projectmanagement';
  static TODO = 'todo';

  public static list() {
    const list = [];
    const ignore = ['length', 'name', 'prototype', 'list', 'item'];
    Object.getOwnPropertyNames(this).filter(propertyName => this.hasOwnProperty(propertyName) && ignore.indexOf(propertyName) === -1).forEach(propertyName => {
      list.push({ id: this[propertyName], title: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.' + this[propertyName].toUpperCase() });
    });
    return list;
  }

  public static item(key: string) {
    return { id: this[key.toUpperCase()], title: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.' + this[key.toUpperCase()].toUpperCase() };
  }
}

const templateRecord = Record(environment.template);
export class Template extends templateRecord {
  id: string;
  name: string;
  language: string;
  defaultLanguage: string;
  background: string;
  color: string;
  thousands: string;
  url: string;
  type: string;
  modeltype: string;
  qstatusdiff: boolean;
  settings: any;
  dashboard: any;
  home: any;
  homepresets: any;
  nodes: any;
  activities: any;
  activitieslist: any;
  aggregations: any;
  businesscalculations: Array<any>;
  drilldownfields: any;
  reports: any;
  canvas: any;
  graph: any;
  mcfarlan: any;
  radial: any;
  table: any;
  syncs: Array<string>;
  created_at: number;
  updated_at: number;
  instances: any;
  businessareas: any;
  businessareascreate: any;
  businessareasduplicate: any;
  models: any;
  modelscreate: any;
  modelsduplicate: any;
  subsets: any;
  subsetscreate: any;
  nodescreate: any;
  nodescreatetodo: any;
  relationship: any;
  humanresource: any;
  humanresourcecreate: any;
  usersettings: any;
  requirementdefinition: any;
  requirementdefinitiongrid: any;
  matrix: any;
  library: any;
  templates: any;
  wizard: any;
  time: any;
  selectedReports: string[];
  mymenu: any;

  relationships: TemplateRelationships;

  static fromJSONAPI(template: ITemplate) {
    /* FIX FOR NON CAMEL CASE ELEMENTS */
    template.attributes = JSON.parse(JSON.stringify(template.attributes)
      .replace('timedescription', 'timeDescription')
      .replace('costdescription', 'costDescription')
      .replace('qualitydescription', 'qualityDescription')
      .replace('executivedescription', 'executiveDescription')
      .replace('programdescription', 'programDescription')
      .replace('functionaldescription', 'functionalDescription')
      .replace('resourcedescription', 'resourceDescription')
      .replace('organizationaldescription', 'organizationalDescription')
      .replace('technicaldescription', 'technicalDescription')
    );
    return RecordFactory.entity.fromJSONAPI<Template>(Template, template);
  }
}

const relationshipsRecord = Record({
  templates: Set<string>()
});

export class TemplateRelationships extends relationshipsRecord {
  templates: Set<string>;

  static fromJSONAPI(template: ITemplate) {
    return RecordFactory.relationships.fromJSONAPI<TemplateRelationships>(TemplateRelationships, template);
  }
}



