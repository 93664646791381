import { Action } from '../shared/common.action';
import { SubsetState, Subset, SubsetRelationships } from './subsets.models';
import { SubsetAction } from './subsets.actions';
import { JSONAPIDeserializerService, Utils } from '../shared';

export default function reducer(state = new SubsetState(), action: Action): SubsetState {

  const deserializer = new JSONAPIDeserializerService<Subset, SubsetRelationships>(Subset.fromJSONAPI, SubsetRelationships.fromJSONAPI);

  switch (action.type) {

    case SubsetAction.LOAD: {
      return <SubsetState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case SubsetAction.LOAD_SUCCESS: {
      const {entities, relationships} = deserializer.deserialize(action.payload);

      return <SubsetState>Utils.updateState<SubsetState>(state, entities, relationships)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case SubsetAction.LOAD_FAIL: {
      return <SubsetState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case SubsetAction.CREATE: {
      return <SubsetState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case SubsetAction.CREATE_SUCCESS: {
      const {entities, relationships} = deserializer.deserialize(action.payload.response);

      return <SubsetState>Utils.updateState<SubsetState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case SubsetAction.CREATE_FAIL: {
      return <SubsetState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case SubsetAction.UPDATE: {
      return <SubsetState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case SubsetAction.UPDATE_SUCCESS: {
      const {entities, relationships} = deserializer.deserialize(action.payload.response);
      return <SubsetState>Utils.updateState<SubsetState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case SubsetAction.UPDATE_FAIL: {
      return <SubsetState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case SubsetAction.REMOVE: {
      return <SubsetState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case SubsetAction.REMOVE_SUCCESS: {
      const {entities, relationships} = deserializer.deserialize(action.payload.response);

      return <SubsetState>Utils.deleteFromState<SubsetState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case SubsetAction.REMOVE_FAIL: {
      return <SubsetState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    default: {
      return state;
    }
  }
}
