import { JSONAPIResourceObject } from '../connector';
import { List, Map } from 'immutable';
import { APIResourceObject } from '../connector/jsonapi/jsonapi-resource-object.model';
import { isNullOrUndefined } from 'util';

export class JSONAPIDeserializerService<Entity, Relationship> {
  constructor(private entityConstructorFunc: ((resource: JSONAPIResourceObject) => Entity),
              private relationshipConstructorFunc: ((resource: JSONAPIResourceObject) => Relationship)) {}

  public deserialize(data: JSONAPIResourceObject | JSONAPIResourceObject[]) {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return data.reduce((result: {entities: Map<string, Entity>, relationships: Map<string, Relationship>},
                        current: JSONAPIResourceObject) => {

      const entity = this.entityConstructorFunc(current);
      const relationship = this.relationshipConstructorFunc(current);
      result.entities = result.entities.set(current.id, entity);
      if (Object.keys(current.relationships).length > 0) {
        result.relationships = result.relationships.set(current.id, relationship);
      }
      return result;
    }, {entities: Map<string, Entity>(), relationships: Map<string, Relationship>()});
  }
}

export class JSONAPIDeserializerServiceNoRel<Entity> {
  constructor(private entityConstructorFunc: ((resource: JSONAPIResourceObject) => Entity)) {}

  public deserialize(data: JSONAPIResourceObject | JSONAPIResourceObject[]) {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return data.reduce((result: {entities: Map<string, Entity>},
                        current: JSONAPIResourceObject) => {

      const entity = this.entityConstructorFunc(current);
      if (!isNullOrUndefined(current)) {
        result.entities = result.entities.set(current.id, entity);
      }
      return result;
    }, {entities: Map<string, Entity>()});
  }
}

export class DeserializerService<Entity, Relationship> {
  constructor(private entityConstructorFunc: ((resource: JSONAPIResourceObject) => Entity), private relationshipConstructorFunc: ((resource: JSONAPIResourceObject) => Relationship)) {}

  public deserialize(data: APIResourceObject | APIResourceObject[]) {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return data.reduce((result: { entities: Map<string, Entity>, relationships: Map<string, Relationship>, secondaryCosts: Map<string, List<Entity>>}, current: JSONAPIResourceObject) => {
      const entity = this.entityConstructorFunc(current);
      const relationships = this.relationshipConstructorFunc(current);
      switch (current.type) {
        case 'secondaryCosts':
          const secondaryCosts = result.secondaryCosts.has(current.id) ? result.secondaryCosts.get(current.id) : List<Entity>();
          result.secondaryCosts = result.secondaryCosts.set(current.id, secondaryCosts.push(entity));
          break;
        default:
          result.entities = result.entities.set(current.id, entity);
      }
      result.relationships = result.relationships.set(current.id, relationships);
      return result;
    }, { entities: Map<string, Entity>(), relationships: Map<string, Relationship>(), secondaryCosts: Map<string, List<Entity>>() });
  }
}
