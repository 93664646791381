export const TemplateFieldsGraph = {
  types: [
    { key: 'subset', type: 'subset', label: 'SUBSET.PLURAL', x: true, y: true, z: false, options: [] },
    { key: 'years', type: 'years', label: 'GRAPH.YEARS', x: true, y: false, z: false, options: [] },
    { key: 'months', type: 'months', label: 'GRAPH.MONTHS', x: true, y: false, z: false, options: [] },
    // { key: 'quarters', type: 'quarters', label: 'GRAPH.QUARTERS', x: true, y: true, z: false, options: [] },
    // { key: 'weeks', type: 'weeks', label: 'GRAPH.WEEKS', x: true, y: true, z: false, options: [] },
    { key: 'level', type: 'level', label: 'LEVEL.TITLE', x: true, y: true, z: false, options: [
        { key: 3, label: 'LEVEL.DELIVERABLE' },
        { key: 2, label: 'LEVEL.SOLUTION' },
        { key: 1, label: 'LEVEL.CHALLENGE' },
        { key: 0, label: 'LEVEL.OBJECTIVE' }
      ]},
    { key: 'status', type: 'status', label: 'STATUS.TITLE', x: true, y: true, z: false, options: [
        { key: 0, label: 'STATUS.PENDING' },
        { key: 100, label: 'STATUS.INDEFINE' },
        { key: 110, label: 'STATUS.INDEVELOP' },
        { key: 120, label: 'STATUS.INVERIFY' },
        { key: 130, label: 'STATUS.INACCEPT' },
        { key: 200, label: 'STATUS.COMPLETED' }
      ]},
    { key: 'businessBenefit', type: 'businessBenefit', label: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.TITLE', x: true, y: true, z: false, options: [
        { key: 0, label: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.NOTAPPLICABLE' },
        { key: 1, label: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.LOW' },
        { key: 2, label: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.MEDIUM' },
        { key: 3, label: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.HIGH' },
      ]},
    { key: 'uncertainty', type: 'uncertainty', label: 'NODE.DATASHEET.FIELDS.UNCERTAINTY', x: true, y: true, z: false, options: [
        { key: 0, label: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE' },
        { key: 1, label: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW' },
        { key: 2, label: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM' },
        { key: 3, label: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH' },
      ]},
    { key: 'risk', type: 'risk', label: 'NODE.DATASHEET.FIELDS.RISK', x: true, y: true, z: false, options: [
        { key: 0, label: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE' },
        { key: 1, label: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW' },
        { key: 2, label: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM' },
        { key: 3, label: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH' },
      ]},
    { key: 'sizeType', type: 'sizeType', label: 'NODE.DATASHEET.FIELDS.SIZETYPE', x: true, y: true, z: false, options: [
        { key: 0, label: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE' },
        { key: 1, label: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW' },
        { key: 2, label: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM' },
        { key: 3, label: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH' },
      ]},
    { key: 'complexity', type: 'complexity', label: 'NODE.DATASHEET.FIELDS.COMPLEXITY', x: true, y: true, z: false, options: [
        { key: 0, label: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE' },
        { key: 1, label: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW' },
        { key: 2, label: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM' },
        { key: 3, label: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH' },
      ]},
    { key: 'riskProfileCategory', type: 'riskProfileCategory', label: 'NODE.DATASHEET.FIELDS.RISKPROFILECATEGORY.TITLE', x: true, y: true, z: false, options: [
        { key: 0, label: 'NODE.DATASHEET.FIELDS.RISKPROFILECATEGORY.EXECUTIVE' },
        { key: 1, label: 'NODE.DATASHEET.FIELDS.RISKPROFILECATEGORY.PROGRAMPROJECT' },
        { key: 2, label: 'NODE.DATASHEET.FIELDS.RISKPROFILECATEGORY.FUNCTIONAL' },
        { key: 3, label: 'NODE.DATASHEET.FIELDS.RISKPROFILECATEGORY.RESOURCE' },
        { key: 4, label: 'NODE.DATASHEET.FIELDS.RISKPROFILECATEGORY.ORGANIZATIONAL' },
        { key: 5, label: 'NODE.DATASHEET.FIELDS.RISKPROFILECATEGORY.TECHNICAL' }
      ]},
    { key: 'riskProfileAssessment', type: 'riskProfileAssessment', label: 'NODE.DATASHEET.FIELDS.RISKPROFILEASSESSMENT.TITLE', x: true, y: true, z: false, options: [
        { key: 0, label: 'NODE.DATASHEET.FIELDS.RISKPROFILEASSESSMENT.LOW' },
        { key: 1, label: 'NODE.DATASHEET.FIELDS.RISKPROFILEASSESSMENT.MEDIUM' },
        { key: 2, label: 'NODE.DATASHEET.FIELDS.RISKPROFILEASSESSMENT.HIGH' }
      ]},
    { key: 'executive', type: 'executive', label: 'NODE.DATASHEET.FIELDS.EXECUTIVE.TITLE', x: true, y: true, z: false, options: [
        { key: 0, label: 'NODE.DATASHEET.FIELDS.EXECUTIVE.NOTAPPLICABLE' },
        { key: 1, label: 'NODE.DATASHEET.FIELDS.EXECUTIVE.LOW' },
        { key: 2, label: 'NODE.DATASHEET.FIELDS.EXECUTIVE.MEDIUM' },
        { key: 3, label: 'NODE.DATASHEET.FIELDS.EXECUTIVE.HIGH' }
      ]},
    { key: 'program', type: 'program', label: 'NODE.DATASHEET.FIELDS.PROGRAM.TITLE', x: true, y: true, z: false, options: [
        { key: 0, label: 'NODE.DATASHEET.FIELDS.PROGRAM.NOTAPPLICABLE' },
        { key: 1, label: 'NODE.DATASHEET.FIELDS.PROGRAM.LOW' },
        { key: 2, label: 'NODE.DATASHEET.FIELDS.PROGRAM.MEDIUM' },
        { key: 3, label: 'NODE.DATASHEET.FIELDS.PROGRAM.HIGH' }
      ]},
    { key: 'functional', type: 'functional', label: 'NODE.DATASHEET.FIELDS.FUNCTIONAL.TITLE', x: true, y: true, z: false, options: [
        { key: 0, label: 'NODE.DATASHEET.FIELDS.FUNCTIONAL.NOTAPPLICABLE' },
        { key: 1, label: 'NODE.DATASHEET.FIELDS.FUNCTIONAL.LOW' },
        { key: 2, label: 'NODE.DATASHEET.FIELDS.FUNCTIONAL.MEDIUM' },
        { key: 3, label: 'NODE.DATASHEET.FIELDS.FUNCTIONAL.HIGH' }
      ]},
    { key: 'resource', type: 'resource', label: 'NODE.DATASHEET.FIELDS.RESOURCE.TITLE', x: true, y: true, z: false, options: [
        { key: 0, label: 'NODE.DATASHEET.FIELDS.RESOURCE.NOTAPPLICABLE' },
        { key: 1, label: 'NODE.DATASHEET.FIELDS.RESOURCE.LOW' },
        { key: 2, label: 'NODE.DATASHEET.FIELDS.RESOURCE.MEDIUM' },
        { key: 3, label: 'NODE.DATASHEET.FIELDS.RESOURCE.HIGH' }
      ]},
    { key: 'organizational', type: 'organizational', label: 'NODE.DATASHEET.FIELDS.ORGANIZATIONAL', x: true, y: true, z: false, options: [
        { key: 0, label: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE' },
        { key: 1, label: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW' },
        { key: 2, label: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM' },
        { key: 3, label: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH' }
      ]},
    { key: 'technical', type: 'technical', label: 'NODE.DATASHEET.FIELDS.TECHNICAL', x: true, y: true, z: false, options: [
        { key: 0, label: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE' },
        { key: 1, label: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW' },
        { key: 2, label: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM' },
        { key: 3, label: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH' }
      ]},
    { key: 'budget', type: 'budget', x: false, y: false, z: true, label: 'NODE.DATASHEET.FIELDS.KPI' },
    { key: 'budgetCalculated', type: 'budgetCalculated', x: false, y: false, z: true, label: 'NODE.DATASHEET.FIELDS.AGGREGATIONKPI' },
    { key: 'benefitBudget', type: 'benefitBudget', x: false, y: false, z: true, label: 'GRAPH.BENEFITBUDGET' },
    { key: 'benefitActual', type: 'benefitActual', x: false, y: false, z: true, label: 'GRAPH.BENEFITACTUAL' },
    { key: 'benefitRemaining', type: 'benefitRemaining', x: false, y: false, z: true, label: 'GRAPH.BENEFITREMAINING' },
    { key: 'costBudget', type: 'costBudget', x: false, y: false, z: true, label: 'GRAPH.COSTBUDGET' },
    { key: 'costActual', type: 'costActual', x: false, y: false, z: true, label: 'GRAPH.COSTACTUAL' },
    { key: 'costRemaining', type: 'costRemaining', x: false, y: false, z: true, label: 'GRAPH.COSTREMAINING' },
    { key: 'investBudget', type: 'investBudget', x: false, y: false, z: true, label: 'GRAPH.INVESTBUDGET' },
    { key: 'investActual', type: 'investActual', x: false, y: false, z: true, label: 'GRAPH.INVESTACTUAL' },
    { key: 'investRemaining', type: 'investRemaining', x: false, y: false, z: true, label: 'GRAPH.INVESTREMAINING' }
  ],
  tabs: [
    {
      entry: { key: 'header1', label: 'NODE.DATASHEET.HEADERS.HEADER1' },
      children: [
        { entry: { key: 'status', controlType: 'dropdown', label: 'STATUS.TITLE', options: [
              { key: 0, value: 'STATUS.PENDING' },
              { key: 100, value: 'STATUS.INDEFINE' },
              { key: 110, value: 'STATUS.INDEVELOP' },
              { key: 120, value: 'STATUS.INVERIFY' },
              { key: 130, value: 'STATUS.INACCEPT' },
              { key: 200, value: 'STATUS.COMPLETED' }
            ]}},
        { entry: { key: 'responsibleId', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.RESPONSIBLEID', source: 'humanresources' }}
      ]
    },
    {
      entry: { key: 'group2', label: 'NODE.DATASHEET.GROUPS.GROUP2', controlType: 'group' },
      children: [
        { entry: { key: 'budget', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.KPI' }},
        { entry: { key: 'budgetCalculated', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.AGGREGATIONKPI' }}
      ]
    },
    {
      entry: { key: 'group3', label: 'NODE.DATASHEET.GROUPS.GROUP3', controlType: 'group' },
      children: [
        { entry: { key: 'benefitBudget', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.BENEFITBUDGET' }},
        { entry: { key: 'benefitActual', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.BENEFITACTUAL' }},
        { entry: { key: 'benefitRemaining', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.BENEFITREMAINING' }}
      ]
    },
    {
      entry: { key: 'group4', label: 'NODE.DATASHEET.GROUPS.GROUP4', controlType: 'group' },
      children: [
        { entry: { key: 'costBudget', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.COSTBUDGET' }},
        { entry: { key: 'costActual', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.COSTACTUAL' }},
        { entry: { key: 'costRemaining', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.COSTREMAINING' }}
      ]
    },
    {
      entry: { key: 'group5', label: 'NODE.DATASHEET.GROUPS.GROUP5', controlType: 'group' },
      children: [
        { entry: { key: 'investBudget', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.INVESTBUDGET' }},
        { entry: { key: 'investActual', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.INVESTACTUAL' }},
        { entry: { key: 'investRemaining', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.INVESTREMAINING' }}
      ]
    },
    {
      entry: { key: 'audits', label: 'NODE.DATASHEET.HEADERS.HEADER4'}
    }
  ]
};
