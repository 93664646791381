import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';

import { AppState } from '../../app.reducer';
import * as selectors from './import.selectors';
import { ImportAction } from './import.actions';

@Injectable()
export class ImportService {

  public isBusy: Observable<number>;

  public constructor(private store: Store<AppState>, private action: ImportAction) {
    this.isBusy = this.getAPIState().let(selectors.getIsBusy());
  }

  public import(request: any) {
    this.store.dispatch(this.action.import(request));
  }

  protected getAPIState() {
    return this.store.select((state: AppState) => state.api);
  }

}
