import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { TreeNode } from '../../../core/interface/core.interface';
import { environment } from '../../../../environments/environment';

export class ColorLabelProviderServiceQStatus extends ColorLabelProviderAbstract {

  private static LOW = '#9ac93e';
  private static MEDIUM = '#EDD515';
  private static HIGH = '#E50000';

  public constructor(private field: string) {
    super();
  }

  public color(element: TreeNode, value?: any) {
    if (environment.template.qstatusdiff) {
      if (element.level === 0) {
        switch (element[value]) {
          case 1:
            return [ColorLabelProviderServiceQStatus.LOW];
          case 2:
            return [ColorLabelProviderServiceQStatus.MEDIUM];
          case 3:
            return [ColorLabelProviderServiceQStatus.HIGH];
        }
      }
      if (element.level === 2) {
        switch (element.riskProfileAssessment) {
          case 1:
            return [ColorLabelProviderServiceQStatus.LOW];
          case 2:
            return [ColorLabelProviderServiceQStatus.MEDIUM];
          case 3:
            return [ColorLabelProviderServiceQStatus.HIGH];
        }
      }
    } else {
      switch (element[value]) {
        case 1:
          return [ColorLabelProviderServiceQStatus.LOW];
        case 2:
          return [ColorLabelProviderServiceQStatus.MEDIUM];
        case 3:
          return [ColorLabelProviderServiceQStatus.HIGH];
      }
    }
    return super.color();
  }

  public legend() {
    return [
      { key: 'qstatus.low', color: ColorLabelProviderServiceQStatus.LOW, label: 'LEGEND.QSTATUS.LOW', field: this.field, value: 1 },
      { key: 'qsatus.medium', color: ColorLabelProviderServiceQStatus.MEDIUM, label: 'LEGEND.QSTATUS.MEDIUM', field: this.field, value: 2 },
      { key: 'qstatus.high', color: ColorLabelProviderServiceQStatus.HIGH, label: 'LEGEND.QSTATUS.HIGH', field: this.field, value: 3 }
    ];
  }

}
