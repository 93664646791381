export const TemplateFieldsReportsEffectControlling = [
  {
    type: 'table',
    settings: {
      /* Group the elements by model */
      groupByModels: false,
      /* Caption element above the table */
      caption: { tag: 'h6', text: 'REPORTS.EFFECTCONTROLLING.CAPTION' },
      /* Virtual scrolling */
      virtualScrolling: true,
      /* Show top most header row (grouped heading) */
      showHeading: false,
      /* Show the footer */
      showFooter: true,
      /* The header text color */
      headerTextColor: '#000000',
      /* The header background color */
      headerBackgroundColor: '#ffffff',
      /* The total text color */
      totalTextColor: '#000000',
      /* The total background color */
      totalBackgroundColor: '#b7b7b7',
      /* Show total row at the end of the table */
      showTotals: true,
      /* Show table fields select box */
      showFieldsSelectBox: false,
      /* Use a thousand seperator for numeric values */
      thousandsSeperator: '´',
      /* Default wizard flow */
      wizardFlow: 'add.node.level',
      /* Add button label */
      wizardButtonLabel: 'REPORTS.EFFECTCONTROLLING.ADD',
      /* Add button level */
      wizardVariables: { 'select.level': [{ id: 2, answer: '' }] },
      /* Break the table vertically among pages */
      verticalPageBreak: true,
      /* Exports */
      exports: [], //  excel, pdf
      /* Is Row Span being used */
      maxRowSpanUsed: 4,
    },
    configuration: {
      fields: [
        { entry: { key: 'wizard', label: '', controlType: 'wizard', source: 'delete', icon: 'times', editable: true, fixedWidth: 30, rowspan: 4, wizardColor: '#ffffff', wizardBackground: '#d9534f', nodeSource: 'measurement', border: '0', fixed: true, background: '#ffffff' }},
        { entry: { key: 'wizard', label: '', controlType: 'wizard', source: 'duplicate', icon: 'copy', editable: true, fixedWidth: 30, rowspan: 4, wizardColor: '#ffffff', wizardBackground: '#5bc0de', nodeSource: 'measurement', border: '0', fixed: true, background: '#ffffff' }},
        { entry: { key: 'A', label: '', controlType: 'group', border: '0', background: '#ffffff'}, children: [
          { entry: { key: 'B', label: '', controlType: 'group', border: '0', background: '#ffffff', fixed: true, left: 60 }, children: [
            { entry: { key: 'model', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.INITIATIVE', calculated: true, editable: false, rowspan: 3, styles: ['border-bottom: 2px solid #333333'], fixed: true, fixedWidth: 70, left: 60 }},
            { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.MASSNAHMEN', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME', rowspan: 3, nodeSource: 'measurement', fixed: true, fixedWidth: 100, left: 130, styles: ['border-bottom: 2px solid #333333']}}
          ]}
        ]},
        { entry: { key: '', label: '', controlType: 'group', border: '0'}, children: [
          { entry: { key: '', label: 'REPORTS.EFFECTCONTROLLING.PRIMARYCOSTVIEW', controlType: 'group', pdfWidth: 70, background: '#afc5e0', fixed: true, left: 230 }, children: [
            { entry: { key: 'businesscalculation', controlType: 'dropdown', label: 'REPORTS.EFFECTCONTROLLING.DB.TITLE', rowspan: 3, pdfWidth: 70, fixed: true, fixedWidth: 100, left: 230, styles: ['border-bottom: 2px solid #333333'], options: [
              { key: 'null', value: 'REPORTS.EFFECTCONTROLLING.DB.D0'},
              { key: 2, value: 'REPORTS.EFFECTCONTROLLING.DB.D2'},
              { key: 3, value: 'REPORTS.EFFECTCONTROLLING.DB.D3'},
              { key: 5, value: 'REPORTS.EFFECTCONTROLLING.DB.D5'},
              { key: 4, value: 'REPORTS.EFFECTCONTROLLING.DB.D4'},
              { key: 6, value: 'REPORTS.EFFECTCONTROLLING.DB.D6'},
              { key: 7, value: 'REPORTS.EFFECTCONTROLLING.DB.D7'},
              { key: 8, value: 'REPORTS.EFFECTCONTROLLING.DB.D8'},
              { key: 9, value: 'REPORTS.EFFECTCONTROLLING.DB.D9'},
              { key: 10, value: 'REPORTS.EFFECTCONTROLLING.DB.D10'},
              { key: 11, value: 'REPORTS.EFFECTCONTROLLING.DB.D11'},
              { key: 12, value: 'REPORTS.EFFECTCONTROLLING.DB.D12'},
              { key: 13, value: 'REPORTS.EFFECTCONTROLLING.DB.D13'}
            ]}},
            { entry: { key: 'costtype', controlType: 'dropdown', label: 'REPORTS.EFFECTCONTROLLING.CT.TITLE', rowspan: 3, pdfWidth: 70, fixed: true, fixedWidth: 100, left: 330, styles: ['border-bottom: 2px solid #333333'], options: [
              { key: 1, value: 'REPORTS.EFFECTCONTROLLING.CT.1'},
              { key: 3, value: 'REPORTS.EFFECTCONTROLLING.CT.3'},
              { key: 40, value: 'REPORTS.EFFECTCONTROLLING.CT.40'},
              { key: 41, value: 'REPORTS.EFFECTCONTROLLING.CT.41'},
              { key: 43, value: 'REPORTS.EFFECTCONTROLLING.CT.43'},
              { key: 440, value: 'REPORTS.EFFECTCONTROLLING.CT.440'},
              { key: 441, value: 'REPORTS.EFFECTCONTROLLING.CT.441'},
              { key: 45, value: 'REPORTS.EFFECTCONTROLLING.CT.45'},
              { key: 46, value: 'REPORTS.EFFECTCONTROLLING.CT.46'},
              { key: 47, value: 'REPORTS.EFFECTCONTROLLING.CT.47'},
              { key: 48, value: 'REPORTS.EFFECTCONTROLLING.CT.48'}
            ]}},
            { entry: { key: 'complexity', controlType: 'dropdown', label: 'REPORTS.EFFECTCONTROLLING.POTENTIAL.TITLE', rowspan: 3, pdfWidth: 70, fixed: true, fixedWidth: 100, left: 430, styles: ['border-bottom: 2px solid #333333'], options: [
              { key: 0, value: 'REPORTS.EFFECTCONTROLLING.POTENTIAL.P0'},
              { key: 1, value: 'REPORTS.EFFECTCONTROLLING.POTENTIAL.P1'},
              { key: 2, value: 'REPORTS.EFFECTCONTROLLING.POTENTIAL.P2'},
              { key: 3, value: 'REPORTS.EFFECTCONTROLLING.POTENTIAL.P3'},
              { key: 4, value: 'REPORTS.EFFECTCONTROLLING.POTENTIAL.P4'},
              { key: 5, value: 'REPORTS.EFFECTCONTROLLING.POTENTIAL.P5'},
              { key: 6, value: 'REPORTS.EFFECTCONTROLLING.POTENTIAL.P6'}
            ]}},
          ]}
        ]},
        { entry: { key: '', label: '', controlType: 'group', border: '0'}, children: [
          { entry: { key: '', label: '', controlType: 'group', border: '0', pdfWidth: 10, fixed: true, fixedWidth: 10, left: 530 }, children: [
            { entry: { key: 'string#s1', label: '', controlType: 'string', editable: false, rowspan: 3, pdfWidth: 10, fixed: true, fixedWidth: 10, left: 530, calculated: true, styles: ['border-top: 0']}}
          ]}
        ]},
        { entry: { key: '', label: '', controlType: 'group', border: '0'}, children: [
          { entry: { key: '', label: '', controlType: 'group', colspan: 2, pdfWidth: 50, border: '0', fixed: true, left: 541, background: '#ffffff' }, children: [
            { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.AUSGANGSLAGE', params: 'Y-2', background: '#aed9e5', pdfWidth: 50, fixed: true, left: 541 }, children: [
              { entry: { key: 'investActual', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.BETRAGIST', pdfWidth: 50, rowspan: 2, fixed: true, fixedWidth: 75, left: 541, styles: ['border-bottom: 2px solid #333333'] }},
              { entry: { key: 'description1', controlType: 'text', label: 'REPORTS.EFFECTCONTROLLING.BESCHREIBUNG', pdfWidth: 50, rowspan: 2, fixed: true, fixedWidth: 75, left: 616, styles: ['border-bottom: 2px solid #333333'] }}
            ]},
          ]}
        ]},
        { entry: { key: '', label: '', controlType: 'group', border: '0'}, children: [
          { entry: { key: '', label: '', controlType: 'group', pdfWidth: 10, border: '0', fixed: true, fixedWidth: 10, left: 691 }, children: [
              { entry: { key: 'string#s2', label: '', controlType: 'string', editable: false, rowspan: 3, pdfWidth: 10, calculated: true, styles: ['border-top: 0'], fixed: true, fixedWidth: 10, left: 691}}
          ]}
        ]},
        { entry: { key: '', label: '', controlType: 'group', border: '0'}, children: [
          { entry: { key: '', label: '', controlType: 'group', colspan: 2, border: '0', pdfWidth: 50, background: '#ffffff', fixed: true, left: 702 }, children: [
            { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.GROBANALYSE', pdfWidth: 50, fixed: true, left: 702 }, children: [
              { entry: { key: 'investBudget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.POTENZIAL', pdfWidth: 50, rowspan: 2, fixed: true, fixedWidth: 75, left: 702, styles: ['border-bottom: 2px solid #333333'] }},
              { entry: { key: 'description2', controlType: 'text', label: 'REPORTS.EFFECTCONTROLLING.BERECHNUNGSLOGIK', pdfWidth: 50, rowspan: 2, fixed: true, fixedWidth: 75, left: 777, styles: ['border-bottom: 2px solid #333333'] }}
            ]},
          ]}
        ]},
        { entry: { key: '', label: '', controlType: 'group', border: '0'}, children: [
          { entry: { key: '', label: '', controlType: 'group', pdfWidth: 10, border: '0', fixed: true, fixedWidth: 10, left: 852 }, children: [
            { entry: { key: 'string#s3', label: '', controlType: 'string', editable: false, rowspan: 3, pdfWidth: 10, calculated: true, fixed: true, fixedWidth: 10, left: 852, styles: ['border-top: 0']}}
          ]}
        ]},
        { entry: { key: '', label: '', controlType: 'group', border: '0'}, children: [
          { entry: { key: '', label: '', controlType: 'group', colspan: 2, pdfWidth: 50, border: '0', background: '#ffffff', fixed: true, left: 863 }, children: [
            { entry: { key: '', controlType: 'group', pdfWidth: 50, label: 'REPORTS.EFFECTCONTROLLING.DETAILANALYE', fixed: true, left: 863 }, children: [
              { entry: { key: 'investRemaining', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.POTENZIAL', pdfWidth: 50, rowspan: 2, fixed: true, fixedWidth: 75, left: 863, styles: ['border-bottom: 2px solid #333333'] }},
              { entry: { key: 'description3', controlType: 'text', label: 'REPORTS.EFFECTCONTROLLING.BERECHNUNGSLOGIK', pdfWidth: 50, rowspan: 2, fixed: true, fixedWidth: 75, left: 938, styles: ['border-bottom: 2px solid #333333'] }}
            ]},
          ]}
        ]},
        { entry: { key: '', label: '', controlType: 'group', border: '0'}, children: [
          { entry: { key: '', label: '', controlType: 'group', pdfWidth: 10, border: '0', fixed: true, fixedWidth: 10, left: 1013 }, children: [
            { entry: { key: 'string#s4', label: '', controlType: 'string', editable: false, rowspan: 3, pdfWidth: 10, calculated: true, fixed: true, fixedWidth: 10, left: 1013, styles: ['border-top: 0']}}
          ]}
        ]},
        { entry: { key: '', label: '', controlType: 'group', border: '0'}, children: [
          { entry: { key: '', label: 'REPORTS.EFFECTCONTROLLING.SECONDARYCOSTVIEW', controlType: 'group', pdfWidth: 50, background: '#afc5e0'}, children: [
            { entry: { key: 'wirkung', type: 'text', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.WIRKUNG', calculated: true, pdfWidth: 50, editable: false, rowspan: 3, styles: ['border-bottom: 2px solid #333333'] }},
            { entry: { key: 'category', controlType: 'dropdown', label: 'REPORTS.EFFECTCONTROLLING.CATEGORY.TITLE', hasNull: true, rowspan: 3, pdfWidth: 50, calculated: true, editable: false, styles: ['border-bottom: 2px solid #333333'], options: [
              { key: 0, value: ''},
              { key: 1, value: 'REPORTS.EFFECTCONTROLLING.CATEGORY.DIVISION'},
              { key: 2, value: 'REPORTS.EFFECTCONTROLLING.CATEGORY.NEUTRAL'},
              { key: 3, value: 'REPORTS.EFFECTCONTROLLING.CATEGORY.CENTRAL'}
            ]}},
          ]}
        ]},
        { entry: { key: '', label: '', controlType: 'group', border: '0'}, children: [
          { entry: { key: '', label: '', controlType: 'group', pdfWidth: 10, border: '0' }, children: [
            { entry: { key: 'string', label: '', controlType: 'string', editable: false, rowspan: 3, pdfWidth: 10, calculated: true, styles: ['border-top: 0']}}
          ]}
        ]},
        { entry: { key: '', label: '', controlType: 'group', border: '0' }, children: [
          { entry: { key: '', label: '', controlType: 'group', pdfWidth: 50, border: '0' }, children: [
            { entry: { key: 'weight', type: 'number', controlType: 'textbox', showTotal: false, label: 'REPORTS.EFFECTCONTROLLING.VERTEILSCHLUESSEL', pdfWidth: 50, editable: false, calculated: true, rowspan: 3, suffix: '%', styles: ['border-bottom: 2px solid #333333'] }}
          ]}
        ]},
        { entry: { key: '', label: '', controlType: 'group', border: '0'}, children: [
          { entry: { key: '', label: '', controlType: 'group', pdfWidth: 20, border: '0' }, children: [
            { entry: { key: 'secondarycosts', label: '', controlType: 'secondarycosts', rowspan: 3, pdfWidth: 20, styles: ['border-top: 0']}}
          ]}
        ]},
        { entry: { key: '', label: '', controlType: 'group', border: '0'}, children: [
          { entry: { key: '', label: 'REPORTS.EFFECTCONTROLLING.SAVINGS', params: 'Y-2', controlType: 'group', colspan: 21, border: '0', pdfWidth: 56.875, background: '#d3e0b4' }, children: [
            // { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.EINZELJAHR', params: 'Y-1', background: '#c5b8d5', pdfWidth: 60, colspan: 10 }, children: [
            //   { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q1', pdfWidth: 60, background: '#bdd4ef' }, children: [
            //     { entry: { key: 'budgetPlan#y1q1', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q1-Y-1', styles: ['border-bottom: 2px solid #333333'] }},
            //     { entry: { key: 'budget#y1q1', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, editable: false, pdfWidth: 60, nodeBucketId: 'Q1-Y-1', calculated: true, styles: ['border-bottom: 2px solid #333333'] }},
            //   ]},
            //   { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q2', pdfWidth: 60, background: '#bdd4ef' }, children: [
            //     { entry: { key: 'budgetPlan#y1q2', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q2-Y-1', styles: ['border-bottom: 2px solid #333333'] }},
            //     { entry: { key: 'budget#y1q2', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, editable: false, pdfWidth: 60, nodeBucketId: 'Q2-Y-1', calculated: true, styles: ['border-bottom: 2px solid #333333'] }},
            //   ]},
            //   { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q3', pdfWidth: 60, background: '#bdd4ef' }, children: [
            //     { entry: { key: 'budgetPlan#y1q3', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q3-Y-1', styles: ['border-bottom: 2px solid #333333'] }},
            //     { entry: { key: 'budget#y1q3', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, editable: false, pdfWidth: 60, nodeBucketId: 'Q3-Y-1', calculated: true, styles: ['border-bottom: 2px solid #333333'] }},
            //   ]},
            //   { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q4', pdfWidth: 60, background: '#bdd4ef' }, children: [
            //     { entry: { key: 'budgetPlan#y1q4', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q4-Y-1', styles: ['border-bottom: 2px solid #333333'] }},
            //     { entry: { key: 'budget#y1q4', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, editable: false, pdfWidth: 60, nodeBucketId: 'Q4-Y-1', calculated: true, styles: ['border-bottom: 2px solid #333333'] }},
            //   ]},
            //   { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.KUMULIERT', styles: ['font-weight: bold'], pdfWidth: 60, background: '#fccfab' }, children: [
            //     { entry: { key: 'ziel#y1cu', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucketId: 'Y-1', pdfWidth: 60, calculated: true, editable: false, styles: ['border-bottom: 2px solid #333333'], background: '#ffe2c6', fieldBackground: '#ffe2c6' }},
            //     { entry: { key: 'ist#y1cu', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucketId: 'Y-1', pdfWidth: 60, calculated: true, editable: false, styles: ['border-bottom: 2px solid #333333'], background: '#ffe2c6', fieldBackground: '#ffe2c6'  }}
            //   ]}
            // ]},
            // { entry: { key: '', label: '', controlType: '', editable: false, pdfWidth: 10, rowspan: 3 }},
            { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.EINZELJAHR', params: 'Y', background: '#c5b8d5', pdfWidth: 60, colspan: 10 }, children: [
              { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q1', pdfWidth: 60, background: '#bdd4ef' }, children: [
                { entry: { key: 'budgetPlan#y2q1', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q1-Y', styles: ['border-bottom: 2px solid #333333'] }},
                { entry: { key: 'budget#y2q1', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q1-Y', styles: ['border-bottom: 2px solid #333333'] }},
              ]},
              { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q2', pdfWidth: 60, background: '#bdd4ef' }, children: [
                { entry: { key: 'budgetPlan#y2q2', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q2-Y', styles: ['border-bottom: 2px solid #333333'] }},
                { entry: { key: 'budget#y2q2', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q2-Y', styles: ['border-bottom: 2px solid #333333'] }},
              ]},
              { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q3', pdfWidth: 60, background: '#bdd4ef' }, children: [
                { entry: { key: 'budgetPlan#y2q3', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q3-Y', styles: ['border-bottom: 2px solid #333333'] }},
                { entry: { key: 'budget#y2q3', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q3-Y', styles: ['border-bottom: 2px solid #333333'] }},
              ]},
              { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q4', pdfWidth: 60, background: '#bdd4ef' }, children: [
                { entry: { key: 'budgetPlan#y2q4', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q4-Y', styles: ['border-bottom: 2px solid #333333'] }},
                { entry: { key: 'budget#y2q4', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q4-Y', styles: ['border-bottom: 2px solid #333333'] }},
              ]},
              { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.KUMULIERT', pdfWidth: 60, styles: ['font-weight: bold'], background: '#fccfab' }, children: [
                { entry: { key: 'ziel#y2cu', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucketId: 'Y', pdfWidth: 60, calculated: true, editable: false, styles: ['border-bottom: 2px solid #333333'], background: '#ffe2c6', fieldBackground: '#ffe2c6' }},
                { entry: { key: 'ist#y2cu', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucketId: 'Y', pdfWidth: 60, calculated: true, editable: false, styles: ['border-bottom: 2px solid #333333'], background: '#ffe2c6', fieldBackground: '#ffe2c6'  }}
              ]}
            ]},
            { entry: { key: '', label: '', controlType: '', editable: false, pdfWidth: 10, rowspan: 3 }},
            { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.EINZELJAHR', params: 'Y+1', background: '#c5b8d5', pdfWidth: 60, colspan: 10 }, children: [
              { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q1', pdfWidth: 60, background: '#bdd4ef' }, children: [
                { entry: { key: 'budgetPlan#y3q1', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q1-Y+1', styles: ['border-bottom: 2px solid #333333'] }},
                { entry: { key: 'budget#y3q1', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q1-Y+1', styles: ['border-bottom: 2px solid #333333'] }},
              ]},
              { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q2', pdfWidth: 60, background: '#bdd4ef' }, children: [
                { entry: { key: 'budgetPlan#y3q2', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q2-Y+1', styles: ['border-bottom: 2px solid #333333'] }},
                { entry: { key: 'budget#y3q2', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q2-Y+1', styles: ['border-bottom: 2px solid #333333'] }},
              ]},
              { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q3', pdfWidth: 60, background: '#bdd4ef' }, children: [
                { entry: { key: 'budgetPlan#y3q3', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q3-Y+1', styles: ['border-bottom: 2px solid #333333'] }},
                { entry: { key: 'budget#y3q3', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q3-Y+1', styles: ['border-bottom: 2px solid #333333'] }},
              ]},
              { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q4', pdfWidth: 60, background: '#bdd4ef' }, children: [
                { entry: { key: 'budgetPlan#y3q4', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q4-Y+1', styles: ['border-bottom: 2px solid #333333'] }},
                { entry: { key: 'budget#y3q4', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q4-Y+1', styles: ['border-bottom: 2px solid #333333'] }},
              ]},
              { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.KUMULIERT', pdfWidth: 60, styles: ['font-weight: bold'], background: '#fccfab' }, children: [
                { entry: { key: 'ziel#y3cu', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucketId: 'Y+1', calculated: true, pdfWidth: 60, editable: false, styles: ['border-bottom: 2px solid #333333'], background: '#ffe2c6', fieldBackground: '#ffe2c6' }},
                { entry: { key: 'ist#y3cu', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucketId: 'Y+1', calculated: true, pdfWidth: 60, editable: false, styles: ['border-bottom: 2px solid #333333'], background: '#ffe2c6', fieldBackground: '#ffe2c6'  }}
              ]}
            ]},
          ]}
        ]},
      ],
      additionalfields: [
        { entry: { key: 'cost', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL' }}
      ]
    }
  }
];
