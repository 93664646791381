export const TemplateFieldsRadial = {
  levels: [
    {
      entry: { key: 'default' },
      items: [
        { entry: { key: 'default.display', label: 'RADIAL.DEFAULT.DISPLAY.TITLE', sub: 'default.display' }},
        { entry: { key: 'default.action', label: 'RADIAL.DEFAULT.ACTION.TITLE', sub: 'default.action' }},
        { entry: { key: 'default.splitscreen', label: 'RADIAL.DEFAULT.SPLITSCREEN.TITLE', sub: 'default.splitscreen' }},
        { entry: { key: 'default.settings', label: 'RADIAL.DEFAULT.SETTINGS.TITLE', sub: 'default.settings' }},
      ]
    },
    {
      entry: { key: 'default.splitscreen' },
      items: [
        { entry: { key: 'default.splitscreen.nodes', label: 'RADIAL.DEFAULT.SPLITSCREEN.NODES.TITLE', sub: 'default.splitscreen.nodes' }},
        { entry: { key: 'default.splitscreen.activity', label: 'RADIAL.DEFAULT.SPLITSCREEN.ACTIVITIES.TITLE', sub: 'default.splitscreen.activities' }},
        { entry: { key: 'default.splitscreen.report', label: 'RADIAL.DEFAULT.SPLITSCREEN.REPORT.TITLE', sub: 'default.splitscreen.report' }}
      ]
    },
    {
      entry: { key: 'default.splitscreen.activities' },
      items: [
        { entry: { key: 'default.splitscreen.activities.list', label: 'RADIAL.DEFAULT.SPLITSCREEN.ACTIVITIES.LIST', event: 'default.splitscreen.activities', subevent: 'list' }}
      ]
    },
    {
      entry: { key: 'default.splitscreen.nodes' },
      items: [
        { entry: { key: 'default.splitscreen.nodes.canvas', label: 'RADIAL.DEFAULT.SPLITSCREEN.NODES.CANVAS', event: 'default.splitscreen.nodes.canvas' }},
        { entry: { key: 'default.splitscreen.nodes.graph', label: 'RADIAL.DEFAULT.SPLITSCREEN.NODES.GRAPH', event: 'default.splitscreen.nodes.graph' }},
        { entry: { key: 'default.splitscreen.nodes.mcfarlan', label: 'RADIAL.DEFAULT.SPLITSCREEN.NODES.MCFARLAN', event: 'default.splitscreen.nodes.graph', subevent: 'mcfarlan' }},
        { entry: { key: 'default.splitscreen.nodes.time', label: 'RADIAL.DEFAULT.SPLITSCREEN.NODES.TIME', event: 'default.splitscreen.nodes.time' }}
      ]
    },
    {
      entry: { key: 'default.settings' },
      items: [
        { entry: { key: 'default.settings.editmode', label: 'RADIAL.DEFAULT.SETTINGS.EDITMODE', event: 'default.settings.editmode', toggle: true }},
        { entry: { key: 'default.settings.pointermode', label: 'RADIAL.DEFAULT.SETTINGS.POINTERMODE', event: 'default.settings.pointermode', toggle: true }},
        { entry: { key: 'default.settings.directchain', label: 'RADIAL.DEFAULT.SETTINGS.DIRECTCHAIN', event: 'default.settings.directchain', toggle: true }},
        { entry: { key: 'default.settings.subtree', label: 'RADIAL.DEFAULT.SETTINGS.SUBTREE', event: 'default.settings.subtree', toggle: true }}
      ]
    },
    {
      entry: { key: 'default.action' },
      items: [
        { entry: { key: 'default.action.select', label: 'RADIAL.DEFAULT.ACTION.SELECT.TITLE', sub: 'default.action.select' }},
        { entry: { key: 'default.action.filter', label: 'RADIAL.DEFAULT.ACTION.FILTER.TITLE', sub: 'default.action.filter' }},
        { entry: { key: 'default.action.import', label: 'RADIAL.DEFAULT.ACTION.IMPORT', event: 'default.action.import' }},
        // { entry: { key: 'default.action.importfromlibrary', label: 'RADIAL.DEFAULT.ACTION.IMPORTFROMLIBRARY', event: 'default.action.importfromlibrary' }},
        { entry: { key: 'default.action.paste', label: 'RADIAL.DEFAULT.ACTION.PASTE.TITLE', sub: 'default.action.paste', enabled: 'hasCopiedNodes' }}
      ]
    },
    {
      entry: { key: 'default.action.paste' },
      items: [
        { entry: { key: 'default.action.paste.paste', label: 'RADIAL.DEFAULT.ACTION.PASTE.PASTE', event: 'default.action.paste', subevent: '' }},
        { entry: { key: 'default.action.paste.select', label: 'RADIAL.DEFAULT.ACTION.PASTE.SELECT', event: 'default.action.paste', subevent: 'select' }}
      ]
    },
    {
      entry: { key: 'default.action.select' },
      items: [
        { entry: { key: 'default.action.select.level', label: 'RADIAL.DEFAULT.ACTION.SELECT.LEVELS', sub: 'default.action.select.level' }},
        { entry: { key: 'default.action.select.all', label: 'RADIAL.DEFAULT.ACTION.SELECT.ALL', event: 'default.action.select.all' }}
      ]
    },
    {
      entry: { key: 'default.action.select.level' },
      items: [
        { entry: { key: 'default.action.select.level.objective', label: 'LEVEL.OBJECTIVE', event: 'default.action.select.level', subevent: 'objective' }},
        { entry: { key: 'default.action.select.level.challenge', label: 'LEVEL.CHALLENGE', event: 'default.action.select.level', subevent: 'challenge' }},
        { entry: { key: 'default.action.select.level.solution', label: 'LEVEL.SOLUTION', event: 'default.action.select.level', subevent: 'solution' }},
        { entry: { key: 'default.action.select.level.deliverable', label: 'LEVEL.DELIVERABLE', event: 'default.action.select.level', subevent: 'deliverable' }}
      ]
    },
    {
      entry: { key: 'default.action.filter' },
      items: [
        { entry: { key: 'default.action.filter.closed', label: 'RADIAL.DEFAULT.ACTION.FILTER.CLOSED', event: 'default.action.filter', subevent: 'closed', toggle: true }},
        { entry: { key: 'default.action.filter.active', label: 'RADIAL.DEFAULT.ACTION.FILTER.ACTIVE', event: 'default.action.filter', subevent: 'active', toggle: true }},
        { entry: { key: 'default.action.filter.notclosed', label: 'RADIAL.DEFAULT.ACTION.FILTER.NOTCLOSED', event: 'default.action.filter', subevent: 'notclosed', toggle: true }}
      ]
    },
    {
      entry: { key: 'default.display' },
      items: [
        { entry: { key: 'default.display.color', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.TITLE', sub: 'default.display.color' }},
        { entry: { key: 'default.display.label', label: 'RADIAL.DEFAULT.DISPLAY.LABEL.TITLE', sub: 'default.display.label' }},
        { entry: { key: 'default.display.weights', label: 'RADIAL.DEFAULT.DISPLAY.WEIGHTS', event: 'default.display.weights', toggle: true }},
        { entry: { key: 'default.display.conditions', label: 'RADIAL.DEFAULT.DISPLAY.CONDITIONS', event: 'default.display.conditions', toggle: true }},
        { entry: { key: 'default.display.tooltip', label: 'RADIAL.DEFAULT.DISPLAY.TOOLTIP.TITLE', sub: 'default.display.tooltip' }}
      ]
    },
    {
      entry: { key: 'default.display.tooltip' },
      items: [
        { entry: { key: 'default.display.tooltip.notapplicable', label: 'RADIAL.DEFAULT.DISPLAY.TOOLTIP.NOTAPPLICABLE', event: 'default.display.tooltip', subevent: '', radio: true, radioDefault: true }},
        { entry: { key: 'default.display.tooltip.name', label: 'NODE.DATASHEET.FIELDS.NAME', event: 'default.display.tooltip', subevent: 'name', radio: true }},
        { entry: { key: 'default.display.tooltip.description', label: 'NODE.DATASHEET.FIELDS.DESCRIPTION', event: 'default.display.tooltip', subevent: 'description', radio: true }},
        { entry: { key: 'default.display.tooltip.crossreference', label: 'NODE.DATASHEET.FIELDS.CROSSREFERENCE', event: 'default.display.tooltip', subevent: 'crossReference', radio: true }}
      ]
    },
    {
      entry: { key: 'default.display.color.qstatus' },
      items: [
        { entry: { key: 'default.display.color.qstatus.q1', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.QSTATUS.Q1', event: 'default.display.color', subevent: 'qstatus.q1', radio: true, radioParentKey: 'default.display.color.qstatus' }},
        { entry: { key: 'default.display.color.qstatus.q2', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.QSTATUS.Q2', event: 'default.display.color', subevent: 'qstatus.q2', radio: true, radioParentKey: 'default.display.color.qstatus' }},
        { entry: { key: 'default.display.color.qstatus.q3', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.QSTATUS.Q3', event: 'default.display.color', subevent: 'qstatus.q3', radio: true, radioParentKey: 'default.display.color.qstatus' }},
        { entry: { key: 'default.display.color.qstatus.q4', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.QSTATUS.Q4', event: 'default.display.color', subevent: 'qstatus.q4', radio: true, radioParentKey: 'default.display.color.qstatus' }}
      ]
    },
    {
      entry: { key: 'default.display.color' },
      items: [
        { entry: { key: 'default.display.color.notapplicable', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.NOTAPPLICABLE', event: 'default.display.color', subevent: 'notapplicable', radio: true, radioDefault: true, alignedLabel: true }},
        { entry: { key: 'default.display.color.status', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.STATUS', event: 'default.display.color', subevent: 'status', radio: true, alignedLabel: true }},
        { entry: { key: 'default.display.color.sidestep', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.SIDESTEP', event: 'default.display.color', subevent: 'sidestep', radio: true, alignedLabel: true }},
        { entry: { key: 'default.display.color.targetDate', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.TARGETDATE', event: 'default.display.color', subevent: 'targetDate', radio: true, alignedLabel: true }},
        { entry: { key: 'default.display.color.models', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.MODELS', event: 'default.display.color', subevent: 'models', radio: true, alignedLabel: true }},
        { entry: { key: 'default.display.color.submodels', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.SUBMODELS', event: 'default.display.color', subevent: 'submodels', radio: true, alignedLabel: true }},
        { entry: { key: 'default.display.color.subsets', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.SUBSETS', event: 'default.display.color', subevent: 'subsets', radio: true, alignedLabel: true }},
        { entry: { key: 'default.display.color.responsible', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.RESPONSIBLE', event: 'default.display.color', subevent: 'responsible', radio: true, alignedLabel: true }},
        { entry: { key: 'default.display.color.difference', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.DIFFERENCE', event: 'default.display.color', subevent: 'difference', radio: true, alignedLabel: true }},
        { entry: { key: 'default.display.color.todostatus', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.TODOSTATUS', event: 'default.display.color', subevent: 'todostatus', radio: true, alignedLabel: true }},
        { entry: { key: 'default.display.color.importance', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.IMPORTANCE', event: 'default.display.color', subevent: 'importance', radio: true, alignedLabel: true }},
        { entry: { key: 'default.display.color.risk', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.RISK', event: 'default.display.color', subevent: 'risk', radio: true, alignedLabel: true }},
        { entry: { key: 'default.display.color.qstatus', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.QSTATUS.TITLE', sub: 'default.display.color.qstatus', event: 'default.display.color', radio: true, radioParent: true, alignedLabel: true }},
        { entry: { key: 'default.display.color.relatedstatus', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.RELATEDSTATUS', event: 'default.display.color', subevent: 'relatedstatus', radio: true, alignedLabel: true }}
      ]
    },
    {
      entry: { key: 'default.display.label' },
      items: [
        { entry: { key: 'default.display.label.top', label: 'RADIAL.DEFAULT.DISPLAY.LABEL.TOP.TITLE', sub: 'default.display.label.top' }},
        { entry: { key: 'default.display.label.bottom', label: 'RADIAL.DEFAULT.DISPLAY.LABEL.BOTTOM.TITLE', sub: 'default.display.label.bottom' }}
      ]
    },
    {
      entry: { key: 'default.display.label.top' },
      items: [
        { entry: { key: 'default.display.label.top.notapplicable' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.TOP.NOTAPPLICABLE', event: 'default.display.label.top', subevent: '', radio: true, radioDefault: true, alignedLabel: true }},
        { entry: { key: 'default.display.label.top.targetdate' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.TOP.TARGETDATE', event: 'default.display.label.top', subevent: 'targetDate', radio: true, alignedLabel: true }},
        { entry: { key: 'default.display.label.top.status' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.TOP.STATUS', event: 'default.display.label.top', subevent: 'status', radio: true, alignedLabel: true }},
        { entry: { key: 'default.display.label.top.budget' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.TOP.KPI', event: 'default.display.label.top', subevent: 'budget', radio: true, alignedLabel: true }},
        { entry: { key: 'default.display.label.top.reference' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.TOP.REFERENCE', event: 'default.display.label.top', subevent: 'reference', radio: true, alignedLabel: true }},
        { entry: { key: 'default.display.label.top.crossReference' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.TOP.CROSSREFERENCE', event: 'default.display.label.top', subevent: 'crossReference', radio: true, alignedLabel: true }},
        { entry: { key: 'default.display.label.top.description' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.TOP.DESCRIPTION', event: 'default.display.label.top', subevent: 'description', radio: true, alignedLabel: true }},
        { entry: { key: 'default.display.label.top.budgetCalculated' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.TOP.BUDGETCALCULATED', event: 'default.display.label.top', subevent: 'budgetCalculated', radio: true, alignedLabel: true }},
        { entry: { key: 'default.display.label.top.benefit' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.TOP.BENEFIT', sub: 'default.display.label.top.benefit', radioParent: true, alignedLabel: true }},
        { entry: { key: 'default.display.label.top.opex' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.TOP.OPEX', sub: 'default.display.label.top.opex', radioParent: true, alignedLabel: true }},
        { entry: { key: 'default.display.label.top.capex' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.TOP.CAPEX', sub: 'default.display.label.top.capex', radioParent: true, alignedLabel: true }},
        { entry: { key: 'default.display.label.top.profit' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.TOP.PROFIT', sub: 'default.display.label.top.profit', radioParent: true, alignedLabel: true }},
      ]
    },
    {
      entry: { key: 'default.display.label.top.benefit' },
      items: [
        { entry: { key: 'default.display.label.top.benefit.budget' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.TOP.BENEFITBUDGET', event: 'default.display.label.top', subevent: 'benefitBudget', radio: true }},
        { entry: { key: 'default.display.label.top.benefit.actual' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.TOP.BENEFITACTUAL', event: 'default.display.label.top', subevent: 'benefitActual', radio: true }},
        { entry: { key: 'default.display.label.top.benefit.remaining' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.TOP.BENEFITREMAINING', event: 'default.display.label.top', subevent: 'benefitRemaining', radio: true }},
        { entry: { key: 'default.display.label.top.benefit.deviation' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.TOP.BENEFITDEVIATION', event: 'default.display.label.top', subevent: 'benefitDeviation', radio: true }}
      ]
    },
    {
      entry: { key: 'default.display.label.top.opex' },
      items: [
        { entry: { key: 'default.display.label.top.opex.budget' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.TOP.COSTBUDGET', event: 'default.display.label.top', subevent: 'costBudget', radio: true }},
        { entry: { key: 'default.display.label.top.opex.actual' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.TOP.COSTACTUAL', event: 'default.display.label.top', subevent: 'costActual', radio: true }},
        { entry: { key: 'default.display.label.top.opex.remaining' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.TOP.COSTREMAINING', event: 'default.display.label.top', subevent: 'costRemaining', radio: true }},
        { entry: { key: 'default.display.label.top.opex.deviation' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.TOP.COSTDEVIATION', event: 'default.display.label.top', subevent: 'costDeviation', radio: true }}
      ]
    },
    {
      entry: { key: 'default.display.label.top.capex' },
      items: [
        { entry: { key: 'default.display.label.top.capex.budget' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.TOP.INVESTBUDGET', event: 'default.display.label.top', subevent: 'investBudget', radio: true }},
        { entry: { key: 'default.display.label.top.capex.actual' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.TOP.INVESTACTUAL', event: 'default.display.label.top', subevent: 'investActual', radio: true }},
        { entry: { key: 'default.display.label.top.capex.remaining' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.TOP.INVESTREMAINING', event: 'default.display.label.top', subevent: 'investRemaining', radio: true }},
        { entry: { key: 'default.display.label.top.capex.deviation' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.TOP.INVESTDEVIATION', event: 'default.display.label.top', subevent: 'investDeviation', radio: true }}
      ]
    },
    {
      entry: { key: 'default.display.label.top.profit' },
      items: [
        { entry: { key: 'default.display.label.top.profit.budget' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.TOP.PROFITBUDGET', event: 'default.display.label.top', subevent: 'budgetProfit', radio: true }},
        { entry: { key: 'default.display.label.top.profit.actual' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.TOP.PROFITACTUAL', event: 'default.display.label.top', subevent: 'actualProfit', radio: true }},
        { entry: { key: 'default.display.label.top.profit.remaining' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.TOP.PROFITREMAINING', event: 'default.display.label.top', subevent: 'remainingProfit', radio: true }},
        { entry: { key: 'default.display.label.top.profit.deviation' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.TOP.PROFITDEVIATION', event: 'default.display.label.top', subevent: 'deviationProfit', radio: true }}
      ]
    },
    {
      entry: { key: 'default.display.label.bottom' },
      items: [
        { entry: { key: 'default.display.label.bottom.notapplicable' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.BOTTOM.NAME', event: 'default.display.label.bottom', subevent: 'name', radio: true, radioDefault: true }},
        { entry: { key: 'default.display.label.bottom.description' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.BOTTOM.DESCRIPTION', event: 'default.display.label.bottom', subevent: 'description', radio: true }},
        { entry: { key: 'default.display.label.bottom.ref' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.BOTTOM.REF', event: 'default.display.label.bottom', subevent: 'reference', radio: true }},
        { entry: { key: 'default.display.label.bottom.crossReference' , label: 'RADIAL.DEFAULT.DISPLAY.LABEL.BOTTOM.CROSSREF', event: 'default.display.label.bottom', subevent: 'crossReference', radio: true }}
      ]
    },
    {
      entry: { key: 'node.selected' },
      items: [
        { entry: { key: 'node.selected.edit' , label: 'RADIAL.NODE.SELECTED.EDIT.TITLE', sub: 'node.selected.edit' }},
        { entry: { key: 'node.selected.select' , label: 'RADIAL.NODE.SELECTED.SELECT.TITLE', sub: 'node.selected.select' }},
        { entry: { key: 'node.selected.sidestep' , label: 'RADIAL.NODE.SELECTED.SIDESTEP.TITLE', sub: 'nodes.selected.sidestep' }},
        { entry: { key: 'node.selected.grouping' , label: 'RADIAL.NODE.SELECTED.GROUPING.TITLE', sub: 'nodes.selected.grouping' }}
      ]
    },
    {
      entry: { key: 'node.selected.edit' },
      items: [
        { entry: { key: 'node.selected.edit.copy' , label: 'RADIAL.NODE.SELECTED.EDIT.COPY', event: 'nodes.selected.edit.copy' }},
        { entry: { key: 'node.selected.edit.delete' , label: 'RADIAL.NODE.SELECTED.EDIT.DELETE', event: 'nodes.selected.edit.delete' }},
        { entry: { key: 'node.selected.addtolibrary' , label: 'RADIAL.NODE.SELECTED.ADDTOLIBRARY.TITLE', event: 'nodes.selected.addtolibrary' }}
      ]
    },
    {
      entry: { key: 'node.selected.select' },
      items: [
        { entry: { key: 'node.selected.select.subtree' , label: 'RADIAL.NODE.SELECTED.SELECT.SUBTREE', event: 'node.selected.select.subtree' }}
      ]
    },
    {
      entry: { key: 'nodes.selected' },
      items: [
        { entry: { key: 'nodes.selected.edit' , label: 'RADIAL.NODES.SELECTED.EDIT.TITLE', sub: 'nodes.selected.edit' }},
        { entry: { key: 'nodes.selected.select' , label: 'RADIAL.NODES.SELECTED.SELECT.TITLE', sub: 'nodes.selected.select' }},
        { entry: { key: 'nodes.selected.grouping' , label: 'RADIAL.NODES.SELECTED.GROUPING.TITLE', sub: 'nodes.selected.grouping' }},
        { entry: { key: 'nodes.selected.addtolibrary' , label: 'RADIAL.NODES.SELECTED.ADDTOLIBRARY.TITLE', event: 'nodes.selected.addtolibrary' }}
      ]
    },
    {
      entry: { key: 'nodes.selected.edit' },
      items: [
        { entry: { key: 'nodes.selected.edit.copy' , label: 'RADIAL.NODES.SELECTED.EDIT.COPY', event: 'nodes.selected.edit.copy' }},
        { entry: { key: 'nodes.selected.edit.delete' , label: 'RADIAL.NODES.SELECTED.EDIT.DELETE', event: 'nodes.selected.edit.delete' }}
      ]
    },
    {
      entry: { key: 'nodes.selected.select' },
      items: [
        { entry: { key: 'nodes.selected.select.subtree' , label: 'RADIAL.NODES.SELECTED.SELECT.SUBTREE', event: 'nodes.selected.select.subtree' }}
      ]
    },
    {
      entry: { key: 'nodes.selected.grouping', extendedArc: true },
      items: [
        { entry: { key: 'nodes.selected.grouping.next' , label: 'RADIAL.NODES.SELECTED.GROUPING.NEXT', event: 'nodes.selected.grouping.next', enabled: false }},
        { entry: { key: 'nodes.selected.grouping.previous' , label: 'RADIAL.NODES.SELECTED.GROUPING.PREVIOUS', event: 'nodes.selected.grouping.previous', enabled: false }}
      ]
    },
    {
      entry: { key: 'nodes.selected.grouping.model' },
      items: [
        { entry: { key: 'nodes.selected.grouping.submodels' , label: 'RADIAL.NODES.SELECTED.GROUPING.SUBMODELS', sub: 'nodes.selected.grouping.submodels', enabled: 'hasSubModels' }},
        { entry: { key: 'nodes.selected.grouping.subsets' , label: 'RADIAL.NODES.SELECTED.GROUPING.SUBSETS', sub: 'nodes.selected.grouping.subsets', enabled: 'hasSubSets' }}
      ]
    },
    {
      entry: { key: 'nodes.selected.grouping.submodels', extendedArc: true },
      items: [
        { entry: { key: 'nodes.selected.grouping.submodels.next' , label: 'RADIAL.NODES.SELECTED.GROUPING.NEXT', event: 'nodes.selected.grouping.submodels.next', enabled: false }},
        { entry: { key: 'nodes.selected.grouping.submodels.previous' , label: 'RADIAL.NODES.SELECTED.GROUPING.PREVIOUS', event: 'nodes.selected.grouping.submodels.previous', enabled: false }}
      ]
    },
    {
      entry: { key: 'nodes.selected.grouping.subsets', extendedArc: true },
      items: [
        { entry: { key: 'nodes.selected.grouping.subsets.next' , label: 'RADIAL.NODES.SELECTED.GROUPING.NEXT', event: 'nodes.selected.grouping.subsets.next', enabled: false }},
        { entry: { key: 'nodes.selected.grouping.subsets.previous' , label: 'RADIAL.NODES.SELECTED.GROUPING.PREVIOUS', event: 'nodes.selected.grouping.subsets.previous', enabled: false }}
      ]
    },
    {
      entry: { key: 'nodes.selected.sidestep', extendedArc: true },
      items: [
        { entry: { key: 'nodes.selected.sidestep.next' , label: 'RADIAL.NODES.SELECTED.SIDESTEP.NEXT', event: 'nodes.selected.sidestep.next', enabled: false }},
        { entry: { key: 'nodes.selected.sidestep.previous' , label: 'RADIAL.NODES.SELECTED.SIDESTEP.PREVIOUS', event: 'nodes.selected.sidestep.previous', enabled: false }}
      ]
    },
    {
      entry: { key: 'node.selected.navigate.models', extendedArc: true },
      items: [
        { entry: { key: 'node.selected.navigate.models.next' , label: 'RADIAL.NODE.SELECTED.NAVIGATE.NEXT', event: 'node.selected.navigate.models.next', enabled: false }},
        { entry: { key: 'node.selected.navigate.models.previous' , label: 'RADIAL.NODE.SELECTED.NAVIGATE.PREVIOUS', event: 'node.selected.navigate.models.previous', enabled: false }}
      ]
    },
    {
      entry: { key: 'node.selected.navigate.sidestep', extendedArc: true },
      items: [
        { entry: { key: 'node.selected.navigate.sidestep.next' , label: 'RADIAL.NODE.SELECTED.NAVIGATE.NEXT', event: 'node.selected.navigate.sidestep.next', enabled: false }},
        { entry: { key: 'node.selected.navigate.sidestep.previous' , label: 'RADIAL.NODE.SELECTED.NAVIGATE.PREVIOUS', event: 'node.selected.navigate.sidestep.previous', enabled: false }}
      ]
    }
  ]
};
