import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';

import { GeneralAction } from './general.actions';
import { Action } from '../shared/common.action';
import { GoAPI } from '../connector/shared/api.go.service';

@Injectable()
export class GeneralEffects {

  @Effect() getHealyMembers$ = this.actions$
    .pipe(ofType(GeneralAction.GET_HEALY_MEMBERS))
    .mergeMap((action: Action) => this.api.getHealyMembers(action.payload.data.start, action.payload.data.count, action.payload.data.search).get()
      .mergeMap(response => Observable.from([
        this.generalAction.getHealyMembersSuccess(response)
      ]))
      .catch((error: any) => Observable.of(this.generalAction.getHealyMembersFailed()))
    );

  constructor(private api: GoAPI,
              private actions$: Actions,
              private generalAction: GeneralAction) {
  }
}
