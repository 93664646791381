import { IHumanResource } from '../connector';
import { Injectable } from '@angular/core';

import { IPayload } from '../shared/common.models';
import { Action } from '../shared/common.action';

@Injectable()
export class HumanResourceAction {
  static key = 'HumanResource';

  static LOAD = `[${HumanResourceAction.key}] Load`;
  static LOAD_SUCCESS = `[${HumanResourceAction.key}] Load success`;
  static LOAD_FAIL = `[${HumanResourceAction.key}] Load fail`;

  static CREATE = `[${HumanResourceAction.key}] Create`;
  static CREATE_GO = `[${HumanResourceAction.key}] Create Go`;
  static CREATE_SUCCESS = `[${HumanResourceAction.key}] Create success`;
  static CREATE_FAIL = `[${HumanResourceAction.key}] Create fail`;

  static UPDATE = `[${HumanResourceAction.key}] Update`;
  static UPDATE_GO = `[${HumanResourceAction.key}] Update Go`;
  static UPDATE_SUCCESS = `[${HumanResourceAction.key}] Update success`;
  static UPDATE_FAIL = `[${HumanResourceAction.key}] Update fail`;

  static REMOVE = `[${HumanResourceAction.key}] Remove`;
  static REMOVE_GO = `[${HumanResourceAction.key}] Remove Go`;
  static REMOVE_SUCCESS = `[${HumanResourceAction.key}] Remove success`;
  static REMOVE_FAIL = `[${HumanResourceAction.key}] Remove fail`;

  static CONNECT = `[${HumanResourceAction.key}] Connect`;
  static CONNECT_SUCCESS = `[${HumanResourceAction.key}] Connect success`;
  static CONNECT_FAIL = `[${HumanResourceAction.key}] Connect fail`;

  static DISCONNECT = `[${HumanResourceAction.key}] Disconnect`;
  static DISCONNECT_SUCCESS = `[${HumanResourceAction.key}] Disconnect success`;
  static DISCONNECT_FAIL = `[${HumanResourceAction.key}] Disconnect fail`;

  public load(instanceId: string): Action {
    return {
      type: HumanResourceAction.LOAD,
      payload: {
        instanceId: instanceId
      }
    };
  }

  public loadSuccess(data: IHumanResource[]): Action {
    return {
      type: HumanResourceAction.LOAD_SUCCESS,
      payload: data
    };
  }

  public loadFail(error: any): Action {
    return {
      type: HumanResourceAction.LOAD_FAIL,
      payload: {
        request: undefined,
        response: error
      }
    };
  }

  public create(instanceId: string, data: IPayload | IPayload[]): Action {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: HumanResourceAction.CREATE,
      payload: {
        instanceId: instanceId,
        data: data
      }
    };
  }

  public createGo(instanceId: string, data: IPayload | IPayload[]): Action {
    if (!Array.isArray(data)) {
      data = [data];
    }

    const z = JSON.stringify(data);
    let outputData = JSON.parse(z);
    outputData = outputData.map(i => {
      i.data.storypoints = Number(i.data.storypoints);
      return i;
    });

    return {
      type: HumanResourceAction.CREATE_GO,
      payload: {
        instanceId: instanceId,
        data: outputData
      }
    };
  }

  public createSuccess(payload: IPayload, response: IHumanResource[]): Action {
    return {
      type: HumanResourceAction.CREATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public createFail(payload: IPayload, error: any): Action {
    return {
      type: HumanResourceAction.CREATE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public update(data: IPayload | IPayload[]): Action {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: HumanResourceAction.UPDATE,
      payload: data
    };
  }

  public updateGo(data: IPayload | IPayload[]): Action {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: HumanResourceAction.UPDATE_GO,
      payload: data
    };
  }

  public updateSuccess(payload: IPayload, response: IHumanResource[]): Action {
    return {
      type: HumanResourceAction.UPDATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public updateFail(payload: IPayload, error: any): Action {
    return {
      type: HumanResourceAction.UPDATE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public remove(ids: string | string[]): Action {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }
    return {
      type: HumanResourceAction.REMOVE,
      payload: {
        data: ids
      }
    };
  }

  public removeGo(ids: string | string[]): Action {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }
    return {
      type: HumanResourceAction.REMOVE_GO,
      payload: {
        data: ids
      }
    };
  }

  public removeSuccess(payload: IPayload, response: IHumanResource[]): Action {
    return {
      type: HumanResourceAction.REMOVE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public removeFail(payload: IPayload, error: any): Action {
    return {
      type: HumanResourceAction.REMOVE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public connect(ids: string | string[]): Action {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }
    return {
      type: HumanResourceAction.CONNECT,
      payload: {
        data: ids
      }
    };
  }

  public connectSuccess(payload: IPayload, response: IHumanResource): Action {
    return {
      type: HumanResourceAction.CONNECT_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public connectFail(payload: IPayload, error: any): Action {
    return {
      type: HumanResourceAction.CONNECT_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public disconnect(ids: string | string[]): Action {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }
    return {
      type: HumanResourceAction.DISCONNECT,
      payload: {
        data: ids
      }
    };
  }

  public disconnectSuccess(payload: IPayload, response: IHumanResource): Action {
    return {
      type: HumanResourceAction.DISCONNECT_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public disconnectFail(payload: IPayload, error: any): Action {
    return {
      type: HumanResourceAction.DISCONNECT_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public autorefreshUpdate(data: any): Action {
    return {
      type: HumanResourceAction.UPDATE_SUCCESS,
      payload: data
    };
  }

  public autorefreshCreate(data: any): Action {
    return {
      type: HumanResourceAction.CREATE_SUCCESS,
      payload: data
    };
  }

  public autorefreshRemove(data: any): Action {
    return {
      type: HumanResourceAction.REMOVE_SUCCESS,
      payload: data
    };
  }

}
