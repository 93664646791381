export const TemplateFieldsReportsObjective = [
  {
    type: 'table',
    settings: {
      /* Virtual scrolling */
      virtualScrolling: true,
      /* Group the elements by model */
      groupByModels: true,
      /* Show table fields */
      showFields: true,
      /* Show the footer */
      showFooter: true,
      /* Trigger to load the Businessarea with that table */
      loadBusinessarea: true,
      /* Exports */
      exports: ['excel', 'pdf'],
      /* The header background color */
      headerBackgroundColor: '#9ac93e',
      /* The header text color */
      headerTextColor: '#ffffff',
    },
    configuration: {
      fields: [
        { entry: { key: 'sublevel', label: '', controlType: 'sublevel', editable: false }},
        { entry: { key: 'general', label: 'REPORTS.GENERAL.GROUP', controlType: 'group'}, children: [
            { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.NAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME', width: 10 }},
            { entry: { key: 'responsibleId', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.RESPONSIBLEID', addNull: true, source: 'humanresources', width: 10 }},
            { entry: { key: 'status', controlType: 'dropdown', label: 'STATUS.TITLE', addNull: false, width: 10, options: [
                  { key: 0, value: 'STATUS.PENDING' },
                  { key: 100, value: 'STATUS.INDEFINE' },
                  { key: 110, value: 'STATUS.INDEVELOP' },
                  { key: 120, value: 'STATUS.INVERIFY' },
                  { key: 130, value: 'STATUS.INACCEPT' },
                  { key: 200, value: 'STATUS.COMPLETED' }
                ]}},
            { entry: { key: 'targetDate', type: 'datetime', controlType: 'date', label: 'REPORTS.OBJECTIVE.TARGETDATE', width: 10 }},
            { entry: { key: 'actualDate', type: 'datetime', controlType: 'date', label: 'REPORTS.OBJECTIVE.ACTUALDATE', width: 10 }},
            { entry: { key: 'budget', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.KPI', width: 10 }},
            { entry: { key: 'budgetCalculated', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.AGGREGATIONKPI', width: 10 }},
            { entry: { key: 'description', controlType: 'text', label: 'NODE.DATASHEET.FIELDS.DESCRIPTION', width: 20 }},
            { entry: { key: 'stategicClassification', controlType: 'textbox', label: 'REPORTS.OBJECTIVE.STRATEGICCLASSIFICATION', editable: false, calculated: true, width: 10 }}
          ]}
      ]
    }
  }
];
