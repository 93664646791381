import { Store } from '@ngrx/store';
import { isNullOrUndefined } from 'util';

import { APIState } from '../reducer';
import { commonSelectors } from '../shared';
import { DataModelState } from '../shared/common.models';
import { Template } from './templates.models';
import { compose } from '../../utilities/compose';

export function getState() {
    return (state$: Store<APIState>) => state$
        .select((s: APIState) => s.templates);
}

export function has(id: string) {
  return compose(commonSelectors.has(id), getState());
}

export function get(id: string) {
  return compose((state$: Store<DataModelState>) => state$
    .select(s => {
      let entity = s.entities.get('' + id);
      if (!isNullOrUndefined(entity)) {
        const defaultTemplate = new Template();
        defaultTemplate.forEach((v: any, k: string) => {
          if (isNullOrUndefined(entity.get(k))) {
            entity = entity.set(k, v);
          }
        });
      }
      return isNullOrUndefined(entity) ? new Template() : new Template(entity.toJS());
    }), getState());
}

export function getMany(ids?: string[]) {
  return compose(commonSelectors.getMany(ids), getState());
}

export function getIsBusy() {
  return compose(commonSelectors.getIsBusy(), getState());
}

export function getDiff() {
  return compose(commonSelectors.getDiff(), getState());
}
