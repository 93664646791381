import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {List, Set} from 'immutable';

import { AppState } from '../../app.reducer';

import { ModelService, Model } from '../models';
import { Relationship } from './relationships.models';
import { RelationshipAction } from './relationships.actions';
import * as selectors from './relationships.selectors';
import { SharedService } from '../shared/shared.service';
import { isNullOrUndefined } from 'util';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class RelationshipService extends SharedService {

  public constructor(protected store: Store<AppState>, protected action: RelationshipAction, private modelService: ModelService) {
    super(store, action, selectors);
  }

  public cancel() {
    this.store.dispatch(this.action.createCancel());
  }

  public findByModelId(id: string) {
    return this.modelService.find(id).filter(model => !!model).mergeMap((model: Model) => {
      let idSet;
      if (isNullOrUndefined(model.relationships.submodels)) {
        idSet = Set([model.id]);
      } else {
        idSet = model.relationships.submodels.map(_ => '' + _).toSet().add(model.id);
      }
      return this.all().map((relationships: List<Relationship>) => relationships.filter((relationship: Relationship) => idSet.has(relationship.relationships.model)).toList());
    });
  }

  public byModelIds(ids: string[]) {
    return this.all().filter((relationship: any) => !isNullOrUndefined(relationship) && !isNullOrUndefined(relationship.relationships) && ids.indexOf(relationship.relationships.model) !== -1);
  }

}
