import { OrderedMap } from 'immutable';

import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { TreeNode } from '../../../core/interface/core.interface';
import { Datum } from '../../../shared/utilities/datum';

export class ColorlabelproviderServiceRelatedstatus extends ColorLabelProviderAbstract {

  public static MEDIUM = '#EDD515';
  public static HIGH = '#E50000';

  public constructor(private treeNodes: OrderedMap<string, TreeNode>) {
    super();
  }

  public color(element: TreeNode, value?: any) {
    const now = new Datum();
    let max = 0;
    this.treeNodes.forEach(treeNode => {
      if (element.relatedChildren.indexOf(parseInt(treeNode.id)) !== -1) {
        if (new Datum(treeNode.targetDate).sameDay(now)) {
          if (max <= 1) { max = 1; }
        } else if (new Datum(treeNode.targetDate).beforeDay(now)) {
          if (max <= 2) { max = 2; }
        }
      }
    });
    switch (max) {
      case 1:
        return [ColorlabelproviderServiceRelatedstatus.MEDIUM];
      case 2:
        return [ColorlabelproviderServiceRelatedstatus.HIGH];
    }
  }

}
