export const TemplateFieldsSettings = {
  general: {
    autoposition: true,
    versioning: true
  },
  splitscreen: {
    activities: {
      gantt: true,
      list: true
    },
    reports: {
      cawecocompliance: true,
      clevelheatmap: true,
      compliancestatus: false,
      estimation: true,
      heatmap: false,
      kpis: true,
      modelstatus: true,
      objective: true,
      objectivemodel: false,
      objectivemodelmeasures: false,
      objectivebusinessarea: false,
      objectivebusinessareameasures: false,
      projectoverview: true,
      ilg: false,
      roi: true,
      sadt: false,
      stack: false,
      strategicfitness: true,
      structured: true,
      teamstatus: true,
      scheduleddistribution: false,
      pomoverview: false,
      status: false,
      overview: false,
      initiativeupdate: false,
      initiativeinventory: false,
      effectcontrolling: false,
      kpi: false,
      focusmaster: false,
      focuspmo: false,
      strategyoverview: false,
      changeit: false,
      rollout: false,
      factories: false
    }
  }
};
