import { Record } from 'immutable';

export interface MiningData {
  source?: string;
  library?: string;
  target?: string;
  trends?: string;
  workflow?: string;
  links?: { parent: string, child: string };
}

const miningStateRecord = Record({
  isBusy: 0
});

export class MiningState extends miningStateRecord {
  isBusy: number;
  api: any;
}
