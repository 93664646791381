import { List } from 'immutable';

import { ColorLabelProviderCommon } from '../colorlabelprovider.common';
import { LegendItem } from '../../../../shared/legend/legend.item';
import { TranslateService } from '@ngx-translate/core';
import { isNullOrUndefined, isNumber } from 'util';

export class ColorLabelProviderAbstract {

  protected ignore = ['name', ''];

  public constructor(protected translateService?: TranslateService) {}

  public color(...args: any[]): any {
    return [ColorLabelProviderCommon.COLOR];
  }

  public label(...args: any[]): any {
    return ColorLabelProviderCommon.LABEL;
  }

  public legend(...args: any[]): any {
    return List<LegendItem>();
  }

  protected translate(text: string, params?: any) {
    if (isNullOrUndefined(text) || text === '') {
      return '';
    }
    if (isNumber(text)) {
      return '' + text;
    }
    return this.translateService.instant(text, params);
  }

}
