import { IBusinessarea } from '../connector';
import { Injectable } from '@angular/core';

import { IPayload } from '../shared/common.models';
import { Action } from '../shared/common.action';

@Injectable()
export class BusinessareaAction {
  static key = 'Businessarea';

  static LOAD = `[${BusinessareaAction.key}] Load`;
  static LOAD_BY_INSTANCE = `[${BusinessareaAction.key}] Load by instance`;
  static LOAD_SUCCESS = `[${BusinessareaAction.key}] Load success`;
  static LOAD_FAIL = `[${BusinessareaAction.key}] Load fail`;

  static MASSLOAD = `[${BusinessareaAction.key}] Mass Load`;
  static MASSGOLOAD = `[${BusinessareaAction.key}] Mass Golang Load`;
  static MASSLOAD_SUCCESS = `[${BusinessareaAction.key}] Mass load success`;

  static CREATE = `[${BusinessareaAction.key}] Create`;
  static CREATE_GO = `[${BusinessareaAction.key}] Create Go`;

  static CREATE_SUCCESS = `[${BusinessareaAction.key}] Create success`;
  static CREATE_FAIL = `[${BusinessareaAction.key}] Create fail`;

  static DUPLICATE = `[${BusinessareaAction.key}] Duplicate`;
  static DUPLICATE_SUCCESS = `[${BusinessareaAction.key}] Duplicate success`;
  static DUPLICATE_FAIL = `[${BusinessareaAction.key}] Duplicate fail`;

  static UPDATE = `[${BusinessareaAction.key}] Update`;
  static UPDATE_GO = `[${BusinessareaAction.key}] Update Go`;
  static UPDATE_SUCCESS = `[${BusinessareaAction.key}] Update success`;
  static UPDATE_FAIL = `[${BusinessareaAction.key}] Update fail`;

  static REMOVE = `[${BusinessareaAction.key}] Remove`;
  static REMOVE_GO = `[${BusinessareaAction.key}] Remove Go`;
  static REMOVE_SUCCESS = `[${BusinessareaAction.key}] Remove success`;
  static REMOVE_FAIL = `[${BusinessareaAction.key}] Remove fail`;

  static ADD_RELATIONSHIP = `[${BusinessareaAction.key}] Add Relationship`;
  static REMOVE_RELATIONSHIP = `[${BusinessareaAction.key}] Remove Relationship`;

  public load(id: string, includes: string[] = []): Action {
    return {
      type: BusinessareaAction.LOAD,
      payload: {
        id: id,
        includes: includes
      }
    };
  }

  public massLoad(id: string) {
    return {
      type: BusinessareaAction.MASSLOAD,
      payload: {
        id: id
      }
    };
  }

  public massGoLoad(id: string) {
    return {
      type: BusinessareaAction.MASSGOLOAD,
      payload: {
        id: id
      }
    };
  }

  public massLoadSuccess(data: IBusinessarea[]) {
    return {
      type: BusinessareaAction.MASSLOAD_SUCCESS,
      payload: {
        response: data
      }
    };
  }

  public loadBusinessareasOfInstance(instanceId: string, includes: string[] = []): Action {
    return {
      type: BusinessareaAction.LOAD_BY_INSTANCE,
      payload: {
        instanceId: instanceId,
        includes: includes
      }
    };
  }

  public loadSuccess(data: IBusinessarea[]) {
    return {
      type: BusinessareaAction.LOAD_SUCCESS,
      payload: {
        response: data
      }
    };
  }

  public loadFail(error: any) {
    return {
      type: BusinessareaAction.LOAD_FAIL,
      payload: {
        request: undefined,
        response: error
      }
    };
  }

  public create(instanceId: string, data: IPayload | IPayload[]) {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: BusinessareaAction.CREATE,
      payload: {
        instanceId: instanceId,
        data: data
      }
    };
  }

  public createGo(instanceId: string, data: IPayload | IPayload[]) {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: BusinessareaAction.CREATE_GO,
      payload: {
        instanceId: instanceId,
        data: data
      }
    };
  }

  public createSuccess(payload: IPayload, response: IBusinessarea) {
    return {
      type: BusinessareaAction.CREATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public createFail(payload: IPayload, error: any) {
    return {
      type: BusinessareaAction.CREATE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public duplicate(instanceId: string, data: IPayload | IPayload[]) {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: BusinessareaAction.DUPLICATE,
      payload: {
        instanceId: instanceId,
        data: data
      }
    };
  }

  public duplicateSuccess(payload: IPayload, response: IBusinessarea) {
    return {
      type: BusinessareaAction.DUPLICATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public duplicateFail(payload: IPayload, error: any) {
    return {
      type: BusinessareaAction.DUPLICATE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public update(data: IPayload | IPayload[]) {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: BusinessareaAction.UPDATE,
      payload: data
    };
  }

  public updateGo(data: IPayload | IPayload[]) {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: BusinessareaAction.UPDATE_GO,
      payload: data
    };
  }


  public updateSuccess(payload: IPayload, response: IBusinessarea[]) {
    return {
      type: BusinessareaAction.UPDATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public updateFail(payload: IPayload, error: any) {
    return {
      type: BusinessareaAction.UPDATE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public remove(ids: string | string[]) {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }
    return {
      type: BusinessareaAction.REMOVE,
      payload: {
        data: ids
      }
    };
  }

  public removeGo(ids: string | string[]) {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }
    return {
      type: BusinessareaAction.REMOVE_GO,
      payload: {
        data: ids
      }
    };
  }

  public removeSuccess(payload: IPayload, response: IBusinessarea[]) {
    return {
      type: BusinessareaAction.REMOVE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public removeFail(payload: IPayload, error: any) {
    return {
      type: BusinessareaAction.REMOVE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public addRelationship(id: string, type: string, relationship: string) {
    return {
      type: BusinessareaAction.ADD_RELATIONSHIP,
      payload: {
        id: id,
        type: type,
        relationship: relationship
      }
    };
  }

  public removeRelationship(id: string, type: string, relationship: string) {
    return {
      type: BusinessareaAction.REMOVE_RELATIONSHIP,
      payload: {
        id: id,
        type: type,
        relationship: relationship
      }
    };
  }

  public autorefreshUpdate(data: any) {
    return {
      type: BusinessareaAction.UPDATE_SUCCESS,
      payload: data
    };
  }

  public autorefreshCreate(data: any) {
    return {
      type: BusinessareaAction.CREATE_SUCCESS,
      payload: data
    };
  }

  public autorefreshRemove(data: any) {
    return {
      type: BusinessareaAction.REMOVE_SUCCESS,
      payload: data
    };
  }
}
