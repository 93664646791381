import { is, Map, OrderedMap, Set } from 'immutable';
import { isNullOrUndefined } from 'util';
import { UUID } from 'angular2-uuid';
import { NaturalSort } from 'angular2-natural-sort';

import { TreeNode } from '../tree.node';
import { TreeRelationship } from '../tree.relationship';
import { Node } from '../../../../shared/api/nodes';
import { Screen } from '../../../splitscreen/screen';

export interface TreePositionNode {
  id: string;
  phantom: boolean;
  positionX: number;
  level: number;
  subLevel: number;
  autoposition: number;
  fixedposition: number;
  parents: TreePositionNode[];
  children: TreePositionNode[];
  maxChildren: number;
}

export class TreeComponentPositioning {

  public screen: Screen;

  private dummyEdges = Map<string, TreeNode>();
  private dummyVertices = Map<string, TreeRelationship>();
  private nodes: OrderedMap<string, TreeNode>;
  private relationships: Map<string, TreeRelationship>;
  private levelSubLevelMap = Map<number, number>();

  private _nodes = {};
  private positions = Map<string, Map<string, number>>();
  private maxChildren = Map<string, Set<string>>();

  public update(nodes: OrderedMap<string, TreeNode>, relationships: Map<string, TreeRelationship>, fixedNodes: string[] = []): OrderedMap<string, TreeNode> {
    return this.auto(nodes, relationships, fixedNodes);
  }

  private auto(nodes: OrderedMap<string, TreeNode>, relationships: Map<string, TreeRelationship>, fixedNodes: string[]): OrderedMap<string, TreeNode> {
    /* Clear */
    this.dummyEdges = this.dummyEdges.clear();
    this.dummyVertices = this.dummyVertices.clear();
    this.positions = this.positions.clear();
    this.levelSubLevelMap = this.levelSubLevelMap.clear();
    this.maxChildren = this.maxChildren.clear();

    /* Set */
    this.nodes = nodes;
    if (this.nodes.size === 0) {
      return this.nodes;
    }

    /* max children */
    nodes.filter(_ => _.children.size === 0).forEach(node => this.setMaxChildren(node));

    /* Convert widget */
    this._nodes = {};
    nodes.forEach(_ => this._nodes[_.id] = this.convert(_));

    Map(this._nodes).forEach((node: TreePositionNode) => {
      let count = this.levelSubLevelMap.has(node.level) ? this.levelSubLevelMap.get(node.level) : 0;
      if (node.subLevel > count) {
        count = node.subLevel;
      }
      this.levelSubLevelMap = this.levelSubLevelMap.set(node.level, count);
    });

    /* Step 1 - introduction of the dummy vertices and dummy edges */
    this.relationships = <Map<string, TreeRelationship>> relationships.filter(relationship => {
      const parent = this._nodes[relationship.parent];
      const child = this._nodes[relationship.child];
      if (isNullOrUndefined(parent) || isNullOrUndefined(child)) {
        return false;
      }
      const difference = this.getSubLevelDifference(parent, child);
      if (difference < -1) {
        this.introduceDummies(parent, child);
        return false;
      }
      return true;
    });

    /* Step 2 - down */
    const topMost = Object.keys(this._nodes).filter(id => this._nodes.hasOwnProperty(id) && this._nodes[id].parents.length === 0).map(id => this._nodes[id]).sort(this.sortByPosition);
    this.down(topMost);

    /* Step 3 - collision detection between trees */
    this.checkForCollision(topMost);

    /* Step 4 - build the matrix */
    let matrix = OrderedMap<string, OrderedMap<string, TreePositionNode>>();
    Object.keys(this._nodes).forEach(key => {
      if (this._nodes.hasOwnProperty(key)) {
        const node = this._nodes[key];
        // node.fixedposition = fixedNodes.indexOf(node.id) !== -1 ? node.node.positionX : 0;
        // node.autoposition = 0;
        const layer = node.level + '-' + node.subLevel;
        const _matrix = matrix.has(layer) ? matrix.get(layer) : OrderedMap<string, TreePositionNode>();
        matrix = matrix.set(layer, _matrix.set(node.id, node));
        return node;
      }
    });

    /* Step 5 - sort the matrix by x position */
    matrix = <OrderedMap<string, OrderedMap<string, TreePositionNode>>> matrix.sortBy((v, k) => k, NaturalSort.SORT).map(matrixNodes => <OrderedMap<string, TreePositionNode>> matrixNodes.sort(this.sortByAutoPosition));

    /* Step 6 - collision detection within trees */
    this.checkForCollisionInMatrix(matrix);

    /* Step 7 - remove whitespace between trees */
    this.removeWhitespacebetweenTrees(topMost);

    /* Return widget */
    return <OrderedMap<string, TreeNode>> this.nodes.map(treeNode => {
      if (!isNullOrUndefined(this._nodes[treeNode.id])) {
        const treePositionNode: TreePositionNode = this._nodes[treeNode.id];
        if (!isNullOrUndefined(treePositionNode)) {
          treeNode = <TreeNode> treeNode.set('autoposition', treePositionNode.autoposition);
        }
      }
      return treeNode;
    });
  }

  private convert(node: TreeNode, cache = {}): TreePositionNode {
    if (!isNullOrUndefined(cache[node.id])) {
      return cache[node.id];
    }
    const parents = [];
    const children = [];
    node.parents.forEach(_ => parents.push(this.convert(_, cache)));
    node.children.forEach(_ => children.push(this.convert(_, cache)));
    const treePositionNode = <TreePositionNode> {
      id: node.id,
      phantom: node.phantom,
      positionX: node.node.positionX,
      level: node.level,
      subLevel: node.subLevel,
      autoposition: node.autoposition,
      fixedposition: node.fixedposition,
      parents: parents,
      children: children,
      maxChildren: this.maxChildren.has(node.id) ? this.maxChildren.get(node.id).size : 0
    };
    cache[treePositionNode.id] = treePositionNode;
    return treePositionNode;
  }

  private setMaxChildren(node: TreeNode) {
    node.parents.forEach(parent => {
      const count = this.maxChildren.has(parent.id) ? this.maxChildren.get(parent.id) : Set<string>();
      if (node.parents.size === 1) {
        this.maxChildren = this.maxChildren.set(parent.id, count.add(node.id));
      }
      this.setMaxChildren(parent);
    });
  }

  private sortByPosition(node1: TreePositionNode, node2: TreePositionNode): number {
    if (isNullOrUndefined(node1) || isNullOrUndefined(node2) || node1.positionX === node2.positionX) {
      return 0;
    }
    return node1.positionX > node2.positionX ? 1 : -1;
  }

  private sortByAutoPosition(node1: TreePositionNode, node2: TreePositionNode): number {
    if (isNullOrUndefined(node1) || isNullOrUndefined(node2) || node1.autoposition === node2.autoposition) {
      return 0;
    }
    return node1.autoposition > node2.autoposition ? 1 : -1;
  }

  private getSubLevelDifference(a: TreePositionNode, b: TreePositionNode) {
    return this.getSubLevelID(a) - this.getSubLevelID(b);
  }

  private getSubLevelID(node: TreePositionNode) {
    let count = 1 + node.subLevel;
    for (let i = 0; i < node.level; i++) {
      count += this.levelSubLevelMap.get(i) + 1;
    }
    return count;
  }

  private introduceDummies(source: TreePositionNode, target: TreePositionNode) {
    const difference = this.getSubLevelDifference(source, target);
    if (difference < -1) {
      const dummyEdge = this.getDummyTreeNode(source, target, source.subLevel + 1);
      dummyEdge['parents'] = [source];
      source.children = source.children.filter(_ => _.id !== target.id);
      target.parents = target.parents.filter(_ => _.id !== source.id);
      this.addTreeRelationship(source, dummyEdge);
      this.introduceDummies(dummyEdge, target);
    } else {
      this.addTreeRelationship(source, target);
    }
  }

  private getDummyTreeNode(source: TreePositionNode, target?: TreePositionNode, subLevel ?: number, level?: number, positionX?: number) {
    subLevel = !isNullOrUndefined(subLevel) ? subLevel : source.subLevel;
    level = !isNullOrUndefined(level) ? level : source.level;
    positionX = !isNullOrUndefined(positionX) ? positionX : (!isNullOrUndefined(target) ? target.positionX : source.positionX);
    const dummyTreeNode = <TreeNode> new TreeNode(new Node({ id: UUID.UUID(), positionX: positionX }))
      .set('level', level)
      .set('subLevel', subLevel)
      .set('dummy', true);
    const dummyEdge = this.convert(dummyTreeNode);
    dummyEdge.maxChildren = target.maxChildren;
    this._nodes[dummyEdge.id] = dummyEdge;
    return dummyEdge;
  }

  private addTreeRelationship(parent: TreePositionNode, child: TreePositionNode) {
    parent.children = parent.children.filter(_ => _.id !== child.id);
    parent.children.push(child);
    parent.children.sort(this.sortByPosition);
    child.parents = child.parents.filter(_ => _.id !== parent.id);
    child.parents.push(parent);
    child.parents.sort(this.sortByPosition);
  }

  private down(nodes: TreePositionNode[]) {
    nodes.forEach((node: TreePositionNode) => {
      node = this._nodes[node.id];
      this.getBarycentricChildPositions(node);
      node.autoposition = this.getPosition(node);
      if (node.children.length > 0) {
        this.down(node.children);
      }
    });
  }

  private getBarycentricChildPositions(node: TreePositionNode) {
    const parentX = this.getPosition(node);
    let x = parentX - (this.getChildrenWidth(node) / 2);
    node.children.forEach(child => {
      const childWidth = child.maxChildren === 0 ? this.screen.nodeHitboxWidth : child.maxChildren * this.screen.nodeHitboxWidth;
      const pos = this.positions.has(child.id) ? this.positions.get(child.id) : Map<string, number>();
      this.positions = this.positions.set(child.id, pos.set(node.id, x + (childWidth / 2)));
      x += childWidth;
    });
  }

  private getChildrenWidth(node: TreePositionNode) {
    let width = 0;
    node.children.forEach(child => {
      width += child.maxChildren === 0 ? this.screen.nodeHitboxWidth : child.maxChildren * this.screen.nodeHitboxWidth;
    });
    return width;
  }

  private getPosition(node: TreePositionNode) {
    if (!this.positions.has(node.id)) {
      return node.positionX;
    }
    let min = 0;
    let max = 0;
    this.positions.get(node.id).forEach(pos => {
      if (min === 0 || min > pos) {
        min = pos;
      }
      if (max < pos) {
        max = pos;
      }
    });
    return (max === min) ? max : min + ((max - min) / 2);
  }

  private getAutoPositionMinMax(node: TreePositionNode, min = 0, max = 0) {
    node = this._nodes[node.id];
    if (min === 0 || node.autoposition < min) {
      min = node.autoposition;
    }
    if (node.autoposition > max) {
      max = node.autoposition;
    }
    node.children.forEach(child => {
      const mm = this.getAutoPositionMinMax(child, min, max);
      min = mm.min;
      max = mm.max;
    });
    return { min: min, max: max };
  }

  private shiftTree(node: TreePositionNode, shift: number, direction = 'children') {
    node = this._nodes[node.id];
    node.autoposition = node.autoposition + shift;
    node[direction].forEach(element => this.shiftTree(element, shift));
  }

  private checkForCollision(nodes: TreePositionNode[]) {
    let min = this.screen.levelPaddingLeft;
    nodes.forEach((node: TreePositionNode) => {
      const mm = this.getAutoPositionMinMax(node);
      let shift = 0;
      if (mm.min < min) {
        shift = min - mm.min;
        this.shiftTree(node, shift);
      }
      min = mm.max + this.screen.nodeHitboxWidth + shift;
    });
  }

  private checkForCollisionInMatrix(matrix: OrderedMap<string, OrderedMap<string, TreePositionNode>>) {
    matrix.reverse().forEach((nodes: OrderedMap<string, TreePositionNode>) => {
      let x = 0;
      let shift = 0;
      let shifts = Map<string, number>();
      nodes.forEach(node => {
        node = this._nodes[node.id];
        if (x !== 0 && (x + this.screen.nodeHitboxWidth) > node.autoposition) {
          shift = this.screen.nodeHitboxWidth - (node.autoposition - x);
          node.autoposition += shift;
          node.parents.forEach(parent => {
            shifts = shifts.set(parent.id, shift);
          });
        }
        x = node.autoposition;
      });
      shifts.forEach((s, id) => {
        const parent = this._nodes[id];
        if (!isNullOrUndefined(parent)) {
          parent.autoposition += s;
        }
      });
    });
  }

  private removeWhitespacebetweenTrees(nodes: TreePositionNode[]) {
    let shift = 0;
    let max = this.screen.levelPaddingLeft;
    nodes.forEach(node => {
      const position = this.getAutoPositionMinMax(node);
      if (position.min > max) {
        shift = max - position.min;
      }
      max = position.max + shift + this.screen.levelPaddingLeft;
      this.updateTreeWithShift(node, shift);
    });
  }

  private updateTreeWithShift(node: TreePositionNode, shift: number) {
    const _node = this._nodes[node.id];
    if (!isNullOrUndefined(_node)) {
      _node.autoposition += shift;
    }
    node.children.forEach(childNode => this.updateTreeWithShift(childNode, shift));
  }

}
