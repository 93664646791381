export const TemplateFieldsReportsOverview = [
  {
    type: 'overview',
    settings: {
      /* Show the footer */
      showFooter: true,
      /* Show legend dropup */
      showLegend: true,
      /* Trigger to load the Businessarea with that table */
      loadBusinessarea: false,
      /* Exports */
      exports: ['pdf'], //  excel, pdf, html
    }
  }
];
