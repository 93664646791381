export const TemplateFieldsActivities = {
  tabs: [
    {
      entry: { key: 'header1', label: 'ACTIVITY.DATASHEET.HEADERS.HEADER1' },
      children: [
        { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.NAME', required: true, validationMessage: 'VALIDATION.REQUIRED.NAME' }},
        { entry: { key: 'reference', type: 'text', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.REFERENCE' }},
        { entry: { key: 'crossReference', type: 'text', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.CROSSREFERENCE' }},
        { entry: { key: 'type', controlType: 'dropdown', label: 'ACTIVITY.DATASHEET.FIELDS.TYPE.TITLE', options: [
          { key: 0, value: 'ACTIVITY.DATASHEET.FIELDS.TYPE.SPECIFY' },
          { key: 1, value: 'ACTIVITY.DATASHEET.FIELDS.TYPE.DEVELOP' },
          { key: 2, value: 'ACTIVITY.DATASHEET.FIELDS.TYPE.VERIFY' },
          { key: 3, value: 'ACTIVITY.DATASHEET.FIELDS.TYPE.ACCEPT' }
        ]}},
        { entry: { key: 'node', controlType: 'dropdown', label: 'ACTIVITY.DATASHEET.FIELDS.NODE', source: 'nodes', required: true, validationMessage: 'VALIDATION.REQUIRED.NODES' }},
        { entry: { key: 'responsibleId', controlType: 'dropdown', label: 'ACTIVITY.DATASHEET.FIELDS.RESPONSIBLE', source: 'humanresources', hasNull: true }},
        { entry: { key: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.BUDGETPLAN' }},
        { entry: { key: 'budget', type: 'number', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.BUDGET' }},
        {
          entry: { key: 'group1', label: 'ACTIVITY.DATASHEET.GROUPS.GROUP1', controlType: 'group' },
          children: [
            { entry: { key: 'benefitBudget', type: 'number', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.BENEFITBUDGET' }},
            { entry: { key: 'benefitActual', type: 'number', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.BENEFITACTUAL' }},
            { entry: { key: 'benefitRemaining', type: 'number', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.BENEFITREMAINING' }}
          ]
        },
        {
          entry: { key: 'group2', label: 'ACTIVITY.DATASHEET.GROUPS.GROUP2', controlType: 'group' },
          children: [
            { entry: { key: 'costBudget', type: 'number', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.COSTBUDGET' }},
            { entry: { key: 'costActual', type: 'number', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.COSTACTUAL' }},
            { entry: { key: 'costRemaining', type: 'number', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.COSTREMAINING' }}
          ]
        },
        {
          entry: { key: 'group3', label: 'ACTIVITY.DATASHEET.GROUPS.GROUP3', controlType: 'group' },
          children: [
            { entry: { key: 'investBudget', type: 'number', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.INVESTBUDGET' }},
            { entry: { key: 'investActual', type: 'number', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.INVESTACTUAL' }},
            { entry: { key: 'investRemaining', type: 'number', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.INVESTREMAINING' }}
          ]
        },
        { entry: { key: 'description', controlType: 'text', label: 'ACTIVITY.DATASHEET.FIELDS.DESCRIPTION' }},
      ]
    },
    {
      entry: { key: 'header2', label: 'ACTIVITY.DATASHEET.HEADERS.HEADER2'},
      children: [
        {
          entry: { key: 'group4', label: 'ACTIVITY.DATASHEET.GROUPS.GROUP4', controlType: 'group' },
          children: [
            { entry: { key: 'start', type: 'datetime', controlType: 'date', label: 'ACTIVITY.DATASHEET.FIELDS.START', required: true, validationMessage: 'VALIDATION.REQUIRED.START' }},
            { entry: { key: 'end', type: 'datetime', controlType: 'date', label: 'ACTIVITY.DATASHEET.FIELDS.END', required: true, validationMessage: 'VALIDATION.REQUIRED.END' }},
            { entry: { key: 'startActual', type: 'datetime', controlType: 'date', label: 'ACTIVITY.DATASHEET.FIELDS.STARTACTUAL' }},
            { entry: { key: 'endActual', type: 'datetime', controlType: 'date', label: 'ACTIVITY.DATASHEET.FIELDS.ENDACTUAL' }}
          ]
        },
        {
          entry: { key: 'group5', label: 'ACTIVITY.DATASHEET.GROUPS.GROUP5', controlType: 'group' },
          children: [
            { entry: { key: 'status', controlType: 'dropdown', label: 'ACTIVITY.DATASHEET.FIELDS.STATUS.TITLE', options: [
              { key: 0, value: 'ACTIVITY.DATASHEET.FIELDS.STATUS.PENDING' },
              { key: 1, value: 'ACTIVITY.DATASHEET.FIELDS.STATUS.PLANNED' },
              { key: 2, value: 'ACTIVITY.DATASHEET.FIELDS.STATUS.INEXECUTION' },
              { key: 3, value: 'ACTIVITY.DATASHEET.FIELDS.STATUS.SUCCCOMP' },
              { key: 4, value: 'ACTIVITY.DATASHEET.FIELDS.STATUS.UNSUCC' },
              { key: 5, value: 'ACTIVITY.DATASHEET.FIELDS.STATUS.CONDSUCC' }
            ]}},
            { entry: { key: 'percentageComplete', type: 'number', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.PERCENTAGECOMPLETE' }}
          ]
        },
        { entry: { key: 'priority', type: 'number', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.PRIORITY' }},
        { entry: { key: 'milestone', controlType: 'checkbox', label: 'ACTIVITY.DATASHEET.FIELDS.MILESTONE' }}
      ]
    },
    {
      entry: { key: 'header3', label: 'ACTIVITY.DATASHEET.HEADERS.HEADER3'},
      children: [
        {
          entry: { key: 'group6', label: 'ACTIVITY.DATASHEET.GROUPS.GROUP6', controlType: 'group' },
          children: [
            { entry: { key: 'riskProfileCategory', controlType: 'dropdown', label: 'ACTIVITY.DATASHEET.FIELDS.RISKPROFILECATEGORY.TITLE', options: [
              { key: 0, value: 'ACTIVITY.DATASHEET.FIELDS.RISKPROFILECATEGORY.EXECUTIVE' },
              { key: 1, value: 'ACTIVITY.DATASHEET.FIELDS.RISKPROFILECATEGORY.PROGRAMPROJECT' },
              { key: 2, value: 'ACTIVITY.DATASHEET.FIELDS.RISKPROFILECATEGORY.FUNCTIONAL' },
              { key: 3, value: 'ACTIVITY.DATASHEET.FIELDS.RISKPROFILECATEGORY.RESOURCE' },
              { key: 4, value: 'ACTIVITY.DATASHEET.FIELDS.RISKPROFILECATEGORY.ORGANIZATIONAL' },
              { key: 5, value: 'ACTIVITY.DATASHEET.FIELDS.RISKPROFILECATEGORY.TECHNICAL' }
            ]}},
            { entry: { key: 'riskProfileAssessment', controlType: 'dropdown', label: 'ACTIVITY.DATASHEET.FIELDS.RISKPROFILEASSESSMENT.TITLE', options: [
              { key: 0, value: 'ACTIVITY.DATASHEET.FIELDS.RISKPROFILEASSESSMENT.LOW' },
              { key: 1, value: 'ACTIVITY.DATASHEET.FIELDS.RISKPROFILEASSESSMENT.MEDIUM' },
              { key: 2, value: 'ACTIVITY.DATASHEET.FIELDS.RISKPROFILEASSESSMENT.HIGH' }
            ]}},
            { entry: { key: 'riskProfileCountermeasures', controlType: 'text', label: 'ACTIVITY.DATASHEET.FIELDS.RISKPROFILECOUNTERMEASURES' }}
          ]
        }
      ]
    },
    {
      entry: { key: 'unused', label: 'ACTIVITY.DATASHEET.HEADERS.UNUSED'},
      children: [
        { entry: { key: 'group7', label: 'ACTIVITY.DATASHEET.GROUPS.GROUP7', controlType: 'group' }, children: [] },
        { entry: { key: 'group8', label: 'ACTIVITY.DATASHEET.GROUPS.GROUP8', controlType: 'group' }, children: [] },
        { entry: { key: 'group9', label: 'ACTIVITY.DATASHEET.GROUPS.GROUP9', controlType: 'group' }, children: [] },
        { entry: { key: 'group10', label: 'ACTIVITY.DATASHEET.GROUPS.GROUP10', controlType: 'group' }, children: [] },
        { entry: { key: 'time', type: 'text', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.TIME' }},
        { entry: { key: 'timeDescription', controlType: 'text', label: 'ACTIVITY.DATASHEET.FIELDS.TIMEDESCRIPTION' }},
        { entry: { key: 'cost', type: 'text', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.COST' }},
        { entry: { key: 'costDescription', controlType: 'text', label: 'ACTIVITY.DATASHEET.FIELDS.COSTDESCRIPTION' }},
        { entry: { key: 'quality', type: 'text', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.QUALITY' }},
        { entry: { key: 'qualityDescription', controlType: 'text', label: 'ACTIVITY.DATASHEET.FIELDS.QUALITYDESCRIPTION' }},
        { entry: { key: 'executive', type: 'text', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.EXECUTIVE' }},
        { entry: { key: 'executiveDescription', controlType: 'text', label: 'ACTIVITY.DATASHEET.FIELDS.EXECUTIVEDESCRIPTION' }},
        { entry: { key: 'program', type: 'text', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.PROGRAM' }},
        { entry: { key: 'programDescription', controlType: 'text', label: 'ACTIVITY.DATASHEET.FIELDS.PROGRAMDESCRIPTION' }},
        { entry: { key: 'functional', type: 'text', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.FUNCTIONAL' }},
        { entry: { key: 'functionalDescription', controlType: 'text', label: 'ACTIVITY.DATASHEET.FIELDS.FUNCTIONALDESCRIPTION' }},
        { entry: { key: 'resource', type: 'text', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.RESOURCE' }},
        { entry: { key: 'resourceDescription', controlType: 'text', label: 'ACTIVITY.DATASHEET.FIELDS.RESOURCEDESCRIPTION' }},
        { entry: { key: 'organizational', type: 'text', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.ORGANIZATIONAL' }},
        { entry: { key: 'organizationalDescription', controlType: 'text', label: 'ACTIVITY.DATASHEET.FIELDS.ORGANIZATIONALDESCRIPTION' }},
        { entry: { key: 'technical', type: 'text', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.TECHNICAL' }},
        { entry: { key: 'technicalDescription', controlType: 'text', label: 'ACTIVITY.DATASHEET.FIELDS.TECHNICALDESCRIPTION' }},
      ]
    }
  ]
};
