export const TemplateFieldsMatrix = {
  grid: {
    options: [
      { entry: { key: '', label: 'DASHBOARD.MATRIX.GRID.UNASSIGNED', x: true, y: true, options: [
        { key: 0, value: '' },
      ]}},
      { entry: { key: 'businessBenefit', x: true, y: true, type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.TITLE', options: [
        { key: 0, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.NOTAPPLICABLE' },
        { key: 1, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.LOW' },
        { key: 2, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.MEDIUM' },
        { key: 3, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.HIGH' },
      ]}}
    ]
  },
  trends: {
    options: [
      { entry: { key: '', label: 'DASHBOARD.MATRIX.GRID.UNASSIGNED', x: true, y: false, options: [
            { key: 0, value: '' },
          ]}},
      { entry: { key: 'businessBenefit', x: false, y: true, type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.TITLE', options: [
            { key: 1, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.LOW' },
            { key: 2, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.MEDIUM' },
            { key: 3, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.HIGH' },
          ]}}
    ]
  },
  ipnavigator: {
    options: [
      { entry: { key: '', label: 'DASHBOARD.MATRIX.GRID.UNASSIGNED', x: true, y: true, options: [
            { key: 0, value: '' },
          ]}},
      { entry: { key: 'businessBenefit', x: true, y: true, type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.TITLE', options: [
            { key: 0, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.NOTAPPLICABLE' },
            { key: 1, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.LOW' },
            { key: 2, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.MEDIUM' },
            { key: 3, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.HIGH' },
          ]}}
    ]
  }
};
