export const TemplateFieldsReportsEstimation = [
  {
    type: 'table',
    settings: {
      /* Virtual scrolling */
      virtualScrolling: true,
      /* Group the elements by levels */
      groupByLevels: true,
      /* Show total row after level group */
      showTotalsAfterLevel: true,
      /* Show total row at the end of the table */
      showTotals: true,
      /* Use a thousand seperator for numeric values */
      thousandsSeperator: false,
      /* Show top most header row (grouped heading) */
      showHeading: true,
      /* Show table fields */
      showFields: true,
      /* Show the footer */
      showFooter: true,
      /* Break the collapsing on level border */
      breakCollapseOnLevel: true,
      /* Exports */
      exports: ['excel', 'pdf'], //  excel, pdf
      /* Allow page to break vertically */
      verticalPageBreak: true, // boolean
      /* Type of styling to use in case of Excel Export */
      stylingForExcel: 'simple', // simple OR complex
    },
    configuration: {
      fields: [
        { entry: { key: 'sublevel', label: 'TABLE.SUBLEVEL', displayLabel: false, controlType: 'sublevel', editable: false }},
        { entry: { key: 'general', label: 'REPORTS.ESTIMATION.GROUPS.GENERAL', controlType: 'group'}, children: [
          { entry: { key: 'reference', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.REFERENCE', fixedWidth: 75, sortable: true }},
          { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.NAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME', fixedWidth: 130, sortable: true }},
          { entry: { key: 'responsibleId', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.RESPONSIBLEID', addNull: true, source: 'humanresources', fixedWidth: 80, sortable: true }}
        ]},
        { entry: { key: 'space', label: '', controlType: 'space', editable: false }},
        { entry: { key: '', label: 'REPORTS.ESTIMATION.GROUPS.RELATIVEBENEFIT', controlType: 'group'}, children: [
          { entry: { key: 'relativeBenefit', type: 'number', controlType: 'dropdown', calculated: true, label: 'REPORTS.ESTIMATION.RELATIVEBENEFIT', editable: false, fixedWidth: 75, options: [
            { key: 0, value: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE' },
            { key: 1, value: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW' },
            { key: 2, value: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM' },
            { key: 3, value: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH' },
          ]}},
          { entry: { key: 'businessBenefit', type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.TITLE', sortable: true, filter: true, fixedWidth: 110, options: [
            { key: 0, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.NOTAPPLICABLE' },
            { key: 1, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.LOW' },
            { key: 2, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.MEDIUM' },
            { key: 3, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.HIGH' },
          ]}},
          { entry: { key: 'complexity', type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.COMPLEXITY', sortable: true, filter: true, fixedWidth: 110, options: [
            { key: 0, value: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE' },
            { key: 1, value: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW' },
            { key: 2, value: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM' },
            { key: 3, value: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH' },
          ]}}
        ]},
        { entry: { key: 'space', label: '', controlType: 'space', editable: false }},
        { entry: { key: '', label: 'REPORTS.ESTIMATION.GROUPS.RELATIVECOST', controlType: 'group'}, children: [
          { entry: { key: 'relativeCost', type: 'number', controlType: 'dropdown', calculated: true, editable: false, label: 'REPORTS.ESTIMATION.RELATIVECOST', fixedWidth: 75, options: [
            { key: 0, value: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE' },
            { key: 1, value: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW' },
            { key: 2, value: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM' },
            { key: 3, value: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH' },
          ]}},
          { entry: { key: 'risk', type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.RISK', sortable: true, filter: true, fixedWidth: 110, options: [
            { key: 0, value: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE' },
            { key: 1, value: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW' },
            { key: 2, value: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM' },
            { key: 3, value: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH' },
          ]}},
          { entry: { key: 'sizeType', type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.SIZETYPE', sortable: true, filter: true, fixedWidth: 110, options: [
            { key: 0, value: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE' },
            { key: 1, value: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW' },
            { key: 2, value: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM' },
            { key: 3, value: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH' },
          ]}},
          { entry: { key: 'uncertainty', type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.UNCERTAINTY', sortable: true, filter: true, fixedWidth: 110, options: [
            { key: 0, value: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE' },
            { key: 1, value: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW' },
            { key: 2, value: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM' },
            { key: 3, value: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH' },
          ]}}
          ]},
        { entry: { key: 'space', label: '', controlType: 'space', editable: false }},
        { entry: { key: 'relative', label: 'REPORTS.ESTIMATION.GROUPS.RELATIVE', controlType: 'group'}, children: [
          { entry: { key: 'relative', type: 'number', controlType: 'dropdown', calculated: true, editable: false, label: 'NODE.DATASHEET.FIELDS.RISK', fixedWidth: 75, options: [
            { key: 0, value: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE' },
            { key: 1, value: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW' },
            { key: 2, value: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM' },
            { key: 3, value: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH' }
          ]}}
        ]},
        { entry: { key: 'space', label: '', controlType: 'space', editable: false }},
        { entry: { key: 'absolutebenefit', label: 'REPORTS.ESTIMATION.GROUPS.ABSOLUTEBENEFIT', controlType: 'group'}, children: [
          { entry: { key: 'benefitBudget', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.BENEFITBUDGET', fixedWidth: 75, sortable: true }},
          { entry: { key: 'benefitActual', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.BENEFITACTUAL', fixedWidth: 75, sortable: true }},
          { entry: { key: 'benefitRemaining', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.BENEFITREMAINING', fixedWidth: 75, sortable: true }}
        ]},
        { entry: { key: 'space', label: '', controlType: 'space', editable: false }},
        { entry: { key: 'absolutecost', label: 'REPORTS.ESTIMATION.GROUPS.ABSOLUTECOST', controlType: 'group'}, children: [
          { entry: { key: 'costBudget', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.COSTBUDGET', fixedWidth: 75, sortable: true }},
          { entry: { key: 'costActual', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.COSTACTUAL', fixedWidth: 75, sortable: true }},
          { entry: { key: 'costRemaining', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.COSTREMAINING', fixedWidth: 75, sortable: true }}
        ]},
        { entry: { key: 'space', label: '', controlType: 'space', editable: false }},
        { entry: { key: 'absoluteinvest', label: 'REPORTS.ESTIMATION.GROUPS.ABSOLUTEINVEST', controlType: 'group'}, children: [
          { entry: { key: 'investBudget', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.INVESTBUDGET', fixedWidth: 75, sortable: true }},
          { entry: { key: 'investActual', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.INVESTACTUAL', fixedWidth: 75, sortable: true }},
          { entry: { key: 'investRemaining', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.INVESTREMAINING', fixedWidth: 75, sortable: true }}
        ]},
        { entry: { key: 'space', label: '', controlType: 'space', editable: false }},
        { entry: { key: 'datasheet', label: 'REPORTS.ESTIMATION.GROUPS.DATASHEET', controlType: 'group'}, children: [
          { entry: { key: 'crossReference', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.CROSSREFERENCE', fixedWidth: 75, sortable: true }},
          { entry: { key: 'level', controlType: 'dropdown', label: 'LEVEL.TITLE', editable: false, fixedWidth: 85, sortable: true, filter: true, options: [
            { key: 0, value: 'LEVEL.OBJECTIVE' },
            { key: 1, value: 'LEVEL.CHALLENGE' },
            { key: 2, value: 'LEVEL.SOLUTION' },
            { key: 3, value: 'LEVEL.DELIVERABLE' }
          ]}},
          { entry: { key: 'status', controlType: 'dropdown', label: 'STATUS.TITLE', fixedWidth: 85, sortable: true, filter: true, options: [
            { key: 0, value: 'STATUS.PENDING', color: '#999999' },
            { key: 100, value: 'STATUS.INDEFINE', color: '#000000' },
            { key: 110, value: 'STATUS.INDEVELOP', color: '#1099D6' },
            { key: 120, value: 'STATUS.INVERIFY', color: '#AA008F' },
            { key: 130, value: 'STATUS.INACCEPT', color: '#EDB922' },
            { key: 200, value: 'STATUS.COMPLETED', color: '#9ac93e' }
          ]}},
          { entry: { key: 'documentUri', type: 'text', controlType: 'textbox-button', buttonTitle: 'NODE.DATASHEET.FIELDS.OPENDOCUMENTURI', fixedWidth: 200, label: 'NODE.DATASHEET.FIELDS.DOCUMENTURI' }},
          { entry: { key: 'budget', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.KPI', fixedWidth: 85, sortable: true }},
          { entry: { key: 'budgetCalculated', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.AGGREGATIONKPI', sortable: true, fixedWidth: 85 }}
        ]},
        { entry: { key: 'space', label: '', controlType: 'space', editable: false }},
        { entry: { key: 'targetdate', label: 'REPORTS.ESTIMATION.GROUPS.TARGETDATE', controlType: 'group'}, children: [
          { entry: { key: 'targetDate', type: 'datetime', controlType: 'date', label: 'NODE.DATASHEET.FIELDS.TARGETDATE', sortable: true, fixedWidth: 85 }},
          { entry: { key: 'actualDate', type: 'datetime', controlType: 'date', label: 'NODE.DATASHEET.FIELDS.ACTUALDATE', sortable: true, fixedWidth: 85 }}
        ]},
        { entry: { key: 'space', label: '', controlType: 'space', editable: false }},
        { entry: { key: 'comments', label: 'REPORTS.ESTIMATION.GROUPS.COMMENTS', controlType: 'group'}, children: [
          { entry: { key: 'description', controlType: 'text', label: 'NODE.DATASHEET.FIELDS.DESCRIPTION', fixedWidth: 250}},
          { entry: { key: 'description1', controlType: 'text', label: 'NODE.DATASHEET.FIELDS.DESCRIPTION1', fixedWidth: 250}},
          { entry: { key: 'description2', controlType: 'text', label: 'NODE.DATASHEET.FIELDS.DESCRIPTION2', fixedWidth: 250}},
          { entry: { key: 'description3', controlType: 'text', label: 'NODE.DATASHEET.FIELDS.DESCRIPTION3', fixedWidth: 250}},
          { entry: { key: 'description4', controlType: 'text', label: 'NODE.DATASHEET.FIELDS.DESCRIPTION4', fixedWidth: 250}}
        ]}
      ]
    }
  }
];
