import { Record } from 'immutable';
import { RequestDiffRecord } from '../shared/common.models';

const aiStateRecord = Record({
  diff: new RequestDiffRecord(),
  isBusy: 0
});

export class AiState extends aiStateRecord {
  diff: RequestDiffRecord;
}
