import { IHumanResource, IHumanResourceAttributes } from '../connector';
import { Record, Set, Map } from 'immutable';
import { RecordFactory } from '../shared/record-factory';
import { DataModelState, RequestDiffRecord } from '../shared';

const humanResourceStateRecord = Record({
  ids: Set<string>(),
  tempIdsMap: Map<string, string>(),
  entities: Map<string, HumanResource>(),
  relationships: Map<string, HumanResourceRelationships>(),
  isBusy: 0,
  diff: new RequestDiffRecord()
});

export class HumanResourceState extends humanResourceStateRecord implements DataModelState {
  ids: Set<string>;
  tempIdsMap: Map<string, string>;
  entities: Map<string, HumanResource>;
  relationships: Map<string, HumanResourceRelationships>;
  isBusy: number;
  diff: RequestDiffRecord;
}

const humanResourceRecord = Record(<IHumanResourceAttributes>{
  id: undefined,
  email: undefined,
  name: undefined,
  first_name: undefined,
  last_name: undefined,
  color: undefined,
  can_login: undefined,
  foreign_id: undefined,
  permissions: undefined,
  notification_settings: undefined,
  image: undefined,
  storypoints: 0,
  updatedAt: undefined,
  relationships: undefined,
  original: undefined
});

export class HumanResource extends humanResourceRecord implements IHumanResourceAttributes {
  id: number;
  email: string;
  name: string;
  first_name: string;
  last_name: string;
  color: string;
  can_login: boolean;
  foreign_id: number;
  updatedAt: number;
  image: any;
  permissions: any;
  notification_settings: any;
  storypoints: number;
  original: string;

  relationships: HumanResourceRelationships;

  static fromJSONAPI(humanResource: IHumanResource) {
    return RecordFactory.entity.fromJSONAPI<HumanResource>(HumanResource, humanResource);
  }
}

const humanResourceRelationshipsRecord = Record({
  instance: undefined
});

export class HumanResourceRelationships extends humanResourceRelationshipsRecord {
  instance: string;

  static fromJSONAPI(humanResource: IHumanResource) {
    return RecordFactory.relationships.fromJSONAPI<HumanResourceRelationships>(HumanResourceRelationships, humanResource);
  }
}
