import { isNullOrUndefined } from 'util';

import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { Datum } from '../../../../shared/utilities/datum';

export class ColorLabelProviderServiceBasic extends ColorLabelProviderAbstract {

  public label(element: any, value: any) {
    let upper = value.toUpperCase();
    if (upper === 'BUDGET') {
      upper = 'KPI';
    }
    const pre = this.ignore.indexOf(value) === -1 ? this.translate('RADIAL.DEFAULT.DISPLAY.LABEL.TOP.' + upper) + ': ' : '';
    if (!!element && !!element.has && element.has(value)) {
      let result = element.get(value);
      if (typeof result === 'number') {
        /* To make the display label show only up to 2 decimal points
        * the + in front of result is to make sure that result is a
        * number because toFixed() will sometimes return a string.
        * more explanation at: https://stackoverflow.com/a/12830454/3523879 */
        result = +result.toFixed(2);
      }
      if (!isNullOrUndefined(result) && value === 'targetDate') {
        result = new Datum(element.get(value)).toDateString();
      }
      if (isNullOrUndefined(result)) {
        result = '';
      }
      return pre + result;
    } else {
      return pre + super.label(element, value);
    }
  }

}
