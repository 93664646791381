import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { SharedService } from '../shared/shared.service';
import { AppState } from '../../app.reducer';
import * as selectors from '../audits/audits.selectors';
import { AuditAction } from './audits.actions';

@Injectable()
export class AuditService extends SharedService {

    public constructor(protected store: Store<AppState>, protected action: AuditAction) {
        super(store, action, selectors);
    }

    public loadByIds(type: string, ids: string[], fromDate?: number, toDate?: number, limit?: number) {
      this.store.dispatch(this.action.load(type, ids, fromDate, toDate, limit));
    }

}
