import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import * as hello from 'hellojs';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class TokenService {

  private valueminer = this.initService();

  public get(): Observable<string> {
    const token = this.valueminer.login({ force: false, display: 'page' }).then(() => <string> this.valueminer.getAuthResponse().access_token);
    const promise = Promise.resolve(token);   // Abstraction to make the promise compatible
    return <Observable<string>> Observable.fromPromise(promise);
  }

  public refresh(): Observable<string> {
    const token = this.valueminer.login({ force: true, display: 'page' })
      .then(() => <string> this.valueminer.getAuthResponse().access_token);
    const promise = Promise.resolve(token);   // Abstraction to make the promise compatible
    return <Observable<string>> Observable.fromPromise(promise);
  }

  private initService() {
    hello.init({
      valueminer: {
        name: 'ValueMiner',
        oauth: {
          version: 2,
          auth: environment.auth.authurl,
          grant: environment.auth.tokenurl
        },
        display: 'page',
        refresh: true,
        scope: {
          api: 'api',
          users: 'users'
        },
        scope_delim: ' '
      }
    });

    hello.init(
      { valueminer: environment.auth.clientID },
      { scope: environment.auth.scopes.join(',') }
    );
    return hello('valueminer');
  }
}
