import { Action } from '../shared/common.action';
import { VersionState, Version } from './versions.models';
import { VersionAction } from './versions.actions';
import { Utils } from '../shared/utils';
import { JSONAPIDeserializerServiceNoRel } from '../shared';

export default function reducer(state = new VersionState(), action: Action): VersionState {
  const deserializer = new JSONAPIDeserializerServiceNoRel<Version>(Version.fromJSONAPI);

  switch (action.type) {
    case VersionAction.LOAD:
      return <VersionState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case VersionAction.LOAD_SUCCESS: {
      const {entities} = deserializer.deserialize(action.payload);
      return <VersionState>Utils.updateStateNoRel<VersionState>(state, entities)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case VersionAction.LOAD_FAIL: {
      return <VersionState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case VersionAction.CREATE: {
      return <VersionState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case VersionAction.CREATE_SUCCESS: {
      const {entities} = deserializer.deserialize(action.payload.response);
      return <VersionState>Utils.updateStateNoRel<VersionState>(state, entities, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case VersionAction.CREATE_FAIL: {
      return <VersionState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case VersionAction.REMOVE: {
      return <VersionState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case VersionAction.REMOVE_SUCCESS: {
      const {entities} = deserializer.deserialize(action.payload.response);
      return <VersionState>Utils.updateStateNoRel<VersionState>(state, entities, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case VersionAction.REMOVE_FAIL: {
      return <VersionState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case VersionAction.ROLLBACK: {
      return <VersionState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case VersionAction.ROLLBACK_SUCCESS: {
      const {entities} = deserializer.deserialize(action.payload.response);
      return <VersionState>Utils.updateStateNoRel<VersionState>(state, entities, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case VersionAction.ROLLBACK_FAIL: {
      return <VersionState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    default: {
      return state;
    }
  }
}
