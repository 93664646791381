import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { List } from 'immutable';

import { AppState } from '../../app.reducer';

import { Sync } from './syncs.models';
import { SyncAction } from './syncs.actions';
import * as selectors from './syncs.selectors';
import { SharedService } from '../shared/shared.service';

@Injectable()
export class SyncService extends SharedService {

  public constructor(protected store: Store<AppState>, protected action: SyncAction) {
    super(store, action, selectors);
  }

  public findByModelId(id: string): Observable<List<Sync>> {
    return this.getAPIState().let(selectors.getByModelId(id));
  }

  public extOAuth(data: any) {
    this.store.dispatch(this.action.extOAuth(data));
  }

  public sqs(data: any) {
    this.store.dispatch(this.action.sqs(data));
  }

}
