import { List } from 'immutable';

import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { LegendItem } from '../../../../shared/legend/legend.item';

export class ColorLabelProviderServiceImportance extends ColorLabelProviderAbstract {

  private static LOW = '#E50000';
  private static MEDIUM = '#EDD515';
  private static HIGH = '#9ac93e';

  public color(element: any) {
    switch (element.businessBenefit) {
      case 1:
        return [ColorLabelProviderServiceImportance.LOW];
      case 2:
        return [ColorLabelProviderServiceImportance.MEDIUM];
      case 3:
        return [ColorLabelProviderServiceImportance.HIGH];
      default:
        return super.color();
    }
  }

  public legend() {
    return List<LegendItem>([
      new LegendItem({ key: 'importance.low', color: ColorLabelProviderServiceImportance.LOW, label: 'LEGEND.IMPORTANCE.LOW', field: 'businessBenefit', value: 1 }),
      new LegendItem({ key: 'importance.medium', color: ColorLabelProviderServiceImportance.MEDIUM, label: 'LEGEND.IMPORTANCE.MEDIUM', field: 'businessBenefit', value: 2 }),
      new LegendItem({ key: 'importance.high', color: ColorLabelProviderServiceImportance.HIGH, label: 'LEGEND.IMPORTANCE.HIGH', field: 'businessBenefit', value: 3 })
    ]);
  }

}
