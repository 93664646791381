import { Injectable} from '@angular/core';
import { Store } from '@ngrx/store';
import { Socket } from '../connector';

import { AppState } from '../../app.reducer';

import { SettingsAction } from './settings.actions';
import * as selectors from './settings.selectors';
import { SharedService } from '../shared/shared.service';

@Injectable()
export class SettingsService extends SharedService {

  public constructor(protected store: Store<AppState>, protected action: SettingsAction, private socket: Socket) {
    super(store, action, selectors);
  }

}
