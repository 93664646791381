import { Injectable } from '@angular/core';
import { CoreService } from '../../../core/service/core.service';
import { NotificationsModalComponent } from '../modal/notifications.modal';
import { SelectionModalComponent } from '../../selection/modal/selection.modal';
import { NODES_TYPE_HUMANRESOURCE } from '../../../shared/api/nodes/nodes.models';
import { TreeNode } from '../../../core/interface/core.interface';
import { SubscriptionService } from '../../../shared/utilities/subscription';

@Injectable()
export class NotificationsService {

  public coreService: CoreService;

  private subscriptionService = new SubscriptionService();

  public alert(title: string, message: string) {
    return new Promise(resolve => this.show('alert', title, message).then(() => resolve()));
  }

  public confirm(title: string, message: string) {
    return new Promise(resolve => this.show('confirm', title, message).then(confirmed => resolve(confirmed)));
  }

  public error(title: string, message: string) {
    return new Promise(resolve => this.show('error', title, message).then(() => resolve()));
  }

  public show(type: string, title: string, message: string) {
    return new Promise(resolve => {
      this.subscriptionService.add('notifications-modal', this.coreService.getModal('notifications-modal').filter(_ => !!_).subscribe((modal: NotificationsModalComponent) => {
        this.subscriptionService.remove('notifications-modal');
        switch (type) {
          case 'error':
            modal.cancel.take(1).subscribe(() => {
              modal.dismiss().then(() => resolve());
            });
            modal.showError(title, message).then(() => {});
            break;
          case 'alert':
            modal.cancel.take(1).subscribe(() => {
              modal.dismiss().then(() => resolve());
            });
            modal.showAlert(title, message).then(() => {});
            break;
          case 'confirm':
            modal.continue.take(1).subscribe(() => {
              modal.dismiss().then(() => resolve(true));
            });
            modal.cancel.take(1).subscribe(() => {
              modal.dismiss().then(() => resolve(false));
            });
            modal.showConfirm(title, message).then(() => {});
            break;
        }
      }));
      this.coreService.showModal('notifications-modal');
    });
  }

}
