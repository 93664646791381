import { Injectable } from '@angular/core';
import { IInstance } from '../connector';

import { IPayload } from '../shared';
import { Action } from '../shared/common.action';

@Injectable()
export class InstanceAction {

  static key = 'Instance';

  static LOAD = `[${InstanceAction.key}] Load`;
  static LOAD_ALL = `[${InstanceAction.key}] Load all`;
  static LOAD_SUCCESS = `[${InstanceAction.key}] Load success`;
  static LOAD_FAIL = `[${InstanceAction.key}] Load fail`;

  static CREATE = `[${InstanceAction.key}] Create`;
  static CREATE_GO = `[${InstanceAction.key}] Create Go`;
  static CREATE_SUCCESS = `[${InstanceAction.key}] Create success`;
  static CREATE_FAIL = `[${InstanceAction.key}] Create fail`;

  static UPDATE = `[${InstanceAction.key}] Update`;
  static UPDATE_SUCCESS = `[${InstanceAction.key}] Update success`;
  static UPDATE_FAIL = `[${InstanceAction.key}] Update fail`;

  static REMOVE = `[${InstanceAction.key}] Remove`;
  static REMOVE_SUCCESS = `[${InstanceAction.key}] Remove success`;
  static REMOVE_FAIL = `[${InstanceAction.key}] Remove fail`;

  static ADD_RELATIONSHIP = `[${InstanceAction.key}] Add Relationship`;
  static REMOVE_RELATIONSHIP = `[${InstanceAction.key}] Remove Relationship`;

  public load(id: string, includes: string[] = []): Action {
    return {
      type: InstanceAction.LOAD,
      payload: {
        id: id,
        includes: includes
      }
    };
  }

  public loadAll(includes: string[] = []): Action {
    return {
      type: InstanceAction.LOAD_ALL,
      payload: {
        includes: includes
      }
    };
  }

  public loadSuccess(data: IInstance[]): Action {
    return {
      type: InstanceAction.LOAD_SUCCESS,
      payload: {
        response: data
      }
    };
  }

  public loadFail(error: any): Action {
    return {
      type: InstanceAction.LOAD_FAIL,
      payload: {
        request: undefined,
        response: error
      }
    };
  }

  public create(data: IPayload | IPayload[]): Action {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: InstanceAction.CREATE,
      payload: {
        data: data
      }
    };
  }

  public createGo(data: IPayload | IPayload[]): Action {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: InstanceAction.CREATE_GO,
      payload: {
        data: data
      }
    };
  }

  public createSuccess(payload: IPayload, response: IInstance): Action {
    return {
      type: InstanceAction.CREATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public createFail(payload: IPayload, error: any): Action {
    return {
      type: InstanceAction.CREATE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public update(data: IPayload | IPayload[]): Action {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: InstanceAction.UPDATE,
      payload: data
    };
  }

  public updateSuccess(payload: IPayload, response: IInstance): Action {
    return {
      type: InstanceAction.UPDATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public updateFail(payload: IPayload, error: any): Action {
    return {
      type: InstanceAction.UPDATE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public remove(ids: string | string[]): Action {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }
    return {
      type: InstanceAction.REMOVE,
      payload: {
        data: ids
      }
    };
  }

  public removeSuccess(payload: IPayload, response: IInstance): Action {
    return {
      type: InstanceAction.REMOVE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public removeFail(payload: IPayload, error: any): Action {
    return {
      type: InstanceAction.REMOVE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public addRelationship(id: string, type: string, relationship: string): Action {
    return {
      type: InstanceAction.ADD_RELATIONSHIP,
      payload: {
        id: id,
        type: type,
        relationship: relationship
      }
    };
  }

  public removeRelationship(id: string, type: string, relationship: string): Action {
    return {
      type: InstanceAction.REMOVE_RELATIONSHIP,
      payload: {
        id: id,
        type: type,
        relationship: relationship
      }
    };
  }
}
