import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { List } from 'immutable';

import { Utils } from '../shared/utils';
import { ImportSchemeState, ImportScheme, ImportSchemeRelationships } from './importschemes.models';
import { ImportSchemeAction } from './importschemes.actions';
import { JSONAPIDeserializerService } from '../shared/jsonapi-deserializer.service';
import { Action } from '../shared/common.action';

export default function reducer(state = new ImportSchemeState(), action: Action): ImportSchemeState {
  switch (action.type) {
      case ImportSchemeAction.LOAD:
        return <ImportSchemeState> state.withMutations(s => s
          .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
        );

      case ImportSchemeAction.LOAD_SUCCESS: {
        const deserializer = new JSONAPIDeserializerService<ImportScheme, ImportSchemeRelationships>(ImportScheme.fromJSONAPI, ImportSchemeRelationships.fromJSONAPI);
        const { entities, relationships } = deserializer.deserialize(action.payload);

        return <ImportSchemeState>Utils.updateState<ImportSchemeState>(state, entities, relationships)
          .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
      }

      case ImportSchemeAction.LOAD_FAIL:
        return <ImportSchemeState> Utils.updateDiff(state, action).withMutations(s => s
          .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
        );

      case ImportSchemeAction.CREATE:
        return <ImportSchemeState> state.withMutations(s => s
          .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
        );

      case ImportSchemeAction.CREATE_SUCCESS: {
        const deserializer = new JSONAPIDeserializerService<ImportScheme, ImportSchemeRelationships>(ImportScheme.fromJSONAPI, ImportSchemeRelationships.fromJSONAPI);
        const {entities, relationships} = deserializer.deserialize(action.payload.response);

        return <ImportSchemeState>Utils.updateState<ImportSchemeState>(state, entities, relationships, action)
          .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
      }

      case ImportSchemeAction.CREATE_FAIL: {
        return <ImportSchemeState> Utils.updateDiff(state, action).withMutations(s => s
          .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
        );
      }

      case ImportSchemeAction.REMOVE:
        return <ImportSchemeState> state.withMutations(s => s
          .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
        );

      case ImportSchemeAction.REMOVE_SUCCESS: {
        const deserializer = new JSONAPIDeserializerService<ImportScheme, ImportSchemeRelationships>(ImportScheme.fromJSONAPI, ImportSchemeRelationships.fromJSONAPI);
        const {entities, relationships} = deserializer.deserialize(action.payload.response);

        return <ImportSchemeState>Utils.deleteFromState<ImportSchemeState>(state, entities, relationships, action)
          .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
      }
      case ImportSchemeAction.REMOVE_FAIL:
        return <ImportSchemeState> Utils.updateDiff(state, action).withMutations(s => s
          .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
        );

      default: {
          return state;
      }
  }
}

export function getIsBusy() {
    return (state$: Store<ImportSchemeState>) => state$
        .select((s: ImportSchemeState) => !!s.isBusy);
}
