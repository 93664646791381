import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducer';
import { NodeCreate } from '../nodes/nodes.models';
import { RelationshipCreate } from '../relationships';
import { IPayload } from '../shared';
import { NodesAndRelationshipsAction } from './nodes-relationships.action';
import { INodesRelationships } from './nodes-relationships.models';

@Injectable()
export class NodesAndRelationshipsService {

  public constructor(private store: Store<AppState>,
                    private action: NodesAndRelationshipsAction) {}

  public create(data: any) {
    this.store.dispatch(this.action.create(data));
  }

}
