
import { IPayload } from '../shared/common.models';
import { Template } from './templates.models';
import { Action } from '../shared/common.action';
import { ITemplate } from '../connector/models';

export class TemplateAction {
  static key = 'Template';

  static LOAD = `[${TemplateAction.key}] Load`;
  static LOAD_SUCCESS = `[${TemplateAction.key}] Load success`;
  static LOAD_FAIL = `[${TemplateAction.key}] Load fail`;

  static CREATE = `[${TemplateAction.key}] Create`;
  static CREATE_SUCCESS = `[${TemplateAction.key}] Create success`;
  static CREATE_FAIL = `[${TemplateAction.key}] Create fail`;

  static UPDATE = `[${TemplateAction.key}] Update`;
  static UPDATE_SUCCESS = `[${TemplateAction.key}] Update success`;
  static UPDATE_FAIL = `[${TemplateAction.key}] Update fail`;

  static REMOVE = `[${TemplateAction.key}] Remove`;
  static REMOVE_SUCCESS = `[${TemplateAction.key}] Remove success`;
  static REMOVE_FAIL = `[${TemplateAction.key}] Remove fail`;

  public load(): Action {
    return {
      type: TemplateAction.LOAD,
      payload: null
    };
  }

  public loadSuccess(data: ITemplate[]): Action {
    return {
      type: TemplateAction.LOAD_SUCCESS,
      payload: {
        response: data
      }
    };
  }

  public loadFail(error: any): Action {
    return {
      type: TemplateAction.LOAD_FAIL,
      payload: {
        request: undefined,
        response: error
      }
    };
  }

  public create(data: IPayload | IPayload[]): Action {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: TemplateAction.CREATE,
      payload: {
        data: data
      }
    };
  }

  public createSuccess(payload: IPayload, response: ITemplate): Action {
    return {
      type: TemplateAction.CREATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public createFail(payload: IPayload, error: any): Action {
    return {
      type: TemplateAction.CREATE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public update(data: IPayload | IPayload[]): Action {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: TemplateAction.UPDATE,
      payload: data
    };
  }

  public updateSuccess(payload: IPayload, response: ITemplate): Action {
    return {
      type: TemplateAction.UPDATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public updateFail(payload: IPayload, error: any): Action {
    return {
      type: TemplateAction.UPDATE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public remove(ids: string | string[]): Action {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }
    return {
      type: TemplateAction.REMOVE,
      payload: {
        data: ids
      }
    };
  }

  public removeSuccess(payload: IPayload, response: ITemplate): Action {
    return {
      type: TemplateAction.REMOVE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public removeFail(payload: IPayload, error: any): Action {
    return {
      type: TemplateAction.REMOVE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

}
