import { combineReducers, ActionReducer, Action } from '@ngrx/store';

import { InstanceState, reducer as instanceReducer } from './instances/index';
import { HierarchyState, reducer as hierarchyReducer } from './hierarchy/index';
import { ModelState, reducer as modelReducer } from './models/index';
import { UserState, reducer as userReducer } from './user/index';
import { NotificationState, reducer as notificationReducer } from './notifications/index';
import { RelationshipState, reducer as relationshipReducer } from './relationships/index';
import { NodeStructureState, reducer as nodestructureReducer } from './nodestructures/index';
import { ImportSchemeState, reducer as importschemeReducer } from './importschemes/index';
import { NodeDataState, reducer as nodedataReducer } from './nodedata/index';
import { GroupState, reducer as groupReducer} from './groups';
import { BusinessareaState, reducer as businessareaReducer } from './businessareas';
import { SubsetState, reducer as subsetReducer } from './subsets';
import { HumanResourceState, reducer as humanResourceReducer } from './humanresources';
import { ActivityState, reducer as activityReducer } from './activities';
import { TemplateState, reducer as templateReducer } from './templates';
import { VersionState, reducer as versionReducer } from './versions';
import { SyncState, reducer as syncReducer } from './syncs';
import { Audit, reducer as auditReducer } from './audits';
import { SettingsState, reducer as settingsReducer } from './settings';
import { ImportState, reducer as importReducer } from './import';
import { NodeState, reducer as nodeReducer } from './node';
import { OneTimeTokenState, reducer as oneTimeTokenReducer } from './onetimetoken';
import { compose } from '../utilities/compose';
import { MiningState, reducer as miningReducer } from './mining';
import { WorkflowState, reducer as workflowReducer } from './workflow';
import { FormState, reducer as formReducer } from './form';
import { EmailState, reducer as emailReducer } from './email';
import { AiState, reducer as aiReducer } from './ai';
import { GeneralState, reducer as generalReducer } from './general';
import { GuardianState, reducer as guardianReducer } from './guardian';
import { MyState, reducer as myReducer } from './my';

export interface APIState {
    instances: InstanceState;
    businessareas: BusinessareaState;
    models: ModelState;
    subsets: SubsetState;
    relationships: RelationshipState;
    nodeStructures: NodeStructureState;
    nodeData: NodeDataState;
    nodes: NodeState;
    notifications: NotificationState;
    importschemes: ImportSchemeState;
    humanResources: HumanResourceState;
    activities: ActivityState;
    user: UserState;
    groups: GroupState;
    templates: TemplateState;
    versions: VersionState;
    syncs: SyncState;
    hierarchy: HierarchyState;
    audits: Audit;
    settings: SettingsState;
    node: NodeState;
    oneTimeToken: OneTimeTokenState;
    import: ImportState;
    mining: MiningState;
    workflow: WorkflowState;
    form: FormState;
    email: EmailState;
    ai: AiState;
    general: GeneralState;
    guardian: GuardianState;
    my: MyState;
}

/**
 * Because metareducers take a reducer function and return a new reducer,
 * we can use our compose helper to chain them together. Here we are
 * using combineReducers to make our top level reducer, and then
 * wrapping that in storeLogger. Remember that compose applies
 * the result from right to left.
 */
const apiReducer: any = compose(combineReducers)({
  instances: instanceReducer,
  businessareas: businessareaReducer,
  models: modelReducer,
  subsets: subsetReducer,
  relationships: relationshipReducer,
  nodeStructures: nodestructureReducer,
  nodeData: nodedataReducer,
  notifications: notificationReducer,
  importschemes: importschemeReducer,
  humanResources: humanResourceReducer,
  activities: activityReducer,
  user: userReducer,
  groups: groupReducer,
  templates: templateReducer,
  versions: versionReducer,
  syncs: syncReducer,
  hierarchy: hierarchyReducer,
  audits: auditReducer,
  settings: settingsReducer,
  node: nodeReducer,
  oneTimeToken: oneTimeTokenReducer,
  import: importReducer,
  mining: miningReducer,
  workflow: workflowReducer,
  form: formReducer,
  email: emailReducer,
  ai: aiReducer,
  general: generalReducer,
  guardian: guardianReducer,
  my: myReducer
});

export function reducer(state: any, action: any): APIState {
    return apiReducer(state, action);
}
