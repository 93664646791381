export const TemplateFieldsBusinessareas = {
  tabs: [
    {
      entry: { key: 'header1', label: 'BUSINESSAREA.DATASHEET.HEADERS.HEADER1' },
      children: [
        { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'BUSINESSAREA.DATASHEET.FIELDS.NAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME' }},
        { entry: { key: 'reference', type: 'text', controlType: 'textbox', label: 'BUSINESSAREA.DATASHEET.FIELDS.REFERENCE' }},
        { entry: { key: 'crossReference', type: 'text', controlType: 'textbox', label: 'BUSINESSAREA.DATASHEET.FIELDS.CROSSREFERENCE' }},
        { entry: { key: 'isAggregationEnabled', controlType: 'checkbox', label: 'BUSINESSAREA.DATASHEET.FIELDS.AGGREGATIONENABLED' }},
        { entry: { key: 'isNormalizationEnabled', controlType: 'checkbox', label: 'BUSINESSAREA.DATASHEET.FIELDS.NORMALIZATIONENABLED' }},
        { entry: { key: 'description', controlType: 'text', label: 'BUSINESSAREA.DATASHEET.FIELDS.DESCRIPTION'}},
        { entry: { key: 'type', controlType: 'dropdown', label: 'BUSINESSAREA.DATASHEET.FIELDS.TYPE.TITLE', options: [
          { key: 0, value: '' },
          { key: 1, value: 'BUSINESSAREA.DATASHEET.FIELDS.TYPE.IPNAVIGATOR' },
          { key: 2, value: 'BUSINESSAREA.DATASHEET.FIELDS.TYPE.LIBRARY' },
          { key: 3, value: 'BUSINESSAREA.DATASHEET.FIELDS.TYPE.MODULE' },
          { key: 4, value: 'BUSINESSAREA.DATASHEET.FIELDS.TYPE.SETUP' }
        ]}}
      ]
    },
    {
      entry: { key: 'header2', label: 'BUSINESSAREA.DATASHEET.HEADERS.HEADER2' },
      children: [
        { entry: { key: 'humanresource', controlType: 'dropdown', label: 'BUSINESSAREA.DATASHEET.FIELDS.RESPONSIBLE', source: 'humanresources', hasNull: true }},
        { entry: { key: 'color', type: 'text', controlType: 'color', label: 'BUSINESSAREA.DATASHEET.FIELDS.COLOR' }},
        { entry: { key: 'targetpicture', controlType: 'text', label: 'BUSINESSAREA.DATASHEET.FIELDS.TARGETPICTURE'}}
      ]
    },
    {
      entry: { key: 'header3', label: 'BUSINESSAREA.DATASHEET.HEADERS.HEADER3' },
      children: [
        { entry: { key: 'status', controlType: 'dropdown', label: 'BUSINESSAREA.DATASHEET.FIELDS.STATUS', options: [
          { key: 0, value: 'STATUS.PENDING' },
          { key: 1, value: 'STATUS.INDEFINE' },
          { key: 2, value: 'STATUS.INDEVELOP' },
          { key: 3, value: 'STATUS.INVERIFY' },
          { key: 4, value: 'STATUS.INACCEPT' },
          { key: 5, value: 'STATUS.COMPLETED' }
        ]}},
        { entry: { key: 'commercialStatus', controlType: 'dropdown', label: 'BUSINESSAREA.DATASHEET.FIELDS.COMMERCIALSTATUS', options: [
          { key: 0, value: 'COMMERCIALSTATUS.NA' },
          { key: 1, value: 'COMMERCIALSTATUS.REGISTERED' },
          { key: 2, value: 'COMMERCIALSTATUS.TOBEINVESTIGATED' },
          { key: 3, value: 'COMMERCIALSTATUS.TOBEAPPROVED' },
          { key: 4, value: 'COMMERCIALSTATUS.CONDACCEPTED' },
          { key: 5, value: 'COMMERCIALSTATUS.ACCEPTED' },
          { key: 6, value: 'COMMERCIALSTATUS.INVOICED' },
          { key: 7, value: 'COMMERCIALSTATUS.REJECTED' },
          { key: 8, value: 'COMMERCIALSTATUS.CLOSED' }
        ]}},
        { entry: { key: 'validfrom', type: 'datetime', controlType: 'date', label: 'BUSINESSAREA.DATASHEET.FIELDS.VALIDFROM' }},
        { entry: { key: 'date', type: 'datetime', controlType: 'date', label: 'BUSINESSAREA.DATASHEET.FIELDS.TARGETDATE' }},
        { entry: { key: 'icon', type: 'text', controlType: 'icon', label: 'BUSINESSAREA.DATASHEET.FIELDS.ICON' }}
      ]
    }
  ]
};
