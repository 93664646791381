import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';

import { AppState } from '../../app.reducer';
import { NodeAction } from './node.actions';
import { Node, Request } from './node.models';
import * as selectors from './node.selectors';
import { List, Map } from 'immutable';
import { RequestDiffRecord } from '../shared';

@Injectable()
export class GoService {

  public isBusy: Observable<number>;
  public isBusyIPC: Observable<number>;
  public diff: Observable<RequestDiffRecord>;

  public constructor(private store: Store<AppState>, private action: NodeAction) {
    this.isBusy = this.getAPIState().let(selectors.getIsBusy());
    this.isBusyIPC = this.getAPIState().let(selectors.getIsBusyIPC());
    this.diff = this.getAPIState().let(selectors.getDiff());
  }

  public byBusinessArea(id: number): Observable<List<Node>> {
    return this.getAPIState().let(selectors.getMany()).map((nodes: List<Node>) => {
      return <List<Node>> nodes.filter(node => node.relationships.businessarea.id === id);
    });
  }

  public secondaryCostsByBusinessArea(id: string): Observable<Map<string, List<Node>>> {
    return this.getAPIState().let(selectors.getSecondaryCosts());
  }

  public loadByBusinessArea(id: string, request: Request) {
    this.store.dispatch(this.action.businessarea(id, request));
  }

  public loadIpcClasses(classes: { id: string, classes: string[] }[]) {
    this.store.dispatch(this.action.ipc(classes));
  }

  protected getAPIState() {
    return this.store.select((state: AppState) => state.api);
  }

}
