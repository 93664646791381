export const TemplateFieldsSubsets = {
  tabs: [
    {
      entry: { key: 'header1', label: 'SUBSET.DATASHEET.HEADERS.HEADER1' },
      children: [
        { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'SUBSET.DATASHEET.FIELDS.NAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME' }},
        { entry: { key: 'reference', type: 'text', controlType: 'textbox', label: 'SUBSET.DATASHEET.FIELDS.REFERENCE' }},
        { entry: { key: 'crossReference', type: 'text', controlType: 'textbox', label: 'SUBSET.DATASHEET.FIELDS.CROSSREFERENCE' }},
        { entry: { key: 'description', controlType: 'text', label: 'SUBSET.DATASHEET.FIELDS.DESCRIPTION'}}
      ]
    },
    {
      entry: { key: 'header2', label: 'SUBSET.DATASHEET.HEADERS.HEADER2' },
      children: [
        { entry: { key: 'responsibleId', controlType: 'dropdown', label: 'SUBSET.DATASHEET.FIELDS.RESPONSIBLE', source: 'humanresources', hasNull: true }},
        { entry: { key: 'color', type: 'text', controlType: 'color', label: 'SUBSET.DATASHEET.FIELDS.COLOR' }}
      ]
    },
    {
      entry: { key: 'header3', label: 'SUBSET.DATASHEET.HEADERS.HEADER3' },
      children: [
        { entry: { key: 'status', controlType: 'dropdown', label: 'SUBSET.DATASHEET.FIELDS.STATUS', options: [
          { key: 0, value: 'STATUS.PENDING' },
          { key: 1, value: 'STATUS.INDEFINE' },
          { key: 2, value: 'STATUS.INDEVELOP' },
          { key: 3, value: 'STATUS.INVERIFY' },
          { key: 4, value: 'STATUS.INACCEPT' },
          { key: 5, value: 'STATUS.COMPLETED' }
        ]}},
        { entry: { key: 'commercialStatus', controlType: 'dropdown', label: 'SUBSET.DATASHEET.FIELDS.COMMERCIALSTATUS', options: [
          { key: 0, value: 'COMMERCIALSTATUS.NA' },
          { key: 1, value: 'COMMERCIALSTATUS.REGISTERED' },
          { key: 2, value: 'COMMERCIALSTATUS.TOBEINVESTIGATED' },
          { key: 3, value: 'COMMERCIALSTATUS.TOBEAPPROVED' },
          { key: 4, value: 'COMMERCIALSTATUS.CONDACCEPTED' },
          { key: 5, value: 'COMMERCIALSTATUS.ACCEPTED' },
          { key: 6, value: 'COMMERCIALSTATUS.INVOICED' },
          { key: 7, value: 'COMMERCIALSTATUS.REJECTED' },
          { key: 8, value: 'COMMERCIALSTATUS.CLOSED' }
        ]}},
        { entry: { key: 'date', type: 'datetime', controlType: 'date', label: 'SUBSET.DATASHEET.FIELDS.TARGETDATE' }}
      ]
    }
  ]
};
