export const TemplateFieldsChecklist = {
  tabs: [
    {
      entry: { key: 'header1', label: '' },
      children: [
        { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'DASHBOARD.REQUIREMENTDEFINITION.FIELDS.NAME', maxLength: 255, validations: [ 'required' ], validationMessages: { required: 'DASHBOARD.REQUIREMENTDEFINITION.VALIDATION.REQUIRED.NAME' } }}
      ]
    }
  ]
};
