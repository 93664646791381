import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '../local-storage-service';
import { Map } from 'immutable';
import { ThirdPartyTrendExplorerService } from './thirdparty.trendexplorer.service';
import { GoService } from '../../api/node';
import { GoAPI, TokenService } from '../../api/connector/shared';

export interface IAccount {
  id: string;
  name: string;
  url: string;
  username: string;
  password: string;
  clientId: string;
  clientSecret: string;
  status: string;
  authToken: string;
  accessToken: string;
  refreshToken: string;
  error?: { status: number; message: string };
}

@Injectable()
export class ThirdPartyService {

  protected service: any;
  protected account: IAccount;
  protected connectivity = Map<string, BehaviorSubject<boolean>>();

  public constructor(protected http: HttpClient,
                     protected localStorageService: LocalStorageService,
                     protected tokenService: TokenService) {}

  public connected(account: string): Observable<boolean> {
    if (!this.connectivity.has(account)) {
      this.connectivity = this.connectivity.set(account, new BehaviorSubject<boolean>(false));
    }
    return this.connectivity.get(account);
  }

  public updateAccount(account: IAccount) {
    this.account = account;
    if (!this.connectivity.has(this.account.id)) {
      this.connectivity = this.connectivity.set(this.account.id, new BehaviorSubject<boolean>(false));
    }
    this.connectivity.get(this.account.id).next(this.account.status === 'connected');
    this.localStorageService.set('accounts-' + account.id, account);
  }

  public status(account: string) {
    return this.initialize(this.getAccount(account)).getToken();
  }

  public initialize(account: IAccount): ThirdPartyService {
    this.account = account;
    return this;
  }

  public getToken(): Observable<IAccount> {
    return Observable.empty();
  }

  public getTokenByRefreshToken(): Observable<IAccount> {
    return Observable.empty();
  }

  public getAccount(id: string): IAccount {
    const account = <IAccount> this.localStorageService.get('accounts-' + id);
    return !!account ? account : <IAccount> { id: '', url: '', username: '', password: '', authToken: '', refreshToken: '', accessToken: '', status: '', name: '', error: undefined };
  }

  public request(method: string, url: string, body: {} = {}, token?: string): Observable<{}> {
    return this.sendRequest(this.getToken(), method, url, body)
      .catch((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return this.retryRequest(method, url, body);
        }
        return Observable.empty();
      }).catch((err: any) => {
        return Observable.empty();
      });
  }

  public retryRequest(method: string, url: string, body: any) {
    return this.sendRequest(this.getTokenByRefreshToken(), method, url, body)
      .catch((error: HttpErrorResponse) => {
        this.disconnectAccount();
        return new BehaviorSubject(error);
      })
      .catch(() => Observable.empty());
  }

  public disconnectAccount() {
    this.account.accessToken = '';
    this.account.refreshToken = '';
    this.account.status = 'disconnected';
    this.updateAccount(this.account);
  }

  public sendRequest(tokenObservable: Observable<IAccount>, method: string, url: string, body: {} = {}): Observable<Object> {
    return tokenObservable.flatMap((account: IAccount) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/vnd.api.v2+json',
        'Authorization': 'Bearer ' + account.accessToken
      });
      return this.http.request(method, url, {
        body: JSON.stringify(body),
        headers: headers
      });
    });
  }

}
