export const TemplateFieldsReportsComplianceStatus = [
  {
    type: 'table',
    settings: {
      /* Group the elements by model */
      groupByModels: true,
      /* Use a thousand seperator for numeric values */
      thousandsSeperator: '.',
      /* How many decimals should be shown on numeric values */
      decimalnumbers: 2,
      /* Show top most header row (grouped heading) */
      showHeading: true,
      /* Show table fields */
      showFields: true,
      /* Show models to choose from in table footer */
      showModelsSelectBox: true,
      /* Show the footer */
      showFooter: true,
      /* A filter function to filter by */
      filter: 'onreports',
      /* Trigger to load the Businessarea with that table */
      loadBusinessarea: true,
      /* Show sidestep top most / tree below related node */
      showSidestep: 'tree', // false, tree, topmost
      /* Exports */
      exports: ['pdf', 'excel'], //  excel, pdf
      /* Allow page to break vertically */
      verticalPageBreak: true
    },
    configuration: {
      fields: [
        { entry: { key: 'structure', label: 'TABLE.STRUCTURE.TITLE', displayLabel: false, controlType: 'structure', shape: 'ellipsis-v', additional: { addchild: true, addsibling: true, edit: true, delete: true, create: true, unlink: true, changeparent: true, link: true } }},
        { entry: { key: 'wizard', label: '', controlType: 'wizard', source: 'email-todo', icon: 'envelope', editable: true, fixedWidth: 30 }},
        { entry: { key: 'wizard', label: '', controlType: 'wizard', source: 'strategy-overview-edit', icon: 'project-diagram', editable: true, fixedWidth: 30 }},
        { entry: { key: 'number', label: '', controlType: 'group' }, children: [
            { entry: { key: 'number', controlType: 'textbox', label: 'REPORTS.COMPLIANCESTATUS.NR', editable: false, calculated: true, fixedWidth: 46 }}
          ]
        },
        { entry: { key: 'levels', label: 'TABLE.LEVELS', controlType: 'levels' }},
        { entry: { key: 'general', label: 'TABLE.GENERAL', displayLabel: false, controlType: 'group' }, children: [
            { entry: { key: 'model', controlType: 'textbox', label: 'REPORTS.COMPLIANCESTATUS.STRATEGICCLASSIFICATION', editable: false, sortable: true, calculated: true, fixedWidth: 90 }},
            { entry: { key: 'objectiveLeaf', controlType: 'textbox', label: 'REPORTS.COMPLIANCESTATUS.OBJECTIVELEAF', editable: false, sortable: true, calculated: true, fixedWidth: 91 }},
            { entry: { key: 'challengeLeaf', controlType: 'textbox', label: 'REPORTS.COMPLIANCESTATUS.CHALLENGELEAF', editable: false, sortable: true, calculated: true, fixedWidth: 93 }},
            { entry: { key: 'status', controlType: 'dropdown', label: 'STATUS.TITLE', sortable: true, filter: true, fixedWidth: 113, options: [
              { key: 0, value: 'STATUS.PENDING', shape: 'circle', color: '#999999' },
              { key: 100, value: 'STATUS.INDEFINE', shape: 'circle', color: '#000000' },
              { key: 110, value: 'STATUS.INDEVELOP', shape: 'circle', color: '#1099D6' },
              { key: 120, value: 'STATUS.INVERIFY', shape: 'circle', color: '#AA008F' },
              { key: 130, value: 'STATUS.INACCEPT', shape: 'circle', color: '#EDB922' },
              { key: 200, value: 'STATUS.COMPLETED', shape: 'circle', color: '#9ac93e' }
            ]}},
            { entry: { key: 'responsibleId', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.RESPONSIBLEID', fixedWidth: 140, sortable: true, filter: true, hasNull: true, source: 'humanresources' }},
            { entry: { key: 'technical', controlType: 'dropdown', label: 'REPORTS.COMPLIANCESTATUS.ITCHALLENGES.TITLE', fixedWidth: 93, sortable: true, options: [
              { key: 0, value: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE' },
              { key: 1, value: 'REPORTS.COMPLIANCESTATUS.ITCHALLENGES.FINANCIAL' },
              { key: 2, value: 'REPORTS.COMPLIANCESTATUS.ITCHALLENGES.LICENCES' },
              { key: 3, value: 'REPORTS.COMPLIANCESTATUS.ITCHALLENGES.EXPORT' },
              { key: 4, value: 'REPORTS.COMPLIANCESTATUS.ITCHALLENGES.PRISON' },
              { key: 5, value: 'REPORTS.COMPLIANCESTATUS.ITCHALLENGES.LOSSOFREPUTATION' },
              { key: 6, value: 'REPORTS.COMPLIANCESTATUS.ITCHALLENGES.LOSSOFMARKET' }
            ]}},
            { entry: { key: 'benefitBudget', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.BENEFITBUDGET', fixedWidth: 80, sortable: true }},
            { entry: { key: 'costBudget', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.COSTBUDGET', sortable: true, fixedWidth: 80 }},
            { entry: { key: 'investBudget', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.INVESTBUDGET', sortable: true, fixedWidth: 80 }},
            { entry: { key: 'risk', type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.RISK', fixedWidth: 103, filter: true, sortable: true, options: [
              { key: 0, value: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE', color: '#999999', shape: 'circle' },
              { key: 1, value: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW', color: '#9ac93e', shape: 'circle' },
              { key: 2, value: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM', color: '#EDD515', shape: 'circle' },
              { key: 3, value: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH', color: '#E50000', shape: 'circle' }
            ]}},
            { entry: { key: 'resource', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.RESOURCE.TITLE', fixedWidth: 90, sortable: true, options: [
              { key: 0, value: 'NODE.DATASHEET.FIELDS.RESOURCE.NOTAPPLICABLE' },
              { key: 1, value: 'NODE.DATASHEET.FIELDS.RESOURCE.LOW' },
              { key: 2, value: 'NODE.DATASHEET.FIELDS.RESOURCE.MEDIUM' },
            ]}},
            { entry: { key: 'description3', controlType: 'text', label: 'NODE.DATASHEET.FIELDS.DESCRIPTION3', fixedWidth: 250, editable: true, sortable: false, calculated: false}},
            { entry: { key: 'description', controlType: 'text', label: 'NODE.DATASHEET.FIELDS.DESCRIPTION', fixedWidth: 250, editable: true, sortable: true, calculated: false}},
            { entry: { key: 'description4', controlType: 'text', label: 'NODE.DATASHEET.FIELDS.DESCRIPTION4', fixedWidth: 250, editable: true, sortable: false, calculated: false}},
            { entry: { key: 'documentUri', type: 'text', controlType: 'textbox-button', buttonTitle: 'NODE.DATASHEET.FIELDS.OPENDOCUMENTURI', sortable: true, fixedWidth: 150, label: 'NODE.DATASHEET.FIELDS.DOCUMENTURI' }},
            { entry: { key: 'upload_uri', type: 'text', controlType: 'textbox-button', buttonTitle: 'NODE.DATASHEET.FIELDS.UPLOADURI', sortable: false, fixedWidth: 150, label: 'NODE.DATASHEET.FIELDS.UPLOADURI' }}
          ]
        }
      ]
    }
  }
];
