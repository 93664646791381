import { Record, Set, Map } from 'immutable';
import { DataModelState, RequestDiffRecord } from '../shared';
import { RecordFactory } from '../shared/record-factory';

const stateRecord = Record({
  ids: Set<string>(),
  entities: Map<string, Sync>(),
  relationships: undefined,
  diff: new RequestDiffRecord(),
  isBusy: 0,
});

export class SyncState extends stateRecord implements DataModelState {
  ids: Set<string>;
  entities: Map<string, Sync>;
  relationships: undefined;
  diff: RequestDiffRecord;
  isBusy: number;
}

const syncRecord = Record({
  id: undefined,
  instanceId: 0,
  businessareaId: 0,
  modelId: 0,
  clientUid: '',
  clientId: '',
  clientSecret: '',
  clientUri: '',
  clientToken: '',
  synced: false,
  additionalData: {},
  created_at: undefined,
  updated_at: undefined,
  userId: undefined,
  formGroup: undefined,
  fields: [],
  formErrors: Map<string, string>(),
  userbound: false,
  loading: false,
  blocked: false
});

export class Sync extends syncRecord {
  id: string;
  instanceId: number;
  businessareaId: number;
  modelId: number;
  clientUid: string;
  clientId: string;
  clientSecret: string;
  clientUri: string;
  clientToken: string;
  synced: boolean;
  additionalData: any;
  created_at: number;
  updated_at: number;
  userId: string;
  formGroup: any;
  fields: string[];
  formErrors: Map<string, string>;
  userbound: boolean;
  loading: boolean;
  blocked: boolean;

  static fromJSONAPI(sync: any) {
    return RecordFactory.entity.fromJSONAPI<Sync>(Sync, sync);
  }
}
