export const TemplateFieldsAccountOctimine = {
  tabs: [
    {
      entry: { key: 'header1', label: '' },
      children: [
        { entry: { key: 'url', type: 'text', controlType: 'textbox', label: 'LINKACCOUNTS.FIELDS.URL' }},
        { entry: { key: 'username', type: 'text', controlType: 'textbox', label: 'LINKACCOUNTS.FIELDS.USERNAME' }},
        { entry: { key: 'password', type: 'password', controlType: 'textbox', label: 'LINKACCOUNTS.FIELDS.PASSWORD' }}
      ]
    }
  ]
};

export const TemplateFieldsAccountTrendExplorer = {
  tabs: [
    {
      entry: { key: 'header1', label: '' },
      children: [
        { entry: { key: 'url', type: 'text', controlType: 'textbox', label: 'LINKACCOUNTS.FIELDS.URL' }},
        { entry: { key: 'clientId', type: 'text', controlType: 'textbox', label: 'LINKACCOUNTS.FIELDS.CLIENTID' }},
        { entry: { key: 'clientSecret', type: 'password', controlType: 'textbox', label: 'LINKACCOUNTS.FIELDS.CLIENTSECRET' }}
      ]
    }
  ]
};

export const TemplateFieldsAccountCrunchbase = {
  tabs: [
    {
      entry: { key: 'header1', label: '' },
      children: [
        { entry: { key: 'url', type: 'text', controlType: 'textbox', label: 'LINKACCOUNTS.FIELDS.URL' }},
        { entry: { key: 'accessToken', type: 'text', controlType: 'textbox', label: 'LINKACCOUNTS.FIELDS.ACCESSTOKEN' }},
      ]
    }
  ]
};
