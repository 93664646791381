export const TemplateFieldsReportsObjectiveModelMeasures = [
  {
    type: 'table',
    settings: {
      /* Caption element above the table */
      caption: { text: 'REPORTS.OBJECTIVEMODEL.CAPTION' },
      /* Show the footer */
      showFooter: true,
      /* Show legend dropup */
      showLegend: true,
      /* Use a thousand seperator for numeric values */
      thousandsSeperator: '´',
      /* Show top most by sidestep */
      topMostBySidestep: true,
      /* Trigger to load the Businessarea with that table */
      loadBusinessarea: true,
      /* A filter function to filter by */
      filter: 'objectivesjustmodel',
      /* Exports */
      exports: ['excel', 'pdf'], //  excel, pdf
    },
    configuration: {
      fields: [
        { entry: { key: '', label: 'TABLE.GENERAL', controlType: 'group'}, children: [
            { entry: { key: 'measures', controlType: 'measures', label: 'REPORTS.OBJECTIVEBUSINESSAREAMEASURES.FORMAT', fixedWidth: 70 }},
            { entry: { key: 'number', controlType: 'primary', label: 'REPORTS.OBJECTIVEMODEL.NUMBER', calculated: true, width: 5, editable: false }},
            { entry: { key: 'stategicClassification', controlType: 'textbox', label: 'REPORTS.OBJECTIVEMODEL.STRATEGICCLASSIFICATION', editable: false, calculated: true, width: 12 }},
            { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'REPORTS.OBJECTIVEMODEL.NAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME', width: 7 }},
            { entry: { key: 'responsibleId', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.RESPONSIBLEID', hasNull: true, source: 'humanresources', width: 11 }},
            { entry: { key: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.OBJECTIVEMODEL.KPI', width: 8 }},
            { entry: { key: 'budgetCalculated', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.AGGREGATIONKPI', width: 8 }},
            { entry: { key: 'targetDate', type: 'datetime', controlType: 'date', label: 'REPORTS.OBJECTIVEMODEL.TARGETDATE', width: 7 }},
            { entry: { key: 'actualDate', type: 'datetime', controlType: 'date', label: 'REPORTS.OBJECTIVEMODEL.ACTUALDATE', width: 7 }},
            { entry: { key: 'executive', controlType: 'dropdown', label: 'Q1', addNull: false, width: 5, options: [
                  { key: 0, value: 'NODE.DATASHEET.FIELDS.EXECUTIVE.NOTAPPLICABLE', color: '#999999' },
                  { key: 1, value: 'NODE.DATASHEET.FIELDS.EXECUTIVE.LOW', color: '#9ac93e', shape: 'circle' },
                  { key: 2, value: 'NODE.DATASHEET.FIELDS.EXECUTIVE.MEDIUM', color: '#EDD515', shape: 'triangle' },
                  { key: 3, value: 'NODE.DATASHEET.FIELDS.EXECUTIVE.HIGH', color: '#E50000', shape: 'rect' }
                ] }},
            { entry: { key: 'program', controlType: 'dropdown', label: 'Q2', addNull: false, width: 5, options: [
                  { key: 0, value: 'NODE.DATASHEET.FIELDS.PROGRAM.NOTAPPLICABLE', color: '#999999' },
                  { key: 1, value: 'NODE.DATASHEET.FIELDS.PROGRAM.LOW', color: '#9ac93e', shape: 'circle' },
                  { key: 2, value: 'NODE.DATASHEET.FIELDS.PROGRAM.MEDIUM', color: '#EDD515', shape: 'triangle' },
                  { key: 3, value: 'NODE.DATASHEET.FIELDS.PROGRAM.HIGH', color: '#E50000', shape: 'rect' }
                ] }},
            { entry: { key: 'functional', controlType: 'dropdown', label: 'Q3', addNull: false, width: 5, options: [
                  { key: 0, value: 'NODE.DATASHEET.FIELDS.FUNCTIONAL.NOTAPPLICABLE', color: '#999999' },
                  { key: 1, value: 'NODE.DATASHEET.FIELDS.FUNCTIONAL.LOW', color: '#9ac93e', shape: 'circle' },
                  { key: 2, value: 'NODE.DATASHEET.FIELDS.FUNCTIONAL.MEDIUM', color: '#EDD515', shape: 'triangle' },
                  { key: 3, value: 'NODE.DATASHEET.FIELDS.FUNCTIONAL.HIGH', color: '#E50000', shape: 'rect' }
                ] }},
            { entry: { key: 'resource', controlType: 'dropdown', label: 'Q4', addNull: false, width: 5, options: [
                  { key: 0, value: 'NODE.DATASHEET.FIELDS.RESOURCE.NOTAPPLICABLE', color: '#999999' },
                  { key: 1, value: 'NODE.DATASHEET.FIELDS.RESOURCE.LOW', color: '#9ac93e', shape: 'circle' },
                  { key: 2, value: 'NODE.DATASHEET.FIELDS.RESOURCE.MEDIUM', color: '#EDD515', shape: 'triangle' },
                  { key: 3, value: 'NODE.DATASHEET.FIELDS.RESOURCE.HIGH', color: '#E50000', shape: 'rect' }
                ] }},
            { entry: { key: 'lastStatus', controlType: 'text', calculated: true, width: 15, label: 'REPORTS.OBJECTIVEMODEL.LASTSTATUS'}}
          ]}
      ]
    }
  }
];
