export const TemplateFieldsTable = {
  type: 'table',
  settings: {
    /* Content alignment */
    contentAligned: 'center',
    /* Group the elements by levels */
    groupByLevels: false,
    /* Show total row after level group */
    showTotalsAfterLevel: false,
    /* Show total row after model group */
    showTotalsAfterModel: false,
    /* Show total row at the end of the table */
    showTotals: false,
    /* Show break before total row at the end of the table */
    showBreakBeforeTotals: false,
    /* The total text color */
    totalTextColor: '#ffffff',
    /* The total background color */
    totalBackgroundColor: '#666666',
    /* Group the elements by model */
    groupByModels: false,
    /* Use a thousand seperator for numeric values */
    thousandsSeperator: false,
    /* How many decimals should be shown on numeric values */
    decimalnumbers: 2,
    /* Show top most header row (grouped heading) */
    showHeading: false,
    /* Show table fields */
    showFields: true,
    /* Show the footer */
    showFooter: false,
    /* Has multi line cells */
    hasMultiCell: false,
    /* Show legend dropup */
    showLegend: false,
    /* Different source then nodes */
    source: '', // '', models, activities
    /* Caption element above the table */
    caption: null,
    /* The header text color */
    headerTextColor: '#ffffff',
    /* The header background color */
    headerBackgroundColor: '#9ac93e',
    /* The border */
    border: false,
    /* A filter function to filter by */
    filter: null,
    /* A filter function to filter models */
    modelsFilter: null,
    /* Break the collapsing on level border */
    breakCollapseOnLevel: false,
    /* Show the assigned activities */
    activities: false,
    /* Trigger to load the Businessarea with that table */
    loadBusinessarea: false,
    /* Show sidestep top most / tree below related node */
    showSidestep: false, // false, tree, topmost
    /* Show top most by sidestep */
    topMostBySidestep: false,
    /* Exports */
    exports: [], //  excel, pdf
    /* Allow page to break vertically */
    verticalPageBreak: false,
    /* Turn toggle mode on or off */
    toggleFields: false,
    /* Show table fields select box */
    showFieldsSelectBox: true,
    /* Show table models select box */
    showModelsSelectBox: false,
    /* Show wizard button */
    wizardButton: true,
    /* Default wizard flow */
    wizardFlow: 'add',
    /* Wizard button label */
    wizardButtonLabel: '',
    /* Wizard variables */
    wizardVariables: {},
    /* Color label provider */
    colorLabelProvider: false, // boolean, string
    /* Request setting: Load the tree as well */
    requestLoadTree: false,
    /* Request setting: Load the secondary costs */
    requestLoadSecondaryCosts: false,
    /* Request setting: Filter the result */
    requestFilter: ''
  },
  configuration: {
    fields: []
  }
};
