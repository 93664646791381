import { IAction, ISuccessAction } from '../shared/common.interfaces';
import { Action } from '../shared/common.action';

export class ImportAction {
  static key = 'Import';

  static IMPORT = `[${ImportAction.key}] Import`;
  static IMPORT_SUCCESS = `[${ImportAction.key}] Import success`;
  static IMPORT_FAIL = `[${ImportAction.key}] Import fail`;

  public import(request: Request): IAction {
    return {
      type: ImportAction.IMPORT,
      payload: {
        data: request
      }
    };
  }

  public importSuccess(): ISuccessAction {
    return {
      type: ImportAction.IMPORT_SUCCESS,
      payload: {
        request: undefined,
        response: undefined
      }
    };
  }

  public importFail(): Action {
    return {
      type: ImportAction.IMPORT_FAIL,
      payload: {
        request: undefined,
        response: undefined
      }
    };
  }

}
