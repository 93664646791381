import { IBusinessarea, IBusinessareaAttributes } from '../connector';
import { Record, Set, Map } from 'immutable';
import { DataModelState, RequestDiffRecord } from '../shared';
import { RecordFactory } from '../shared/record-factory';
import { isNullOrUndefined } from 'util';

export const BUSINESSAREA_TYPE_DEFAULT = 0;
export const BUSINESSAREA_TYPE_IPNAVIGATOR = 1;
export const BUSINESSAREA_TYPE_LIBRARY = 2;
export const BUSINESSAREA_TYPE_MODULE = 3;
export const BUSINESSAREA_TYPE_SETUP = 4;

const businessareaStateRecord = Record({
  ids: Set<string>(),
  entities: Map<string, Businessarea>(),
  relationships: Map<string, BusinessareaRelationships>(),
  diff: new RequestDiffRecord(),
  isBusy: 0
});

export class BusinessareaState extends businessareaStateRecord implements DataModelState {
  ids: Set<string>;
  entities: Map<string, Businessarea>;
  relationships: Map<string, BusinessareaRelationships>;
  diff: RequestDiffRecord;
  isBusy: number;
}

const businessareaRecord = Record(<IBusinessareaAttributes>{
  id: undefined,
  name: undefined,
  reference: '',
  crossReference: '',
  description: '',
  targetpicture: '',
  color: undefined,
  date: undefined,
  validfrom: null,
  status: 0,
  commercialStatus: 0,
  isAggregationEnabled: false,
  isNormalizationEnabled: false,
  updatedAt: undefined,
  duplicate_original_id: undefined,
  type: BUSINESSAREA_TYPE_DEFAULT,

  icon: '',

  humanresource: undefined,
  relationships: undefined
});

export class Businessarea extends businessareaRecord implements IBusinessareaAttributes {
  id: string;
  name: string;
  reference: string;
  crossReference: string;
  description: string;
  targetpicture: string;
  color: string;
  date: string;
  validfrom: string;
  status: number;
  commercialStatus: number;
  isAggregationEnabled: boolean;
  isNormalizationEnabled: boolean;
  updatedAt: number;
  duplicate_original_id?: number;
  humanresource: string;
  type: number;

  icon: string;

  relationships: BusinessareaRelationships;

  static fromJSONAPI(businessarea: IBusinessarea) {
    return <Businessarea> RecordFactory.entity.fromJSONAPI<Businessarea>(Businessarea, businessarea)
      .set('humanresource', !isNullOrUndefined(businessarea.relationships.humanresource.data) ? businessarea.relationships.humanresource.data.id : null);
  }
}


const relationshipsRecord = Record({
  instance: undefined,
  humanresource: undefined,
  models: undefined,
  submodels: undefined,
  importschemes: undefined,
  template: undefined
});

export class BusinessareaRelationships extends relationshipsRecord {
  instance: string;
  humanresource: string;
  models: Set<string>;
  submodels: Set<string>;
  importschemes: Set<string>;
  template: string;

  static fromJSONAPI(businessarea: IBusinessarea) {
    return RecordFactory.relationships.fromJSONAPI<BusinessareaRelationships>(BusinessareaRelationships, businessarea);
  }
}
