import { Utils } from '../shared/utils';
import { Action } from '../shared/common.action';
import { MiningState } from './mining.models';
import { MiningAction } from './mining.actions';

export default function reducer(state = new MiningState(), action: Action): MiningState {

  switch (action.type) {

    case MiningAction.ATTACH: {
      return <MiningState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case MiningAction.ATTACH_SUCCESS: {
      return <MiningState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case MiningAction.ATTACH_FAIL: {
      return <MiningState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case MiningAction.TRENDS: {
      return <MiningState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case MiningAction.TRENDS_SUCCESS: {
      return <MiningState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case MiningAction.TRENDS_FAIL: {
      return <MiningState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    default:
      return state;
  }
}
