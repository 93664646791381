import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from '../../app.reducer';

import * as selectors from './form.selectors';
import { SharedService } from '../shared/shared.service';
import { FormAction } from './form.actions';

@Injectable()
export class FormService extends SharedService {

  public constructor(protected store: Store<AppState>, protected action: FormAction) {
    super(store, action, selectors);
  }

}
