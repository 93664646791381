export const TemplateFieldsReportsModelStatus = [
  {
    type: 'table',
    settings: {
      /* Virtual scrolling */
      virtualScrolling: true,
      /* Show top most header row (grouped heading) */
      showHeading: true,
      /* Show table fields */
      showFields: true,
      /* Show the footer */
      showFooter: true,
      /* Show legend dropup */
      showLegend: true,
      /* A filter function to filter by */
      filter: 'onreports',
      /* Exports */
      exports: ['excel', 'pdf'], //  excel, pdf
      /* Allow page to break vertically */
      verticalPageBreak: true, // boolean
    },
    configuration: {
      fields: [
        { entry: { key: 'number', label: '', controlType: 'group', colspans: [25]}, children: [
            { entry: { key: 'number', controlType: 'textbox', label: 'REPORTS.MODELSTATUS.NR', editable: false, calculated: true, pdfWidth: 25, width: 7 }}
          ]
        },
        { entry: { key: 'levels', label: '', controlType: 'levels'} },
        { entry: { key: 'general', label: '', controlType: 'group', colspans: [20, 20, 20, 20, 200] }, children: [
            { entry: { key: 'stategicClassification', controlType: 'textbox', label: 'REPORTS.MODELSTATUS.STRATEGICCLASSIFICATION', editable: false, calculated: true, width: 7 }},
            { entry: { key: 'responsibleId', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.RESPONSIBLEID', addNull: true, source: 'humanresources', width: 7 }},
            { entry: { key: 'riskProfileAssessment', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.RISKPROFILEASSESSMENT.TITLE', width: 7, options: [
              { key: 0, value: 'NODE.DATASHEET.FIELDS.RISKPROFILEASSESSMENT.LOW' },
              { key: 1, value: 'NODE.DATASHEET.FIELDS.RISKPROFILEASSESSMENT.MEDIUM' },
              { key: 2, value: 'NODE.DATASHEET.FIELDS.RISKPROFILEASSESSMENT.HIGH' }
            ]}},
            { entry: { key: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.MODELSTATUS.KPI', width: 7 }},
            { entry: { key: 'budgetCalculated', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.AGGREGATIONKPI', width: 7 }},
            { entry: { key: 'targetDate', type: 'datetime', controlType: 'date', label: 'REPORTS.MODELSTATUS.TARGETDATE', width: 7 }},
            { entry: { key: 'actualDate', type: 'datetime', controlType: 'date', label: 'REPORTS.MODELSTATUS.ACTUALDATE', width: 7 }},
            { entry: { key: 'executive', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.EXECUTIVE.TITLE', addNull: false, pdfWidth: 20, width: 7, options: [
                  { key: 0, value: 'NODE.DATASHEET.FIELDS.EXECUTIVE.NOTAPPLICABLE', color: '#999999' },
                  { key: 1, value: 'NODE.DATASHEET.FIELDS.EXECUTIVE.LOW', color: '#9ac93e', shape: 'circle' },
                  { key: 2, value: 'NODE.DATASHEET.FIELDS.EXECUTIVE.MEDIUM', color: '#EDD515', shape: 'triangle' },
                  { key: 3, value: 'NODE.DATASHEET.FIELDS.EXECUTIVE.HIGH', color: '#E50000', shape: 'rect' },
                ] }},
            { entry: { key: 'program', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.PROGRAM.TITLE', addNull: false, pdfWidth: 20, width: 7, options: [
                  { key: 0, value: 'NODE.DATASHEET.FIELDS.PROGRAM.NOTAPPLICABLE', color: '#999999' },
                  { key: 1, value: 'NODE.DATASHEET.FIELDS.PROGRAM.LOW', color: '#9ac93e', shape: 'circle' },
                  { key: 2, value: 'NODE.DATASHEET.FIELDS.PROGRAM.MEDIUM', color: '#EDD515', shape: 'triangle' },
                  { key: 3, value: 'NODE.DATASHEET.FIELDS.PROGRAM.HIGH', color: '#E50000', shape: 'rect' },
                ] }},
            { entry: { key: 'functional', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.FUNCTIONAL.TITLE', addNull: false, pdfWidth: 20, width: 7, options: [
                  { key: 0, value: 'NODE.DATASHEET.FIELDS.FUNCTIONAL.NOTAPPLICABLE', color: '#999999' },
                  { key: 1, value: 'NODE.DATASHEET.FIELDS.FUNCTIONAL.LOW', color: '#9ac93e', shape: 'circle' },
                  { key: 2, value: 'NODE.DATASHEET.FIELDS.FUNCTIONAL.MEDIUM', color: '#EDD515', shape: 'triangle' },
                  { key: 3, value: 'NODE.DATASHEET.FIELDS.FUNCTIONAL.HIGH', color: '#E50000', shape: 'rect' },
                ] }},
            { entry: { key: 'resource', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.RESOURCE.TITLE', addNull: false, pdfWidth: 20, width: 7, options: [
                  { key: 0, value: 'NODE.DATASHEET.FIELDS.RESOURCE.NOTAPPLICABLE', color: '#999999' },
                  { key: 1, value: 'NODE.DATASHEET.FIELDS.RESOURCE.LOW', color: '#9ac93e', shape: 'circle' },
                  { key: 2, value: 'NODE.DATASHEET.FIELDS.RESOURCE.MEDIUM', color: '#EDD515', shape: 'triangle' },
                  { key: 3, value: 'NODE.DATASHEET.FIELDS.RESOURCE.HIGH', color: '#E50000', shape: 'rect' },
                ] }},
            { entry: { key: 'lastStatus', controlType: 'text', calculated: true, width: 16, pdfWidth: 200, label: 'REPORTS.MODELSTATUS.DESCRIPTION1'}}
          ]
        }
      ]
    }
  }
];
