import { IAction } from '../shared/common.interfaces';

export class GeneralAction {
  static key = 'GENERAL';

  static GET_HEALY_MEMBERS = `[${ GeneralAction.key }] Get Healy members`;
  static GET_HEALY_MEMBERS_SUCCESS = `[${ GeneralAction.key }] Get Healy members success`;
  static GET_HEALY_MEMBERS_FAILED = `[${ GeneralAction.key }] Get Healy members failed`;

  public getHealyMembers(start: number, count: number, search?: string): IAction {
    return {
      type: GeneralAction.GET_HEALY_MEMBERS,
      payload: {
        data: {
          start: start,
          count: count,
          search: search
        }
      }
    };
  }

  public getHealyMembersSuccess(response) {
    return {
      type: GeneralAction.GET_HEALY_MEMBERS_SUCCESS,
      payload: response
    };
  }

  public getHealyMembersFailed() {
    return {
      type: GeneralAction.GET_HEALY_MEMBERS_FAILED,
      payload: null
    };
  }

}
