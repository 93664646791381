export const TemplateFieldsReportsRollout = [
    {
        type: 'table',
        settings: {
            /* Virtual scrolling */
            virtualScrolling: true,
            /* Show top most header row (grouped heading) */
            showHeading: true,
            /* Show table fields */
            showFields: true,
            /* Show the footer */
            showFooter: true,
            /* A filter function to filter by */
            filter: 'onreports',
            /* Exports */
            exports: ['excel', 'pdf'], //  excel, pdf
            /* Allow page to break vertically */
            verticalPageBreak: true, // boolean
            /* Color label provider */
            colorLabelProvider: 'relatedstatus' // boolean, string
        },
        configuration: {
            fields: [
                { entry: {key: 'structure', label: 'TABLE.STRUCTURE.TITLE', displayLabel: false, controlType: 'structure', shape: 'ellipsis-v', additional: {addchild: true, addsibling: true, edit: true, delete: true, create: true, unlink: true, changeparent: true, link: true }}},
                { entry: {key: 'wizard', label: '', controlType: 'wizard', source: 'email-todo', icon: 'envelope', editable: true, fixedWidth: 30 }},
                { entry: {key: 'wizard', label: '', controlType: 'wizard', source: 'hoshin-kanri-edit', icon: 'project-diagram', editable: true, fixedWidth: 30 }},
                { entry: {key: 'levels', label: '', controlType: 'levels', minWidth: 100 }},
                {
                    entry: { key: 'general', label: 'REPORTS.ROLLOUT.GROUP', controlType: 'group'}, children: [
                        {entry: {key: 'reference', type: 'text', controlType: 'textbox', label: 'REPORTS.ROLLOUT.REFERENCE', fixedWidth: 70, sortable: true}},
                        {entry: {key: 'description2', controlType: 'dropdown', label: 'REPORTS.ROLLOUT.DESCRIPTION2', addNull: true, source: 'humanresources'}},
                        {entry: {key: 'creationDate', type: 'datetime', controlType: 'date', label: 'REPORTS.ROLLOUT.CREATIONDATE', sortable: true}},
                        {entry: {key: 'executiveDescription', type: 'text', controlType: 'textbox', label: 'REPORTS.ROLLOUT.EXECUTIVEDESCRIPTION', maxLength: 255, sortable: true, filter: true}},
                    ]
                },
                {entry: {key: 'general', label: 'REPORTS.ROLLOUT.GROUP1', controlType: 'group'}, children: [
                    {entry: {key: 'status#1', controlType: 'dropdown', label: 'REPORTS.ROLLOUT.STATUS.TITLE1', sortable: true, filter: true, options: [
                                {key: 0, value: 'REPORTS.ROLLOUT.STATUS.PENDING', color: '#E50000', shape: 'circle'},
                                {key: 100, value: 'REPORTS.ROLLOUT.STATUS.INDEFINEONE', color: '#EDD515', shape: 'circle'},
                                {key: 110, value: 'REPORTS.ROLLOUT.STATUS.INDEVELOPONE', color: '#9ac93e', shape: 'circle'},
                                {key: 120, value: 'REPORTS.ROLLOUT.STATUS.INVERIFYONE', color: '#9ac93e', shape: 'circle'},
                                {key: 130, value: 'REPORTS.ROLLOUT.STATUS.INACCEPTONE', color: '#9ac93e', shape: 'circle'},
                                {key: 200, value: 'REPORTS.ROLLOUT.STATUS.COMPLETED', color: '#9ac93e', shape: 'circle'},
                    ]}},
                    {entry: {key: 'status#2', controlType: 'dropdown', label: 'REPORTS.ROLLOUT.STATUS.TITLE2', sortable: true, filter: true, options: [
                                {key: 0, value: 'REPORTS.ROLLOUT.STATUS.PENDING', color: '#E50000', shape: 'circle'},
                                {key: 100, value: 'REPORTS.ROLLOUT.STATUS.INDEFINETWO', color: '#E50000', shape: 'circle'},
                                {key: 110, value: 'REPORTS.ROLLOUT.STATUS.INDEVELOPTWO', color: '#EDD515', shape: 'circle'},
                                {key: 120, value: 'REPORTS.ROLLOUT.STATUS.INVERIFYTWO', color: '#9ac93e', shape: 'circle'},
                                {key: 130, value: 'REPORTS.ROLLOUT.STATUS.INACCEPTTWO', color: '#9ac93e', shape: 'circle'},
                                {key: 200, value: 'REPORTS.ROLLOUT.STATUS.COMPLETED', color: '#9ac93e', shape: 'circle'},
                        ]},
                    },
                    {entry: {key: 'status#3', controlType: 'dropdown', label: 'REPORTS.ROLLOUT.STATUS.TITLE3', sortable: true, filter: true, options: [
                                    {key: 0, value: 'REPORTS.ROLLOUT.STATUS.PENDING', color: '#E50000', shape: 'circle'},
                                    {key: 100, value: 'REPORTS.ROLLOUT.STATUS.INDEFINETHREE', color: '#E50000', shape: 'circle'},
                                    {key: 110, value: 'REPORTS.ROLLOUT.STATUS.INDEVELOPTHREE', color: '#E50000', shape: 'circle'},
                                    {key: 120, value: 'REPORTS.ROLLOUT.STATUS.INVERIFYTHREE', color: '#EDD515', shape: 'circle'},
                                    {key: 130, value: 'REPORTS.ROLLOUT.STATUS.INACCEPTTHREE', color: '#9ac93e', shape: 'circle'},
                                    {key: 200, value: 'REPORTS.ROLLOUT.STATUS.COMPLETED', color: '#9ac93e', shape: 'circle'},
                                ]},
                    },
                    { entry: { key: 'time', controlType: 'dropdown', label: 'REPORTS.ROLLOUT.TIME.TITLE', sortable: true, filter: true, options: [
                                    { key: 0, value: 'REPORTS.ROLLOUT.TIME.APPROACH' },
                                    { key: 1, value: 'REPORTS.ROLLOUT.TIME.PROJECT' }
                    ]}},
                    { entry: { key: 'sizeType', type: 'number', controlType: 'dropdown', label: 'REPORTS.ROLLOUT.SIZETYPE.TITLE', sortable: true, filter: true, options: [
                                    { key: 0, value: 'REPORTS.ROLLOUT.SIZETYPE.NOTAPPLICABLE' },
                                    { key: 1, value: 'REPORTS.ROLLOUT.SIZETYPE.ONE' },
                                    { key: 2, value: 'REPORTS.ROLLOUT.SIZETYPE.TWO' },
                                    { key: 3, value: 'REPORTS.ROLLOUT.SIZETYPE.THREE' },
                    ]}},
                    {entry: {key: 'status#4', controlType: 'dropdown', label: 'REPORTS.ROLLOUT.STATUS.TITLE4', sortable: true, filter: true, options: [
                                    {key: 0, value: 'REPORTS.ROLLOUT.STATUS.PENDING', color: '#E50000', shape: 'circle'},
                                    {key: 100, value: 'REPORTS.ROLLOUT.STATUS.INDEFINEFOUR', color: '#E50000', shape: 'circle'},
                                    {key: 110, value: 'REPORTS.ROLLOUT.STATUS.INDEVELOPFOUR', color: '#E50000', shape: 'circle'},
                                    {key: 120, value: 'REPORTS.ROLLOUT.STATUS.INVERIFYFOUR', color: '#E50000', shape: 'circle'},
                                    {key: 130, value: 'REPORTS.ROLLOUT.STATUS.INACCEPTFOUR', color: '#EDD515', shape: 'circle'},
                                    {key: 200, value: 'REPORTS.ROLLOUT.STATUS.COMPLETED', color: '#9ac93e', shape: 'circle'},
                                ]},
                    }
                ]
                },
                {entry: {key: 'general', label: 'REPORTS.ROLLOUT.GROUP2', controlType: 'group'}, children: [
                    { entry: { key: 'groupedmodelslist', controlType: 'text', editable: false, label: 'REPORTS.ROLLOUT.CONNECTIONS', calculated: true }},
                    /*{ entry: { key: 'complexity', type: 'number', controlType: 'dropdown', label: 'REPORTS.ROLLOUT.COMPLEXITY.TITLE', sortable: true, filter: true, options: [
                            { key: 0, value: 'REPORTS.ROLLOUT.COMPLEXITY.NOTAPPLICABLE' },
                            { key: 1, value: 'REPORTS.ROLLOUT.COMPLEXITY.ONE' },
                            { key: 2, value: 'REPORTS.ROLLOUT.COMPLEXITY.TWO' },
                            { key: 3, value: 'REPORTS.ROLLOUT.COMPLEXITY.THREE' },
                            { key: 4, value: 'REPORTS.ROLLOUT.COMPLEXITY.FOUR' },
                            { key: 5, value: 'REPORTS.ROLLOUT.COMPLEXITY.FIVE' },
                            { key: 6, value: 'REPORTS.ROLLOUT.COMPLEXITY.SIX' },
                            { key: 7, value: 'REPORTS.ROLLOUT.COMPLEXITY.SEVEN' },
                            { key: 8, value: 'REPORTS.ROLLOUT.COMPLEXITY.EIGHT' },
                            { key: 9, value: 'REPORTS.ROLLOUT.COMPLEXITY.NINE' },
                            { key: 10, value: 'REPORTS.ROLLOUT.COMPLEXITY.TEN' },
                            { key: 11, value: 'REPORTS.ROLLOUT.COMPLEXITY.ELEVEN' },
                            { key: 12, value: 'REPORTS.ROLLOUT.COMPLEXITY.TWELFE' },
                            { key: 13, value: 'REPORTS.ROLLOUT.COMPLEXITY.THIRTEEN' },
                    ]}},*/
                    { entry: { key: 'budgetCalculated', type: 'number', controlType: 'textbox', defaultValue: 0, label: 'REPORTS.ROLLOUT.AGGREGATIONKPI' }},
                    { entry: { key: 'budget', type: 'number', controlType: 'textbox', defaultValue: 0, label: 'REPORTS.ROLLOUT.KPI' }},
                    { entry: { key: 'costBudget', type: 'number', controlType: 'textbox', defaultValue: 0, label: 'REPORTS.ROLLOUT.COSTBUDGET' }},
                    { entry: { key: 'investBudget', type: 'number', controlType: 'textbox', defaultValue: 0, label: 'REPORTS.ROLLOUT.INVESTBUDGET' }},
                    { entry: { key: 'costRemaining', type: 'number', controlType: 'textbox', defaultValue: 0, label: 'REPORTS.ROLLOUT.COSTREMAINING' }},
                    { entry: { key: 'benefitBudget', type: 'number', controlType: 'textbox', defaultValue: 0, label: 'REPORTS.ROLLOUT.BENEFITBUDGET' }},
                    { entry: { key: 'benefitActual', type: 'number', controlType: 'textbox', defaultValue: 0, label: 'REPORTS.ROLLOUT.BENEFITACTUAL' }},
                    { entry: { key: 'quality', type: 'number', controlType: 'dropdown', label: 'REPORTS.ROLLOUT.QUALITY.TITLE', sortable: true, filter: true, options: [
                            { key: 0, value: 'REPORTS.ROLLOUT.QUALITY.ZERO' },
                            { key: 1, value: 'REPORTS.ROLLOUT.QUALITY.ONE' },
                    ]}},
                    {entry: {key: 'stategicClassification', controlType: 'textbox', label: 'REPORTS.ROLLOUT.STRATEGICCLASSIFICATION', editable: false, calculated: true}},
                    {entry: {key: 'name', type: 'text', controlType: 'textbox', label: 'REPORTS.ROLLOUT.NAME', maxLength: 255, validations: ['required'], sortable: true, validationMessages: {required: 'DATASHEET.VALIDATION.REQUIRED.NAME'}}},
                    { entry: { key: 'startDate', type: 'datetime', controlType: 'date', label: 'REPORTS.ROLLOUT.START', sortable: true}},
                    { entry: { key: 'targetDate', type: 'datetime', controlType: 'date', label: 'REPORTS.ROLLOUT.TARGETDATE', sortable: true }},
                    { entry: { key: 'actualDate', type: 'datetime', controlType: 'date', label: 'REPORTS.ROLLOUT.ACTUALDATE', sortable: true }},
                    { entry: { key: 'timeDescription', controlType: 'text', label: 'REPORTS.ROLLOUT.TIMEDESCRIPTION' }},
                ]},
                { entry: { key: 'space', label: '', controlType: 'space', editable: false }},
                { entry: { key: 'roadmap', label: 'REPORTS.ROLLOUT.ROADMAP', controlType: 'group', colspan: 48 }, children: [
                        { entry: { key: 'roadmapy', controlType: 'roadmap', label: 'TABLE.YEAR', params: 'Y', colspan: 12, pdfWidth: 10, editable: false }},
                        { entry: { key: 'roadmapy1', controlType: 'roadmap', label: 'TABLE.YEAR', params: 'Y+1', colspan: 12, pdfWidth: 10, editable: false }},
                        { entry: { key: 'roadmapy2', controlType: 'roadmap', label: 'TABLE.YEAR', params: 'Y+2', colspan: 12, pdfWidth: 10, editable: false }},
                        { entry: { key: 'roadmapy3', controlType: 'roadmap', label: 'TABLE.YEAR', params: 'Y+3', colspan: 12, pdfWidth: 10, editable: false }}
                ]}
            ]
        }
    }
];
