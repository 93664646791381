import { IAction } from '../shared/common.interfaces';
import { TreeNode } from '../../../core/interface/core.interface';

export class AiAction {
  static key = 'AI';

  static ANALYSE = `[${AiAction.key}] Analyse`;
  static ANALYSE_SUCCESS = `[${AiAction.key}] Analyse success`;
  static ANALYSE_FAILED = `[${AiAction.key}] Analyse failed`;

  static SIMILARITY = `[${AiAction.key}] Similarity`;
  static SIMILARITY_SUCCESS = `[${AiAction.key}] Similarity success`;
  static SIMILARITY_FAILED = `[${AiAction.key}] Similarity failed`;

  public analyse(businessAreaId: string, modelId: string, structure: string, ignore: string, engine: string, files: FileList, statusNode: TreeNode): IAction {
    /* Get file */
    const file = files[0];
    /* Build up form data */
    const formData = new FormData();
    formData.append('businessarea', businessAreaId);
    formData.append('model', modelId);
    formData.append('structure', structure);
    formData.append('ignore', ignore);
    formData.append('status', statusNode.id);
    formData.append('engine', engine);
    formData.append('document', file, file.name);
    return {
      type: AiAction.ANALYSE,
      payload: {
        data: formData
      }
    };
  }

  public analyseSuccess() {
    return {
      type: AiAction.ANALYSE_SUCCESS,
      payload: null
    };
  }

  public analyseFailed() {
    return {
      type: AiAction.ANALYSE_FAILED,
      payload: null
    };
  }

  public similarity(requestId: string, input: string[], limit = 0): IAction {
    return {
      type: AiAction.SIMILARITY,
      payload: {
        data: {
          id: requestId,
          data: input,
          limit: limit
        }
      }
    };
  }

  public similaritySuccess(requestId: string, indices: any) {
    return {
      type: AiAction.SIMILARITY_SUCCESS,
      payload: {
        request: requestId,
        response: indices
      }
    };
  }

  public similarityFailed(requestId: string, error) {
    return {
      type: AiAction.SIMILARITY_FAILED,
      payload: {
        request: requestId,
        response: error
      }
    };
  }

}
