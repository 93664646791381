import { Record } from 'immutable';
import { RequestDiffRecord } from '../shared';

export interface GuardianData {
  businessAreaId: number;
  versionId: number;
  instanceId: number;
  badWordNodeType: number;
  infractionNodeType: number;
  prefixes?: string;
  searchForMembers?: boolean;
  preferredUrls?: string;
  limit?: number;
  badWordIds?: number[];
}

export interface GuardianResponseData {
  business_area_id: number;
  results_written: number;
  total_results_retrieved: number;
  total_requests_made: number;
  twitter_results_written: number;
  twitter_total_results_retrieved: number;
  twitter_total_requests_made: number;
  bing_results_written: number;
  bing_total_results_retrieved: number;
  bing_total_requests_made: number;
}

const guardianStateRecord = Record({
  isBusy: 0,
  diff: new RequestDiffRecord()
});

export class GuardianState extends guardianStateRecord {
  isBusy: number;
  diff: RequestDiffRecord;
}
