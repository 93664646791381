import { List, Map, Set } from 'immutable';
import { NaturalSort } from 'angular2-natural-sort';

import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { isNullOrUndefined } from 'util';
import { Model } from '../../../../shared/api/models';
import { TreeNode } from '../../tree/tree.node';
import { LegendItem } from '../../../../shared/legend/legend.item';
import { Node } from '../../../../shared/api/nodes';

export class ColorLabelProviderServiceModels extends ColorLabelProviderAbstract {

  private modelMap = Map<string, Model>();

  public constructor(private models: List<Model>) {
    super();
    this.models.forEach(model => this.modelMap = this.modelMap.set('' + model.id, model));
  }

  public color(element: Node) {
    if (element.relationships === undefined) { return super.color(); }
    const color = List(element.relationships.models).map(id => this.modelMap.get('' + id)).filter(model => !!model).map(model => model.color).toArray();
    if (color.length === 0 && !isNullOrUndefined(element.relationships.model) && this.modelMap.has(element.relationships.model)) {
      color.push(this.modelMap.get(element.relationships.model).color);
    }
    return color.length === 0 ? super.color() : color;
  }

  public legend(extNodes: Map<string, TreeNode>) {
    let modelSet = Set<string>();
    extNodes.forEach(extNode => {
      if (!isNullOrUndefined(extNode.node.relationships)) {
        extNode.node.relationships.models.forEach(id => modelSet = modelSet.add(id));
      }
    });
    return List<LegendItem>(this.models.filter(model => modelSet.has(model.id)).map(model => new LegendItem({
      key: 'models.' + model.id,
      color: model.color,
      label: model.name,
      field: 'model',
      value: model.id
    })).sort((a: LegendItem, b: LegendItem) => NaturalSort.SORT(a.label, b.label)).toArray());
  }

}
