import { Store } from '@ngrx/store';

import { APIState } from '../reducer';
import { commonSelectors } from '../shared';
import { compose } from '../../utilities/compose';

export function getState() {
  return (state$: Store<APIState>) => state$
    .select((s: APIState) => s.importschemes);
}

export function hasImportScheme(id: string) {
  return compose(commonSelectors.has(id), getState());
}

export function getImportScheme(id: string) {
  return compose(commonSelectors.get(id), getState());
}

export function getImportSchemes(ids?: string[]) {
  return compose(commonSelectors.getMany(ids), getState());
}

export function getIsBusy() {
  return compose(commonSelectors.getIsBusy(), getState());
}

export function getDiff() {
  return compose(commonSelectors.getDiff(), getState());
}
