import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';

import { NodeAction } from './node.actions';
import { Action } from '../shared/common.action';
import { GoAPI } from '../connector/shared/api.go.service';

@Injectable()
export class NodeEffects {

  @Effect() loadNodesByBusinessArea$ = this.actions$
    .pipe(ofType(NodeAction.LOAD))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.api.byBusinessarea(action.payload.id).post(action.payload.data).mergeMap(response => Observable.from([
      this.nodeAction.businessareaSuccess(action.payload, response)
    ])).catch((error: any) => Observable.of(this.nodeAction.businessareaFail(action.payload, error))));

  @Effect() loadIpc$ = this.actions$
    .pipe(ofType(NodeAction.LOAD_IPC))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.api.ipc().post(action.payload.data).mergeMap(response => Observable.from([
      this.nodeAction.ipcSuccess(action.payload, response)
    ])).catch((error: any) => Observable.of(this.nodeAction.ipcFail(action.payload, error))));

  constructor(private api: GoAPI,
    private actions$: Actions,
    private nodeAction: NodeAction) {
  }
}
