import { Notification, NotificationState } from './notifications.models';
import { Action } from '../shared/common.action';
import { NotificationAction } from './notifications.actions';
import { Utils } from '../shared';
import { JSONAPIDeserializerServiceNoRel } from '../shared';

export default function reducer(state = new NotificationState(), action: Action): NotificationState {
  const deserializer = new JSONAPIDeserializerServiceNoRel<Notification>(Notification.fromJSONAPI);

  switch (action.type) {
    case NotificationAction.LOAD:
      return <NotificationState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case NotificationAction.LOAD_SUCCESS: {
      const { entities } = deserializer.deserialize(action.payload.response);
      return <NotificationState>Utils.updateStateNoRel<NotificationState>(state, entities)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case NotificationAction.LOAD_FAIL: {
      return <NotificationState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case NotificationAction.UPDATE: {
      return <NotificationState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case NotificationAction.UPDATE_SUCCESS: {
      const {entities} = deserializer.deserialize(action.payload.response);
      return <NotificationState>Utils.updateStateNoRel<NotificationState>(state, entities, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case NotificationAction.UPDATE_FAIL: {
      return <NotificationState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case NotificationAction.REMOVE: {
      return <NotificationState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case NotificationAction.REMOVE_SUCCESS: {
      const {entities} = deserializer.deserialize(action.payload.response);
      return <NotificationState>Utils.updateStateNoRel<NotificationState>(state, entities, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case NotificationAction.REMOVE_FAIL: {
      return <NotificationState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    default: {
      return state;
    }

  }
}
