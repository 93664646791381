import { CoreWidgetMessage, TreeNode } from './core/interface/core.interface';
import { BehaviorSubject } from 'rxjs/Rx';
import { EventEmitter } from '@angular/core';

export class AppGlobal {

  /* Debug */
  public debug = false;

  /* Dashboard */
  public dashboardHidden = new BehaviorSubject<string[]>([]);
  public dashboardFullscreen = new BehaviorSubject<string>(undefined);

  /* Table button trigger */
  public tableButtonClickedEvent: string;
  public tableButtonClickedTrigger = new EventEmitter<{ id: string, treeNode: TreeNode }>();

  /* Table hide messages */
  public tableHideMessages: CoreWidgetMessage[];

  /* Global filter hierarchy */
  public globalFilterHierarchy = new BehaviorSubject<boolean>(true);

  /* Global filter hierarchy tree nodes */
  public globalFilterHierarchyIds = new BehaviorSubject<string[]>([]);

  /* Global filter hierarchy tree nodes */
  public globalFilterSelectedIds = new BehaviorSubject<string[]>([]);

  /* Global filter chained settings */
  public globalFilterChainedFilters: string[] = [];

  /* Global filter use subtract */
  public globalFilterUseSubtract = new BehaviorSubject<string[]>([]);

  /* Selected file */
  public existingFileSelected: TreeNode;

  public globalHeatmapNodes: TreeNode[] = []
}
