import {Injectable} from '@angular/core';
import {IEmail} from '../connector/models';
import {IPayload} from '../shared';

@Injectable()
export class EmailAction {
  static key = 'Email';

  static SEND_EMAIL = `[${EmailAction.key}] Send Email`;
  static SEND_EMAIL_SUCCESS = `[${EmailAction.key}] Send Email Success`;
  static SEND_EMAIL_FAIL = `[${EmailAction.key}] Send Email Fail`;

  public sendEmail(data: IPayload[]) {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: EmailAction.SEND_EMAIL,
      payload: {
        data: data
      }
    };
  }

  public emailSuccess(payload: IPayload, response) {
    return {
      type: EmailAction.SEND_EMAIL_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public emailFail(payload: IPayload, error: any) {
    return {
      type: EmailAction.SEND_EMAIL_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

}
