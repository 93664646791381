export const TemplateFieldsReportsKPIs = [
  {
    type: 'dashboard',
    settings: {
      targetLevel: 0,
      projectLevel: 2,
      accordion: true,
      type: 1,
      title: 'REPORTS.KPIS.TYPE1',
      label1: 'REPORTS.KPIS.PLANNED',
      label2: 'REPORTS.KPIS.ACTUAL',
      value1: 'REPORTS.KPIS.EVA',
      value2: 'REPORTS.KPIS.BUDGET',
      budgetSum: 'REPORTS.KPIS.PORTFOLIOBUDGET',
      evaSum: 'REPORTS.KPIS.TOTALEVATARGET',
      mio: 'REPORTS.KPIS.MIO',
      /* Exports */
      exports: ['html'], //  puppeteer
    },
    fields: {
      evaTarget: ['benefitBudget'],
      evaPlanned: ['benefitBudget'],
      evaActual: ['benefitActual'],
      budgetTarget: ['costBudget', 'investBudget'],
      budgetPlanned: ['costBudget', 'investBudget'],
      budgetActual: ['costActual', 'investActual']
    },
    edit: [
      { entry: { key: 'header1', label: 'REPORTS.KPIS.EVA', controlType: 'group'}, children: [
        { entry: { key: 'benefitBudget', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.BENEFITBUDGET' }},
        { entry: { key: 'benefitActual', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.BENEFITACTUAL' }}
      ]},
      { entry: { key: 'header2', label: 'REPORTS.KPIS.BUDGET', controlType: 'group'}, children: [
        { entry: { key: 'group1', label: 'REPORTS.KPIS.COST', controlType: 'group'}, children: [
          { entry: { key: 'costBudget', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.COSTBUDGET' }},
          { entry: { key: 'costActual', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.COSTACTUAL' }},
        ]},
        { entry: { key: 'group2', label: 'REPORTS.KPIS.INVEST', controlType: 'group'}, children: [
          { entry: { key: 'investBudget', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.INVESTBUDGET' }},
          { entry: { key: 'investActual', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.INVESTACTUAL' }}
        ]}
      ]}
    ]
  },
  {
    type: 'dashboard',
    settings: {
      targetLevel: 0,
      projectLevel: 2,
      accordion: true,
      type: 2,
      title: 'REPORTS.KPIS.TYPE2',
      sum: 'REPORTS.KPIS.SUM',
      mio: 'REPORTS.KPIS.MIO',
      toomuch: 'REPORTS.KPIS.TOOMUCH',
      toolittle: 'REPORTS.KPIS.TOOLITTLE'
    },
    fields: {
      evaTarget: ['benefitBudget'],
      evaPlanned: ['benefitBudget'],
      evaActual: ['benefitActual'],
      budgetTarget: ['costBudget', 'investBudget'],
      budgetPlanned: ['costBudget', 'investBudget'],
      budgetActual: ['costActual', 'investActual']
    }
  },
  {
    type: 'dashboard',
    settings: {
      targetLevel: 0,
      projectLevel: 2,
      accordion: true,
      type: 3,
      title: 'REPORTS.KPIS.TYPE3',
      value1: 'REPORTS.KPIS.BUDGET',
      value2: 'REPORTS.KPIS.EVA',
      budgetSum: 'REPORTS.KPIS.PORTFOLIOBUDGET',
      evaSum: 'REPORTS.KPIS.TOTALEVATARGET',
      mio: 'REPORTS.KPIS.MIO'
    },
    fields: {
      evaTarget: ['benefitBudget'],
      evaPlanned: ['benefitBudget'],
      evaActual: ['benefitActual'],
      budgetTarget: ['costBudget', 'investBudget'],
      budgetPlanned: ['costBudget', 'investBudget'],
      budgetActual: ['costActual', 'investActual']
    }
  }
];
