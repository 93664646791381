import { IModel, IVersion } from '../connector';

import { IPayload } from '../shared';
import { Action } from '../shared/common.action';
import { IFailAction, ISuccessAction } from '../shared/common.interfaces';

export class ModelAction {
  static key = 'Model';

  static LOAD_FAVORITES = `[${ModelAction.key}] Load favorites`;
  static LOAD_FAVORITES_SUCCESS = `[${ModelAction.key}] Load favorites success`;
  static LOAD_FAVORITES_FAIL = `[${ModelAction.key}] Load favorites fail`;

  static ADD_FAVORITE = `[${ModelAction.key}] Add favorite`;
  static ADD_FAVORITE_SUCCESS = `[${ModelAction.key}] Add favorite success`;
  static ADD_FAVORITE_FAIL = `[${ModelAction.key}] Add favorite fail`;

  static REMOVE_FAVORITE = `[${ModelAction.key}] Remove favorite`;
  static REMOVE_FAVORITE_SUCCESS = `[${ModelAction.key}] Remove favorite success`;
  static REMOVE_FAVORITE_FAIL = `[${ModelAction.key}] Remove favorite fail`;

  static LOAD = `[${ModelAction.key}] Load`;
  static LOAD_SUCCESS = `[${ModelAction.key}] Load success`;
  static LOAD_FAIL = `[${ModelAction.key}] Load fail`;

  static MASSLOAD = `[${ModelAction.key}] Mass load`;
  static MASSGOLOAD = `[${ModelAction.key}] Mass Golang Load`;
  static MASSLOAD_SUCCESS = `[${ModelAction.key}] Mass load success`;
  static MASSLOAD_FAIL = `[${ModelAction.key}] Mass load fail`;

  static INITIATIVESLOAD = `[${ModelAction.key}] Initiatives Load`;
  static INITIATIVESLOAD_SUCCESS = `[${ModelAction.key}] Initiatives load success`;
  static INITIATIVESLOAD_FAIL = `[${ModelAction.key}] Initiatives load fail`;

  static CREATE_ON_BUSINESSAREA = `[${ModelAction.key}] Create on businessarea`;
  static CREATE_ON_BUSINESSAREA_GO = `[${ModelAction.key}] Create on businessarea Go`;
  static CREATE_ON_MODEL = `[${ModelAction.key}] Create on model`;
  static CREATE_ON_MODEL_GO = `[${ModelAction.key}] Create on model Go`;
  static CREATE_SUCCESS = `[${ModelAction.key}] Create success`;
  static CREATE_FAIL = `[${ModelAction.key}] Create fail`;

  static DUPLICATE_ON_BUSINESSAREA = `[${ModelAction.key}] Duplicate on businessarea`;
  static DUPLICATE_ON_MODEL = `[${ModelAction.key}] Duplicate on model`;
  static DUPLICATE_SUCCESS = `[${ModelAction.key}] Duplicate success`;
  static DUPLICATE_FAIL = `[${ModelAction.key}] Duplicate fail`;

  static UPDATE = `[${ModelAction.key}] Update`;
  static UPDATE_GO = `[${ModelAction.key}] Update Go`;
  static UPDATE_SUCCESS = `[${ModelAction.key}] Update success`;
  static UPDATE_FAIL = `[${ModelAction.key}] Update fail`;

  static REMOVE = `[${ModelAction.key}] Remove`;
  static REMOVE_GO = `[${ModelAction.key}] Remove Go`;
  static REMOVE_SUCCESS = `[${ModelAction.key}] Remove success`;
  static REMOVE_FAIL = `[${ModelAction.key}] Remove fail`;

  static ADD_RELATIONSHIP = `[${ModelAction.key}] Add Relationship`;
  static REMOVE_RELATIONSHIP = `[${ModelAction.key}] Remove Relationship`;

  static ADD_VERSION = `[${ModelAction.key}] Add version`;

  public loadFavorites(): Action {
    return {
      type: ModelAction.LOAD_FAVORITES,
    };
  }

  public loadFavoritesSuccess(response: any): Action {
    return {
      type: ModelAction.LOAD_FAVORITES_SUCCESS,
      payload: {
        response: response.data
      }
    };
  }

  public loadFavoritesFail(error: any): Action {
    return {
      type: ModelAction.LOAD_FAVORITES_FAIL,
      payload: {
        request: undefined,
        response: error
      }
    };
  }

  public addFavorite(id: string): Action {
    return {
      type: ModelAction.ADD_FAVORITE,
      payload: {
        id: id
      }
    };
  }

  public addFavoriteSuccess(response: any): Action {
    return {
      type: ModelAction.ADD_FAVORITE_SUCCESS,
      payload: {
        response: response.data
      }
    };
  }

  public addFavoriteFail(error: any): Action {
    return {
      type: ModelAction.ADD_FAVORITE_FAIL,
      payload: {
        request: undefined,
        response: error
      }
    };
  }

  public removeFavorite(id: string): Action {
    return {
      type: ModelAction.REMOVE_FAVORITE,
      payload: {
        id: id
      }
    };
  }

  public removeFavoriteSuccess(response: any): Action {
    return {
      type: ModelAction.REMOVE_FAVORITE_SUCCESS,
      payload: {
        response: response.data
      }
    };
  }

  public removeFavoriteFail(error: any): Action {
    return {
      type: ModelAction.REMOVE_FAVORITE_FAIL,
      payload: {
        request: undefined,
        response: error
      }
    };
  }

  public massLoad(data: string | string[]): Action {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: ModelAction.MASSLOAD,
      payload: {
        data: data
      }
    };
  }

  public massGoLoad(data: string | string[]): Action {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: ModelAction.MASSGOLOAD,
      payload: {
        data: data
      }
    };
  }

  public massLoadSuccess(response: any): Action {
    return {
      type: ModelAction.MASSLOAD_SUCCESS,
      payload: {
        response: response
      }
    };
  }

  public massLoadFail(): Action {
    return {
      type: ModelAction.MASSLOAD_FAIL,
      payload: {}
    };
  }

  public initiativesLoad(id: string): Action {
    return {
      type: ModelAction.INITIATIVESLOAD,
      payload: {
        id: id
      }
    };
  }

  public initiativesLoadSuccess(data: any): Action {
    return {
      type: ModelAction.INITIATIVESLOAD_SUCCESS,
      payload: {
        data: data
      }
    };
  }

  public initiativesLoadFail(error: any): Action {
    return {
      type: ModelAction.INITIATIVESLOAD_FAIL,
      payload: {
        request: undefined,
        response: error
      }
    };
  }

  public load(id: string, includes: string[] = []): Action {
    return {
      type: ModelAction.LOAD,
      payload: {
        id: id,
        includes: includes
      }
    };
  }

  public loadSuccess(data: IModel[]): Action {
    return {
      type: ModelAction.LOAD_SUCCESS,
      payload: {
        response: data
      }
    };
  }

  public loadFail(error: any): Action {
    return {
      type: ModelAction.LOAD_FAIL,
      payload: {
        request: undefined,
        response: error
      }
    };
  }

  public createOnBusinessarea(businessareaId: string, data: IPayload | IPayload[]): Action {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: ModelAction.CREATE_ON_BUSINESSAREA,
      payload: {
        businessareaId: businessareaId,
        data: data
      }
    };
  }

  public createOnBusinessareaGo(businessareaId: string, data: IPayload | IPayload[]): Action {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: ModelAction.CREATE_ON_BUSINESSAREA_GO,
      payload: {
        businessareaId: businessareaId,
        data: data
      }
    };
  }


  public createOnModel(modelId: string, data: IPayload | IPayload[]): Action {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: ModelAction.CREATE_ON_MODEL,
      payload: {
        modelId: modelId,
        data: data
      }
    };
  }

  public createOnModelGo(modelId: string, data: IPayload | IPayload[]): Action {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: ModelAction.CREATE_ON_MODEL_GO,
      payload: {
        modelId: modelId,
        data: data
      }
    };
  }

  public createSuccess(payload: IPayload, response: IModel[]): ISuccessAction {
    return {
      type: ModelAction.CREATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public createFail(payload: IPayload, error: any): IFailAction {
    return {
      type: ModelAction.CREATE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public duplicateOnBusinessarea(businessareaId: string, data: IPayload | IPayload[]): Action {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: ModelAction.DUPLICATE_ON_BUSINESSAREA,
      payload: {
        businessareaId: businessareaId,
        data: data
      }
    };
  }

  public duplicateOnModel(modelId: string, data: IPayload | IPayload[]): Action {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: ModelAction.DUPLICATE_ON_MODEL,
      payload: {
        modelId: modelId,
        data: data
      }
    };
  }

  public duplicateSuccess(payload: IPayload, response: IModel): Action {
    return {
      type: ModelAction.DUPLICATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public duplicateFail(payload: IPayload, error: any): Action {
    return {
      type: ModelAction.DUPLICATE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public update(data: IPayload | IPayload[]): Action {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: ModelAction.UPDATE,
      payload: data
    };
  }

  public updateGo(data: IPayload | IPayload[]): Action {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: ModelAction.UPDATE_GO,
      payload: data
    };
  }

  public updateSuccess(payload: IPayload, response: IModel[]): Action {
    return {
      type: ModelAction.UPDATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public updateFail(payload: IPayload, error: any): Action {
    return {
      type: ModelAction.UPDATE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public remove(ids: string | string[]): Action {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }
    return {
      type: ModelAction.REMOVE,
      payload: {
        data: ids
      }
    };
  }

  public removeGo(ids: string | string[]): Action {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }
    return {
      type: ModelAction.REMOVE_GO,
      payload: {
        data: ids
      }
    };
  }


  public removeSuccess(payload: IPayload, response: IModel[]): Action {
    return {
      type: ModelAction.REMOVE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public removeFail(payload: IPayload, error: any): Action {
    return {
      type: ModelAction.REMOVE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public addRelationship(id: string, type: string, relationship: string): Action {
    return {
      type: ModelAction.ADD_RELATIONSHIP,
      payload: {
        id: id,
        type: type,
        relationship: relationship
      }
    };
  }

  public removeRelationship(id: string, type: string, relationship: string): Action {
    return {
      type: ModelAction.REMOVE_RELATIONSHIP,
      payload: {
        id: id,
        type: type,
        relationship: relationship
      }
    };
  }

  public autorefreshUpdate(data: any): Action {
    return {
      type: ModelAction.UPDATE_SUCCESS,
      payload: data
    };
  }

  public autorefreshCreate(data: any): Action {
    return {
      type: ModelAction.CREATE_SUCCESS,
      payload: data
    };
  }

  public autorefreshRemove(data: any): Action {
    return {
      type: ModelAction.REMOVE_SUCCESS,
      payload: data
    };
  }

  public addVersion(payload: IPayload, version: IVersion): Action {
    return {
      type: ModelAction.ADD_VERSION,
      payload: {
        id: payload.data.modelId,
        version: version
      }
    };
  }

}
