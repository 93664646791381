import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { List, Set } from 'immutable';
import { isArray } from 'util';

import { AppState } from '../../app.reducer';
import * as selectors from './user.selectors';
import { UserAction } from './user.actions';
import { SharedService } from '../shared/shared.service';
import { User } from './user.models';
import { NodeService } from '../nodes';
import { Node } from '../../api/nodes/nodes.models';

@Injectable()
export class UserService extends SharedService {

  public constructor(protected store: Store<AppState>, protected action: UserAction, private nodeService: NodeService) {
    super(store, action, selectors);
  }

  public find() {
    return this.getAPIState().let(selectors.getUser());
  }

  public getElementsResponsibleFor(offset?: number, limit?: number) {
    this.store.dispatch(this.action.loadElementsResponsibleFor(offset, limit));
  }

  public getAssigned() {
    return this.find().filter(_ => !!_).switchMap((user: User) => {
      const humanResources = isArray(user.relationships.humanresources) ? Set<number>(user.relationships.humanresources.map((_: any) => _.id)) : user.relationships.humanresources;
      return this.nodeService.all().map((nodes: List<Node>) => nodes.filter(node => humanResources.has(node.responsibleId)));
    });
  }

}
