import { Map, OrderedMap, OrderedSet, Record } from 'immutable';
import { Node } from '../../../shared/api/nodes/index';
import { UUID } from 'angular2-uuid';
import { isNullOrUndefined, isUndefined } from 'util';
import { ColorLabelProviderCommon } from '../colorlabelprovider/colorlabelprovider.common';
import { WordWrapLine } from '../../../shared/utilities';
import { TreeActivity } from './tree.activity';
import { FormGroup } from '@angular/forms';
import { Activity, ActivityService } from '../../../shared/api/activities';

const treeNodeRecord = Record({
  id: undefined,
  uuid: undefined,
  className: 'node',
  level: undefined,
  originalLevel: undefined,
  subLevel: 0,
  originalSubLevel: undefined,
  parents: OrderedMap<string, TreeNode>(),
  children: OrderedMap<string, TreeNode>(),
  activities: OrderedSet<TreeActivity>(),
  topParent: undefined,
  selected: false,
  invalid: false,
  filtered: false,
  hasChildren: false,
  x: 0,
  y: 0,
  dx: 0,
  dy: 0,
  position: 'translate(0,0)',
  color: undefined,
  labelTop: '',
  labelBottom: '',
  arc: [],
  rel: 'node-',
  phantom: false,
  phantomDrag: false,
  toCreate: false,
  topMost: undefined,
  leaf: undefined,
  challenge: undefined,
  challenge2nd: undefined,
  sumMapping: Map<string, number>(),
  stackedMapping: Map<string, boolean>(),
  calculatedValue: 0,
  calculatedSum: 0,
  calculations: [],
  formGroup: undefined,
  dummy: false,
  autoposition: 0,
  autopositionUncrossed: 0,
  fixedposition: 0,
  chainIds: [],
  parentIds: [],
  levelParentIds: [],
  node: Node
});

export class TreeNode extends treeNodeRecord {
  public id: string;
  public uuid: string;
  public className: string;
  public level: number;
  public originalLevel: number;
  public subLevel: number;
  public originalSubLevel: number;
  public parents: OrderedMap<string, TreeNode>;
  public children: OrderedMap<string, TreeNode>;
  public activities: OrderedSet<TreeActivity>;
  public topParent: TreeNode;
  public selected: boolean;
  public invalid: boolean;
  public filtered: boolean;
  public hasChildren: boolean;
  public x: number;
  public y: number;
  public dx: number;
  public dy: number;
  public position: string;
  public color: number[];
  public labelTop: string | WordWrapLine[];
  public labelBottom: string | WordWrapLine[];
  public arc: any[];
  public rel: string;
  public phantom: boolean;
  public phantomDrag: boolean;
  public toCreate: boolean;
  public node: Node;
  public topMost: TreeNode;
  public leaf: TreeNode;
  public challenge: TreeNode;
  public challenge2nd: TreeNode;
  public sumMapping: Map<string, number>;
  public stackedMapping: Map<string, boolean>;
  public calculatedValue: number;
  public calculations: any[];
  public calculatedSum: number;
  public dummy: boolean;
  public autoposition: number;
  public autopositionUncrossed: number;
  public fixedposition: number;
  public chainIds: string[];
  public parentIds: string[];
  public levelParentIds: string[];
  public formGroup: FormGroup;

  public constructor(node?: Node) {
    const uuid = UUID.UUID();
    super(isNullOrUndefined(node) ? {
      id: uuid,
      uuid: 'node-0-' + uuid
    } : {
      id: node.id,
      uuid: 'node-' + (isUndefined(node.updatedAt) ? new Date().valueOf() : node.updatedAt) + '-' + node.id,
      level: node.level,
      x: node.positionX,
      autoposition: node.autoPositionX,
      autopositionUncrossed: node.autoPositionUncrossed,
      node: node,
      rel: 'node-' + node.id,
      color: [ColorLabelProviderCommon.COLOR]
    });
  }
}
