import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducer';
import { Observable } from 'rxjs/Observable';
import { List } from 'immutable';
import { IImportScheme } from '../connector';
import { RequestDiffRecord } from '../shared';

import { ImportSchemeAction } from './importschemes.actions';
import * as fromImportSchemes from './importschemes.selectors';
import { BusinessareaService } from '../businessareas';
import { ImportScheme } from './importschemes.models';

@Injectable()
export class ImportSchemeService {
  public importschemes: Observable<List<ImportScheme>>;
  public isBusy: Observable<number>;
  public diff: Observable<RequestDiffRecord>;

  public constructor(private store: Store<AppState>, private action: ImportSchemeAction, private businessareaService: BusinessareaService) {
    this.importschemes = this.getAPIState().let(fromImportSchemes.getImportSchemes());
    this.isBusy = this.getAPIState().let(fromImportSchemes.getIsBusy());
    this.diff = this.getAPIState().let(fromImportSchemes.getDiff());
  }

  public find(id: string) {
    return this.getAPIState().let(fromImportSchemes.getImportScheme(id));
  }

  public loadImportschemesOfBusinessarea(businessareaId: string): void {
    this.store.dispatch(this.action.load(businessareaId));
  }

  public create(id: string, payload: IImportScheme | IImportScheme[]) {
    if (!Array.isArray(payload)) {
      payload = [payload];
    }
    this.store.dispatch(this.action.create(id, payload));
  }

  public remove(id: string | string[]) {
    if (!Array.isArray(id)) {
      id = [id];
    }
    this.store.dispatch(this.action.remove(id));
  }

  public autorefreshUpdate(data: any) {
    this.store.dispatch(this.action.autorefreshUpdate(data));
  }

  public autorefreshCreate(data: any) {
    this.store.dispatch(this.action.autorefreshCreate(data));
  }

  public autorefreshRemove(data: any) {
    this.store.dispatch(this.action.autorefreshRemove(data));
  }

  private getAPIState() {
    return this.store.select((state: AppState) => state.api);
  }
}
