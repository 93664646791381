import { IRelationship } from '../connector';

import { Relationship } from './relationships.models';
import { IPayload } from '../shared/common.models';
import { Action } from '../shared/common.action';
import { IAction, IFailAction, ISuccessAction } from '../shared/common.interfaces';

export class RelationshipAction {
  static key = 'Relationship';

  static LOAD = `[${RelationshipAction.key}] Load`;
  static LOAD_SUCCESS = `[${RelationshipAction.key}] Load success`;
  static LOAD_FAIL = `[${RelationshipAction.key}] Load fail`;

  static CREATE = `[${RelationshipAction.key}] Create`;
  static CREATE_GO = `[${RelationshipAction.key}] Create Go`;
  static CREATE_SUCCESS = `[${RelationshipAction.key}] Create success`;
  static CREATE_FAIL = `[${RelationshipAction.key}] Create fail`;
  static CREATE_CANCEL = `[${RelationshipAction.key}] Create cancel`;

  static UPDATE = `[${RelationshipAction.key}] Update`;
  static UPDATE_GO = `[${RelationshipAction.key}] Update Go`;
  static UPDATE_SUCCESS = `[${RelationshipAction.key}] Update success`;
  static UPDATE_FAIL = `[${RelationshipAction.key}] Update fail`;

  static REMOVE = `[${RelationshipAction.key}] Remove`;
  static REMOVE_GO = `[${RelationshipAction.key}] Remove Go`;
  static REMOVE_SUCCESS = `[${RelationshipAction.key}] Remove success`;
  static REMOVE_FAIL = `[${RelationshipAction.key}] Remove fail`;

  public load(id: number): Action {
    return {
      type: RelationshipAction.LOAD,
      payload: {
        id: id
      }
    };
  }

  public loadSuccess(response: IRelationship[]): ISuccessAction {
    return {
      type: RelationshipAction.LOAD_SUCCESS,
      payload: {
        request: null,
        response: response
      }
    };
  }

  public loadFail(error: any): IFailAction {
    return {
      type: RelationshipAction.LOAD_FAIL,
      payload: {
        request: null,
        response: error
      }
    };
  }

  public create(id: string, data: IPayload | IPayload[]): IAction {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: RelationshipAction.CREATE,
      payload: {
        id: id,
        data: data
      }
    };
  }

  public createGo(id: string, data: IPayload | IPayload[]): IAction {
    if (!Array.isArray(data)) {
      data = [data];
    }

    const z = JSON.stringify(data);
    let outputData = JSON.parse(z);
    outputData = outputData.map(i => {
      i.data.weight = Number(i.data.weight);
      return i;
    });

    return {
      type: RelationshipAction.CREATE_GO,
      payload: {
        id: id,
        data: outputData
      }
    };
  }

  public createSuccess(payload: IPayload, response: IRelationship[]): ISuccessAction {
    return {
      type: RelationshipAction.CREATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public createFail(payload: IPayload, error: any): IFailAction {
    return {
      type: RelationshipAction.CREATE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public createCancel(): any {
    return {
      type: RelationshipAction.CREATE_CANCEL,
      payload: {}
    };
  }

  public update(data: IPayload | IPayload[]): IAction {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: RelationshipAction.UPDATE,
      payload: {
        data: data
      }
    };
  }

  public updateGo(data: IPayload | IPayload[]): IAction {
    if (!Array.isArray(data)) {
      data = [data];
    }
    const z = JSON.stringify(data);
    const outputData = JSON.parse(z).map(i => {
      if (i.data.weight) {
        i.data.weight = Number(i.data.weight);
      }
      return i;
    });

    return {
      type: RelationshipAction.UPDATE_GO,
      payload: {
        data: outputData
      }
    };
  }

  public updateSuccess(payload: IPayload, response: IRelationship[]): ISuccessAction {
    return {
      type: RelationshipAction.UPDATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public updateFail(payload: IPayload, error: any): IFailAction {
    return {
      type: RelationshipAction.UPDATE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public remove(ids: string | string[]): IAction {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }
    return {
      type: RelationshipAction.REMOVE,
      payload: {
        data: ids
      }
    };
  }

  public removeGo(ids: string | string[]): IAction {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }

    const convertedIds = ids.map(id => Number(id));

    return {
      type: RelationshipAction.REMOVE_GO,
      payload: {
        data: convertedIds
      }
    };
  }

  public removeSuccess(payload: IPayload, response: IRelationship[]): ISuccessAction {
    return {
      type: RelationshipAction.REMOVE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public removeFail(payload: IPayload, error: any): IFailAction {
    return {
      type: RelationshipAction.REMOVE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public autorefreshUpdate(data: any) {
    return {
      type: RelationshipAction.UPDATE_SUCCESS,
      payload: data
    };
  }

  public autorefreshCreate(data: any) {
    return {
      type: RelationshipAction.CREATE_SUCCESS,
      payload: data
    };
  }

  public autorefreshRemove(data: any) {
    return {
      type: RelationshipAction.REMOVE_SUCCESS,
      payload: data
    };
  }
}
