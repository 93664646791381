import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';

import { AppState } from '../../app.reducer';
import { GeneralAction } from './general.actions';
import * as selectors from './general.selectors';
import { HealyMember } from './general.models';

@Injectable()
export class GeneralService {

  public isBusy: Observable<number>;
  public healyMembers: Observable<HealyMember[]>;

  public constructor(private store: Store<AppState>, private action: GeneralAction) {
    this.isBusy = this.getAPIState().let(selectors.getIsBusy());
    this.healyMembers = this.getAPIState().let(selectors.getHealyMembers());
  }

  public getHealyMembers(start: number, count: number, search?: string) {
    this.store.dispatch(this.action.getHealyMembers(start, count, search));
  }

  protected getAPIState() {
    return this.store.select((state: AppState) => state.api);
  }

}
