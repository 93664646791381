export const TemplateFieldsReportsStructured = [
  {
    type: 'table',
    settings: {
      /* Virtual scrolling */
      virtualScrolling: true,
      /* Group the elements by levels */
      groupByLevels: true,
      /* Show the footer */
      showFooter: true,
      /* Break the collapsing on level border */
      breakCollapseOnLevel: true,
      /* Exports */
      exports: ['excel', 'pdf'], //  excel, pdf
      /* The header background color */
      headerBackgroundColor: '#9ac93e',
      /* The header text color */
      headerTextColor: '#ffffff',
    },
    configuration: {
      fields: [
        { entry: { key: 'structure', label: 'TABLE.STRUCTURE.TITLE', displayLabel: false, controlType: 'structure', shape: 'ellipsis-v', additional: { addchild: true, addsibling: true, edit: true, delete: true, create: true, unlink: true, changeparent: true, link: true } }},
        { entry: { key: 'sublevel', label: 'TABLE.SUBLEVEL', displayLabel: false, controlType: 'sublevel', editable: false }},
        { entry: { key: 'general', label: 'REPORTS.GENERAL.GROUP', controlType: 'group'}, children: [
          { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.NAME', width: 30, required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME', sortable: true }},
          { entry: { key: 'description', controlType: 'text', label: 'NODE.DATASHEET.FIELDS.DESCRIPTION', width: 50}},
          { entry: { key: 'benefitBudget', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.BENEFITBUDGET', fixedWidth: 70 }},
          { entry: { key: 'businessBenefit', type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.TITLE', addNull: false, width: 20, options: [
            { key: 0, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.NOTAPPLICABLE' },
            { key: 1, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.LOW' },
            { key: 2, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.MEDIUM' },
            { key: 3, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.HIGH' }
          ]}}
        ]}
      ]
    }
  }
];
