import { Map, OrderedMap } from 'immutable';

import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { TreeNode } from '../../../core/interface/core.interface';
import { ColorLabelProviderLegend } from '../colorlabelprovider.interface';
import { isArray, isNullOrUndefined } from 'util';

export class ColorLabelProviderNodeTypes extends ColorLabelProviderAbstract {

  protected params = Map<number, any>();

  public constructor(params: any[]) {
    super();
    if (!isNullOrUndefined(params)) {
      params.forEach((param, i) => {
        this.params = this.params.set(param.key, param);
      });
    }
  }

  public color(treeNode: TreeNode) {
    if (!isNullOrUndefined(this.params) && this.params.has(treeNode.nodeType)) {
      return [this.params.get(treeNode.nodeType).color];
    } else {
      return super.color();
    }
  }

  public legend(treeNodes?: OrderedMap<string, TreeNode>) {
    if (!isNullOrUndefined(treeNodes)) {
      const nodeTypes: number[] = <number[]> (isArray(treeNodes) ? treeNodes.map(treeNode => treeNode.nodeType) : treeNodes.map(treeNode => treeNode.nodeType).toArray());
      return this.params.filter(param => nodeTypes.indexOf(param.key) !== -1).map(param => <ColorLabelProviderLegend> { key: 'nodetype.' + param.key, color: param.color, label: param.label, field: 'nodeType', value: param.key }).toArray();
    }
    if (isNullOrUndefined(this.params)) {
      return [];
    }
    return this.params.map(param => <ColorLabelProviderLegend> { key: 'nodetype.' + param.key, color: param.color, label: param.label, field: 'nodeType', value: param.key }).toArray();
  }

}
