import { Injectable, Inject } from '@angular/core';
import { ValueMinerAPIUrl, ValueMinerExportAPIUrl, ValueMinerMessagingAPIUrl, ValueMinerOAuthUrl, ValueMinerGoUrl } from '../tokens';
import { TokenService } from './token.service';
import { BackendService } from './backend.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class BackendMessagingService extends BackendService {

  constructor(@Inject(ValueMinerAPIUrl) apiUrl: string,
              @Inject(ValueMinerMessagingAPIUrl) messagingApiUrl: string,
              @Inject(ValueMinerExportAPIUrl) exportApiUrl: string,
              @Inject(ValueMinerOAuthUrl) oauthApiUrl: string,
              @Inject(ValueMinerGoUrl) goUrl: string,
              http: HttpClient,
              token: TokenService) {
    super(apiUrl, messagingApiUrl, exportApiUrl, oauthApiUrl, goUrl, http, token);
    this.apiUrl = messagingApiUrl;
  }

}
