export const TemplateFieldsTemplate = {
  tabs: [
    {
      entry: { key: 'header1', label: 'TEMPLATE.DATASHEET.HEADERS.HEADER1' },
      children: [
        { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'TEMPLATE.DATASHEET.FIELDS.NAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME' }},
        { entry: { key: 'language', controlType: 'dropdown', label: 'TEMPLATE.DATASHEET.FIELDS.LANGUAGE', source: 'languagefile', hasNull: true }},
        { entry: { key: 'background', type: 'text', controlType: 'color', label: 'TEMPLATE.DATASHEET.FIELDS.BACKGROUND' }},
        { entry: { key: 'color', type: 'text', controlType: 'color', label: 'TEMPLATE.DATASHEET.FIELDS.COLOR' }},
        { entry: { key: 'modeltype', controlType: 'dropdown', label: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.TITLE', hasNull: true, options: [
          { key: 'compliance', value: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.COMPLIANCE' },
          { key: 'strategydeployment', value: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.STRATEGYDEPLOYMENT' },
          { key: 'programmanagement', value: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.PROGRAMMANAGEMENT' },
          { key: 'multiprojektmanagement', value: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.MULTIPROJEKTMANAGEMENT' },
          { key: 'riskmanagement', value: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.RISKMANAGEMENT' },
          { key: 'agilemanagement', value: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.AGILEMANAGEMENT' },
          { key: 'transformationmanagement', value: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.TRANSFORMATIONMANAGEMENT' },
          { key: 'requirementsmanagement', value: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.REQUIREMENTSMANAGEMENT' },
          { key: 'personalmanagement', value: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.PERSONALMANAGEMENT' },
          { key: 'projectmanagement', value: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.PROJECTMANAGEMENT' },
          { key: 'todo', value: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.TODO' },
          { key: 'initiative', value: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.INITIATIVE' },
          { key: 'development', value: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.DEVELOPMENT' },
          { key: 'workflow', value: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.WORKFLOW' },
          { key: 'workflowsetup', value: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.WORKFLOWSETUP' },
          { key: 'compliancereview', value: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.COMPLIANCEREVIEW' },
          { key: 'itcompliance', value: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.ITCOMPLIANCE' },
          { key: 'hoshinkanri', value: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.HOSHINKANRI' },
          { key: 'rollout', value: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.ROLLOUT' },
          { key: 'factory', value: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.FACTORY' }
        ]}}
      ]
    },
    {
      entry: { key: 'header2', label: 'TEMPLATE.DATASHEET.HEADERS.HEADER2'},
      children: [
        { entry: { key: 'settings', controlType: 'checkbox-editor', label: 'TEMPLATE.DATASHEET.FIELDS.SETTINGS.TITLE' } }
      ]
    },
    {
      entry: { key: 'header3', label: 'TEMPLATE.DATASHEET.HEADERS.HEADER3', modalWidth: 'xl' },
      children: [
        { entry: { key: 'nodes', controlType: 'json-editor', label: 'TEMPLATE.DATASHEET.FIELDS.NODES' } }
      ]
    },
    {
      entry: { key: 'header4', label: 'TEMPLATE.DATASHEET.HEADERS.HEADER4', modalWidth: 'xl' },
      children: [
        { entry: { key: 'activities', controlType: 'json-editor', label: 'TEMPLATE.DATASHEET.FIELDS.ACTIVITIES' } }
      ]
    },
    {
      entry: { key: 'header5', label: 'TEMPLATE.DATASHEET.HEADERS.HEADER5', modalWidth: 'xl' },
      children: [
        { entry: { key: 'aggregations', controlType: 'aggregation-settings-editor', label: 'TEMPLATE.DATASHEET.FIELDS.AGGREGATIONS' } }
      ]
    },
    {
      entry: { key: 'header6', label: 'TEMPLATE.DATASHEET.HEADERS.HEADER6'},
      children: [
        { entry: { key: 'mymenu', controlType: 'checkbox-editor', label: 'TEMPLATE.DATASHEET.FIELDS.MYMENU.TITLE' } }
      ]
    },
  ]
};
