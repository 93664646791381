import { Action } from '../shared/common.action';
import { JSONAPIDeserializerService, Utils } from '../shared';
import { RelationshipState, Relationship, RelationshipRelationships } from './relationships.models';
import { RelationshipAction } from './relationships.actions';

export default function reducer(state = new RelationshipState(), action: Action): RelationshipState {
  const deserializer = new JSONAPIDeserializerService<Relationship, RelationshipRelationships>(Relationship.fromJSONAPI, RelationshipRelationships.fromJSONAPI);

  switch (action.type) {

    case RelationshipAction.LOAD:
      return <RelationshipState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case RelationshipAction.LOAD_SUCCESS: {
      const { entities, relationships } = deserializer.deserialize(action.payload.response);

      return <RelationshipState>Utils.updateState<RelationshipState>(state, entities, relationships)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case RelationshipAction.LOAD_FAIL: {
      return <RelationshipState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case RelationshipAction.CREATE: {
      return <RelationshipState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case RelationshipAction.CREATE_SUCCESS: {
      const { entities, relationships } = deserializer.deserialize(action.payload.response);

      return <RelationshipState>Utils.updateState<RelationshipState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case RelationshipAction.CREATE_FAIL: {
      return <RelationshipState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case RelationshipAction.CREATE_CANCEL: {
      return <RelationshipState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => 0)
      );
    }

    case RelationshipAction.REMOVE: {
      return <RelationshipState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case RelationshipAction.REMOVE_SUCCESS: {
      const { entities, relationships } = deserializer.deserialize(action.payload.response);

      return <RelationshipState>Utils.deleteFromState<RelationshipState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case RelationshipAction.REMOVE_FAIL: {
      return <RelationshipState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case RelationshipAction.UPDATE: {
      return <RelationshipState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case RelationshipAction.UPDATE_SUCCESS: {
      const { entities, relationships } = deserializer.deserialize(action.payload.response);
      return <RelationshipState>Utils.updateState<RelationshipState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case RelationshipAction.UPDATE_FAIL: {
      return <RelationshipState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    default: {
      return state;
    }
  }
}
