import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from '../../app.reducer';

import * as selectors from './instances.selectors';
import { InstanceAction } from './instances.actions';
import { SharedService } from '../shared/shared.service';

@Injectable()
export class InstanceService extends SharedService {

  public constructor(protected store: Store<AppState>, protected action: InstanceAction) {
    super(store, action, selectors);
  }

}
