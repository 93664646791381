import { OrderedMap, Set } from 'immutable';
import { isNullOrUndefined } from 'util';

import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { Subset } from '../../../shared/api/subsets';
import { TreeNode } from '../../../core/interface/core.interface';

export class ColorLabelProviderServiceSubSets extends ColorLabelProviderAbstract {

  public constructor(private subsets: OrderedMap<string, Subset>) {
    super();
  }

  public color(element: TreeNode) {
    const color = element.subsets.filter(id => this.subsets.has(id)).map(id => this.subsets.get(id).color);
    return color.length === 0 ? super.color() : color;
  }

  public legend(treeNodes: OrderedMap<string, TreeNode>) {
    if (isNullOrUndefined(this.subsets)) {
      return [];
    }
    let subSetSet = Set<string>();
    treeNodes.forEach(treeNode => {
      treeNode.subsets.forEach(id => subSetSet = subSetSet.add(id));
    });
    return this.subsets.filter(subset => subSetSet.has(subset.id)).map(subset => ({
      key: 'subset.' + subset.id,
      color: subset.color,
      label: subset.name,
      field: 'subSet',
      value: subset.id
    })).toArray();
  }

}
