import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';

import { Action } from '../shared/common.action';
import { GoAPI } from '../connector/shared/api.go.service';
import { MiningAction } from './mining.actions';

@Injectable()
export class MiningEffects {

  @Effect() attach$ = this.actions$
    .pipe(ofType(MiningAction.ATTACH))
    .mergeMap((action: Action) => this.api.mining('attach').post(action.payload.data).mergeMap(response => Observable.from([
      this.miningAction.attachSuccess()
    ])).catch((error: any) => Observable.of(this.miningAction.attachFail())));

  @Effect() trends$ = this.actions$
    .pipe(ofType(MiningAction.TRENDS))
    .mergeMap((action: Action) => this.api.mining('trends').post(action.payload.data).mergeMap(response => Observable.from([
      this.miningAction.trendsSuccess()
    ])).catch((error: any) => Observable.of(this.miningAction.trendsFail())));

  constructor(private api: GoAPI,
              private actions$: Actions,
              private miningAction: MiningAction) {
  }
}
