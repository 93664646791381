import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';

import * as selectors from './mining.selectors';
import { MiningAction } from './mining.actions';
import { MiningData, MiningState } from './mining.models';

@Injectable()
export class MiningService {

  public isBusy: Observable<number>;

  public constructor(private store: Store<MiningState>, private action: MiningAction) {
    this.isBusy = this.getAPIState().let(selectors.getIsBusy());
  }

  public attach(miningData: MiningData) {
    this.store.dispatch(this.action.attach(miningData));
  }

  public trends(miningData: MiningData) {
    this.store.dispatch(this.action.trends(miningData));
  }

  protected getAPIState() {
    return this.store.select((state: MiningState) => state.api);
  }

}
