import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { API } from '../connector';
import { FormAction } from './form.actions';
import { Observable } from 'rxjs/Observable';
import { JSONAPIResponse } from '../connector';
import { Action } from '../shared/common.action';

@Injectable()
export class FormEffects {

  @Effect() loadForm$ = this.actions$
    .pipe(ofType(FormAction.LOAD))
    .mergeMap((action: Action) => this.api.form.find(action.payload.id)
      .mergeMap((response: JSONAPIResponse<any>) => {
        return Observable.from([this.formAction.loadSuccess([response.toPayload()])]);
      }
      )).catch((error: any) => Observable.of(this.formAction.loadFail(error)));

  @Effect() loadForms$ = this.actions$
    .pipe(ofType(FormAction.LOAD_ALL))
    .mergeMap((action: Action) => this.api.form.get()
      .mergeMap((response: JSONAPIResponse<any>) => {
          return Observable.from([this.formAction.loadAllSuccess([response])]);
        }
      )).catch((error: any) => Observable.of(this.formAction.loadAllFail(error)));


  constructor(private api: API,
              private actions$: Actions,
              private formAction: FormAction) {
  }
}
