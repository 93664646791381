import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from '../../app.reducer';

import * as selectors from './workflow.selectors';
import { WorkflowAction } from './workflow.actions';
import { SharedService } from '../shared/shared.service';

@Injectable()
export class WorkflowService extends SharedService {

  public constructor(protected store: Store<AppState>, protected action: WorkflowAction) {
    super(store, action, selectors);
  }

}
