import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { isNullOrUndefined } from 'util';
import { Observable } from 'rxjs/Observable';
import { List } from 'immutable';

import { AppState } from '../../app.reducer';
import { TemplateAction } from './templates.actions';
import * as selectors from './templates.selectors';
import { SharedService } from '../shared/shared.service';
import { Template } from './templates.models';
import { Utilities } from '../../utilities/utilities';
import { Model, ModelService } from '../models';

@Injectable()
export class TemplateService extends SharedService {

  public static BUSINESSAREA = 'BUSINESSAREA';
  public static MODEL = 'MODEL';

  readonly DEEPVALUES = ['settings'];

  public constructor(protected store: Store<AppState>, protected action: TemplateAction, protected modelService: ModelService) {
    super(store, action, selectors);
  }

  public all(defaultTemplate = <any> new Template()): Observable<List<any>> {
    return this.getAPIState().let(this.selectors.getMany()).map((templates: List<Template>) => templates.map(template => this.map(template, defaultTemplate)).toList());
  }

  public find(id ?: string | number, defaultTemplate = new Template()): Observable<any> {
    return this.getAPIState().let(this.selectors.get('' + id)).map((template: Template) => this.map(template, defaultTemplate));
  }

  public findByModelId(modelId: string | number): Observable<any> {
    return this.modelService.find(modelId).filter(model => !!model).mergeMap((model: Model) => {
      if (isNullOrUndefined(model.relationships.template)) {
        return Observable.empty();
      }
      return this.all().map((templates: List<Template>) =>
        templates.filter((template: Template) => ('' + template.id) === ('' + model.relationships.template))
      );
    });
  }

  private map(template: Template, defaultTemplate: Template): Template {
    this.DEEPVALUES.forEach (deepValue => {
      switch (deepValue) {
        case 'settings':
          template = <Template> template.set(deepValue, Utilities.mergeObject(defaultTemplate.get(deepValue), template.get(deepValue)));
          break;
        case 'nodes':
        case 'activities':
          const tabs = template.get(deepValue).tabs;
          const defaultDataMap = Utilities.dataMap(defaultTemplate.get(deepValue).tabs);
          const result = Utilities.assignMap(tabs, defaultDataMap);
          if (result.size > 0) {
            tabs[tabs.length - 1].children.concat(result.toJS());
          }
          break;
      }
    });
    return template;
  }

}
