import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from '../../app.reducer';

import * as selectors from './nodedata.selectors';
import { NodeDataAction } from './nodedata.actions';
import { SharedService } from '../shared/shared.service';
import { List } from 'immutable';
import { ModelService } from '../models/models.service';
import { NodeData } from './nodedata.models';
import { IPayload } from '../shared';

@Injectable()
export class NodeDataService extends SharedService {

  public constructor(protected store: Store<AppState>, protected action: NodeDataAction, private modelService: ModelService) {
    super(store, action, selectors);
  }

  public findByBusinessareaId(id: string) {
    return this.all().map((nodeData: List<NodeData>) => nodeData.filter(nodeDatum => '' + nodeDatum.relationships.businessarea === '' + id).toList());
  }

  public addRelationshipOf(id: string, type: string, relationship: string) {
    this.store.dispatch(this.action.addRelationship(id, type, relationship));
  }

  public removeRelationshipOf(id: string, type: string, relationship: string) {
    this.store.dispatch(this.action.removeRelationship(id, type, relationship));
  }

  public loadAudits(id: string, data?: any) {
    this.store.dispatch(this.action.loadAudits(id, data));
  }

  public loadByTypes(types: number[]) {
    this.store.dispatch(this.action.loadByTypes(types));
  }

  public updateWithToken(data: IPayload, token: string): void {
    this.store.dispatch(this.action.updateWithToken(data, token));
  }

  public findByTypes(types: number[]) {
    return this.all().map((nodeData: List<NodeData>) => nodeData.filter(nodeDatum => types.indexOf(nodeDatum.nodeType) !== -1).toList());
  }
}
