export const TemplateFieldsReportsScheduledDistribution = [
  {
    type: 'table',
    settings: {
      /* Show top most header row (grouped heading) */
      showHeading: true,
      /* Virtual scrolling */
      virtualScrolling: true,
      /* Group the elements by levels */
      groupByLevels: true,
      /* Show the footer */
      showFooter: true,
      /* Exports */
      exports: ['pdf', 'excel'],
      /* Turn toggle mode on or off */
      toggleFields: true
    },
    configuration: {
      fields: [
        { entry: { key: 'sublevel', label: '', controlType: 'sublevel', editable: false }},
        { entry: { key: 'general', label: 'REPORTS.SCHEDULEDDISTRIBUTION.GROUPS.GENERAL', controlType: 'group'}, children: [
          { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.NAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME', fixedWidth: 100 }},
          { entry: { key: 'responsibleId', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.RESPONSIBLEID', addNull: true, source: 'humanresources', fixedWidth: 100 }},
          { entry: { key: 'businessBenefit', type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.TITLE', addNull: false, fixedWidth: 75, options: [
            { key: 0, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.NOTAPPLICABLE' },
            { key: 1, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.LOW' },
            { key: 2, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.MEDIUM' },
            { key: 3, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.HIGH' },
          ]}}
        ]},
        { entry: { key: 'dates', label: 'REPORTS.SCHEDULEDDISTRIBUTION.GROUPS.DATES', controlType: 'group'}, children: [
          { entry: { key: 'actualStartDate', type: 'datetime', controlType: 'date', label: 'REPORTS.SCHEDULEDDISTRIBUTION.STARTACTUAL', toggle: true, toggleKey: 'start', fixedWidth: 75, toggleDefault: true }},
          { entry: { key: 'startDate', type: 'datetime', controlType: 'date', label: 'REPORTS.SCHEDULEDDISTRIBUTION.START', toggle: true, toggleKey: 'start', fixedWidth: 75 }},
          { entry: { key: 'actualDate', type: 'datetime', controlType: 'date', label: 'REPORTS.SCHEDULEDDISTRIBUTION.ACTUALDATE', toggle: true, toggleKey: 'end', fixedWidth: 75, toggleDefault: true }},
          { entry: { key: 'targetDate', type: 'datetime', controlType: 'date', label: 'REPORTS.SCHEDULEDDISTRIBUTION.TARGETDATE', toggle: true, toggleKey: 'end', fixedWidth: 75 }},
        ]},
        { entry: { key: 'space', label: '', controlType: 'space', editable: false }},
        // { entry: { key: 'scheduleddistribution', label: 'REPORTS.SCHEDULEDDISTRIBUTION.GROUPS.VALUE', controlType: 'scheduleddistribution' }, children: [
        //     { entry: { key: 'benefitBudget', type: 'number', controlType: 'textbox', label: 'REPORTS.SCHEDULEDDISTRIBUTION.BENEFITBUDGET', toggle: true, toggleKey: 'scheduleddistribution', fixedWidth: 75 }},
        //     { entry: { key: 'benefitActual', type: 'number', controlType: 'textbox', label: 'REPORTS.SCHEDULEDDISTRIBUTION.BENEFITACTUAL', toggle: true, toggleKey: 'scheduleddistribution', fixedWidth: 75 }},
        //     { entry: { key: 'benefitRemaining', type: 'number', controlType: 'textbox', label: 'REPORTS.SCHEDULEDDISTRIBUTION.BENEFITREMAINING', toggle: true, toggleKey: 'scheduleddistribution', fixedWidth: 75 }},
        //     { entry: { key: 'costBudget', type: 'number', controlType: 'textbox', label: 'REPORTS.SCHEDULEDDISTRIBUTION.COSTBUDGET', toggle: true, toggleKey: 'scheduleddistribution', fixedWidth: 75 }},
        //     { entry: { key: 'costActual', type: 'number', controlType: 'textbox', label: 'REPORTS.SCHEDULEDDISTRIBUTION.COSTACTUAL', toggle: true, toggleKey: 'scheduleddistribution', fixedWidth: 75 }},
        //     { entry: { key: 'costRemaining', type: 'number', controlType: 'textbox', label: 'REPORTS.SCHEDULEDDISTRIBUTION.COSTREMAINING', toggle: true, toggleKey: 'scheduleddistribution', fixedWidth: 75 }},
        //     { entry: { key: 'investBudget', type: 'number', controlType: 'textbox', label: 'REPORTS.SCHEDULEDDISTRIBUTION.INVESTBUDGET', toggle: true, toggleKey: 'scheduleddistribution', toggleDefault: true, fixedWidth: 75 }},
        //     { entry: { key: 'investActual', type: 'number', controlType: 'textbox', label: 'REPORTS.SCHEDULEDDISTRIBUTION.INVESTACTUAL', toggle: true, toggleKey: 'scheduleddistribution', fixedWidth: 75 }},
        //     { entry: { key: 'investRemaining', type: 'number', controlType: 'textbox', label: 'REPORTS.SCHEDULEDDISTRIBUTION.INVESTREMAINING', toggle: true, toggleKey: 'scheduleddistribution', fixedWidth: 75 }},
        // ]}
      ]
    }
  }
];
