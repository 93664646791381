import { IPayload } from '../shared';
import { IAction, ISuccessAction } from '../shared/common.interfaces';
import { Action } from '../shared/common.action';
import { Request } from './node.models';

export class NodeAction {
  static key = 'Node';

  static LOAD = `[${NodeAction.key}] Load nodes`;
  static LOAD_SUCCESS = `[${NodeAction.key}] Load nodes success`;
  static LOAD_FAIL = `[${NodeAction.key}] Load nodes fail`;

  static LOAD_IPC = `[${NodeAction.key}] Load ipc chain`;
  static LOAD_IPC_SUCCESS = `[${NodeAction.key}] Load ipc chain success`;
  static LOAD_IPC_FAIL = `[${NodeAction.key}] Load ipc chain fail`;

  public businessarea(id: string, request: Request): IAction {
    return {
      type: NodeAction.LOAD,
      payload: {
        id: id,
        data: request
      }
    };
  }

  public businessareaSuccess(payload: IPayload, response: any): ISuccessAction {
    return {
      type: NodeAction.LOAD_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public businessareaFail(payload: IPayload, error: any): Action {
    return {
      type: NodeAction.LOAD_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public ipc(classes: { id: string, classes: string[] }[]): IAction {
    return {
      type: NodeAction.LOAD_IPC,
      payload: {
        data: {
          classes: classes
        }
      }
    };
  }

  public ipcSuccess(payload: IPayload, response: any): ISuccessAction {
    return {
      type: NodeAction.LOAD_IPC_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public ipcFail(payload: IPayload, error: any): Action {
    return {
      type: NodeAction.LOAD_IPC_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

}
