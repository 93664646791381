import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { API, JSONAPIResponse, ITemplate } from '../connector';
import { TemplateAction } from './templates.actions';
import { Observable } from 'rxjs/Observable';
import { Action } from '../shared/common.action';

@Injectable()
export class TemplateEffects {

  private concurrency = 5;

  @Effect() loadTemplates = this.actions$
    .pipe(ofType(TemplateAction.LOAD))
    .mergeMap((action: Action) => this.api.templates.findAll()
      .mergeMap((response: JSONAPIResponse<ITemplate[]>) => {
        return Observable.from([
          this.templateAction.loadSuccess(response.toPayload())
        ]);
      })
    ).catch((error: any) => Observable.of(this.templateAction.loadFail(error)));

  @Effect() createTemplate$ = this.actions$
    .pipe(ofType(TemplateAction.CREATE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.data.map(payload => {
        const data = payload.data.toJS();
        delete data.id;
        return this.api.templates.create(data)
          .mergeMap(response => Observable.from([
            this.templateAction.createSuccess(payload, response.toPayload())
          ]))
          .catch((error: any) => Observable.of(this.templateAction.createFail(payload, error)));
    })))
    .mergeAll(this.concurrency);

  @Effect() removeTemplate$ = this.actions$
    .pipe(ofType(TemplateAction.REMOVE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.data.map(payload => this.api.templates.remove(payload)
        .mergeMap(response => Observable.from([
          this.templateAction.removeSuccess(payload, response.toPayload())
        ]))
        .catch((error: any) => Observable.of(this.templateAction.removeFail(payload, error)))
    )))
    .mergeAll(this.concurrency);

  @Effect() updateTemplate$ = this.actions$
    .pipe(ofType(TemplateAction.UPDATE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.map(payload => this.api.templates.update(payload.id, payload.data)
      .mergeMap(response => Observable.from([
        this.templateAction.updateSuccess(payload, response.toPayload())
      ]))
      .catch((error: any) => Observable.of(this.templateAction.updateFail(payload, error)))
    )))
    .mergeAll(this.concurrency);

  constructor(private api: API,
              private actions$: Actions,
              private templateAction: TemplateAction) {
  }
}
