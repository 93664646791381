import { Record, OrderedSet } from 'immutable';
import { UUID } from 'angular2-uuid';
import { EventEmitter, Injectable } from '@angular/core';
import { User } from '../api/user/user.models';
import { AppShared } from '../../app.shared';
import { isNullOrUndefined } from 'util';

const notificationMessageRecord = Record({
  key: undefined,
  title: '',
  message: '',
  type: 'toast',
  user: undefined,
  timer: 2,
  error: '',
  onShow: new EventEmitter<any>(),
  onHide: new EventEmitter<any>(),
  onConfirm: new EventEmitter<any>(),
  onCancel: new EventEmitter<any>()
});

export class NotificationMessage extends notificationMessageRecord {
  key: string;
  title: string;
  message: string;
  type: string;
  user: User;
  timer: number;
  error: string;
  onShow: EventEmitter<any>;
  onHide: EventEmitter<any>;
  onConfirm: EventEmitter<any>;
  onCancel: EventEmitter<any>;
}

@Injectable()
export class NotifyService {

  public constructor(private appShared: AppShared) {}

  private blocked = false;
  private confirmations = OrderedSet<NotificationMessage>();
  private aggregationConfirmations = OrderedSet<NotificationMessage>();
  private errors = OrderedSet<NotificationMessage>();
  private toasts = OrderedSet<NotificationMessage>();

  private add(type, title = '', message = '', show = true, user?: User, timer?): NotifyService {
    const notification = this.transform(type, title, message, user, timer);
    switch (notification.type) {
      case 'confirmation':
        this.confirmations = this.confirmations.add(notification);
        break;
      case 'error':
        this.errors = this.errors.add(notification);
        break;
      case 'aggregationConfirmation':
        this.aggregationConfirmations = this.aggregationConfirmations.add(notification);
        break;
      default:
        this.toasts = this.toasts.add(notification);
    }
    if (show) {
      this.show();
    }
    return this;
  }

  public toast(title = '', message = '', user?: User) {
    this.add('toast', title, message, true, user, 2);
  }

  public toastWithTimer(title = '', message = '', timer = 2, user?: User) {
    this.add('toast', title, message, true, user, timer);
  }

  public confirmation(title, message, onConfirm, onCancel) {
    this.add(new NotificationMessage({
      key: UUID.UUID(),
      title: title,
      type: 'confirmation',
      message: message,
      onConfirm: onConfirm,
      onCancel: onCancel
    }));
  }

  public aggregationConfirmation(onConfirm, onCancel) {
    this.add(new NotificationMessage({
      key: UUID.UUID(),
      title: 'GENERAL.AGGREGATE.TITLE',
      type: 'aggregationConfirmation',
      message: 'GENERAL.AGGREGATE.MESSAGE',
      onConfirm: onConfirm,
      onCancel: onCancel
    }));
  }

  public error(title, message, error?: any) {
    this.add(new NotificationMessage({
      key: UUID.UUID(),
      title: title,
      type: 'error',
      message: message,
      error: error
    }));
  }

  public show() {
    /* Show toasts */
    if (this.toasts.size === 1) {
      this.showToast();
    }
    /* Show confirmation */
    if (this.confirmations.size === 1) {
      this.showConfirmation();
    }
    /* Show error */
    if (this.errors.size === 1) {
      this.showError();
    }
    /* Show aggregation confirmation */
    if (this.aggregationConfirmations.size === 1) {
      this.showAggregationConfirmation();
    }
  }

  private showToast() {
    this.showNotification(this.toasts);
  }

  private showConfirmation() {
    this.showNotification(this.confirmations);
  }

  private showError() {
    this.showNotification(this.errors);
  }

  private showAggregationConfirmation() {
    this.showNotification(this.aggregationConfirmations);
  }

  private showNotification(notifications: OrderedSet<NotificationMessage>) {
    const notification = notifications.first();
    notification.onHide.take(1).subscribe(() => {
      notifications = notifications.remove(notification);
      switch (notification.type) {
        case 'toast':
          this.toasts = notifications;
          break;
        case 'confirmation':
          this.confirmations = notifications;
          break;
        case 'error':
          this.errors = notifications;
          break;
        case 'aggregationConfirmation':
          this.aggregationConfirmations = notifications;
          break;
      }
      if (notifications.size > 0) {
        this.showNotification(notifications);
      }
    });
    if (!isNullOrUndefined(this.appShared.notificationComponent)) {
      this.appShared.notificationComponent.notify(notification);
    }
  }

  private transform(type, title = '', message = '', user?: User, timer?, error?) {
    if (type instanceof NotificationMessage) {
      return type;
    } else {
      return new NotificationMessage({
        key: UUID.UUID(),
        title: title,
        message: message,
        type: type,
        error: error,
        user: user,
        timer: timer
      });
    }
  }

}
