export const TemplateFieldsHomePresets = [
  { key: 'default',
    label: '',
    string: 'a:[:b:[:i1:i2:]:i3:]',
    sizes: {i1: 50, i2: 50, i3: 100},
    directions: { a: 'vertical', b: 'horizontal'},
    selected: false
  },
  { key: 'risk',
    label: '',
    string: 'a:[:c1:[:c2:[:i1:c3:[:i67:i71:]:]:i48:]:]',
    sizes: {'a': 100, 'b': 50, 'i1': 30, 'i2': 50, 'i3': 50, 'c1': 100, 'c2': 50, 'c3': 50, 'i67': 50, 'i71': 50, 'i48': 50},
    directions: {'a': 'horizontal', 'b': 'horizontal', 'c3': 'vertical', 'c4': 'horizontal', 'c5': 'horizontal'},
    selected: false
  },
  {
    key: 'octimineipc',
    label: 'Octimine -> IPC',
    string: 'a:[:c1:[:i78:i75:]:i81:]',
    sizes: {'a': 100, 'i78': 50, 'c1': 100, 'i75': 50, 'i81': 50 },
    directions: { 'a': 'vertical', 'c1': 'horizontal', 'c2': 'horizontal' },
    selected: false
  },
  {
    key: 'trends',
    label: 'Trends',
    string: 'a:[:c1:[:i83:i75:]:i82:]',
    sizes: { 'a': 100, 'c1': 50, 'i82': 50, 'i75': 50, 'i83': 50 },
    directions: { 'a': 'vertical', 'c1': 'horizontal' },
    selected: false
  }
];
