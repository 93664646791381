import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';

export class ColorLabelProviderServiceActivityDefault extends ColorLabelProviderAbstract {

  private static DEFAULT = '#999999';
  private static LABELDEF = '#000000';


  public color() {
    return ColorLabelProviderServiceActivityDefault.DEFAULT;
  }

  public label() {
    return ColorLabelProviderServiceActivityDefault.LABELDEF;
  }

}
