import { IAction, ISuccessAction } from '../shared/common.interfaces';
import { Action } from '../shared/common.action';
import { GuardianData } from './guardian.models';

export class GuardianAction {
  static key = 'Guardian';

  static FIND = `[${GuardianAction.key}] Find`;
  static FIND_SUCCESS = `[${GuardianAction.key}] Find success`;
  static FIND_FAIL = `[${GuardianAction.key}] Find fail`;

  public find(guardianData: GuardianData): IAction {
    return {
      type: GuardianAction.FIND,
      payload: {
        data: guardianData
      }
    };
  }

  public findSuccess(response): ISuccessAction {
    return {
      type: GuardianAction.FIND_SUCCESS,
      payload: {
        request: undefined,
        response: response
      }
    };
  }

  public findFail(error): Action {
    return {
      type: GuardianAction.FIND_FAIL,
      payload: {
        request: undefined,
        response: error
      }
    };
  }

}
