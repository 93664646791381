import { List, Map, Set } from 'immutable';
import { NaturalSort } from 'angular2-natural-sort';

import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';

import { isNullOrUndefined } from 'util';
import { Subset } from '../../../../shared/api/subsets';
import { Node } from '../../../../shared/api/nodes';
import { TreeNode } from '../../tree/tree.node';
import { LegendItem } from '../../../../shared/legend/legend.item';

export class ColorLabelProviderServiceSubSets extends ColorLabelProviderAbstract {

  private subSetMap = Map<string, Subset>();

  public constructor(private subsets: List<Subset>) {
    super();
    this.subsets.forEach(subset => this.subSetMap = this.subSetMap.set(subset.id, subset));
  }

  public color(element: Node) {
    if (element.relationships === undefined) { return super.color(); }
    const color = List(element.relationships.subsets).map(id => this.subSetMap.get(id)).filter(subset => !!subset).map(subset => subset.color).toArray();
    return color.length === 0 ? super.color() : color;
  }

  public legend(extNodes: Map<string, TreeNode>) {
    let subSetSet = Set<string>();
    extNodes.forEach(extNode => {
      if (!isNullOrUndefined(extNode.node.relationships)) {
        extNode.node.relationships.subsets.forEach(id => subSetSet = subSetSet.add(id));
      }
    });
    return List<LegendItem>(this.subsets.filter(subset => subSetSet.has(subset.id)).map(subset => new LegendItem({
      key: 'subset.' + subset.id,
      color: subset.color,
      label: subset.name,
      field: 'subSet',
      value: subset.id
    })).sort((a: LegendItem, b: LegendItem) => NaturalSort.SORT(a.label, b.label)).toArray());
  }

}
