import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { API, GoAPI } from '../connector';
import { Observable } from 'rxjs/Observable';
import { Action } from '../shared/common.action';
import { EmailAction } from './email.actions';
import { from } from 'rxjs';

@Injectable()
export class EmailEffects {

  @Effect() sendEmail$ = this.actions$
    .pipe(ofType(EmailAction.SEND_EMAIL))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.api.email.sendmail(action.payload.data).mergeMap(response => from([this.emailAction.emailSuccess(action.payload, response)])).catch((error: any) => Observable.of(this.emailAction.emailFail(action.payload, error))));

  constructor(private api: GoAPI,
              private actions$: Actions,
              private emailAction: EmailAction) {
  }
}
