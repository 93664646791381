export const TemplateFieldsReportsFocusMaster = [
  {
    type: 'table',
    settings: {
      /* Request setting: Load the tree as well */
      requestLoadTree: true,
      /* Request setting: Filter the result */
      requestFilter: 'focusmaster',
      /* Request setting: Load the secondary costs */
      requestLoadSecondaryCosts: true,
      /* The header background color */
      headerBackgroundColor: '#ff6600',
      /* Group the elements by model */
      groupByModels: true,
      /* A filter function to filter by */
      filter: 'costtype',
      /* Use a thousand seperator for numeric values */
      thousandsSeperator: '´',
      /* Show the footer */
      showFooter: true,
      /* Show break before total row at the end of the table */
      showBreakBeforeTotals: true,
      /* Show total row after model group */
      showTotalsAfterModel: true,
      /* Show total row at the end of the table */
      showTotals: true,
      /* Break the collapsing on level border */
      breakCollapseOnLevel: true,
      /* Exports */
      exports: ['excel'], //  excel, pdf
      /* Is Row Span being used */
      maxRowSpanUsed: 3,
      /* Show wizard button */
      wizardButton: false,
      /* Break the table vertically among pages */
      verticalPageBreak: false,
      /* Show table models select box */
      showModelsSelectBox: true,
    },
    configuration: {
      fields: [
        { entry: { key: 'header1', label: '', controlType: 'group', pdfWidth: 140 }, children: [
          { entry: { key: 'secondarycosts', label: 'REPORTS.FOCUSMASTER.SECONDARYCOSTS', displayLabel: false, editSecondaryCosts: false, controlType: 'secondarycosts', pdfWidth: 20, fixedWidth: 20, rowspan: 2, ignoreOnLoad: true }},
          { entry: { key: 'initiativeName', field: 'name', controlType: 'textbox', type: 'text', label: 'REPORTS.FOCUSMASTER.MODEL', editable: false, nodeSource: 'initiative', pdfWidth: 50, fixedWidth: 50, rowspan: 2 }},
          { entry: { key: 'measureName', field: 'name', controlType: 'textbox', label: 'REPORTS.FOCUSMASTER.MEASURE', editable: false, nodeSource: 'measurement', pdfWidth: 70, fixedWidth: 70, rowspan: 2 }}
        ]},
        { entry: { key: 'header2', label: 'REPORTS.EFFECTCONTROLLING.PRIMARYCOSTVIEW', controlType: 'group', pdfWidth: 300 }, children: [
          { entry: { key: 'businesscalculation', controlType: 'dropdown', label: 'REPORTS.EFFECTCONTROLLING.DB.TITLE', editable: false, filter: true, pdfWidth: 100, fixedWidth: 100, rowspan: 2, options: [
            { key: 'null', value: 'REPORTS.EFFECTCONTROLLING.DB.D0'},
            { key: 2, value: 'REPORTS.EFFECTCONTROLLING.DB.D2'},
            { key: 3, value: 'REPORTS.EFFECTCONTROLLING.DB.D3'},
            { key: 5, value: 'REPORTS.EFFECTCONTROLLING.DB.D5'},
            { key: 4, value: 'REPORTS.EFFECTCONTROLLING.DB.D4'},
            { key: 6, value: 'REPORTS.EFFECTCONTROLLING.DB.D6'},
            { key: 7, value: 'REPORTS.EFFECTCONTROLLING.DB.D7'},
            { key: 8, value: 'REPORTS.EFFECTCONTROLLING.DB.D8'},
            { key: 9, value: 'REPORTS.EFFECTCONTROLLING.DB.D9'},
            { key: 10, value: 'REPORTS.EFFECTCONTROLLING.DB.D10'},
            { key: 11, value: 'REPORTS.EFFECTCONTROLLING.DB.D11'},
            { key: 12, value: 'REPORTS.EFFECTCONTROLLING.DB.D12'},
            { key: 13, value: 'REPORTS.EFFECTCONTROLLING.DB.D13'}
          ]}},
          { entry: { key: 'costtype', controlType: 'dropdown', label: 'REPORTS.FOCUSMASTER.CT', editable: false, filter: true, rowspan: 2, pdfWidth: 100, fixedWidth: 100, options: [
            { key: 1, value: 'REPORTS.EFFECTCONTROLLING.CT.1'},
            { key: 3, value: 'REPORTS.EFFECTCONTROLLING.CT.3'},
            { key: 40, value: 'REPORTS.EFFECTCONTROLLING.CT.40'},
            { key: 41, value: 'REPORTS.EFFECTCONTROLLING.CT.41'},
            { key: 43, value: 'REPORTS.EFFECTCONTROLLING.CT.43'},
            { key: 440, value: 'REPORTS.EFFECTCONTROLLING.CT.440'},
            { key: 441, value: 'REPORTS.EFFECTCONTROLLING.CT.441'},
            { key: 45, value: 'REPORTS.EFFECTCONTROLLING.CT.45'},
            { key: 46, value: 'REPORTS.EFFECTCONTROLLING.CT.46'},
            { key: 47, value: 'REPORTS.EFFECTCONTROLLING.CT.47'},
            { key: 48, value: 'REPORTS.EFFECTCONTROLLING.CT.48'}
          ]}},
          { entry: { key: 'complexity', controlType: 'dropdown', label: 'REPORTS.EFFECTCONTROLLING.POTENTIAL.TITLE', editable: false, filter: true, rowspan: 2, pdfWidth: 100, fixedWidth: 100, options: [
            { key: 0, value: 'REPORTS.EFFECTCONTROLLING.POTENTIAL.P0'},
            { key: 1, value: 'REPORTS.EFFECTCONTROLLING.POTENTIAL.P1'},
            { key: 2, value: 'REPORTS.EFFECTCONTROLLING.POTENTIAL.P2'},
            { key: 3, value: 'REPORTS.EFFECTCONTROLLING.POTENTIAL.P3'},
            { key: 4, value: 'REPORTS.EFFECTCONTROLLING.POTENTIAL.P4'},
            { key: 5, value: 'REPORTS.EFFECTCONTROLLING.POTENTIAL.P5'},
            { key: 6, value: 'REPORTS.EFFECTCONTROLLING.POTENTIAL.P6'}
          ]}},
        ]},
        { entry: { key: 'header3', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.AUSGANGSLAGE', params: 'Y-2', pdfWidth: 200 }, children: [
          { entry: { key: 'investActual', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.BETRAGIST', rowspan: 2, pdfWidth: 100, fixedWidth: 100, editable: false }},
          { entry: { key: 'description1', controlType: 'text', label: 'REPORTS.EFFECTCONTROLLING.BESCHREIBUNG', rowspan: 2, pdfWidth: 100, fixedWidth: 100, editable: false }}
        ]},
        { entry: { key: 'header4', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.GROBANALYSE', pdfWidth: 200 }, children: [
          { entry: { key: 'investBudget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.POTENZIAL', rowspan: 2, pdfWidth: 100, fixedWidth: 100, editable: false }},
          { entry: { key: 'description2', controlType: 'text', label: 'REPORTS.EFFECTCONTROLLING.BERECHNUNGSLOGIK', rowspan: 2, pdfWidth: 100, fixedWidth: 100, editable: false }}
        ]},
        { entry: { key: 'header5', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.DETAILANALYE', pdfWidth: 200 }, children: [
          { entry: { key: 'investRemaining', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.POTENZIAL', rowspan: 2, pdfWidth: 100, fixedWidth: 100, editable: false }},
          { entry: { key: 'description3', controlType: 'text', label: 'REPORTS.EFFECTCONTROLLING.BERECHNUNGSLOGIK', rowspan: 2, pdfWidth: 100, fixedWidth: 100, editable: false }}
        ]},
        { entry: { key: 'header6', label: 'REPORTS.EFFECTCONTROLLING.SECONDARYCOSTVIEW', controlType: 'group', fixedWidth: 200 }, children: [
          { entry: { key: 'wirkung', type: 'text', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.WIRKUNG', calculated: true, editable: false, pdfWidth: 100, fixedWidth: 100, rowspan: 2 }},
          { entry: { key: 'category', controlType: 'dropdown', label: 'REPORTS.EFFECTCONTROLLING.CATEGORY.TITLE', hasNull: true, rowspan: 2, calculated: true, editable: false, pdfWidth: 100, fixedWidth: 100, options: [
            { key: 0, value: ''},
            { key: 1, value: 'REPORTS.EFFECTCONTROLLING.CATEGORY.DIVISION'},
            { key: 2, value: 'REPORTS.EFFECTCONTROLLING.CATEGORY.NEUTRAL'},
            { key: 3, value: 'REPORTS.EFFECTCONTROLLING.CATEGORY.CENTRAL'}
          ]}},
        ]},
        { entry: { key: 'header7', label: '', controlType: 'group', border: '0', pdfWidth: 100 }, children: [
          { entry: { key: 'weight', type: 'number', controlType: 'textbox', showTotal: false, label: 'REPORTS.EFFECTCONTROLLING.VERTEILSCHLUESSEL', editable: false, pdfWidth: 100, fixedWidth: 100, calculated: true, rowspan: 2 }}
        ]},
        // { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.EINZELJAHR', params: 'Y-1', pdfWidth: 600, colspan: 10 }, children: [
        //     { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q1', pdfWidth: 120 }, children: [
        //         { entry: { key: 'budgetPlanY1Q1', field: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q1Y-1', editable: false }},
        //         { entry: { key: 'budgetY1Q1', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q1Y-1', editable: false, calculated: true }},
        //       ]},
        //     { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q2', pdfWidth: 120 }, children: [
        //         { entry: { key: 'budgetPlanY1Q2', field: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q2Y-1', editable: false }},
        //         { entry: { key: 'budgetY1Q2', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q2Y-1', editable: false, calculated: true }},
        //       ]},
        //     { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q3', pdfWidth: 120 }, children: [
        //         { entry: { key: 'budgetPlanY1Q3', field: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q3Y-1', editable: false }},
        //         { entry: { key: 'budgetY1Q3', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q3Y-1', editable: false, calculated: true }},
        //       ]},
        //     { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q4', pdfWidth: 120 }, children: [
        //         { entry: { key: 'budgetPlanY1Q4', field: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q4Y-1', editable: false }},
        //         { entry: { key: 'budgetY1Q4', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q4Y-1', editable: false, calculated: true }},
        //       ]},
        //     { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.KUMULIERT', pdfWidth: 120 }, children: [
        //         { entry: { key: 'budgetPlanY1', field: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Y-1', editable: false }},
        //         { entry: { key: 'budgetY1', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Y-1', editable: false, calculated: true }},
        //       ]}
        //   ]},
        { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.EINZELJAHR', params: 'Y', colspan: 10, pdfWidth: 600 }, children: [
            { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q1', pdfWidth: 120 }, children: [
                { entry: { key: 'budgetPlanY2Q1', field: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q1Y', editable: false }},
                { entry: { key: 'budgetY2Q1', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q1Y', editable: false }},
              ]},
            { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q2', pdfWidth: 120 }, children: [
                { entry: { key: 'budgetPlanY2Q2', field: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q2Y', editable: false }},
                { entry: { key: 'budgetY2Q2', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q2Y', editable: false }},
              ]},
            { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q3', pdfWidth: 120 }, children: [
                { entry: { key: 'budgetPlanY2Q3', field: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q3Y', editable: false }},
                { entry: { key: 'budgetY2Q3', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q3Y', editable: false }},
              ]},
            { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q4', pdfWidth: 120 }, children: [
                { entry: { key: 'budgetPlanY2Q4', field: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q4Y', editable: false }},
                { entry: { key: 'budgetY2Q4', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q4Y', editable: false }},
              ]},
            { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.KUMULIERT', pdfWidth: 120 }, children: [
                { entry: { key: 'budgetPlanY2', field: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Y', editable: false }},
                { entry: { key: 'budgetY2', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Y', editable: false }},
              ]}
          ]},
        { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.EINZELJAHR', params: 'Y+1', colspan: 10, pdfWidth: 600 }, children: [
            { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q1', pdfWidth: 120 }, children: [
                { entry: { key: 'budgetPlanY3Q1', field: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q1Y+1', editable: false }},
                { entry: { key: 'budgetY3Q1', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q1Y+1', editable: false }},
              ]},
            { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q2', pdfWidth: 120 }, children: [
                { entry: { key: 'budgetPlanY3Q2', field: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q2Y+1', editable: false }},
                { entry: { key: 'budgetY3Q2', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q2Y+1', editable: false }},
              ]},
            { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q3', pdfWidth: 120 }, children: [
                { entry: { key: 'budgetPlanY3Q3', field: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q3Y+1', editable: false }},
                { entry: { key: 'budgetY3Q3', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q3Y+1', editable: false }},
              ]},
            { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q4', pdfWidth: 120 }, children: [
                { entry: { key: 'budgetPlanY3Q4', field: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q4Y+1', editable: false }},
                { entry: { key: 'budgetY3Q4', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q4Y+1', editable: false }},
              ]},
            { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.KUMULIERT', pdfWidth: 120 }, children: [
                { entry: { key: 'budgetPlanY3', field: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Y+1', editable: false }},
                { entry: { key: 'budgetY3', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Y+1', editable: false }},
              ]}
          ]},
        { entry: { key: 'header8', label: '', controlType: 'group', pdfWidth: 300 }, children: [
          { entry: { key: 'kpiName', field: 'name', type: 'text', controlType: 'textbox', label: 'REPORTS.KPI.NAME', rowspan: 2, editable: false, pdfWidth: 50, fixedWidth: 50, nodeSource: 'kpi' }},
          { entry: { key: 'kpiDescription', field: 'description', controlType: 'text', label: 'REPORTS.KPI.DESCRIPTION', rowspan: 2, editable: false, pdfWidth: 100, fixedWidth: 100, nodeSource: 'kpi' }},
          { entry: { key: 'kpiBudget', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.BESCHRIEB', rowspan: 2, editable: false, pdfWidth: 75, fixedWidth: 75, nodeSource: 'kpi' }},
          { entry: { key: 'kpiRiskProfileCountermeasures', field: 'riskProfileCountermeasures', type: 'text', controlType: 'textbox', label: 'REPORTS.KPI.EINHEIT', rowspan: 2, pdfWidth: 75, fixedWidth: 75, editable: false, nodeSource: 'kpi' }}
        ]},
        // { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.EINZELJAHR', params: 'Y-1', colspan: 10, pdfWidth: 600 }, children: [
        //     { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q1', pdfWidth: 120 }, children: [
        //         { entry: { key: 'budgetPlanKpiY1Q1', field: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q1Y-1', editable: false, nodeSource: 'kpi' }},
        //         { entry: { key: 'budgetKpiY1Q1', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q1Y-1', editable: false, nodeSource: 'kpi' }},
        //       ]},
        //     { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q2', pdfWidth: 120 }, children: [
        //         { entry: { key: 'budgetPlanKpiY1Q2', field: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q2Y-1', editable: false, nodeSource: 'kpi' }},
        //         { entry: { key: 'budgetKpiY1Q2', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q2Y-1', editable: false, nodeSource: 'kpi' }},
        //       ]},
        //     { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q3', pdfWidth: 120 }, children: [
        //         { entry: { key: 'budgetPlanKpiY1Q3', field: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q3Y-1', editable: false, nodeSource: 'kpi' }},
        //         { entry: { key: 'budgetKpiY1Q3', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q3Y-1', editable: false, nodeSource: 'kpi' }},
        //       ]},
        //     { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q4', pdfWidth: 120 }, children: [
        //         { entry: { key: 'budgetPlanKpiY1Q4', field: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q4Y-1', editable: false, nodeSource: 'kpi' }},
        //         { entry: { key: 'budgetKpiY1Q4', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q4Y-1', editable: false, nodeSource: 'kpi' }},
        //       ]},
        //     { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.KUMULIERT', pdfWidth: 120 }, children: [
        //         { entry: { key: 'budgetPlanKpiY1', field: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Y-1', editable: false, nodeSource: 'kpi' }},
        //         { entry: { key: 'budgetKpiY1', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Y-1', editable: false, nodeSource: 'kpi' }},
        //       ]}
        //   ]},
        { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.EINZELJAHR', params: 'Y', colspan: 10, pdfWidth: 600 }, children: [
            { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q1', pdfWidth: 120 }, children: [
                { entry: { key: 'budgetPlanKpiY2Q1', field: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q1Y', editable: false, nodeSource: 'kpi' }},
                { entry: { key: 'budgetKpiY2Q1', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q1Y', editable: false, nodeSource: 'kpi' }},
              ]},
            { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q2', pdfWidth: 120 }, children: [
                { entry: { key: 'budgetPlanKpiY2Q2', field: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q2Y', editable: false, nodeSource: 'kpi' }},
                { entry: { key: 'budgetKpiY2Q2', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q2Y', editable: false, nodeSource: 'kpi' }},
              ]},
            { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q3', pdfWidth: 120 }, children: [
                { entry: { key: 'budgetPlanKpiY2Q3', field: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q3Y', editable: false, nodeSource: 'kpi' }},
                { entry: { key: 'budgetKpiY2Q3', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q3Y', editable: false, nodeSource: 'kpi' }},
              ]},
            { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q4', pdfWidth: 120 }, children: [
                { entry: { key: 'budgetPlanKpiY2Q4', field: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q4Y', editable: false, nodeSource: 'kpi' }},
                { entry: { key: 'budgetKpiY2Q4', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q4Y', editable: false, nodeSource: 'kpi' }},
              ]},
            { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.KUMULIERT', pdfWidth: 120 }, children: [
                { entry: { key: 'budgetPlanKpiY2', field: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Y', editable: false, nodeSource: 'kpi' }},
                { entry: { key: 'budgetKpiY2', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Y', editable: false, nodeSource: 'kpi' }},
              ]}
          ]},
        { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.EINZELJAHR', params: 'Y+1', colspan: 10, pdfWidth: 600 }, children: [
            { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q1', pdfWidth: 120 }, children: [
                { entry: { key: 'budgetPlanKpiY3Q1', field: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q1Y+1', editable: false, nodeSource: 'kpi' }},
                { entry: { key: 'budgetKpiY3Q1', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q1Y+1', editable: false, nodeSource: 'kpi' }},
              ]},
            { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q2', pdfWidth: 120 }, children: [
                { entry: { key: 'budgetPlanKpiY3Q2', field: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q2Y+1', editable: false, nodeSource: 'kpi' }},
                { entry: { key: 'budgetKpiY3Q2', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q2Y+1', editable: false, nodeSource: 'kpi' }},
              ]},
            { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q3', pdfWidth: 120 }, children: [
                { entry: { key: 'budgetPlanKpiY3Q3', field: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q3Y+1', editable: false, nodeSource: 'kpi' }},
                { entry: { key: 'budgetKpiY3Q3', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q3Y+1', editable: false, nodeSource: 'kpi' }},
              ]},
            { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.Q4', pdfWidth: 120 }, children: [
                { entry: { key: 'budgetPlanKpiY3Q4', field: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q4Y+1', editable: false, nodeSource: 'kpi' }},
                { entry: { key: 'budgetKpiY3Q4', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Q4Y+1', editable: false, nodeSource: 'kpi' }},
              ]},
            { entry: { key: '', controlType: 'group', label: 'REPORTS.EFFECTCONTROLLING.KUMULIERT', pdfWidth: 120 }, children: [
                { entry: { key: 'budgetPlanKpiY3', field: 'budgetPlan', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ZIEL', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Y+1', editable: false, nodeSource: 'kpi' }},
                { entry: { key: 'budgetKpiY3', field: 'budget', type: 'number', controlType: 'textbox', label: 'REPORTS.EFFECTCONTROLLING.ISTPROG', nodeBucket: true, pdfWidth: 60, fixedWidth: 60, nodeBucketId: 'Y+1', editable: false, nodeSource: 'kpi' }},
              ]}
          ]},
        { entry: { key: 'header10', label: 'REPORTS.INITIATIVEUPDATE.TITLE', displayLabel: false, controlType: 'group', pdfWidth: 415 }, children: [
          { entry: { key: 'sizeType', type: 'number', controlType: 'dropdown', label: 'REPORTS.INITIATIVEUPDATE.SIZETYPE', rowspan: 2, pdfWidth: 60, fixedWidth: 60, editable: false, nodeSource: 'initiative', options: [
            { key: 0, value: 'REPORTS.FOCUSMASTER.NOTAPPLICABLE' },
            { key: 1, value: 'REPORTS.FOCUSMASTER.KI' },
            { key: 2, value: 'REPORTS.FOCUSMASTER.BI' },
            { key: 3, value: 'REPORTS.FOCUSMASTER.AI' },
            { key: 4, value: 'REPORTS.FOCUSMASTER.OTHERS' }
          ]}},
          { entry: { key: 'priority', controlType: 'dropdown',  label: 'REPORTS.FOCUSMASTER.PRIORITY.TITLE', editable: false, rowspan: 2, pdfWidth: 60, fixedWidth: 60, addNull: false, nodeSource: 'initiative', options: [
            { key: 1, value: 'REPORTS.FOCUSMASTER.PRIORITY.1' },
            { key: 2, value: 'REPORTS.FOCUSMASTER.PRIORITY.2' },
            { key: 3, value: 'REPORTS.FOCUSMASTER.PRIORITY.3' },
            { key: 4, value: 'REPORTS.FOCUSMASTER.PRIORITY.4' },
            { key: 5, value: 'REPORTS.FOCUSMASTER.PRIORITY.5' },
            { key: 6, value: 'REPORTS.FOCUSMASTER.PRIORITY.6' },
            { key: 7, value: 'REPORTS.FOCUSMASTER.PRIORITY.7' },
            { key: 8, value: 'REPORTS.FOCUSMASTER.PRIORITY.8' },
            { key: 9, value: 'REPORTS.FOCUSMASTER.PRIORITY.9' }
          ]}},
          { entry: { key: 'organizational', type: 'number', controlType: 'dropdown', label: 'REPORTS.FOCUSMASTER.ORGANIZATIONAL.TITLE', editable: false, pdfWidth: 60, fixedWidth: 60, rowspan: 2, nodeSource: 'initiative', options: [
            { key: 0, value: 'REPORTS.FOCUSMASTER.ORGANIZATIONAL.NOTAPPLICABLE' },
            { key: 1, value: 'REPORTS.FOCUSMASTER.ORGANIZATIONAL.AGILE' },
            { key: 2, value: 'REPORTS.FOCUSMASTER.ORGANIZATIONAL.CLASSIC' },
            { key: 3, value: 'REPORTS.FOCUSMASTER.ORGANIZATIONAL.MAJOR' }
          ]}},
          { entry: { key: 'responsibleId', controlType: 'dropdown', label: 'REPORTS.FOCUSMASTER.RESPONSIBLEID', nodeSource: 'initiative', rowspan: 2, pdfWidth: 75, fixedWidth: 75, editable: false, source: 'humanresources' }},
          { entry: { key: 'organizationalDescription', controlType: 'text', nodeSource: 'initiative', rowspan: 2, editable: false, pdfWidth: 100, fixedWidth: 100, label: 'REPORTS.FOCUSMASTER.ORGANIZATIONALDESCRIPTION' }},
          { entry: { key: 'technical', type: 'number', controlType: 'dropdown', nodeSource: 'initiative', rowspan: 2, editable: false, pdfWidth: 60, fixedWidth: 60, label: 'REPORTS.STATUS.TEMPER', options: [
            { key: 0, value: 'REPORTS.STATUS.DROPDOWN.NOTAPPLICABLE' },
            { key: 1, value: 'REPORTS.STATUS.DROPDOWN.L1' },
            { key: 2, value: 'REPORTS.STATUS.DROPDOWN.L2' },
            { key: 3, value: 'REPORTS.STATUS.DROPDOWN.L3' },
            { key: 4, value: 'REPORTS.STATUS.DROPDOWN.L4' },
            { key: 5, value: 'REPORTS.STATUS.DROPDOWN.L5' }
          ]}}
        ]},
        { entry: { key: 'header12', label: 'REPORTS.FOCUSMASTER.STATUS', controlType: 'group', pdfWidth: 300 }, children: [
          { entry: { key: 'riskProfileAssessmentStatus', field: 'riskProfileAssessment', controlType: 'dropdown', nodeSource: 'initiative', rowspan: 2, pdfWidth: 60, fixedWidth: 60, editable: false, label: 'REPORTS.FOCUSMASTER.RISKPROFILEASSESSMENT', options: [
                { key: 0, value: 'REPORTS.FOCUSMASTER.DROPDOWN.NOTAPPLICABLE', color: '#999999' },
                { key: 1, value: 'REPORTS.FOCUSMASTER.DROPDOWN.LOW', color: '#9ac93e', shape: 'circle' },
                { key: 2, value: 'REPORTS.FOCUSMASTER.DROPDOWN.MEDIUM', color: '#EDD515', shape: 'triangle' },
                { key: 3, value: 'REPORTS.FOCUSMASTER.DROPDOWN.HIGH', color: '#E50000', shape: 'rect' },
              ]}},
          { entry: { key: 'costStatus', field: 'cost', controlType: 'dropdown', nodeSource: 'initiative', rowspan: 2, label: 'REPORTS.FOCUSMASTER.COST', pdfWidth: 60, fixedWidth: 60, editable: false, options: [
                { key: 0, value: 'REPORTS.FOCUSMASTER.DROPDOWN.NOTAPPLICABLE', color: '#999999' },
                { key: 1, value: 'REPORTS.FOCUSMASTER.DROPDOWN.LOW', color: '#9ac93e', shape: 'circle' },
                { key: 2, value: 'REPORTS.FOCUSMASTER.DROPDOWN.MEDIUM', color: '#EDD515', shape: 'triangle' },
                { key: 3, value: 'REPORTS.FOCUSMASTER.DROPDOWN.HIGH', color: '#E50000', shape: 'rect' },
              ]}},
          { entry: { key: 'qualityStatus', field: 'quality', controlType: 'dropdown', nodeSource: 'initiative', rowspan: 2, label: 'REPORTS.FOCUSMASTER.QUALITY', pdfWidth: 60, fixedWidth: 60, editable: false, options: [
                { key: 0, value: 'REPORTS.FOCUSMASTER.DROPDOWN.NOTAPPLICABLE', color: '#999999' },
                { key: 1, value: 'REPORTS.FOCUSMASTER.DROPDOWN.LOW', color: '#9ac93e', shape: 'circle' },
                { key: 2, value: 'REPORTS.FOCUSMASTER.DROPDOWN.MEDIUM', color: '#EDD515', shape: 'triangle' },
                { key: 3, value: 'REPORTS.FOCUSMASTER.DROPDOWN.HIGH', color: '#E50000', shape: 'rect' },
              ]}},
          { entry: { key: 'timeStatus', field: 'time', controlType: 'dropdown', nodeSource: 'initiative', rowspan: 2, label: 'REPORTS.FOCUSMASTER.TIME', pdfWidth: 60, fixedWidth: 60, editable: false, options: [
                { key: 0, value: 'REPORTS.FOCUSMASTER.DROPDOWN.NOTAPPLICABLE', color: '#999999' },
                { key: 1, value: 'REPORTS.FOCUSMASTER.DROPDOWN.LOW', color: '#9ac93e', shape: 'circle' },
                { key: 2, value: 'REPORTS.FOCUSMASTER.DROPDOWN.MEDIUM', color: '#EDD515', shape: 'triangle' },
                { key: 3, value: 'REPORTS.FOCUSMASTER.DROPDOWN.HIGH', color: '#E50000', shape: 'rect' },
              ]}},
          { entry: { key: 'complexityStatus', field: 'complexity', controlType: 'dropdown', nodeSource: 'initiative', rowspan: 2, label: 'REPORTS.FOCUSMASTER.COMPLEXITY', pdfWidth: 60, fixedWidth: 60, editable: false, options: [
                { key: 0, value: 'REPORTS.FOCUSMASTER.DROPDOWN.NOTAPPLICABLE', color: '#999999' },
                { key: 1, value: 'REPORTS.FOCUSMASTER.DROPDOWN.LOW', color: '#9ac93e', shape: 'circle' },
                { key: 2, value: 'REPORTS.FOCUSMASTER.DROPDOWN.MEDIUM', color: '#EDD515', shape: 'triangle' },
                { key: 3, value: 'REPORTS.FOCUSMASTER.DROPDOWN.HIGH', color: '#E50000', shape: 'rect' },
              ]}},
        ]}
      ],
      additionalfields: [
        { entry: { key: 'initiativeInvestBudget', field: 'investBudget', type: 'number', controlType: 'textbox', nodeSource: 'initiative' }},
        { entry: { key: 'initiativeInvestActual', field: 'investActual', type: 'number', controlType: 'textbox', nodeSource: 'initiative' }},
        { entry: { key: 'initiativeInvestRemaining', field: 'investRemaining', type: 'number', controlType: 'textbox', nodeSource: 'initiative' }},
        { entry: { key: 'initiativeCostBudget', field: 'costBudget', type: 'number', controlType: 'textbox', nodeSource: 'initiative' }},
        { entry: { key: 'initiativeCostActual', field: 'costActual', type: 'number', controlType: 'textbox', nodeSource: 'initiative' }},
        { entry: { key: 'initiativeCostRemaining', field: 'costRemaining', type: 'number', controlType: 'textbox', nodeSource: 'initiative' }},
        { entry: { key: 'budgetPlanInitiativeY1Q1', field: 'budgetPlan', nodeBucket: true, nodeBucketId: 'Q1Y-1', nodeSource: 'initiative' }},
        { entry: { key: 'budgetPlanInitiativeY1Q2', field: 'budgetPlan', nodeBucket: true, nodeBucketId: 'Q2Y-1', nodeSource: 'initiative' }},
        { entry: { key: 'budgetPlanInitiativeY1Q3', field: 'budgetPlan', nodeBucket: true, nodeBucketId: 'Q3Y-1', nodeSource: 'initiative' }},
        { entry: { key: 'budgetPlanInitiativeY1Q4', field: 'budgetPlan', nodeBucket: true, nodeBucketId: 'Q4Y-1', nodeSource: 'initiative' }},
        { entry: { key: 'budgetPlanInitiativeY1', field: 'budgetPlan', nodeBucket: true, nodeBucketId: 'Y-1', nodeSource: 'initiative' }},
        { entry: { key: 'budgetPlanInitiativeY2Q1', field: 'budgetPlan', nodeBucket: true, nodeBucketId: 'Q1Y', nodeSource: 'initiative' }},
        { entry: { key: 'budgetPlanInitiativeY2Q2', field: 'budgetPlan', nodeBucket: true, nodeBucketId: 'Q2Y', nodeSource: 'initiative' }},
        { entry: { key: 'budgetPlanInitiativeY2Q3', field: 'budgetPlan', nodeBucket: true, nodeBucketId: 'Q3Y', nodeSource: 'initiative' }},
        { entry: { key: 'budgetPlanInitiativeY2Q4', field: 'budgetPlan', nodeBucket: true, nodeBucketId: 'Q4Y', nodeSource: 'initiative' }},
        { entry: { key: 'budgetPlanInitiativeY2', field: 'budgetPlan', nodeBucket: true, nodeBucketId: 'Y', nodeSource: 'initiative' }},
        { entry: { key: 'budgetPlanInitiativeY3Q1', field: 'budgetPlan', nodeBucket: true, nodeBucketId: 'Q1Y+1', nodeSource: 'initiative' }},
        { entry: { key: 'budgetPlanInitiativeY3Q2', field: 'budgetPlan', nodeBucket: true, nodeBucketId: 'Q2Y+1', nodeSource: 'initiative' }},
        { entry: { key: 'budgetPlanInitiativeY3Q3', field: 'budgetPlan', nodeBucket: true, nodeBucketId: 'Q3Y+1', nodeSource: 'initiative' }},
        { entry: { key: 'budgetPlanInitiativeY3Q4', field: 'budgetPlan', nodeBucket: true, nodeBucketId: 'Q4Y+1', nodeSource: 'initiative' }},
        { entry: { key: 'budgetPlanInitiativeY3', field: 'budgetPlan', nodeBucket: true, nodeBucketId: 'Y+1', nodeSource: 'initiative' }},
        { entry: { key: 'costInitiativeY1Q1', field: 'cost', nodeBucket: true, nodeBucketId: 'Q1Y-1', nodeSource: 'initiative' }},
        { entry: { key: 'costInitiativeY1Q2', field: 'cost', nodeBucket: true, nodeBucketId: 'Q2Y-1', nodeSource: 'initiative' }},
        { entry: { key: 'costInitiativeY1Q3', field: 'cost', nodeBucket: true, nodeBucketId: 'Q3Y-1', nodeSource: 'initiative' }},
        { entry: { key: 'costInitiativeY1Q4', field: 'cost', nodeBucket: true, nodeBucketId: 'Q4Y-1', nodeSource: 'initiative' }},
        { entry: { key: 'costInitiativeY1', field: 'cost', nodeBucket: true, nodeBucketId: 'Y-1', nodeSource: 'initiative' }},
        { entry: { key: 'costInitiativeY2Q1', field: 'cost', nodeBucket: true, nodeBucketId: 'Q1Y', nodeSource: 'initiative' }},
        { entry: { key: 'costInitiativeY2Q2', field: 'cost', nodeBucket: true, nodeBucketId: 'Q2Y', nodeSource: 'initiative' }},
        { entry: { key: 'costInitiativeY2Q3', field: 'cost', nodeBucket: true, nodeBucketId: 'Q3Y', nodeSource: 'initiative' }},
        { entry: { key: 'costInitiativeY2Q4', field: 'cost', nodeBucket: true, nodeBucketId: 'Q4Y', nodeSource: 'initiative' }},
        { entry: { key: 'costInitiativeY2', field: 'cost', nodeBucket: true, nodeBucketId: 'Y', nodeSource: 'initiative' }},
        { entry: { key: 'costInitiativeY3Q1', field: 'cost', nodeBucket: true, nodeBucketId: 'Q1Y+1', nodeSource: 'initiative' }},
        { entry: { key: 'costInitiativeY3Q2', field: 'cost', nodeBucket: true, nodeBucketId: 'Q2Y+1', nodeSource: 'initiative' }},
        { entry: { key: 'costInitiativeY3Q3', field: 'cost', nodeBucket: true, nodeBucketId: 'Q3Y+1', nodeSource: 'initiative' }},
        { entry: { key: 'costInitiativeY3Q4', field: 'cost', nodeBucket: true, nodeBucketId: 'Q4Y+1', nodeSource: 'initiative' }},
        { entry: { key: 'costInitiativeY3', field: 'cost', nodeBucket: true, nodeBucketId: 'Y+1', nodeSource: 'initiative' }}
      ]
    }
  }
];
