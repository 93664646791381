import { NodeCreate, RelationshipCreate } from '..';
import { INodeStructureRelationships } from '../connector';
import { IPayload } from '../shared';
import { IAction, ISuccessAction } from '../shared/common.interfaces';

export class NodesAndRelationshipsAction {

  static id = 'NodesAndRelationships';

  static CREATE = `[${NodesAndRelationshipsAction.id}] Create`;
  static CREATE_SUCCESS = `[${NodesAndRelationshipsAction.id}] Create success`;
  static CREATE_FAIL = `[${NodesAndRelationshipsAction.id}] Create failed`;

  public create(data: any): IAction {
    return {
      type: NodesAndRelationshipsAction.CREATE,
      payload: {
        id: data.modelId,
        data: {
          nodes: data.nodes,
          relationships: data.relationships
        }
      }
    };
  }

  public createSuccess(payload: IPayload, response: INodeStructureRelationships[]): ISuccessAction {
    return {
      type: NodesAndRelationshipsAction.CREATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public createFail(payload: IPayload, response: INodeStructureRelationships[]): ISuccessAction {
    return {
      type: NodesAndRelationshipsAction.CREATE_FAIL,
      payload: {
        request: payload,
        response: response
      }
    };
  }

}
