import { OrderedMap } from 'immutable';
import { isNullOrUndefined } from 'util';

import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { Node } from '../../../../shared/api/nodes';
import { NodeData } from '../../../../shared/api/nodedata';
import { Datum } from '../../../../shared/utilities/datum';

export class ColorlabelproviderServiceRelatedstatus extends ColorLabelProviderAbstract {

  public static MEDIUM = '#EDD515';
  public static HIGH = '#E50000';

  private nodeData = OrderedMap<string, NodeData>();

  public constructor(nodeData: OrderedMap<string, NodeData>) {
    super();
    this.nodeData = nodeData;
  }

  public color(element: Node, value?: any) {
    const now = new Datum();
    let max = 0;
    if (!isNullOrUndefined(element.relationships)) {
      (<number[]> element.relationships.relatedChildren).filter(id => this.nodeData.has('' + id)).map(id => {
        const nodeData = this.nodeData.get('' + id);
        if (new Datum(nodeData.targetDate).sameDay(now)) {
          if (max <= 1) { max = 1; }
        } else if (new Datum(nodeData.targetDate).beforeDay(now)) {
          if (max <= 2) { max = 2; }
        }
      });
      switch (max) {
        case 1:
          return [ColorlabelproviderServiceRelatedstatus.MEDIUM];
        case 2:
          return [ColorlabelproviderServiceRelatedstatus.HIGH];
      }
    }
    return undefined;
  }

}
