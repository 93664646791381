import { List, Map, OrderedMap } from 'immutable';
import * as d3 from 'd3';

import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { LegendItem } from '../../../../shared/legend/legend.item';
import { Node } from '../../../../shared/api/nodes';

export class ColorLabelProviderLevels extends ColorLabelProviderAbstract {

  protected levels = Map<number, any>();

  constructor() {
    super();
    this.levels = this.levels
      .set(0, { key: '0', color: '#ED6A5A', label: 'LEVEL.OBJECTIVE' })
      .set(1, { key: '1', color: '#F4F1BB', label: 'LEVEL.CHALLENGE' })
      .set(2, { key: '2', color: '#9BC1BC', label: 'LEVEL.SOLUTION' })
      .set(3, { key: '3', color: '#5CA4A9', label: 'LEVEL.DELIVERABLE' });
  }

  public color(element: Node) {
    if (this.levels.has(element.level)) {
      return [this.levels.get(element.level).color];
    } else {
      return super.color();
    }
  }

  public legend() {
    return this.levels.map(level => new LegendItem({ key: 'level.' + level.key, color: level.color, label: level.label, field: 'level', value: parseInt(level.key) })).toList();
  }

}
