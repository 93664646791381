import { NodeStructureState, NodeStructure, NodeStructureRelationships } from './nodestructures.models';
import { NodeStructureAction } from './nodestructures.actions';
import { JSONAPIDeserializerService } from '../shared/jsonapi-deserializer.service';
import { Utils } from '../shared/utils';
import { NodesAction } from '../nodes/nodes.actions';
import { Action } from '../shared/common.action';

export default function reducer(state = new NodeStructureState(), action: Action): NodeStructureState {
  const deserializer = new JSONAPIDeserializerService<NodeStructure, NodeStructureRelationships>(NodeStructure.fromJSONAPI, NodeStructureRelationships.fromJSONAPI);

  switch (action.type) {

    case NodeStructureAction.LOAD: {
      return <NodeStructureState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case NodeStructureAction.LOAD_WITH_TOKEN: {
      return <NodeStructureState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case NodeStructureAction.LOAD_SUCCESS: {
      const { entities, relationships } = deserializer.deserialize(action.payload.response);
      return <NodeStructureState>Utils.updateState<NodeStructureState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case NodeStructureAction.LOAD_FAIL: {
      return <NodeStructureState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case NodeStructureAction.CREATE: {
      return <NodeStructureState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case NodesAction.CREATE: {
      return <NodeStructureState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case NodeStructureAction.CREATE_SUCCESS: {
      const { entities, relationships } = deserializer.deserialize(action.payload.response);
      return <NodeStructureState>Utils.updateState<NodeStructureState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case NodeStructureAction.CREATE_FAIL: {
      return <NodeStructureState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case NodeStructureAction.REMOVE: {
      return <NodeStructureState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case NodeStructureAction.REMOVE_SUCCESS: {
      const { entities, relationships } = deserializer.deserialize(action.payload.response);
      return <NodeStructureState>Utils.deleteFromState<NodeStructureState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case NodeStructureAction.REMOVE_FAIL: {
      return <NodeStructureState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case NodeStructureAction.UPDATE: {
      return <NodeStructureState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case NodeStructureAction.UPDATE_SUCCESS: {
      const { entities, relationships } = deserializer.deserialize(action.payload.response);
      return <NodeStructureState>Utils.updateState<NodeStructureState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case NodeStructureAction.UPDATE_FAIL: {
      return <NodeStructureState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case NodeStructureAction.TAG: {
      return <NodeStructureState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case NodeStructureAction.UNTAG: {
      return <NodeStructureState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case NodeStructureAction.REASSIGN: {
      return <NodeStructureState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case NodeStructureAction.REASSIGN_SUCCESS: {
      const { entities, relationships } = deserializer.deserialize(action.payload.response);
      return <NodeStructureState>Utils.updateState<NodeStructureState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case NodeStructureAction.REASSIGN_FAIL: {
      return <NodeStructureState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    default:
      return state;
  }
}
