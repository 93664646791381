import { Map } from 'immutable';
import { Subscription } from 'rxjs/Subscription';
import { isFunction, isNullOrUndefined, isString } from 'util';

declare var jQuery: any;

export class SubscriptionService {

  private subscriptions = Map<string, Subscription>();
  private attaches = Map<string, Map<string, any>>();
  private vanilla = Map<string, any>();
  // @ts-ignore
  private observers = Map<string, { observer: ResizeObserver, element: any }>();

  public add(key: string, subscription: Subscription) {
    if (this.subscriptions.has(key)) {
      this.subscriptions.get(key).unsubscribe();
    }
    this.subscriptions = this.subscriptions.set(key, subscription);
  }

  public remove(key?: string) {
    if (isString(key)) {
      if (this.subscriptions.has(key)) {
        this.subscriptions.get(key).unsubscribe();
        this.subscriptions = this.subscriptions.remove(key);
      }
    } else {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
      this.subscriptions = Map<string, Subscription>();
    }
  }

  public attach(event: string, element: any, callback: any, key: string) {
    if (this.attaches.has(key)) {
      this.detach(key);
    }
    jQuery(element).on(event, function(e) { callback(this, e); });
    this.attaches = this.attaches.set(key, (this.attaches.has(key) ? this.attaches.get(key) : Map<string, any>()).set(event, element));
  }

  public detach(key?: string) {
    if (isString(key)) {
      if (this.attaches.has(key)) {
        this.attaches.get(key).forEach((element: any, event: string) => {
          if (!isNullOrUndefined(element) && isFunction(element.off)) {
            element.off(event);
          }
        });
      }
    } else {
      this.attaches.forEach(att => att.forEach((element: any, event: string) => {
        if (!isNullOrUndefined(element) && isFunction(element.off)) {
          element.off(event);
        }
      }));
      this.attaches = this.attaches.clear();
    }
  }

  public observe(key: string, element: any, callback: Function) {
    if (this.observers.has(key)) {
      const d = this.observers.get(key);
      d.observer.unobserve(d.element);
      d.observer = undefined;
    }
    // @ts-ignore
    const observer = new ResizeObserver(callback);
    observer.observe(element);
    this.observers = this.observers.set(key, { observer: observer, element: element });
  }

  public unobserve(key?: string) {
    if (isString(key)) {
      if (this.observers.has(key)) {
        const d = this.observers.get(key);
        d.observer.unobserve(d.element);
        d.observer = undefined;
        this.observers = this.observers.remove(key);
      }
    } else {
      this.observers.forEach(d => d.observer.unobserve(d.element));
      this.observers = this.observers.clear();
    }
  }

}
