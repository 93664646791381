import { Record, Set, Map } from 'immutable';
import { DataModelState, RequestDiffRecord } from '../shared';


const formStateRecord = Record({
  ids: Set<string>(),
  entities: Map<string, any>(),
  diff: new RequestDiffRecord(),
  isBusy: 0
});

export class FormState extends formStateRecord implements DataModelState {
  ids: Set<string>;
  entities: Map<string, any>;
  diff: RequestDiffRecord;
  relationships: undefined;
  isBusy: number;
}
