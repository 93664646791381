import { Map } from 'immutable';

import { Action } from '../shared/common.action';
import { JSONAPIDeserializerService, Utils } from '../shared';
import { FormAction } from './form.actions';
import { NodeData } from '../nodedata';
import { NodeDataRelationships } from '../nodedata/nodedata.models';
import { NodeStructure, NodeStructureRelationships } from '../nodestructures';
import { Relationship } from '../relationships';
import { RelationshipRelationships } from '../relationships/relationships.models';
import { Node } from '../nodes';
import { FormState } from './form.models';

export default function reducer(state = new FormState(), action: Action): FormState {
  switch (action.type) {

    case FormAction.LOAD:
      return <FormState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => 1)
      );

    case FormAction.LOAD_SUCCESS: {
      let data = Map<string, any>();

      const nodeDataDeserializer = new JSONAPIDeserializerService<NodeData, NodeDataRelationships>(NodeData.fromJSONAPI, NodeDataRelationships.fromJSONAPI);
      const nodeStructureDeserializer = new JSONAPIDeserializerService<NodeStructure, NodeStructureRelationships>(NodeStructure.fromJSONAPI, NodeStructureRelationships.fromJSONAPI);
      const relationshipDeserializer = new JSONAPIDeserializerService<Relationship, RelationshipRelationships>(Relationship.fromJSONAPI, RelationshipRelationships.fromJSONAPI);

      let nodeStructures = Map<string, NodeStructure>();
      let nodeData = Map<string, NodeData>();

      const count = action.payload.response[0].length;
      for (let i = 0; i < count; i++) {
        const item = action.payload.response[0][i];
        switch (item.type) {
          case 'nodestructures':
            const a = nodeStructureDeserializer.deserialize(item);
            const aa = <NodeStructure> a.entities.first().set('relationships', a.relationships.first());
            nodeStructures = nodeStructures.set(aa.id, aa);
            break;
          case 'nodedata':
            const b = nodeDataDeserializer.deserialize(item);
            const bb = <NodeData> b.entities.first().set('relationships', b.relationships.first());
            nodeData = nodeData.set(bb.id, bb);
            break;
          case 'relationships':
            const c = relationshipDeserializer.deserialize(item);
            const cc = <Relationship> c.entities.first().set('relationships', c.relationships.first());
            data = data.set(cc.id, cc);
            break;
        }
      }

      /* Merge node data and structure */
      nodeStructures.forEach(nodeStructure => {
        /* Get the node data */
        const nodeDatum = nodeData.get(nodeStructure.relationships.nodedata);
        /* Generate the node */
        const node = new Node(Object.assign(nodeDatum.toJS(), nodeStructure.toJS(), {
          data_duplicate_original_id: nodeDatum.duplicate_original_id,
          structure_duplicate_original_id: nodeStructure.duplicate_original_id,
          updatedAt: (nodeStructure.updatedAt > nodeDatum.updatedAt ? nodeStructure.updatedAt : nodeDatum.updatedAt),
          relationships: Object.assign(nodeStructure.relationships.toJS(), nodeDatum.relationships.toJS())
        }));
        /* Store the node */
        data = data.set(node.id, node);
      });

      return <FormState>Utils.updateStateWithoutMerge<FormState>(state, data, action)
        .updateIn(['isBusy'], (isBusy: number) => (isBusy === 0 ? 0 : --isBusy));
    }

    case FormAction.LOAD_FAIL: {
      return <FormState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => (isBusy === 0 ? 0 : --isBusy))
      );
    }

    case FormAction.LOAD_ALL:
      return <FormState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => 1)
      );

    case FormAction.LOAD_ALL_SUCCESS: {
      let data = Map<string, any>();
      const payload = action.payload.response[0].data;
      const payloadCount = payload.length;
      for (let i = 0; i < payloadCount; i++) {
        const datum = payload[i];
        data = data.set(datum.id, datum);
      }
      return <FormState>Utils.updateStateWithoutMerge<FormState>(state, data, action, true)
        .updateIn(['isBusy'], (isBusy: number) => (isBusy === 0 ? 0 : --isBusy));
    }

    case FormAction.LOAD_ALL_FAIL: {
      return <FormState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => (isBusy === 0 ? 0 : --isBusy))
      );
    }

    default: {
      return state;
    }
  }
}
