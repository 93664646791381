import { List } from 'immutable';

import { Node } from '../../../../shared/api/nodes';
import { Relationship } from '../../../../shared/api/relationships';
import { Activity } from '../../../../shared/api/activities';

export class TreeComponentHashing {

  public getHash(list: List<Node | Relationship | Activity>) {
    return list.map(_ => _.id + '-' + _.updatedAt).toArray().toString();
  }

}
