import { EmailAction } from './email.actions';
import { Utils } from '../shared/utils';
import { Action } from '../shared/common.action';
import { EmailState } from './email.model';

export default function reducer(state = new EmailState(), action: Action): EmailState {

  switch (action.type) {
    case EmailAction.SEND_EMAIL_SUCCESS: {
      return <EmailState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case EmailAction.SEND_EMAIL_FAIL: {
      return <EmailState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    default:
      return state;
  }
}
