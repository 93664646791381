import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { List, Map } from 'immutable';
import { Observable } from 'rxjs/Observable';

import { AppState } from '../../app.reducer';

import { Model } from './models.models';
import { ModelAction } from './models.actions';
import * as selectors from './models.selectors';
import { IPayload } from '../shared/common.models';
import { SharedService } from '../shared/shared.service';

@Injectable()
export class ModelService extends SharedService {

  public constructor(protected store: Store<AppState>, protected action: ModelAction) {
    super(store, action, selectors);
  }

  public loadFavorites() {
    this.store.dispatch(this.action.loadFavorites());
  }

  public findFavorites(): Observable<Map<string, any>> {
    return this.getAPIState().let(selectors.getFavoriteModels());
  }

  public addFavorite(id: string) {
    this.store.dispatch(this.action.addFavorite(id));
  }

  public removeFavorite(id: string) {
    this.store.dispatch(this.action.removeFavorite(id));
  }

  public initiatives(id: number | string) {
    this.store.dispatch(this.action.initiativesLoad('' + id));
  }

  public findByBusinessarea(id: string): Observable<List<Model>> {
    return this.all().map((models: List<Model>) => models.filter(model => model.relationships.businessarea + '' === id + '').toList());
  }

  public findByModel(id: string): Observable<List<Model>> {
    return this.all().map((models: List<Model>) => models.filter(model => model.relationships.model === id).toList());
  }

  public createOnBusinessarea(id: string, data: IPayload | IPayload[]) {
    this.store.dispatch(this.action.createOnBusinessarea(id, data));
  }

  public createOnModel(id: string, data: IPayload | IPayload[]) {
    this.store.dispatch(this.action.createOnModel(id, data));
  }

  public duplicateOnBusinessarea(businessareaId: string, data: IPayload | IPayload[]) {
    this.store.dispatch(this.action.duplicateOnBusinessarea(businessareaId, data));
  }

}
