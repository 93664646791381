import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';

import { Action } from '../shared/common.action';
import { GoAPI } from '../connector/shared/api.go.service';
import { GuardianAction } from './guardian.actions';

@Injectable()
export class GuardianEffects {

  @Effect() attach$ = this.actions$
    .pipe(ofType(GuardianAction.FIND))
    .mergeMap((action: Action) => this.api.guardian().post(action.payload.data).mergeMap(response => Observable.from([
      this.guardianAction.findSuccess(response)
    ])).catch((error: any) => Observable.of(this.guardianAction.findFail(error))));

  constructor(private api: GoAPI,
              private actions$: Actions,
              private guardianAction: GuardianAction) {
  }
}
