import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { TreeNode } from '../../../core/interface/core.interface';
import { Datum } from '../../../shared/utilities/datum';
import { NODES_TYPE_CAPACITY, NODES_TYPE_COUNTERMEASURE, NODES_TYPE_IGNORE, NODES_TYPE_PROJECT, NODES_TYPE_REQUIREMENT } from '../../../shared/api/nodes/nodes.models';
import { FormService } from '../../../components/form/service/form.service';
import { Map } from 'immutable';
import { isNullOrUndefined } from 'util';

export class ColorLabelProviderServiceProjectsAvailable extends ColorLabelProviderAbstract {

  public complex = true;

  private static AVAILABLE = '#9ac93e';
  private static NOTAVAILABLE = '#E50000';

  public constructor(private formService: FormService) {
    super();
  }

  public getColorCode(value: string | number) {
    if (isNullOrUndefined(value) || value === '') {
      return super.color();
    }
    if (value >= 0) {
      return [ColorLabelProviderServiceProjectsAvailable.AVAILABLE];
    } else {
      return [ColorLabelProviderServiceProjectsAvailable.NOTAVAILABLE];
    }
  }

  public color(project: TreeNode) {
    if (project.nodeType !== NODES_TYPE_PROJECT) {
      return super.color();
    }
    return this.getColorCode(this.calculate(project));
  }

  public calculate(project: TreeNode) {
    /* Values */
    let requirementValue = null;
    let capacityValue = null;
    /* Project dates */
    const projectStart = new Datum(project.startDate);
    const projectEnd = new Datum(project.targetDate);
    const months = projectStart.getMonthCount(projectEnd, true);
    /* Get the linked requirement nodes */
    const requirementNodes = project.unfilteredChildren.filter(child => child.nodeType === NODES_TYPE_REQUIREMENT);
    const count = requirementNodes.length;
    for (let i = 0; i < count; i++) {
      const requirementNode = requirementNodes[i];
      requirementValue = this.addDates(projectStart, months, requirementNode, requirementValue);
      /* Get the linked capacity nodes */
      const capacityNodes = requirementNode.unfilteredChildren.filter(child => child.nodeType === NODES_TYPE_CAPACITY);
      const count2 = capacityNodes.length;
      for (let i2 = 0; i2 < count2; i2++) {
        const capacityNode = capacityNodes[i2];
        capacityValue = this.addDates(projectStart, months, capacityNode, capacityValue);
        /* Counter measures */
        const counterMeasures = capacityNode.unfilteredChildren.filter(child => child.nodeType === NODES_TYPE_COUNTERMEASURE || child.nodeType === NODES_TYPE_IGNORE);
        const count4 = counterMeasures.length;
        for (let i4 = 0; i4 < count4; i4++) {
          capacityValue += this.calculateValue(counterMeasures[i4]);
        }
      }
    }
    if (isNullOrUndefined(capacityValue) || isNullOrUndefined(requirementValue)) {
      return undefined;
    }
    return capacityValue - requirementValue;
  }

  private addDates(start: Datum, months: number, treeNode: TreeNode, result: number) {
    for (let i = 0; i < months; i++) {
      const d = start.clone().addMonths(i);
      let value = this.calculateValue(treeNode, d);
      if (treeNode.nodeType === NODES_TYPE_CAPACITY) {
        value = value * 17;
      } else if (treeNode.nodeType === NODES_TYPE_REQUIREMENT) {
        value = value / months;
      }
      if (isNullOrUndefined(result)) {
        result = 0;
      }
      result += value;
    }
    return result;
  }

  private calculateValue(treeNode: TreeNode, date?: Datum): number {
    if (isNullOrUndefined(treeNode)) {
      return 0;
    }
    switch (treeNode.nodeType) {
      case NODES_TYPE_CAPACITY:
        return this.formService.aggregateChildren(Map().set('entryNode', Map().set('formFieldId', 'budget').set('formBucket', true).toJS()).set('nodeBucket', true).toJS(), treeNode, date);
      case NODES_TYPE_REQUIREMENT:
        return this.formService.getValue(<TreeNode> { formFieldId: 'budget', formBucket: true }, treeNode);
      case NODES_TYPE_COUNTERMEASURE:
      case NODES_TYPE_IGNORE:
        return this.formService.getValue(<TreeNode> { formFieldId: 'budget' }, treeNode);
    }
  }

}
