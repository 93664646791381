import { NgModule, Optional, SkipSelf } from '@angular/core';
import { StoreModule as NgrxStore } from '@ngrx/store';
import { APIModule } from './api';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../environments/environment';
import { rootReducer } from './app.reducer';

const imports = [
  NgrxStore.forRoot(rootReducer),
  // APIModule
];

const devImports = [
  StoreDevtoolsModule.instrument()
];

if (!environment.production) {
  // imports.push(...devImports);
}

@NgModule({
  imports: [
    ...imports
  ],
  exports: [
    APIModule
  ]
})
export class StoreModule {
  constructor(@Optional() @SkipSelf() parentModule: StoreModule) {
    if (parentModule) {
      throw new Error('StoreModule already loaded; Import in root module only.');
    }
  }
}
