import { NgModule } from '@angular/core';

import { ThirdPartyOctimineService } from './thirdparty.octimine.service';
import { ThirdPartyTrendExplorerService } from './thirdparty.trendexplorer.service';
import { ThirdPartyCrunchbaseService } from './thirdparty.crunchbase.service';

@NgModule({
  providers: [
    ThirdPartyOctimineService,
    ThirdPartyTrendExplorerService,
    ThirdPartyCrunchbaseService
  ]
})
export class ThirdPartyModule {}
