import { Utils } from '../shared/utils';
import { Action } from '../shared/common.action';
import { NodeAction } from './node.actions';
import { Node, NodeRelationships, NodeState } from './node.models';
import { DeserializerService } from '../shared';

export default function reducer(state = new NodeState(), action: Action): NodeState {

  const deserializer = new DeserializerService<Node, Node>(Node.fromAPI, NodeRelationships.fromAPI);

  switch (action.type) {

    case NodeAction.LOAD: {
      return <NodeState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case NodeAction.LOAD_SUCCESS: {
      const { entities, relationships, secondaryCosts } = deserializer.deserialize(action.payload.response);
      return <NodeState>Utils.updateGoState<NodeState>(state, entities, relationships, secondaryCosts)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case NodeAction.LOAD_FAIL: {
      return <NodeState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case NodeAction.LOAD_IPC: {
      return <NodeState> state.withMutations(s => s
        .updateIn(['isBusyIPC'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case NodeAction.LOAD_IPC_SUCCESS: {
      const { entities } = deserializer.deserialize(action.payload.response);
      return <NodeState>Utils.updateDiff<NodeState>(state, action)
        .updateIn(['isBusyIPC'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case NodeAction.LOAD_IPC_FAIL: {
      return <NodeState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusyIPC'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    default:
      return state;
  }
}
