export const TemplateFieldsReportsStatus = [
  {
    type: 'status',
    settings: {
      /* Show the footer */
      showFooter: true,
      /* Show the assigned activities */
      activities: true,
      /* Trigger to load the Businessarea with that table */
      loadBusinessarea: false,
      /* Exports */
      exports: ['html', 'png'], //  excel, pdf, html
    },
    configuration: {
      fields: [
        { entry: { key: 'description', controlType: 'text', label: 'REPORTS.STATUS.KEYQUESTION'}},
        { entry: { key: 'organizationalDescription', controlType: 'text', label: 'REPORTS.STATUS.PROJECTORGANISATION' }},
        { entry: { key: 'riskProfileCountermeasures', controlType: 'text', label: 'REPORTS.STATUS.EXPLANATION' }},
        { entry: { key: 'description4', controlType: 'text', label: 'REPORTS.STATUS.DESCRIPTION' }},
        { entry: { key: 'benefitBudget', type: 'number', controlType: 'textbox', label: 'REPORTS.STATUS.BENEFITBUDGET' }},
        { entry: { key: 'benefitRemaining', type: 'number', controlType: 'textbox', label: 'REPORTS.STATUS.BENEFITREMAINING' }},
        { entry: { key: 'costBudget', type: 'number', controlType: 'textbox', label: 'REPORTS.STATUS.COSTBUDGET' }},
        { entry: { key: 'costRemaining', type: 'number', controlType: 'textbox', label: 'REPORTS.STATUS.COSTREMAINING' }},
        { entry: { key: 'investBudget', type: 'number', controlType: 'textbox', label: 'REPORTS.STATUS.INVESTBUDGET' }},
        { entry: { key: 'investRemaining', type: 'number', controlType: 'textbox', label: 'REPORTS.STATUS.INVESTREMAINING' }},
        { entry: { key: 'riskProfileAssessment', controlType: 'dropdown', label: 'REPORTS.STATUS.TOTAL', options: [
          { key: 0, value: 'REPORTS.STATUS.DROPDOWN.NOTAPPLICABLE' },
          { key: 1, value: 'REPORTS.STATUS.DROPDOWN.LOW.TITLE' },
          { key: 2, value: 'REPORTS.STATUS.DROPDOWN.MEDIUM.TITLE' },
          { key: 3, value: 'REPORTS.STATUS.DROPDOWN.HIGH.TITLE' },
        ]}},
        { entry: { key: 'complexity', controlType: 'dropdown', label: 'REPORTS.STATUS.IT', options: [
          { key: 0, value: 'REPORTS.STATUS.DROPDOWN.NOTAPPLICABLE' },
          { key: 1, value: 'REPORTS.STATUS.DROPDOWN.LOW.TITLE' },
          { key: 2, value: 'REPORTS.STATUS.DROPDOWN.MEDIUM.TITLE' },
          { key: 3, value: 'REPORTS.STATUS.DROPDOWN.HIGH.TITLE' },
        ]}},
        { entry: { key: 'time', controlType: 'dropdown', label: 'REPORTS.STATUS.TIMESCHEDULE', options: [
          { key: 0, value: 'REPORTS.STATUS.DROPDOWN.NOTAPPLICABLE' },
          { key: 1, value: 'REPORTS.STATUS.DROPDOWN.LOW.TITLE' },
          { key: 2, value: 'REPORTS.STATUS.DROPDOWN.MEDIUM.TITLE' },
          { key: 3, value: 'REPORTS.STATUS.DROPDOWN.HIGH.TITLE' },
        ]}},
        { entry: { key: 'cost', type: 'number', controlType: 'dropdown', label: 'REPORTS.STATUS.QUANTITATIVE', options: [
          { key: 0, value: 'REPORTS.STATUS.DROPDOWN.NOTAPPLICABLE' },
          { key: 1, value: 'REPORTS.STATUS.DROPDOWN.LOW.TITLE' },
          { key: 2, value: 'REPORTS.STATUS.DROPDOWN.MEDIUM.TITLE' },
          { key: 3, value: 'REPORTS.STATUS.DROPDOWN.HIGH.TITLE' },
        ]}},
        { entry: { key: 'quality', type: 'number', controlType: 'dropdown', label: 'REPORTS.STATUS.QUALITATIVE', options: [
          { key: 0, value: 'REPORTS.STATUS.DROPDOWN.NOTAPPLICABLE' },
          { key: 1, value: 'REPORTS.STATUS.DROPDOWN.LOW.TITLE' },
          { key: 2, value: 'REPORTS.STATUS.DROPDOWN.MEDIUM.TITLE' },
          { key: 3, value: 'REPORTS.STATUS.DROPDOWN.HIGH.TITLE' },
        ]}},
        { entry: { key: 'technical', type: 'number', controlType: 'dropdown', showHeader: false, label: 'REPORTS.STATUS.TEMPER', options: [
          { key: 0, value: 'REPORTS.STATUS.DROPDOWN.NOTAPPLICABLE' },
          { key: 1, value: 'REPORTS.STATUS.DROPDOWN.L1' },
          { key: 2, value: 'REPORTS.STATUS.DROPDOWN.L2' },
          { key: 3, value: 'REPORTS.STATUS.DROPDOWN.L3' },
          { key: 4, value: 'REPORTS.STATUS.DROPDOWN.L4' },
          { key: 5, value: 'REPORTS.STATUS.DROPDOWN.L5' }
        ]}}
      ]
    }
  }
];
