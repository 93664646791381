export const TemplateFieldsHome = {
  grid: {
    string: 'a:[:b:[:i1:i2:]:i3:]',
    sizes: {
      'a': 100,
      'b': 50
    },
    directions: {
      'a': 'vertical',
      'b': 'horizontal'
    },
    id: 'items',
    tags: {
      generic: { id: 'generic', label: 'DASHBOARD.TAGS.GENERIC' },
      sidebar: { id: 'sidebar', label: 'DASHBOARD.TAGS.SIDEBAR' },
      topmost: { id: 'topmost', label: 'DASHBOARD.TAGS.TOPMOST' },
      reports: { id: 'reports', label: 'DASHBOARD.TAGS.REPORTS' },
      layouts: { id: 'layouts', label: 'DASHBOARD.TAGS.LAYOUTS' },
      datamining: { id: 'datamining', label: 'DASHBOARD.TAGS.DATAMINING' },
      matrix: { id: 'matrix', label: 'DASHBOARD.TAGS.MATRIX' }
    },
    objects: {
      'a': { gid: 'a' },
      'b': { gid: 'b' },

      /* Elements */
      'i1': { gid: 'i1', id: 'instance.list', label: 'INSTANCE.PLURAL', description: 'DASHBOARD.INSTANCE.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['generic'] },
      'i2': { gid: 'i2', id: 'templates', label: 'TEMPLATE.PLURAL', description: 'DASHBOARD.TEMPLATE.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['generic'] },

      /* Widgets */
      'i3': { gid: 'i3', id: 'dashboard.nodes', label: 'DASHBOARD.NODES.TITLE', description: 'DASHBOARD.NODES.DESCRIPTION', dropZones: ['dashboard', 'sidebar', 'unused'], tags: ['generic'] },
      'i4': { gid: 'i4', id: 'dashboard.benefit', label: 'DASHBOARD.BENEFIT.TITLE', description: 'DASHBOARD.BENEFIT.DESCRIPTION', dropZones: ['dashboard', 'sidebar', 'unused'], tags: ['generic'] },
      'i5': { gid: 'i5', id: 'dashboard.nodestoreview', label: 'DASHBOARD.NODESTOREVIEW.TITLE', description: 'DASHBOARD.NODESTOREVIEW.DESCRIPTION', dropZones: ['dashboard', 'sidebar', 'unused'], tags: ['generic'] },
      'i6': { gid: 'i6', id: 'dashboard.cost', label: 'DASHBOARD.COST.TITLE', description: 'DASHBOARD.COST.DESCRIPTION', dropZones: ['dashboard', 'sidebar', 'unused'], tags: ['generic'] },
      'i7': { gid: 'i7', id: 'dashboard.accomplishments', label: 'DASHBOARD.ACCOMPLISHMENTS.TITLE', description: 'DASHBOARD.ACCOMPLISHMENTS.DESCRIPTION', dropZones: ['dashboard', 'sidebar', 'unused'], tags: ['generic'] },
      'i9': { gid: 'i9', id: 'dashboard.topmost', label: 'DASHBOARD.TOPMOST.TITLE', description: 'DASHBOARD.TOPMOST.DESCRIPTION', dropZones: ['dashboard', 'sidebar', 'unused'], tags: ['generic'] },
      'i21': { gid: 'i21', id: 'dashboard.reports', label: 'DASHBOARD.REPORTS.TITLE', description: 'DASHBOARD.REPORTS.DESCRIPTION', hierarchy: true, dropZones: ['dashboard', 'unused'], tags: ['generic'] },
      'i36': { gid: 'i36', id: 'dashboard.layouts', label: 'DASHBOARD.LAYOUTS.TITLE', description: 'DASHBOARD.LAYOUTS.DESCRIPTION', hierarchy: true, dropZones: ['dashboard', 'unused'], tags: ['generic'] },
      'i39': { gid: 'i39', id: 'dashboard.todo', label: 'DASHBOARD.TODO.TITLE', description: 'DASHBOARD.TODO.DESCRIPTION', dropZones: ['dashboard', 'sidebar', 'unused'], tags: ['generic'] },
      'i40': { gid: 'i40', id: 'dashboard.modelmenu', label: 'DASHBOARD.MYMENU.TITLE', description: 'DASHBOARD.MYMENU.DESCRIPTION', dropZones: ['dashboard', 'unused', 'sidebar'], tags: ['generic'] },
      'i41': { gid: 'i41', id: 'dashboard.compliance', label: 'DASHBOARD.COMPLIANCE.TITLE', description: 'DASHBOARD.COMPLIANCE.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['generic'] },
      'i42': { gid: 'i42', id: 'dashboard.qreport', label: 'DASHBOARD.QREPORT.TITLE', description: 'DASHBOARD.QREPORT.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['generic'] },
      'i43': { gid: 'i43', id: 'dashboard.hierarchytreeview', label: 'DASHBOARD.HIERARCHYTREEVIEW.TITLE', description: 'DASHBOARD.HIERARCHYTREEVIEW.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['generic'] },
      'i46': { gid: 'i46', id: 'dashboard.statuspie', label: 'DASHBOARD.STATUSPIE.TITLE', description: 'DASHBOARD.STATUSPIE.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['generic'] },
      'i48': { gid: 'i48', id: 'dashboard.scenariocomparison', label: 'DASHBOARD.SCENARIOCOMPARISON.TITLE', description: 'DASHBOARD.SCENARIOCOMPARISON.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['generic'] },
      'i49': { gid: 'i49', id: 'dashboard.burndown', label: 'DASHBOARD.BURNDOWN.TITLE', description: 'DASHBOARD.BURNDOWN.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['generic'] },
      'i53': { gid: 'i53', id: 'dashboard.emailtodos', label: 'DASHBOARD.EMAILTODOS.TITLE', description: 'DASHBOARD.EMAILTODOS.DESCRIPTION', dropZones: ['dashboard', 'sidebar', 'unused'], tags: ['generic'] },
      'i64': { gid: 'i64', id: 'dashboard.strategyanchorage', label: 'DASHBOARD.STRATEGYANCHORAGE.TITLE', description: 'DASHBOARD.STRATEGYANCHORAGE.DESCRIPTION', dropZones: ['dashboard', 'sidebar', 'unused'], tags: ['generic'] },
      'i65': { gid: 'i65', id: 'dashboard.permissions', label: 'DASHBOARD.PERMISSIONS.TITLE', description: 'DASHBOARD.PERMISSIONS.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['generic'] },
      'i67': { gid: 'i67', id: 'dashboard.checklist', label: 'DASHBOARD.CHECKLIST.TITLE', description: 'DASHBOARD.CHECKLIST.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['generic'] },
      'i68': { gid: 'i68', id: 'dashboard.eisenhoweroverview', label: 'DASHBOARD.EISENHOWEROVERVIEW.TITLE', description: 'DASHBOARD.EISENHOWEROVERVIEW.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['generic'] },
      'i69': { gid: 'i69', id: 'dashboard.assignmentoftasks', label: 'DASHBOARD.ASSIGNMENTOFTASKS.TITLE', description: 'DASHBOARD.ASSIGNMENTOFTASKS.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['generic'] },
      'i71': { gid: 'i71', id: 'dashboard.requirementdefinition', label: 'DASHBOARD.REQUIREMENTDEFINITION.TITLE', description: 'DASHBOARD.REQUIREMENTDEFINITION.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['generic'] },
      'i72': { gid: 'i72', id: 'dashboard.myadmin', label: 'DASHBOARD.MYADMIN.TITLE', description: 'DASHBOARD.MYADMIN.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['generic'] },
      'i73': { gid: 'i73', id: 'dashboard.library', label: 'DASHBOARD.LIBRARY.TITLE', description: 'DASHBOARD.LIBRARY.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['generic'] },
      'i74': { gid: 'i74', id: 'dashboard.benchmarking', label: 'DASHBOARD.BENCHMARKING.TITLE', description: 'DASHBOARD.BENCHMARKING.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['generic'] },
      'i75': { gid: 'i75', id: 'dashboard.sunburst', label: 'DASHBOARD.SUNBURST.TITLE', description: 'DASHBOARD.SUNBURST.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['layouts'] },
      'i76': { gid: 'i76', id: 'dashboard.matrix', label: 'DASHBOARD.MATRIX.TITLE', description: 'DASHBOARD.MATRIX.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['generic'] },
      'i78': { gid: 'i78', id: 'dashboard.octimine', label: 'DASHBOARD.OCTIMINE.TITLE', description: 'DASHBOARD.OCTIMINE.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['generic'] },
      'i79': { gid: 'i79', id: 'dashboard.datamining', label: 'DASHBOARD.DATAMINING.TITLE', description: 'DASHBOARD.DATAMINING.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['generic'] },
      'i80': { gid: 'i80', id: 'dashboard.myassignements', label: 'DASHBOARD.MYASSIGNEMENTS.TITLE', description: 'DASHBOARD.MYASSIGNEMENTS.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['generic'] },
      'i84': { gid: 'i84', id: 'dashboard.trends', label: 'DASHBOARD.TRENDS.TITLE', description: 'DASHBOARD.TRENDS.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['generic'] },
      'i86': { gid: 'i86', id: 'dashboard.import', label: 'DASHBOARD.IMPORT.TITLE', description: 'DASHBOARD.IMPORT.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['generic'] },
      'i87': { gid: 'i87', id: 'dashboard.ipnavigator', label: 'DASHBOARD.IPNAVIGATOR.TITLE', description: 'DASHBOARD.IPNAVIGATOR.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['generic'] },
      'i88': { gid: 'i88', id: 'dashboard.whiteboard', label: 'DASHBOARD.WHITEBOARD.TITLE', description: 'DASHBOARD.WHITEBOARD.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['generic'] },
      'i89': { gid: 'i89', id: 'dashboard.workflowplayground', label: 'DASHBOARD.WORKFLOWPLAYGROUND.TITLE', description: 'DASHBOARD.WORKFLOWPLAYGROUND.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['generic'] },
      'i90': { gid: 'i90', id: 'dashboard.gantt', label: 'DASHBOARD.GANTT.TITLE', description: 'DASHBOARD.GANTT.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['generic'] },
      'i91': { gid: 'i91', id: 'dashboard.dev', label: 'DASHBOARD.DEV.TITLE', description: 'DASHBOARD.DEV.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['generic'] },
      'i92': { gid: 'i92', id: 'dashboard.compare', label: 'DASHBOARD.COMPARE.TITLE', description: 'DASHBOARD.COMPARE.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['generic'] },

      /* Sidebar Widgets */
      'i100': { gid: 'i100', id: 'sidebar.userprofile', label: 'DASHBOARD.SIDEBAR.USERPROFILE.TITLE', description: 'DASHBOARD.SIDEBAR.USERPROFILE.DESCRIPTION', dropZones: ['dashboard', 'sidebar', 'unused'], tags: ['sidebar'] },
      'i101': { gid: 'i101', id: 'sidebar.humanresources', label: 'DASHBOARD.SIDEBAR.HUMANRESOURCES.TITLE', description: 'DASHBOARD.SIDEBAR.HUMANRESOURCES.DESCRIPTION', dropZones: ['dashboard', 'sidebar', 'unused'], tags: ['sidebar'] },
      'i102': { gid: 'i102', id: 'sidebar.subsets', label: 'DASHBOARD.SIDEBAR.SUBSETS.TITLE', description: 'DASHBOARD.SIDEBAR.SUBSETS.DESCRIPTION', dropZones: ['dashboard', 'sidebar', 'unused'], tags: ['sidebar'] },
      'i103': { gid: 'i103', id: 'sidebar.favoritemodels', label: 'DASHBOARD.SIDEBAR.FAVORITEMODELS.TITLE', description: 'DASHBOARD.SIDEBAR.FAVORITEMODELS.DESCRIPTION', dropZones: ['dashboard', 'sidebar', 'unused'], tags: ['sidebar'] },
      'i104': { gid: 'i104', id: 'sidebar.notifications', label: 'DASHBOARD.SIDEBAR.NOTIFICATIONS.TITLE', description: 'DASHBOARD.SIDEBAR.NOTIFICATIONS.DESCRIPTION', hierarchy: true, dropZones: ['dashboard', 'sidebar', 'unused'], tags: ['sidebar'] },

      /* Child Widgets */
      'i10': { gid: 'i10', child: true, hierarchy: true, id: 'dashboard.topmost', overwrite: [{
        settings: { modeltype: 'compliance' },
        title: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.COMPLIANCE'
      }], label: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.COMPLIANCE', description: 'DASHBOARD.MODELTYPE.COMPLIANCE.DESCRIPTION', dropZones: ['dashboard', 'sidebar', 'unused'], tags: ['topmost'] },
      'i11': { gid: 'i11', child: true, hierarchy: true, id: 'dashboard.topmost', overwrite: [{
        settings: { modeltype: 'strategydeployment' },
        title: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.STRATEGYDEPLOYMENT'
      }], label: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.STRATEGYDEPLOYMENT', description: 'DASHBOARD.MODELTYPE.STRATEGYDEPLOYMENT.DESCRIPTION', dropZones: ['dashboard', 'sidebar', 'unused'], tags: ['topmost'] },
      'i12': { gid: 'i12', child: true, hierarchy: true, id: 'dashboard.topmost', overwrite: [{
        settings: { modeltype: 'programmanagement' },
        title: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.PROGRAMMANAGEMENT'
      }], label: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.PROGRAMMANAGEMENT', description: 'DASHBOARD.MODELTYPE.PROGRAMMANAGEMENT.DESCRIPTION', dropZones: ['dashboard', 'sidebar', 'unused'], tags: ['topmost'] },
      'i13': { gid: 'i13', child: true, hierarchy: true, id: 'dashboard.topmost', overwrite: [{
        settings: { modeltype: 'multiprojektmanagement' },
        title: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.MULTIPROJEKTMANAGEMENT'
      }], label: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.MULTIPROJEKTMANAGEMENT', description: 'DASHBOARD.MODELTYPE.MULTIPROJEKTMANAGEMENT.DESCRIPTION', dropZones: ['dashboard', 'sidebar', 'unused'], tags: ['topmost'] },
      'i14': { gid: 'i14', child: true, hierarchy: true, id: 'dashboard.topmost', overwrite: [{
        settings: { modeltype: 'riskmanagement' },
        title: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.RISKMANAGEMENT'
      }], label: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.RISKMANAGEMENT', description: 'DASHBOARD.MODELTYPE.RISKMANAGEMENT.DESCRIPTION', dropZones: ['dashboard', 'sidebar', 'unused'], tags: ['topmost'] },
      'i15': { gid: 'i15', child: true, hierarchy: true, id: 'dashboard.topmost', overwrite: [{
        settings: { modeltype: 'agilemanagement' },
        title: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.AGILEMANAGEMENT'
      }], label: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.AGILEMANAGEMENT', description: 'DASHBOARD.MODELTYPE.AGILEMANAGEMENT.DESCRIPTION', dropZones: ['dashboard', 'sidebar', 'unused'], tags: ['topmost'] },
      'i16': { gid: 'i16', child: true, hierarchy: true, id: 'dashboard.topmost', overwrite: [{
        settings: { modeltype: 'transformationmanagement' },
        title: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.TRANSFORMATIONMANAGEMENT'
      }], label: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.TRANSFORMATIONMANAGEMENT', description: 'DASHBOARD.MODELTYPE.TRANSFORMATIONMANAGEMENT.DESCRIPTION', dropZones: ['dashboard', 'sidebar', 'unused'], tags: ['topmost'] },
      'i17': { gid: 'i17', child: true, hierarchy: true, id: 'dashboard.topmost', overwrite: [{
        settings: { modeltype: 'requirementsmanagement' },
        title: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.REQUIREMENTSMANAGEMENT'
      }], label: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.REQUIREMENTSMANAGEMENT', description: 'DASHBOARD.MODELTYPE.REQUIREMENTSMANAGEMENT.DESCRIPTION', dropZones: ['dashboard', 'sidebar', 'unused'], tags: ['topmost'] },
      'i18': { gid: 'i18', child: true, hierarchy: true, id: 'dashboard.topmost', overwrite: [{
        settings: { modeltype: 'personalmanagement' },
        title: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.PERSONALMANAGEMENT'
      }], label: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.PERSONALMANAGEMENT', description: 'DASHBOARD.MODELTYPE.PERSONALMANAGEMENT.DESCRIPTION', dropZones: ['dashboard', 'sidebar', 'unused'], tags: ['topmost'] },
      'i19': { gid: 'i19', child: true, hierarchy: true, id: 'dashboard.topmost', overwrite: [{
        settings: { modeltype: 'projectmanagement' },
        title: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.PROJECTMANAGEMENT'
      }], label: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.PROJECTMANAGEMENT', description: 'DASHBOARD.MODELTYPE.PROJECTMANAGEMENT.DESCRIPTION', dropZones: ['dashboard', 'sidebar', 'unused'], tags: ['topmost'] },
      'i20': { gid: 'i20', child: true, hierarchy: true, id: 'dashboard.topmost', overwrite: [{
        settings: { modeltype: 'todo' },
        title: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.TODO'
      }], label: 'TEMPLATE.DATASHEET.FIELDS.MODELTYPE.TODO', description: 'DASHBOARD.MODELTYPE.TODO.DESCRIPTION', dropZones: ['dashboard', 'sidebar', 'unused'], tags: ['topmost'] },
      'i22': { gid: 'i22', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
        settings: { report: 'cawecocompliance' },
        title: 'REPORTS.CAWECOCOMPLIANCE.TITLE'
        }], label: 'REPORTS.CAWECOCOMPLIANCE.TITLE', description: 'DASHBOARD.CAWECOCOMPLIANCE.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i23': { gid: 'i23', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
        settings: { report: 'estimation' },
        title: 'REPORTS.ESTIMATION.TITLE'
        }], label: 'REPORTS.ESTIMATION.TITLE', description: 'DASHBOARD.ESTIMATION.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i24': { gid: 'i24', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
        settings: { report: 'structured' },
        title: 'REPORTS.STRUCTURED.TITLE'
      }], label: 'REPORTS.STRUCTURED.TITLE', description: 'DASHBOARD.STRUCTURED.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i25': { gid: 'i25', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
        settings: { report: 'milestone' },
        title: 'REPORTS.MILESTONE.TITLE'
      }], label: 'REPORTS.MILESTONE.TITLE', description: 'DASHBOARD.MILESTONE.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i26': { gid: 'i26', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
        settings: { report: 'teamstatus' },
        title: 'REPORTS.TEAMSTATUS.TITLE'
      }], label: 'REPORTS.TEAMSTATUS.TITLE', description: 'DASHBOARD.TEAMSTATUS.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i27': { gid: 'i27', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
        settings: { report: 'objective' },
        title: 'REPORTS.OBJECTIVE.TITLE'
      }], label: 'REPORTS.OBJECTIVE.TITLE', description: 'DASHBOARD.OBJECTIVE.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i28': { gid: 'i28', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
        settings: { report: 'heatmap' },
        title: 'REPORTS.HEATMAP.TITLE'
      }], label: 'REPORTS.HEATMAP.TITLE', description: 'DASHBOARD.HEATMAP.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i29': { gid: 'i29', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
        settings: { report: 'clevelheatmap' },
        title: 'REPORTS.CLEVELHEATMAP.TITLE'
      }], label: 'REPORTS.CLEVELHEATMAP.TITLE', description: 'DASHBOARD.CLEVELHEATMAP.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i30': { gid: 'i30', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
        settings: { report: 'kpis' },
        title: 'REPORTS.KPIS.TITLE'
      }], label: 'REPORTS.KPIS.TITLE', description: 'DASHBOARD.KPIS.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i31': { gid: 'i31', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
        settings: { report: 'roi' },
        title: 'REPORTS.ROI.TITLE'
      }], label: 'REPORTS.ROI.TITLE', description: 'DASHBOARD.ROI.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i32': { gid: 'i32', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
        settings: { report: 'modelstatus' },
        title: 'REPORTS.MODELSTATUS.TITLE'
      }], label: 'REPORTS.MODELSTATUS.TITLE', description: 'DASHBOARD.MODELSTATUS.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i33': { gid: 'i33', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
        settings: { report: 'projectoverview' },
        title: 'REPORTS.PROJECTOVERVIEW.TITLE'
      }], label: 'REPORTS.PROJECTOVERVIEW.TITLE', description: 'DASHBOARD.PROJECTOVERVIEW.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i34': { gid: 'i34', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
        settings: { report: 'strategicfitness' },
        title: 'REPORTS.STRATEGICFITNESS.TITLE'
      }], label: 'REPORTS.STRATEGICFITNESS.TITLE', description: 'DASHBOARD.STRATEGICFITNESS.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i35': { gid: 'i35', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
        settings: { report: 'compliancestatus' },
        title: 'REPORTS.COMPLIANCESTATUS.TITLE'
      }], label: 'REPORTS.COMPLIANCESTATUS.TITLE', description: 'DASHBOARD.COMPLIANCESTATUS.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i37': { gid: 'i37', child: true, hierarchy: true, id: 'dashboard.layouts', overwrite: [{
        settings: { layout: 'time' },
        title: 'DASHBOARD.LAYOUTS.TIME.TITLE'
      }], label: 'DASHBOARD.LAYOUTS.TIME.TITLE', description: 'DASHBOARD.LAYOUTS.TIME.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['layouts'] },
      'i38': { gid: 'i38', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
        settings: { report: 'ilg' },
        title: 'REPORTS.ILG.TITLE'
      }], label: 'REPORTS.ILG.TITLE', description: 'DASHBOARD.ILG.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i44': { gid: 'i44', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
          settings: { report: 'pomoverview' },
          title: 'REPORTS.POMOVERVIEW.TITLE'
        }], label: 'REPORTS.POMOVERVIEW.TITLE', description: 'DASHBOARD.POMOVERVIEW.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i45': { gid: 'i45', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
          settings: { report: 'stack' },
          title: 'REPORTS.STACK.TITLE'
        }], label: 'REPORTS.STACK.TITLE', description: 'DASHBOARD.STACK.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i47': { gid: 'i47', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
          settings: { report: 'strategyoverview' },
          title: 'REPORTS.STRATEGYOVERVIEW.TITLE'
        }], label: 'REPORTS.STRATEGYOVERVIEW.TITLE', description: 'DASHBOARD.STRATEGYOVERVIEW.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i50': { gid: 'i50', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
          settings: { report: 'changeit' },
          title: 'REPORTS.CHANGEIT.TITLE'
        }], label: 'REPORTS.CHANGEIT.TITLE', description: 'DASHBOARD.CHANGEIT.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i51': { gid: 'i51', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
          settings: { report: 'rollout' },
          title: 'REPORTS.ROLLOUT.TITLE'
        }], label: 'REPORTS.ROLLOUT.TITLE', description: 'DASHBOARD.ROLLOUT.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i52': { gid: 'i52', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
          settings: { report: 'factories' },
          title: 'REPORTS.FACTORIES.TITLE'
        }], label: 'REPORTS.FACTORIES.TITLE', description: 'DASHBOARD.FACTORIES.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i54': { gid: 'i54', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
          settings: { report: 'objectivemodel' },
          title: 'REPORTS.OBJECTIVEMODEL.TITLE'
        }], label: 'REPORTS.OBJECTIVEMODEL.TITLE', description: 'DASHBOARD.OBJECTIVEMODEL.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i55': { gid: 'i55', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
          settings: { report: 'objectivebusinessarea' },
          title: 'REPORTS.OBJECTIVEBUSINESSAREA.TITLE'
        }], label: 'REPORTS.OBJECTIVEBUSINESSAREA.TITLE', description: 'DASHBOARD.OBJECTIVEBUSINESSAREA.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i56': { gid: 'i56', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
          settings: { report: 'objectivebusinessareameasures' },
          title: 'REPORTS.OBJECTIVEBUSINESSAREAMEASURES.TITLE'
        }], label: 'REPORTS.OBJECTIVEBUSINESSAREAMEASURES.TITLE', description: 'DASHBOARD.OBJECTIVEBUSINESSAREAMEASURES.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i57': { gid: 'i57', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
          settings: { report: 'status' },
          title: 'REPORTS.STATUS.TITLE'
        }], label: 'REPORTS.STATUS.TITLE', description: 'DASHBOARD.STATUS.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i58': { gid: 'i58', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
          settings: { report: 'initiativeupdate' },
          title: 'REPORTS.INITIATIVEUPDATE.TITLE'
        }], label: 'REPORTS.INITIATIVEUPDATE.TITLE', description: 'DASHBOARD.INITIATIVEUPDATE.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i59': { gid: 'i59', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
          settings: { report: 'initiativeinventory' },
          title: 'REPORTS.INITIATIVEINVENTORY.TITLE'
        }], label: 'REPORTS.INITIATIVEINVENTORY.TITLE', description: 'DASHBOARD.INITIATIVEINVENTORY.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i60': { gid: 'i60', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
          settings: { report: 'effectcontrolling' },
          title: 'REPORTS.EFFECTCONTROLLING.TITLE'
        }], label: 'REPORTS.EFFECTCONTROLLING.TITLE', description: 'DASHBOARD.EFFECTCONTROLLING.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i61': { gid: 'i61', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
          settings: { report: 'kpi' },
          title: 'REPORTS.KPI.TITLE'
        }], label: 'REPORTS.KPI.TITLE', description: 'DASHBOARD.KPI.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i62': { gid: 'i62', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
          settings: { report: 'focusmaster' },
          preventLoad: true,
          title: 'REPORTS.FOCUSMASTER.TITLE'
        }], label: 'REPORTS.FOCUSMASTER.TITLE', description: 'DASHBOARD.FOCUSMASTER.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i63': { gid: 'i63', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
          settings: { report: 'focuspmo' },
          title: 'REPORTS.FOCUSPMO.TITLE'
        }], label: 'REPORTS.FOCUSPMO.TITLE', description: 'DASHBOARD.FOCUSPMO.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i66': { gid: 'i66', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
          settings: { report: 'overview' },
          title: 'REPORTS.OVERVIEW.TITLE'
        }], label: 'REPORTS.OVERVIEW.TITLE', description: 'DASHBOARD.OVERVIEW.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i70': { gid: 'i70', child: true, hierarchy: true, id: 'dashboard.reports', overwrite: [{
          settings: { report: 'objectivemodelmeasures' },
          title: 'REPORTS.OBJECTIVEMODELMEASURES.TITLE'
        }], label: 'REPORTS.OBJECTIVEMODELMEASURES.TITLE', description: 'DASHBOARD.OBJECTIVEMODELMEASURES.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['reports'] },
      'i77': { gid: 'i77', child: true, id: 'dashboard.matrix', overwrite: [{
          settings: { matrix: 'riskassessment' },
          title: 'DASHBOARD.MATRIX.RISKASSESSMENT.TITLE'
        }], label: 'DASHBOARD.MATRIX.RISKASSESSMENT.TITLE', description: 'DASHBOARD.MATRIX.RISKASSESSMENT.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['matrix'] },
      'i81': { gid: 'i81', child: true, id: 'dashboard.datamining', overwrite: [{
          settings: { workflow: 'attach' },
          title: 'DASHBOARD.DATAMINING.ATTACH.TITLE'
        }], label: 'DASHBOARD.DATAMINING.ATTACH.TITLE', description: 'DASHBOARD.DATAMINING.ATTACH.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['datamining'] },
      'i82': { gid: 'i82', child: true, id: 'dashboard.datamining', overwrite: [{
          settings: { workflow: 'trends' },
          title: 'DASHBOARD.DATAMINING.TRENDS.TITLE'
        }], label: 'DASHBOARD.DATAMINING.TRENDS.TITLE', description: 'DASHBOARD.DATAMINING.TRENDS.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['datamining'] },
      'i83': { gid: 'i83', child: true, id: 'dashboard.matrix', overwrite: [{
          settings: { matrix: 'grid' },
          title: 'DASHBOARD.MATRIX.GRID.TITLE'
        }], label: 'DASHBOARD.MATRIX.GRID.TITLE', description: 'DASHBOARD.MATRIX.GRID.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['matrix'] },
      'i85': { gid: 'i85', child: true, id: 'dashboard.matrix', overwrite: [{
          settings: { matrix: 'trends' },
          title: 'DASHBOARD.MATRIX.TRENDS.TITLE'
        }], label: 'DASHBOARD.MATRIX.TRENDS.TITLE', description: 'DASHBOARD.MATRIX.TRENDS.DESCRIPTION', dropZones: ['dashboard', 'unused'], tags: ['matrix'] },
    }
  }
};
