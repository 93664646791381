export const TemplateFieldsHumanResource = {
  tabs: [
    {
      entry: { key: 'header1', label: '' },
      children: [
        { entry: { key: 'first_name', type: 'text', controlType: 'textbox', label: 'HUMANRESOURCE.DATASHEET.FIELDS.FIRSTNAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.FIRSTNAME' }},
        { entry: { key: 'last_name', type: 'text', controlType: 'textbox', label: 'HUMANRESOURCE.DATASHEET.FIELDS.LASTNAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.LASTNAME' }},
        { entry: { key: 'email', type: 'text', controlType: 'textbox', label: 'HUMANRESOURCE.DATASHEET.FIELDS.EMAIL' }},
        { entry: { key: 'storypoints', type: 'number', controlType: 'textbox', label: 'HUMANRESOURCE.DATASHEET.FIELDS.STORYPOINTS' }},
        { entry: { key: 'color', type: 'text', controlType: 'color', label: 'HUMANRESOURCE.DATASHEET.FIELDS.COLOR' }},
        { entry: { key: 'can_login', controlType: 'checkbox', label: 'HUMANRESOURCE.DATASHEET.FIELDS.ALLOWLOGIN', visibleFn: 'emailIsNotEmpty' }}
      ]
    }
  ]
};
