import { IPayload } from '../shared/common.models';
import { Action } from '../shared/common.action';
import { IGroup } from '../connector/models';

export class GroupAction {
  static key = 'Group';

  static LOAD = `[${GroupAction.key}] Load`;
  static LOAD_SUCCESS = `[${GroupAction.key}] Load success`;
  static LOAD_FAIL = `[${GroupAction.key}] Load fail`;

  static CREATE = `[${GroupAction.key}] Create`;
  static CREATE_SUCCESS = `[${GroupAction.key}] Create success`;
  static CREATE_FAIL = `[${GroupAction.key}] Create fail`;

  static UPDATE = `[${GroupAction.key}] Update`;
  static UPDATE_SUCCESS = `[${GroupAction.key}] Update success`;
  static UPDATE_FAIL = `[${GroupAction.key}] Update fail`;

  static REMOVE = `[${GroupAction.key}] Remove`;
  static REMOVE_SUCCESS = `[${GroupAction.key}] Remove success`;
  static REMOVE_FAIL = `[${GroupAction.key}] Remove fail`;

  static ASSIGN = `[${GroupAction.key}] Assign`;
  static ASSIGN_SUCCESS = `[${GroupAction.key}] Assign success`;
  static ASSIGN_FAIL = `[${GroupAction.key}] Assign fail`;

  static UNASSIGN = `[${GroupAction.key}] Unassign`;
  static UNASSIGN_SUCCESS = `[${GroupAction.key}] Unassign success`;
  static UNASSIGN_FAIL = `[${GroupAction.key}] Unassign fail`;

  public load(instanceId: string): Action {
    return {
      type: GroupAction.LOAD,
      payload: {
        instanceId: instanceId
      }
    };
  }

  public loadSuccess(data: IGroup[]): Action {
    return {
      type: GroupAction.LOAD_SUCCESS,
      payload: data
    };
  }

  public loadFail(error: any): Action {
    return {
      type: GroupAction.LOAD_FAIL,
      payload: error
    };
  }

  public create(instanceId: string, data: IPayload | IPayload[]): Action {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: GroupAction.CREATE,
      payload: {
        instanceId: instanceId,
        data: data
      }
    };
  }

  public createSuccess(payload: IPayload, response: IGroup): Action {
    return {
      type: GroupAction.CREATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public createFail(payload: IPayload, error: any): Action {
    return {
      type: GroupAction.CREATE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public update(data: IPayload | IPayload[]): Action {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: GroupAction.UPDATE,
      payload: data
    };
  }

  public updateSuccess(payload: IPayload, response: IGroup): Action {
    return {
      type: GroupAction.UPDATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public updateFail(payload: IPayload, error: any): Action {
    return {
      type: GroupAction.UPDATE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public remove(ids: string | string[]): Action {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }
    return {
      type: GroupAction.REMOVE,
      payload: {
        data: ids
      }
    };
  }

  public removeSuccess(payload: IPayload, response: IGroup): Action {
    return {
      type: GroupAction.REMOVE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public removeFail(payload: IPayload, error: any): Action {
    return {
      type: GroupAction.REMOVE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public autorefreshUpdate(data: any): Action {
    return {
      type: GroupAction.UPDATE_SUCCESS,
      payload: data
    };
  }

  public autorefreshCreate(data: any): Action {
    return {
      type: GroupAction.CREATE_SUCCESS,
      payload: data
    };
  }

  public autorefreshRemove(data: any): Action {
    return {
      type: GroupAction.REMOVE_SUCCESS,
      payload: data
    };
  }

  public assign(data: IPayload | IPayload[]): Action {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: GroupAction.ASSIGN,
      payload: data
    };
  }

  public assignSuccess(payload: IPayload, response: IGroup): Action {
    return {
      type: GroupAction.ASSIGN_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public assignFail(payload: IPayload, error: any): Action {
    return {
      type: GroupAction.ASSIGN_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public unassign(data: IPayload | IPayload[]): Action {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: GroupAction.UNASSIGN,
      payload: data
    };
  }

  public unassignSuccess(payload: IPayload, response: IGroup): Action {
    return {
      type: GroupAction.UNASSIGN_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public unassignFail(payload: IPayload, error: any): Action {
    return {
      type: GroupAction.UNASSIGN_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

}
