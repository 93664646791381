export const TemplateFieldsReportsTeamStatus = [
  {
    type: 'table',
    settings: {
      /* Show the footer */
      showFooter: true,
      /* Group the elements by levels */
      groupByLevels: true,
      /* Break the collapsing on level border */
      breakCollapseOnLevel: true,
      /* Exports */
      exports: ['excel', 'pdf'], //  excel, pdf
      /* The header background color */
      headerBackgroundColor: '#9ac93e',
      /* The header text color */
      headerTextColor: '#ffffff',
      /* Allow page to break vertically */
      verticalPageBreak: true,
    },
    configuration: {
      fields: [
        { entry: { key: 'structure', label: 'TABLE.STRUCTURE.TITLE', displayLabel: false, controlType: 'structure', shape: 'ellipsis-v', additional: { addchild: true, addsibling: true, edit: true, delete: true, create: true, unlink: true, changeparent: true, link: true } }},
        { entry: { key: 'wizard', label: '', controlType: 'wizard', source: 'email-todo', icon: 'envelope', editable: true, fixedWidth: 30 }},
        { entry: { key: 'sublevel', label: 'TABLE.SUBLEVEL', displayLabel: false, controlType: 'sublevel' }},
        { entry: { key: 'general', label: 'REPORTS.TEAMSTATUS.GROUPS.GENERAL', controlType: 'group'}, children: [
            { entry: { key: 'reference', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.REFERENCE', fixedWidth: 75, sortable: true }},
            { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.NAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME', fixedWidth: 60, sortable: true }},
            { entry: { key: 'picture', controlType: 'picture', label: 'REPORTS.TEAMSTATUS.PICTURE', source: 'humanresources', calculated: true, editable: false, fixedWidth: 72 }},
            { entry: { key: 'responsibleId', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.RESPONSIBLEID', hasNull: true, filter: true, source: 'humanresources', fixedWidth: 106, sortable: true }},
            { entry: { key: 'status', controlType: 'dropdown', label: 'STATUS.TITLE', fixedWidth: 81, sortable: true, filter: true, options: [
                  { key: 0, value: 'STATUS.PENDING', color: '#999999' },
                  { key: 100, value: 'STATUS.INDEFINE', color: '#000000' },
                  { key: 110, value: 'STATUS.INDEVELOP', color: '#1099D6' },
                  { key: 120, value: 'STATUS.INVERIFY', color: '#AA008F' },
                  { key: 130, value: 'STATUS.INACCEPT', color: '#EDB922' },
                  { key: 200, value: 'STATUS.COMPLETED', color: '#9ac93e' }
                ]}},
            { entry: { key: 'targetDate', type: 'datetime', controlType: 'date', label: 'REPORTS.TEAMSTATUS.TARGETDATE', sortable: true, fixedWidth: 96 }},
            { entry: { key: 'description', controlType: 'text', label: 'NODE.DATASHEET.FIELDS.DESCRIPTION', fixedWidth: 300 }}
          ]},
        { entry: { key: 'space', label: '', controlType: 'space', editable: false, fixedWidth: 10 }},
        { entry: { key: '', label: 'REPORTS.TEAMSTATUS.GROUPS.RELATIVEBENEFIT', controlType: 'group'}, children: [
            { entry: { key: 'stategicClassification', controlType: 'textbox', label: 'REPORTS.TEAMSTATUS.STRATEGICCLASSIFICATION', editable: false, calculated: true, sortable: true, fixedWidth: 125 }},
            { entry: { key: 'challenge', controlType: 'textbox', label: 'REPORTS.TEAMSTATUS.CHALLENGE', editable: false, calculated: true, sortable: true, fixedWidth: 100 }},
            { entry: { key: 'challenge2ndlayer', controlType: 'textbox', label: 'REPORTS.TEAMSTATUS.CHALLENGE2NDLAYER', editable: false, calculated: true, sortable: true, fixedWidth: 125 }},
          ]}
      ]
    }
  }
];
