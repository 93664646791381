export const TemplateFieldsSubsetsCreate = {
  tabs: [
    {
      entry: { key: 'header1', label: 'SUBSET.DATASHEET.HEADERS.HEADER1' },
      children: [
        { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'SUBSET.DATASHEET.FIELDS.NAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME' }}
      ]
    }
  ]
};
