export const TemplateFieldsRelationships = {
  tabs: [
    {
      entry: {key: 'header1', label: 'NODE.DATASHEET.HEADERS.HEADER1'},
      children: [
        { entry: { key: 'condition', controlType: 'dropdown', label: 'RELATIONSHIP.DATASHEET.FIELDS.CONDITION.TITLE', options: [
          { key: 0, value: '' },
          { key: 1, value: 'RELATIONSHIP.DATASHEET.FIELDS.CONDITION.YES' },
          { key: 2, value: 'RELATIONSHIP.DATASHEET.FIELDS.CONDITION.NO' }
        ]}}
      ]
    }
  ]
};
