import {IHierarchy, IHierarchyAttributes} from '../connector';
import { Record, Set, Map } from 'immutable';
import { RecordFactory } from '../shared/record-factory';
import { DataModelState, RequestDiffRecord } from '../shared';
import { HumanResource } from '../humanresources';


const hierarchyStateRecord = Record({
  ids: Set<string>(),
  entities: Map<string, Hierarchy>(),
  diff: new RequestDiffRecord(),
  isBusy: 0
});

export class HierarchyState extends hierarchyStateRecord implements DataModelState {
  ids: Set<string>;
  entities: Map<string, Hierarchy>;
  diff: RequestDiffRecord;
  relationships: undefined;
  isBusy: number;
}

const hierarchyRecord = Record(<IHierarchyAttributes>{
  id: undefined,
  name: undefined,
  type: undefined,
  modeltype: undefined,
  modelstype: 0,
  instanceType: 0,
  templateId: undefined,
  children: undefined,
  hr: undefined,
  humanResources: [],
  permissions: undefined
});

export class Hierarchy extends hierarchyRecord implements IHierarchyAttributes {
  id: string;
  name: string;
  type: string;
  modeltype: string;
  modelstype: number;
  instanceType: number;
  templateId: string;
  children: IHierarchyAttributes | null;
  hr: number[];
  humanResources: HumanResource[];
  parent: number;
  permissions: string | any;

  static fromJSONAPI(hierarchy: IHierarchy) {
    return RecordFactory.entity.fromJSONAPI<Hierarchy>(Hierarchy, hierarchy);
  }
}
