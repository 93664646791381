import { IAction, ISuccessAction } from '../shared/common.interfaces';
import { Action } from '../shared/common.action';
import { MiningData } from './mining.models';

export class MiningAction {
  static key = 'Mining';

  static ATTACH = `[${MiningAction.key}] Attach`;
  static ATTACH_SUCCESS = `[${MiningAction.key}] Attach success`;
  static ATTACH_FAIL = `[${MiningAction.key}] Attach fail`;

  static TRENDS = `[${MiningAction.key}] Trends`;
  static TRENDS_SUCCESS = `[${MiningAction.key}] Trends success`;
  static TRENDS_FAIL = `[${MiningAction.key}] Trends fail`;

  public attach(miningData: MiningData): IAction {
    return {
      type: MiningAction.ATTACH,
      payload: {
        data: miningData
      }
    };
  }

  public attachSuccess(): ISuccessAction {
    return {
      type: MiningAction.ATTACH_SUCCESS,
      payload: {
        request: undefined,
        response: undefined
      }
    };
  }

  public attachFail(): Action {
    return {
      type: MiningAction.ATTACH_FAIL,
      payload: {
        request: undefined,
        response: undefined
      }
    };
  }

  public trends(miningData: MiningData): IAction {
    return {
      type: MiningAction.TRENDS,
      payload: {
        data: miningData
      }
    };
  }

  public trendsSuccess(): ISuccessAction {
    return {
      type: MiningAction.TRENDS_SUCCESS,
      payload: {
        request: undefined,
        response: undefined
      }
    };
  }

  public trendsFail(): Action {
    return {
      type: MiningAction.TRENDS_FAIL,
      payload: {
        request: undefined,
        response: undefined
      }
    };
  }

}
