import { IActivity } from '../connector';

import { IAction, IFailAction, ISuccessAction } from '../shared/common.interfaces';
import { IPayload } from '../shared/common.models';

export class ActivityAction {
  static key = 'Activity';

  static LOAD_SUCCESS = `[${ActivityAction.key}] Load success`;
  static LOAD_FAIL = `[${ActivityAction.key}] Load fail`;

  static CREATE_ON_INSTANCE = `[${ActivityAction.key}] Create on instance`;
  static CREATE_ON_INSTANCE_GO = `[${ActivityAction.key}] Create on instance Go`;
  static CREATE_ON_NODEDATA = `[${ActivityAction.key}] Create on node data`;
  static CREATE_ON_NODEDATA_GO = `[${ActivityAction.key}] Create on node data Go`;
  static CREATE_SUCCESS = `[${ActivityAction.key}] Create success`;
  static CREATE_FAIL = `[${ActivityAction.key}] Create fail`;

  static UPDATE = `[${ActivityAction.key}] Update`;
  static UPDATE_GO = `[${ActivityAction.key}] Update Go`;
  static UPDATE_SUCCESS = `[${ActivityAction.key}] Update success`;
  static UPDATE_FAIL = `[${ActivityAction.key}] Update fail`;

  static REMOVE = `[${ActivityAction.key}] Remove`;
  static REMOVE_SUCCESS = `[${ActivityAction.key}] Remove success`;
  static REMOVE_FAIL = `[${ActivityAction.key}] Remove fail`;

  public loadSuccess(data: IActivity[]) {
    return {
      type: ActivityAction.LOAD_SUCCESS,
      payload: data
    };
  }

  public loadFail(error: any): IFailAction {
    return {
      type: ActivityAction.LOAD_FAIL,
      payload: error
    };
  }

  public createOnInstance(id: string, data: IPayload | IPayload[]): IAction {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: ActivityAction.CREATE_ON_INSTANCE,
      payload: {
        id: id,
        data: data
      }
    };
  }

  public createOnInstanceGo(id: string, data: IPayload | IPayload[]): IAction {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: ActivityAction.CREATE_ON_INSTANCE_GO,
      payload: {
        id: id,
        data: data
      }
    };
  }

  public createOnNodeData(id: string, data: IPayload | IPayload[]): IAction {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: ActivityAction.CREATE_ON_NODEDATA,
      payload: {
        id: id,
        data: data
      }
    };
  }

  public createOnNodeDataGo(id: string, data: IPayload | IPayload[]): IAction {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: ActivityAction.CREATE_ON_NODEDATA_GO,
      payload: {
        id: id,
        data: data
      }
    };
  }

  public createSuccess(payload: IPayload, response: IActivity[]): ISuccessAction {
    return {
      type: ActivityAction.CREATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public createFail(payload: IPayload, error: any): IFailAction {
    return {
      type: ActivityAction.CREATE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public update(data: IPayload | IPayload[]): IAction {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: ActivityAction.UPDATE,
      payload: {
        data: data
      }
    };
  }

  public updateGo(data: IPayload | IPayload[]): IAction {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: ActivityAction.UPDATE_GO,
      payload: {
        data: data
      }
    };
  }



  public updateSuccess(payload: IPayload, response: IActivity[]): ISuccessAction {
    return {
      type: ActivityAction.UPDATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public updateFail(payload: IPayload, error: any): IFailAction {
    return {
      type: ActivityAction.UPDATE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public remove(ids: string | string[]): IAction {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }
    return {
      type: ActivityAction.REMOVE,
      payload: {
        data: ids
      }
    };
  }

  public removeSuccess(payload: IPayload, response: IActivity[]): ISuccessAction {
    return {
      type: ActivityAction.REMOVE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public removeFail(payload: IPayload, error: any): IFailAction {
    return {
      type: ActivityAction.REMOVE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public autorefreshUpdate(data: any) {
    return {
      type: ActivityAction.UPDATE_SUCCESS,
      payload: data
    };
  }

  public autorefreshCreate(data: any) {
    return {
      type: ActivityAction.CREATE_SUCCESS,
      payload: data
    };
  }

  public autorefreshRemove(data: any) {
    return {
      type: ActivityAction.REMOVE_SUCCESS,
      payload: data
    };
  }
}
