import {UserState, User, UserRelationships} from './user.models';
import { Action } from '../shared/common.action';
import { UserAction } from './user.actions';
import { JSONAPIDeserializerService } from '../shared/jsonapi-deserializer.service';
import { Utils } from '../shared/utils';

export default function reducer(state = new UserState(), action: Action): UserState {

  switch (action.type) {
    case UserAction.LOAD:
      return <UserState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case UserAction.LOAD_SUCCESS: {
      const deserializer = new JSONAPIDeserializerService<User, UserRelationships>(User.fromJSONAPI, UserRelationships.fromJSONAPI);
      const { entities, relationships } = deserializer.deserialize(action.payload.response);

      Utils.currentUser = entities.first();

      return <UserState>Utils.updateState<UserState>(state, entities, relationships)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case UserAction.LOAD_FAIL:
      return <UserState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );

    default:
      return state;
  }

}
