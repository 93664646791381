import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { GoAPI, JSONAPIResponse } from '../connector';
import { SettingsAction } from './settings.actions';
import { Observable } from 'rxjs/Observable';
import { Action } from '../shared/common.action';
import { OAuthAPI } from '../connector/shared/api.oauth.service';

@Injectable()
export class SettingsEffects {

  @Effect() loadSettings$ = this.actions$.pipe(ofType(SettingsAction.LOAD))
    .switchMap(() => this.goApi.settings.get()
      .mergeMap((response: any) => Observable.from([this.settingsAction.loadSuccess(response)]))
    ).catch((error: any) => Observable.of(this.settingsAction.loadFail(error)));

  @Effect() updateSettings$ = this.actions$.pipe(ofType(SettingsAction.UPDATE))
    .filter((action: Action) => !!action.payload)
    .switchMap((action: Action) => this.oauthApi.settings.put(action.payload)
      .mergeMap((response: JSONAPIResponse<any>) => Observable.from([this.settingsAction.updateSuccess(action.payload, response)]))
      .catch((error: any) => Observable.of(this.settingsAction.updateFail(action.payload, error)))
    );

  @Effect() removeSettings$ = this.actions$
    .pipe(ofType(SettingsAction.REMOVE))
    .switchMap((action: Action) => this.oauthApi.settings.delete().mergeMap((response: JSONAPIResponse<any>) => Observable.from([
      this.settingsAction.removeSuccess(action.payload, response)
    ])).catch((error: any) => Observable.of(this.settingsAction.removeFail(action.payload, error)))
    );

  constructor(private oauthApi: OAuthAPI,
    private goApi: GoAPI,
    private actions$: Actions,
    private settingsAction: SettingsAction) {
  }
}
