import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { ColorLabelProviderLegend } from '../colorlabelprovider.interface';
import { TreeNode } from '../../../core/interface/core.interface';

export class ColorLabelProviderServiceImportance extends ColorLabelProviderAbstract {

  private static LOW = '#9ac93e';
  private static MEDIUM = '#EDD515';
  private static HIGH = '#E50000';

  public color(treeNode: TreeNode) {
    switch (treeNode.businessBenefit) {
      case 1:
        return [ColorLabelProviderServiceImportance.LOW];
      case 2:
        return [ColorLabelProviderServiceImportance.MEDIUM];
      case 3:
        return [ColorLabelProviderServiceImportance.HIGH];
      default:
        return super.color();
    }
  }

  public legend() {
    return <ColorLabelProviderLegend[]>([
      { key: 'importance.low', color: ColorLabelProviderServiceImportance.LOW, label: 'LEGEND.IMPORTANCE.LOW', field: 'businessBenefit', value: 1 },
      { key: 'importance.medium', color: ColorLabelProviderServiceImportance.MEDIUM, label: 'LEGEND.IMPORTANCE.MEDIUM', field: 'businessBenefit', value: 2 },
      { key: 'importance.high', color: ColorLabelProviderServiceImportance.HIGH, label: 'LEGEND.IMPORTANCE.HIGH', field: 'businessBenefit', value: 3 }
    ]);
  }

}
