import { Record } from 'immutable';
import * as d3 from 'd3';
import { isNull, isNullOrUndefined, isUndefined } from 'util';
import { WordwrapCache } from './wordwrap.cache';

const wordWrapLineRecord = Record({
  text: '',
  width: 0,
  x: 0,
  y: 0
});

export class WordWrapLine extends wordWrapLineRecord {
  public text: string;
  public width: number;
  public x: number;
  public y: number;
}

export class WordWrapper {

  protected _width = 100;
  protected _maxLines = 0;
  protected _splitter = ' ';
  protected _truncater = '...';
  protected _wordBreak = true;
  protected _divider = '-';
  protected _fontSize = undefined;
  protected _fillup = false;

  private splits = [];
  private result = Array<WordWrapLine>();
  private dummy;

  public width(width: number): WordWrapper {
    this._width = width;
    return this;
  }

  public fontSize(fontSize: number): WordWrapper {
    this._fontSize = fontSize;
    return this;
  }

  public maxLines(maxLines: number): WordWrapper {
    this._maxLines = maxLines;
    return this;
  }

  public splitter(splitter: string): WordWrapper {
    this._splitter = splitter;
    return this;
  }

  public truncater(truncater: string): WordWrapper {
    this._truncater = truncater;
    return this;
  }

  public wordBreak(wordBreak: boolean): WordWrapper {
    this._wordBreak = wordBreak;
    return this;
  }

  public divider(divider: string): WordWrapper {
    this._divider = divider;
    return this;
  }

  public fillUp(fillUp: boolean): WordWrapper {
    this._fillup = fillUp;
    return this;
  }

  public wrap(text: string) {
    if (isNullOrUndefined(text) || text === '') {
      return [new WordWrapLine()];
    }
    const fromDictonary = WordwrapCache.getSplit(text);
    if (isNullOrUndefined(fromDictonary)) {
      this.result = [];
      this.dummy = d3.select('body').append('div').attr('id', 'wordwrapdummy');
      if (!isNullOrUndefined(this._fontSize)) {
        this.dummy.style('font-size', this._fontSize + 'px');
      }
      this.splits = text.split(this._splitter).reverse();
      this.lines('');
      d3.select('#wordwrapdummy').remove();
      WordwrapCache.setSplit(text, this.result);
    } else {
      this.result = fromDictonary;
    }
    return this.result;
  }

  public measure(text: string) {
    this.dummy = d3.select('body').append('div').attr('id', 'wordwrapdummy');
    if (!isNullOrUndefined(this._fontSize)) {
      this.dummy.style('font-size', this._fontSize + 'px');
    }
    const result = this.getWidth(text);
    d3.select('#wordwrapdummy').remove();
    return result;
  }

  private testrun(text) {
    return this.getWidth(text) < this._width;
  }

  private getWidth(text) {
    const span = this.dummy.append('span');
    const width = WordwrapCache.getWidth(text, span.text(text).node());
    span.remove();
    return width;
  }

  private striptofit(line) {
    const t = line + this._truncater;
    if (this.testrun(t)) {
      return t;
    } else {
      return this.striptofit(line.slice(0, -1));
    }
  }

  private lines(line) {
    let l = line;
    const splitsLength = this.splits.length;
    let word = this.splits[splitsLength - 1];
    if (isUndefined(word)) {
      this.result.push(new WordWrapLine({ text: line, width: this.getWidth(line) }));
      return;
    }
    const brokenWord = word instanceof Array;

    if (brokenWord) {
      word = word[word.length - 1];
    }
    if (brokenWord || this.testrun(word)) {
      line += ((line !== '' && !brokenWord ? this._splitter : '') + word);
      if (this.testrun(line + (brokenWord ? '-' : ''))) {
        if (brokenWord) {
          this.splits[splitsLength - 1].pop();
          if (this.splits[splitsLength - 1].length === 0) {
            this.splits.pop();
          }
        } else {
          this.splits.pop();
        }
        this.lines(line);
      } else {
        if (!brokenWord && this._fillup) {
          l = this.fillingUp(l);
        }
        if (this._maxLines !== 0 && this.result.length === this._maxLines - 1) {
          const strtof = this.striptofit(l);
          this.result.push(new WordWrapLine({ text: strtof, width: this.getWidth(strtof) }));
        } else {
          if (brokenWord) {
            l = l + '-';
          }
          this.result.push(new WordWrapLine({ text: l, width: this.getWidth(l) }));
          if (splitsLength > 0) {
            this.lines('');
          }
        }
      }
    } else {
      this.splits.pop();
      this.splits.push(word.split('').reverse());
      this.lines(line + ' ');
    }
  }

  private fillingUp(line, word = null, length = 0) {
    if (isNull(word)) {
      word = this.splits.pop();
      if (word.split instanceof Function) {
        word = word.split('').reverse();
      } else {
        word = [];
      }
      length = word.length;
    }
    const l = line + word[word.length - 1] + ((this._maxLines !== 0 && this.result.length === this._maxLines - 1) ? this._truncater : '-');
    if (word.length > 0 && this.testrun(l)) {
      line = line + (length === word.length ? ' ' : '') + word.pop();
      return this.fillingUp(line, word, length);
    } else {
      this.splits.push(word);
      return line + ((length === word.length) || (this._maxLines !== 0 && this.result.length === this._maxLines - 1) ? '' : '-');
    }
  }

}
