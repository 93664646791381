import { FormControl, FormGroup, Validators } from '@angular/forms';
import { isArray, isNull, isNullOrUndefined, isNumber, isObject, isString, isUndefined } from 'util';
import { List, Map, Set } from 'immutable';
import { NaturalSort } from 'angular2-natural-sort';
import { TranslateService } from '@ngx-translate/core';

import { BaseEntry } from '../form/dynamic-form/entries/base-entry';
import { Activity } from '../api/activities';
import { Node } from '../api/nodes';
import { NodeData } from '../api/nodedata';
import { NodeDataRelationships } from '../api/nodedata/nodedata.models';
import { ActivityRelationships } from '../api/activities/activities.models';
import { TreeNode } from '../../working/shared/tree/tree.node';

interface EntryHierarchy<T> {
  entry: BaseEntry<T>;
  children: Array<EntryHierarchy<T>>;
}

declare var jQuery;

export class Utilities {

  public static textWidthCache = Map<string, number>();

  public static getControl(tree: string[], formGroup: FormGroup) {
    let control: any;
    tree.forEach(treeElement => {
      control = isUndefined(control) ? formGroup.controls[treeElement] : control.controls[treeElement];
    });
    return control;
  }

  public static toFormGroup(entries: Array<EntryHierarchy<any>> | Array<any>, element: any) {
    let formGroup = new FormGroup({});
    if (!isNullOrUndefined(entries)) {
      formGroup = Utilities.buildFormRec(entries, formGroup, element);
    }
    return formGroup;
  }

  private static buildFormRec(properties: Array<EntryHierarchy<any>>, formGroup: FormGroup, element: any): FormGroup {
    if (isNullOrUndefined(properties)) {
      return formGroup;
    }
    properties.forEach((property) => {
      const entry = property.entry;
      const children = property.children;
      if (!isNullOrUndefined(children)) {
        const subGroup = Utilities.buildFormRec(children, new FormGroup({}), element);
        formGroup.setControl(entry.key, subGroup);
      } else {
        const validations = [];
        if (!isNullOrUndefined(entry.validations)) {
          entry.validations.forEach(validation => {
            const is_object = isObject(validation);
            const key = is_object ? validation.key : validation;
            const value = is_object ? validation.value : true;
            switch (key) {
              case 'required':
                validations.push(Validators.required);
                break;
              case 'maxLength':
                validations.push(Validators.maxLength(value));
                break;
            }
          });
        }
        formGroup.setControl(entry.key, new FormControl(entry.value || '', validations));
        if (!isNullOrUndefined(element) && (!isNullOrUndefined(element[entry.key]))) {
          const value = element[entry.key];
          if (!isUndefined(value)) {
            const v = {};
            v[entry.key] = value;
            formGroup.patchValue(v);
          }
        } else if (!!(<any> entry).calculated && !!(<any> element).calculated && !!(<any> element).calculated[entry.key]) {
          const v = {};
          v[entry.key] = (<any> element).calculated[entry.key];
          formGroup.patchValue(v);
        } else if (!isNullOrUndefined(element) && !isNullOrUndefined(entry.source)) {
          const source = this.getValueByPath(element, entry.source);
          if (!isNullOrUndefined(source)) {
            const value = source[entry.key];
            if (!isUndefined(value)) {
              const v = {};
              v[entry.key] = value;
              formGroup.patchValue(v);
            }
          }
        }
      }
    });
    return formGroup;
  }

  public static setDefaultValues(data: any) {
    return data.map((value: any, key: string) => {
      switch (key) {
        case 'businesscalculation':
        case 'actualDate':
        case 'targetDate':
        case 'startActual':
        case 'endActual':
        case 'responsibleId':
        case 'humanresource':
          return value === '' ? null : value;
      }
      return value;
    });
  }

  public static getValueByPath(object: any, path: string) {
    if (isObject(path)) {
      return path;
    }
    const split = path.split('.');
    if (isNullOrUndefined(object)) {
      return undefined;
    }
    if (split.length === 1) {
      return object[split[0]];
    } else {
      return this.getValueByPath(object[split[0]], split.splice(1).join('.'));
    }
  }

  public static getDelta(newElement: any, oldElement: any, flatten = true): Map<string, any> {
    newElement = (newElement instanceof Map) ? newElement : Map<string, any>(newElement);
    oldElement = (oldElement instanceof Map) ? oldElement : Map<string, any>(oldElement);
    let delta = Map<string, any>();
    newElement.forEach((v, k) => {
      if ((!oldElement.has(k) || oldElement.get(k) !== v) && !isUndefined(v)) {
        if (k === 'relationships' && flatten) {
          delta = delta.merge(Utilities.getDelta(newElement.get(k), oldElement.get(k), false));
        } else if (v === '' && oldElement.get(k) === null) {
        } else {
          delta = delta.set(k, v);
        }
      }
    });
    return delta;
  }

  public static getDeltaFromFormGroup(element: Node | NodeData | Activity, formGroup: FormGroup) {
    const type = Utilities.getType(element);
    const original = type === 'node' ? Node : (type === 'nodedata' ? NodeData : Activity);
    const originalRelationship = type === 'node' ? NodeDataRelationships : (type === 'nodedata' ? NodeDataRelationships : ActivityRelationships);
    const flatFormGroup = this.flatFormGroup(Map<string, any>(formGroup.value));
    return Utilities.getDelta(Utilities.mapFormGroup(original, originalRelationship, formGroup), element).filter((d, key) => flatFormGroup.has(key));
  }

  public static getDeltaFromAnyFormGroup(element: any, formGroup: FormGroup) {
    return Utilities.getDelta(this.flatFormGroup(Map<string, any>(formGroup.value)), element);
  }

  public static getType(element: Node | NodeData | Activity) {
    let type = '';
    if (element instanceof Node) {
      type = 'node';
    } else if (element instanceof NodeData) {
      type = 'nodedata';
    } else if (element instanceof Activity) {
      type = 'activity';
    }
    return type;
  }

  public static flatFormGroup(values: Map<string, any>, result = Map<string, any>()): Map<string, any> {
    values.forEach((entry, key) => {
      if (key.indexOf('header') > -1 || key.indexOf('group') > -1 || key.indexOf('unused') > -1) {
        if (entry !== '') {
          result = this.flatFormGroup(Map<string, any>(entry), result);
        }
      } else {
        result = result.set(key, entry);
      }
    });
    return result;
  }

  public static mapFormGroup(element: any, relationships: any, formGroup: FormGroup) {
    return this.flattenFormGroup(new element(), new relationships(), Map<string, any>(formGroup.value));
  }

  public static flattenFormGroup(element: any, relationships: any, values: Map<string, any>) {
    values.forEach((entry, key) => {
      if (key.indexOf('unused') > -1) {
      } else if (key.indexOf('header') > -1 || key.indexOf('group') > -1) {
        element = this.flattenFormGroup(element, relationships, Map<string, any>(entry));
        relationships = element.relationships;
      } else if (element.has(key)) {
        element = element.set(key, entry);
      } else if (!isNullOrUndefined(relationships) && relationships.has(key)) {
        relationships = relationships.set(key, entry);
      }
    });
    return element.set('relationships', relationships);
  }

  public static flatConfiguration(fields: any[], replaceHashtag = true, result = Map<string, any>()): Map<string, any> {
    fields.forEach(field => {
      if (field.entry.key.indexOf('unused') > -1) {
      } else if (!isNullOrUndefined(field.children)) {
        result = this.flatConfiguration(field.children, replaceHashtag, result);
      } else {
        result = result.set(replaceHashtag ? field.entry.key.replace(/#\S*/, '') : field.entry.key, field.entry);
      }
    });
    return result;
  }

  public static findElementInConfig(needle: string, haystack: any[]) {
    let result: any;
    haystack.forEach(element => {
      if (element.entry.key === needle) {
        result = JSON.parse(JSON.stringify(element.entry));
      } else if (isNullOrUndefined(result) && !isNullOrUndefined(element.children)) {
        result = this.findElementInConfig(needle, element.children);
      }
    });
    return result;
  }

  public static removeTags(str: string) {
    const div = document.createElement('div');
    div.innerHTML = str;
    return div.textContent || div.innerText || '';
  }

  public static flatMap(list: List<any>, result = List<any>()): List<any> {
    list.forEach(_ => {
      if (_ instanceof List) {
        result = this.flatMap((<List<any>> _), result);
      } else {
        result = result.push(_);
      }
    });
    return result;
  }

  public static flatSet(set: Set<any>, result = Set<any>()): Set<any> {
    set.forEach(_ => {
      if (_ instanceof Set) {
        result = this.flatSet((<Set<any>> _), result);
      } else {
        result = result.add(_);
      }
    });
    return result;
  }

  public static flatArray(arr: any[], result = []) {
    if (!isArray(arr)) {
      return arr;
    }
    arr.forEach(_ => {
      if (isArray(_)) {
        result = this.flatArray(_, result);
      } else {
        result.push(_);
      }
    });
    return result;
  }

  public static duplicateObject(element: Object) {
    return jQuery.extend(true, {}, element);
  }

  public static calculateTextWidth(htmlElement: any): number {
    const text = jQuery(htmlElement).text();
    if (!Utilities.textWidthCache.has(text)) {
      Utilities.textWidthCache = Utilities.textWidthCache.set(text, htmlElement.getComputedTextLength());
    }
    return Utilities.textWidthCache.get(text);
  }

  public static addClass(className, add): string {
    if (className.indexOf(add) === -1) {
      className += (' ' + add);
    }
    return className;
  }

  public static removeClass(className, remove): string {
    if (className.indexOf(remove) !== -1) {
      const expression = new RegExp('\\s*' + remove + '|' + remove);
      className = className.replace(expression, '');
    }
    return className;
  }

  public static reduce(arr: any[]) {
    if (arr.length === 0) { return null; }
    const comparison = isNumber(arr[0]) ? 'number' : (isString(arr[0]) ? 'string' : null);
    if (isNull(comparison)) { return null; }
    switch (comparison) {
      case 'number':
        let arrSum = 0;
        arr.forEach(_ => arrSum += _);
        return Math.round(arrSum / arr.length);
      case 'string':
        let col = Map<string, number>();
        arr.forEach(_ => {
          if (!isNull(_)) {
            col = col.set(_, col.has(_) ? (col.get(_) + 1) : 1);
          }
        });
        let result = null;
        let max = 0;
        col.forEach((_, k) => { if (_ >= max) { result = k; max = _; } });
        return result;
    }
  }

  public static getComplement(color: string) {
    return '#' + ( parseInt('FFFFFF', 16) - parseInt(color.substr(1), 16) ).toString(16);
  }

  public static firstUpperCase(s: string): string {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  public static padZero(str: string, len?: number) {
    len = len || 2;
    const zeros = [len].join('0');
    return (zeros + str).slice(-len);
  }

  public static rgbToHex(rgb: string): string {
    const regex = /rgb\((\d*)\D*(\d*)\D*(\d*)\)/gi;
    const m = regex.exec(rgb);
    if (!isNullOrUndefined(m)) {
      return '#' + Utilities.singleRgbToHex(m[1]) + Utilities.singleRgbToHex(m[2]) + Utilities.singleRgbToHex(m[3]);
    } else {
      return '#000000';
    }
  }

  public static hexToRgb(hex: string): { r: number, g: number, b: number } {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  public static rgbaToHex(rgba: string): string {
    const rgb = rgba.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i);
    const alpha = parseInt((rgb && rgb[4] || '').trim());
    // tslint:disable-next-line:no-bitwise
    let hex = rgb ? ((<any> rgb[1]) | 1 << 8).toString(16).slice(1) + ((<any> rgb[2]) | 1 << 8).toString(16).slice(1) + ((<any> rgb[3]) | 1 << 8).toString(16).slice(1) : rgba;
    const a = Math.round((!isNaN(alpha) ? alpha : 1) * 100) / 100;
    const hexAlpha = (Math.round(a * 255) + 0x10000).toString(16).substr(-2).toUpperCase();
    hex = hex + hexAlpha;
    return hex;
  }

  public static rgbaToRgb(rgba: string, background = { r: 255, g: 255, b: 255 }) {
    const result = rgba.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i);
    const alpha = parseInt((!!result && result[4] || '').trim());
    return result ? {
      r: ((1 - alpha) * background.r) + (alpha * parseInt(result[1], 16)),
      g: ((1 - alpha) * background.g) + (alpha * parseInt(result[2], 16)),
      b: ((1 - alpha) * background.b) + (alpha * parseInt(result[3], 16))
    } : null;
  }

  public static channelsToRgb(r: number, g: number, b: number, a: number, background = { r: 255, g: 255, b: 255 }) {
    const alpha = a;
    const rr = (1 - alpha) * background.r + alpha * r;
    const rg = (1 - alpha) * background.g + alpha * g;
    const rb = (1 - alpha) * background.b + alpha * b;
    return {
      r: rr > 255 ? 255 : rr,
      g: rg > 255 ? 255 : rg,
      b: rb > 255 ? 255 : rb
    };
  }

  public static singleRgbToHex = function (color) {
    let hex = parseInt(color).toString(16);
    if (hex.length < 2) {
      hex = '0' + hex;
    }
    return hex;
  };

  public static invertColor(hex: string, bw = false) {
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.');
    }
    let r: string | number = parseInt(hex.slice(0, 2), 16);
    let g: string | number = parseInt(hex.slice(2, 4), 16);
    let b: string | number = parseInt(hex.slice(4, 6), 16);
    if (bw) {
      return (r * 0.299 + g * 0.587 + b * 0.114) > 186 ? '#000000' : '#FFFFFF';
    }
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    return '#' + Utilities.padZero(r) + Utilities.padZero(g) + Utilities.padZero(b);
  }

  public static mergeObject(to: any, from: any) {
    if (isNullOrUndefined(from)) {
      return to;
    }
    return Map<string, any>(to).map((value, key) => {
      const t = !isNullOrUndefined(to) ? to[key] : undefined;
      const f = !isNullOrUndefined(from) ? from[key] : undefined;
      return isObject(value) ? this.mergeObject(t, f) : (isUndefined(f) ? value : f);
    }).toJS();
  }

  public static assignMap(to: any, data: Map<string, any>) {
    if (isArray(to)) {
      to.forEach(_to => data = Utilities.assignMap(_to, data));
    } else if (isObject(to)) {
      if (data.has(to.entry.key)) {
        to.entry = data.get(to.entry.key);
        data = data.remove(to.entry.key);
      }
      if (!isNullOrUndefined(to.children)) {
        data = Utilities.assignMap(to.children, data);
      }
    }
    return data;
  }

  public static dataMap(element: any, result = Map<string, any>()) {
    if (isArray(element)) {
      element.forEach(_element => result = Utilities.dataMap(_element, result));
    } else if (isObject(element)) {
      if (!isNullOrUndefined(element.entry.controlType) && element.entry.controlType !== 'group') {
        result = result.set(element.entry.key, element.entry);
      }
      if (!isNullOrUndefined(element.children)) {
        result = Utilities.dataMap(element.children, result);
      }
    }
    return result;
  }

  public static getPosition(event: MouseEvent | TouchEvent) {
    const position = { x: 0, y: 0 };
    if (event instanceof MouseEvent) {
      position.x = event.clientX;
      position.y = event.clientY;
    } else if (event instanceof TouchEvent) {
      position.x = event.changedTouches[0].clientX;
      position.y = event.changedTouches[0].clientY;
    }
    return position;
  }

  public static randomHexGenerator() {
    let hex = Math.floor(Math.random() * 16777215).toString(16);
    const count = hex.length;
    for (let i = 0; i < 6 - count; i++) {
      hex += 'f';
    }
    return '#' + hex;
  }

  public static randomRGBGenerator() {
    const o = Math.round, r = Math.random, s = 255;
    return 'rgb(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ')';
  }

  public static randomUniqueRGBGenerator(unique = []) {
    const rgb = Utilities.randomRGBGenerator();
    return unique.indexOf(rgb) === -1 ? rgb : Utilities.randomUniqueRGBGenerator(unique);
  }

  public static hasAnyElement(haystack: any[], arr: any[]) {
    return arr.some(function (v) {
      return haystack.indexOf(v) >= 0;
    });
  }

  public static sortBy(key: string, translateService?: TranslateService) {
    return (a: any, b: any) => {
      const valA = !isNullOrUndefined(translateService) ? translateService.instant(a[key]) : a[key];
      const valB = !isNullOrUndefined(translateService) ? translateService.instant(b[key]) : b[key];
      return NaturalSort.SORT(valA, valB);
    };
  }

  public static getMaxChildPosition(treeNode: TreeNode, maxPosition = 0) {
    treeNode.children.forEach(childTreeNode => {
      if (childTreeNode.node.positionX > maxPosition) {
        maxPosition = childTreeNode.node.positionX;
      }
    });
    return maxPosition === 0 ? treeNode.node.positionX : maxPosition;
  }

  public static toggleArray(arr: any[], trigger: boolean, element: any): any[] {
    let set = Set(arr);
    if (trigger) {
      set = set.remove(element);
    } else {
      set = set.add(element);
    }
    return set.toArray();
  }

  public static contextFittingString(context, str, maxWidth, ellipsis = '…') {
    let width = context.measureText(str).width;
    const ellipsisWidth = context.measureText(ellipsis).width;
    if (width <= maxWidth || width <= ellipsisWidth) {
      return str;
    } else {
      let len = str.length;
      while (width >= maxWidth - ellipsisWidth && len-- > 0) {
        str = str.substring(0, len);
        width = context.measureText(str).width;
      }
      return str + ellipsis;
    }
  }

  public static arraysEqual(array1, array2) {
    if (!array1 || !array2) {
      return false;
    }
    if (array1.length !== array2.length) {
      return false;
    }
    const count = array1.length;
    for (let i = 0; i < count; i++) {
      if (array1[i] instanceof Array && array2[i] instanceof Array) {
        if (!Utilities.arraysEqual(array1[i], array2[i])) {
          return false;
        }
      } else if (array1[i] !== array2[i]) {
        return false;
      }
    }
    return true;
  }

}
