import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { List } from 'immutable';

import { AppState } from '../../app.reducer';
import { IPayload } from '../shared';

import { BusinessareaAction } from './businessareas.actions';
import * as selectors from './businessareas.selectors';
import { Businessarea } from './businessareas.models';
import { SharedService } from '../shared/shared.service';

@Injectable()
export class BusinessareaService extends SharedService {

  public constructor(protected store: Store<AppState>, protected action: BusinessareaAction) {
    super(store, action, selectors);
  }

  public findByInstance(id: string): Observable<List<Businessarea>> {
    return this.all().map((businessareas: List<Businessarea>) => businessareas.filter(businessarea => businessarea.relationships.instance === id).toList());
  }

  public duplicate(id: string, data: IPayload) {
    this.store.dispatch(this.action.duplicate(id, data));
  }

}
