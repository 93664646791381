import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';

import { API, GoAPI, IHumanResource, JSONAPIResponse } from '../connector';
import { HumanResourceAction } from './humanresources.actions';
import { Action } from '../shared/common.action';

@Injectable()
export class HumanResourceEffects {

  private concurrency = 5;

  @Effect() findHumanResourceByInstance$ = this.actions$
    .pipe(ofType(HumanResourceAction.LOAD))
    .mergeMap((action: Action) => this.api.instance(action.payload.instanceId).humanresources.findAll()
      .mergeMap((response: JSONAPIResponse<IHumanResource[]>) => {
        return Observable.from([
          this.humanResourceAction.loadSuccess(response.toPayload())
        ]);
      })
    ).catch((error: any) => Observable.of(this.humanResourceAction.loadFail(error)));

  @Effect() createHumanResource$ = this.actions$
    .pipe(ofType(HumanResourceAction.CREATE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.data.map(payload => {
      const data = payload.data.toJS();
      delete data.id;
      delete data.can_login;
      return this.api.instance(action.payload.instanceId).humanresources.create(data)
        .mergeMap(response => Observable.from([
          this.humanResourceAction.createSuccess(payload, [response.toPayload()])
        ]))
        .catch((error: any) => Observable.of(this.humanResourceAction.createFail(payload, error)));
    })))
    .mergeAll(this.concurrency);

  @Effect() createHumanResourceGo$ = this.actions$
    .pipe(ofType(HumanResourceAction.CREATE_GO))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.apiGo.instance(action.payload.instanceId).humanresources.bulkCreate(action.payload.data)
      .mergeMap(response => Observable.of(
        this.humanResourceAction.createSuccess(action.payload, response.toPayload())
      ))
      .catch((error: any) => Observable.of(this.humanResourceAction.createFail(action.payload, error)))
    )
  // .mergeAll(this.concurrency);

  @Effect() removeHumanResource$ = this.actions$
    .pipe(ofType(HumanResourceAction.REMOVE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.data.map(payload => this.api.humanresources.remove(payload)
      .mergeMap(response => Observable.from([
        this.humanResourceAction.removeSuccess(payload, [response.toPayload()])
      ]))
      .catch((error: any) => Observable.of(this.humanResourceAction.removeFail(payload, error)))
    )))
    .mergeAll(this.concurrency);

  @Effect() removeHumanResourceGo$ = this.actions$
    .pipe(ofType(HumanResourceAction.REMOVE_GO))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.apiGo.humanresources.bulkRemove(action.payload.data)
      .mergeMap(response => Observable.from([
        this.humanResourceAction.removeSuccess(action.payload, response.toPayload())
      ]))
      .catch((error: any) => Observable.of(this.humanResourceAction.removeFail(action.payload, error)))
    );

  @Effect() updateHumanResource$ = this.actions$
    .pipe(ofType(HumanResourceAction.UPDATE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.map(payload => this.api.humanresources.update(payload.id, payload.data)
      .mergeMap(response => Observable.from([
        this.humanResourceAction.updateSuccess(payload, [response.toPayload()])
      ]))
      .catch((error: any) => Observable.of(this.humanResourceAction.updateFail(payload, error)))
    )))
    .mergeAll(this.concurrency);

  @Effect() updateHumanResourceGo$ = this.actions$
    .pipe(ofType(HumanResourceAction.UPDATE_GO))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.apiGo.humanresources.bulkUpdate([action.payload[0]])
      .mergeMap(response => Observable.from([
        this.humanResourceAction.updateSuccess(action.payload, response.toPayload())
      ]))
      .catch((error: any) => Observable.of(this.humanResourceAction.updateFail(action.payload, error)))
    )

  @Effect() connectHumanResource$ = this.actions$
    .pipe(ofType(HumanResourceAction.CONNECT))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.data.map(payload => this.api.humanresources
      .connect(payload)
      .mergeMap(response => Observable.from([
        this.humanResourceAction.connectSuccess(payload, response.toPayload())
      ]))
      .catch((error: any) => Observable.of(this.humanResourceAction.connectFail(payload, error)))
    )))
    .mergeAll(this.concurrency);

  @Effect() disconnectHumanResource$ = this.actions$
    .pipe(ofType(HumanResourceAction.DISCONNECT))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.data.map(payload => this.api.humanresources
      .disconnect(payload)
      .mergeMap(response => Observable.from([
        this.humanResourceAction.disconnectSuccess(payload, response.toPayload())
      ]))
      .catch((error: any) => Observable.of(this.humanResourceAction.disconnectFail(payload, error)))
    )))
    .mergeAll(this.concurrency);


  constructor(private api: API,
    private apiGo: GoAPI,
    private actions$: Actions,
    private humanResourceAction: HumanResourceAction) {
  }
}
