import { Injectable } from '@angular/core';

import { IAccount, ThirdPartyService } from './thirdparty.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';

export interface OctimineTokenResponse {
  data: { access_token: string, refresh_token: string };
  status: number;
}

export interface IOctimineResponse {
  data: IOctimineResponseData;
  status: number;
}

export interface IOctimineResponseData {
  documents: IOctimine[];
  page: IOctimineResponsePage;
  search_info: IOctimineResponseSearchInfo;
}

export interface IOctimineResponsePage {
  current_page: number;
  last_page: number;
  page_size: number;
  total_hits: number;
}

export interface IOctimineResponseSearchInfo {
  new_search: boolean;
  search_hash: string;
}

export interface IOctimine {
  bibliographic: IOctimineBibliographic;
  general: IOctimineGeneral;
  fulltext: IOctimineFulltext;
  nice_data: IOctimineNiceData;
}

export interface IOctimineBibliographic {
  abstract: string;
  abstract_lang: string;
  also_published_as: string[];
  applicants: string[];
  authorities: string[];
  cpc: string[];
  cpc4: any;
  inventors: string[];
  ipc: string[];
  ipc4: string[];
  priority_date: string;
  publication_authority: string;
  publication_date: string;
  publication_kind: string;
  tech_areas: string[];
  tech_fields: string[];
  title: string;
  title_lang: string;
}

export interface IOctimineGeneral {
  docdb_family_id: number;
  rank: number;
  similarity_index: number;
}

export interface IOctimineFulltext {
  claims: string;
  claims_lang: string;
  description: string;
  description_lang: string;
}

export interface IOctimineNiceData {
  abstract: { short: string; long: string };
  also_published_as: string;
  applicants: string;
  cpc: string;
  cpc4: any;
  ipc: string;
  ipc4: string;
  priority_date: string;
  publication_date: string;
  tech_areas: string;
  tech_fields: string;
  title: string;
  authorities: string;
  similarity_index: number;
  claims: { short: string; long: string };
  description: { short: string; long: string };
}

@Injectable()
export class ThirdPartyOctimineService extends ThirdPartyService {

  public getToken(): Observable<IAccount> {
    /* Check if there is a saved token */
    if (this.account.accessToken !== null && this.account.accessToken !== '') {
      return new BehaviorSubject<IAccount>(this.account);
    } else {
      return this.http.post(this.account.url + '/auth/login', {
        email: this.account.username,
        password: this.account.password
      }).map((response: OctimineTokenResponse) => {
        if (response.status === 200) {
          this.account.accessToken = response.data.access_token;
          this.account.refreshToken = response.data.refresh_token;
        }
        return this.account;
      }).catch((err: HttpErrorResponse) => {
        this.account.error = { status: err.status, message: err.message};
        return new BehaviorSubject<IAccount>(this.account);
      });
    }
  }

  public getTokenByRefreshToken(): Observable<IAccount> {
    return this.http.post(this.account.url + '/auth/refresh', {}, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.account.refreshToken
      })
    }).map((response: OctimineTokenResponse) => {
      if (response.status === 200) {
        this.account.accessToken = response.data.access_token;
        this.updateAccount(this.account);
      }
      return this.account;
    }).catch((err: HttpErrorResponse) => {
      this.account.error = { status: err.status, message: err.message};
      return new BehaviorSubject<IAccount>(this.account);
    });
  }

  public search(searchString: string, page = 1, sort_order = 'asc', sort_by = '', filters = {}): Observable<IOctimineResponse> {
    return <Observable<IOctimineResponse>> this.initialize(this.getAccount('octimine')).request('POST', this.account.url + '/search/semantic?show_fulltext=1&page=' + page + (sort_by !== '' ? '&sort_by=' + sort_by : '') + '&sort_order=' + sort_order, { search_input: searchString, search_filters: filters });
  }

  public searchBoolean(searchString: string, page = 1, sort_order = 'asc', sort_by = '', filters= {}): Observable<IOctimineResponse> {
    return <Observable<IOctimineResponse>> this.initialize(this.getAccount('octimine')).request('POST', this.account.url + '/search/boolean?show_fulltext=1&page=' + page + (sort_by !== '' ? '&sort_by=' + sort_by : '') + '&sort_order=' + sort_order, { search_input: searchString, search_filters: filters });
  }

}
