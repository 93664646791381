import { IPayload } from '../shared';
import { IAction } from '../shared/common.interfaces';

export class NodesAction {
  static key = 'Nodes';

  static CREATE = `[${NodesAction.key}] Create`;
  static CREATE_GO = `[${NodesAction.key}] Create GO`;

  public create(id: string, data: IPayload | IPayload[]): IAction {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: NodesAction.CREATE,
      payload: {
        id: id,
        data: data
      }
    };
  }

  public createGo(id: string, data: IPayload | IPayload[]): IAction {
    if (!Array.isArray(data)) {
      data = [data];
    }

    const z = JSON.stringify(data);

    let outputData = JSON.parse(z);
    outputData = outputData.map(i => {
      i.data.level = Number(i.data.level);
      return i;
    });

    // data[0].data.level = 4;

    return {
      type: NodesAction.CREATE_GO,
      payload: {
        id: id,
        data: outputData
      }
    };
  }

}
