import { Action } from '../shared/common.action';
import { JSONAPIDeserializerServiceNoRel, Utils } from '../shared';
import { Sync, SyncState } from './syncs.models';
import { SyncAction } from './syncs.actions';

export default function reducer(state = new SyncState(), action: Action): SyncState  {
  const deserializer = new JSONAPIDeserializerServiceNoRel<Sync>(Sync.fromJSONAPI);
  switch (action.type) {

    case SyncAction.LOAD:
      return <SyncState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case SyncAction.LOAD_SUCCESS: {
      const {entities} = deserializer.deserialize(action.payload.response);

      return <SyncState>Utils.updateState<SyncState>(state, entities, undefined)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case SyncAction.LOAD_FAIL: {
      return <SyncState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case SyncAction.CREATE: {
      return <SyncState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case SyncAction.CREATE_SUCCESS: {
      const {entities} = deserializer.deserialize(action.payload.response);

      return <SyncState>Utils.updateState<SyncState>(state, entities, undefined, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case SyncAction.CREATE_FAIL: {
      return <SyncState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case SyncAction.REMOVE: {
      return <SyncState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case SyncAction.REMOVE_SUCCESS: {
      const {entities} = deserializer.deserialize(action.payload.response);
      return <SyncState>Utils.deleteFromState<SyncState>(state, entities, undefined, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case SyncAction.REMOVE_FAIL: {
      return <SyncState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case SyncAction.UPDATE: {
      return <SyncState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case SyncAction.UPDATE_SUCCESS: {
      const {entities} = deserializer.deserialize(action.payload.response);
      return <SyncState>Utils.updateState<SyncState>(state, entities, undefined, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case SyncAction.UPDATE_FAIL: {
      return <SyncState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case SyncAction.EXTOAUTH: {
      return <SyncState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case SyncAction.EXTOAUTH_SUCCESS: {
      return <SyncState>Utils.updateDiff(state, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case SyncAction.EXTOAUTH_FAIL: {
      return <SyncState>Utils.updateDiff(state, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case SyncAction.SQS: {
      return <SyncState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case SyncAction.SQS_SUCCESS: {
      return <SyncState>Utils.updateDiff(state, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case SyncAction.SQS_FAIL: {
      return <SyncState>Utils.updateDiff(state, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    default: {
      return state;
    }
  }
}
