import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { DndModule } from 'ng2-dnd-fixed';
import { RouterModule } from '@angular/router';

import { NavbarComponent } from './navbar/navbar.component';
import { RadialMenuComponent } from '../working/shared/radial-menu/radial-menu.component';
import { SubscriptionService } from './utilities/subscription';
import { ModalsModule } from './modals/modals.module';
import { DatasheetModule } from './datasheet/datasheet.module';
import { NotificationsModule } from './notifications/notifications.module';
import { TreeViewComponent } from './components/treeview/treeview.component';
import { NodeMenuComponent } from './components/node-menu/node-menu.component';
import {ScreenFactory} from './utilities/screen.factory';
import { LoadingComponent } from './components/loading/loading.component';
import { APIModule } from './api';
import { FormHelper } from './utilities/form.helper';
import { TreeService } from '../start/shared/services/tree.service';
import { SimilarityService } from './ai/similarity/similarity.service';

@NgModule({
  providers: [
    FormHelper,
    TreeService,
    SimilarityService
    // SubscriptionService,
    // ScreenFactory
  ],
  declarations: [
    LoadingComponent
    // RadialMenuComponent,
    // LoadingComponent,
    // TreeViewComponent,
    // NodeMenuComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    // APIModule
    // CommonModule,
    // RouterModule,
    // TranslateModule,
    // NotificationsModule,
    // ModalsModule,
    // DatasheetModule,
    // DndModule.forRoot(),
  ],
  exports: [
    LoadingComponent
  ]
})
export class SharedModule {}
