import { Map } from 'immutable';
import { WordWrapLine } from './wordwrap';

export class WordwrapCache {

  private static dictonary = Map<string, any>();
  private static splits = Map<string, WordWrapLine[]>();

  public static getWidth(text: string, element: any) {
    if (this.dictonary.has(text)) {
      return this.dictonary.get(text);
    } else {
      const width = element.getBoundingClientRect().width;
      this.dictonary = this.dictonary.set(text, width);
      return width;
    }
  }

  public static getSplit(text: string) {
    return this.splits.get(text);
  }

  public static setSplit(text: string, split: WordWrapLine[]) {
    return this.splits.set(text, split);
  }

}
