import { Injectable } from '@angular/core';
import { IHierarchy } from '../connector';

import { Action } from '../shared/common.action';

@Injectable()
export class HierarchyAction {

  static key = 'Hierarchy';

  static LOAD = `[${HierarchyAction.key}] Load`;
  static LOAD_ALL = `[${HierarchyAction.key}] Load all`;
  static LOAD_SUCCESS = `[${HierarchyAction.key}] Load success`;
  static LOAD_FAIL = `[${HierarchyAction.key}] Load fail`;

  public load(id: string): Action {
    return {
      type: HierarchyAction.LOAD,
      payload: {
        id: id
      }
    };
  }

  public loadAll(): Action {
    return {
      type: HierarchyAction.LOAD_ALL,
    };
  }

  public loadSuccess(data: IHierarchy[]) {
    return {
      type: HierarchyAction.LOAD_SUCCESS,
      payload: {
        response: data
      }
    };
  }

  public loadFail(error: any) {
    return {
      type: HierarchyAction.LOAD_FAIL,
      payload: {
        request: undefined,
        response: error
      }
    };
  }
}
