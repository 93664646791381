import {Datum} from '../../../../../app/shared/utilities/datum';

export const TemplateFieldsReportsQReportConfig = [
  {
    type: 'general',
    settings: {
      fontSize: 7,
      header: '',
      orientation: 'L',
      footer: new Datum().toEuropeanString(),
      /* Background image for the first page in the report */
      backgroundImage: 'QReportChapter.jpg',
    }
  }
];

export const TemplateFieldsReportsQReportSkeleton = [
  {
    settings: {
      /* Virtual scrolling */
      virtualScrolling: false,
      /* Make the table fixed */
      tableFixed: false,
      /* Group the elements by levels */
      groupByLevels: false,
      /* Show total row after level group */
      showTotalsAfterLevel: false,
      /* Show total row at the end of the table */
      showTotals: false,
      /* Group the elements by model */
      groupByModels: false,
      /* Use a thousand seperator for numeric values */
      thousandsSeperator: false,
      /* How many decimals should be shown on numeric values */
      decimalnumbers: 2,
      /* Show top most header row (grouped heading) */
      showHeading: false,
      /* Show table fields */
      showFields: true,
      /* Show the footer */
      showFooter: false,
      /* Has multi line cells */
      hasMultiCell: false,
      /* Different source then nodes */
      source: '', // '', models, activities
      /* Caption element above the table */
      caption: null,
      /* The header text color */
      headerTextColor: '#ffffff',
      /* The header background color */
      headerBackgroundColor: '#9ac93e',
      /* The border */
      border: false,
      /* A filter function to filter by */
      filter: null,
      /* Break the collapsing on level border */
      breakCollapseOnLevel: false,
      /* Show the assigned activities */
      activities: false,
      /* Trigger to load the Businessarea with that table */
      loadBusinessarea: false,
      /* Show sidestep top most / tree below related node */
      showSidestep: false, // false, tree, topmost
      /* Exports */
      exports: [], //  excel, pdf
      /* Allow page to break vertically */
      verticalPageBreak: false,
      /* Turn toggle mode on or off */
      toggleFields: false,
      /* Show table fields select box */
      showFieldsSelectBox: true,
      /* Show add button */
      wizardButton: true
    },
  }
  /*
  All the reports follow here, with chapters wherever they are needed,
  Everything will be in the format,
  *****
    TemplateFieldsReportsQReportTitlePageConfig,
    { type: 'pagebreak' }
    Config for models
  *****
  And then this would be passed into the export config service
  */
];
