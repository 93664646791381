import { Action } from '../shared/common.action';
import { Injectable } from '@angular/core';
import { IPayload } from '../shared/common.models';
import { ISync } from '../connector/models';

@Injectable()
export class SyncAction {
  static key = 'Sync';

  static LOAD = `[${SyncAction.key}] Load`;
  static LOAD_SUCCESS = `[${SyncAction.key}] Load success`;
  static LOAD_FAIL = `[${SyncAction.key}] Load fail`;

  static CREATE = `[${SyncAction.key}] Create`;
  static CREATE_SUCCESS = `[${SyncAction.key}] Create success`;
  static CREATE_FAIL = `[${SyncAction.key}] Create fail`;

  static UPDATE = `[${SyncAction.key}] Update`;
  static UPDATE_SUCCESS = `[${SyncAction.key}] Update success`;
  static UPDATE_FAIL = `[${SyncAction.key}] Update fail`;

  static REMOVE = `[${SyncAction.key}] Remove`;
  static REMOVE_SUCCESS = `[${SyncAction.key}] Remove success`;
  static REMOVE_FAIL = `[${SyncAction.key}] Remove fail`;

  static EXTOAUTH = `[${SyncAction.key}] ExtOAuth`;
  static EXTOAUTH_SUCCESS = `[${SyncAction.key}] ExtOAuth success`;
  static EXTOAUTH_FAIL = `[${SyncAction.key}] ExtOAuth fail`;

  static SQS = `[${SyncAction.key}] SQS`;
  static SQS_SUCCESS = `[${SyncAction.key}] SQS success`;
  static SQS_FAIL = `[${SyncAction.key}] SQS fail`;

  public load(): Action {
    return {
      type: SyncAction.LOAD,
      payload: null
    };
  }

  public loadSuccess(data: ISync[]) {
    return {
      type: SyncAction.LOAD_SUCCESS,
      payload: {
        response: data
      }
    };
  }

  public loadFail(error: any) {
    return {
      type: SyncAction.LOAD_FAIL,
      payload: {
        request: undefined,
        response: error
      }
    };
  }

  public create(data: IPayload | IPayload[]) {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: SyncAction.CREATE,
      payload: {
        data: data
      }
    };
  }

  public createSuccess(payload: IPayload, response: ISync) {
    return {
      type: SyncAction.CREATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public createFail(payload: IPayload, error: any) {
    return {
      type: SyncAction.CREATE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public update(data: IPayload | IPayload[]) {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: SyncAction.UPDATE,
      payload: data
    };
  }

  public updateSuccess(payload: IPayload, response: ISync) {
    return {
      type: SyncAction.UPDATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public updateFail(payload: IPayload, error: any) {
    return {
      type: SyncAction.UPDATE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public remove(ids: string | string[]) {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }
    return {
      type: SyncAction.REMOVE,
      payload: {
        data: ids
      }
    };
  }

  public removeSuccess(payload: IPayload, response: ISync) {
    return {
      type: SyncAction.REMOVE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public removeFail(payload: IPayload, error: any) {
    return {
      type: SyncAction.REMOVE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public extOAuth(data: any) {
    return {
      type: SyncAction.EXTOAUTH,
      payload: data
    };
  }

  public extOAuthSuccess(response: any) {
    return {
      type: SyncAction.EXTOAUTH_SUCCESS,
      payload: {
        request: {},
        response: response
      }
    };
  }

  public extOAuthFail(error: any) {
    return {
      type: SyncAction.EXTOAUTH_FAIL,
      payload: {
        request: {},
        response: error
      }
    };
  }

  public sqs(data: any) {
    return {
      type: SyncAction.SQS,
      payload: data
    };
  }

  public sqsSuccess(data: any, response: any) {
    return {
      type: SyncAction.SQS_SUCCESS,
      payload: {
        request: data,
        response: response
      }
    };
  }

  public sqsFail(data: any, error: any) {
    return {
      type: SyncAction.SQS_FAIL,
      payload: {
        request: data,
        response: error
      }
    };
  }

}
