import { BehaviorSubject, Observable } from 'rxjs';
import { GridsterItem } from 'angular-gridster2';

import { Businessarea } from '../../shared/api/businessareas';
import { ModelCreate } from '../../shared/api/models/models.models';
import { IPayload } from '../../shared/api/shared';
import { NodeCreate } from '../../shared/api/nodes';
import { RelationshipCreate } from '../../shared/api/relationships';

import {
  NODES_TYPE_ACTION,
  NODES_TYPE_ACTIONBAR,
  NODES_TYPE_ACTIONGROUP,
  NODES_TYPE_ADD,
  NODES_TYPE_ADD_CHILD,
  NODES_TYPE_ADD_ELEMENT,
  NODES_TYPE_AI,
  NODES_TYPE_ALLOWED,
  NODES_TYPE_ANALYSE,
  NODES_TYPE_ANALYSE_STRUCTURE,
  NODES_TYPE_ANALYSEFOLDER,
  NODES_TYPE_ASSET_GROUP,
  NODES_TYPE_ASSET_MACHINE,
  NODES_TYPE_ASSET_MODULE, NODES_TYPE_ASSET_POSITION, NODES_TYPE_AXIS,
  NODES_TYPE_BADWORD,
  NODES_TYPE_BUSINESSAREA, NODES_TYPE_BY,
  NODES_TYPE_CAPABILITY,
  NODES_TYPE_CAPACITY,
  NODES_TYPE_CATEGORY,
  NODES_TYPE_CATEGORYGROUP,
  NODES_TYPE_CCM, NODES_TYPE_CENTER,
  NODES_TYPE_CHALLENGE,
  NODES_TYPE_CHILD,
  NODES_TYPE_CLIENT,
  NODES_TYPE_COLORLABELPROVIDER,
  NODES_TYPE_COLUMN,
  NODES_TYPE_COMMENT,
  NODES_TYPE_COMPETITOR,
  NODES_TYPE_CONNECT,
  NODES_TYPE_COUNTERMEASURE,
  NODES_TYPE_CURVED_LINK,
  NODES_TYPE_CWM,
  NODES_TYPE_DATASHEET,
  NODES_TYPE_DATASOURCE,
  NODES_TYPE_DATATARGET,
  NODES_TYPE_DEFAULT, NODES_TYPE_DEFAULTVALUES,
  NODES_TYPE_DEFINITION,
  NODES_TYPE_DELETE,
  NODES_TYPE_DELIVERABLE,
  NODES_TYPE_DEMAND, NODES_TYPE_DIRECT_CHAIN,
  NODES_TYPE_DISCONNECT,
  NODES_TYPE_DRAG,
  NODES_TYPE_DROP, NODES_TYPE_DUPLICATE,
  NODES_TYPE_EDIT,
  NODES_TYPE_ELSE, NODES_TYPE_ENTITY,
  NODES_TYPE_FACTSHEET,
  NODES_TYPE_FEASIBILITY_IP,
  NODES_TYPE_FEASIBILITY_TECH,
  NODES_TYPE_FIELD,
  NODES_TYPE_FIELDS,
  NODES_TYPE_FILTERS,
  NODES_TYPE_FOCUS,
  NODES_TYPE_FOOTER_TABS,
  NODES_TYPE_FORM, NODES_TYPE_FORM_COLUMN,
  NODES_TYPE_FORM_OPTION, NODES_TYPE_FORM_ROW,
  NODES_TYPE_FORM_TAB, NODES_TYPE_FULL,
  NODES_TYPE_FUNCTIONS,
  NODES_TYPE_GANTT,
  NODES_TYPE_GAP,
  NODES_TYPE_GLOBALFILTER,
  NODES_TYPE_GLOBALSUM,
  NODES_TYPE_GROUP, NODES_TYPE_HIERARCHY,
  NODES_TYPE_HOMEACTION, NODES_TYPE_HORIZONTAL,
  NODES_TYPE_HUMANRESOURCE,
  NODES_TYPE_IDEA,
  NODES_TYPE_IF,
  NODES_TYPE_IGNORE,
  NODES_TYPE_INDUSTRY,
  NODES_TYPE_INFRACTION,
  NODES_TYPE_INITIATIVE,
  NODES_TYPE_INSTANCE,
  NODES_TYPE_IPCCLASS,
  NODES_TYPE_IPNAVIGATOR,
  NODES_TYPE_KEYWORD,
  NODES_TYPE_LEGEND,
  NODES_TYPE_LIBRARY, NODES_TYPE_LINE,
  NODES_TYPE_LINK, NODES_TYPE_MACHINE,
  NODES_TYPE_MACROTREND, NODES_TYPE_MANUFACTURING_SLOT,
  NODES_TYPE_MARKET,
  NODES_TYPE_MCM,
  NODES_TYPE_MEGATREND, NODES_TYPE_MEMBER,
  NODES_TYPE_METHODOLOGY,
  NODES_TYPE_MICROTREND,
  NODES_TYPE_MILESTONE,
  NODES_TYPE_MODEL, NODES_TYPE_MODULE,
  NODES_TYPE_MODULECONFIGURATION,
  NODES_TYPE_MUSTHAVE, NODES_TYPE_MUTATION, NODES_TYPE_MY,
  NODES_TYPE_NEXT,
  NODES_TYPE_NODE,
  NODES_TYPE_NODETYPEGROUP,
  NODES_TYPE_OBJECTIVE,
  NODES_TYPE_OPERATION,
  NODES_TYPE_ORGANISATIONALUNIT,
  NODES_TYPE_ORGLEVEL_1,
  NODES_TYPE_ORGLEVEL_2,
  NODES_TYPE_ORGLEVEL_3, NODES_TYPE_ORGLEVEL_5, NODES_TYPE_ORGLEVEL_6, NODES_TYPE_ORGLEVEL_7, NODES_TYPE_PAGINATION,
  NODES_TYPE_PARENT,
  NODES_TYPE_PATENT,
  NODES_TYPE_PLACE_CHILDREN_ASIDE,
  NODES_TYPE_PLANTS, NODES_TYPE_PROCESS,
  NODES_TYPE_PRODUCT,
  NODES_TYPE_PRODUCTGROUP,
  NODES_TYPE_PROJECT, NODES_TYPE_PROJECT_CATEGORY,
  NODES_TYPE_PROJECTPORTFOLIO,
  NODES_TYPE_QUESTION,
  NODES_TYPE_RECEIVER,
  NODES_TYPE_REGION,
  NODES_TYPE_RELATIONSHIP, NODES_TYPE_REMAINING, NODES_TYPE_REMINDER,
  NODES_TYPE_REQUIREMENT, NODES_TYPE_REVERSE, NODES_TYPE_ROLLOUT, NODES_TYPE_ROUNDED,
  NODES_TYPE_SCM,
  NODES_TYPE_SEARCH,
  NODES_TYPE_SELECT,
  NODES_TYPE_SETTINGS,
  NODES_TYPE_SETUPACTION,
  NODES_TYPE_SKILLS, NODES_TYPE_SLOT,
  NODES_TYPE_SMARTACTION,
  NODES_TYPE_SOLUTION,
  NODES_TYPE_SORTING,
  NODES_TYPE_SPLASHSCREEN, NODES_TYPE_STANDARD_LINE,
  NODES_TYPE_STEP, NODES_TYPE_STRAIGHT_LINKS,
  NODES_TYPE_STRATEGYMAP,
  NODES_TYPE_STRUCTURE, NODES_TYPE_SUGGESTIONS,
  NODES_TYPE_SUM,
  NODES_TYPE_TABLEGROUP, NODES_TYPE_TABLEGROUPMONTHS,
  NODES_TYPE_TASK,
  NODES_TYPE_TEAM,
  NODES_TYPE_TECHAREA,
  NODES_TYPE_TECHFIELD,
  NODES_TYPE_TEXTBLOCK,
  NODES_TYPE_THEN,
  NODES_TYPE_TOGGLE, NODES_TYPE_TRANSFER,
  NODES_TYPE_TREND,
  NODES_TYPE_UCIM,
  NODES_TYPE_UPDATE, NODES_TYPE_VALUE, NODES_TYPE_VERTICAL,
  NODES_TYPE_WBS_ACTIVITY,
  NODES_TYPE_WBS_PROGRAM,
  NODES_TYPE_WBS_SUBPROJECTS,
  NODES_TYPE_WBS_WORKPACKAGE,
  NODES_TYPE_WELCOME_POWER,
  NODES_TYPE_WELCOME_REGULAR,
  NODES_TYPE_WIDGET,
  NODES_TYPE_WIDGET_FOOTER,
  NODES_TYPE_WIDGET_HEADER,
  NODES_TYPE_WIDGET_ROW,
  NODES_TYPE_WORKFLOW,
  NODES_TYPE_WORKFLOW_LINK,
  NODES_TYPE_WORKFLOW_QUESTIONNAIRE,
  NODES_TYPE_WORKFLOW_QUESTIONNAIRE_TAB
} from '../../shared/api/nodes/nodes.models';
import { EventEmitter } from '@angular/core';
import { Activity } from '../../shared/api/activities';
import { HumanResource } from '../../shared/api/humanresources';
import { Group } from '../../shared/api/groups';
import { DashboardScreenComponent } from '../../screens/dashboard/dashboard.screen.component';
import { Datum } from '../../shared/utilities/datum';

export interface TreeActivity {
  /* Tree */
  id: string;
  phantom: boolean;
  calculated: any;
  nodebucket: boolean;
  internalType: string;
  /* Relationships */
  activitable_type: string;
  activitable_id: string;
  humanresources: string;
  instance: string;
  nodeData: string;
  /* Data */
  name: string;
  description: string;
  start: string;
  end: string;
  startActual: string;
  endActual: string;
  reference: string;
  crossReference: string;
  type: number;
  status: number;
  responsibleId: number;
  percentageComplete: number;
  priority: number;
  milestone: number;
  budget: number;
  budgetPlan: number;
  benefitBudget: number;
  benefitActual: number;
  benefitRemaining: number;
  costBudget: number;
  costActual: number;
  costRemaining: number;
  investBudget: number;
  investActual: number;
  investRemaining: number;
  riskProfileCategory: number;
  riskProfileAssessment: number;
  riskProfileCountermeasures: string;
  time: number;
  timeDescription: string;
  cost: number;
  costDescription: string;
  quality: number;
  qualityDescription: string;
  executive: number;
  executiveDescription: string;
  program: number;
  programDescription: string;
  functional: number;
  functionalDescription: string;
  resource: number;
  resourceDescription: string;
  organizational: number;
  organizationalDescription: string;
  technical: number;
  technicalDescription: string;
  updatedAt: number;
  duplicate_original_id: number;
  sorting: number;
  float_field1_a: number;
  float_field2_a: number;
  float_field3_a: number;
  float_field4_a: number;
  float_field5_a: number;
  float_field6_a: number;
  float_field7_a: number;
  float_field8_a: number;
  float_field9_a: number;
  float_field10_a: number;
  float_field11_a: number;
  float_field12_a: number;
  float_field13_a: number;
  float_field14_a: number;
  float_field15_a: number;
  float_field1_b: number;
  float_field2_b: number;
  float_field3_b: number;
  float_field4_b: number;
  float_field5_b: number;
  float_field6_b: number;
  float_field7_b: number;
  float_field8_b: number;
  float_field9_b: number;
  float_field10_b: number;
  float_field11_b: number;
  float_field12_b: number;
  float_field13_b: number;
  float_field14_b: number;
  float_field15_b: number;
  float_field1_c: number;
  float_field2_c: number;
  float_field3_c: number;
  float_field4_c: number;
  float_field5_c: number;
  float_field6_c: number;
  float_field7_c: number;
  float_field8_c: number;
  float_field9_c: number;
  float_field10_c: number;
  float_field11_c: number;
  float_field12_c: number;
  float_field13_c: number;
  float_field14_c: number;
  float_field15_c: number;
  float_field1_d: number;
  float_field2_d: number;
  float_field3_d: number;
  float_field4_d: number;
  float_field5_d: number;
  float_field6_d: number;
  float_field7_d: number;
  float_field8_d: number;
  float_field9_d: number;
  float_field10_d: number;
  float_field11_d: number;
  float_field12_d: number;
  float_field13_d: number;
  float_field14_d: number;
  float_field15_d: number;
}

export interface TreeNode {
  /* Ids */
  id: string;
  dataId: string;
  internalType: string;
  internalSorting: number;
  internalSubLevel: number;
  /* Tree information */
  parents: TreeNode[];
  parentsByKey: any;
  parentIds: string[];
  children: TreeNode[];
  childrenByKey: any;
  filteredChildren: any;
  unfilteredParents: TreeNode[];
  unfilteredChildren: TreeNode[];
  connectedChildren: TreeNode[];
  relatedTreeNode: TreeNode;
  originalParents: TreeNode[];
  originalChildren: TreeNode[];
  activities: TreeActivity[];
  treeAnchor: string;
  level: number;
  subLevel: number;
  color: string;
  invertedColor: string;
  visible: any;
  /* Colors */
  colors: string[];
  invertedColors: string[];
  /* Relationships */
  nodedata: string;
  originalId: string;
  modelId: string;
  parentModel: string;
  subsets: string[];
  sidesteps: string[];
  instance: string;
  templates: string;
  models: string[];
  nodestructures: string[];
  businessarea: string;
  created: string[];
  relatedChildren: number[];
  relatedParents: number[];
  /* Node structure */
  active: boolean;
  positionX: number;
  autoPositionX: number;
  autoPositionUncrossed: number;
  updatedAt: number;
  isSidestep: boolean;
  isInput: boolean;
  /* Node data */
  name: string;
  reference: string;
  crossReference: string;
  createdAt: number;
  description: string;
  description1: string;
  description2: string;
  description3: string;
  description4: string;
  documentUri: string;
  upload_uri: string;
  status: number;
  responsibleId: number;
  groupId: number;
  targetDate: string | number;
  actualDate: string;
  aggregationKPI: number;
  KPI: number;
  businessBenefit: number;
  commercialStatus: number;
  complexity: number;
  uncertainty: number;
  risk: number;
  sizeType: number;
  budget: number;
  budgetActual: number;
  budgetRemaining: number;
  budgetCalculated: number;
  benefitBudget: number;
  benefitActual: number;
  benefitRemaining: number;
  costBudget: number;
  costActual: number;
  costRemaining: number;
  investBudget: number;
  investActual: number;
  investRemaining: number;
  businesscalculation: string;
  creationDate: number;
  lastUpdated: number;
  currency: string;
  startDate: string;
  actualStartDate: string;
  percentageComplete: number;
  priority: number;
  sorting: number;
  riskProfileCategory: number;
  riskProfileAssessment: number;
  riskProfileCountermeasures: string;
  time: number;
  timeDescription: string;
  cost: number;
  costDescription: string;
  quality: number;
  qualityDescription: string;
  executive: number;
  executiveDescription: string;
  program: number;
  programDescription: string;
  functional: number;
  functionalDescription: string;
  resource: number;
  resourceDescription: string;
  organizational: number;
  organizationalDescription: string;
  technical: number;
  technicalDescription: string;
  storypoints: number;
  costtype: number;
  onreport: boolean;
  nodeType: number;
  linkToParent: string;
  alsoPublishedAs: string;
  cpc: string;
  cpc4: string;
  ipc: string;
  ipc4: string;
  applicants: string;
  techAreas: string;
  techFields: string;
  authorities: string;
  workFlowModel: number;
  workFlowOperation: string;
  workFlowIfClause: string;
  workFlowId: string;
  workFlowFormId: string;
  workFlowFormParams: string;
  workFlowLink: string;
  workFlowTabId: string;
  formId: string;
  formFieldId: string;
  formFieldType: string;
  formFieldControlType: string;
  formFieldDropdownValue: string;
  formFieldEditable: boolean;
  formFieldSortable: boolean;
  formFieldFilterable: boolean;
  formFieldFixed: boolean;
  formFieldSearchable: boolean;
  formFieldWidth: string;
  fullscreen: boolean;
  formFieldBucketId: string;
  formFieldCalculation: string;
  formFieldShape: string;
  formBucket: boolean;
  form_default_value: string | number;
  printable: boolean;
  hideWidget: boolean;
  ai_type: string;
  ai_preset: string;
  structure_duplicate_original_id: number;
  data_duplicate_original_id: number;
  widget: string;
  icon: string;
  obligatory: boolean;
  dashboardCols: number;
  dashboardRows: number;
  dashboardX: number;
  dashboardY: number;
  fieldConversion: string;
  fieldCreateType: string;
  fieldSkipIfExists: boolean;
  colorLabelProvider: string;
  sync: any;
  /* Additional parameter for in app usage */
  truncatedName: string;
  subLevelName: string;
  x: number;
  y: number;
  fx: number;
  fy: number;
  selector: string;
  phantom: boolean;
  placeholder: boolean;
  filtered: boolean;
  hasChildren: boolean;
  size: number;
  selected: boolean;
  preselected: boolean;
  collapsed: boolean;
  image: any;
  relationship: TreeRelationship;
  dropZones: string[];
  email: string;
  createdAtReadable: string;
  audit: string;
  isSummary: boolean;
  attention: boolean;
  calculatedStart: Datum;
  calculatedEnd: Datum;
  flag: string;
  checkboxValue: boolean;
  validFrom?: Date;
  validUntil?: Date;
  ping: boolean;
  valueInBudget: boolean;
}

export interface CoreAudit {
  id: string;
  event: string;
  before: any;
  after: any;
  type: string;
  elementId: string;
  userId: string;
  responsibleId: string;
  updatedAt: Datum;
  readableUpdatedAt: string;
}

export const CoreNodeTypes: CoreNodeType[] = [
  { key: NODES_TYPE_INITIATIVE, label: 'NODE.DATASHEET.FIELDS.TYPE.INITIATIVE', color: '#42DE95' },
  { key: NODES_TYPE_QUESTION, label: 'NODE.DATASHEET.FIELDS.TYPE.QUESTION', color: '#999999' },
  { key: NODES_TYPE_PROJECT, label: 'NODE.DATASHEET.FIELDS.TYPE.PROJECT', color: '#A40E4C' },
  { key: NODES_TYPE_FEASIBILITY_TECH, label: 'NODE.DATASHEET.FIELDS.TYPE.TECH', color: '#999999' },
  { key: NODES_TYPE_FEASIBILITY_IP, label: 'NODE.DATASHEET.FIELDS.TYPE.IP', color: '#999999' },
  { key: NODES_TYPE_REQUIREMENT, label: 'NODE.DATASHEET.FIELDS.TYPE.REQUIREMENT', color: '#79B8F4' },
  { key: NODES_TYPE_SOLUTION, label: 'NODE.DATASHEET.FIELDS.TYPE.SOLUTION', color: '#42DE95' },
  { key: NODES_TYPE_LIBRARY, label: 'NODE.DATASHEET.FIELDS.TYPE.LIBRARY', color: '#999999' },
  { key: NODES_TYPE_DATASOURCE, label: 'NODE.DATASHEET.FIELDS.TYPE.DATASOURCE', color: '#999999' },
  { key: NODES_TYPE_TREND, label: 'NODE.DATASHEET.FIELDS.TYPE.TRENDS', color: '#999999' },
  { key: NODES_TYPE_IPCCLASS, label: 'NODE.DATASHEET.FIELDS.TYPE.IPCCLASS', color: '#999999' },
  { key: NODES_TYPE_PATENT, label: 'NODE.DATASHEET.FIELDS.TYPE.PATENT', color: '#23806f' },
  { key: NODES_TYPE_CLIENT, label: 'NODE.DATASHEET.FIELDS.TYPE.CLIENT', color: '#999999' },
  { key: NODES_TYPE_COMPETITOR, label: 'NODE.DATASHEET.FIELDS.TYPE.COMPETITOR', color: '#999999' },
  { key: NODES_TYPE_MARKET, label: 'NODE.DATASHEET.FIELDS.TYPE.MARKET', color: '#2CA4BB' },
  { key: NODES_TYPE_DEFINITION, label: 'NODE.DATASHEET.FIELDS.TYPE.DEFINITION', color: '#999999' },
  { key: NODES_TYPE_OPERATION, label: 'NODE.DATASHEET.FIELDS.TYPE.OPERATION', color: '#999999' },
  { key: NODES_TYPE_DATATARGET, label: 'NODE.DATASHEET.FIELDS.TYPE.DATATARGET', color: '#999999' },
  { key: NODES_TYPE_TECHFIELD, label: 'NODE.DATASHEET.FIELDS.TYPE.TECHFIELD', color: '#999999' },
  { key: NODES_TYPE_TECHAREA, label: 'NODE.DATASHEET.FIELDS.TYPE.TECHAREA', color: '#999999' },
  { key: NODES_TYPE_CCM, label: 'NODE.DATASHEET.FIELDS.TYPE.CCM', color: '#999999' },
  { key: NODES_TYPE_SCM, label: 'NODE.DATASHEET.FIELDS.TYPE.SCM', color: '#999999' },
  { key: NODES_TYPE_UCIM, label: 'NODE.DATASHEET.FIELDS.TYPE.UCIM', color: '#999999' },
  { key: NODES_TYPE_CWM, label: 'NODE.DATASHEET.FIELDS.TYPE.CWM', color: '#999999' },
  { key: NODES_TYPE_MCM, label: 'NODE.DATASHEET.FIELDS.TYPE.MCM', color: '#999999' },
  { key: NODES_TYPE_IPNAVIGATOR, label: 'NODE.DATASHEET.FIELDS.TYPE.IPNAVIGATOR', color: '#999999' },
  { key: NODES_TYPE_IF, label: 'NODE.DATASHEET.FIELDS.TYPE.IF', color: '#999999' },
  { key: NODES_TYPE_THEN, label: 'NODE.DATASHEET.FIELDS.TYPE.THEN', color: '#999999' },
  { key: NODES_TYPE_ELSE, label: 'NODE.DATASHEET.FIELDS.TYPE.ELSE', color: '#999999' },
  { key: NODES_TYPE_INSTANCE, label: 'NODE.DATASHEET.FIELDS.TYPE.INSTANCE', color: '#999999' },
  { key: NODES_TYPE_BUSINESSAREA, label: 'NODE.DATASHEET.FIELDS.TYPE.BUSINESSAREA', color: '#999999' },
  { key: NODES_TYPE_MODEL, label: 'NODE.DATASHEET.FIELDS.TYPE.MODEL', color: '#999999' },
  { key: NODES_TYPE_WORKFLOW, label: 'NODE.DATASHEET.FIELDS.TYPE.WORKFLOW', color: '#999999' },
  { key: NODES_TYPE_FORM, label: 'NODE.DATASHEET.FIELDS.TYPE.FORM', color: '#999999' },
  { key: NODES_TYPE_FORM_TAB, label: 'NODE.DATASHEET.FIELDS.TYPE.FORMTAB', color: '#999999' },
  { key: NODES_TYPE_FORM_OPTION, label: 'NODE.DATASHEET.FIELDS.TYPE.FORMOPTION', color: '#999999' },
  { key: NODES_TYPE_WORKFLOW_QUESTIONNAIRE, label: 'NODE.DATASHEET.FIELDS.TYPE.WORKFLOWQUESTIONNAIRE', color: '#999999' },
  { key: NODES_TYPE_WORKFLOW_QUESTIONNAIRE_TAB, label: 'NODE.DATASHEET.FIELDS.TYPE.WORKFLOWQUESTIONNAIRETAB', color: '#999999' },
  { key: NODES_TYPE_WORKFLOW_LINK, label: 'NODE.DATASHEET.FIELDS.TYPE.WORKFLOWLINK', color: '#999999' },
  { key: NODES_TYPE_NODE, label: 'NODE.DATASHEET.FIELDS.TYPE.NODE', color: '#999999' },
  { key: NODES_TYPE_CATEGORY, label: 'NODE.DATASHEET.FIELDS.TYPE.CATEGORY', color: '#999999' },
  { key: NODES_TYPE_CATEGORYGROUP, label: 'NODE.DATASHEET.FIELDS.TYPE.CATEGORYGROUP', color: '#999999' },
  { key: NODES_TYPE_INDUSTRY, label: 'NODE.DATASHEET.FIELDS.TYPE.INDUSTRY', color: '#999999' },
  { key: NODES_TYPE_MACROTREND, label: 'NODE.DATASHEET.FIELDS.TYPE.MACROTREND', color: '#999999' },
  { key: NODES_TYPE_MEGATREND, label: 'NODE.DATASHEET.FIELDS.TYPE.MEGATREND', color: '#999999' },
  { key: NODES_TYPE_MICROTREND, label: 'NODE.DATASHEET.FIELDS.TYPE.MICROTREND', color: '#999999' },
  { key: NODES_TYPE_IDEA, label: 'NODE.DATASHEET.FIELDS.TYPE.IDEA', color: '#c73264' },
  { key: NODES_TYPE_PRODUCT, label: 'NODE.DATASHEET.FIELDS.TYPE.PRODUCT', color: '#00d4de' },
  { key: NODES_TYPE_MILESTONE, label: 'NODE.DATASHEET.FIELDS.TYPE.MILESTONE', color: '#999999' },
  { key: NODES_TYPE_MODULECONFIGURATION, label: 'NODE.DATASHEET.FIELDS.TYPE.MODULECONFIGURATION', color: '#999999' },
  { key: NODES_TYPE_WIDGET, label: 'NODE.DATASHEET.FIELDS.TYPE.WIDGET', color: '#999999' },
  { key: NODES_TYPE_SMARTACTION, label: 'NODE.DATASHEET.FIELDS.TYPE.SMARTACTION', color: '#999999' },
  { key: NODES_TYPE_DATASHEET, label: 'NODE.DATASHEET.FIELDS.TYPE.DATASHEET', color: '#999999' },
  { key: NODES_TYPE_DELETE, label: 'NODE.DATASHEET.FIELDS.TYPE.DELETE', color: '#999999' },
  { key: NODES_TYPE_GANTT, label: 'NODE.DATASHEET.FIELDS.TYPE.GANTT', color: '#999999' },
  { key: NODES_TYPE_TABLEGROUP, label: 'NODE.DATASHEET.FIELDS.TYPE.TABLEGROUP', color: '#999999' },
  { key: NODES_TYPE_ALLOWED, label: 'NODE.DATASHEET.FIELDS.TYPE.ALLOWED', color: '#999999' },
  { key: NODES_TYPE_ACTIONBAR, label: 'NODE.DATASHEET.FIELDS.TYPE.ACTIONBAR', color: '#999999' },
  { key: NODES_TYPE_MUSTHAVE, label: 'NODE.DATASHEET.FIELDS.TYPE.MUSTHAVE', color: '#999999' },
  { key: NODES_TYPE_ADD, label: 'NODE.DATASHEET.FIELDS.TYPE.ADD', color: '#999999' },
  { key: NODES_TYPE_CONNECT, label: 'NODE.DATASHEET.FIELDS.TYPE.CONNECT', color: '#435EC2' },
  { key: NODES_TYPE_DISCONNECT, label: 'NODE.DATASHEET.FIELDS.TYPE.DISCONNECT', color: '#999999' },
  { key: NODES_TYPE_SPLASHSCREEN, label: 'NODE.DATASHEET.FIELDS.TYPE.SPLASHSCREEN', color: '#999999' },
  { key: NODES_TYPE_WELCOME_POWER, label: 'NODE.DATASHEET.FIELDS.TYPE.POWER', color: '#999999' },
  { key: NODES_TYPE_WELCOME_REGULAR, label: 'NODE.DATASHEET.FIELDS.TYPE.REGULAR', color: '#999999' },
  { key: NODES_TYPE_WIDGET_ROW, label: 'NODE.DATASHEET.FIELDS.TYPE.WIDGETROW', color: '#999999' },
  { key: NODES_TYPE_FIELDS, label: 'NODE.DATASHEET.FIELDS.TYPE.FIELDS', color: '#999999' },
  { key: NODES_TYPE_FIELD, label: 'NODE.DATASHEET.FIELDS.TYPE.FIELD', color: '#999999' },
  { key: NODES_TYPE_METHODOLOGY, label: 'NODE.DATASHEET.FIELDS.TYPE.METHODOLOGY', color: '#999999' },
  { key: NODES_TYPE_ACTION, label: 'NODE.DATASHEET.FIELDS.TYPE.ACTION', color: '#999999' },
  { key: NODES_TYPE_SETUPACTION, label: 'NODE.DATASHEET.FIELDS.TYPE.SETUPACTION', color: '#999999' },
  { key: NODES_TYPE_OBJECTIVE, label: 'NODE.DATASHEET.FIELDS.TYPE.OBJECTIVE', color: '#ffc7b3' },
  { key: NODES_TYPE_CHALLENGE, label: 'NODE.DATASHEET.FIELDS.TYPE.CHALLENGE', color: '#1DD3B0' },
  { key: NODES_TYPE_DELIVERABLE, label: 'NODE.DATASHEET.FIELDS.TYPE.DELIVERABLE', color: '#8BF25D' },
  { key: NODES_TYPE_STRATEGYMAP, label: 'NODE.DATASHEET.FIELDS.TYPE.STRATEGYMAP', color: '#999999' },
  { key: NODES_TYPE_PROJECTPORTFOLIO, label: 'NODE.DATASHEET.FIELDS.TYPE.PROJECTPORTFOLIO', color: '#999999' },
  { key: NODES_TYPE_ACTIONGROUP, label: 'NODE.DATASHEET.FIELDS.TYPE.ACTIONGROUP', color: '#999999' },
  { key: NODES_TYPE_HOMEACTION, label: 'NODE.DATASHEET.FIELDS.TYPE.HOMEACTION', color: '#999999' },
  { key: NODES_TYPE_NODETYPEGROUP, label: 'NODE.DATASHEET.FIELDS.TYPE.NODETYPEGROUP', color: '#999999' },
  { key: NODES_TYPE_NEXT, label: 'NODE.DATASHEET.FIELDS.TYPE.NEXT', color: '#999999' },
  { key: NODES_TYPE_SORTING, label: 'NODE.DATASHEET.FIELDS.TYPE.SORTING', color: '#999999' },
  { key: NODES_TYPE_FACTSHEET, label: 'NODE.DATASHEET.FIELDS.TYPE.FACTSHEET', color: '#999999' },
  { key: NODES_TYPE_HUMANRESOURCE, label: 'NODE.DATASHEET.FIELDS.TYPE.HUMANRESOURCE', color: '#999999' },
  { key: NODES_TYPE_GROUP, label: 'NODE.DATASHEET.FIELDS.TYPE.GROUP', color: '#999999' },
  { key: NODES_TYPE_CAPABILITY, label: 'NODE.DATASHEET.FIELDS.TYPE.CAPABILITY', color: '#0079A2' },
  { key: NODES_TYPE_FILTERS, label: 'NODE.DATASHEET.FIELDS.TYPE.FILTERS', color: '#999999' },
  { key: NODES_TYPE_ORGANISATIONALUNIT, label: 'NODE.DATASHEET.FIELDS.TYPE.ORGANISATIONALUNIT', color: '#999999' },
  { key: NODES_TYPE_TEAM, label: 'NODE.DATASHEET.FIELDS.TYPE.TEAM', color: '#999999' },
  { key: NODES_TYPE_AI, label: 'NODE.DATASHEET.FIELDS.TYPE.AI', color: '#999999' },
  { key: NODES_TYPE_TEXTBLOCK, label: 'NODE.DATASHEET.FIELDS.TYPE.TEXTBLOCK', color: '#999999' },
  { key: NODES_TYPE_ANALYSE_STRUCTURE, label: 'NODE.DATASHEET.FIELDS.TYPE.ANALYSESTRUCTURE', color: '#999999' },
  { key: NODES_TYPE_ENTITY, label: 'NODE.DATASHEET.FIELDS.TYPE.ENTITY', color: '#999999' },
  { key: NODES_TYPE_ANALYSE, label: 'NODE.DATASHEET.FIELDS.TYPE.ANALYSE', color: '#999999' },
  { key: NODES_TYPE_ANALYSEFOLDER, label: 'NODE.DATASHEET.FIELDS.TYPE.ANALYSEFOLDER', color: '#999999' },
  { key: NODES_TYPE_KEYWORD, label: 'NODE.DATASHEET.FIELDS.TYPE.KEYWORD', color: '#999999' },
  { key: NODES_TYPE_COMMENT, label: 'NODE.DATASHEET.FIELDS.TYPE.COMMENT', color: '#999999' },
  { key: NODES_TYPE_REGION, label: 'NODE.DATASHEET.FIELDS.TYPE.REGION', color: '#4e0577' },
  { key: NODES_TYPE_RELATIONSHIP, label: 'NODE.DATASHEET.FIELDS.TYPE.RELATIONSHIP', color: '#999999' },
  { key: NODES_TYPE_EDIT, label: 'NODE.DATASHEET.FIELDS.TYPE.EDIT', color: '#999999' },
  { key: NODES_TYPE_PARENT, label: 'NODE.DATASHEET.FIELDS.TYPE.PARENT', color: '#999999' },
  { key: NODES_TYPE_CHILD, label: 'NODE.DATASHEET.FIELDS.TYPE.CHILD', color: '#999999' },
  { key: NODES_TYPE_GLOBALFILTER, label: 'NODE.DATASHEET.FIELDS.TYPE.GLOBALFILTER', color: '#999999' },
  { key: NODES_TYPE_PLANTS, label: 'NODE.DATASHEET.FIELDS.TYPE.PLANTS', color: '#003559' },
  { key: NODES_TYPE_FUNCTIONS, label: 'NODE.DATASHEET.FIELDS.TYPE.TASK', color: '#B7094C' },
  { key: NODES_TYPE_COLUMN, label: 'NODE.DATASHEET.FIELDS.TYPE.COLUMN', color: '#999999' },
  { key: NODES_TYPE_TASK, label: 'NODE.DATASHEET.FIELDS.TYPE.FUNCTIONS', color: '#999999' },
  { key: NODES_TYPE_CAPACITY, label: 'NODE.DATASHEET.FIELDS.TYPE.CAPACITY', color: '#999999' },
  { key: NODES_TYPE_DRAG, label: 'NODE.DATASHEET.FIELDS.TYPE.DRAG', color: '#999999' },
  { key: NODES_TYPE_DROP, label: 'NODE.DATASHEET.FIELDS.TYPE.DROP', color: '#999999' },
  { key: NODES_TYPE_COLORLABELPROVIDER, label: 'NODE.DATASHEET.FIELDS.TYPE.COLORLABELPROVIDER', color: '#999999' },
  { key: NODES_TYPE_COUNTERMEASURE, label: 'NODE.DATASHEET.FIELDS.TYPE.COUNTERMEASURE', color: '#999999' },
  { key: NODES_TYPE_SUM, label: 'NODE.DATASHEET.FIELDS.TYPE.SUM', color: '#999999' },
  { key: NODES_TYPE_GLOBALSUM, label: 'NODE.DATASHEET.FIELDS.TYPE.GLOBALSUM', color: '#999999' },
  { key: NODES_TYPE_IGNORE, label: 'NODE.DATASHEET.FIELDS.TYPE.IGNORE', color: '#999999' },
  { key: NODES_TYPE_UPDATE, label: 'NODE.DATASHEET.FIELDS.TYPE.UPDATE', color: '#999999' },
  { key: NODES_TYPE_INFRACTION, label: 'NODE.DATASHEET.FIELDS.TYPE.INFRACTION', color: '#999999' },
  { key: NODES_TYPE_GAP, label: 'NODE.DATASHEET.FIELDS.TYPE.GAP', color: '#c9182c' },
  { key: NODES_TYPE_SETTINGS, label: 'NODE.DATASHEET.FIELDS.TYPE.SETTINGS', color: '#999999' },
  { key: NODES_TYPE_SEARCH, label: 'NODE.DATASHEET.FIELDS.TYPE.SEARCH', color: '#ed625e' },
  { key: NODES_TYPE_SELECT, label: 'NODE.DATASHEET.FIELDS.TYPE.SELECT', color: '#999999' },
  { key: NODES_TYPE_TOGGLE, label: 'NODE.DATASHEET.FIELDS.TYPE.TOGGLE', color: '#999999' },
  { key: NODES_TYPE_WIDGET_HEADER, label: 'NODE.DATASHEET.FIELDS.TYPE.WIDGET_HEADER', color: '#999999' },
  { key: NODES_TYPE_WIDGET_FOOTER, label: 'NODE.DATASHEET.FIELDS.TYPE.WIDGET_FOOTER', color: '#999999' },
  { key: NODES_TYPE_PLACE_CHILDREN_ASIDE, label: 'NODE.DATASHEET.FIELDS.TYPE.PLACE_CHILDREN_ASIDE', color: '#999999' },
  { key: NODES_TYPE_CURVED_LINK, label: 'NODE.DATASHEET.FIELDS.TYPE.CURVED_LINK', color: '#999999' },
  { key: NODES_TYPE_LEGEND, label: 'NODE.DATASHEET.FIELDS.TYPE.LEGEND', color: '#999999' },
  { key: NODES_TYPE_FOOTER_TABS, label: 'NODE.DATASHEET.FIELDS.TYPE.FOOTER_TABS', color: '#999999' },
  { key: NODES_TYPE_FOCUS, label: 'NODE.DATASHEET.FIELDS.TYPE.FOCUS', color: '#999999' },
  { key: NODES_TYPE_STEP, label: 'NODE.DATASHEET.FIELDS.TYPE.STEP', color: '#999999' },
  { key: NODES_TYPE_ADD_ELEMENT, label: 'NODE.DATASHEET.FIELDS.TYPE.ADD_ELEMENT', color: '#999999' },
  { key: NODES_TYPE_ADD_CHILD, label: 'NODE.DATASHEET.FIELDS.TYPE.ADD_CHILD', color: '#999999' },
  { key: NODES_TYPE_LINK, label: 'NODE.DATASHEET.FIELDS.TYPE.LINK', color: '#999999' },
  { key: NODES_TYPE_RECEIVER, label: 'NODE.DATASHEET.FIELDS.TYPE.RECEIVER', color: '#999999' },
  { key: NODES_TYPE_STRUCTURE, label: 'NODE.DATASHEET.FIELDS.TYPE.STRUCTURE', color: '#999999' },
  { key: NODES_TYPE_ORGLEVEL_1, label: 'NODE.DATASHEET.FIELDS.TYPE.ORGLEVEL-1', color: '#999999' },
  { key: NODES_TYPE_ORGLEVEL_2, label: 'NODE.DATASHEET.FIELDS.TYPE.ORGLEVEL-2', color: '#999999' },
  { key: NODES_TYPE_ORGLEVEL_3, label: 'NODE.DATASHEET.FIELDS.TYPE.ORGLEVEL-3', color: '#999999' },
  { key: NODES_TYPE_WBS_PROGRAM, label: 'NODE.DATASHEET.FIELDS.TYPE.WBS-PROGRAM', color: '#B7B7A4' },
  { key: NODES_TYPE_WBS_WORKPACKAGE, label: 'NODE.DATASHEET.FIELDS.TYPE.WBS-WORKPACKAGE', color: '#F3F353' },
  { key: NODES_TYPE_WBS_ACTIVITY, label: 'NODE.DATASHEET.FIELDS.TYPE.WBS-ACTIVITY', color: '#f0f3bd' },
  { key: NODES_TYPE_WBS_SUBPROJECTS, label: 'NODE.DATASHEET.FIELDS.TYPE.WBS-SUBPROJECTS', color: '#fca311' },
  { key: NODES_TYPE_SKILLS, label: 'NODE.DATASHEET.FIELDS.TYPE.SKILLS', color: '#999999' },
  { key: NODES_TYPE_DEMAND, label: 'NODE.DATASHEET.FIELDS.TYPE.DEMAND', color: '#999999' },
  { key: NODES_TYPE_ASSET_MACHINE, label: 'NODE.DATASHEET.FIELDS.TYPE.ASSET-MACHINE', color: '#999999' },
  { key: NODES_TYPE_ASSET_MODULE, label: 'NODE.DATASHEET.FIELDS.TYPE.ASSET-MODULE', color: '#999999' },
  { key: NODES_TYPE_PRODUCTGROUP, label: 'NODE.DATASHEET.FIELDS.TYPE.PRODUCTGROUP', color: '#005182' },
  { key: NODES_TYPE_ASSET_GROUP, label: 'NODE.DATASHEET.FIELDS.TYPE.ASSET-GROUP', color: '#999999' },
  { key: NODES_TYPE_BADWORD, label: 'NODE.DATASHEET.FIELDS.TYPE.BADWORD', color: '#999999' },
  { key: NODES_TYPE_MEMBER, label: 'NODE.DATASHEET.FIELDS.TYPE.MEMBER', color: '#999999' },
  { key: NODES_TYPE_DUPLICATE, label: 'NODE.DATASHEET.FIELDS.TYPE.DUPLICATE', color: '#999999' },
  { key: NODES_TYPE_REVERSE, label: 'NODE.DATASHEET.FIELDS.TYPE.REVERSE', color: '#999999' },
  { key: NODES_TYPE_ROUNDED, label: 'NODE.DATASHEET.FIELDS.TYPE.ROUNDED', color: '#999999' },
  { key: NODES_TYPE_SUGGESTIONS, label: 'NODE.DATASHEET.FIELDS.TYPE.SUGGESTIONS', color: '#999999' },
  { key: NODES_TYPE_FULL, label: 'NODE.DATASHEET.FIELDS.TYPE.FULL', color: '#999999' },
  { key: NODES_TYPE_CENTER, label: 'NODE.DATASHEET.FIELDS.TYPE.CENTER', color: '#999999' },
  { key: NODES_TYPE_HIERARCHY, label: 'NODE.DATASHEET.FIELDS.TYPE.HIERARCHY', color: '#999999' },
  { key: NODES_TYPE_REMAINING, label: 'NODE.DATASHEET.FIELDS.TYPE.REMAINING', color: '#999999' },
  { key: NODES_TYPE_VERTICAL, label: 'NODE.DATASHEET.FIELDS.TYPE.VERTICAL', color: '#999999' },
  { key: NODES_TYPE_HORIZONTAL, label: 'NODE.DATASHEET.FIELDS.TYPE.HORIZONTAL', color: '#999999' },
  { key: NODES_TYPE_AXIS, label: 'NODE.DATASHEET.FIELDS.TYPE.AXIS', color: '#999999' },
  { key: NODES_TYPE_TABLEGROUPMONTHS, label: 'NODE.DATASHEET.FIELDS.TYPE.TABLEGROUPMONTHS', color: '#999999' },
  { key: NODES_TYPE_DEFAULTVALUES, label: 'NODE.DATASHEET.FIELDS.TYPE.DEFAULTVALUES', color: '#999999' },
  { key: NODES_TYPE_STRAIGHT_LINKS, label: 'NODE.DATASHEET.FIELDS.TYPE.STRAIGHT-LINKS', color: '#999999' },
  { key: NODES_TYPE_DIRECT_CHAIN, label: 'NODE.DATASHEET.FIELDS.TYPE.DIRECT-CHAIN', color: '#999999' },
  { key: NODES_TYPE_FORM_ROW, label: 'NODE.DATASHEET.FIELDS.TYPE.FORM-ROW', color: '#999999' },
  { key: NODES_TYPE_FORM_COLUMN, label: 'NODE.DATASHEET.FIELDS.TYPE.FORM-COLUMN', color: '#999999' },
  { key: NODES_TYPE_REMINDER, label: 'NODE.DATASHEET.FIELDS.TYPE.REMINDER', color: '#999999' },
  { key: NODES_TYPE_ASSET_POSITION, label: 'NODE.DATASHEET.FIELDS.TYPE.ASSET-POSITION', color: '#999999' },
  { key: NODES_TYPE_MANUFACTURING_SLOT, label: 'NODE.DATASHEET.FIELDS.TYPE.MANUFACTURING-SLOT', color: '#999999' },
  { key: NODES_TYPE_BY, label: 'NODE.DATASHEET.FIELDS.TYPE.BY', color: '#999999' },
  { key: NODES_TYPE_VALUE, label: 'NODE.DATASHEET.FIELDS.TYPE.VALUE', color: '#999999' },
  { key: NODES_TYPE_PAGINATION, label: 'NODE.DATASHEET.FIELDS.TYPE.PAGINATION', color: '#999999' },
  { key: NODES_TYPE_SLOT, label: 'NODE.DATASHEET.FIELDS.TYPE.SLOT', color: '#006DAA' },
  { key: NODES_TYPE_LINE, label: 'NODE.DATASHEET.FIELDS.TYPE.LINE', color: '#043D74' },
  { key: NODES_TYPE_MACHINE, label: 'NODE.DATASHEET.FIELDS.TYPE.MACHINE', color: '#33496D' },
  { key: NODES_TYPE_MODULE, label: 'NODE.DATASHEET.FIELDS.TYPE.MODULE', color: '#607899' },
  { key: NODES_TYPE_TRANSFER, label: 'NODE.DATASHEET.FIELDS.TYPE.TRANSFER', color: '#999999' },
  { key: NODES_TYPE_MUTATION, label: 'NODE.DATASHEET.FIELDS.TYPE.MUTATION', color: '#999999' },
  { key: NODES_TYPE_STANDARD_LINE, label: 'NODE.DATASHEET.FIELDS.TYPE.STANDARD-LINE', color: '#999999' },
  { key: NODES_TYPE_ORGLEVEL_6, label: 'NODE.DATASHEET.FIELDS.TYPE.ORGLEVEL-6', color: '#999999' },
  { key: NODES_TYPE_ORGLEVEL_7, label: 'NODE.DATASHEET.FIELDS.TYPE.ORGLEVEL-7', color: '#999999' },
  { key: NODES_TYPE_ORGLEVEL_5, label: 'NODE.DATASHEET.FIELDS.TYPE.ORGLEVEL-5', color: '#999999' },
  { key: NODES_TYPE_MY, label: 'NODE.DATASHEET.FIELDS.TYPE.MY', color: '#999999' },
  { key: NODES_TYPE_ROLLOUT, label: 'NODE.DATASHEET.FIELDS.TYPE.ROLLOUT', color: '#999999' },
  { key: NODES_TYPE_PROCESS, label: 'NODE.DATASHEET.FIELDS.TYPE.PROCESS', color: '#999999' },
  { key: NODES_TYPE_PROJECT_CATEGORY, label: 'NODE.DATASHEET.FIELDS.TYPE.PROJECT-CATEGORY', color: '#999999' },
];

export interface TreeRelationship {
  /* Ids */
  id: string;
  parentId?: string;
  childId?: string;
  originalParentId?: string;
  originalChildId?: string;
  modelId?: string;
  internalType?: string;
  updatedAt?: number;
  version_id?: number;
  /* Data */
  rel?: string;
  phantom?: boolean;
  weight?: number;
  condition?: number;
  category?: number;
  type?: number;
  filtered?: boolean;
  connected?: boolean;
  startDate?: string;
  endDate?: string;
  /* Additional parameter for in app usage */
  source?: TreeNode | string;
  target?: TreeNode | string;
}

export interface ExportExcel {
  sheet: { data: ExportExcelData[] }
}

export interface ExportExcelData {
  headers: ExportExcelCell[][],
  rows: ExportExcelCell[][],
  styling: string
}

export interface ExportExcelCell {
  value: string,
  type: string,
  colspan: number,
  rowspan: number
  bgcolor?: string,
  color?: string,
}

export interface CoreOptions {
  filters?: CoreFilter[];
  ignoreGlobalFilter?: boolean;
  ignoreBusinessArea?: boolean;
  ignoreMCM?: boolean;
  onlyMCM?: boolean;
  stopAt?: number[];
  makeImmutable?: boolean;
}

export interface CoreGlobalFilter {
  id: string;
  key: string;
  type: string;
  value: any;
  subtract?: boolean;
}

export interface CoreWidget extends GridsterItem {
  id: string;
  widgetId: string;
  fx: number;
  fy: number;
  hidden: string[];
  rowWeight: number;
  colWeight: number;
  businessAreaId: string;
  modelIds: string[];
  isGlobal: boolean;
  nodeFilterKey: string;
  methodology: TreeNode;
  nodes: Observable<TreeNode[]>;
  nodesWOGlobal: Observable<TreeNode[]>;
  nodesWMcm: Observable<TreeNode[]>;
  relationships: Observable<TreeRelationship[]>;
  humanResources: Observable<CoreHumanResource[]>;
  hierarchy: Observable<TreeNode[]>;
  legends: Observable<CoreLegend[]>;
  configuration: TreeNode;
  screen: DashboardScreenComponent;
  resize: EventEmitter<any>;
  cancel: EventEmitter<any>;
  sendMessages: EventEmitter<CoreWidgetMessage[]>;
  receiveMessages: BehaviorSubject<CoreWidgetMessage[]>;
  position: string; // For later usage to position the widget with the use of a grid system
  reset: boolean;
  useHierarchy: boolean;
}

export interface CoreWidgetMessage {
  id: string;
  sender: string;
  receiver?: string;
  data: any;
  toggleWidget?: boolean;
  storeToggle?: boolean;
  direct?: boolean;
}

export interface CoreTransfer {
  instanceId?: string;
  businessAreaId?: string;
  modelId?: string;

  businessArea?: Businessarea | IPayload;
  models?: ModelCreate[];
  activities?: TreeActivity[] | IPayload[] | Activity[] | string[];
  nodes: TreeNode[] | IPayload[] | NodeCreate[] | string[];
  relationships: TreeRelationship[] | IPayload[] | RelationshipCreate[] | string[];
  humanResources?: CoreHumanResource[] | IPayload[] | HumanResource[] | string[];
  groups?: CoreGroup[] | IPayload[] | Group[] | string[];
  nodeStructures?: TreeNode[] | IPayload[] | NodeCreate[] | string[];

  ids?: string[];
}

export interface CoreMultiTransfer {
  create: CoreTransfer;
  update: CoreTransfer;
  delete: CoreTransfer;
}

export interface CoreTransferResult {
  businessAreaId?: string;
  modelIds?: string[];
  nodeIds?: string[];
  relationshipIds: string[];
}

export interface CoreDrop {
  parentTreeNode: TreeNode;
  droppedTreeNode: TreeNode;
  defaultWeight: number;
}



export interface CoreConfiguration {
  widgets: CoreWidget[];
}

export interface CoreLegend {
  key: string;
  color: string;
  label: string;
  field: string;
  value: string;
  selected: boolean;
}

export interface CoreFilter {
  by: string;
  value: any | any[];
  nodeType?: number;
  directChain?: TreeNode;
}

export interface CoreNodeType {
  key: number;
  label: string;
  color: string;
}

export interface CoreSorting {
  field: string;
  direction: number;
}

export interface CoreModule {
  id: string;
  name: string;
  description: string;
  color: string;
  background: string;
  type: string;
  icon: string;
  businessarea_id?: string;
  actions ?: CoreAction[];
}

export interface CoreUser {
  id: string;
  name: string;
  role: string;
  image: string;
  superUser: boolean;
  humanResources: any[];
  permissions: string[];
  settings: any;
}

export interface CoreActionGroup {
  id: string;
  name: string;
  actions: CoreAction[];
}

export interface CoreAction {
  id: string;
  name: string;
  description: string;
  color: string;
  type: string;
  background: string;
  icon: string;
  className: string;
}

export interface CoreInstance {
  id: string;
  name: string;
  type: number;
}

export interface CoreLabelProvider {
  key: string;
  label: string;
}

export interface CoreModel {
  id: string;
  name: string;
  versionname: string;
  reference: string;
  crossReference: string;
  description: string;
  color: string;
  date: string;
  startDate: string;
  status: number;
  commercialStatus: number;
  updatedAt: number;
  template: string;
  settings: any;
  humanresource: number;
  targetpicture: string;
  hypothesis: string;
  abbreviation: string;
  duplicate_original_id?: number;
  sorting: number;
  onstrategyradar: boolean;
  onreport: boolean;
  onprogramm: number;
  parentModel: string;
  active: boolean;
  type: number;
  icon: string;
}

export interface CoreHumanResource {
  id: number;
  email?: string;
  name?: string;
  first_name?: string;
  last_name?: string;
  color?: string;
  can_login?: boolean;
  foreign_id?: number;
  updatedAt?: number;
  permissions?: any;
  notification_settings?: any;
  storypoints?: number;
  instanceId?: string;
  image?: string;
}

export interface CoreGroup {
  id: string;
  name?: string;
  humanResources?: string[];
}

export interface CoreSocketData {
  data: any[];
  included: any[];
}

export interface CoreExportOptions {
  header?: string;
  pageNumber?: boolean;
  footer?: string;
  screenCSS?: boolean;
  portrait?: boolean;
}

export interface CoreStatusItem {
  text: string;
  color: string;
}

export interface CoreWidgetFilter {
  id: string;
  field: string;
  label: string;
  checkboxes: CoreWidgetFilterCheckbox[];
}

export interface CoreWidgetFilterCheckbox {
  id: string;
  label: string;
  value: any;
  checked: boolean;
  treeNode: TreeNode;
}

export const CoreStatus = {
  0: <CoreStatusItem> { text: 'STATUS.PENDING', color: '#999999' },
  100: <CoreStatusItem> { text: 'STATUS.INDEFINE', color: '#000000' },
  110: <CoreStatusItem> { text: 'STATUS.INDEVELOP', color: '#1099d6' },
  120: <CoreStatusItem> { text: 'STATUS.INVERIFY', color: '#aa008f' },
  130: <CoreStatusItem> { text: 'STATUS.INACCEPT', color: '#edb922' },
  200: <CoreStatusItem> { text: 'STATUS.COMPLETED', color: '#9ac93e' }
};

export const CoreCountries = [
  { id: 'CHN', name: 'China' },
  { id: 'IND', name: 'India' },
  { id: 'USA', name: 'United States' },
  { id: 'IDN', name: 'Indonesia' },
  { id: 'BRA', name: 'Brazil' },
  { id: 'PAK', name: 'Pakistan' },
  { id: 'BGD', name: 'Bangladesh' },
  { id: 'NGA', name: 'Nigeria' },
  { id: 'RUS', name: 'Russia' },
  { id: 'JPN', name: 'Japan' },
  { id: 'MEX', name: 'Mexico' },
  { id: 'PHL', name: 'Philippines' },
  { id: 'VNM', name: 'Vietnam' },
  { id: 'ETH', name: 'Ethiopia' },
  { id: 'DEU', name: 'Germany' },
  { id: 'EGY', name: 'Egypt' },
  { id: 'TUR', name: 'Turkey' },
  { id: 'COD', name: '"Congo, Democratic Republic of the"' },
  { id: 'IRN', name: 'Iran' },
  { id: 'THA', name: 'Thailand' },
  { id: 'FRA', name: 'France' },
  { id: 'GBR', name: 'United Kingdom' },
  { id: 'ITA', name: 'Italy' },
  { id: 'MMR', name: 'Burma' },
  { id: 'ZAF', name: 'South Africa' },
  { id: 'KOR', name: '"Korea, South"' },
  { id: 'UKR', name: 'Ukraine' },
  { id: 'COL', name: 'Colombia' },
  { id: 'SDN', name: 'Sudan' },
  { id: 'TZA', name: 'Tanzania' },
  { id: 'ARG', name: 'Argentina' },
  { id: 'ESP', name: 'Spain' },
  { id: 'KEN', name: 'Kenya' },
  { id: 'POL', name: 'Poland' },
  { id: 'DZA', name: 'Algeria' },
  { id: 'CAN', name: 'Canada' },
  { id: 'UGA', name: 'Uganda' },
  { id: 'MAR', name: 'Morocco' },
  { id: 'PER', name: 'Peru' },
  { id: 'IRQ', name: 'Iraq' },
  { id: 'SAU', name: 'Saudi Arabia' },
  { id: 'AFG', name: 'Afghanistan' },
  { id: 'NPL', name: 'Nepal' },
  { id: 'UZB', name: 'Uzbekistan' },
  { id: 'VEN', name: 'Venezuela' },
  { id: 'MYS', name: 'Malaysia' },
  { id: 'GHA', name: 'Ghana' },
  { id: 'YEM', name: 'Yemen' },
  { id: 'TWN', name: 'Taiwan' },
  { id: 'PRK', name: '"Korea, North"' },
  { id: 'SYR', name: 'Syria' },
  { id: 'ROU', name: 'Romania' },
  { id: 'MOZ', name: 'Mozambique' },
  { id: 'AUS', name: 'Australia' },
  { id: 'LKA', name: 'Sri Lanka' },
  { id: 'MDG', name: 'Madagascar' },
  { id: 'CIV', name: 'Cote d Ivoire' },
  { id: 'CMR', name: 'Cameroon' },
  { id: 'NLD', name: 'Netherlands' },
  { id: 'CHL', name: 'Chile' },
  { id: 'BFA', name: 'Burkina Faso' },
  { id: 'NER', name: 'Niger' },
  { id: 'KAZ', name: 'Kazakhstan' },
  { id: 'MWI', name: 'Malawi' },
  { id: 'ECU', name: 'Ecuador' },
  { id: 'KHM', name: 'Cambodia' },
  { id: 'SEN', name: 'Senegal' },
  { id: 'MLI', name: 'Mali' },
  { id: 'GTM', name: 'Guatemala' },
  { id: 'AGO', name: 'Angola' },
  { id: 'ZMB', name: 'Zambia' },
  { id: 'ZWE', name: 'Zimbabwe' },
  { id: 'CUB', name: 'Cuba' },
  { id: 'RWA', name: 'Rwanda' },
  { id: 'GRC', name: 'Greece' },
  { id: 'PRT', name: 'Portugal' },
  { id: 'TUN', name: 'Tunisia' },
  { id: 'TCD', name: 'Chad' },
  { id: 'BEL', name: 'Belgium' },
  { id: 'GIN', name: 'Guinea' },
  { id: 'CZE', name: 'Czech Republic' },
  { id: 'SOM', name: 'Somalia' },
  { id: 'BOL', name: 'Bolivia' },
  { id: 'HUN', name: 'Hungary' },
  { id: 'BDI', name: 'Burundi' },
  { id: 'DOM', name: 'Dominican Republic' },
  { id: 'BLR', name: 'Belarus' },
  { id: 'HTI', name: 'Haiti' },
  { id: 'SWE', name: 'Sweden' },
  { id: 'BEN', name: 'Benin' },
  { id: 'AZE', name: 'Azerbaijan' },
  { id: 'AUT', name: 'Austria' },
  { id: 'HND', name: 'Honduras' },
  { id: 'CHE', name: 'Switzerland' },
  { id: 'TJK', name: 'Tajikistan' },
  { id: 'ISR', name: 'Israel' },
  { id: 'SRB', name: 'Serbia' },
  { id: 'BGR', name: 'Bulgaria' },
  { id: 'HKG', name: 'Hong Kong' },
  { id: 'LAO', name: 'Laos' },
  { id: 'LBY', name: 'Libya' },
  { id: 'JOR', name: 'Jordan' },
  { id: 'PRY', name: 'Paraguay' },
  { id: 'TGO', name: 'Togo' },
  { id: 'PNG', name: 'Papua New Guinea' },
  { id: 'SLV', name: 'El Salvador' },
  { id: 'NIC', name: 'Nicaragua' },
  { id: 'ERI', name: 'Eritrea' },
  { id: 'DNK', name: 'Denmark' },
  { id: 'KGZ', name: 'Kyrgyzstan' },
  { id: 'SVK', name: 'Slovakia' },
  { id: 'FIN', name: 'Finland' },
  { id: 'SLE', name: 'Sierra Leone' },
  { id: 'ARE', name: 'United Arab Emirates' },
  { id: 'TKM', name: 'Turkmenistan' },
  { id: 'CAF', name: 'Central African Republic' },
  { id: 'SGP', name: 'Singapore' },
  { id: 'NOR', name: 'Norway' },
  { id: 'BIH', name: 'Bosnia and Herzegovina' },
  { id: 'GEO', name: 'Georgia' },
  { id: 'CRI', name: 'Costa Rica' },
  { id: 'HRV', name: 'Croatia' },
  { id: 'MDA', name: 'Moldova' },
  { id: 'NZL', name: 'New Zealand' },
  { id: 'IRL', name: 'Ireland' },
  { id: 'COG', name: '"Congo, Republic of the"' },
  { id: 'LBN', name: 'Lebanon' },
  { id: 'PRI', name: 'Puerto Rico' },
  { id: 'LBR', name: 'Liberia' },
  { id: 'ALB', name: 'Albania' },
  { id: 'LTU', name: 'Lithuania' },
  { id: 'URY', name: 'Uruguay' },
  { id: 'PAN', name: 'Panama' },
  { id: 'MRT', name: 'Mauritania' },
  { id: 'MNG', name: 'Mongolia' },
  { id: 'OMN', name: 'Oman' },
  { id: 'ARM', name: 'Armenia' },
  { id: 'JAM', name: 'Jamaica' },
  { id: 'KWT', name: 'Kuwait' },
  { id: 'PSE', name: 'West Bank' },
  { id: 'LVA', name: 'Latvia' },
  { id: 'NAM', name: 'Namibia' },
  { id: 'MKD', name: 'Macedonia' },
  { id: 'BWA', name: 'Botswana' },
  { id: 'SVN', name: 'Slovenia' },
  { id: 'LSO', name: 'Lesotho' },
  { id: 'GMB', name: '"Gambia, The"' },
  { id: 'KWT', name: 'Kosovo' },
  { id: '149', name: 'Gaza Strip' },
  { id: 'GNB', name: 'Guinea-Bissau' },
  { id: 'GAB', name: 'Gabon' },
  { id: 'SWZ', name: 'Swaziland' },
  { id: '153', name: 'Mauritius' },
  { id: 'EST', name: 'Estonia' },
  { id: 'TTO', name: 'Trinidad and Tobago' },
  { id: 'TLS', name: 'Timor-Leste' },
  { id: 'CYP', name: 'Cyprus' },
  { id: 'FJI', name: 'Fiji' },
  { id: 'QAT', name: 'Qatar' },
  { id: '160', name: 'Comoros' },
  { id: 'GUY', name: 'Guyana' },
  { id: 'DJI', name: 'Djibouti' },
  { id: '163', name: 'Bahrain' },
  { id: 'BTN', name: 'Bhutan' },
  { id: 'MNE', name: 'Montenegro' },
  { id: 'GNQ', name: 'Equatorial Guinea' },
  { id: 'SLB', name: 'Solomon Islands' },
  { id: '168', name: 'Macau' },
  { id: '169', name: 'Cape Verde' },
  { id: 'LUX', name: 'Luxembourg' },
  { id: 'ESH', name: 'Western Sahara' },
  { id: 'SUR', name: 'Suriname' },
  { id: '173', name: 'Malta' },
  { id: '174', name: 'Maldives' },
  { id: 'BRN', name: 'Brunei' },
  { id: 'BLZ', name: 'Belize' },
  { id: 'BHS', name: '"Bahamas, The"' },
  { id: 'ISL', name: 'Iceland' },
  { id: '179', name: 'French Polynesia' },
  { id: '180', name: 'Barbados' },
  { id: '181', name: 'Mayotte' },
  { id: 'NCL', name: 'New Caledonia' },
  { id: '183', name: 'Netherlands Antilles' },
  { id: 'VUT', name: 'Vanuatu' },
  { id: '185', name: 'Samoa' },
  { id: '186', name: 'Sao Tome and Principe' },
  { id: '187', name: 'Saint Lucia' },
  { id: '188', name: 'Tonga' },
  { id: '189', name: 'Virgin Islands' },
  { id: '190', name: 'Grenada' },
  { id: '191', name: '"Micronesia, Federated States of"' },
  { id: '192', name: 'Aruba' },
  { id: '193', name: 'Saint Vincent and the Grenadines' },
  { id: '194', name: 'Kiribati' },
  { id: '195', name: 'Jersey' },
  { id: '196', name: 'Seychelles' },
  { id: '197', name: 'Antigua and Barbuda' },
  { id: '198', name: 'Andorra' },
  { id: '199', name: 'Isle of Man' },
  { id: 'DOM', name: 'Dominica' },
  { id: '201', name: 'Bermuda' },
  { id: '202', name: 'American Samoa' },
  { id: '203', name: 'Marshall Islands' },
  { id: '204', name: 'Guernsey' },
  { id: 'GRL', name: 'Greenland' },
  { id: '206', name: 'Cayman Islands' },
  { id: '207', name: 'Saint Kitts and Nevis' },
  { id: '208', name: 'Faroe Islands' },
  { id: '209', name: 'Northern Mariana Islands' },
  { id: '210', name: 'Liechtenstein' },
  { id: '211', name: 'San Marino' },
  { id: '212', name: 'Monaco' },
  { id: '213', name: 'Saint Martin' },
  { id: '214', name: 'Gibraltar' },
  { id: '215', name: 'British Virgin Islands' },
  { id: '216', name: 'Turks and Caicos Islands' },
  { id: '217', name: 'Palau' },
  { id: '218', name: 'Akrotiri' },
  { id: '219', name: 'Dhekelia' },
  { id: '220', name: 'Wallis and Futuna' },
  { id: '221', name: 'Anguilla' },
  { id: '222', name: 'Nauru' },
  { id: '223', name: 'Cook Islands' },
  { id: '224', name: 'Tuvalu' },
  { id: '225', name: '"Saint Helena, Ascension, and Tristan da Cunha"' },
  { id: '226', name: 'Saint Barthelemy' },
  { id: '227', name: 'Saint Pierre and Miquelon' },
  { id: '228', name: 'Montserrat' },
  { id: 'FLK', name: 'Falkland Islands (Islas Malvinas)' },
  { id: '230', name: 'Norfolk Island' },
  { id: '231', name: 'Svalbard' },
  { id: '232', name: 'Christmas Island' },
  { id: '233', name: 'Tokelau' },
  { id: '234', name: 'Niue' },
  { id: '235', name: 'Holy See (Vatican City)' },
  { id: '236', name: 'Cocos (Keeling) Islands' },
  { id: '237', name: 'Pitcairn Islands' },
  { id: 'ATA', name: 'Antarctica' },
  { id: 'ATF', name: 'French Southern and Antarctic Lands' },
  { id: 'SDS', name: 'South Sudan' },
  { id: 'ABV', name: 'Somaliland' },
  { id: 'OSA', name: 'Kosovo' }
];

