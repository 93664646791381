import { Utils } from '../shared/utils';
import { Action } from '../shared/common.action';
import { GuardianState } from './guardian.models';
import { GuardianAction } from './guardian.actions';

export default function reducer(state = new GuardianState(), action: Action): GuardianState {

  switch (action.type) {

    case GuardianAction.FIND: {
      return <GuardianState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case GuardianAction.FIND_SUCCESS: {
      return <GuardianState>Utils.updateDiff<GuardianState>(state, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case GuardianAction.FIND_FAIL: {
      return <GuardianState>Utils.updateDiff<GuardianState>(state, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    default:
      return state;
  }
}
