import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { API } from '../connector';
import { WorkflowAction } from './workflow.actions';
import { Observable } from 'rxjs/Observable';
import { JSONAPIResponse } from '../connector';
import { Action } from '../shared/common.action';

@Injectable()
export class WorkflowEffects {

  @Effect() loadWorkflow$ = this.actions$
    .pipe(ofType(WorkflowAction.LOAD))
    .mergeMap((action: Action) => this.api.workflow.find(action.payload.id)
      .mergeMap((response: JSONAPIResponse<any>) => {
          return Observable.from([this.workflowAction.loadSuccess([response.toPayload()])]);
        }
      )).catch((error: any) => Observable.of(this.workflowAction.loadFail(error)));

  @Effect() loadWorkflows$ = this.actions$
    .pipe(ofType(WorkflowAction.LOAD_ALL))
    .mergeMap((action: Action) => this.api.workflow.get()
      .mergeMap((response: JSONAPIResponse<any>) => {
          return Observable.from([this.workflowAction.loadAllSuccess([response])]);
        }
      )).catch((error: any) => Observable.of(this.workflowAction.loadAllFail(error)));


  constructor(private api: API,
              private actions$: Actions,
              private workflowAction: WorkflowAction) {
  }
}
