import { Store } from '@ngrx/store';

import { APIState } from '../reducer';
import { commonSelectors } from '../shared';
import { compose } from '../../utilities/compose';
import { GeneralState } from './general.models';

export function getState() {
    return (state$: Store<APIState>) => state$
        .select((s: APIState) => s.general);
}

export function getIsBusy() {
  return compose(commonSelectors.getIsBusy(), getState());
}

export function getHealyMembers() {
  return compose((state$: Store<GeneralState>) => state$.select(s => s.healyMembers), getState());
}
