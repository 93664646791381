import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { TreeNode } from '../../../core/interface/core.interface';
import { isNullOrUndefined } from 'util';
import { TranslateService } from '@ngx-translate/core';

export class ColorLabelProviderServiceValidated extends ColorLabelProviderAbstract {

  private static FIELD = 'commercialStatus';

  protected colors = {
    validated: '#c00000',
    disregard: '#1df00b',
    counterMeasureSent: '#cf00e7',
    counterMeasureAcknowledged: '#a5f0e8',
    complianceConcurred: '#d1f0c0',
    complianceVerified: '#1df00b'
  };

  public constructor(protected configurationNode?: TreeNode) {
    super();
    /* Configuration node */
    if (!isNullOrUndefined(configurationNode)) {
      this.configure();
    }
  }

  public color(element: TreeNode, column: any, originalElement: TreeNode) {
    if (!isNullOrUndefined(originalElement)) {
      element = originalElement;
    }
    const value = element[ColorLabelProviderServiceValidated.FIELD];
    if (!isNullOrUndefined(value)) {
      switch (value) {
        case -2:
          return [this.colors.complianceVerified];
        case -1:
          return [this.colors.disregard];
        case 1:
          return [this.colors.validated];
        case 2:
          return [this.colors.counterMeasureSent];
        case 3:
          return [this.colors.counterMeasureAcknowledged];
        case 4:
          return [this.colors.complianceConcurred];
        case 5:
          return [this.colors.complianceVerified];
        default:
          return super.color();
      }
      // return value === 1 ? [ColorLabelProviderServiceValidated.VALIDATED] : (value === -1 ? [ColorLabelProviderServiceValidated.DISREGARD] : super.color());
    }
    return super.color();
  }

  protected configure() {
    /* Replace values */
    const count = this.configurationNode.children.length;
    for (let i = 0; i < count; i++) {
      const child = this.configurationNode.children[i];
      if (!isNullOrUndefined(this.colors[child.formId]) && !isNullOrUndefined(child.color) && child.color !== '') {
        this.colors[child.formId] = child.color;
      }
    }
  }

}
