import { List } from 'immutable';

import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { Node } from '../../../../shared/api/nodes';
import { LegendItem } from '../../../../shared/legend/legend.item';

export class ColorLabelProviderServiceTargetDate extends ColorLabelProviderAbstract {

  private static DUEDATEPAST = '#E50000';
  private static INTIME = '#9ac93e';

  public color(element: Node) {
    if (element.targetDate !== null) {
      if (new Date(element.targetDate) < new Date() && element.status !== 200) {
        return [ColorLabelProviderServiceTargetDate.DUEDATEPAST];
      } else {
        return [ColorLabelProviderServiceTargetDate.INTIME];
      }
    }
    return super.color();
  }

  public legend() {
    return List<LegendItem>([
      new LegendItem({ key: 'targetdate.na', color: super.color(), label: 'LEGEND.NOTAPPLICABLE', field: 'targetDate', value: 0 }),
      new LegendItem({ key: 'targetdate.duedatepast', color: ColorLabelProviderServiceTargetDate.DUEDATEPAST, label: 'LEGEND.TARGETDATE.DUEDATEPAST', field: 'targetDate', value: 1 }),
      new LegendItem({ key: 'targetdate.intime', color: ColorLabelProviderServiceTargetDate.INTIME, label: 'LEGEND.TARGETDATE.INTIME', field: 'targetDate', value: 2 })
    ]);
  }

  public calculate(element: Node) {
    if (element.targetDate !== null) {
      if (new Date(element.targetDate) < new Date() && element.status !== 200) {
        return 1;
      } else {
        return 2;
      }
    }
    return 1;
  }

}
