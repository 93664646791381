import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';

import { ImportAction } from './import.actions';
import { Action } from '../shared/common.action';
import { GoAPI } from '../connector/shared/api.go.service';

@Injectable()
export class ImportEffects {

  @Effect() import$ = this.actions$
    .pipe(ofType(ImportAction.IMPORT))
    .mergeMap((action: Action) => this.api.import().post(action.payload.data).mergeMap(response => Observable.from([
      this.importAction.importSuccess()
    ])).catch((error: any) => Observable.of(this.importAction.importFail())));

  constructor(private api: GoAPI,
              private actions$: Actions,
              private importAction: ImportAction) {
  }
}
