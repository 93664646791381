import { Injectable } from '@angular/core';
import { IImportScheme, IImportSchemeAttributes } from '../connector';

import { ImportScheme } from './importschemes.models';
import { IPayload } from '../shared/common.models';
import { Action } from '../shared/common.action';

@Injectable()
export class ImportSchemeAction {
  static key = 'ImportScheme';

  static LOAD = `[${ImportSchemeAction.key}] Load`;
  static LOAD_SUCCESS = `[${ImportSchemeAction.key}] Load success`;
  static LOAD_FAIL = `[${ImportSchemeAction.key}] Load fail`;

  static CREATE = `[${ImportSchemeAction.key}] Create`;
  static CREATE_SUCCESS = `[${ImportSchemeAction.key}] Create success`;
  static CREATE_FAIL = `[${ImportSchemeAction.key}] Create fail`;

  static UPDATE = `[${ImportSchemeAction.key}] Update`;
  static UPDATE_SUCCESS = `[${ImportSchemeAction.key}] Update success`;
  static UPDATE_FAIL = `[${ImportSchemeAction.key}] Update fail`;

  static REMOVE = `[${ImportSchemeAction.key}] Remove`;
  static REMOVE_SUCCESS = `[${ImportSchemeAction.key}] Remove success`;
  static REMOVE_FAIL = `[${ImportSchemeAction.key}] Remove fail`;

  public load(businessareaId: string): Action {
    return {
      type: ImportSchemeAction.LOAD,
      payload: {
        businessareaId: businessareaId
      }
    };
  }

  public loadSuccess(data: IImportScheme[]): Action {
    return {
      type: ImportSchemeAction.LOAD_SUCCESS,
      payload: data
    };
  }

  public loadFail(error: any): Action {
    return {
      type: ImportSchemeAction.LOAD_FAIL,
      payload: error
    };
  }

  public create(businessareaId: string, data: IImportScheme | IImportScheme[]): Action {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: ImportSchemeAction.CREATE,
      payload: {
        businessareaId: businessareaId,
        data: data
      }
    };
  }

  public createSuccess(payload: IPayload, response: IImportScheme): Action {
    return {
      type: ImportSchemeAction.CREATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public createFail(payload: IPayload, error: any): Action {
    return {
      type: ImportSchemeAction.CREATE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public update(id: number, data: IImportSchemeAttributes): Action {
    return {
      type: ImportSchemeAction.UPDATE,
      payload: {
        id: id,
        data: data
      }
    };
  }

  public updateSuccess(payload: IPayload, response: IImportScheme): Action {
    return {
      type: ImportSchemeAction.UPDATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public updateFail(payload: IPayload, error: any): Action {
    return {
      type: ImportSchemeAction.UPDATE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public remove(id: string | string[]): Action {
    if (!Array.isArray(id)) {
        id = [id];
    }
    return {
      type: ImportSchemeAction.REMOVE,
      payload: {
        id: id
      }
    };
  }

  public removeSuccess(payload: IPayload, response: IImportScheme): Action {
    return {
      type: ImportSchemeAction.REMOVE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public removeFail(payload: IPayload, error: any): Action {
    return {
      type: ImportSchemeAction.REMOVE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public autorefreshUpdate(data: any): Action {
    return {
      type: ImportSchemeAction.UPDATE_SUCCESS,
      payload: data
    };
  }

  public autorefreshCreate(data: any): Action {
    return {
      type: ImportSchemeAction.CREATE_SUCCESS,
      payload: data
    };
  }

  public autorefreshRemove(data: any): Action {
    return {
      type: ImportSchemeAction.REMOVE_SUCCESS,
      payload: data
    };
  }
}
