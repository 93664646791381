import { Record } from 'immutable';
import { isNullOrUndefined } from 'util';
import { UUID } from 'angular2-uuid';

import { Relationship } from '../../../shared/api/index';
import { TreeNode } from './tree.node';

const treeRelationshipRecord = Record({
  id: undefined,
  uuid: undefined,
  relationship: undefined,
  parent: undefined,
  child: undefined,
  rel: 'relationship-',
  phantom: false,
  toCreate: false,
  invalid: false,
  weight: { value: 0, width: 0 },
  x1: 0,
  y1: 0,
  x2: 0,
  y2: 0
});

export class TreeRelationship extends treeRelationshipRecord {
  public id: string;
  public uuid: string;
  public relationship: Relationship;
  public parent: string;
  public child: string;
  public rel: string;
  public phantom: boolean;
  public toCreate: boolean;
  public invalid: boolean;
  public weight: any;
  public x1: number;
  public y1: number;
  public x2: number;
  public y2: number;

  public constructor(relationship?: Relationship) {
    const uuid = UUID.UUID();
    super(isNullOrUndefined(relationship) ? {
      id: uuid,
      uuid: 'relationship-0-' + uuid
    } : {
      id: relationship.id,
      uuid: 'relationship-' + relationship.updatedAt + '-' + relationship.id,
      parent: relationship.relationships.parent,
      child: relationship.relationships.child,
      rel: 'relationship-' + relationship.id,
      weight: { value: (Math.round(parseFloat(relationship.weight) * 100) / 100), width: 0 },
      relationship: relationship
    });
  }
}
