export const TemplateFieldsReportsHeatmap = [
  {
    type: 'table',
    settings: {},
    configuration: {
      fields: [
        { entry: { key: '', label: '', controlType: 'group'}, children: [
            {
              entry: { key: 'header1', label: 'NODE.DATASHEET.HEADERS.HEADER1' },
              children: [
                { entry: { key: 'status', controlType: 'dropdown', label: 'STATUS.TITLE', options: [
                      { key: 0, value: 'STATUS.PENDING' },
                      { key: 100, value: 'STATUS.INDEFINE' },
                      { key: 110, value: 'STATUS.INDEVELOP' },
                      { key: 120, value: 'STATUS.INVERIFY' },
                      { key: 130, value: 'STATUS.INACCEPT' },
                      { key: 200, value: 'STATUS.COMPLETED' }
                    ]}},
                { entry: { key: 'responsibleId', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.RESPONSIBLEID', hasNull: true, source: 'humanresources' }}
              ]
            },
            {
              entry: { key: 'group2', label: 'NODE.DATASHEET.GROUPS.GROUP2', controlType: 'group' },
              children: [
                { entry: { key: 'budget', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.KPI' }},
                { entry: { key: 'budgetCalculated', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.AGGREGATIONKPI' }}
              ]
            },
            {
              entry: { key: 'group3', label: 'NODE.DATASHEET.GROUPS.GROUP3', controlType: 'group' },
              children: [
                { entry: { key: 'benefitBudget', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.BENEFITBUDGET' }},
                { entry: { key: 'benefitActual', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.BENEFITACTUAL' }},
                { entry: { key: 'benefitRemaining', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.BENEFITREMAINING' }}
              ]
            },
            {
              entry: { key: 'group4', label: 'NODE.DATASHEET.GROUPS.GROUP4', controlType: 'group' },
              children: [
                { entry: { key: 'costBudget', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.COSTBUDGET' }},
                { entry: { key: 'costActual', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.COSTACTUAL' }},
                { entry: { key: 'costRemaining', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.COSTREMAINING' }}
              ]
            },
            {
              entry: { key: 'group5', label: 'NODE.DATASHEET.GROUPS.GROUP5', controlType: 'group' },
              children: [
                { entry: { key: 'investBudget', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.INVESTBUDGET' }},
                { entry: { key: 'investActual', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.INVESTACTUAL' }},
                { entry: { key: 'investRemaining', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.INVESTREMAINING' }}
              ]
            }
          ]}
      ]
    }
  }
];
