import { ISubset, ISubsetAttributes } from '../connector';
import { Record, Set, Map } from 'immutable';
import { RecordFactory, RequestDiffRecord } from '../shared';

const subsetStateRecord = Record({
  ids: Set<string>(),
  entities: Map<string, Subset>(),
  relationships: Map<string, SubsetRelationships>(),
  diff: new RequestDiffRecord(),
  isBusy: 0
});

export class SubsetState extends subsetStateRecord {
  ids: Set<string>;
  entities: Map<string, Subset>;
  relationships: Map<string, SubsetRelationships>;
  diff: RequestDiffRecord;
  isBusy: number;
}

const subsetRecord = Record(<ISubsetAttributes>{
  id: undefined,
  name: undefined,
  reference: '',
  crossReference: '',
  description: '',
  color: undefined,
  date: undefined,
  status: 0,
  commercialStatus: 0,
  updatedAt: undefined,

  relationships: undefined
});

export class Subset extends subsetRecord implements ISubsetAttributes {
  id: string;
  name: string;
  reference: string;
  crossReference: string;
  description: string;
  color: string;
  date: string;
  status: number;
  commercialStatus: number;
  updatedAt: number;

  relationships: SubsetRelationships;

  static fromJSONAPI(subset: ISubset) {
    return RecordFactory.entity.fromJSONAPI<Subset>(Subset, subset);
  }
}

const relationshipsRecord = Record({
  nodestructures: Set<string>(),
  relationships: Set<string>(),
  humanresource: null,
  tags: Set<string>(),
  template: undefined,
  model: undefined
});

export class SubsetRelationships extends relationshipsRecord {
  nodestructures: Set<string>;
  relationships: Set<string>;
  humanresource: string;
  tags: Set<string>;
  template: string;
  model: string;

  static fromJSONAPI(subset: ISubset) {
    return RecordFactory.relationships.fromJSONAPI<SubsetRelationships>(SubsetRelationships, subset);
  }
}
