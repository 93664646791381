import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { TreeNode } from '../../../core/interface/core.interface';
import { Datum } from '../../../shared/utilities/datum';

export class ColorLabelProviderServiceTargetDate extends ColorLabelProviderAbstract {

  private static DUEDATEPAST = '#E50000';
  private static INTIME = '#9ac93e';

  public color(element: TreeNode) {
    if (element.targetDate !== null) {
      const datum = new Datum(element.targetDate);
      if (datum.date < new Date() && element.status !== 200) {
        return [ColorLabelProviderServiceTargetDate.DUEDATEPAST];
      } else {
        return [ColorLabelProviderServiceTargetDate.INTIME];
      }
    }
    return super.color();
  }

  public legend() {
    return [
      { key: 'targetdate.na', color: super.color(), label: 'LEGEND.NOTAPPLICABLE', field: 'targetDate', value: 0 },
      { key: 'targetdate.duedatepast', color: ColorLabelProviderServiceTargetDate.DUEDATEPAST, label: 'LEGEND.TARGETDATE.DUEDATEPAST', field: 'targetDate', value: 1 },
      { key: 'targetdate.intime', color: ColorLabelProviderServiceTargetDate.INTIME, label: 'LEGEND.TARGETDATE.INTIME', field: 'targetDate', value: 2 }
    ];
  }

  public calculate(element: TreeNode) {
    if (element.targetDate !== null) {
      if (new Date(element.targetDate) < new Date() && element.status !== 200) {
        return 1;
      } else {
        return 2;
      }
    }
    return 1;
  }

}
