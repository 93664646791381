import { INotification, INotificationAttributes } from '../connector';
import { Record, Set, Map } from 'immutable';
import { RecordFactory, RequestDiffRecord } from '../shared';

const notificationStateRecord = Record({
    ids: Set<string>(),
    entities: Map<string, Notification>(),
    isBusy: 0,
    diff: new RequestDiffRecord()
});

export class NotificationState extends notificationStateRecord {
    ids: Set<string>;
    entities: Map<string, Notification>;
    isBusy: number;
    diff: RequestDiffRecord;
}

const notificationRecord = Record(<INotificationAttributes>{
    id: undefined,
    userId: 0,
    logId: 0,
    read: false,
    rolledBack: false,
    log: undefined
});

export class Notification extends notificationRecord implements INotificationAttributes {
    id: number;
    userId: number;
    logId: number;
    read: boolean;
    rolledBack: boolean;
    log: any;

    static fromJSONAPI(notification: INotification) {
        return RecordFactory.entity.fromJSONAPI<Notification>(Notification, notification);
    }
}
