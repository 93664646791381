import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { ColorLabelProviderLegend } from '../colorlabelprovider.interface';
import { TreeNode } from '../../../core/interface/core.interface';

export class ColorLabelProviderServiceDifference extends ColorLabelProviderAbstract {

  private static LOW = '#9ac93e';
  private static MEDIUM = '#EDD515';
  private static HIGH = '#E50000';

  public color(treeNode: TreeNode) {
    switch (this.calculate(treeNode)) {
      case 1:
        return [ColorLabelProviderServiceDifference.HIGH];
      case 2:
        return [ColorLabelProviderServiceDifference.MEDIUM];
      case 3:
        return [ColorLabelProviderServiceDifference.LOW];
      default:
        return super.color();
    }
  }

  public legend() {
    return <ColorLabelProviderLegend[]>([
      { key: 'difference.low', color: ColorLabelProviderServiceDifference.LOW, label: 'LEGEND.DIFFERENCE.LOW', field: 'difference', value: 3 },
      { key: 'difference.medium', color: ColorLabelProviderServiceDifference.MEDIUM, label: 'LEGEND.DIFFERENCE.MEDIUM', field: 'difference', value: 2 },
      { key: 'difference.high', color: ColorLabelProviderServiceDifference.HIGH, label: 'LEGEND.DIFFERENCE.HIGH', field: 'difference', value: 1 }
    ]);
  }

  public calculate(treeNode: TreeNode) {
    let result = treeNode.budget - treeNode.budgetCalculated;
    const negative = result < 0;
    result = treeNode.budget === 0 ? 100 : (Math.abs(result) / treeNode.budget) * 100;
    result = negative ? -result : result;
    return result <= -30 ? 1 : (result < 0 ? 2 : 3);
  }

}
