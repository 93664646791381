import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as selectors from './guardian.selectors';
import { GuardianAction } from './guardian.actions';
import { SharedService } from '../shared/shared.service';
import { AppState } from '../../app.reducer';
import { GuardianData } from './guardian.models';

@Injectable()
export class GuardianService extends SharedService {

  public constructor(protected store: Store<AppState>, protected action: GuardianAction) {
    super(store, action, selectors);
  }

  public findInfractions(guardianData: GuardianData): void {
    this.store.dispatch(this.action.find(guardianData));
  }

}
