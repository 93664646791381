import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';

import { API, GoAPI } from '../connector';
import { JSONAPIResponse } from '../connector';
import { ModelAction } from '../models/models.actions';
import { NodeStructureAction } from './nodestructures.actions';
import { INodeStructure } from '../connector';
import { NodeDataAction } from '../nodedata/nodedata.actions';
import { Action } from '../shared/common.action';
import { RelationshipAction } from '../relationships';
import { TemplateAction } from '../templates/templates.actions';
import { OneTimeTokenAction } from '../onetimetoken';

@Injectable()
export class NodeStructureEffects {
  private concurrency = 5;

  @Effect() fetchNodeStructures$ = this.actions$
    .pipe(ofType(NodeStructureAction.LOAD))
    .mergeMap((action: Action) => this.api.model(action.payload.modelId).nodestructures.include(['nodedata']).findAll()
      .mergeMap((response: JSONAPIResponse<INodeStructure[]>) => {
        return Observable.from([
          this.nodeStructureAction.loadSuccess(response.toPayload()),
          this.nodeDataAction.loadSuccess(response.toIncludedByType('nodedata'))
        ]);
      })
    ).catch((error: any) => Observable.of(this.nodeStructureAction.loadFail(error)));

  @Effect() createNodeStructure$ = this.actions$
    .pipe(ofType(NodeStructureAction.CREATE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.api.model(action.payload.id).nodestructures.bulkCreate(action.payload.data).mergeMap(response => Observable.from([
      this.nodeStructureAction.createSuccess(action.payload, response.toPayload())
    ])).catch((error: any) => Observable.of(this.nodeStructureAction.createFail(action.payload, error))));

  @Effect() createNodeStructureGo$ = this.actions$
    .pipe(ofType(NodeStructureAction.CREATE_GO))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.apiGo.model(action.payload.id).nodestructures.bulkCreate(action.payload.data).mergeMap(response => Observable.from([
      this.nodeStructureAction.createSuccess(action.payload, response.toPayload())
    ])).catch((error: any) => Observable.of(this.nodeStructureAction.createFail(action.payload, error))));


  @Effect() removeNodeStructure$ = this.actions$
    .pipe(ofType(NodeStructureAction.REMOVE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.api.nodestructures.bulkRemove(action.payload.data).mergeMap(response => Observable.from([
      this.nodeStructureAction.removeSuccess(action.payload, response.toPayload())
    ])).catch((error: any) => Observable.of(this.nodeStructureAction.removeFail(action.payload, error))));

  @Effect() removeNodeStructureGo$ = this.actions$
    .pipe(ofType(NodeStructureAction.REMOVE_GO))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.apiGo.nodestructures.bulkRemove(action.payload.data).mergeMap(response => Observable.from([
      this.nodeStructureAction.removeSuccess(action.payload, response.toPayload())
    ])).catch((error: any) => Observable.of(this.nodeStructureAction.removeFail(action.payload, error))));


  @Effect() updateNodeStructure$ = this.actions$
    .pipe(ofType(NodeStructureAction.UPDATE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.api.nodestructures.bulkUpdate(action.payload.data).mergeMap(response => Observable.from([
      this.nodeStructureAction.updateSuccess(action.payload, response.toPayload())
    ])).catch((error: any) => Observable.of(this.nodeStructureAction.updateFail(action.payload, error))));

  @Effect() updateNodeStructureGo$ = this.actions$
    .pipe(ofType(NodeStructureAction.UPDATE_GO))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.apiGo.nodestructures.bulkUpdate(action.payload.data).mergeMap(response => Observable.from([
      this.nodeStructureAction.updateSuccess(action.payload, response.toPayload())
    ])).catch((error: any) => Observable.of(this.nodeStructureAction.updateFail(action.payload, error))));


  @Effect() tagNodeStructure$ = this.actions$
    .pipe(ofType(NodeStructureAction.TAG))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.data.map(payload => this.api.nodestructures.tag(payload, action.payload.subset)
      .mergeMap(response => Observable.from([
        this.nodeStructureAction.updateSuccess(payload, [response.toPayload()])
      ]))
      .catch((error: any) => Observable.of(this.nodeStructureAction.updateFail(payload, error)))
    )))
    .mergeAll(this.concurrency);

  @Effect() untagNodeStructure$ = this.actions$
    .pipe(ofType(NodeStructureAction.UNTAG))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.data.map(payload => this.api.nodestructures.untag(payload, action.payload.subset)
      .mergeMap(response => Observable.from([
        this.nodeStructureAction.updateSuccess(payload, [response.toPayload()])
      ]))
      .catch((error: any) => Observable.of(this.nodeStructureAction.updateFail(payload, error)))
    )))
    .mergeAll(this.concurrency);

  @Effect() loadWithToken$ = this.actions$
    .pipe(ofType(NodeStructureAction.LOAD_WITH_TOKEN))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.api.nodestructures.include(action.payload.includes).findWithToken(action.payload.id, action.token)
      .mergeMap((response: JSONAPIResponse<INodeStructure>) => {
        return Observable.from([
          this.nodeStructureAction.loadSuccess(response.toPayload()),
          this.nodeDataAction.loadSuccess(response.toIncludedByType('nodedata')),
          this.templateAction.loadSuccess(response.toIncludedByType('templates'))
        ]);
      })
    ).catch((error: any) => Observable.of(this.nodeStructureAction.loadFail(error)));

  @Effect() fetchTokenForNodeStructure$ = this.actions$
    .pipe(ofType(NodeStructureAction.GET_TOKEN))
    .mergeMap((action: Action) => this.api.nodestructures.getOneTimeToken(action.payload.id, action.payload.formNodeId)
      .mergeMap((response: JSONAPIResponse<INodeStructure[]>) => {
        return Observable.from([
          this.oneTimeTokenAction.loadSuccess(action.payload, response.toPayload()),
        ]);
      })
    ).catch((error: any) => Observable.of(this.oneTimeTokenAction.loadFail(error)));

  constructor(private api: API,
    private apiGo: GoAPI,
    private actions$: Actions,
    private nodeStructureAction: NodeStructureAction,
    private nodeDataAction: NodeDataAction,
    private templateAction: TemplateAction,
    private oneTimeTokenAction: OneTimeTokenAction,
    private relationshipAction: RelationshipAction,
    private modelAction: ModelAction) {
  }
}
