export const TemplateFieldsMyMenu = {
  splitscreen: {
    reports: {
      cawecocompliance: false,
      clevelheatmap: false,
      compliancestatus: false,
      estimation: false,
      heatmap: false,
      kpis: false,
      modelstatus: false,
      objective: false,
      objectivemodel: false,
      objectivemodelmeasures: false,
      objectivebusinessarea: false,
      objectivebusinessareameasures: false,
      projectoverview: false,
      ilg: false,
      roi: false,
      sadt: false,
      stack: false,
      strategicfitness: false,
      structured: false,
      teamstatus: false,
      scheduleddistribution: false,
      pomoverview: false,
      status: false,
      overview: false,
      initiativeupdate: false,
      initiativeinventory: false,
      effectcontrolling: false,
      kpi: false,
      focusmaster: false,
      focuspmo: false,
      strategyoverview: false,
      changeit: false,
      rollout: false,
      factories: false
    }
  }
};
