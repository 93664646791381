export const TemplateFieldsAggregations = {
  tabs: [
    {
      entry: { key: 'cb_header', label: 'Cost Benefit' },
      children: [
        { entry: { key: 'budget', controlType: 'text', direction: 'both', label: 'NODE.DATASHEET.FIELDS.KPI'}},
        { entry: { key: 'budget_calculated', controlType: 'text', direction: 'both', label: 'NODE.DATASHEET.FIELDS.AGGREGATIONKPI'}},
        { entry: { key: 'cb_benefit_budget', controlType: 'text', direction: 'both', label: 'NODE.DATASHEET.FIELDS.BENEFITBUDGET'}},
        { entry: { key: 'cb_benefit_actual', controlType: 'text', direction: 'both', label: 'NODE.DATASHEET.FIELDS.BENEFITACTUAL'}},
        { entry: { key: 'cb_benefit_remaining', controlType: 'text', direction: 'both', label: 'NODE.DATASHEET.FIELDS.BENEFITREMAINING'}},
        { entry: { key: 'cb_cost_budget', controlType: 'text', direction: 'both', label: 'NODE.DATASHEET.FIELDS.COSTBUDGET'}},
        { entry: { key: 'cb_cost_actual', controlType: 'text', direction: 'both', label: 'NODE.DATASHEET.FIELDS.COSTACTUAL'}},
        { entry: { key: 'cb_cost_remaining', controlType: 'text', direction: 'both', label: 'NODE.DATASHEET.FIELDS.COSTREMAINING'}},
        { entry: { key: 'cb_invest_budget', controlType: 'text', direction: 'both', label: 'NODE.DATASHEET.FIELDS.INVESTBUDGET'}},
        { entry: { key: 'cb_invest_actual', controlType: 'text', direction: 'both', label: 'NODE.DATASHEET.FIELDS.INVESTACTUAL'}},
        { entry: { key: 'cb_invest_remaining', controlType: 'text', direction: 'both', label: 'NODE.DATASHEET.FIELDS.INVESTREMAINING'}}
      ]
    },
    {
      entry: { key: 'status_header', label: 'Status' },
      children: [
        { entry: { key: 'status_type', controlType: 'text', direction: 'both', label: 'STATUS.TITLE'}},
        { entry: { key: 'priority', controlType: 'text', direction: 'both', label: 'NODE.DATASHEET.FIELDS.PRIORITY'}},
        { entry: { key: 'percComplete', controlType: 'text', direction: 'both', label: 'NODE.DATASHEET.FIELDS.PERCENTAGECOMPLETE'}},
        { entry: { key: 'size_type', controlType: 'text', direction: 'both', label: 'NODE.DATASHEET.FIELDS.SIZETYPE'}},
        { entry: { key: 'complexity_type', controlType: 'text', direction: 'both', label: 'NODE.DATASHEET.FIELDS.COMPLEXITY'}},
        { entry: { key: 'risk_type', controlType: 'text', direction: 'both', label: 'NODE.DATASHEET.FIELDS.RISK'}},
        { entry: { key: 'business_benefit_type', controlType: 'text', direction: 'both', label: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.TITLE'}},
        { entry: { key: 'uncertainty_type', controlType: 'text', direction: 'both', label: 'NODE.DATASHEET.FIELDS.UNCERTAINTY'}},
        { entry: { key: 'time', controlType: 'text', direction: 'both', label: 'NODE.DATASHEET.FIELDS.TIME'}},
        { entry: { key: 'cost', controlType: 'text', direction: 'both', label: 'NODE.DATASHEET.FIELDS.COST'}},
        { entry: { key: 'quality', controlType: 'text', direction: 'both', label: 'NODE.DATASHEET.FIELDS.QUALITY'}},
        { entry: { key: 'executive', controlType: 'text', direction: 'both', label: 'NODE.DATASHEET.FIELDS.EXECUTIVE.TITLE'}},
        { entry: { key: 'program', controlType: 'text', direction: 'both', label: 'NODE.DATASHEET.FIELDS.PROGRAM.TITLE'}},
        { entry: { key: 'functional', controlType: 'text', direction: 'both', label: 'NODE.DATASHEET.FIELDS.FUNCTIONAL.TITLE'}},
        { entry: { key: 'resource', controlType: 'text', direction: 'both', label: 'NODE.DATASHEET.FIELDS.RESOURCE.TITLE'}},
        { entry: { key: 'organizational', controlType: 'text', direction: 'both', label: 'NODE.DATASHEET.FIELDS.ORGANIZATIONAL'}},
        { entry: { key: 'technical', controlType: 'text', direction: 'both', label: 'NODE.DATASHEET.FIELDS.TECHNICAL'}}
      ]
    },
    {
      entry: { key: 'time_header', label: 'Time' },
      children: [
        { entry: { key: 'target_date', controlType: 'text', direction: 'both', label: 'NODE.DATASHEET.FIELDS.TARGETDATE'}},
        { entry: { key: 'target_date_actual', controlType: 'text', direction: 'both', label: 'NODE.DATASHEET.FIELDS.ACTUALDATE'}}
      ]
    },
    {
      entry: { key: 'unused', label: 'Not Aggregated' },
      children: [
      ]
    }
  ]
};
