import { Map } from 'immutable';

import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { TreeNode } from '../../../core/interface/core.interface';

export class ColorLabelProviderLevels extends ColorLabelProviderAbstract {

  protected levels = Map<number, any>();

  constructor() {
    super();
    this.levels = this.levels
      .set(0, { key: '0', color: '#ED6A5A', label: 'LEVEL.OBJECTIVE' })
      .set(1, { key: '1', color: '#F4F1BB', label: 'LEVEL.CHALLENGE' })
      .set(2, { key: '2', color: '#9BC1BC', label: 'LEVEL.SOLUTION' })
      .set(3, { key: '3', color: '#5CA4A9', label: 'LEVEL.DELIVERABLE' });
  }

  public color(treeNode: TreeNode) {
    if (this.levels.has(treeNode.level)) {
      return [this.levels.get(treeNode.level).color];
    } else {
      return super.color();
    }
  }

  public legend() {
    return this.levels.map(level => ({ key: 'level.' + level.key, color: level.color, label: level.label, field: 'level', value: parseInt(level.key) })).toArray();
  }

}
