import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { Activity } from '../../../../shared/api/activities';

export class ColorLabelProviderServiceActivityStatus extends ColorLabelProviderAbstract {

  private static PENDING = '#999999';
  private static PLANNED = '#1099D6';
  private static INEXEC = '#000000';
  private static SUCCOMP = '#9ac93e';
  private static UNSUCC = '#AA008F';
  private static CONDSUCC = '#FFA500';

  private static LABELDEF = '#000000';
  private static LABELINEXEC = '#FFFFFF';

  public color(element?: Activity) {
    switch (element.status) {
      case 0:
        return ColorLabelProviderServiceActivityStatus.PENDING;
      case 1:
        return ColorLabelProviderServiceActivityStatus.PLANNED;
      case 2:
        return ColorLabelProviderServiceActivityStatus.INEXEC;
      case 3:
        return ColorLabelProviderServiceActivityStatus.SUCCOMP;
      case 4:
        return ColorLabelProviderServiceActivityStatus.UNSUCC;
      case 5:
        return ColorLabelProviderServiceActivityStatus.CONDSUCC;
      default:
        return super.color();
    }
  }

  public label(element?: Activity) {

    if (!element) {
      return ColorLabelProviderServiceActivityStatus.LABELDEF;
    }

    switch (element.status) {
      case 2:
        return ColorLabelProviderServiceActivityStatus.LABELINEXEC;
      default:
        return ColorLabelProviderServiceActivityStatus.LABELDEF;
    }
  }

}

