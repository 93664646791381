export const TemplateFieldsReportsIlg = [
  {
    type: 'table',
    settings: {
      /* Show total row at the end of the table */
      showTotals: false,
      /* Show total row after level group */
      showTotalsAfterLevel: false,
      /* Group the elements by model */
      groupByModels: false,
      /* Show top most header row (grouped heading) */
      showHeading: false,
      /* The header text color */
      headerTextColor: '#ffffff',
      /* The header background color */
      headerBackgroundColor: '#9ac93e',
      /* Use a thousand seperator for numeric values */
      thousandsSeperator: false,
      /* How many decimals should be shown on numeric values */
      decimalnumbers: 2,
      /* A filter function to filter by */
      filter: 'onreports',
      /* Trigger to load the Businessarea with that table */
      loadBusinessarea: false,
      /* Mark that the table is built from activites */
      activities: true,
      /* Exports */
      exports: [], //  excel, pdf
    },
    configuration: {
      fields: [
        {
          entry: { key: '', controlType: 'group', label: ''},
          children: [
            { entry: { key: 'actions', controlType: 'text', label: 'REPORTS.ILG.ACTION', editable: false, defaultEntry: getActions } },
            { entry: { key: 'name', controlType: 'textBox', label: 'REPORTS.ILG.COUNTRY', editable: false } },
            { entry: { key: 'description1', controlType: 'text', label: 'REPORTS.ILG.EMBARGOSANCTIONS', editable: false } },
            { entry: { key: 'name', controlType: 'textBox', label: 'REPORTS.ILG.INSURANCETYPE', editable: false, activity: true } },
            { entry: { key: 'description', controlType: 'text', label: 'REPORTS.ILG.REGSUMMARY', editable: false, activity: true } },
            { entry: { key: 'timeDescription', controlType: 'text', label: 'REPORTS.ILG.REGREQUIREMENTS', editable: false, activity: true } },
            { entry: { key: 'qualityDescription', type: 'text', controlType: 'text', label: 'REPORTS.ILG.COMPULSORY', editable: false, activity: true }}
          ]
        }
      ]
    }
  }
];

export function getActions(element) {
  const editId = 'edit-entry-' + element.node.id;
  return '' +
    '<p class="edit-entry" id="' + editId + '" data-elementid="' + element.node.id + '">edit</p>' +
    '<p class="ilg-referral-link" id="' + 'ilg-referral-link-' + element.node.id + '" data-elementid="' + element.node.id + '">ILG referral</p>';
}

export const TemplateFieldsReportsIlgDatasheet = {
  tabs: [
    {
      entry: { key: 'header1', label: 'REPORTS.ILG.DATASHEET.HEADERS.MAIN' },
      children: [
        { entry: { key: 'country', type: 'text', controlType: 'textbox', label: 'REPORTS.ILG.COUNTRY', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME' }},
        { entry: { key: 'operationalEntity', type: 'text', controlType: 'text', label: 'REPORTS.ILG.OPERATIONALENTITYEXISTS' }},
        { entry: { key: 'embargoSanctions', type: 'text', controlType: 'text', label: 'REPORTS.ILG.EMBARGOSANCTIONS' }},
        { entry: { key: 'exchangeControl', type: 'text', controlType: 'text', label: 'REPORTS.ILG.EXCHANGECONTROL' }},
        // { entry: { key: 'lastUpdated', type: 'text', controlType: 'text', label: 'REPORTS.ILG.LASTUPDATED' }},
      ]
    },
    {
      entry: { key: 'header2', label: 'REPORTS.ILG.DATASHEET.HEADERS.DIRECT' },
      children: [
        { entry: { key: 'direct_regulatorySummary', type: 'text', controlType: 'text', label: 'REPORTS.ILG.REGSUMMARY' }},
        { entry: { key: 'direct_regulatoryReq', type: 'text', controlType: 'text', label: 'REPORTS.ILG.REGREQUIREMENTS' }},
        { entry: { key: 'direct_exceptions', type: 'text', controlType: 'text', label: 'REPORTS.ILG.EXCEPTIONS' }},
        { entry: { key: 'direct_compulsoryInsurance', type: 'text', controlType: 'text', label: 'REPORTS.ILG.COMPULSORY' }},
        { entry: { key: 'direct_registeredForOE1', type: 'text', controlType: 'text', label: 'REPORTS.ILG.REGISTERED.OE1' }},
        { entry: { key: 'direct_registeredForOE2', type: 'text', controlType: 'text', label: 'REPORTS.ILG.REGISTERED.OE2' }},
        { entry: { key: 'direct_registeredForOE3', type: 'text', controlType: 'text', label: 'REPORTS.ILG.REGISTERED.OE3' }},
        { entry: { key: 'direct_registeredForOE4', type: 'text', controlType: 'text', label: 'REPORTS.ILG.REGISTERED.OE4' }},
        { entry: { key: 'direct_registeredForOE5', type: 'text', controlType: 'text', label: 'REPORTS.ILG.REGISTERED.OE5' }},
        { entry: { key: 'direct_registeredForOE6', type: 'text', controlType: 'text', label: 'REPORTS.ILG.REGISTERED.OE6' }},
      ]
    },
    {
      entry: { key: 'header3', label: 'REPORTS.ILG.DATASHEET.HEADERS.RE' },
      children: [
        { entry: { key: 're_regulatorySummary', type: 'text', controlType: 'text', label: 'REPORTS.ILG.REGSUMMARY' }},
        { entry: { key: 're_regulatoryReq', type: 'text', controlType: 'text', label: 'REPORTS.ILG.REGREQUIREMENTS' }},
        { entry: { key: 're_exceptions', type: 'text', controlType: 'text', label: 'REPORTS.ILG.EXCEPTIONS' }},
        { entry: { key: 're_compulsoryInsurance', type: 'text', controlType: 'text', label: 'REPORTS.ILG.COMPULSORY' }},
        { entry: { key: 're_registeredForOE1', type: 'text', controlType: 'text', label: 'REPORTS.ILG.REGISTERED.OE1' }},
        { entry: { key: 're_registeredForOE2', type: 'text', controlType: 'text', label: 'REPORTS.ILG.REGISTERED.OE2' }},
        { entry: { key: 're_registeredForOE3', type: 'text', controlType: 'text', label: 'REPORTS.ILG.REGISTERED.OE3' }},
        { entry: { key: 're_registeredForOE4', type: 'text', controlType: 'text', label: 'REPORTS.ILG.REGISTERED.OE4' }},
        { entry: { key: 're_registeredForOE5', type: 'text', controlType: 'text', label: 'REPORTS.ILG.REGISTERED.OE5' }},
        { entry: { key: 're_registeredForOE6', type: 'text', controlType: 'text', label: 'REPORTS.ILG.REGISTERED.OE6' }},
      ]
    },
  ]
};
