import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { List } from 'immutable';

import { AppState } from '../../app.reducer';
import { IPayload } from '../shared';

import * as selectors from './groups.selectors';
import { Group } from './groups.models';
import { GroupAction } from './groups.actions';
import { SharedService } from '../shared/shared.service';

@Injectable()
export class GroupService extends SharedService {

  public constructor(protected store: Store<AppState>, protected action: GroupAction) {
    super(store, action, selectors);
  }

  public findByInstanceId(id: string) {
    return this.all().map((groups: List<Group>) => groups.filter(group => group.relationships.instance === id).toList());
  }

  public createOnInstance(id: string, payload: IPayload | IPayload[]) {
    this.store.dispatch(this.action.create(id, payload));
  }
  public assign(payload: IPayload | IPayload[]) {
    this.store.dispatch(this.action.assign(payload));
  }

  public unassign(payload: IPayload | IPayload[]) {
    this.store.dispatch(this.action.unassign(payload));
  }

}
