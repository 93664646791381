import { IAction, ISuccessAction } from '../shared/common.interfaces';
import { Action } from '../shared/common.action';

export class MyAction {
  static key = 'My';

  static FIND = `[${MyAction.key}] Find`;
  static FIND_SUCCESS = `[${MyAction.key}] Find success`;
  static FIND_FAIL = `[${MyAction.key}] Find fail`;

  public find(businessAreaId: string): IAction {
    return {
      type: MyAction.FIND,
      payload: {
        data: businessAreaId
      }
    };
  }

  public findSuccess(response): ISuccessAction {
    return {
      type: MyAction.FIND_SUCCESS,
      payload: {
        request: undefined,
        response: response
      }
    };
  }

  public findFail(error): Action {
    return {
      type: MyAction.FIND_FAIL,
      payload: {
        request: undefined,
        response: error
      }
    };
  }

}
