import { List } from 'immutable';

import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { environment } from '../../../../../environments/environment';
import { Node } from '../../../../shared/api/nodes';
import { LegendItem } from '../../../../shared/legend/legend.item';

export class ColorLabelProviderServiceQStatus extends ColorLabelProviderAbstract {

  private static LOW = '#9ac93e';
  private static MEDIUM = '#EDD515';
  private static HIGH = '#E50000';

  public color(element: Node, value?: any) {
    if (environment.template.qstatusdiff) {
      if (element.level === 0) {
        switch (element.get(value)) {
          case 1:
            return [ColorLabelProviderServiceQStatus.LOW];
          case 2:
            return [ColorLabelProviderServiceQStatus.MEDIUM];
          case 3:
            return [ColorLabelProviderServiceQStatus.HIGH];
        }
      }
      if (element.level === 2) {
        switch (element.riskProfileAssessment) {
          case 1:
            return [ColorLabelProviderServiceQStatus.LOW];
          case 2:
            return [ColorLabelProviderServiceQStatus.MEDIUM];
          case 3:
            return [ColorLabelProviderServiceQStatus.HIGH];
        }
      }
    } else {
      switch (element.get(value)) {
        case 1:
          return [ColorLabelProviderServiceQStatus.LOW];
        case 2:
          return [ColorLabelProviderServiceQStatus.MEDIUM];
        case 3:
          return [ColorLabelProviderServiceQStatus.HIGH];
      }
    }
    return super.color();
  }

  public legend(field?: any) {
    return List<LegendItem>([
      new LegendItem({ key: 'qstatus.low', color: ColorLabelProviderServiceQStatus.LOW, label: 'LEGEND.QSTATUS.LOW', field: field, value: 1 }),
      new LegendItem({ key: 'qsatus.medium', color: ColorLabelProviderServiceQStatus.MEDIUM, label: 'LEGEND.QSTATUS.MEDIUM', field: field, value: 2 }),
      new LegendItem({ key: 'qstatus.high', color: ColorLabelProviderServiceQStatus.HIGH, label: 'LEGEND.QSTATUS.HIGH', field: field, value: 3 })
    ]);
  }

}
