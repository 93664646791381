import { List, Map, Set } from 'immutable';

import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { HumanResource } from '../../../../shared/api/humanresources';
import { Node } from '../../../../shared/api/nodes';
import { TreeNode } from '../../tree/tree.node';
import { LegendItem } from '../../../../shared/legend/legend.item';

export class ColorLabelProviderServiceResponsible extends ColorLabelProviderAbstract {

  private humanResourcesMap = Map<string, HumanResource>();

  public constructor(private humanResources: List<HumanResource>) {
    super();
    this.humanResources.forEach(humanResource => this.humanResourcesMap = this.humanResourcesMap.set('' + humanResource.id, humanResource));
  }

  public color(element: Node) {
    if (this.humanResourcesMap.has('' + element.responsibleId)) {
      return [this.humanResourcesMap.get('' + element.responsibleId).color];
    }

    return super.color();
  }

  public legend(extNodes: Map<string, TreeNode>) {
    let responsibleSet = Set<string>();
    extNodes.forEach(extNode => responsibleSet = responsibleSet.add('' + extNode.node.responsibleId));
    return List<LegendItem>(this.humanResourcesMap
      .filter(humanResource => responsibleSet.has('' + humanResource.id))
      .map(humanResource => new LegendItem({ key: 'responsible.' + humanResource.id, color: humanResource.color, label: humanResource.name, field: 'responsibleId', value: humanResource.id }))
      .toArray());
  }

}
