import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { API, GoAPI, INodeData, JSONAPIResponse } from '../connector';
import { Observable } from 'rxjs/Observable';

import { NodeDataAction } from '../nodedata/nodedata.actions';
import { CommonEffects } from '../shared/common.effects';
import { Action } from '../shared/common.action';
import { AuditAction } from '../audits';

@Injectable()
export class NodeDataEffects {

  @Effect() loadNodeData$ = this.actions$
    .pipe(ofType(NodeDataAction.LOAD))
    .mergeMap((action: Action) => this.api.nodedata
      .include(action.payload.includes)
      .find(action.payload.id)
      .mergeMap((response: JSONAPIResponse<INodeData>) =>
        this.commonEffects.getObservables(this.nodeDataAction.loadSuccess, action.payload.includes, response)
      )
    ).catch((error: any) => Observable.of(this.nodeDataAction.loadFail(error)));

  @Effect() updateNodeData$ = this.actions$
    .pipe(ofType(NodeDataAction.UPDATE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => {
      return this.api.
        nodedata.bulkUpdate(action.payload.data)
        .mergeMap(response => Observable.from([
          this.nodeDataAction.updateSuccess(action.payload, response.toPayload())
        ])).catch((error: any) => Observable.of(this.nodeDataAction.updateFail(action.payload, error)));
    });

    @Effect() updateNodeDataGo$ = this.actions$
    .pipe(ofType(NodeDataAction.UPDATE_GO))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => {
      return this.apiGo.
        nodedata.bulkUpdate(action.payload.data)
        .mergeMap(response => Observable.from([
          this.nodeDataAction.updateSuccess(action.payload, response.toPayload())
        ])).catch((error: any) => Observable.of(this.nodeDataAction.updateFail(action.payload, error)));
    });

  @Effect() updateNodeDataWithToken$ = this.actions$
    .pipe(ofType(NodeDataAction.UPDATE_WITH_TOKEN))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.api.nodedata.updateWithToken(action.payload.id, action.payload.data, action.token).mergeMap(response => Observable.from([
      this.nodeDataAction.updateSuccess(action.payload, response.toPayload())
    ])).catch((error: any) => Observable.of(this.nodeDataAction.updateFail(action.payload, error))));

  @Effect() loadNodeDataAudits$ = this.actions$
    .pipe(ofType(NodeDataAction.LOAD_AUDITS))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.api.nodedata.audits(action.payload.id, action.payload.data).mergeMap(response => Observable.from([
      this.auditAction.loadSuccess(action.payload, response.data)
    ])).catch((error: any) => Observable.of(this.auditAction.loadFail(error))));

  @Effect() loadNodeDataByTypes$ = this.actions$
    .pipe(ofType(NodeDataAction.LOAD_BY_TYPES))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.api.nodedata.findByType(action.payload).mergeMap(response => Observable.from([
      this.nodeDataAction.loadByTypesSuccess(response.data)
    ])).catch((error: any) => Observable.of(this.nodeDataAction.loadByTypesFail(error))));


  constructor(private api: API,
    private apiGo: GoAPI,
    private actions$: Actions,
    private commonEffects: CommonEffects,
    private nodeDataAction: NodeDataAction,
    private auditAction: AuditAction) {
  }
}
