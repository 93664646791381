import { IVersion } from '../connector';

import { Action } from '../shared/common.action';
import { IPayload } from '../shared';

export class VersionAction {
  static key = 'Version';

  static LOAD = `[${VersionAction.key}] Load`;
  static LOAD_SUCCESS = `[${VersionAction.key}] Load success`;
  static LOAD_FAIL = `[${VersionAction.key}] Load fail`;

  static CREATE = `[${VersionAction.key}] Create on model`;
  static CREATE_SUCCESS = `[${VersionAction.key}] Create success`;
  static CREATE_FAIL = `[${VersionAction.key}] Create fail`;

  static REMOVE = `[${VersionAction.key}] Remove`;
  static REMOVE_SUCCESS = `[${VersionAction.key}] Remove success`;
  static REMOVE_FAIL = `[${VersionAction.key}] Remove fail`;

  static ROLLBACK = `[${VersionAction.key}] Rollback`;
  static ROLLBACK_SUCCESS = `[${VersionAction.key}] Rollback success`;
  static ROLLBACK_FAIL = `[${VersionAction.key}] Rollback fail`;

  public load(): Action {
    return {
      type: VersionAction.LOAD,
    };
  }

  public loadSuccess(data: IVersion[]): Action {
    return {
      type: VersionAction.LOAD_SUCCESS,
      payload: data
    };
  }

  public loadFail(error: any): Action {
    return {
      type: VersionAction.LOAD_FAIL,
      payload: error
    };
  }

  public create(modelId: string, data: IPayload | IPayload[]): Action {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: VersionAction.CREATE,
      payload: {
        modelId: modelId,
        data: data
      }
    };
  }

  public createSuccess(payload: IPayload, response: any): Action {
    return {
      type: VersionAction.CREATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public createFail(payload: IPayload, error: any): Action {
    return {
      type: VersionAction.CREATE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public remove(ids: string | string[]): Action {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }
    return {
      type: VersionAction.REMOVE,
      payload: {
        modelId: ids,
        data: ids
      }
    };
  }

  public removeSuccess(payload: IPayload, response: IVersion): Action {
    return {
      type: VersionAction.REMOVE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public removeFail(payload: IPayload, error: any): Action {
    return {
      type: VersionAction.REMOVE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public rollback(id: string): Action {
    return {
      type: VersionAction.ROLLBACK,
      payload: {
        version: id
      }
    };
  }

  public rollbackSuccess(payload: IPayload, response: IVersion): Action {
    return {
      type: VersionAction.ROLLBACK_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public rollbackFail(payload: IPayload, error: any): Action {
    return {
      type: VersionAction.ROLLBACK_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

}
