import { Action } from '../shared/common.action';
import { JSONAPIDeserializerService, Utils } from '../shared';
import { TemplateState, Template, TemplateRelationships } from './templates.models';
import { TemplateAction } from './templates.actions';

export default function reducer(state = new TemplateState(), action: Action): TemplateState  {
  const deserializer = new JSONAPIDeserializerService<Template, TemplateRelationships>(Template.fromJSONAPI, TemplateRelationships.fromJSONAPI);
  switch (action.type) {

    case TemplateAction.LOAD:
      return <TemplateState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case TemplateAction.LOAD_SUCCESS: {
      const {entities, relationships} = deserializer.deserialize(action.payload.response);

      return <TemplateState>Utils.updateState<TemplateState>(state, entities, relationships)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case TemplateAction.LOAD_FAIL: {
      return <TemplateState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case TemplateAction.CREATE: {
      return <TemplateState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case TemplateAction.CREATE_SUCCESS: {
      const {entities, relationships} = deserializer.deserialize(action.payload.response);

      return <TemplateState>Utils.updateState<TemplateState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case TemplateAction.CREATE_FAIL: {
      return <TemplateState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case TemplateAction.REMOVE: {
      return <TemplateState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case TemplateAction.REMOVE_SUCCESS: {
      const {entities, relationships} = deserializer.deserialize(action.payload.response);
      return <TemplateState>Utils.deleteFromState<TemplateState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case TemplateAction.REMOVE_FAIL: {
      return <TemplateState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case TemplateAction.UPDATE: {
      return <TemplateState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case TemplateAction.UPDATE_SUCCESS: {
      const {entities, relationships} = deserializer.deserialize(action.payload.response);
      return <TemplateState>Utils.updateState<TemplateState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case TemplateAction.UPDATE_FAIL: {
      return <TemplateState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    default: {
      return state;
    }
  }
}
