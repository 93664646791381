import { Injectable } from '@angular/core';
import { JSONAPIResourceService } from '../jsonapi/jsonapi-resource.service';
import { BackendOAuthService } from './backend.oauth.service';

@Injectable()
export class OAuthAPI {

  constructor(private apiService: BackendOAuthService) {}

  public get settings() {
    return new JSONAPIResourceService<any>('settings', 'settings/', this.apiService);
  }

}
