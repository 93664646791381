import { apiReducer } from './api';

export interface AppState {
  api: any;
}

/**
 * Because metareducers take a reducer function and return a new reducer,
 * we can use our compose helper to chain them together. Here we are
 * using combineReducers to make our top level reducer, and then
 * wrapping that in storeLogger. Remember that compose applies
 * the result from right to left.
 */
const applicationReducer = <AppState>{
  api: apiReducer
};

export const rootReducer = applicationReducer;
