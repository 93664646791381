import { Map, List } from 'immutable';
import { IPayload } from './common.models';
import { isNullOrUndefined } from 'util';

export class PayloadFactory {

  public static fromDeltaMap(delta: Map<string, Map<string, any>>, toJS = true): IPayload[] {
    return delta.map((data, id) => <IPayload> { id: id, data: toJS ? data.toJS() : data }).toArray();
  }

  public static fromArray(data: any[], toJS = false): IPayload[] {
    return List(data).map(d => <IPayload> { id: (isNullOrUndefined(d.id) && !isNullOrUndefined(d.get) ? d.get('id') : d.id), data: (toJS ? d.toJS() : d) }).toArray();
  }

  public static toPayload(data: any, toJS = false, removeId = false): IPayload {
    return <IPayload> { id: data.id, data: PayloadFactory.removeId((toJS ? data.toJS() : data), removeId) };
  }

  private static removeId(data: any, removeId: boolean) {
    if (removeId) {
      delete data.id;
    }
    return data;
  }

}
