import { IPayload } from './payload.interface';
import { NodeCreate } from '../../shared/api/nodes';
import { Activity } from '../../shared/api/activities';
import { RelationshipCreate } from '../../shared/api/relationships';

export class PayloadService {

  public static toPayload(datum: NodeCreate | Activity | RelationshipCreate): IPayload {
    if (datum instanceof NodeCreate) {
      return <IPayload> { id: datum.id, data: (<NodeCreate> datum).remove('id') };
    }
    if (datum instanceof Activity) {
      return <IPayload> (<Activity> datum);
    }
    if (datum instanceof RelationshipCreate) {
      return <IPayload> { id: datum.id, data: (<RelationshipCreate> datum).remove('id') };
    }
  }

}
