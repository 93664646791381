import { Record, Map, Set } from 'immutable';
import { RequestDiffRecord } from '../shared';

const settingsStateRecord = Record({
  ids: Set<string>(),
  entities: Map<string, any>(),
  diff: new RequestDiffRecord(),
  isBusy: 0,
});

export class SettingsState extends settingsStateRecord {
  entities: Map<string, any>;
  diff: RequestDiffRecord;
  isBusy: number;
}
