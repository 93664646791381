export const TemplateFieldsReportsFocusPmo = [
  {
    type: 'table',
    settings: {
      /* Request setting: Load the tree as well */
      requestLoadTree: true,
      /* Request setting: Filter the result */
      requestFilter: 'focuspmo',
      /* Request setting: Load the secondary costs */
      requestLoadSecondaryCosts: false,
      /* A filter function to filter by */
      filter: 'initiative',
      /* The header background color */
      headerBackgroundColor: '#ff6600',
      /* Group the elements by model */
      groupByModels: false,
      /* Use a thousand seperator for numeric values */
      thousandsSeperator: '´',
      /* Show the footer */
      showFooter: true,
      /* Show break before total row at the end of the table */
      showBreakBeforeTotals: false,
      /* Show total row after model group */
      showTotalsAfterModel: false,
      /* Show total row at the end of the table */
      showTotals: true,
      /* Break the collapsing on level border */
      breakCollapseOnLevel: true,
      /* Exports */
      exports: ['excel', 'pdf'], //  excel, pdf
      /* Is Row Span being used */
      /* Show wizard button */
      wizardButton: false,
      /* Break the table vertically among pages */
      verticalPageBreak: false,
      /* Show table models select box */
      showModelsSelectBox: false,
    },
    configuration: {
      fields: [
        { entry: { key: 'header1', label: '', controlType: 'group' }, children: [
            { entry: { key: 'sizeType', type: 'number', controlType: 'dropdown', label: 'REPORTS.INITIATIVEUPDATE.SIZETYPE', fixedWidth: 75, pdfWidth: 75, filter: true, filterAlign: 'left', editable: false, options: [
              { key: 0, value: 'REPORTS.FOCUSMASTER.NOTAPPLICABLE' },
              { key: 1, value: 'REPORTS.FOCUSMASTER.KI' },
              { key: 2, value: 'REPORTS.FOCUSMASTER.BI' },
              { key: 3, value: 'REPORTS.FOCUSMASTER.AI' },
              { key: 4, value: 'REPORTS.FOCUSMASTER.OTHERS' }
            ]}},
            { entry: { key: 'responsibledb', controlType: 'dropdown', label: 'REPORTS.EFFECTCONTROLLING.DB.TITLE', calculated: true, editable: false, filter: true, pdfWidth: 100, fixedWidth: 100, rowspan: 3, options: [
              { key: 'null', value: 'REPORTS.EFFECTCONTROLLING.DB.D0'},
              { key: 2, value: 'REPORTS.EFFECTCONTROLLING.DB.D2'},
              { key: 3, value: 'REPORTS.EFFECTCONTROLLING.DB.D3'},
              { key: 5, value: 'REPORTS.EFFECTCONTROLLING.DB.D5'},
              { key: 4, value: 'REPORTS.EFFECTCONTROLLING.DB.D4'},
              { key: 6, value: 'REPORTS.EFFECTCONTROLLING.DB.D6'},
              { key: 7, value: 'REPORTS.EFFECTCONTROLLING.DB.D7'},
              { key: 8, value: 'REPORTS.EFFECTCONTROLLING.DB.D8'},
              { key: 9, value: 'REPORTS.EFFECTCONTROLLING.DB.D9'},
              { key: 10, value: 'REPORTS.EFFECTCONTROLLING.DB.D10'},
              { key: 11, value: 'REPORTS.EFFECTCONTROLLING.DB.D11'},
              { key: 12, value: 'REPORTS.EFFECTCONTROLLING.DB.D12'},
              { key: 13, value: 'REPORTS.EFFECTCONTROLLING.DB.D13'}
            ]}},
            { entry: { key: 'name', controlType: 'textbox', type: 'text', label: 'REPORTS.FOCUSPMO.MODEL', pdfWidth: 80, fixedWidth: 80, editable: false }}
        ]},
        { entry: { key: 'header2', label: 'REPORTS.FOCUSPMO.POTENTIALROUGH', controlType: 'group', pdfWidth: 240 }, children: [
          { entry: { key: 'effectebitdarough', field: 'investBudget', controlType: 'textbox', type: 'number', editable: false, pdfWidth: 80, fixedWidth: 80, label: 'REPORTS.FOCUSPMO.EFFECTEBITDA' }},
          { entry: { key: 'effectinvestrough', field: 'investBudget', controlType: 'textbox', type: 'number', editable: false, pdfWidth: 80, fixedWidth: 80, label: 'REPORTS.FOCUSPMO.EFFECTINVEST' }},
          { entry: { key: 'effecttotalrough', field: 'investBudget', controlType: 'textbox', type: 'number', editable: false, pdfWidth: 80, fixedWidth: 80, label: 'REPORTS.FOCUSPMO.EFFECTTOTAL' }},
        ]},
        { entry: { key: 'header3', label: 'REPORTS.FOCUSPMO.POTENTIALDETAIL', controlType: 'group', pdfWidth: 400 }, children: [
          { entry: { key: 'effectebitdadetail', field: 'investRemaining', controlType: 'textbox', type: 'number', editable: false, pdfWidth: 80, fixedWidth: 80, label: 'REPORTS.FOCUSPMO.EFFECTEBITDA' }},
          { entry: { key: 'effectinvestdetail', field: 'investRemaining', controlType: 'textbox', type: 'number', editable: false, pdfWidth: 80, fixedWidth: 80, label: 'REPORTS.FOCUSPMO.EFFECTINVEST' }},
          { entry: { key: 'effecttotaldetail', field: 'investRemaining', controlType: 'textbox', type: 'number', editable: false, pdfWidth: 80, fixedWidth: 80, label: 'REPORTS.FOCUSPMO.EFFECTTOTAL' }},
          { entry: { key: 'investRemaining', controlType: 'textbox', type: 'number', editable: false, pdfWidth: 80, fixedWidth: 80, label: 'REPORTS.FOCUSPMO.INITIALER' }},
          { entry: { key: 'costRemaining', controlType: 'textbox', type: 'number', editable: false, pdfWidth: 80, fixedWidth: 80, label: 'REPORTS.FOCUSPMO.INITIALINVEST' }},
        ]},
        { entry: { key: 'header4', label: '', controlType: 'group' }, children: [
          { entry: { key: 'technical', type: 'number', controlType: 'dropdown', editable: false, label: 'REPORTS.FOCUSPMO.TEMPER', pdfWidth: 75, fixedWidth: 75, options: [
            { key: 0, value: 'REPORTS.FOCUSPMO.DROPDOWN.NOTAPPLICABLE' },
            { key: 1, value: 'REPORTS.FOCUSPMO.DROPDOWN.L1', text: 'DROPDOWN.L1' },
            { key: 2, value: 'REPORTS.FOCUSPMO.DROPDOWN.L2', text: 'DROPDOWN.L2' },
            { key: 3, value: 'REPORTS.FOCUSPMO.DROPDOWN.L3', text: 'DROPDOWN.L3' },
            { key: 4, value: 'REPORTS.FOCUSPMO.DROPDOWN.L4', text: 'DROPDOWN.L4' },
            { key: 5, value: 'REPORTS.FOCUSPMO.DROPDOWN.L5', text: 'DROPDOWN.L5' }
          ]}},
          { entry: { key: 'responsibleId', controlType: 'dropdown', label: 'REPORTS.FOCUSPMO.RESPONSIBLEID', editable: false, pdfWidth: 80, fixedWidth: 80, source: 'humanresources' }},
          { entry: { key: 'organizationalDescription', controlType: 'text', editable: false, pdfWidth: 100, fixedWidth: 100, label: 'REPORTS.FOCUSPMO.ORGANIZATIONALDESCRIPTION' }},
          { entry: { key: 'priority', controlType: 'dropdown',  label: 'REPORTS.FOCUSPMO.PRIORITY.TITLE', editable: false, pdfWidth: 100, fixedWidth: 100, addNull: false, options: [
            { key: 1, value: 'REPORTS.FOCUSPMO.PRIORITY.1' },
            { key: 2, value: 'REPORTS.FOCUSPMO.PRIORITY.2' },
            { key: 3, value: 'REPORTS.FOCUSPMO.PRIORITY.3' },
            { key: 4, value: 'REPORTS.FOCUSPMO.PRIORITY.4' },
            { key: 5, value: 'REPORTS.FOCUSPMO.PRIORITY.5' },
            { key: 6, value: 'REPORTS.FOCUSPMO.PRIORITY.6' },
            { key: 7, value: 'REPORTS.FOCUSPMO.PRIORITY.7' },
            { key: 8, value: 'REPORTS.FOCUSPMO.PRIORITY.8' },
            { key: 9, value: 'REPORTS.FOCUSPMO.PRIORITY.9' }
          ]}},
          { entry: { key: 'organizational', type: 'number', controlType: 'dropdown', pdfWidth: 100, fixedWidth: 100, label: 'REPORTS.FOCUSPMO.ORGANIZATIONAL.TITLE', editable: false, options: [
            { key: 0, value: 'REPORTS.FOCUSPMO.CALCULATED.NOTAPPLICABLE' },
            { key: 1, value: 'REPORTS.FOCUSPMO.ORGANIZATIONAL.AGILE' },
            { key: 2, value: 'REPORTS.FOCUSPMO.ORGANIZATIONAL.CLASSIC' },
            { key: 3, value: 'REPORTS.FOCUSPMO.ORGANIZATIONAL.MAJOR' }
          ]}},
          { entry: { key: 'description', controlType: 'text', editable: false, pdfWidth: 100, fixedWidth: 100, label: 'REPORTS.FOCUSPMO.DESCRIPTION' }},
          { entry: { key: 'riskProfileAssessment', controlType: 'dropdown', editable: false, pdfWidth: 75, fixedWidth: 75, label: 'REPORTS.FOCUSPMO.RISKPROFILEASSESSMENT', options: [
            { key: 0, value: 'REPORTS.FOCUSPMO.DROPDOWN.NOTAPPLICABLE', color: '#999999' },
            { key: 1, value: 'REPORTS.FOCUSPMO.DROPDOWN.LOW', color: '#9ac93e', shape: 'circle' },
            { key: 2, value: 'REPORTS.FOCUSPMO.DROPDOWN.MEDIUM', color: '#EDD515', shape: 'triangle' },
            { key: 3, value: 'REPORTS.FOCUSPMO.DROPDOWN.HIGH', color: '#E50000', shape: 'rect' },
          ]}},
          { entry: { key: 'description4', controlType: 'text', editable: false, pdfWidth: 100, fixedWidth: 100, label: 'REPORTS.FOCUSPMO.DESCRIPTION4' }},
          { entry: { key: 'riskProfileCountermeasures', controlType: 'text', editable: false, pdfWidth: 100, fixedWidth: 100, label: 'REPORTS.FOCUSPMO.RISKPROFILECOUNTERMEASURES' }},
          { entry: { key: 'cost', controlType: 'dropdown', label: 'REPORTS.FOCUSPMO.COST', editable: false, pdfWidth: 75, fixedWidth: 75, options: [
            { key: 0, value: 'REPORTS.FOCUSPMO.DROPDOWN.NOTAPPLICABLE', color: '#999999' },
            { key: 1, value: 'REPORTS.FOCUSPMO.DROPDOWN.LOW', color: '#9ac93e', shape: 'circle' },
            { key: 2, value: 'REPORTS.FOCUSPMO.DROPDOWN.MEDIUM', color: '#EDD515', shape: 'triangle' },
            { key: 3, value: 'REPORTS.FOCUSPMO.DROPDOWN.HIGH', color: '#E50000', shape: 'rect' },
          ]}},
          { entry: { key: 'costchildren', controlType: 'text', editable: false, pdfWidth: 100, fixedWidth: 100, label: 'REPORTS.FOCUSPMO.COSTCHILDREN', calculated: true }},
          { entry: { key: 'quality', controlType: 'dropdown', pdfWidth: 75, fixedWidth: 75, label: 'REPORTS.FOCUSPMO.QUALITY', editable: false, options: [
            { key: 0, value: 'REPORTS.FOCUSPMO.DROPDOWN.NOTAPPLICABLE', color: '#999999' },
            { key: 1, value: 'REPORTS.FOCUSPMO.DROPDOWN.LOW', color: '#9ac93e', shape: 'circle' },
            { key: 2, value: 'REPORTS.FOCUSPMO.DROPDOWN.MEDIUM', color: '#EDD515', shape: 'triangle' },
            { key: 3, value: 'REPORTS.FOCUSPMO.DROPDOWN.HIGH', color: '#E50000', shape: 'rect' },
          ]}},
          { entry: { key: 'qualitychildren', controlType: 'text', editable: false, pdfWidth: 100, fixedWidth: 100, label: 'REPORTS.FOCUSPMO.QUALITYCHILDREN', calculated: true }},
          { entry: { key: 'time', controlType: 'dropdown', pdfWidth: 75, fixedWidth: 75, label: 'REPORTS.FOCUSPMO.TIME', editable: false, options: [
            { key: 0, value: 'REPORTS.FOCUSPMO.DROPDOWN.NOTAPPLICABLE', color: '#999999' },
            { key: 1, value: 'REPORTS.FOCUSPMO.DROPDOWN.LOW', color: '#9ac93e', shape: 'circle' },
            { key: 2, value: 'REPORTS.FOCUSPMO.DROPDOWN.MEDIUM', color: '#EDD515', shape: 'triangle' },
            { key: 3, value: 'REPORTS.FOCUSPMO.DROPDOWN.HIGH', color: '#E50000', shape: 'rect' },
          ]}},
          { entry: { key: 'complexity', controlType: 'dropdown', pdfWidth: 75, fixedWidth: 75, label: 'REPORTS.FOCUSPMO.COMPLEXITY', editable: false, options: [
            { key: 0, value: 'REPORTS.FOCUSPMO.DROPDOWN.NOTAPPLICABLE', color: '#999999' },
            { key: 1, value: 'REPORTS.FOCUSPMO.DROPDOWN.LOW', color: '#9ac93e', shape: 'circle' },
            { key: 2, value: 'REPORTS.FOCUSPMO.DROPDOWN.MEDIUM', color: '#EDD515', shape: 'triangle' },
            { key: 3, value: 'REPORTS.FOCUSPMO.DROPDOWN.HIGH', color: '#E50000', shape: 'rect' },
          ]}},
          { entry: { key: 'risks', controlType: 'text', editable: false, pdfWidth: 100, fixedWidth: 100, label: 'REPORTS.FOCUSPMO.RISKS', calculated: true }}
        ]}
      ],
      additionalfields: [
        { entry: { key: 'status' }},
        { entry: { key: 'businesscalculation' }},
        { entry: { key: 'nodeType' }}
      ]
    }
  }
];
