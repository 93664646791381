import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { IAccount, ThirdPartyService } from './thirdparty.service';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

export interface CrunchbaseResult {
  count: number;
  entities: CrunchbaseEntity[];
}

export interface CrunchbaseEntity {
  facet_ids: string[];
  identifier: CrunchbaseEntityIdentifier;
  short_description: string;
}

export interface CrunchbaseEntityIdentifier {
  entity_def_id: string;
  image_id: string;
  permalink: string;
  uuid: string;
  value: string;
}

@Injectable()
export class ThirdPartyCrunchbaseService extends ThirdPartyService {

  public sendRequest(tokenObservable: Observable<IAccount>, method: string, url: string, body: {} = {}): Observable<Object> {
    return this.tokenService.get().flatMap(token => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/vnd.api.v2+json',
        'Authorization': 'Bearer ' + token
      });
      return this.http.request(method, url, {
        body: JSON.stringify(body),
        headers: headers
      });
    });
  }

  public get(url: string): Observable<any> {
    /* Call */
    return <Observable<CrunchbaseResult>> this.initialize(this.getAccount('crunchbase')).request('POST', environment.go + '/api/v5/cors', {
      method: 'GET',
      url: url
    });
  }

}
