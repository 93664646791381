export const TemplateFieldsReportsPomOverview = [
  {
    type: 'table',
    settings: {
      /* Show top most header row (grouped heading) */
      showHeading: true,
      /* Show table fields */
      showFields: true,
      /* Show the footer */
      showFooter: true,
      /* Exports */
      exports: ['pdf', 'excel'], //  excel, pdf
      /* Allow page to break vertically */
      verticalPageBreak: true
    },
    configuration: {
      fields: [
        { entry: { key: 'structure', label: 'TABLE.STRUCTURE.TITLE', displayLabel: false, controlType: 'structure', shape: 'ellipsis-v', background: '#ffffff', border: '0', additional: { addchild: true, addsibling: true, edit: true, delete: true } }},
        { entry: { key: 'number', label: '', controlType: 'group', background: '#ffffff', border: '0' }, children: [
          { entry: { key: 'number', controlType: 'textbox', label: 'REPORTS.POMOVERVIEW.NR', border: '0', editable: false, calculated: true, pdfWidth: 25, fixedWidth: 50, background: '#3C4A5F' }}
        ]},
        { entry: { key: 'levels', label: 'TABLE.LEVELS', controlType: 'levels', border: '0', fixedWidth: 100 }},
        { entry: { key: 'general', label: '', controlType: 'group', background: '#ffffff' }, children: [
          { entry: { key: 'stategicClassification', controlType: 'textbox', border: '0', label: 'REPORTS.POMOVERVIEW.STRATEGICCLASSIFICATION', editable: false, calculated: true, sortable: true, fixedWidth: 100, background: '#3C4A5F' }},
          { entry: { key: 'priority', type: 'number', controlType: 'textbox', border: '0', label: 'NODE.DATASHEET.FIELDS.PRIORITY', editable: true, calculated: false, fixedWidth: 75, sortable: true, background: '#3C4A5F' }},
          { entry: { key: 'complexity', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.COMPLEXITY', border: '0', fixedWidth: 100, background: '#3C4A5F', sortable: true, filter: true, options: [
            { key: 0, value: 'REPORTS.POMOVERVIEW.COMPLEXITY.NOTAPPLICABLE' },
            { key: 1, value: 'REPORTS.POMOVERVIEW.COMPLEXITY.LOW' },
            { key: 2, value: 'REPORTS.POMOVERVIEW.COMPLEXITY.MEDIUM' },
            { key: 3, value: 'REPORTS.POMOVERVIEW.COMPLEXITY.HIGH' }
          ]}},
          { entry: { key: 'executive', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.EXECUTIVE.TITLE', border: '0', fixedWidth: 100, background: '#3C4A5F', sortable: true, filter: true, options: [
            { key: 0, value: 'NODE.DATASHEET.FIELDS.EXECUTIVE.NOTAPPLICABLE' },
            { key: 1, value: 'NODE.DATASHEET.FIELDS.EXECUTIVE.ONE' },
            { key: 2, value: 'NODE.DATASHEET.FIELDS.EXECUTIVE.TWO' },
            { key: 3, value: 'NODE.DATASHEET.FIELDS.EXECUTIVE.THREE' },
            { key: 4, value: 'NODE.DATASHEET.FIELDS.EXECUTIVE.FOUR' },
            { key: 5, value: 'NODE.DATASHEET.FIELDS.EXECUTIVE.FIVE' },
          ]}},
          { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'REPORTS.POMOVERVIEW.NAME', border: '0', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME', fixedWidth: 75, background: '#3C4A5F', sortable: true }},
          { entry: { key: 'description', controlType: 'text', label: 'NODE.DATASHEET.FIELDS.DESCRIPTION', border: '0', fixedWidth: 150, background: '#3C4A5F' }},
          { entry: { key: 'startDate', type: 'datetime', controlType: 'date', label: 'NODE.DATASHEET.FIELDS.START', border: '0', fixedWidth: 100, background: '#3C4A5F', sortable: true }},
          { entry: { key: 'targetDate', type: 'datetime', controlType: 'date', label: 'NODE.DATASHEET.FIELDS.TARGETDATE', border: '0', fixedWidth: 100, background: '#3C4A5F', sortable: true }},
        ]},
        { entry: { key: 'today', label: 'REPORTS.GENERAL.YEAR', params: 'Y', controlType: 'group', border: '0' }, children: [
          { entry: { key: 'time', type: 'number', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.TIME', fixedWidth: 75, border: '0', nodeBucket: true, nodeBucketId: 'Y', sortable: true }}
        ]},
        { entry: { key: 'year+1', label: 'REPORTS.GENERAL.YEAR', params: 'Y+1', controlType: 'group', border: '0' }, children: [
          { entry: { key: 'cost#1', type: 'number', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.COST', fixedWidth: 75, border: '0', nodeBucket: true, nodeBucketId: 'Y+1', sortable: true }},
          { entry: { key: 'quality#1', type: 'number', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.QUALITY', fixedWidth: 75, border: '0', nodeBucket: true, nodeBucketId: 'Y+1', sortable: true }},
          { entry: { key: 'executive#1', type: 'number', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.CUMULATEDBENEFIT', params: 'Y+1', fixedWidth: 75, border: '0', nodeBucket: true, nodeBucketId: 'Y+1', sortable: true }}
        ]},
        { entry: { key: 'year+2', label: 'REPORTS.GENERAL.YEAR', params: 'Y+2', controlType: 'group', border: '0' }, children: [
          { entry: { key: 'cost#2', type: 'number', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.COST', fixedWidth: 75, nodeBucket: true, nodeBucketId: 'Y+2', border: '0', sortable: true }},
          { entry: { key: 'executive#2', type: 'number', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.CUMULATEDBENEFIT', params: 'Y+2', fixedWidth: 75, border: '0', nodeBucket: true, nodeBucketId: 'Y+2', sortable: true }}
        ]},
        { entry: { key: 'year+3', label: 'REPORTS.GENERAL.YEAR', params: 'Y+3', controlType: 'group', border: '0' }, children: [
          { entry: { key: 'cost#3', type: 'number', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.COST', fixedWidth: 75, nodeBucket: true, nodeBucketId: 'Y+3', border: '0', sortable: true }},
          { entry: { key: 'executive#3', type: 'number', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.CUMULATEDBENEFIT', params: 'Y+3', fixedWidth: 75, border: '0', nodeBucket: true, nodeBucketId: 'Y+3', sortable: true }}
        ]},
        { entry: { key: 'year+4', label: 'REPORTS.GENERAL.YEAR', params: 'Y+4', controlType: 'group', border: '0' }, children: [
          { entry: { key: 'cost#4', type: 'number', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.COST', fixedWidth: 75, nodeBucket: true, nodeBucketId: 'Y+4', border: '0', sortable: true }},
          { entry: { key: 'executive#4', type: 'number', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.CUMULATEDBENEFIT', params: 'Y+4', fixedWidth: 75, border: '0', nodeBucket: true, nodeBucketId: 'Y+4', sortable: true }}
        ]},
        { entry: { key: 'year+5', label: 'REPORTS.GENERAL.YEAR', params: 'Y+5', controlType: 'group', border: '0' }, children: [
          { entry: { key: 'cost#5', type: 'number', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.COST', fixedWidth: 75, nodeBucket: true, nodeBucketId: 'Y+5', border: '0', sortable: true }},
          { entry: { key: 'executive#5', type: 'number', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.CUMULATEDBENEFIT', params: 'Y+5', fixedWidth: 75, border: '0', nodeBucket: true, nodeBucketId: 'Y+5', sortable: true }}
        ]},
        { entry: { key: 'general', label: '', controlType: 'group', background: '#ffffff' }, children: [
            { entry: { key: 'quality', type: 'number', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.CUMULATEDEXPENSES', fixedWidth: 75, params: 'Y+5', border: '0', nodeBucket: true, nodeBucketId: 'Y+5', background: '#757575', sortable: true }},
            { entry: { key: 'description1', controlType: 'text', label: 'NODE.DATASHEET.FIELDS.DESCRIPTION1', fixedWidth: 150, background: '#3C4A5F', border: '0', sortable: true }}
        ]},
        { entry: { key: 'general', label: 'REPORTS.POMOVERVIEW.ASSIGNMENTBUSINESSAREA', controlType: 'group', background: '#3C4A5F', border: '0' }, children: [
          { entry: { key: 'cost', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.COST', editable: true, calculated: false, fixedWidth: 75, border: '0', background: '#3C4A5F', sortable: true }},
          { entry: { key: 'organizational', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.ORGANIZATIONAL', editable: true, calculated: false, border: '0', fixedWidth: 75, background: '#3C4A5F', sortable: true}},
          { entry: { key: 'technical', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.TECHNICAL', editable: true, calculated: false, border: '0', fixedWidth: 75, background: '#3C4A5F', sortable: true }},
        ]},
        { entry: { key: 'general', label: '', controlType: 'group', background: '#ffffff' }, children: [
          { entry: { key: 'crossReference', controlType: 'text', label: 'NODE.DATASHEET.FIELDS.CROSSREFERENCE', fixedWidth: 75, background: '#3C4A5F', border: '0'}},
          { entry: { key: 'riskProfileCategory', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.RISKPROFILECATEGORY.TITLE', fixedWidth: 100, border: '0', background: '#3C4A5F', sortable: true, filter: true, options: [
            { key: 0, value: 'NODE.DATASHEET.FIELDS.PROGRAM.NOTAPPLICABLE' },
            { key: 1, value: 'NODE.DATASHEET.FIELDS.RISKPROFILECATEGORY.EXECUTIVE' },
            { key: 2, value: 'NODE.DATASHEET.FIELDS.RISKPROFILECATEGORY.PROGRAMPROJECT' },
            { key: 3, value: 'NODE.DATASHEET.FIELDS.RISKPROFILECATEGORY.FUNCTIONAL' },
            { key: 4, value: 'NODE.DATASHEET.FIELDS.RISKPROFILECATEGORY.RESOURCE' },
            { key: 5, value: 'NODE.DATASHEET.FIELDS.RISKPROFILECATEGORY.ORGANIZATIONAL' },
            { key: 6, value: 'NODE.DATASHEET.FIELDS.RISKPROFILECATEGORY.TECHNICAL' }
          ]}},
        ]},
        { entry: { key: 'pomCalc1', label: 'REPORTS.POMOVERVIEW.ASSIGNMENTBUSINESSAREATOTALEFFORT', controlType: 'group', background: '#757575', params: 'Y+1', border: '0' }, children: [
          { entry: { key: 'pomCalc1', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.COST', editable: false, calculated: true, fixedWidth: 75, border: '0', background: '#757575', sortable: true }},
          { entry: { key: 'pomCalc2', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.ORGANIZATIONAL', editable: false, calculated: true, fixedWidth: 75, border: '0', background: '#757575', sortable: true}},
          { entry: { key: 'pomCalc3', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.TECHNICAL', editable: false, calculated: true, fixedWidth: 75, border: '0', background: '#757575', sortable: true }},
        ]},
        { entry: { key: 'pomCalc2', label: 'REPORTS.POMOVERVIEW.ASSIGNMENTBUSINESSAREATOTALEFFORT', controlType: 'group', background: '#757575', params: 'Y+1', border: '0' }, children: [
          { entry: { key: 'pomCalc4', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.COST', editable: false, calculated: true, fixedWidth: 75, border: '0', background: '#757575', sortable: true }},
          { entry: { key: 'pomCalc5', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.ORGANIZATIONAL', editable: false, calculated: true, fixedWidth: 75, border: '0', background: '#757575', sortable: true}},
          { entry: { key: 'pomCalc6', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.TECHNICAL', editable: false, calculated: true, fixedWidth: 75, border: '0', background: '#757575', sortable: true }},
        ]},
        { entry: { key: 'general', label: '', controlType: 'group', background: '#ffffff', border: '0' }, children: [
            { entry: { key: 'program', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.PROGRAM.TITLE', fixedWidth: 100, border: '0', background: '#6A9AD0', sortable: true, filter: true, options: [
              { key: 0, value: 'NODE.DATASHEET.FIELDS.PROGRAM.NOTAPPLICABLE' },
              { key: 1, value: 'NODE.DATASHEET.FIELDS.PROGRAM.ONE' },
              { key: 2, value: 'NODE.DATASHEET.FIELDS.PROGRAM.TWO' },
              { key: 3, value: 'NODE.DATASHEET.FIELDS.PROGRAM.THREE' },
              { key: 4, value: 'NODE.DATASHEET.FIELDS.PROGRAM.FOUR' },
              { key: 5, value: 'NODE.DATASHEET.FIELDS.PROGRAM.FIVE' },
              { key: 6, value: 'NODE.DATASHEET.FIELDS.PROGRAM.SIX' },
              { key: 7, value: 'NODE.DATASHEET.FIELDS.PROGRAM.SEVEN' },
            ]}},
            { entry: { key: 'functional', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.FUNCTIONAL.TITLE', fixedWidth: 100, border: '0', background: '#6A9AD0', sortable: true, filter: true, options: [
              { key: 0, value: 'NODE.DATASHEET.FIELDS.FUNCTIONAL.NOTAPPLICABLE' },
              { key: 1, value: 'NODE.DATASHEET.FIELDS.FUNCTIONAL.ONE' },
              { key: 2, value: 'NODE.DATASHEET.FIELDS.FUNCTIONAL.TWO' },
              { key: 3, value: 'NODE.DATASHEET.FIELDS.FUNCTIONAL.THREE' },
              { key: 4, value: 'NODE.DATASHEET.FIELDS.FUNCTIONAL.FOUR' },
            ]}},
            { entry: { key: 'resource', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.RESOURCE.TITLE', fixedWidth: 100, border: '0', background: '#6A9AD0', sortable: true, filter: true, options: [
              { key: 0, value: 'NODE.DATASHEET.FIELDS.RESOURCE.NOTAPPLICABLE' },
              { key: 1, value: 'NODE.DATASHEET.FIELDS.RESOURCE.ONE' },
              { key: 2, value: 'NODE.DATASHEET.FIELDS.RESOURCE.TWO' },
              { key: 3, value: 'NODE.DATASHEET.FIELDS.RESOURCE.THREE' },
              { key: 4, value: 'NODE.DATASHEET.FIELDS.RESOURCE.FOUR' },
              { key: 5, value: 'NODE.DATASHEET.FIELDS.RESOURCE.FIVE' },
              { key: 6, value: 'NODE.DATASHEET.FIELDS.RESOURCE.SIX' },
              { key: 7, value: 'NODE.DATASHEET.FIELDS.RESOURCE.SEVEN' },
            ]}},
            { entry: { key: 'businessBenefit', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.TITLE', fixedWidth: 75, border: '0', background: '#6A9AD0', sortable: true, filter: true, options: [
              { key: 0, value: 'REPORTS.POMOVERVIEW.BUSINESSBENEFIT.NOTAPPLICABLE' },
              { key: 1, value: 'REPORTS.POMOVERVIEW.BUSINESSBENEFIT.LOW' },
              { key: 2, value: 'REPORTS.POMOVERVIEW.BUSINESSBENEFIT.MEDIUM' },
              { key: 3, value: 'REPORTS.POMOVERVIEW.BUSINESSBENEFIT.HIGH' },
            ]}},
           ]
        }
      ]
    }
  }
];
