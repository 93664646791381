import { TemplateFieldsDrilldown } from './fields/default/template.fields.drilldown';
import { TemplateFieldsHumanResource } from './fields/default/template.fields.humanresource';
import { TemplateFieldsBusinessCalculations } from './fields/default/template.fields.businesscalculations';
import { TemplateFieldsInstances } from './fields/default/template.fields.instances';
import { TemplateFieldsBusinessareas } from './fields/default/template.fields.businessareas';
import { TemplateFieldsUserSettings } from './fields/default/template.fields.usersettings';
import { TemplateFieldsTemplate } from './fields/default/template.fields.template';
import { TemplateFieldsModels } from './fields/default/template.fields.models';
import { TemplateFieldsSubsets } from './fields/default/template.fields.subsets';
import { TemplateFieldsNodes } from './fields/default/template.fields.nodes';
import { TemplateFieldsActivities } from './fields/default/template.fields.activities';
import { TemplateFieldsAggregations } from './fields/default/template.fields.aggregations';
import { TemplateFieldsReports } from './fields/default/template.fields.reports';
import { TemplateFieldsRadial } from './fields/default/template.fields.radial';
import { TemplateFieldsActivitiesList } from './fields/default/template.fields.activities.list';
import { TemplateFieldsSettings } from './fields/default/template.fields.settings';
import { TemplateFieldsCanvas } from './fields/default/template.fields.canvas';
import { TemplateFieldsGraph } from './fields/default/template.fields.graph';
import { TemplateFieldsMcfarlan } from './fields/default/template.fields.mcfarlan';
import { TemplateFieldsHome } from './fields/default/template.fields.home';
import { TemplateFieldsDashboard } from './fields/default/template.fields.dashboard';
import { TemplateFieldsTime } from './fields/default/template.fields.time';
import { TemplateFieldsTable } from './fields/default/template.fields.table';
import { TemplateFieldsRelationships } from './fields/default/template.fields.relationships';
import { TemplateFieldsNodesCreate } from './fields/default/template.fields.nodes.create';
import { TemplateFieldsHumanResourceCreate } from './fields/default/template.fields.humanresource.create';
import { TemplateFieldsSubsetsCreate } from './fields/default/template.fields.subsets.create';
import { TemplateFieldsBusinessareasCreate } from './fields/default/template.fields.businessareas.create';
import { TemplateFieldsModelsCreate } from './fields/default/template.fields.models.create';
import { TemplateFieldsNodesCreateToDo } from './fields/default/template.fields.nodes.create.todo';
import { TemplateFieldsWizard } from './fields/default/template.fields.wizard';
import { TemplateFieldsNodesEisenhower1, TemplateFieldsNodesEisenhower2, TemplateFieldsNodesEisenhower3 } from './fields/default/template.fields.nodes.eisenhower';
import { TemplateFieldsMyMenu } from './fields/default/template.fields.mymenu';
import { TemplateFieldsRequirementdefinition, TemplateFieldsNodesRequirementDefinitionGrid } from './fields/default/template.fields.requirementdefinition';
import { TemplateFieldsAccountCrunchbase, TemplateFieldsAccountOctimine, TemplateFieldsAccountTrendExplorer } from './fields/default/template.fields.accounts';
import { TemplateFieldsHomePresets } from './fields/default/template.fields.presets';
import { TemplateFieldsLibrary } from './fields/default/template.fields.library';
import { TemplateFieldsChecklist } from './fields/default/template.fields.checklist';
import { TemplateFieldsSunburst } from './fields/default/template.fields.sunburst';
import { TemplateFieldsMatrix } from './fields/default/template.fields.matrix';

export const template = {
  id: undefined,
  name: '',
  defaultLanguage: 'en',
  language: '',
  url: '',
  type: 'MODEL',
  modeltype: null,
  background: '#9ac93e',
  color: '#ffffff',
  qstatusdiff: true,
  settings: TemplateFieldsSettings,
  syncs: ['office365'],
  dashboard: TemplateFieldsDashboard,
  home: TemplateFieldsHome,
  homepresets: TemplateFieldsHomePresets,
  radial: TemplateFieldsRadial,
  businesscalculations: TemplateFieldsBusinessCalculations,
  drilldownfields: TemplateFieldsDrilldown,
  instances: TemplateFieldsInstances,
  businessareas: TemplateFieldsBusinessareas,
  businessareascreate: TemplateFieldsBusinessareasCreate,
  models: TemplateFieldsModels,
  modelscreate: TemplateFieldsModelsCreate,
  subsets: TemplateFieldsSubsets,
  subsetscreate: TemplateFieldsSubsetsCreate,
  nodes: TemplateFieldsNodes,
  nodescreate: TemplateFieldsNodesCreate,
  nodescreatetodo: TemplateFieldsNodesCreateToDo,
  nodeseisenhower1: TemplateFieldsNodesEisenhower1,
  nodeseisenhower2: TemplateFieldsNodesEisenhower2,
  nodeseisenhower3: TemplateFieldsNodesEisenhower3,
  activities: TemplateFieldsActivities,
  relationship: TemplateFieldsRelationships,
  activitieslist: TemplateFieldsActivitiesList,
  humanresource: TemplateFieldsHumanResource,
  humanresourcecreate: TemplateFieldsHumanResourceCreate,
  usersettings: TemplateFieldsUserSettings,
  templates: TemplateFieldsTemplate,
  aggregations: TemplateFieldsAggregations,
  reports: TemplateFieldsReports,
  canvas: TemplateFieldsCanvas,
  graph: TemplateFieldsGraph,
  time: TemplateFieldsTime,
  mcfarlan: TemplateFieldsMcfarlan,
  table: TemplateFieldsTable,
  wizard: TemplateFieldsWizard,
  requirementdefinition: TemplateFieldsRequirementdefinition,
  requirementdefinitiongrid: TemplateFieldsNodesRequirementDefinitionGrid,
  settingsoctimine: TemplateFieldsAccountOctimine,
  settingstrendexplorer: TemplateFieldsAccountTrendExplorer,
  settingscrunchbase: TemplateFieldsAccountCrunchbase,
  checklist: TemplateFieldsChecklist,
  sunburst: TemplateFieldsSunburst,
  library: TemplateFieldsLibrary,
  matrix: TemplateFieldsMatrix,
  selectedReports: ['structured', 'estimation'],
  mymenu: TemplateFieldsMyMenu,
  created_at: undefined,
  updated_at: undefined,
  deleted_at: undefined,

  relationships: undefined
};
