import { TemplateFieldsReportsEstimation } from './reports/template.fields.reports.estimation';
import { TemplateFieldsReportsStructured } from './reports/template.fields.reports.structured';
import { TemplateFieldsReportsTeamStatus } from './reports/template.fields.reports.teamstatus';
import { TemplateFieldsReportsObjective } from './reports/template.fields.reports.objective';
import { TemplateFieldsReportsHeatmap } from './reports/template.fields.reports.heatmap';
import { TemplateFieldsReportsClevelHeatmap } from './reports/template.fields.reports.clevelheatmap';
import { TemplateFieldsReportsCawecoCompliance } from './reports/template.fields.reports.caweco.compliance';
import { TemplateFieldsReportsKPIs } from './reports/template.fields.reports.kpis';
import { TemplateFieldsReportsROI } from './reports/template.fields.reports.roi';
import { TemplateFieldsReportsModelStatus } from './reports/template.fields.reports.modelstatus';
import { TemplateFieldsReportsProjectOverview } from './reports/template.fields.reports.projectoverview';
import { TemplateFieldsReportsStrategicfitness } from './reports/template.fields.reports.strategicfitness';
import { TemplateFieldsReportsComplianceStatus } from './reports/template.fields.reports.compliancestatus';
import { TemplateFieldsReportsIlg } from './reports/template.fields.reports.ilg';
import { TemplateFieldsReportsObjectiveModel } from './reports/template.fields.reports.objective.model';
import { TemplateFieldsReportsObjectiveBusinessarea } from './reports/template.fields.reports.objective.businessarea';
import { TemplateFieldsReportsStatus } from './reports/template.fields.reports.status';
import { TemplateFieldsReportsScheduledDistribution } from './reports/template.fields.reports.scheduledistribution';
import { TemplateFieldsReportsPomOverview } from './reports/template.fields.reports.pomoverview';
import { TemplateFieldsReportsOverview } from './reports/template.fields.reports.overview';
import { TemplateFieldsReportsObjectiveBusinessareaMeasures } from './reports/template.fields.reports.objective.businessarea.measures';
import { TemplateFieldsReportsInitiativeUpdate } from './reports/template.fields.reports.initiative.update';
import { TemplateFieldsReportsInitiativeInventory } from './reports/template.fields.reports.initiative.inventory';
import { TemplateFieldsReportsEffectControlling } from './reports/template.fields.reports.effectcontrolling';
import { TemplateFieldsReportsKpi } from './reports/template.fields.reports.kpi';
import { TemplateFieldsReportsFocusMaster } from './reports/template.fields.reports.focusmaster';
import { TemplateFieldsReportsFocusPmo } from './reports/template.fields.reports.focuspmo';
import { TemplateFieldsReportsQReportSkeleton } from './reports/template.fields.reports.qreport';
import {TemplateFieldsReportsStrategyOverview} from './reports/template.fields.reports.strategyoverview';
import { TemplateFieldsReportsChangeIT } from './reports/template.fields.reports.changeit';
import { TemplateFieldsReportsRollout } from './reports/template.fields.reports.rollout';
import { TemplateFieldsReportsFactories } from './reports/template.fields.reports.factories';
import { TemplateFieldsReportsObjectiveModelMeasures } from './reports/template.fields.reports.objective.model.measures';

export const TemplateFieldsReports = {
  estimation: TemplateFieldsReportsEstimation,
  structured: TemplateFieldsReportsStructured,
  teamstatus: TemplateFieldsReportsTeamStatus,
  objective: TemplateFieldsReportsObjective,
  heatmap: TemplateFieldsReportsHeatmap,
  clevelheatmap: TemplateFieldsReportsClevelHeatmap,
  cawecocompliance: TemplateFieldsReportsCawecoCompliance,
  kpis: TemplateFieldsReportsKPIs,
  roi: TemplateFieldsReportsROI,
  modelstatus: TemplateFieldsReportsModelStatus,
  projectoverview: TemplateFieldsReportsProjectOverview,
  strategicfitness: TemplateFieldsReportsStrategicfitness,
  compliancestatus: TemplateFieldsReportsComplianceStatus,
  ilg: TemplateFieldsReportsIlg,
  objectivemodel: TemplateFieldsReportsObjectiveModel,
  objectivemodelmeasures: TemplateFieldsReportsObjectiveModelMeasures,
  objectivebusinessarea: TemplateFieldsReportsObjectiveBusinessarea,
  objectivebusinessareameasures: TemplateFieldsReportsObjectiveBusinessareaMeasures,
  status: TemplateFieldsReportsStatus,
  scheduleddistribution: TemplateFieldsReportsScheduledDistribution,
  pomoverview: TemplateFieldsReportsPomOverview,
  overview: TemplateFieldsReportsOverview,
  initiativeupdate: TemplateFieldsReportsInitiativeUpdate,
  initiativeinventory: TemplateFieldsReportsInitiativeInventory,
  effectcontrolling: TemplateFieldsReportsEffectControlling,
  kpi: TemplateFieldsReportsKpi,
  focusmaster: TemplateFieldsReportsFocusMaster,
  focuspmo: TemplateFieldsReportsFocusPmo,
  qreport: TemplateFieldsReportsQReportSkeleton,
  strategyoverview: TemplateFieldsReportsStrategyOverview,
  changeit: TemplateFieldsReportsChangeIT,
  rollout: TemplateFieldsReportsRollout,
  factories: TemplateFieldsReportsFactories
};
