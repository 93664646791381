import { NgModule, ModuleWithProviders } from '@angular/core';
import { ValueMinerAPIUrl, ValueMinerMessagingAPIUrl, ValueMinerExportAPIUrl, ValueMinerOAuthUrl, ValueMinerGoUrl } from './tokens';
import {
  API, MessagingAPI, BackendService, BackendMessagingService, TokenService, Socket,
  ExportAPI, GoAPI, BackendGoService
} from './shared/index';
import { BackendExportService } from './shared/backend.export.service';
import { BackendOAuthService } from './shared/backend.oauth.service';
import { OAuthAPI } from './shared/api.oauth.service';

@NgModule({
  providers: [
    TokenService,
    BackendService,
    BackendMessagingService,
    BackendExportService,
    BackendOAuthService,
    BackendGoService,
    Socket,
    API,
    MessagingAPI,
    ExportAPI,
    OAuthAPI,
    GoAPI
  ]
})
export class ConnectorModule {
  static forRoot(apiURL: string, messagingApiURL: string, exportApiURL: string, oauthApiURL: string, goURL: string): ModuleWithProviders {
    return {
      ngModule: ConnectorModule,
      providers: [
        {provide: ValueMinerAPIUrl, useValue: apiURL},
        {provide: ValueMinerMessagingAPIUrl, useValue: messagingApiURL},
        {provide: ValueMinerExportAPIUrl, useValue: exportApiURL},
        {provide: ValueMinerOAuthUrl, useValue: oauthApiURL},
        {provide: ValueMinerGoUrl, useValue: goURL},
      ]
    };
  }
}
