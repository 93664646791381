import { HumanResourceState, HumanResource, HumanResourceRelationships } from './humanresources.models';
import { Action } from '../shared/common.action';
import { HumanResourceAction } from './humanresources.actions';
import { JSONAPIDeserializerService } from '../shared/jsonapi-deserializer.service';
import { Utils } from '../shared/utils';

export default function reducer(state = new HumanResourceState(), action: Action): HumanResourceState {
  switch (action.type) {
    case HumanResourceAction.LOAD:
      return <HumanResourceState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case HumanResourceAction.LOAD_SUCCESS: {
      const deserializer = new JSONAPIDeserializerService<HumanResource, HumanResourceRelationships>(HumanResource.fromJSONAPI, HumanResourceRelationships.fromJSONAPI);
      const { entities, relationships } = deserializer.deserialize(action.payload);

      return <HumanResourceState>Utils.updateState<HumanResourceState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case HumanResourceAction.LOAD_FAIL:
      return <HumanResourceState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );

    case HumanResourceAction.CREATE:
      return <HumanResourceState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case HumanResourceAction.CREATE_SUCCESS: {
      const deserializer = new JSONAPIDeserializerService<HumanResource, HumanResourceRelationships>(HumanResource.fromJSONAPI, HumanResourceRelationships.fromJSONAPI);
      const { entities, relationships } = deserializer.deserialize(action.payload.response);
      return <HumanResourceState>Utils.updateState<HumanResourceState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case HumanResourceAction.CREATE_FAIL: {
      return <HumanResourceState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case HumanResourceAction.UPDATE:
      return <HumanResourceState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case HumanResourceAction.UPDATE_SUCCESS: {
      const deserializer = new JSONAPIDeserializerService<HumanResource, HumanResourceRelationships>(HumanResource.fromJSONAPI, HumanResourceRelationships.fromJSONAPI);
      const { entities, relationships } = deserializer.deserialize(action.payload.response);

      return <HumanResourceState>Utils.updateState<HumanResourceState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case HumanResourceAction.UPDATE_FAIL: {
      return <HumanResourceState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case HumanResourceAction.REMOVE:
      return <HumanResourceState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case HumanResourceAction.REMOVE_SUCCESS: {
      const deserializer = new JSONAPIDeserializerService<HumanResource, HumanResourceRelationships>(HumanResource.fromJSONAPI, HumanResourceRelationships.fromJSONAPI);
      const { entities, relationships } = deserializer.deserialize(action.payload.response);
      return <HumanResourceState>Utils.deleteFromState<HumanResourceState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case HumanResourceAction.REMOVE_FAIL: {
      return <HumanResourceState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case HumanResourceAction.CONNECT:
      return <HumanResourceState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case HumanResourceAction.CONNECT_SUCCESS: {
      const deserializer = new JSONAPIDeserializerService<HumanResource, HumanResourceRelationships>(HumanResource.fromJSONAPI, HumanResourceRelationships.fromJSONAPI);
      const { entities, relationships } = deserializer.deserialize(action.payload.response);

      return <HumanResourceState>Utils.updateState<HumanResourceState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case HumanResourceAction.CONNECT_FAIL: {
      return <HumanResourceState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case HumanResourceAction.DISCONNECT:
      return <HumanResourceState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case HumanResourceAction.DISCONNECT_SUCCESS: {
      const deserializer = new JSONAPIDeserializerService<HumanResource, HumanResourceRelationships>(HumanResource.fromJSONAPI, HumanResourceRelationships.fromJSONAPI);
      const { entities, relationships } = deserializer.deserialize(action.payload.response);

      return <HumanResourceState>Utils.updateState<HumanResourceState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case HumanResourceAction.DISCONNECT_FAIL: {
      return <HumanResourceState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    default:
      return state;
  }

}
