import { List } from 'immutable';

import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { LegendItem } from '../../../../shared/legend/legend.item';
import { Node } from '../../../../shared/api/nodes';

export class ColorLabelProviderServiceDifference extends ColorLabelProviderAbstract {

  private static LOW = '#9ac93e';
  private static MEDIUM = '#EDD515';
  private static HIGH = '#E50000';

  public color(element: any) {
    switch (this.calculate(element)) {
      case 1:
        return [ColorLabelProviderServiceDifference.HIGH];
      case 2:
        return [ColorLabelProviderServiceDifference.MEDIUM];
      case 3:
        return [ColorLabelProviderServiceDifference.LOW];
      default:
        return super.color();
    }
  }

  public legend() {
    return List<LegendItem>([
      new LegendItem({ key: 'difference.low', color: ColorLabelProviderServiceDifference.LOW, label: 'LEGEND.DIFFERENCE.LOW', field: 'difference', value: 3 }),
      new LegendItem({ key: 'difference.medium', color: ColorLabelProviderServiceDifference.MEDIUM, label: 'LEGEND.DIFFERENCE.MEDIUM', field: 'difference', value: 2 }),
      new LegendItem({ key: 'difference.high', color: ColorLabelProviderServiceDifference.HIGH, label: 'LEGEND.DIFFERENCE.HIGH', field: 'difference', value: 1 })
    ]);
  }

  public calculate(node: Node) {
    let result = node.budget - node.budgetCalculated;
    const negative = result < 0;
    result = node.budget === 0 ? 100 : (Math.abs(result) / node.budget) * 100;
    result = negative ? -result : result;
    return result <= -30 ? 1 : (result < 0 ? 2 : 3);
  }

}
