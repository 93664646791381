export const TemplateFieldsReportsInitiativeInventory = [
  {
    type: 'table',
    settings: {
      /* Request setting: Load the tree as well */
      requestLoadTree: true,
      /* Request setting: Filter the result */
      requestFilter: 'initiativeinventory',
      /* Request setting: Load the secondary costs */
      requestLoadSecondaryCosts: false,
      /* The header background color */
      headerBackgroundColor: '#ff6600',
      /* Group the elements by model */
      groupByModels: false,
      /* Use a thousand seperator for numeric values */
      thousandsSeperator: '´',
      /* Show the footer */
      showFooter: true,
      /* Show break before total row at the end of the table */
      showBreakBeforeTotals: false,
      /* Show total row after model group */
      showTotalsAfterModel: false,
      /* Show total row at the end of the table */
      showTotals: true,
      /* Break the collapsing on level border */
      breakCollapseOnLevel: true,
      /* Exports */
      exports: ['excel', 'pdf'], //  excel, pdf
      /* Show wizard button */
      wizardButton: true,
      /* Show table models select box */
      showModelsSelectBox: false,
    },
    configuration: {
      fields: [
        { entry: { key: 'number', label: 'REPORTS.INITIATIVEINVENTORY.NUMBER', controlType: 'group', pdfWidth: 30 }, children: [
          { entry: { key: 'number', controlType: 'primary', label: 'REPORTS.INITIATIVEINVENTORY.NUMBER', calculated: true, fixedWidth: 30, pdfWidth: 30,  editable: false, displayLabel: false, showTotal: false }}
        ]},
        { entry: { key: 'name', label: 'REPORTS.INITIATIVEINVENTORY.NAME', controlType: 'group', pdfWidth: 50 }, children: [
          { entry: { key: 'name', controlType: 'textbox', type: 'text', label: 'REPORTS.INITIATIVEINVENTORY.NAME', fixedWidth: 115, pdfWidth: 50, displayLabel: false }}
        ]},
        { entry: { key: 'commercialStatus', label: 'REPORTS.INITIATIVEINVENTORY.COMMERCIALSTATUS.TITLE', controlType: 'group', pdfWidth: 40}, children: [
          { entry: { key: 'commercialStatus', type: 'number', controlType: 'dropdown', displayLabel: false, fixedWidth: 81, pdfWidth: 40, label: 'REPORTS.INITIATIVEINVENTORY.COMMERCIALSTATUS.TITLE', options: [
            { key: 0, value: 'REPORTS.INITIATIVEINVENTORY.COMMERCIALSTATUS.STATUS0' },
            { key: 1, value: 'REPORTS.INITIATIVEINVENTORY.COMMERCIALSTATUS.STATUS1' },
            { key: 2, value: 'REPORTS.INITIATIVEINVENTORY.COMMERCIALSTATUS.STATUS2' }
          ]}}
        ]},
        { entry: { key: 'uncertainty', label: 'REPORTS.INITIATIVEINVENTORY.FIELD', controlType: 'group', pdfWidth: 50 }, children: [
          { entry: { key: 'uncertainty', controlType: 'dropdown', label: 'REPORTS.INITIATIVEINVENTORY.UNCERTAINTY', fixedWidth: 82, pdfWidth: 50, hasNull: true, source: 'development', displayLabel: false }}
        ]},
        { entry: { key: 'sizeType', label: 'REPORTS.INITIATIVEINVENTORY.SIZETYPE', controlType: 'group', pdfWidth: 50 }, children: [
          { entry: { key: 'sizeType', type: 'number', controlType: 'dropdown', label: 'REPORTS.INITIATIVEINVENTORY.SIZETYPE', fixedWidth: 78, pdfWidth: 50, displayLabel: false, options: [
            { key: 0, value: 'REPORTS.INITIATIVEINVENTORY.NOTAPPLICABLE' },
            { key: 1, value: 'REPORTS.INITIATIVEINVENTORY.KI' },
            { key: 2, value: 'REPORTS.INITIATIVEINVENTORY.BI' },
            { key: 3, value: 'REPORTS.INITIATIVEINVENTORY.AI' },
            { key: 4, value: 'REPORTS.INITIATIVEINVENTORY.OTHERS' }
          ]}}
        ]},
        { entry: { key: 'riskProfileCategory', label: 'REPORTS.INITIATIVEINVENTORY.RISKPROFILECATEGORY', controlType: 'group', pdfWidth: 50 }, children: [
          { entry: { key: 'riskProfileCategory', controlType: 'dropdown',  label: 'REPORTS.INITIATIVEINVENTORY.RISKPROFILECATEGORY', fixedWidth: 82, pdfWidth: 50, displayLabel: false, options: [
            { key: 0, value: 'REPORTS.INITIATIVEINVENTORY.NOTAPPLICABLE' },
            { key: 1, value: 'REPORTS.INITIATIVEINVENTORY.GMAA' },
            { key: 2, value: 'REPORTS.INITIATIVEINVENTORY.CUSTOM' }
          ]}}
        ]},
        { entry: { key: 'responsible', label: 'REPORTS.INITIATIVEINVENTORY.RESPONSIBLE', controlType: 'group', pdfWidth: 50 }, children: [
          { entry: { key: 'responsibleId', controlType: 'dropdown', label: 'REPORTS.INITIATIVEINVENTORY.RESPONSIBLE', hasNull: true, fixedWidth: 72, pdfWidth: 50, displayLabel: false, source: 'humanresources' }}
        ]},
        { entry: { key: 'temper', label: 'REPORTS.INITIATIVEINVENTORY.TEMPER', controlType: 'group', pdfWidth: 60 }, children: [
          { entry: { key: 'technical', type: 'number', controlType: 'dropdown', displayLabel: false, label: 'REPORTS.INITIATIVEINVENTORY.TEMPER', fixedWidth: 54, pdfWidth: 60, options: [
            { key: 0, value: 'REPORTS.INITIATIVEINVENTORY.NOTAPPLICABLE' },
            { key: 1, value: 'REPORTS.INITIATIVEINVENTORY.L1' },
            { key: 2, value: 'REPORTS.INITIATIVEINVENTORY.L2' },
            { key: 3, value: 'REPORTS.INITIATIVEINVENTORY.L3' },
            { key: 4, value: 'REPORTS.INITIATIVEINVENTORY.L4' },
            { key: 5, value: 'REPORTS.INITIATIVEINVENTORY.L5' }
          ]}}
        ]},
        { entry: { key: '', label: 'REPORTS.INITIATIVEINVENTORY.EFFECTCONTROLLING', params: 'Y', controlType: 'group', pdfWidth: 38 }, children: [
          { entry: { key: 'costBudget', type: 'number', controlType: 'textbox', label: 'REPORTS.INITIATIVEINVENTORY.COSTBUDGET', pdfWidth: 38, fixedWidth: 75, nodeBucket: true, nodeBucketId: 'Y' }},
          { entry: { key: 'benefitBudget', type: 'number', controlType: 'textbox', label: 'REPORTS.INITIATIVEINVENTORY.BENEFITBUDGET', pdfWidth: 38, fixedWidth: 75, nodeBucket: true, nodeBucketId: 'Y' }},
          { entry: { key: 'investBudget', type: 'number', controlType: 'textbox', label: 'REPORTS.INITIATIVEINVENTORY.INVESTBUDGET', pdfWidth: 38, fixedWidth: 75, nodeBucket: true, nodeBucketId: 'Y' }},
          { entry: { key: 'costActual', type: 'number', controlType: 'textbox', label: 'REPORTS.INITIATIVEINVENTORY.COSTACTUAL', pdfWidth: 38, fixedWidth: 75, nodeBucket: true, nodeBucketId: 'Y' }}
        ]},
        { entry: { key: '', label: 'REPORTS.INITIATIVEINVENTORY.EFFECTCONTROLLING', params: 'Y+1', controlType: 'group', pdfWidth: 38 }, children: [
          { entry: { key: 'costBudgetY1', field: 'costBudget', type: 'number', controlType: 'textbox', label: 'REPORTS.INITIATIVEINVENTORY.COSTBUDGET', pdfWidth: 38, fixedWidth: 75, nodeBucket: true, nodeBucketId: 'Y+1' }},
          { entry: { key: 'benefitBudgetY1', field: 'benefitBudget', type: 'number', controlType: 'textbox', label: 'REPORTS.INITIATIVEINVENTORY.BENEFITBUDGET', pdfWidth: 38, fixedWidth: 75, nodeBucket: true, nodeBucketId: 'Y+1' }},
          { entry: { key: 'investBudgetY1', field: 'investBudget', type: 'number', controlType: 'textbox', label: 'REPORTS.INITIATIVEINVENTORY.INVESTBUDGET', pdfWidth: 38, fixedWidth: 75, nodeBucket: true, nodeBucketId: 'Y+1' }},
          { entry: { key: 'costActualY1', field: 'costActual', type: 'number', controlType: 'textbox', label: 'REPORTS.INITIATIVEINVENTORY.COSTACTUAL', pdfWidth: 38, fixedWidth: 75, nodeBucket: true, nodeBucketId: 'Y+1' }}
        ]},
        { entry: { key: '', label: 'REPORTS.INITIATIVEINVENTORY.EFFECTCONTROLLING', params: 'Y+2', controlType: 'group', pdfWidth: 38 }, children: [
          { entry: { key: 'costBudgetY2', field: 'costBudget', type: 'number', controlType: 'textbox', label: 'REPORTS.INITIATIVEINVENTORY.COSTBUDGET', pdfWidth: 38, fixedWidth: 75, nodeBucket: true, nodeBucketId: 'Y+2' }},
          { entry: { key: 'benefitBudgetY2', field: 'benefitBudget', type: 'number', controlType: 'textbox', label: 'REPORTS.INITIATIVEINVENTORY.BENEFITBUDGET', pdfWidth: 38, fixedWidth: 75, nodeBucket: true, nodeBucketId: 'Y+2' }},
          { entry: { key: 'investBudgetY2', field: 'investBudget', type: 'number', controlType: 'textbox', label: 'REPORTS.INITIATIVEINVENTORY.INVESTBUDGET', pdfWidth: 38, fixedWidth: 75, nodeBucket: true, nodeBucketId: 'Y+2' }},
          { entry: { key: 'costActualY2', field: 'costActual', type: 'number', controlType: 'textbox', label: 'REPORTS.INITIATIVEINVENTORY.COSTACTUAL', pdfWidth: 38, fixedWidth: 75, nodeBucket: true, nodeBucketId: 'Y+2' }}
        ]}
      ],
      additionalfields: []
    }
  }
];
