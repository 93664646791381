export const TemplateFieldsSunburst = {
  tabs: [
    {
      entry: { key: 'header1', label: '' },
      children: [
        { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.NAME', readonly: true }},
        { entry: { key: 'description', controlType: 'text', label: 'NODE.DATASHEET.FIELDS.DESCRIPTION', readonly: true }}
      ]
    }
  ]
};
