import { Store } from '@ngrx/store';

import { APIState } from '../reducer';
import { commonSelectors } from '../shared';
import { compose } from '../../utilities/compose';

export function getState() {
  return (state$: Store<APIState>) => state$
    .select((s: APIState) => s.user);
}

export function getUser() {
  return compose(commonSelectors.getFirst(), getState());
}

export function getIsBusy() {
  return compose(commonSelectors.getIsBusy(), getState());
}

export function getDiff() {
  return compose(commonSelectors.getDiff(), getState());
}
