export const TemplateFieldsNodesCreateToDo = {
  tabs: [
    {
      entry: { key: 'header1', label: '' },
      children: [
        { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.NAME', maxLength: 255, validations: [ 'required' ], validationMessages: { required: 'DATASHEET.VALIDATION.REQUIRED.NAME' } }},
        { entry: { key: 'targetDate', type: 'datetime', controlType: 'date', label: 'NODE.DATASHEET.FIELDS.TARGETDATE', validations: [ 'required' ], validationMessages: { required: 'DATASHEET.VALIDATION.REQUIRED.TARGETDATE' } }}
      ]
    }
  ]
};
