import { INotification } from '../connector';

import { IPayload } from '../shared/common.models';
import { Action } from '../shared/common.action';

export class SettingsAction {
  static key = 'Settings';

  static LOAD = `[${SettingsAction.key}] Load`;
  static LOAD_SUCCESS = `[${SettingsAction.key}] Load success`;
  static LOAD_FAIL = `[${SettingsAction.key}] Load fail`;

  static UPDATE = `[${SettingsAction.key}] Update`;
  static UPDATE_GO = `[${SettingsAction.key}] Update Go`;
  static UPDATE_SUCCESS = `[${SettingsAction.key}] Update success`;
  static UPDATE_FAIL = `[${SettingsAction.key}] Update fail`;

  static REMOVE = `[${SettingsAction.key}] Remove`;
  static REMOVE_SUCCESS = `[${SettingsAction.key}] Remove success`;
  static REMOVE_FAIL = `[${SettingsAction.key}] Remove fail`;

  public load(): Action {
    return {
      type: SettingsAction.LOAD,
    };
  }

  public loadSuccess(settings: any): Action {
    return {
      type: SettingsAction.LOAD_SUCCESS,
      payload: {
        response: settings
      }
    };
  }

  public loadFail(error: any): Action {
    return {
      type: SettingsAction.LOAD_FAIL,
      payload: {
        request: undefined,
        response: error
      }
    };
  }

  public update(data: any): Action {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: SettingsAction.UPDATE,
      payload: data
    };
  }

  public updateGo(data: any): Action {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: SettingsAction.UPDATE_GO,
      payload: data
    };
  }

  public updateSuccess(payload: IPayload, response: any): Action {
    return {
      type: SettingsAction.UPDATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public updateFail(payload: IPayload, error: any): Action {
    return {
      type: SettingsAction.UPDATE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public remove(id): Action {
    return {
      type: SettingsAction.REMOVE,
      payload: id
    };
  }

  public removeSuccess(payload: IPayload, response: any): Action {
    return {
      type: SettingsAction.REMOVE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public removeFail(payload: IPayload, error: any): Action {
    return {
      type: SettingsAction.REMOVE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

}
