import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { CoreStatus, TreeNode } from '../../../core/interface/core.interface';

export class ColorLabelProviderServiceStatus extends ColorLabelProviderAbstract {

  public color(element: TreeNode) {

    switch (<any> element.status) {
      case 0:
      case '0':
        return [CoreStatus['0'].color];
      case 100:
      case '100':
        return [CoreStatus['100'].color];
      case 110:
      case '110':
        return [CoreStatus['110'].color];
      case 120:
      case '120':
        return [CoreStatus['120'].color];
      case 130:
      case '130':
        return [CoreStatus['130'].color];
      case 200:
      case '200':
        return [CoreStatus['200'].color];
      default:
        return super.color();
    }

  }

  public label(element: any, value: any) {
    const pre = this.translateService.instant('RADIAL.DEFAULT.DISPLAY.LABEL.TOP.STATUS') + ': ';
    switch (element.status) {
      case 0:
        return pre + this.translateService.instant(CoreStatus['0'].text);
      case 100:
        return pre + this.translateService.instant(CoreStatus['100'].text);
      case 110:
        return pre + this.translateService.instant(CoreStatus['110'].text);
      case 120:
        return pre + this.translateService.instant(CoreStatus['120'].text);
      case 130:
        return pre + this.translateService.instant(CoreStatus['130'].text);
      case 200:
        return pre + this.translateService.instant(CoreStatus['200'].text);
      default:
        return super.label();
    }
  }

  public legend() {
    return [
      { key: 'status.pending', color: CoreStatus['0'].color, label: CoreStatus['0'].text, field: 'status', value: 0 },
      { key: 'status.indefine', color: CoreStatus['100'].color, label: CoreStatus['100'].text, field: 'status', value: 100 },
      { key: 'status.indevelop', color: CoreStatus['110'].color, label: CoreStatus['110'].text, field: 'status', value: 110 },
      { key: 'status.inverify', color: CoreStatus['120'].color, label: CoreStatus['120'].text, field: 'status', value: 120 },
      { key: 'status.inaccept', color: CoreStatus['130'].color, label: CoreStatus['130'].text, field: 'status', value: 130 },
      { key: 'status.completed', color: CoreStatus['200'].color, label: CoreStatus['200'].text, field: 'status', value: 200 }
    ];
  }

}
