import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { Activity } from '../../../../shared/api/activities';

export class ColorLabelProviderServiceActivityType extends ColorLabelProviderAbstract {

  private static SPECIFY = '#999999';
  private static DEVELOP = '#000000';
  private static VERIFY = '#00FF00';
  private static ACCEPT = '#9ac93e';

  private static LABELDEF = '#000000';
  private static LABELINEXEC = '#FFFFFF';

  public color(element: Activity) {

    switch (element.type) {
      case 0:
        return ColorLabelProviderServiceActivityType.SPECIFY;
      case 1:
        return ColorLabelProviderServiceActivityType.DEVELOP;
      case 2:
        return ColorLabelProviderServiceActivityType.VERIFY;
      case 3:
        return ColorLabelProviderServiceActivityType.ACCEPT;
      default:
        return super.color();
    }

  }

  public label(element?: Activity){

    if (!element) {
      return ColorLabelProviderServiceActivityType.LABELDEF;
    }

    switch (element.type) {
      case 1:
        return ColorLabelProviderServiceActivityType.LABELINEXEC;
      default:
        return ColorLabelProviderServiceActivityType.LABELDEF;
    }
  }

}
