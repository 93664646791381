import { Injectable, Inject } from '@angular/core';
import { ValueMinerAPIUrl, ValueMinerExportAPIUrl, ValueMinerGoUrl, ValueMinerMessagingAPIUrl, ValueMinerOAuthUrl } from '../tokens';
import { TokenService } from './token.service';
import { BackendService } from './backend.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class BackendExportService extends BackendService {

  constructor(@Inject(ValueMinerAPIUrl) apiUrl: string,
              @Inject(ValueMinerMessagingAPIUrl) messagingApiUrl: string,
              @Inject(ValueMinerExportAPIUrl) exportApiUrl: string,
              @Inject(ValueMinerOAuthUrl) oauthApiUrl: string,
              @Inject(ValueMinerGoUrl) goUrl: string,
              http: HttpClient,
              token: TokenService) {
    super(apiUrl, messagingApiUrl, exportApiUrl, oauthApiUrl, goUrl, http, token);
    this.apiUrl = exportApiUrl;
  }

  public post(path: string, body: any): Observable<any> {
    const url = BackendService.pathJoin([this.apiUrl, path]);
    switch (body.data.type) {
      case 'html':
        if (body.data.attributes.configuration.type === 'pdf') {
          return this.blobRequest('POST', url, 'application/pdf', body);
        } else {
          return this.blobRequest('POST', url, 'image/' + body.data.attributes.configuration.data.imageType, body);
        }
      case 'pdf':
        return this.blobRequest('POST', url, 'application/pdf', body);
      case 'excel':
        return this.blobRequest('POST', url, 'application/vnd.openxmlformats-officedocument. spreadsheetml.sheet', body);
      case 'png':
        return this.blobRequest('POST', url, 'image/png', body);
      case 'jpg':
        return this.blobRequest('POST', url, 'image/jpeg', body);
      case 'word':
        return this.blobRequest('POST', url, 'application/vnd.openxmlformats-officedocument. wordprocessingml.document', body);
      default:
        return this.request('POST', url, body);
    }
  }

  protected blobRequest(method: string, url: string, contentType: string, body: {} = {}): Observable<{}> {
    return this.sendBlobRequest(this.token.get(), method, url, body)
      .map((response: any) => {
        return new Blob([response], {
          type: contentType
        });
      })
      .catch((error: any) => {
        if (error.status === 401) {
          return this.sendRequest(this.token.refresh(), method, url, body).map((response: Response) => response.json());
        }
        return Observable.throw(error);
      }).catch((err: any) => {
        if (typeof err.json === 'function') {
          err = err.json();
        }
        return Observable.throw(err);
      });
  }

  protected sendBlobRequest(token: Observable<string>, method: string, url: string, body: {} = {}): Observable<Object> {
    return token.flatMap((accessToken: string) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/vnd.api.v2+json',
        'Authorization': 'Bearer ' + accessToken
      });

      return this.http.request(method, url, {
        body: JSON.stringify(body),
        headers: headers,
        responseType: 'blob'
      });
    });
  }

}
