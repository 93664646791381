import { Injectable } from '@angular/core';
import { isNullOrUndefined, isNumber, isObject } from 'util';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Map, OrderedMap } from 'immutable';
import { UUID } from 'angular2-uuid';
import * as moment from 'moment';

import { CoreGroup, CoreHumanResource, CoreMultiTransfer, CoreNodeTypes, CoreOptions, CoreStatus, CoreTransfer, TreeActivity, TreeNode, TreeRelationship } from '../../../core/interface/core.interface';
import {
  NodeCreate,
  NODES_TYPE_CAPACITY,
  NODES_TYPE_CHILD, NODES_TYPE_COLUMN,
  NODES_TYPE_FORM_OPTION,
  NODES_TYPE_GROUP,
  NODES_TYPE_HUMANRESOURCE,
  NODES_TYPE_REMINDER,
  NODES_TYPE_REQUIREMENT, NODES_TYPE_TEAM
} from '../../../shared/api/nodes/nodes.models';
import { Datum } from '../../../shared/utilities/datum';
import { CoreTransformer } from '../../../core/transformer/core.transformer';
import { Activity } from '../../../shared/api/activities';
import { ActivityRelationships } from '../../../shared/api/activities/activities.models';
import { FormElement, FormEntry, FormInterface, FormOption, FormResult } from '../interface/form.interface';
import { PayloadService } from '../../../services/payload/payload.service';
import { IPayload } from '../../../services/payload/payload.interface';
import { environment } from '../../../../environments/environment';
import { HumanResource } from '../../../shared/api/humanresources';
import { CoreService } from '../../../core/service/core.service';
import { TableColumn, TableConfigurationOptions } from '../../table/interface/table.interface';
import { EisenhowerService } from '../../eisenhower/service/eisenhower.service';
import { Group, RelationshipCreate } from '../../../shared';
import { CoreUtilities } from '../../../core/utilities/core.utilities';
import { AppGlobal } from '../../../app.global';
import { SubscriptionService } from '../../../shared/utilities/subscription';
import { FormTableModalComponent } from '../modal/form.table.modal';
import { FormWidgetFormElement } from '../../../widgets/form/interface/form.widget.interface';
import { ActionButtonsService } from '../../../services/action-buttons/action-buttons.service';
import { NotificationsService } from '../../notifications/service/notifications.service';

@Injectable()
export class FormService {

  private humanResourcesMap = OrderedMap<string, CoreHumanResource>();
  private marketsMap = OrderedMap<string, TreeNode>();
  private matrixMap = OrderedMap<string, TreeNode>();

  public coreService: CoreService;
  public eisenhowerService: EisenhowerService;
  public actionButtonsService: ActionButtonsService;
  public notificationsService: NotificationsService

  protected additionalInfos = {};

  private subscriptionService = new SubscriptionService();

  public constructor(private translateService: TranslateService, private coreTransformer: CoreTransformer, private coreUtilities: CoreUtilities, private appGlobal: AppGlobal) {}

  public setActionButtonService() {
    this.actionButtonsService.formService = this;
    this.actionButtonsService.coreService = this.coreService;
    this.actionButtonsService.notificationsService = this.notificationsService;
  }

  public setHumanResources(humanResourcesMap: OrderedMap<string, CoreHumanResource>) {
    this.humanResourcesMap = humanResourcesMap;
  }

  public setMarkets(marketsMap: OrderedMap<string, TreeNode>) {
    this.marketsMap = marketsMap;
  }

  public setAdditionalInfos(additionalInfos: any) {
    this.additionalInfos = additionalInfos;
    return this;
  }

  public getCoreTransferFromFormResult(formResult: FormResult, form: FormInterface, treeNode: TreeNode | TreeActivity, create = false): CoreMultiTransfer {
    let transfers: CoreMultiTransfer = {
      create: <CoreTransfer> { nodes: [], relationships: [], activities: [], nodeStructures: [], ids: [] },
      update: <CoreTransfer> { nodes: [], relationships: [], activities: [] },
      delete: <CoreTransfer> { nodes: [], relationships: [], activities: [] }
    };

    let buckets = Map<string, TreeActivity>();
    let nodeDelta = Map<string, Map<string, any>>();
    let activityDelta = Map<string, Map<string, any>>();
    let bucketDelta = Map<string, Map<string, Map<string, any>>>();

    /* Get the entries map for form */
    const entriesMap = this.getEntriesKeyMap(form.tabs);

    /* Iterate over delta */
    formResult.delta.forEach((delta, key) => {

      /* Get entry */
      const entry = entriesMap.get(key);
      if (!isNullOrUndefined(entry)) {

        /* Set entry node */
        let entryTreeNode = treeNode;

        /* Check if it's a node type node */
        if (!isNullOrUndefined(entry.sourceNodeType)) {
          const sourceNode = this.coreService.searchBy({
            filters: [{
              by: 'nodeType',
              value: entry.sourceNodeType
            }]
          }, (<TreeNode>treeNode).children, true, 'children', true)[0];
          if (!isNullOrUndefined(sourceNode)) {
            entryTreeNode = sourceNode;
          }
        }

        /* In case we have an activity being selected */
        if (entryTreeNode.internalType === 'treeActivity') {
          /* Set the value */
          activityDelta = activityDelta.set(entryTreeNode.id, (activityDelta.has(entryTreeNode.id) ? activityDelta.get(entryTreeNode.id) : Map<string, any>()).set(key, delta));
        } else if (entry.nodeBucket) {
          const id = isNullOrUndefined((<TreeNode> entryTreeNode).dataId) ? entryTreeNode.id : (<TreeNode> entryTreeNode).dataId;
          /* Get the bucket */
          let bucket = this.getBucket(<TreeNode>entryTreeNode);
          /* Get the bucket from buckets */
          if (buckets.has(bucket.id)) {
            bucket = buckets.get(bucket.id);
          }
          /* Set the bucket delta */
          const d1 = bucketDelta.has(id) ? bucketDelta.get(id) : Map<string, Map<string, any>>();
          const d2 = d1.has(bucket.id) ? d1.get(bucket.id) : Map<string, any>();
          bucketDelta = bucketDelta.set(id, d1.set(bucket.id, d2.set(key, delta)));
          /* Store the bucket */
          buckets = buckets.set(bucket.id, bucket);
        } else {
          switch (key) {
            case 'dynamic-reminder':
              if (!isNullOrUndefined(delta) && delta !== '') {
                const mom = moment(delta.value).format('YYYY-MM-DDTHH:mm:ss');
                const data = Map().set('targetDate', mom);
                if (!isNullOrUndefined(delta.reminder.dataId)) {
                  (<IPayload[]> transfers.update.nodes).push(<IPayload> { id: delta.reminder.dataId, data: data });
                } else {
                  (<NodeCreate[]> transfers.create.nodes).push(<NodeCreate> new NodeCreate(data.toJS()).set('name', 'reminder-' + mom).set('nodeType', NODES_TYPE_REMINDER).set('id', delta.reminder.id));
                  (<RelationshipCreate[]> transfers.create.relationships).push(new RelationshipCreate({ id: UUID.UUID(), parent: delta.element.id, child: delta.reminder.id }));
                }
              }
              break;
            case 'dynamic-comments':
              break;
            case 'dynamic-children':
            case 'dynamic-parents':
              const data = formResult.delta.get(key);
              /* Data */
              const treeNode = <TreeNode> data.element;
              const selectedTreeNodes = <TreeNode[]> data.selected;
              const selectedTreeNodeIds = !isNullOrUndefined(selectedTreeNodes) ? selectedTreeNodes.map(d => d.id) : [];
              const initialSelected = <TreeNode[]> data.initialSelected;
              const initialSelectedIds = !isNullOrUndefined(initialSelected) ? initialSelected.map(d => d.id) : [];
              const notSelected = isNullOrUndefined(initialSelected) ? [] : initialSelected.filter(d => selectedTreeNodeIds.indexOf(d.id) === -1).map(d => {
                const a = d.nodestructures.filter(n => n.split(':')[0] === treeNode.modelId)[0];
                return isNullOrUndefined(a) ? a : a.split(':')[1];
              }).filter(d => !!d);
              if (!isNullOrUndefined(treeNode)) {
                /* Set the model ids */
                transfers.create.modelId = treeNode.modelId;
                transfers.delete.modelId = treeNode.modelId;
                transfers.update.modelId = treeNode.modelId;
                /* Iterate over the selected tree nodes */
                const count = selectedTreeNodes.length;
                for (let i = 0; i < count; i++) {
                  const selectedTreeNode = selectedTreeNodes[i];
                  if (initialSelectedIds.indexOf(selectedTreeNode.id) !== -1) {
                    /* Node is known and was already connected */
                    continue;
                  }
                  if (selectedTreeNode.models.indexOf(treeNode.modelId) !== -1) {
                    /* The node is already part of the model but was not connected so we connect them */
                    const b = selectedTreeNode.nodestructures.filter(n => n.split(':')[0] === treeNode.modelId)[0];
                    const id = isNullOrUndefined(b) ? b : b.split(':')[1];
                    if (!isNullOrUndefined(id)) {
                      if (key === 'dynamic-parents') {
                        (<TreeRelationship[]> transfers.create.relationships).push(<TreeRelationship> { id: UUID.UUID(), parentId: id, childId: treeNode.id });
                      } else {
                        (<TreeRelationship[]> transfers.create.relationships).push(<TreeRelationship> { id: UUID.UUID(), parentId: treeNode.id, childId: id });
                      }
                    }
                  }  else {
                    /* The node is not yet known in the target model so we group it over */
                    (<TreeNode[]> transfers.create.nodeStructures).push(<TreeNode> { id: selectedTreeNode.id, dataId: selectedTreeNode.dataId, name: selectedTreeNode.name });

                    /* Link the grouped node to selected node */
                    if (key === 'dynamic-parents') {
                      (<TreeRelationship[]> transfers.create.relationships).push(<TreeRelationship> { id: UUID.UUID(), parentId: selectedTreeNode.id, childId: treeNode.id });
                    } else {
                      (<TreeRelationship[]> transfers.create.relationships).push(<TreeRelationship> { id: UUID.UUID(), parentId: treeNode.id, childId: selectedTreeNode.id });
                    }

                    /* Check if a parent needs to be linked as well */
                    const parents = selectedTreeNode.parents.filter(parent => parent.checkboxValue === true && parent.modelId !== treeNode.modelId);
                    const count2 = parents.length;
                    for (let i2 = 0; i2 < count2; i2++) {
                      (<TreeRelationship[]> transfers.create.relationships).push(<TreeRelationship> { id: UUID.UUID(), parentId: parents[i2].id, childId: selectedTreeNode.id });
                    }
                  }
                }
                /* Get the relationships to delete */
                if (key === 'dynamic-parents') {
                  (<TreeRelationship[]> transfers.delete.relationships) = this.coreService.getInstantRelationships({ filters: [{ by: 'childId', value: treeNode.id }, { by: 'parentId', value: notSelected }] });
                } else {
                  (<TreeRelationship[]> transfers.delete.relationships) = this.coreService.getInstantRelationships({ filters: [{ by: 'parentId', value: treeNode.id }, { by: 'childId', value: notSelected }] });
                }
              }
              break;
            case 'dynamic-gantt':
              break;
            case 'dynamic-assign':
              const assign = formResult.delta.get('dynamic-assign');
              if (!isNullOrUndefined(transfers) && assign !== '') {
                assign.forEach(assignTransfer => {
                  transfers = this.coreUtilities.concatTransfers(transfers, assignTransfer);
                });
              }
              break;
            default:
              /* Set the value */
              const id = isNullOrUndefined((<TreeNode> entryTreeNode).dataId) ? entryTreeNode.id : (<TreeNode> entryTreeNode).dataId;
              nodeDelta = nodeDelta.set(id, (nodeDelta.has(id) ? nodeDelta.get(id) : Map<string, any>()).set(key, delta));
          }
        }

      }
    });

    /* Generate node payload from map */
    if (nodeDelta.size > 0) {
      nodeDelta.forEach((delta, id) => {
        if (create) {
          (<IPayload[]> transfers.create.nodes).push(<IPayload> { id: id, data: delta });
        } else {
          (<IPayload[]> transfers.update.nodes).push(<IPayload> { id: id, data: delta });
        }
      });
    }

    if (activityDelta.size > 0) {
      activityDelta.forEach((delta, id) => {
        if (isNullOrUndefined(id)) {
          const id = !isNullOrUndefined((<TreeActivity> treeNode).nodeData) ? (<TreeActivity> treeNode).nodeData : (!isNullOrUndefined((<TreeActivity> treeNode).instance) ? (<TreeActivity> treeNode).instance : undefined);
          if (!delta.has('name')) {
            delta = delta.set('name', treeNode.name);
          }
          if (!delta.has('nodebucket')) {
            delta = delta.set('nodebucket', true);
          }
          if (!isNullOrUndefined(id)) {
            (<IPayload[]> transfers.create.activities).push(<IPayload> { id: id, data: delta });
          }
        } else {
          (<IPayload[]> transfers.update.activities).push(<IPayload> { id: id, data: delta });
        }
      });
    }

    /* Create node buckets if needed */
    if (bucketDelta.size > 0) {
      bucketDelta.forEach((d1: Map<string, Map<string, any>>, treeNodeId: string) => {
        d1.forEach((d2: Map<string, any>, bucketId: string) => {
          const bucket = buckets.get(bucketId);
          if (bucket.phantom) {
            /* Create the bucket */
            d2.forEach((value, key) => {
              bucket[key] = value;
            });
            (<IPayload[]> transfers.create.activities).push(<IPayload> { id: treeNodeId, data: PayloadService.toPayload(this.coreTransformer.treeActivityToActivity(bucket)) });
          } else {
            /* Update the bucket */
            (<IPayload[]> transfers.update.activities).push(<IPayload> { id: bucket.id, data: d2 });
          }
        });
      });
    }

    return transfers;
  }

  public getCoreTransferFromForm(formResult: FormResult, form: FormInterface, treeRelationship: TreeRelationship): CoreTransfer {
    const transfer = { nodes: [], relationships: [], activities: [] };

    /* Generate node payload from map */
    if (formResult.delta.size > 0) {
      (<IPayload[]> transfer.relationships).push(<IPayload> { id: treeRelationship.id, data: formResult.delta });
    }

    return transfer;
  }

  public getCoreTransferFromField(formField: TreeNode, value: any, treeNode: TreeNode): CoreMultiTransfer {
    const transfers = {
      create: <CoreTransfer> { nodes: [], relationships: [], activities: [] },
      update: <CoreTransfer> { nodes: [], relationships: [], activities: [] },
      delete: <CoreTransfer> { nodes: [], relationships: [], activities: [] }
    };

    if (formField.formBucket) {
      const bucket = this.getBucket(treeNode);
      /* Set the value */
      bucket[formField.formFieldId] = value;
      if (bucket.phantom) {
        /* Create the bucket */
        (<IPayload[]> transfers.create.activities).push(<IPayload> { id: treeNode.dataId, data: PayloadService.toPayload(this.coreTransformer.treeActivityToActivity(bucket)) });
      } else {
        const data = {};
        data[formField.formFieldId] = value;
        /* Update the bucket */
        (<IPayload[]> transfers.update.activities).push(<IPayload> { id: bucket.id, data: data });
      }
    } else {
      (<IPayload[]> transfers.update.nodes).push(<IPayload> { id: treeNode.dataId, data: Map<string, any>().set(formField.formFieldId, value) });
    }

    return transfers;
  }

  public getCoreTransferHumanResource(formResult: FormResult, coreHumanResource: CoreHumanResource, modelId: string, resolve: Function, fieldNodes?: TreeNode[], treeNode?: TreeNode) {
    const transfers = {
      create: <CoreTransfer> { nodes: [], relationships: [], activities: [], humanResources: [], modelId: modelId },
      update: <CoreTransfer> { nodes: [], relationships: [], activities: [], humanResources: [] },
      delete: <CoreTransfer> { nodes: [], relationships: [], activities: [], humanResources: [] }
    };

    if (coreHumanResource instanceof HumanResource) {
      coreHumanResource = this.coreTransformer.humanResourceToCoreHumanResource(coreHumanResource);
    }
    formResult.delta.forEach((value, key) => coreHumanResource[key] = value);

    if (isNullOrUndefined(coreHumanResource.id)) {
      /* Check if a similar hr is already there */
      const humanResources = <CoreHumanResource[]> this.coreService.getInstantHumanResources();
      let findings = OrderedMap<string, CoreHumanResource>();
      const count = humanResources.length;
      for (let i = 0; i < count; i++) {
        const humanResource = humanResources[i];
        if (humanResource.instanceId === this.coreService.getBusinessAreaInstant().relationships.instance &&
          (humanResource.first_name.indexOf(coreHumanResource.first_name) !== -1 ||
            humanResource.last_name.indexOf(coreHumanResource.last_name) !== -1 ||
            (coreHumanResource.email !== '' && humanResource.email.indexOf(coreHumanResource.email) !== -1))) {
          const id = humanResource.first_name + ';' + humanResource.last_name + ';' + humanResource.email;
          if (!findings.has(id)) {
            findings = findings.set(id, humanResource);
          }
        }
      }
      if (!isNullOrUndefined(fieldNodes) && findings.size > 0) {
        const id = UUID.UUID();
        /* Now get the form modal */
        this.coreService.modal('form-table-modal', (modal: FormTableModalComponent) => {

          modal.title = 'Potential duplicates';

          /* Register the listeners */
          this.subscriptionService.add('select', modal.select.subscribe(coreHumanResource_ => {
            (<NodeCreate[]> transfers.create.nodes).push(<NodeCreate> this.coreTransformer.treeNodeToNodeCreate(<any> this.coreTransformer.coreHumanResourceToTreeNode(coreHumanResource_)).set('id', id).set('responsibleId', parseInt(coreHumanResource_.id)));
            modal.close();
            resolve(transfers);
          }));

          this.subscriptionService.add('cancel', modal.cancel.subscribe(() => {
            resolve(transfers);
          }));

          this.subscriptionService.add('ignore', modal.ignore.subscribe(() => {
            (<HumanResource[]> transfers.create.humanResources).push(<HumanResource> this.coreTransformer.coreHumanResourceToHumanResource(coreHumanResource).set('id', id).remove('name'));
            (<NodeCreate[]> transfers.create.nodes).push(<NodeCreate> this.coreTransformer.treeNodeToNodeCreate(<any> this.coreTransformer.coreHumanResourceToTreeNode(coreHumanResource)).set('id', id));
            modal.close();
            resolve(transfers);
          }));

          /* Show modal */
          modal.show(<any> findings.toArray(), fieldNodes);
        });
      } else {
        const id = UUID.UUID();
        (<HumanResource[]> transfers.create.humanResources).push(<HumanResource> this.coreTransformer.coreHumanResourceToHumanResource(coreHumanResource).set('id', id).remove('name'));
        (<NodeCreate[]> transfers.create.nodes).push(<NodeCreate> this.coreTransformer.treeNodeToNodeCreate(<any> this.coreTransformer.coreHumanResourceToTreeNode(coreHumanResource)).set('id', id));
        resolve(transfers);
      }
    } else {
      if (!isNullOrUndefined(treeNode)) {
        /* Get the deltas */
        let nodeDelta = Map<string, any>();
        Map(this.coreTransformer.coreHumanResourceToTreeNode(coreHumanResource)).forEach((value, key) => {
          if (!isNullOrUndefined(treeNode[key]) && treeNode[key] !== value && key !== 'id' && key !== 'responsibleId') {
            nodeDelta = nodeDelta.set(key, value);
          }
        });
        /* Now build the payloads from deltas */
        (<IPayload[]> transfers.update.nodes).push(<IPayload> { id: treeNode.dataId, data: nodeDelta });
        (<IPayload[]> transfers.update.humanResources).push(<IPayload> { id: '' + coreHumanResource.id, data: formResult.delta });
      }
      resolve(transfers);
    }
  }

  public getCoreTransferGroup(formResult: FormResult, coreGroup: CoreGroup, modelId: string, treeNode?: TreeNode): CoreMultiTransfer {
    const transfers = {
      create: <CoreTransfer> { nodes: [], relationships: [], activities: [], groups: [], modelId: modelId },
      update: <CoreTransfer> { nodes: [], relationships: [], activities: [], groups: [] },
      delete: <CoreTransfer> { nodes: [], relationships: [], activities: [], groups: [] }
    };

    if (coreGroup instanceof Group) {
      coreGroup = this.coreTransformer.groupToCoreGroup(coreGroup);
    }
    formResult.delta.forEach((value, key) => coreGroup[key] = value);

    if (isNullOrUndefined(coreGroup.id)) {
      const id = UUID.UUID();
      (<Group[]> transfers.create.groups).push(<Group> this.coreTransformer.coreGroupToGroup(coreGroup).set('id', id));
      (<NodeCreate[]> transfers.create.nodes).push(<NodeCreate> this.coreTransformer.treeNodeToNodeCreate(<any> this.coreTransformer.coreGroupToTreeNode(coreGroup)).set('id', id));
    } else {
      if (!isNullOrUndefined(treeNode)) {
        /* Get the deltas */
        let nodeDelta = Map<string, any>();
        Map(this.coreTransformer.coreGroupToTreeNode(coreGroup)).forEach((value, key) => {
          if (!isNullOrUndefined(treeNode[key]) && treeNode[key] !== value && key !== 'id' && key !== 'groupId') {
            nodeDelta = nodeDelta.set(key, value);
          }
        });
        /* Now build the payloads from deltas */
        (<IPayload[]> transfers.update.nodes).push(<IPayload> { id: treeNode.dataId, data: nodeDelta });
        (<IPayload[]> transfers.update.groups).push(<IPayload> { id: '' + coreGroup.id, data: formResult.delta });
      }
    }

    return transfers;
  }

  public getReadableValue(fieldNode: TreeNode, treeNode: TreeNode | TreeActivity, fields?: any, tableConfigurationOptions?: TableConfigurationOptions) {
    /* Get the value */
    let value = this.getValue(fieldNode, treeNode);
    /* If there is a conversion assigned */
    if (!isNullOrUndefined(fieldNode.fieldConversion)) {
      value = this.coreTransformer.convertValue(fieldNode.fieldConversion, value);
    }
    /* If id is not known */
    if (isNullOrUndefined(value) && fieldNode.formFieldControlType !== 'calculated' && fieldNode.formFieldControlType !== 'sublevel' && fieldNode.formFieldControlType !== 'assign' && fieldNode.formFieldControlType !== 'violatedParagraph') {
      return '';
    }
    /* Switch by control type */
    switch (fieldNode.formFieldControlType) {
      case 'dropdown':
        switch (fieldNode.formFieldId) {
          case 'nodeType':
            const nodeTypes = CoreNodeTypes.filter(nodeType => nodeType.key === value);
            return nodeTypes.length > 0 ? this.translateService.instant(nodeTypes[0].label) : '';
          default:
            switch (fieldNode.formFieldDropdownValue) {
              case 'humanresources':
                return this.humanResourcesMap.has('' + treeNode.responsibleId) ? this.humanResourcesMap.get('' + treeNode.responsibleId).name : '';
              case 'humanresourceimages':
                let src = '';
                const humanResource = this.humanResourcesMap.get('' + treeNode.responsibleId);
                if (!isNullOrUndefined(humanResource) && !isNullOrUndefined(humanResource.image) && humanResource.image !== '') {
                  src = humanResource.image;
                } else {
                  const id = !isNullOrUndefined(humanResource) ? humanResource.foreign_id : 0;
                  if (!isNullOrUndefined(id) && id !== 0) {
                    src = environment.imageUrl + id;
                  }
                }
                return isNullOrUndefined(src) || src === '' ? '' : '<img class="humanresource" src="' + src + '" />';
              case 'shape':
                const shapeValue = treeNode[fieldNode.formFieldId];
                let shape = 'circle';
                let color = fieldNode.colors[0];
                let style = 'background: ' + color;
                const shapeNode = fieldNode.children.filter(child => '' + child.formId === '' + shapeValue)[0];
                if (!isNullOrUndefined(shapeNode)) {
                  /* Set color */
                  if (shapeNode.color !== '') {
                    color = shapeNode.color;
                  }
                  /* Set shape */
                  if (!isNullOrUndefined(shapeNode.formFieldShape) && shapeNode.formFieldShape !== '') {
                    shape = shapeNode.formFieldShape;
                  }
                  if (shape === 'triangle') {
                    style = 'border-color: transparent transparent ' + color + ' transparent;';
                  } else {
                    style = 'background-color: ' + color + ';';
                  }
                }
                return isNullOrUndefined(shapeNode) ? '' : '<div class="' + shape + '" style="' + style + '"></div>';
              case 'statuscolor':
                const status = isNullOrUndefined(CoreStatus['' + value]) ? CoreStatus['0'] : CoreStatus['' + value];
                return '<div class="circle" style="background: ' + status.color + '"></div>';
              case 'status':
                const statusText = isNullOrUndefined(CoreStatus['' + value]) ? CoreStatus['0'] : CoreStatus['' + value];
                return this.translateService.instant(statusText.text);
              case 'markets':
                const mv = treeNode[fieldNode.formFieldId];
                if (!this.marketsMap.has(mv)) {
                  return '';
                }
                const mvNode = this.marketsMap.get(mv);
                return mvNode.name + ' <span class="float-right fflag fflag-' + mvNode.reference.toUpperCase() + '"></span>';
              case 'overbooked':
                if ((<TreeNode> treeNode).nodeType !== NODES_TYPE_GROUP || isNaN((<TreeNode> treeNode).budgetCalculated)) {
                  return '';
                }
                let val = '#999999';
                const colorNode = fieldNode.unfilteredChildren.filter(child => child.nodeType === NODES_TYPE_FORM_OPTION && parseFloat(child.formId) < (<TreeNode> treeNode).budgetCalculated).sort((a, b) => parseFloat(b.formId) - parseFloat(a.formId))[0];
                if (!isNullOrUndefined(colorNode)) {
                  val = colorNode.color;
                }
                return '<div class="circle" style="background: ' + val + '"></div>';
              default:
                const option = fieldNode.children.filter(child => child.nodeType === NODES_TYPE_FORM_OPTION && '' + child.formId === '' + value)[0];
                return !isNullOrUndefined(option) ? option.name : '';
            }
        }
      case 'calculated':
        return this.getCalculatedValue(fieldNode, treeNode, fields);
      case 'sublevel':
        const margin = (<TreeNode> treeNode).subLevel * 10;
        if ((<TreeNode> treeNode).children.length > 0) {
          if ((<TreeNode> treeNode).collapsed) {
            return '<button rel="node:' + treeNode.id + ':expand" style="margin-left: ' + margin + 'px;" class="button button-icon" data-toggle="tooltip" data-placement="bottom" title="Expand" ><i class="fa fa-angle-down"></i></button>';
          } else {
            return '<button rel="node:' + treeNode.id + ':collapse" style="margin-left: ' + margin + 'px;" class="button button-icon" data-toggle="tooltip" data-placement="bottom" title="Collapse" ><i class="fa fa-angle-up"></i></button>';
          }
        }
        return '<button style="margin-left: ' + margin + 'px;" class="button button-icon button-no-hover"></button>';
      case 'sublevelWithName':
        if (isNullOrUndefined((<TreeNode> treeNode).subLevel)) {
          return '';
        }
        const marginWN = (<TreeNode> treeNode).subLevel * 10;
        const name = isNullOrUndefined((<TreeNode> treeNode).subLevelName) ? treeNode.name : (<TreeNode> treeNode).subLevelName;
        if ((<TreeNode> treeNode).children.length > 0) {
          if ((<TreeNode> treeNode).collapsed) {
            return '<button rel="node:' + treeNode.id + ':expand" style="margin-left: ' + marginWN + 'px;" class="button button-icon" data-toggle="tooltip" data-placement="bottom" title="Expand" ><i class="fa fa-angle-down"></i></button>' + name;
          } else {
            return '<button rel="node:' + treeNode.id + ':collapse" style="margin-left: ' + marginWN + 'px;" class="button button-icon" data-toggle="tooltip" data-placement="bottom" title="Collapse" ><i class="fa fa-angle-up"></i></button>' + name;
          }
        }
        return '<button style="margin-left: ' + marginWN + 'px;" class="button button-icon button-no-hover"></button>' + name;
      case 'checkbox':
        return '<i style="pointer-events: none;" class="fa ' + (value ? 'fa-check-square' : 'fa-square') + '"></i>';
      case 'daterange':
      case 'date':
        return new Datum(value).toEuropeanDateString();
      case 'lastEditedBy':
        return new Promise(resolve => {
          this.coreService.getNodeDataAuditsByNode(<TreeNode> treeNode).then(auditMap => {
            const lastAudit = auditMap.has((<TreeNode> treeNode).dataId) ? auditMap.get((<TreeNode> treeNode).dataId)[0] : undefined;
            if (!isNullOrUndefined(lastAudit) && !isNullOrUndefined(lastAudit.userId)) {
              this.coreService.getHumanResources({ filters: [{ by: 'id', value: lastAudit.userId }] }).take(1).subscribe(humanResources => {
                resolve(humanResources.length > 0 ? humanResources[0].name : '');
              });
            }
          });
        });
      case 'validatedBy':
        return new Promise(resolve => {
          this.coreService.getNodeDataAuditsByNode(<TreeNode> treeNode).then(auditMap => {
            const validatedAudit = auditMap.has((<TreeNode> treeNode).dataId) ? auditMap.get((<TreeNode> treeNode).dataId).filter(audit => '' + audit.after['commercialStatus'] === '1')[0] : undefined;
            if (!isNullOrUndefined(validatedAudit) && !isNullOrUndefined(validatedAudit.userId)) {
              this.coreService.getHumanResources({ filters: [{ by: 'id', value: validatedAudit.userId }] }).take(1).subscribe(humanResources => {
                resolve(humanResources.length > 0 ? humanResources[0].name : '');
              });
            }
          });
        });
      case 'select':
        const active = fieldNode.formId + '-' + treeNode.id === this.appGlobal.tableButtonClickedEvent ? ' button-active' : '';
        return '<button rel="node:' + treeNode.id + ':click:' + fieldNode.formId + '" data-key="' + fieldNode.formId + '" class="button button-selectable' + active + '">' + fieldNode.name + '</button>';
      case 'file-status':
        return '<i class="fa ' + (treeNode.status !== 200 ? 'fa-spinner fa-spin' : 'fa-check') + '"></i>';
      case 'priority-score':
        return !isNullOrUndefined(this.eisenhowerService) ? this.eisenhowerService.getPriorityScore(<TreeNode> treeNode) : 0;
      case 'assign':
         return '<i style="pointer-events: none;" class="fa ' + (fieldNode.selected || this.coreService.isElementAssigned(fieldNode.formFieldId, <TreeNode> treeNode, tableConfigurationOptions.connectNodeType) ? 'fa-check-square' : 'fa-square') + '"></i>';
      case 'action-buttons':
        return !isNullOrUndefined(this.actionButtonsService) ? this.actionButtonsService.setDataNode(<TreeNode> treeNode).setButtonNodes(fieldNode.children).getButtons().join('') : '';
      default:
        return fieldNode.formFieldType === 'number' ? this.coreTransformer.readableNumber(value) : value;
    }
  }

  public getSortableValue(fieldNode: TreeNode, treeNode: TreeNode | TreeActivity, fields?: any) {
    /* Get the value */
    let value = this.getValue(fieldNode, treeNode);
    /* If there is a conversion assigned */
    if (!isNullOrUndefined(fieldNode.fieldConversion)) {
      value = this.coreTransformer.convertValue(fieldNode.fieldConversion, value);
    }

    /* If id is not known */
    if (isNullOrUndefined(value) && fieldNode.formFieldControlType !== 'calculated' && fieldNode.formFieldControlType !== 'dropdown' && fieldNode.formFieldControlType !== 'sublevel' && fieldNode.formFieldControlType !== 'assign' && fieldNode.formFieldControlType !== 'violatedParagraph') {
      return 0;
    }
    /* Switch by control type */
    switch (fieldNode.formFieldControlType) {
      case 'dropdown':
        switch (fieldNode.formFieldId) {
          case 'nodeType':
            const nodeTypes = CoreNodeTypes.filter(nodeType => nodeType.key === value);
            return nodeTypes.length > 0 ? nodeTypes[0].key : 0;
          default:
            switch (fieldNode.formFieldDropdownValue) {
              case 'humanresourceimages':
              case 'humanresources':
                return this.humanResourcesMap.has('' + treeNode.responsibleId) ? this.humanResourcesMap.get('' + treeNode.responsibleId).name : '';
              case 'shape':
                return treeNode[fieldNode.formFieldId];
              default:
                return value;
            }
        }
      case 'calculated':
        return this.getCalculatedValue(fieldNode, treeNode, fields);
      case 'sublevel':
      case 'sublevelWithName':
        return (<TreeNode> treeNode).subLevel;
      case 'daterange':
      case 'date':
        return new Datum(value).timestamp;
      case 'priority-score':
        return !isNullOrUndefined(this.eisenhowerService) ? this.eisenhowerService.getPriorityScore(<TreeNode> treeNode) : 0;
      default:
        return value;
    }
  }

  public getDropdownOptions(column: TableColumn | FormEntry) {
    return new Promise<{ key: number | string, value: string, color?: string, invertedColor?: string }[]>(resolve => {
      const id = !isNullOrUndefined((<TableColumn> column).id) ? (<TableColumn> column).id : (<FormEntry> column).key;
      if (id === 'reference' || id === 'priority-score') {
        this.matrixMap = this.matrixMap.clear();
        const xAxis = this.eisenhowerService.xAxis;
        const yAxis = this.eisenhowerService.yAxis;

        if (!isNullOrUndefined(xAxis) && !isNullOrUndefined(yAxis)) {
          xAxis.children.forEach(xChild => {
            xChild.children.forEach(child => {
              this.matrixMap = this.matrixMap.set(child.name, child);
            });
          });
          yAxis.children.forEach(yChild => {
            yChild.children.forEach(child => {
              this.matrixMap = this.matrixMap.set(child.name, child);
            });
          });
        }
        resolve(this.matrixMap.toArray().sort((a, b) => parseInt(a.name) - parseInt(b.name)).map(matrix => ({ key: matrix.name, value: matrix.name })));
      }
      const formNode = !isNullOrUndefined((<TableColumn> column).formNode) ? (<TableColumn> column).formNode : (<FormEntry> column).entryNode;
      switch (formNode.formFieldDropdownValue) {
        case 'humanresources':
        case 'humanresourceimages':
          this.coreService.getHumanResources().take(1).subscribe(humanResources => {
            resolve(humanResources.map(humanResource => ({ key: humanResource.id, value: humanResource.name })));
          });
          break;
        case 'markets':
          resolve(this.marketsMap.map(market => ({ key: market.crossReference, value: market.name })).toArray());
          break;
        case 'status':
        case 'statuscolor':
          resolve([
            { key: 0, value: 'STATUS.PENDING', color: '#999999', invertedColor: '#ffffff' },
            { key: 100, value: 'STATUS.INDEFINE', color: '#000000', invertedColor: '#ffffff' },
            { key: 110, value: 'STATUS.INDEVELOP', color: '#1099d6', invertedColor: '#ffffff' },
            { key: 120, value: 'STATUS.INVERIFY', color: '#aa008f', invertedColor: '#ffffff' },
            { key: 130, value: 'STATUS.INACCEPT', color: '#edb922', invertedColor: '#000000' },
            { key: 200, value: 'STATUS.COMPLETED', color: '#9ac93e', invertedColor: '#000000' }
          ]);
          break;
        default:
          resolve(formNode.children.sort((a, b) => a.positionX - b.positionX).filter(child => child.nodeType === NODES_TYPE_FORM_OPTION).map(child => ({
            key: child.formId, value: child.name, color: child.color, invertedColor: child.invertedColor
          })));
          break;
      }
    });
  }

  public convertYearToBucketId(year: number, month?: number) {
    const diff = year - new Datum().year;
    return 'y' + (diff > 0 ? '+' + diff : (diff < 0 ? '-' + diff : ''));
  }

  public getValue(fieldNode: TreeNode, treeNode: TreeNode | TreeActivity) {
    return fieldNode.formBucket ? this.getBucketValue(fieldNode.formFieldId, (<TreeNode> treeNode), fieldNode.formFieldBucketId) : fieldNode.formFieldId !== '' ? treeNode[fieldNode.formFieldId] : '';
  }

  public getBucketValue(id: string, treeNode: TreeNode, bucketId?: string) {
    return this.getBucket(treeNode, bucketId)[id];
  }

  public setBucketValue(id: string, value: any, treeNode: TreeNode | TreeActivity, bucketId?: string) {
    const bucket: TreeActivity = treeNode.internalType === 'treeActivity' ? (<TreeActivity> treeNode) : this.getBucket((<TreeNode> treeNode), bucketId);
    bucket[id] = value;
    return bucket;
  }

  public getBucket(treeNode: TreeNode, bucketId?: string) {
    const bucketDatum = this.getBucketDatum(!!bucketId ? bucketId : treeNode.formFieldBucketId);
    const matches = (!isNullOrUndefined(treeNode.activities) ? treeNode.activities : []).filter(treeActivity => {
      if (!treeActivity.nodebucket) {
        return false;
      }
      if (isNullOrUndefined(treeNode.formFieldBucketId) || treeNode.formFieldBucketId === '') {
        const start = new Datum(treeActivity.start);
        const end = new Datum(treeActivity.end);
        return start.lteq(bucketDatum) && end.gt(bucketDatum);
      } else {
        return treeActivity.start === bucketDatum.toISOString();
      }
    });
    if (matches.length > 0) {
      return matches[0];
    } else {
      /* Retry it with the first */
      const datumFirst = this.getBucketDatum(!!bucketId ? bucketId : treeNode.formFieldBucketId, 1);
      const matchesFirst = (!isNullOrUndefined(treeNode.activities) ? treeNode.activities : []).filter(treeActivity => treeActivity.nodebucket && treeActivity.start === datumFirst.toISOString());
      if (matchesFirst.length > 0) {
        return matchesFirst[0];
      }
    }
    return this.coreTransformer.activityToTreeActivity(<Activity> new Activity()
      .set('id', 'bucket-' + treeNode.id)
      .set('name', treeNode.name + '-bucket-0')
      .set('start', bucketDatum.toISOString())
      .set('end', bucketDatum.addMonths(1).toISOString())
      .set('nodebucket', true)
      .set('phantom', true)
      .set('relationships', new ActivityRelationships())
    );
  }

  public getBucketDatum(id: string, date = 3): Datum {
    let datum = new Datum().setDay();
    let quarter = 0;
    if (isNullOrUndefined(id)) {
      return datum;
    }
    const monthMatch = id.match(/(\d+)-(\d+)/);
    if (!isNullOrUndefined(monthMatch)) {
      datum.setDate(1);
      datum.setMonth(parseInt(monthMatch[1]) - 1);
      datum.setYear(parseInt(monthMatch[2]));
      return datum;
    }
    const explicitMatch = id.match(/Y:(\d*)|-M:(\d*)|-D:(\d*)/gm)
    if (!isNullOrUndefined(explicitMatch)) {
      datum.setDate(date);
      datum.setMonth(0);
      if (!isNullOrUndefined(explicitMatch[0])) {
        const yearMatch = explicitMatch[0].match(/\d+/);
        if (!isNullOrUndefined(yearMatch[0]) && yearMatch[0] !== '') {
          datum.setYear(parseInt(yearMatch[0]));
        }
      }
      if (!isNullOrUndefined(explicitMatch[1])) {
        const monthMatch = explicitMatch[1].match(/\d+/);
        if (!isNullOrUndefined(monthMatch[0]) && monthMatch[0] !== '') {
          datum.setMonth(parseInt(monthMatch[0]));
        }
      }
      if (!isNullOrUndefined(explicitMatch[2])) {
        const dayMatch = explicitMatch[2].match(/\d+/);
        if (!isNullOrUndefined(dayMatch[0]) && dayMatch[0] !== '') {
          datum.setDate(parseInt(dayMatch[0]));
        }
      }
      return datum;
    }
    const quarterMatch = id.match(/Q(\d)-/);
    if (!isNullOrUndefined(quarterMatch)) {
      quarter = (parseInt(quarterMatch[1]) - 1) * 3;
      id = id.replace(quarterMatch[0], '');
    }
    const match = id.match(/(\w)(\W)(\d)|(\w)/);
    if (!isNullOrUndefined(match)) {
      const operator = match[2];
      const addition = isNullOrUndefined(match[3]) ? 0 : parseInt(match[3]);
      const key = (isNullOrUndefined(match[1]) ? match[0] : match[1]).toLowerCase();
      switch (key) {
        case 'y':
          datum = datum.setMonth(quarter).setDate(date);
          if (date === 1) {
            datum = datum.setHours(12);
          }
          if (!isNullOrUndefined(operator) && !isNullOrUndefined(addition)) {
            datum = datum.setYear(operator === '+' ? (datum.year + addition) : (datum.year - addition));
          }
          break;
      }
    }
    return datum;
  }

  public getCalculatedValue(fieldNode: TreeNode, treeNode: TreeNode | TreeActivity, fields?: any) {
    switch (fieldNode.formFieldCalculation) {
      case 'roi':
        let benefit = fieldNode.formBucket ? this.getBucketValue('benefitBudget', (<TreeNode> treeNode)) : treeNode.benefitBudget;
        if (!isNullOrUndefined(fields) && !isNullOrUndefined(fields.benefit)) {
          benefit = fields.benefit.formBucket ? this.getBucketValue(fields.benefit.formFieldId, (<TreeNode> treeNode)) : treeNode[fields.benefit.formFieldId];
        }
        let cost = fieldNode.formBucket ? this.getBucketValue('costBudget', (<TreeNode> treeNode)) : treeNode.costBudget;
        if (!isNullOrUndefined(fields) && !isNullOrUndefined(fields.cost)) {
          cost = fields.cost.formBucket ? this.getBucketValue(fields.cost.formFieldId, (<TreeNode> treeNode)) : treeNode[fields.cost.formFieldId];
        }
        if (cost === 0) {
          cost = 1;
        }
        return parseFloat(this.coreTransformer.readableNumber(benefit / cost));
      case 'strategicclassification':
        const classifications = this.coreService.searchBy({ filters: [{ by: 'level', value: 0 }, { by: 'subLevel', value: 0 }] }, (<TreeNode> treeNode).parents, true, 'parents');
        return classifications.length > 0 ? classifications[0].name : treeNode.name;
      case 'challenge':
        const challenge = this.coreService.searchBy({ filters: [{ by: 'level', value: 1 }] }, (<TreeNode> treeNode).parents, true, 'parents').sort((a, b) => a.subLevel - b.subLevel);
        return challenge.length > 0 ? challenge[0].name : '';
      case 'challenge2nd':
        const challenge2nd = this.coreService.searchBy({ filters: [{ by: 'level', value: 1 }] }, (<TreeNode> treeNode).parents, true, 'parents').sort((a, b) => a.subLevel - b.subLevel);
        if (challenge2nd.length > 0) {
          const firstSubLevel = challenge2nd[0].subLevel;
          const count = challenge2nd.length;
          let secondSubLevel: TreeNode;
          for (let i = 0; i < count; i++) {
            const c = challenge2nd[i];
            if (c.subLevel !== firstSubLevel) {
              secondSubLevel = c;
              break;
            }
          }
          if (!isNullOrUndefined(secondSubLevel)) {
            return secondSubLevel.name;
          }
        }
        return '';
      default:
        return 0;
    }
  }

  public getCalculatedValueByEntry(entry: FormEntry, treeNode: TreeNode) {
    switch (entry.calculation) {
      case 'roi':
        const benefit = entry.nodeBucket ? this.getBucketValue('benefitBudget', treeNode) : treeNode.benefitBudget;
        let cost = entry.nodeBucket ? this.getBucketValue('costBudget', treeNode) : treeNode.costBudget;
        if (cost === 0) {
          cost = 1;
        }
        return parseFloat(this.coreTransformer.readableNumber(benefit / cost));
      case 'assignedcapacity':
        return this.assignedCapacity(entry, treeNode);
      case 'aggregationsub':
        return this.aggregationSub(entry, treeNode);
      case 'aggregation':
        return this.aggregateChildren(entry, treeNode);
      default:
    }
  }

  public getEntryValue(entry: FormEntry, treeNode: TreeNode) {
    /* If source node type is set */
    if (!isNullOrUndefined(entry.sourceNodeType)) {
      const sourceNode = treeNode.unfilteredChildren.filter(child => child.nodeType === entry.sourceNodeType)[0];
      if (!isNullOrUndefined(sourceNode)) {
        treeNode = sourceNode;
      }
    }
    /* Get the value */
    let value = entry.nodeBucket ? this.getBucketValue(entry.key, treeNode, entry.entryNode.formFieldBucketId) : treeNode[entry.key];
    /* If there is a conversion assigned */
    if (!isNullOrUndefined(entry.entryNode) && !isNullOrUndefined(entry.entryNode.fieldConversion)) {
      value = this.coreTransformer.convertValue(entry.entryNode.fieldConversion, value);
    }
    /* If value is undefined */
    if (isNullOrUndefined(value) && entry.controlType !== 'calculated' && entry.controlType !== 'violatedParagraph') {
      return '';
    }
    /* Switch by control type */
    switch (entry.controlType) {
      case 'dropdown':
        switch (entry.key) {
          case 'nodeType':
            const nodeTypes = CoreNodeTypes.filter(nodeType => nodeType.key === value);
            return nodeTypes.length > 0 ? this.translateService.instant(nodeTypes[0].label) : '';
        }
        return value;
      case 'violatedParagraph':
        return !isNullOrUndefined(this.additionalInfos) && !isNullOrUndefined(this.additionalInfos['violatedParagraphs']) ? this.additionalInfos['violatedParagraphs'] : [];
      case 'calculated':
        value = this.getCalculatedValueByEntry(entry, treeNode);
        /* If there is a conversion assigned */
        if (!isNullOrUndefined(entry.entryNode.fieldConversion) && entry.calculation !== 'aggregationsub') {
          value = this.coreTransformer.convertValue(entry.entryNode.fieldConversion, value);
        }
        return value;
      default:
        return value;
    }
  }

  public getEntriesKeyMap(formElements: FormElement[], entriesKeyMap = Map<string, FormEntry>()): Map<string, FormEntry> {
    const count = formElements.length;
    for (let i = 0; i < count; i++) {
      const formElement = formElements[i];
      if (!isNullOrUndefined(formElement.entry.controlType)) {
        if (formElement.entry.controlType === 'datestack') {
          const countDateStack = formElement.entry.entryNode.children.length;
          for (let ids = 0; ids < countDateStack; ids++) {
            const child = formElement.entry.entryNode.children[ids];
            entriesKeyMap = entriesKeyMap.set(child.formFieldId, this.buildFormEntry(child).entry);
          }
          continue;
        }
        entriesKeyMap = entriesKeyMap.set(formElement.entry.key, formElement.entry);
        if (formElement.entry.controlType === 'questionnaire') {
          const count_ = formElement.entry.entryNode.children.length;
          for (let i_ = 0; i_ < count_; i_++) {
            const child = formElement.entry.entryNode.children[i_];
            switch (child.formFieldControlType) {
              case 'textbox':
                entriesKeyMap = entriesKeyMap.set(child.formFieldId, this.buildFormEntry(child).entry);
                break;
              default:
                const count2 = child.children.length;
                for (let j = 0; j < count2; j++) {
                  const cchild = child.children[j];
                  entriesKeyMap = entriesKeyMap.set(cchild.formFieldId, this.buildFormEntry(cchild).entry);
                }
                break;
            }
          }
        }
      }
      if (!isNullOrUndefined(formElement.children)) {
        entriesKeyMap = this.getEntriesKeyMap(formElement.children, entriesKeyMap);
      }
    }
    return entriesKeyMap;
  }

  public buildFormEntry(treeNode: TreeNode, readonly = false, editorConfig?: any) {
    const entry: any = { entry: {
        key: treeNode.formFieldId,
        type: treeNode.formFieldType,
        controlType: treeNode.formFieldControlType,
        label: treeNode.name,
        nodeBucket: treeNode.formBucket,
        editable: readonly === true ? false : treeNode.formFieldEditable,
        required: treeNode.obligatory,
        additionalLabel: treeNode.form_default_value,
        entryNode: treeNode,
        sourceNodeType: undefined
      }};
    if (!isNullOrUndefined(editorConfig) && !isNullOrUndefined(editorConfig[treeNode.formFieldId])) {
      entry.entry.editorConfig = editorConfig[treeNode.formFieldId];
    }
    const childSourceNode = treeNode.children.filter(child => child.nodeType === NODES_TYPE_CHILD)[0];
    if (!isNullOrUndefined(childSourceNode)) {
      entry.entry.sourceNodeType = childSourceNode.children[0].nodeType;
    }
    if (treeNode.formFieldControlType === 'dropdown') {
      if (treeNode.formFieldDropdownValue === '' || treeNode.formFieldDropdownValue === 'shape') {
        entry.entry.options = treeNode.children.filter(optionNode => optionNode.nodeType === NODES_TYPE_FORM_OPTION).sort((a, b) => this.coreUtilities.sort(a.name, b.name, true)).sort((a, b) => a.positionX - b.positionX).map(optionNode => {
          const option: FormOption = { key: optionNode.formId, value: optionNode.name, optionNode: optionNode };
          if (optionNode.color !== '') {
            option.background = optionNode.color;
            option.color = this.coreUtilities.invertColor(option.background, true);
          }
          return option;
        });
        if (entry.entry.options.length > 0) {
          entry.entry.options.unshift({ key: null, value: '' });
        }
      } else {
        entry.entry.source = treeNode.formFieldDropdownValue;
        if (treeNode.formFieldDropdownValue === 'nodes') {
          entry.entry.type = treeNode.nodeType;
        }
      }
    }
    if (entry.entry.controlType === 'calculated') {
      entry.entry.key = treeNode.formFieldCalculation;
      entry.entry.calculation = treeNode.formFieldCalculation;
      entry.entry.value = this.getCalculatedValueByEntry(entry.entry, treeNode);
    }
    if (entry.entry.controlType === 'children') {
      if (isNullOrUndefined(entry.entry.source)) {
        entry.entry.source = { tabs: [{ entry: { key: 'header-1', label: '' }, children: this.buildFormEntries(treeNode) }] };
      }
    }
    return entry;
  }

  public buildFormEntries(treeNode: TreeNode) {
    return treeNode.children.sort((a, b) => a.positionX - b.positionX).map(childTreeNode => this.buildFormEntry(childTreeNode));
  }

  public buildFormEntriesFromTreeNodes(treeNodes: TreeNode[], readonly = false, editorConfig?: any) {
    return treeNodes.map(treeNode => this.buildFormEntry(treeNode, readonly, editorConfig));
  }

  public toFormGroup(entries: Array<any>, element: any) {
    let formGroup = new FormGroup({});
    if (!isNullOrUndefined(entries)) {
      formGroup = this.buildFormRec(entries, formGroup, element);
    }
    return formGroup;
  }

  public getFormResultFromFormElement(formElement: any, formNode: TreeNode, treeNode: TreeNode): CoreMultiTransfer {
    const formResult = <FormResult> { delta: Map<string, any>(), formGroup: null };
    /* Get the form element */
    const element = jQuery(formElement);
    /* Get the form configuration */
    const entries = this.buildFormEntries(formNode);
    const formInterface = <FormInterface> { tabs: entries };
    const count = entries.length;
    for (let i = 0; i < count; i++) {
      const entry = entries[i];
      const oldValue = this.getEntryValue(entry.entry, treeNode);
      let newValue = element.find('[name="' + entry.entry.key + '"]').val();
      if (isNullOrUndefined(newValue)) {
        newValue = element.val();
      }
      if (!isNullOrUndefined(entry.entry.entryNode) && !isNullOrUndefined(entry.entry.entryNode.fieldConversion)) {
        newValue = this.coreTransformer.invertConvertValue(entry.entry.entryNode.fieldConversion, newValue);
      }
      if (!isNullOrUndefined(newValue) && newValue !== oldValue) {
        formResult.delta = formResult.delta.set(entry.entry.key, newValue);
      }
    }
    return this.getCoreTransferFromFormResult(formResult, formInterface, treeNode);
  }

  public getFormHtml(formElements: FormElement[], treeNode: TreeNode): string {
    let html = '<form data-id="' + treeNode.id + '">';
    const count = formElements.length;
    for (let i = 0; i < count; i++) {
      /* Form element */
      const formElement = formElements[i];
      /* Form bucket */
      let parentTreeNodeFromBucket = this.coreUtilities.getParentTreeNodeFromBucket(treeNode);
      if (formElement.entry.key === 'aggregation') {
        parentTreeNodeFromBucket = treeNode;
      }
      /* Get the value */
      let value = this.getEntryValue(formElement.entry, parentTreeNodeFromBucket);
      if (isNullOrUndefined(value) || (value === 0 && formElement.entry.calculation !== 'aggregationsub')) {
        value = '';
      }
      if (isNumber(value)) {
        value = parseFloat(parseFloat('' + value).toFixed(2));
      }
      switch (formElement.entry.controlType) {
        case 'textbox':
          /* Build the html */
          if (formElement.entry.editable) {
            /* Min and max values -> Question value on a treeNode*/
            let valueConstraints = '';
            if (!isNullOrUndefined(formElement.entry.entryNode.costDescription) && formElement.entry.entryNode.costDescription) {
              valueConstraints = formElement.entry.entryNode.costDescription;
              const regexpSize = /([0-9]+[,.][0-9]+):([0-9]+)/;
              const match = valueConstraints.match(regexpSize);
              if (!isNullOrUndefined(match) && Array.isArray(match) && match.length === 3) {
                valueConstraints = `min="${parseFloat(match[1])}" max="${parseInt(match[2])}"`;
              } else {
                valueConstraints = '';
              }

            }
            html += '<div class="input-group">';
            html += '<input type="number" step=".1" name="' + formElement.entry.key + '" placeholder="' + formElement.entry.label + '" data-id="' + treeNode.id + '" data-value="' + value + '" value="' + value + '" ' + valueConstraints + ' />';
            if (!isNullOrUndefined(formElement.entry.additionalLabel) && formElement.entry.additionalLabel !== '') {
              html += '<div class="input-group-append">';
              html += '<span class="input-group-text">' + formElement.entry.additionalLabel + '</span>';
              html += '</div>';
            }
            html += '</div>';
          } else if (treeNode.nodeType === NODES_TYPE_HUMANRESOURCE) {
            html += '<input type="hidden" name="' + formElement.entry.key + '" data-id="' + treeNode.id + '" data-value="' + value + '" value="' + value + '" /><p>' + value + '</p>';
          } else {
            html += '<div class="input-group">';
            html += '<input type="number" step=".01" disabled="disabled" name="' + formElement.entry.key + '" placeholder="' + formElement.entry.label + '" data-id="' + treeNode.id + '" data-value="' + value + '" value="' + value + '" />';
            if (!isNullOrUndefined(formElement.entry.additionalLabel) && formElement.entry.additionalLabel !== '') {
              html += '<div class="input-group-append">';
              html += '<span class="input-group-text">' + formElement.entry.additionalLabel + '</span>';
              html += '</div>';
            }
            html += '</div>';
          }
          break;
        case 'calculated':
          html += '<div class="input-group">';
          html += '<input disabled="disabled" name="' + formElement.entry.key + '" data-id="' + treeNode.id + '" data-value="' + value + '" value="' + value + '" style="background: #e7f2ba;" />';
          if (!isNullOrUndefined(formElement.entry.additionalLabel) && formElement.entry.additionalLabel !== '') {
            html += '<div class="input-group-append">';
            html += '<span class="input-group-text">' + formElement.entry.additionalLabel + '</span>';
            html += '</div>';
          }
          html += '</div>';
          break;
      }
    }
    html += '</form>';
    return count === 0 ? '' : html;
  }

  public getFormTabsHtml(formElements: FormElement[], treeNode: TreeNode): string {
    let html = '<div class="form form-inline">';
    const count = formElements.length;
    let previousValue: number;
    for (let i = 0; i < count; i++) {
      /* Form element */
      const formElement = formElements[i];
      /* Styles */
      const styles = [];
      /* Set the width */
      styles.push('width: ' + (!isNullOrUndefined(formElement.entry.entryNode.formFieldWidth) ? formElement.entry.entryNode.formFieldWidth : ''));
      /* Get the value */
      let value = this.getEntryValue(formElement.entry, treeNode);
      if (!isNullOrUndefined(previousValue) && previousValue < value) {
        styles.push('background: red');
        styles.push('color: white');
      }
      if (isNullOrUndefined(value) || value === 0) {
        value = '';
      }
      html += '<div class="input-group">';
      if (i < count - 1) {
        html += '<div class="input-group-prepend"><span class="input-group-text">' + formElement.entry.label + '</span></div>';
      }
      const disabled = formElement.entry.controlType === 'calculated' || !formElement.entry.editable ? ' disabled="disabled" ' : '';
      html += '<form data-id="' + treeNode.id + '"><input style="' + styles.join(';') + '" ' + disabled + 'name="' + formElement.entry.key + '" data-id="' + treeNode.id + '" data-value="' + value + '" value="' + value + '" /></form>';
      if (i === count - 1) {
        html += '<div class="input-group-append"><span class="input-group-text">' + formElement.entry.label + '</span></div>';
      }
      html += '</div>';
      previousValue = parseFloat('' + value);
    }
    html += '</div>';
    return html;
  }

  private buildFormRec(properties: Array<any>, formGroup: FormGroup, element: any): FormGroup {
    if (isNullOrUndefined(properties)) {
      return formGroup;
    }
    properties.forEach((property) => {
      const entry = property.entry;
      const children = property.children;
      if (!isNullOrUndefined(children)) {
        const subGroup = this.buildFormRec(children, new FormGroup({}), element);
        formGroup.setControl(entry.key, subGroup);
      } else {
        const validations = entry.required ? [Validators.required] : [];
        formGroup.setControl(entry.key, new FormControl(entry.value || '', validations));

        /* Check if there is an element */
        if (!isNullOrUndefined(element)) {
          /* Get the value */
          let value = this.getEntryValue(entry, element);
          if (entry.controlType === 'hidden' && value === '' && entry.additionalLabel !== '') {
            value = entry.additionalLabel;
          }
          const values = {};
          values[entry.key] = value;
          /* Patch the value */
          formGroup.patchValue(values);
        }
      }
    });
    return formGroup;
  }

  public aggregateChildren(entry: FormEntry, treeNode: TreeNode, date?: Datum, filtered = false, result = 0, ids = []): number {
    if (ids.indexOf(treeNode.id) !== -1) {
      return result;
    }
    ids.push(treeNode.id);
    const relationships = this.coreService.getInstantRelationships({ filters: [{ by: 'parentId', value: treeNode.id }] });
    if (relationships.length > 0) {
      let relMap = Map<string, number>();
      let count = relationships.length;
      for (let i = 0; i < count; i++) {
        const relationship = relationships[i];
        relMap = relMap.set(relationship.parentId + ':' + relationship.childId, relationship.weight);
      }
      const children = filtered ? [...treeNode.children] : [...treeNode.unfilteredChildren];
      let childrenNames = ' : ';
      children.forEach(x => childrenNames += x.name + ', ');
      count = children.length;
      for (let i = 0; i < count; i++) {
        const child = children[i];
        const relId = treeNode.id + ':' + child.id;
        const weight = parseFloat('' + (relMap.has(relId) ? relMap.get(relId) : 0));
        if (child.nodeType === NODES_TYPE_HUMANRESOURCE || child.nodeType === NODES_TYPE_REQUIREMENT) {
          const bucketDatum = !isNullOrUndefined(date) ? <string> date.toString('MY') : date;
          const childValue = entry.nodeBucket ? parseFloat(this.getBucketValue(entry.entryNode.formFieldId, this.coreUtilities.getParentTreeNodeFromBucket(child), bucketDatum)) : this.getValue(entry.entryNode, this.coreUtilities.getParentTreeNodeFromBucket(child));
          result += (childValue * (weight / 100));
        }

        /* Continue */
        if (!isNullOrUndefined(entry.entryNode) && !isNullOrUndefined(children) && !(children.length === 0 || children.filter(c => c.nodeType === child.nodeType).length === 0)) {
          result = this.aggregateChildren(entry, child, date, filtered, result, ids);
        }
      }
      return parseFloat(parseFloat('' + result).toFixed(2));
    }
    return result;
  }

  public assignedCapacity(entry: FormEntry, treeNode: TreeNode): number {
    const capacities = this.coreService.searchBy({ filters: [{ by: 'nodeType', value: NODES_TYPE_CAPACITY }] }, treeNode.unfilteredChildren, true);
    let capacity = 0;
    const count = capacities.length;
    for (let i = 0; i < count; i++) {
      capacity += this.aggregateChildren(entry, capacities[i]);
    }
    return capacity;
  }

  public aggregationSub(entry: FormEntry, treeNode: TreeNode) {
    let aggregatedValue = this.aggregateChildren(entry, treeNode);
    if (!isNullOrUndefined(entry.entryNode) && !isNullOrUndefined(entry.entryNode.fieldConversion)) {
      aggregatedValue = this.coreTransformer.convertValue(entry.entryNode.fieldConversion, aggregatedValue);
    }
    /* Search for connected requirements */
    const requirements = treeNode.parents.filter(parent => parent.nodeType === NODES_TYPE_REQUIREMENT);
    const count = requirements.length;
    let minus = 0;
    const ids = [];
    for (let i = 0; i < count; i++) {
      const requirement = requirements[i];
      if (ids.indexOf(requirement.id) === -1) {
        entry.controlType = 'textbox';
        entry.key = entry.entryNode.formFieldId;
        minus += this.getEntryValue(entry, requirement);
        ids.push(requirement.id);
      }
    }
    const result = aggregatedValue - minus;
    return result < 0 ? 0 : result;
  }

  public getFormEntryByKey(formElements: FormWidgetFormElement[], key: string) {
    let result: FormWidgetFormElement;
    const count = formElements.length;
    for (let i = 0; i < count; i++) {
      const formElement = formElements[i];
      if (!isNullOrUndefined(formElement.entry) && formElement.entry.entry.key === key) {
        result = formElement;
        break;
      }
      if (!isNullOrUndefined(formElement.children)) {
        result = this.getFormEntryByKey(formElement.children, key);
        if (!isNullOrUndefined(result)) {
          break;
        }
      }
    }
    return result;
  }
}
