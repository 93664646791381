import { Store } from '@ngrx/store';

import { APIState } from '../reducer';
import { commonSelectors } from '../shared';
import { List } from 'immutable';
import { getEntityMap } from '../shared/common.selectors';
import { DataModelState } from '../shared/common.models';
import { Activity } from './activities.models';
import { compose } from '../../utilities/compose';

export function getState() {
  return (state$: Store<APIState>) => state$
    .select((s: APIState) => s.activities);
}

export function has(id: string) {
  return compose(commonSelectors.has(id), getState());
}

export function get(id: string) {
  return compose(commonSelectors.get(id), getState());
}

export function getMany(ids?: string[]) {
  return compose((state$: Store<DataModelState>) => state$
    .let(getEntityMap())
    .map(entities => {
      const _entities = !ids ? entities.toList() : List<any>(ids).map(id => entities.get('' + id)).filter(entity => !!entity).toList();
      return _entities.map((activity: Activity) => <Activity> activity
          .set('node', activity.relationships.nodedata)
      ).toList();
    }), getState());
}

export function getIsBusy() {
  return compose(commonSelectors.getIsBusy(), getState());
}

export function getDiff() {
  return compose(commonSelectors.getDiff(), getState());
}
