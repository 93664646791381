export const TemplateFieldsRequirementdefinition = {
  tabs: [
    {
      entry: { key: 'header1', label: '' },
      children: [
        { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'DASHBOARD.REQUIREMENTDEFINITION.FIELDS.NAME', maxLength: 255, validations: [ 'required' ], validationMessages: { required: 'DASHBOARD.REQUIREMENTDEFINITION.VALIDATION.REQUIRED.NAME' } }},
        { entry: { key: 'responsibleId', controlType: 'dropdown', label: 'DASHBOARD.REQUIREMENTDEFINITION.FIELDS.RESPONSIBLEID', source: 'humanresources', hasNull: true }},
        { entry: { key: 'description', controlType: 'text', label: 'DASHBOARD.REQUIREMENTDEFINITION.FIELDS.DESCRIPTION'}},
      ]
    }
  ]
};

export const TemplateFieldsNodesRequirementDefinitionGrid = {
  options: [
    { entry: { key: '', label: '', x: true, y: true, options: [
      { key: 0, value: 'DASHBOARD.REQUIREMENTDEFINITION.UNASSIGNED' },
    ]}},
    { entry: { key: 'businessBenefit', x: true, y: true, type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.TITLE', options: [
      { key: 0, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.NOTAPPLICABLE' },
      { key: 1, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.LOW' },
      { key: 2, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.MEDIUM' },
      { key: 3, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.HIGH' },
    ]}},
  ]
};
