import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { API, GoAPI, IModel, JSONAPIResponse } from '../connector';

import { ModelAction } from './models.actions';
import { CommonEffects } from '../shared/common.effects';
import { Action } from '../shared/common.action';

@Injectable()
export class ModelEffects {

  private concurrency = 5;

  @Effect() loadModel$ = this.actions$
    .pipe(ofType(ModelAction.LOAD))
    .mergeMap((action: Action) => this.api.models
      .include(action.payload.includes)
      .find(action.payload.id)
      .mergeMap((response: JSONAPIResponse<IModel>) =>
        this.commonEffects.getObservables(this.modelAction.loadSuccess, action.payload.includes, response, true)
      )
    ).catch((error: any) => Observable.of(this.modelAction.loadFail(error)));

  @Effect() massLoadModel$ = this.actions$
    .pipe(ofType(ModelAction.MASSLOAD))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.data.map(payload => this.api.models.mass(payload)
      .mergeMap(response => Observable.from(this.commonEffects.getMassModelObservables(this.modelAction.loadSuccess, response)))
      .catch((error: any) => Observable.of(this.modelAction.loadFail(error)))
    )))
    .mergeAll(1);

  @Effect() massGoLoadModel$ = this.actions$
    .pipe(ofType(ModelAction.MASSGOLOAD))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.data.map(payload => this.apiGo.models.mass(payload)
      .mergeMap(response => Observable.from(this.commonEffects.getMassModelObservables(this.modelAction.loadSuccess, response)))
      .catch((error: any) => Observable.of(this.modelAction.loadFail(error)))
    )))
    .mergeAll(1);

  @Effect() initiativesLoadModel$ = this.actions$
    .pipe(ofType(ModelAction.INITIATIVESLOAD))
    .mergeMap((action: Action) => this.api.models.initiatives(action.payload.id)
      .mergeMap((response: JSONAPIResponse<IModel>) =>
        this.commonEffects.getInitiativesModelObservables(this.modelAction.initiativesLoadSuccess, response)
      )
    ).catch((error: any) => Observable.of(this.modelAction.initiativesLoadFail(error)));

  @Effect() createModelOnBusinessarea$ = this.actions$
    .pipe(ofType(ModelAction.CREATE_ON_BUSINESSAREA))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.data.map(payload => {
      const data = payload.data.toJS();
      delete data.id;
      return this.api.businessarea(action.payload.businessareaId).submodels.create(data)
        .mergeMap(response => Observable.from([
          this.modelAction.createSuccess(payload, [response.toPayload()])
        ]))
        .catch((error: any) => Observable.of(this.modelAction.createFail(payload, error)));
    })))
    .mergeAll(this.concurrency);

  @Effect() createModelOnBusinessareaGo$ = this.actions$
    .pipe(ofType(ModelAction.CREATE_ON_BUSINESSAREA_GO))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.apiGo.businessarea(action.payload.businessareaId).submodels.bulkCreate(action.payload.data)
      .mergeMap(response => Observable.of(this.modelAction.createSuccess(action.payload, response.toPayload())))
      .catch((error: any) => Observable.of(this.modelAction.createFail(action.payload, error)))
    );

  @Effect() createModelOnModel$ = this.actions$
    .pipe(ofType(ModelAction.CREATE_ON_MODEL))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.data.map(payload => {
      const data = payload.data.toJS();
      delete data.id;
      return this.api.model(action.payload.modelId).submodels.create(data)
        .mergeMap(response => Observable.from([
          this.modelAction.createSuccess(payload, [response.toPayload()])
        ]))
        .catch((error: any) => Observable.of(this.modelAction.createFail(payload, error)));
    })))
    .mergeAll(this.concurrency);

  @Effect() createModelOnModelGo$ = this.actions$
    .pipe(ofType(ModelAction.CREATE_ON_MODEL_GO))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.apiGo.model(action.payload.modelId).submodels.bulkCreate(action.payload.data)
      .mergeMap(response => Observable.of(this.modelAction.createSuccess(action.payload, response.toPayload())))
      .catch((error: any) => Observable.of(this.modelAction.createFail(action.payload, error)))
    );

  @Effect() duplicateModelOnBusinessarea$ = this.actions$
    .pipe(ofType(ModelAction.DUPLICATE_ON_BUSINESSAREA))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.data.map(payload => {
      const data = payload.data;
      return this.api.businessarea(action.payload.businessareaId).submodels.duplicate(data)
        .mergeMap(response => Observable.from([
          this.modelAction.duplicateSuccess(payload, response.toPayload())
        ]))
        .catch((error: any) => Observable.of(this.modelAction.duplicateFail(payload, error)));
    })))
    .mergeAll(this.concurrency);

  @Effect() duplicateModelOnModel$ = this.actions$
    .pipe(ofType(ModelAction.DUPLICATE_ON_MODEL))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.data.map(payload => {
      const data = payload.data;
      return this.api.model(action.payload.modelId).submodels.duplicate(data)
        .mergeMap(response => Observable.from([
          this.modelAction.duplicateSuccess(payload, response.toPayload())
        ]))
        .catch((error: any) => Observable.of(this.modelAction.duplicateFail(payload, error)));
    })))
    .mergeAll(this.concurrency);

  @Effect() removeModel$ = this.actions$
    .pipe(ofType(ModelAction.REMOVE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.data.map(payload => this.api.models.remove(payload)
      .mergeMap(response => Observable.from([
        this.modelAction.removeSuccess(payload, [response.toPayload()])
      ]))
      .catch((error: any) => Observable.of(this.modelAction.removeFail(payload, error)))
    )))
    .mergeAll(this.concurrency);

  @Effect() removeModelGo$ = this.actions$
    .pipe(ofType(ModelAction.REMOVE_GO))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.apiGo.models.bulkRemove(action.payload.data)
      .mergeMap(response => Observable.from([
        this.modelAction.removeSuccess(action.payload, response.toPayload())
      ]))
      .catch((error: any) => Observable.of(this.modelAction.removeFail(action.payload, error))));

  @Effect() updateModel$ = this.actions$
    .pipe(ofType(ModelAction.UPDATE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.map(payload => this.api.models.update(payload.id, payload.data)
      .mergeMap(response => Observable.from([
        this.modelAction.updateSuccess(payload, [response.toPayload()])
      ]))
      .catch((error: any) => Observable.of(this.modelAction.updateFail(payload, error)))
    )))
    .mergeAll(this.concurrency);

  @Effect() updateModelGo$ = this.actions$
    .pipe(ofType(ModelAction.UPDATE_GO))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.apiGo.models.bulkUpdate([action.payload[0]])
      .mergeMap(response => Observable.from([
        this.modelAction.updateSuccess(action.payload, response.toPayload())
      ])
        .catch((error: any) => Observable.of(this.modelAction.updateFail(action.payload, error)))
      ))

  @Effect() fetchFavorites = this.actions$
    .pipe(ofType(ModelAction.LOAD_FAVORITES))
    .mergeMap(() => this.api.models.favorites.all())
    .mergeMap(response => Observable.from([
      this.modelAction.loadFavoritesSuccess(response)
    ]))
    .catch(error => Observable.of(this.modelAction.loadFavoritesFail(error)));

  @Effect() addFavorite = this.actions$
    .pipe(ofType(ModelAction.ADD_FAVORITE))
    .mergeMap((action: Action) => this.api.models.favorites.add(action.payload.id))
    .mergeMap(response => Observable.from([
      this.modelAction.addFavoriteSuccess(response)
    ]))
    .catch(error => Observable.of(this.modelAction.addFavoriteFail(error)));

  @Effect() removeFavorite = this.actions$
    .pipe(ofType(ModelAction.REMOVE_FAVORITE))
    .mergeMap((action: Action) => this.api.models.favorites.remove(action.payload.id))
    .mergeMap(response => Observable.from([
      this.modelAction.removeFavoriteSuccess(response)
    ]))
    .catch(error => Observable.of(this.modelAction.removeFavoriteFail(error)));

  constructor(private api: API,
    private apiGo: GoAPI,
    private actions$: Actions,
    private modelAction: ModelAction,
    private commonEffects: CommonEffects) {
  }
}
