import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { TreeNode } from '../../../core/interface/core.interface';
import { NODES_TYPE_CAPACITY } from '../../../shared/api/nodes/nodes.models';
import { FormService } from '../../../components/form/service/form.service';
import { Map } from 'immutable';

export class ColorLabelProviderServiceAvailable extends ColorLabelProviderAbstract {

  private static AVAILABLE = '#9ac93e';
  private static LESSAVAILABLE = '#EDD515';
  private static NOTAVAILABLE = '#E50000';

  public constructor(private formService: FormService) {
    super();
  }

  public color(element: TreeNode) {
    const capacities = element.children.filter(child => child.nodeType === NODES_TYPE_CAPACITY);
    const count = capacities.length;
    if (count === 0) {
      return super.color();
    }
    let totalAvailable = 0;
    for (let i = 0; i < count; i++) {
      const capacity = capacities[i];
      const available = this.formService.aggregationSub(Map().set('entryNode', Map().set('formFieldId', 'budget').set('formBucket', true).toJS()).set('nodeBucket', true).toJS(), capacity);
      totalAvailable += available;
    }
    if (totalAvailable > 0) {
      return [ColorLabelProviderServiceAvailable.AVAILABLE];
    } else if (totalAvailable === 0) {
      return [ColorLabelProviderServiceAvailable.LESSAVAILABLE];
    } else {
      return [ColorLabelProviderServiceAvailable.NOTAVAILABLE];
    }
  }

}
