import { Map, Set } from 'immutable';
import { isNullOrUndefined } from 'util';

export class TreeCacheService {

  private cache = Map<string, Map<string, any>>();
  private idMap = Map<string, Set<string>>();

  public has(cache: string, key: string): boolean {
    return this.cache.has(cache) && this.cache.get(cache).has(key);
  }

  public set(cache: string, key: string, value: any, elementId?: string) {
    if (!isNullOrUndefined(elementId)) {
      const idMap: Set<string> = this.idMap.has(elementId) ? this.idMap.get(elementId).filter(oldKey => {
        this.flush(cache, oldKey);
        return false;
      }).toSet() : Set<string>();
      this.idMap = this.idMap.set(elementId, idMap.add(key));
    }
    const cacheMap = this.cache.has(cache) ? this.cache.get(cache) : Map<string, any>();
    this.cache = this.cache.set(cache, cacheMap.set(key, value));
  }

  public get(cache: string, key: string): any | null {
    if (this.has(cache, key)) {
      return this.cache.get(cache).get(key);
    } else {
      return null;
    }
  }

  public flush(cache: string, key?: string) {
    if (isNullOrUndefined(key) && this.cache.has(cache)) {
      this.cache = this.cache.remove(cache);
    } else if (this.has(cache, key)) {
      this.cache = this.cache.set(cache, this.cache.get(cache).remove(key));
    }
  }

  public flushAll(cache?: string) {
    if (isNullOrUndefined(cache)) {
      this.cache = this.cache.clear();
    } else if (this.cache.has(cache)) {
      this.cache = this.cache.set(cache, this.cache.get(cache).clear());
    }
  }

}
