import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { isNullOrUndefined } from 'util';

export class ColorLabelProviderServicePositive extends ColorLabelProviderAbstract {

  private static POSITIVE = '#9ac93e';
  private static ATTENTION = '#EDD515';
  private static NEGATIVE = '#E50000';

  public color(value: number) {
    if (isNullOrUndefined(value)) {
      return super.color();
    }
    if (value >= 0) {
      return [ColorLabelProviderServicePositive.POSITIVE];
    } else if (value >= -10) {
      return [ColorLabelProviderServicePositive.ATTENTION];
    } else {
      return [ColorLabelProviderServicePositive.NEGATIVE];
    }
  }

}
