import {Injectable} from '@angular/core';
import {SharedService} from '../shared/shared.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../app.reducer';
import {EmailAction} from './email.actions';
import * as selectors from './email.selectors';


@Injectable()
export class EmailService extends SharedService {

  public constructor(protected store: Store<AppState>, protected action: EmailAction) {
    super(store, action, selectors);
  }

  public sendEmail(data: any) {
    this.store.dispatch(this.action.sendEmail(data));
  }

}
