import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';

import { NodesAction } from './nodes.actions';
import { NodeDataAction } from '../nodedata/nodedata.actions';
import { NodeStructureAction } from '../nodestructures/nodestructures.actions';
import { Action } from '../shared/common.action';
import { API, GoAPI } from '../connector/shared';

@Injectable()
export class NodesEffects {

  @Effect() createNodeGo$ = this.actions$
    .pipe(ofType(NodesAction.CREATE_GO))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.apiGo.model(action.payload.id).nodes.bulkCreate(action.payload.data)
      .mergeMap(response => Observable.from([
        this.nodeDataAction.createSuccess(action.payload, response.toIncludedByType('nodedata')),
        this.nodeStructureAction.createSuccess(action.payload, response.toPayload())
      ])).catch((error: any) => Observable.of(this.nodeStructureAction.createFail(action.payload, error))));


  @Effect() createNode$ = this.actions$
    .pipe(ofType(NodesAction.CREATE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.api.model(action.payload.id).nodes.bulkCreate(action.payload.data)
      .mergeMap(response => Observable.from([
        this.nodeDataAction.createSuccess(action.payload, response.toIncludedByType('nodedata')),
        this.nodeStructureAction.createSuccess(action.payload, response.toPayload())
      ])).catch((error: any) => Observable.of(this.nodeStructureAction.createFail(action.payload, error))));

  constructor(private api: API,
    private apiGo: GoAPI,
    private actions$: Actions,
    private nodeDataAction: NodeDataAction,
    private nodeStructureAction: NodeStructureAction) {
  }
}
