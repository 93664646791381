import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { API, IImportScheme, JSONAPIResponse } from '../connector';

import { ImportSchemeAction } from './importschemes.actions';
import { BusinessareaAction } from '../businessareas';
import { Action } from '../shared/common.action';

@Injectable()
export class ImportSchemeEffects {

    private concurrency = 5;

    @Effect() fetchImportScheme$ = this.actions$
        .pipe(ofType(ImportSchemeAction.LOAD))
        .mergeMap((action: Action) => this.api.businessarea(action.payload.businessareaId).importschemes.findAll()
            .mergeMap((response: JSONAPIResponse<IImportScheme[]>) => {
                return Observable.from([
                    this.importSchemeAction.loadSuccess(response.toPayload()),
                ]);
            })
        ).catch((error: any) => Observable.of(this.importSchemeAction.loadFail(error)));

    @Effect() createImportScheme$ = this.actions$
        .pipe(ofType(ImportSchemeAction.CREATE))
        .filter((action: Action) => !!action.payload)
        .mergeMap((action: Action) => Observable.of(...action.payload.data.map(payload => {
            const data = payload;
            delete data.id;
            return this.api.businessarea(action.payload.businessareaId).importschemes.create(data)
              .mergeMap(response => Observable.from([
                this.importSchemeAction.createSuccess(payload, response.toPayload())
              ]))
              .catch((error: any) => Observable.of(this.importSchemeAction.createFail(payload, error)));
        })))
        .mergeAll(this.concurrency);

    @Effect() removeImportScheme$ = this.actions$
        .pipe(ofType(ImportSchemeAction.REMOVE))
        .filter((action: Action) => !!action.payload)
        .mergeMap((action: Action) => Observable.of(...action.payload.id.map(payload => this.api.importschemes.remove(payload)
            .mergeMap(response => Observable.from([
              this.importSchemeAction.removeSuccess(payload, response.toPayload())
            ]))
            .catch((error: any) => Observable.of(this.importSchemeAction.removeFail(payload, error)))
        )))
        .mergeAll(this.concurrency);

    @Effect() updateImportScheme$ = this.actions$
      .pipe(ofType(ImportSchemeAction.UPDATE))
      .filter((action: Action) => !!action.payload)
      .mergeMap((action: Action) => Observable.of(...action.payload.data
        .map(payload => this.api.importschemes.update(payload.id, payload.data)
        .mergeMap(response => Observable.from([
          this.importSchemeAction.updateSuccess(payload, response.toPayload())
        ]))
        .catch((error: any) => Observable.of(this.importSchemeAction.updateFail(payload, error)))
      )))
      .mergeAll(this.concurrency);


    constructor(private api: API,
                private actions$: Actions,
                private businessareaAction: BusinessareaAction,
                private importSchemeAction: ImportSchemeAction/*,
                private modelAction: ModelAction*/) {
    }
}
