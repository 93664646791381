import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { TreeNode } from '../../../core/interface/core.interface';

export class ColorLabelProviderServiceSidestep extends ColorLabelProviderAbstract {

  public static INCOMINGSIDESTEP = '#ff00ff';
  public static OUTGOINGSIDESTEP = '#6495ed';

  public color(element: TreeNode) {
    if (element.isSidestep) {
      return [ColorLabelProviderServiceSidestep.INCOMINGSIDESTEP];
    }
    if (element.sidesteps.length > 0) {
      return [ColorLabelProviderServiceSidestep.OUTGOINGSIDESTEP];
    }
    return super.color();
  }

  public legend() {
    return [
      { key: 'sidestep.regular', color: super.color(), label: 'LEGEND.REGULAR', field: 'sidestep', value: 0 },
      { key: 'sidestep.incoming', color: ColorLabelProviderServiceSidestep.INCOMINGSIDESTEP, label: 'LEGEND.SIDESTEP.INCOMINGSIDESTEP', field: 'sidestep', value: 1 },
      { key: 'sidestep.outgoing', color: ColorLabelProviderServiceSidestep.OUTGOINGSIDESTEP, label: 'LEGEND.SIDESTEP.OUTGOINGSIDESTEP', field: 'sidestep', value: 2 }
    ];
  }

  public calculate(element: any) {
    if (element.isSidestep) {
      return 1;
    }
    if (!!element.relationships && element.relationships.sidesteps.length > 0) {
      return 2;
    }
    return 0;
  }

}
