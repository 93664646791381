export const TemplateFieldsReportsFactories = [
  {
    type: 'table',
    settings: {
      /* Show the footer */
      showFooter: true,
      /* Show table fields select box */
      showFieldsSelectBox: false,
      /* Default wizard flow */
      wizardFlow: 'add.node.level',
      /* Add button label */
      wizardButtonLabel: 'REPORTS.FACTORIES.ADD',
      /* Add button level */
      wizardVariables: { 'select.level': [{ id: 2, answer: '' }] },
      /* Exports */
      exports: ['pdf', 'excel'], //  excel, pdf
    },
    configuration: {
      fields: [
        { entry: { key: 'general', label: '', controlType: 'group' }, children: [
          { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'REPORTS.FACTORIES.PROJECT', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME' }},
          { entry: { key: 'costtype', controlType: 'dropdown', label: 'REPORTS.FACTORIES.ROLLOUT.TITLE', options: [
            { key: 0, value: 'REPORTS.FACTORIES.ROLLOUT.PENDING'},
            { key: 1, value: 'REPORTS.FACTORIES.ROLLOUT.ROLLOUT'}
          ]}},
        ]}
      ]
    }
  }
];
