import { isNullOrUndefined } from 'util';

export class SelectionService {

  private _style = () => '';
  private _content = (item: any) => item.name;
  private _rel = (item: any) => '';
  private _additionalClasses = (item: any) => [];

  public rel(callback) {
    this._rel = callback;
    return this;
  }

  public style(callback) {
    this._style = callback;
    return this;
  }

  public additionalClasses(callback) {
    this._additionalClasses = callback;
    return this;
  }

  public content(callback: any) {
    this._content = callback;
    return this;
  }

  public build(items: any[]): string {
    /* Count the items */
    const count = items.length;
    /* Start html */
    let html = '<ul class="list ' + this._style() + '">';
    /* Iterate over items */
    for (let i = 0; i < count; i++) {
      /* Set the item */
      const item = items[i];
      /* Get the rel value */
      const rel = this._rel(item);
      /* Get the content */
      const content = this._content(item);
      /* Merge everything */
      html += '' +
                '<li rel="' + rel + '" class="' + this._additionalClasses(item).concat(['list-item', 'list-item-selectable']).join(' ') + '">' +
                  '<div class="list-item-inner">' + content + '</div>' +
                '</li>' +
              '';
    }
    /* Return the html string */
    return html + '</div>';
  }

  public click(event: any): { id: string, action: string, event: any } {
    let rel = event.target.getAttribute('rel');
    if (!isNullOrUndefined(rel)) {
      rel = rel.split(':');
      const action = rel[0];
      const id = rel[1];
      return { id: id, action: action, event: event };
    }
    return null;
  }

}
