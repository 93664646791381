import { Set, Map, Record } from 'immutable';
import {IAudit, IAuditAttributes} from '../connector';
import {DataModelState, RecordFactory, RequestDiffRecord} from '../shared';
import {NodeData} from '../nodedata';
import {NodeDataRelationships} from '../nodedata/nodedata.models';


const auditStateRecord = Record({
    ids: Set<string>(),
    entities: Map<string, Audit>(),
    relationships: Map<string, AuditRelationships>(),
    diff: new RequestDiffRecord(),
    isBusy: 0
});

export class AuditState extends auditStateRecord implements DataModelState {
    ids: Set<string>;
    entities: Map<string, NodeData>;
    relationships: Map<string, NodeDataRelationships>;
    diff: RequestDiffRecord;
    isBusy: number;
}

const auditRecord = Record(<IAuditAttributes>{
    id: undefined,
    event: '',
    before: {},
    after: {},
    url: '',
    userAgent: '',
    ip: '',
    tags: [],
    type: '',
    elementId: undefined,
    userId: undefined,
    responsibleId: undefined,
    updatedAt: undefined
});

export class Audit extends auditRecord implements IAuditAttributes {
    id: number;
    event: string;
    before: any;
    after: any;
    url: string;
    userAgent: string;
    ip: string;
    tags: any;
    type: string;
    elementId: string;
    userId: number;
    responsibleId: number;
    updatedAt: any;

    relationships: AuditRelationships;

    static fromJSONAPI(audit: IAudit) {
        return RecordFactory.entity.fromJSONAPI<Audit>(Audit, audit);
    }
}

const relationshipsRecord = Record({
    users: undefined,
    nodedata: undefined,
    nodestructures: undefined,
    activities: undefined,
    relationships: undefined,
    models: undefined,
    businessarea: undefined,
});

export class AuditRelationships extends relationshipsRecord {
    users: string;
    nodedata: string;
    nodestructures: string;
    activities: string;
    relationships: string;
    models: string[];
    businessarea: string;

    static fromJSONAPI(audit: IAudit) {
        return RecordFactory.relationships.fromJSONAPI<AuditRelationships>(AuditRelationships, audit);
    }
}
