import { List, Map, OrderedMap } from 'immutable';
import { isNullOrUndefined } from 'util';

import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { Model } from '../../../shared/api/models';
import { Subset } from '../../../shared/api/subsets';
import { HumanResource } from '../../../shared/api/humanresources';
import { CoreHumanResource, TreeNode } from '../../../core/interface/core.interface';
import { ColorLabelProviderLegend } from '../colorlabelprovider.interface';

export class ColorLabelProviderNodesType extends ColorLabelProviderAbstract {

  public constructor(private params: any,
                     private models: OrderedMap<string, Model>,
                     private subModels: OrderedMap<string, Model>,
                     private subSets: OrderedMap<string, Subset>,
                     private responsible: OrderedMap<string, CoreHumanResource>,
                     private treeNodes: OrderedMap<string, TreeNode>,
                     private _colorLabelProvider) {
    super();
  }

  public color(treeNode: TreeNode) {
    if (!isNullOrUndefined(this.params) && !isNullOrUndefined(this.params[treeNode.nodeType])) {
      return this.getColor(this.params[treeNode.nodeType], treeNode);
    }
    return super.color();
  }

  public legend() {
    if (isNullOrUndefined(this.params)) {
      return [];
    }
    return <ColorLabelProviderLegend[]>(Map(this.params).map(provider => {
      switch (provider) {
        case 'models':
          return { provider: provider, legend: this._colorLabelProvider.models(this.models).legend(this.treeNodes) };
        case 'submodels':
          return { provider: provider, legend: this._colorLabelProvider.submodels(this.subModels).legend(this.treeNodes) };
        case 'subsets':
          return { provider: provider, legend: this._colorLabelProvider.subsets(this.subSets).legend(this.treeNodes) };
        case 'targetDate':
          return { provider: provider, legend: this._colorLabelProvider.targetDate().legend() };
        case 'status':
          return { provider: provider, legend: this._colorLabelProvider.status().legend() };
        case 'sidestep':
          return { provider: provider, legend: this._colorLabelProvider.sidestep().legend() };
        case 'responsible':
          return { provider: provider, legend: this._colorLabelProvider.responsible(this.responsible).legend(this.treeNodes) };
        case 'difference':
          return { provider: provider, legend: this._colorLabelProvider.difference().legend() };
        case 'todostatus':
          return { provider: provider, legend: this._colorLabelProvider.basic().legend() };
        case 'importance':
          return { provider: provider, legend: this._colorLabelProvider.importance().legend() };
        case 'risk':
          return { provider: provider, legend: this._colorLabelProvider.risk().legend() };
        case 'qstatus.q1':
          return { provider: provider, legend: this._colorLabelProvider.qstatus().legend('executive') };
        case 'qstatus.q2':
          return { provider: provider, legend: this._colorLabelProvider.qstatus().legend('program') };
        case 'qstatus.q3':
          return { provider: provider, legend: this._colorLabelProvider.qstatus().legend('functional') };
        case 'qstatus.q4':
          return { provider: provider, legend: this._colorLabelProvider.qstatus().legend('resource') };
        case 'relatedstatus':
          return this._colorLabelProvider.relatedstatus().legend();
        default:
          return { provider: provider, legend: this._colorLabelProvider.basic().legend() };
      }
    }).toArray());
  }

  private getColor(provider: string, element: any) {
    switch (provider) {
      case 'models':
        return this._colorLabelProvider.models(this.models).color(element);
      case 'submodels':
        return this._colorLabelProvider.submodels(this.subModels).color(element);
      case 'subsets':
        return this._colorLabelProvider.subsets(this.subSets).color(element);
      case 'targetDate':
        return this._colorLabelProvider.targetDate().color(element);
      case 'status':
        return this._colorLabelProvider.status().color(element);
      case 'sidestep':
        return this._colorLabelProvider.sidestep().color(element);
      case 'responsible':
        return this._colorLabelProvider.responsible(this.responsible).color(element);
      case 'difference':
        return this._colorLabelProvider.difference().color(element);
      case 'importance':
        return this._colorLabelProvider.importance().color(element);
      case 'risk':
        return this._colorLabelProvider.risk().color(element);
      case 'qstatus.q1':
        return this._colorLabelProvider.qstatus().color(element, 'executive');
      case 'qstatus.q2':
        return this._colorLabelProvider.qstatus().color(element, 'program');
      case 'qstatus.q3':
        return this._colorLabelProvider.qstatus().color(element, 'functional');
      case 'qstatus.q4':
        return this._colorLabelProvider.qstatus().color(element, 'resource');
      case 'relatedstatus':
        return this._colorLabelProvider.relatedstatus().color(element);
      default:
        return this._colorLabelProvider.basic().color();
    }
  }

}
