import { OrderedMap } from 'immutable';

import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { Model } from '../../../shared/api/models';
import { TreeNode } from '../../../core/interface/core.interface';
import { ColorLabelProviderLegend } from '../colorlabelprovider.interface';
import { isNullOrUndefined } from 'util';

export class ColorLabelProviderServiceModels extends ColorLabelProviderAbstract {

  public constructor(private models: OrderedMap<string, Model>) {
    super();
  }

  public color(treeNode: TreeNode) {
    if (isNullOrUndefined(this.models)) {
      return super.color();
    }
    const color = this.models.filter(model => treeNode.models.indexOf(model.id) !== -1).map(model => model.color).toArray();
    if (color.length === 0 && this.models.has(treeNode.modelId)) {
      color.push(this.models.get(treeNode.modelId).color);
    }
    return color.length === 0 ? super.color() : color;
  }

  public legend(treeNodes: OrderedMap<string, TreeNode>) {
    if (isNullOrUndefined(this.models)) {
      return [];
    }
    const ids = [];
    treeNodes.forEach(treeNode => treeNode.models.forEach(id => ids.push(id)));
    return this.models.filter(model => ids.indexOf(model.id) !== -1).map(model => <ColorLabelProviderLegend> ({
      key: 'models.' + model.id,
      color: model.color,
      label: model.name,
      field: 'model',
      value: model.id
    })).toArray();
  }

}
