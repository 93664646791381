import { Instance, InstanceState, InstanceRelationships } from './instances.models';
import { Action } from '../shared/common.action';

import { Utils, JSONAPIDeserializerService } from '../shared';
import { InstanceAction } from './instances.actions';

export default function reducer(state = new InstanceState(), action: Action): InstanceState {
  const deserializer = new JSONAPIDeserializerService<Instance, InstanceRelationships>(Instance.fromJSONAPI, InstanceRelationships.fromJSONAPI);
  switch (action.type) {

    case InstanceAction.LOAD:
      return <InstanceState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case InstanceAction.LOAD_ALL:
      return <InstanceState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case InstanceAction.LOAD_SUCCESS: {
      const {entities, relationships} = deserializer.deserialize(action.payload.response);

      return <InstanceState>Utils.updateState<InstanceState>(state, entities, relationships)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case InstanceAction.LOAD_FAIL: {
      return <InstanceState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case InstanceAction.CREATE: {
      return <InstanceState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case InstanceAction.CREATE_SUCCESS: {
      const {entities, relationships} = deserializer.deserialize(action.payload.response);

      return <InstanceState>Utils.updateState<InstanceState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case InstanceAction.CREATE_FAIL: {
      return <InstanceState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case InstanceAction.REMOVE: {
      return <InstanceState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case InstanceAction.REMOVE_SUCCESS: {
      const {entities, relationships} = deserializer.deserialize(action.payload.response);

      return <InstanceState>Utils.deleteFromState<InstanceState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case InstanceAction.REMOVE_FAIL: {
      return <InstanceState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    // case InstanceAction.ADD_RELATIONSHIP: {
    //   const oldRelationships = state.relationships.get('' + action.payload.id);
    //   const updatedRelationships = {};
    //   const relationships: Set<string> =  oldRelationships[action.payload.type];
    //   if (!relationships) {
    //     return state;
    //   }
    //   updatedRelationships[action.payload.id] = oldRelationships.set(action.payload.type, relationships.add(action.payload.relationship));
    //   return <InstanceState>Utils.updateState<InstanceState>(state, Map<any, any>(), Map<any, any>(updatedRelationships));
    // }
    //
    // case InstanceAction.REMOVE_RELATIONSHIP: {
    //   const oldRelationships = state.relationships.get('' + action.payload.id);
    //   const updatedRelationships = {};
    //   const relationships: Set<string> =  oldRelationships[action.payload.type];
    //   if (!relationships) {
    //     return state;
    //   }
    //   updatedRelationships[action.payload.id] = oldRelationships.set(action.payload.type, relationships.remove(action.payload.relationship));
    //   return <InstanceState>Utils.updateState<InstanceState>(state, Map<any, any>(), Map<any, any>(updatedRelationships));
    // }

    default: {
      return state;
    }
  }
}
