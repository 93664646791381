import {Map, Record, Set} from 'immutable';
import { RecordFactory, RequestDiffRecord } from '../shared';
import { IOneTimeToken, IOneTimeTokenAttributes } from '../connector/models/onetimetoken.model';

const oneTimeTokenStateRecord = Record({
  ids: Set<string>(),
  entities: Map<string, OneTimeToken>(),
  diff: new RequestDiffRecord(),
  isBusy: 0
});

export class OneTimeTokenState extends oneTimeTokenStateRecord {
  ids: Set<string>;
  entities: Map<string, OneTimeToken>;
  diff: RequestDiffRecord;
  isBusy: number;
}

const oneTimeTokenRecord = Record(<IOneTimeTokenAttributes>{
  token: '',
  elementId: '',
  elementType: '',
  formNodeId: ''
});

export class OneTimeToken extends oneTimeTokenRecord implements IOneTimeTokenAttributes {
  token: string;
  elementId: string;
  elementType: string;
  formNodeId: string;

  static fromJSONAPI(oneTimeToken: IOneTimeToken) {
    return RecordFactory.entity.fromJSONAPI<OneTimeToken>(OneTimeToken, oneTimeToken);
  }
}
