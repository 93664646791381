import { List } from 'immutable';

import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { LegendItem } from '../../../../shared/legend/legend.item';

export class ColorLabelProviderServiceSidestep extends ColorLabelProviderAbstract {

  public static INCOMINGSIDESTEP = '#ff00ff';
  public static OUTGOINGSIDESTEP = '#6495ed';

  public color(element: any) {
    if (element.isSidestep) {
      return [ColorLabelProviderServiceSidestep.INCOMINGSIDESTEP];
    }
    if (!!element.relationships && element.relationships.sidesteps.length > 0) {
      return [ColorLabelProviderServiceSidestep.OUTGOINGSIDESTEP];
    }
    return super.color();
  }

  public legend() {
    return List<LegendItem>([
      new LegendItem({ key: 'sidestep.regular', color: super.color(), label: 'LEGEND.REGULAR', field: 'sidestep', value: 0 }),
      new LegendItem({ key: 'sidestep.incoming', color: ColorLabelProviderServiceSidestep.INCOMINGSIDESTEP, label: 'LEGEND.SIDESTEP.INCOMINGSIDESTEP', field: 'sidestep', value: 1 }),
      new LegendItem({ key: 'sidestep.outgoing', color: ColorLabelProviderServiceSidestep.OUTGOINGSIDESTEP, label: 'LEGEND.SIDESTEP.OUTGOINGSIDESTEP', field: 'sidestep', value: 2 })
    ]);
  }

  public calculate(element: any) {
    if (element.isSidestep) {
      return 1;
    }
    if (!!element.relationships && element.relationships.sidesteps.length > 0) {
      return 2;
    }
    return 0;
  }

}
