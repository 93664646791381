import { Record } from 'immutable';
import { isNullOrUndefined } from 'util';
import { UUID } from 'angular2-uuid';

import { Activity } from '../../../shared/api/activities/index';
import { TreeNode } from './tree.node';
import { FormGroup } from '@angular/forms';

const treeActivityRecord = Record({
  id: UUID.UUID(),
  node: undefined,
  activity: undefined,
  phantom: false,
  calculated: undefined,
  formGroup: undefined
});

export class TreeActivity extends treeActivityRecord {
  public id: string;
  public node: TreeNode;
  public phantom: boolean;
  public calculated: any;
  public activity: Activity;
  public formGroup: FormGroup;

  public constructor(activity?: Activity | any) {
    super(isNullOrUndefined(activity) ? {
      activity: new Activity()
    } : {
      id: activity.id,
      activity: activity
    });
  }
}
