export const TemplateFieldsReportsInitiativeUpdate = [
  {
    type: 'table',
    settings: {
      /* Group the elements by model */
      groupByModels: false,
      /* Show top most header row (grouped heading) */
      showHeading: true,
      /* Show the field headers */
      showFields: true,
      /* The header background color */
      headerBackgroundColor: '#ff6600',
      /* Has multi line cells */
      hasMultiCell: true,
      /* Show the footer */
      showFooter: true,
      /* Use a thousand seperator for numeric values */
      thousandsSeperator: '´',
      /* A filter function to filter by */
      filter: 'initiativeupdate',
      /* Show legend dropup */
      showLegend: true,
      /* Break the table vertically among pages */
      verticalPageBreak: false,
      /* Show total row after level group */
      showTotalsAfterLevel: false,
      /* Show total row after model group */
      showTotalsAfterModel: false,
      /* Show total row at the end of the table */
      showTotals: false,
      /* Trigger to load the Businessarea with that table */
      loadBusinessarea: true,
      /* Exports */
      exports: ['pdf', 'excel']
    },
    configuration: {
      fields: [
        { entry: { key: 'number', label: 'REPORTS.INITIATIVEUPDATE.NUMBER', controlType: 'group' }, children: [
          { entry: { key: 'number', controlType: 'primary', label: 'REPORTS.INITIATIVEUPDATE.NUMBER', pdfWidth: 30, calculated: true, editable: false, displayLabel: false, rowspan: 3, useRowspan: true }}
        ]},
        { entry: { key: 'model', label: 'REPORTS.INITIATIVEUPDATE.DB', controlType: 'group' }, children: [
          { entry: { key: 'groupedmodel', controlType: 'textbox', label: 'REPORTS.INITIATIVEUPDATE.DB', pdfWidth: 40, calculated: true, editable: false, displayLabel: false, rowspan: 3, useRowspan: true }}
        ]},
        { entry: { key: 'name', label: 'REPORTS.INITIATIVEUPDATE.NAME', controlType: 'group' }, children: [
          { entry: { key: 'name', controlType: 'textbox', type: 'text', label: 'REPORTS.INITIATIVEUPDATE.NAME', pdfWidth: 40, displayLabel: false, rowspan: 3, useRowspan: true }}
        ]},
        { entry: { key: 'sizeType', label: 'REPORTS.INITIATIVEUPDATE.SIZETYPE', controlType: 'group' }, children: [
          { entry: { key: 'sizeType', type: 'number', controlType: 'dropdown', label: 'REPORTS.INITIATIVEUPDATE.SIZETYPE', pdfWidth: 50, displayLabel: false, rowspan: 3, useRowspan: true, options: [
            { key: 0, value: 'REPORTS.INITIATIVEUPDATE.NOTAPPLICABLE' },
            { key: 1, value: 'REPORTS.INITIATIVEUPDATE.KI' },
            { key: 2, value: 'REPORTS.INITIATIVEUPDATE.BI' },
            { key: 3, value: 'REPORTS.INITIATIVEUPDATE.AI' },
            { key: 4, value: 'REPORTS.INITIATIVEUPDATE.OTHERS' }
          ]}}
        ]},
        { entry: { key: 'riskProfileCategory', label: 'REPORTS.INITIATIVEUPDATE.RISKPROFILECATEGORY', controlType: 'group' }, children: [
          { entry: { key: 'riskProfileCategory', controlType: 'dropdown',  label: 'REPORTS.INITIATIVEUPDATE.RISKPROFILECATEGORY', pdfWidth: 50, displayLabel: false, rowspan: 3, useRowspan: true, options: [
            { key: 0, value: 'REPORTS.INITIATIVEUPDATE.NOTAPPLICABLE' },
            { key: 1, value: 'REPORTS.INITIATIVEUPDATE.GMAA' },
            { key: 2, value: 'REPORTS.INITIATIVEUPDATE.CUSTOM' }
          ]}}
        ]},
        { entry: { key: 'responsible', label: 'REPORTS.INITIATIVEUPDATE.RESPONSIBLE', controlType: 'group' }, children: [
          { entry: { key: 'responsibleId', controlType: 'dropdown', label: 'REPORTS.INITIATIVEUPDATE.RESPONSIBLE', displayLabel: false, pdfWidth: 50, rowspan: 3, useRowspan: true, source: 'humanresources' }}
        ]},
        { entry: { key: 'temper', label: 'REPORTS.INITIATIVEUPDATE.TEMPER', controlType: 'group' }, children: [
          { entry: { key: 'technical', type: 'number', controlType: 'dropdown', displayLabel: false, rowspan: 3, useRowspan: true, pdfWidth: 50, label: 'REPORTS.INITIATIVEUPDATE.TEMPER', options: [
            { key: 0, value: 'REPORTS.INITIATIVEUPDATE.NOTAPPLICABLE' },
            { key: 1, value: 'REPORTS.INITIATIVEUPDATE.L1' },
            { key: 2, value: 'REPORTS.INITIATIVEUPDATE.L2' },
            { key: 3, value: 'REPORTS.INITIATIVEUPDATE.L3' },
            { key: 4, value: 'REPORTS.INITIATIVEUPDATE.L4' },
            { key: 5, value: 'REPORTS.INITIATIVEUPDATE.L5' }
          ]}}
        ]},
        { entry: { key: '', label: 'REPORTS.INITIATIVEUPDATE.TARGETACHIEVEMENT', controlType: 'group'}, children: [
          { entry: { key: 'cost', controlType: 'dropdown', label: 'REPORTS.INITIATIVEUPDATE.QUANTITATIVE', rowspan: 3, pdfWidth: 40, useRowspan: true, options: [
            { key: 0, value: 'REPORTS.INITIATIVEUPDATE.NOTAPPLICABLE', color: '#999999' },
            { key: 1, value: 'REPORTS.INITIATIVEUPDATE.LOW', color: '#9ac93e', shape: 'circle' },
            { key: 2, value: 'REPORTS.INITIATIVEUPDATE.MEDIUM', color: '#EDD515', shape: 'triangle' },
            { key: 3, value: 'REPORTS.INITIATIVEUPDATE.HIGH', color: '#E50000', shape: 'rect' },
          ] }},
          { entry: { key: 'quality', controlType: 'dropdown', label: 'REPORTS.INITIATIVEUPDATE.QUALITATIVE', rowspan: 3, pdfWidth: 40, useRowspan: true, options: [
            { key: 0, value: 'REPORTS.INITIATIVEUPDATE.NOTAPPLICABLE', color: '#999999' },
            { key: 1, value: 'REPORTS.INITIATIVEUPDATE.LOW', color: '#9ac93e', shape: 'circle' },
            { key: 2, value: 'REPORTS.INITIATIVEUPDATE.MEDIUM', color: '#EDD515', shape: 'triangle' },
            { key: 3, value: 'REPORTS.INITIATIVEUPDATE.HIGH', color: '#E50000', shape: 'rect' },
          ] }},
          { entry: { key: 'time', controlType: 'dropdown', label: 'REPORTS.INITIATIVEUPDATE.TIME', rowspan: 3, pdfWidth: 40, useRowspan: true, options: [
            { key: 0, value: 'REPORTS.INITIATIVEUPDATE.NOTAPPLICABLE', color: '#999999' },
            { key: 1, value: 'REPORTS.INITIATIVEUPDATE.LOW', color: '#9ac93e', shape: 'circle' },
            { key: 2, value: 'REPORTS.INITIATIVEUPDATE.MEDIUM', color: '#EDD515', shape: 'triangle' },
            { key: 3, value: 'REPORTS.INITIATIVEUPDATE.HIGH', color: '#E50000', shape: 'rect' },
          ] }}
        ]},
        { entry: { key: '', label: 'REPORTS.INITIATIVEUPDATE.EFFECTCONTROLLING', controlType: 'group', colspan: 3 }, children: [
          { entry: { key: 'keyfigure', label: 'REPORTS.INITIATIVEUPDATE.KEYFIGURE', controlType: 'subgroup', pdfWidth: 40, colspan: 1 }, children: [
            { entry: { key: 'string', controlType: 'string', label: 'REPORTS.INITIATIVEUPDATE.COST', pdfWidth: 40, displayLabel: false, calculated: true, editable: false }},
            { entry: { key: 'string', controlType: 'string', label: 'REPORTS.INITIATIVEUPDATE.BENEFIT', pdfWidth: 40, displayLabel: false, calculated: true, editable: false }},
            { entry: { key: 'string', controlType: 'string', label: 'REPORTS.INITIATIVEUPDATE.INVEST', pdfWidth: 40, displayLabel: false, calculated: true, editable: false }}
          ]},
          { entry: { key: 'target', label: 'REPORTS.INITIATIVEUPDATE.TARGET', controlType: 'subgroup', pdfWidth: 40, colspan: 1, nodeBucket: true, nodeBucketId: 'Y' }, children: [
            { entry: { key: 'costBudget', controlType: 'textbox', type: 'number', label: 'REPORTS.INITIATIVEUPDATE.COSTBUDGET', pdfWidth: 40, editable: true, nodeBucket: true, nodeBucketId: 'Y' }},
            { entry: { key: 'benefitBudget', controlType: 'textbox', type: 'number', label: 'REPORTS.INITIATIVEUPDATE.BENEFITBUDGET', pdfWidth: 40, editable: true, nodeBucket: true, nodeBucketId: 'Y' }},
            { entry: { key: 'investBudget', controlType: 'textbox', type: 'number', label: 'REPORTS.INITIATIVEUPDATE.INVESTBUDGET', pdfWidth: 40, editable: true, nodeBucket: true, nodeBucketId: 'Y' }}
          ]},
          { entry: { key: 'forecast', label: 'REPORTS.INITIATIVEUPDATE.FORECAST', controlType: 'subgroup', pdfWidth: 40, colspan: 1, nodeBucket: true, nodeBucketId: 'Y' }, children: [
            { entry: { key: 'costRemaining', controlType: 'textbox', type: 'number', label: 'REPORTS.INITIATIVEUPDATE.COSTREMAINING', pdfWidth: 40, editable: true, nodeBucket: true, nodeBucketId: 'Y' }},
            { entry: { key: 'benefitRemaining', controlType: 'textbox', type: 'number', label: 'REPORTS.INITIATIVEUPDATE.BENEFITREMAINING', pdfWidth: 40, editable: true, nodeBucket: true, nodeBucketId: 'Y' }},
            { entry: { key: 'investRemaining', controlType: 'textbox', type: 'number', label: 'REPORTS.INITIATIVEUPDATE.INVESTREMAINING', pdfWidth: 40, editable: true, nodeBucket: true, nodeBucketId: 'Y' }}
          ]}
        ]},
        { entry: { key: 'description', label: 'REPORTS.INITIATIVEUPDATE.DESCRIPTION', controlType: 'group' }, children: [
          { entry: { key: 'description4', controlType: 'text', displayLabel: false, rowspan: 3, useRowspan: true, label: 'REPORTS.INITIATIVEUPDATE.DESCRIPTION', pdfWidth: 143, fixedWidth: 135 }}
        ]},
        { entry: { key: 'measures', label: 'REPORTS.INITIATIVEUPDATE.MEASURES', controlType: 'group' }, children: [
          { entry: { key: 'riskProfileCountermeasures', displayLabel: false, rowspan: 3, useRowspan: true, controlType: 'text', label: 'REPORTS.INITIATIVEUPDATE.MEASURES', pdfWidth: 143, fixedWidth: 135 }}
        ]}
      ]
    }
  }
];
