import { Map } from 'immutable';

import { SettingsState } from './settings.models';
import { Action } from '../shared/common.action';
import { Utils } from '../shared';
import { SettingsAction } from './settings.actions';

export default function reducer(state = new SettingsState(), action: Action): SettingsState {

  switch (action.type) {
    case SettingsAction.LOAD:
      return <SettingsState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case SettingsAction.LOAD_SUCCESS: {
      return <SettingsState>Utils.updateStateDirect<SettingsState>(state, Map(action.payload.response), action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case SettingsAction.LOAD_FAIL: {
      return <SettingsState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case SettingsAction.UPDATE: {
      return <SettingsState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case SettingsAction.UPDATE_SUCCESS: {
      return <SettingsState>Utils.updateStateDirect<SettingsState>(state, Map(action.payload.response), action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case SettingsAction.UPDATE_FAIL: {
      return <SettingsState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case SettingsAction.REMOVE: {
      return <SettingsState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case SettingsAction.REMOVE_SUCCESS: {
      return <SettingsState>Utils.updateStateDirect<SettingsState>(state, Map(), action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case SettingsAction.REMOVE_FAIL: {
      return <SettingsState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    default: {
      return state;
    }

  }
}
