import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { API, GoAPI, IInstance } from '../connector';
import { Observable } from 'rxjs/Observable';
import { JSONAPIResponse } from '../connector';

import { InstanceAction } from './instances.actions';
import { CommonEffects } from '../shared/common.effects';
import { Action } from '../shared/common.action';

@Injectable()
export class InstanceEffects {

  private concurrency = 5;

  @Effect() loadInstance$ = this.actions$
    .pipe(ofType(InstanceAction.LOAD))
    .mergeMap((action: Action) => this.api.instances
      .include(action.payload.includes)
      .find(action.payload.id)
      .mergeMap((response: JSONAPIResponse<IInstance>) =>
        this.commonEffects.getObservables(this.instanceAction.loadSuccess, action.payload.includes, response)
      )
    ).catch((error: any) => Observable.of(this.instanceAction.loadFail(error)));

  @Effect() loadInstances$ = this.actions$
    .pipe(ofType(InstanceAction.LOAD_ALL))
    .switchMap((action: Action) => this.api.instances
      .include(action.payload.includes)
      .findAll()
      .mergeMap((response: JSONAPIResponse<IInstance[]>) =>
        this.commonEffects.getObservables(this.instanceAction.loadSuccess, action.payload.includes, response)
      )
    ).catch((error: any) => Observable.of(this.instanceAction.loadFail(error)));

  @Effect() createInstance$ = this.actions$
    .pipe(ofType(InstanceAction.CREATE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.data.map(payload => {
      const data = payload.data.toJS();
      delete data.id;
      delete data.relationships;
      return this.api.instances.create(data)
        .mergeMap(response => Observable.from([
          this.instanceAction.createSuccess(payload, response.toPayload())
        ]))
        .catch((error: any) => Observable.of(this.instanceAction.createFail(payload, error)));
    })))
    .mergeAll(this.concurrency);

  @Effect() createInstanceGo$ = this.actions$
    .pipe(ofType(InstanceAction.CREATE_GO))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.data.map(payload => {
      const data = payload.data.toJS();
      delete data.id;
      delete data.relationships;
      return this.apiGo.instances.create(data)
        .mergeMap(response => Observable.from([
          this.instanceAction.createSuccess(payload, response.toPayload())
        ]))
        .catch((error: any) => Observable.of(this.instanceAction.createFail(payload, error)));
    })))
    .mergeAll(this.concurrency);

  @Effect() removeInstance$ = this.actions$
    .pipe(ofType(InstanceAction.REMOVE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.data.map(payload => this.api.instances.remove(payload)
      .mergeMap(response => Observable.from([
        this.instanceAction.removeSuccess(payload, response.toPayload())
      ]))
      .catch((error: any) => Observable.of(this.instanceAction.removeFail(payload, error)))
    )))
    .mergeAll(this.concurrency);

  @Effect() updateInstance$ = this.actions$
    .pipe(ofType(InstanceAction.UPDATE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.map(payload => this.api.instances.update(payload.id, payload.data)
      .mergeMap(response => Observable.from([
        this.instanceAction.updateSuccess(payload, response.toPayload())
      ]))
      .catch((error: any) => Observable.of(this.instanceAction.updateFail(payload, error)))
    )))
    .mergeAll(this.concurrency);

  constructor(private api: API,
    private apiGo: GoAPI,
    private actions$: Actions,
    private instanceAction: InstanceAction,
    private commonEffects: CommonEffects) {
  }
}
