import { List, Map, Record } from 'immutable';
import { RecordFactory } from '../shared/record-factory';
import { RequestDiffRecord } from '../shared/common.models';

export interface Field {
  key: string;
  field?: string;
  nodeSource?: string;
  nodeBucket?: boolean;
  nodeBucketId?: string;
  calculated?: boolean;
}

export interface Configuration {
  loadTree?: boolean;
  loadSecondaryCosts?: boolean;
  filter?: string;
  fields: Field[];
}

export interface Request {
  configuration: Configuration;
}

const nodeRelationshipsRecord = Record({
  model: { id: 0, name: '' },
  businessarea: { id: 0, name: '' }
});

export class NodeRelationships extends nodeRelationshipsRecord {
  model: { id: number, name: string };
  businessarea: { id: number, name: string };

  static fromAPI(nodeRelationship: any) {
    return RecordFactory.relationships.fromAPI<NodeRelationships>(NodeRelationships, nodeRelationship);
  }
}


const nodeRecord = Record({
  id: undefined,
  dataId: undefined,
  businessareaId: undefined,
  number: 0,
  name: '',
  initiativeName: '',
  measureName: '',
  businesscalculation: 0,
  costtype: 0,
  complexity: 0,
  investActual: 0,
  investBudget: 0,
  investRemaining: 0,
  costActual: 0,
  costBudget: 0,
  costRemaining: 0,
  benefitActual: 0,
  benefitBudget: 0,
  benefitRemaining: 0,
  description: '',
  description1: '',
  description2: '',
  description3: '',
  description4: '',
  wirkung: '',
  category: '',
  weight: '',
  budgetY1: 0,
  budgetY1Q1: 0,
  budgetY1Q2: 0,
  budgetY1Q3: 0,
  budgetY1Q4: 0,
  budgetY2: 0,
  budgetY2Q1: 0,
  budgetY2Q2: 0,
  budgetY2Q3: 0,
  budgetY2Q4: 0,
  budgetY3: 0,
  budgetY3Q1: 0,
  budgetY3Q2: 0,
  budgetY3Q3: 0,
  budgetY3Q4: 0,
  budgetPlanY1: 0,
  budgetPlanY1Q1: 0,
  budgetPlanY1Q2: 0,
  budgetPlanY1Q3: 0,
  budgetPlanY1Q4: 0,
  budgetPlanY2: 0,
  budgetPlanY2Q1: 0,
  budgetPlanY2Q2: 0,
  budgetPlanY2Q3: 0,
  budgetPlanY2Q4: 0,
  budgetPlanY3: 0,
  budgetPlanY3Q1: 0,
  budgetPlanY3Q2: 0,
  budgetPlanY3Q3: 0,
  budgetPlanY3Q4: 0,
  budgetKpiY1: 0,
  budgetKpiY1Q1: 0,
  budgetKpiY1Q2: 0,
  budgetKpiY1Q3: 0,
  budgetKpiY1Q4: 0,
  budgetKpiY2: 0,
  budgetKpiY2Q1: 0,
  budgetKpiY2Q2: 0,
  budgetKpiY2Q3: 0,
  budgetKpiY2Q4: 0,
  budgetKpiY3: 0,
  budgetKpiY3Q1: 0,
  budgetKpiY3Q2: 0,
  budgetKpiY3Q3: 0,
  budgetKpiY3Q4: 0,
  budgetPlanKpiY1: 0,
  budgetPlanKpiY1Q1: 0,
  budgetPlanKpiY1Q2: 0,
  budgetPlanKpiY1Q3: 0,
  budgetPlanKpiY1Q4: 0,
  budgetPlanKpiY2: 0,
  budgetPlanKpiY2Q1: 0,
  budgetPlanKpiY2Q2: 0,
  budgetPlanKpiY2Q3: 0,
  budgetPlanKpiY2Q4: 0,
  budgetPlanKpiY3: 0,
  budgetPlanKpiY3Q1: 0,
  budgetPlanKpiY3Q2: 0,
  budgetPlanKpiY3Q3: 0,
  budgetPlanKpiY3Q4: 0,
  budgetPlanInitiativeY1: 0,
  budgetPlanInitiativeY1Q1: 0,
  budgetPlanInitiativeY1Q2: 0,
  budgetPlanInitiativeY1Q3: 0,
  budgetPlanInitiativeY1Q4: 0,
  budgetPlanInitiativeY2: 0,
  budgetPlanInitiativeY2Q1: 0,
  budgetPlanInitiativeY2Q2: 0,
  budgetPlanInitiativeY2Q3: 0,
  budgetPlanInitiativeY2Q4: 0,
  budgetPlanInitiativeY3: 0,
  budgetPlanInitiativeY3Q1: 0,
  budgetPlanInitiativeY3Q2: 0,
  budgetPlanInitiativeY3Q3: 0,
  budgetPlanInitiativeY3Q4: 0,
  costInitiativeY1: 0,
  costInitiativeY1Q1: 0,
  costInitiativeY1Q2: 0,
  costInitiativeY1Q3: 0,
  costInitiativeY1Q4: 0,
  costInitiativeY2: 0,
  costInitiativeY2Q1: 0,
  costInitiativeY2Q2: 0,
  costInitiativeY2Q3: 0,
  costInitiativeY2Q4: 0,
  costInitiativeY3: 0,
  costInitiativeY3Q1: 0,
  costInitiativeY3Q2: 0,
  costInitiativeY3Q3: 0,
  costInitiativeY3Q4: 0,
  initiativeCostActual: '',
  initiativeCostBudget: '',
  initiativeCostRemaining: '',
  initiativeInvestActual: '',
  initiativeInvestBudget: '',
  initiativeInvestRemaining: '',
  kpiName: '',
  kpiDescription: '',
  kpiMeasure: '',
  kpiBudget: 0,
  kpiRiskProfileCountermeasures: '',
  sizeType: 0,
  priority: 0,
  organizational: 0,
  responsibleId: 0,
  organizationalDescription: '',
  technical: 0,
  riskProfileAssessmentStatus: 0,
  costStatus: 0,
  qualityStatus: 0,
  timeStatus: 0,
  complexityStatus: 0,
  cost: 0,
  costchildren: '',
  effectebitdadetail: 0,
  effectebitdarough: 0,
  effectinvestdetail: 0,
  effectinvestrough: 0,
  effecttotaldetail: 0,
  effecttotalrough: 0,
  quality: 0,
  qualitychildren: '',
  riskProfileAssessment: 0,
  riskProfileCountermeasures: '',
  risks: '',
  status: 0,
  commercialStatus: 0,
  uncertainty: 0,
  riskProfileCategory: 0,
  time: 0,
  responsibledb: '',
  nodeType: 0,
  relationships: undefined,
  origin: undefined,
  workFlowModel: 0,
  workFlowOperation: '',
  workFlowIfClause: '',
  workFlowId: '',
  workFlowFormId: '',
  workFlowFormParams: '',
  workFlowLink: '',
  workFlowTabId: '',
  formId: '',
  formFieldId: '',
  formFieldType: '',
  formFieldControlType: '',
  formFieldDropdownValue: '',
  formFieldEditable: false,
  formFieldSortable: true,
  formFieldFilterable: false,
  formFieldFixed: false,
  formFieldSearchable: false,
  formFieldWidth: null,
  formFieldShape: '',
  formFieldBucketId: '',
  fullscreen: false,
  printable: false,
  hideWidget: false,
  dashboardCols: null,
  dashboardRows: null,
  dashboardX: null,
  dashboardY: null,
  fieldConversion: null,
  fieldCreateType: null,
  fieldSkipIfExists: false,
  colorLabelProvider: null
});

export class Node extends nodeRecord {
  id: string;
  dataId: string;
  businessareaId: string;
  number: number;
  name: string;
  initiativeName: string;
  measureName: string;
  businesscalculation: number;
  costtype: number;
  complexity: number;
  investActual: number;
  investBudget: number;
  investRemaining: number;
  costActual: number;
  costBudget: number;
  costRemaining: number;
  benefitActual: number;
  benefitBudget: number;
  benefitRemaining: number;
  description: string;
  description1: string;
  description2: string;
  description3: string;
  wirkung: string;
  category: string;
  weight: string;
  budgetY1: number;
  budgetY1Q1: number;
  budgetY1Q2: number;
  budgetY1Q3: number;
  budgetY1Q4: number;
  budgetY2: number;
  budgetY2Q1: number;
  budgetY2Q2: number;
  budgetY2Q3: number;
  budgetY2Q4: number;
  budgetY3: number;
  budgetY3Q1: number;
  budgetY3Q2: number;
  budgetY3Q3: number;
  budgetY3Q4: number;
  budgetPlanY1: number;
  budgetPlanY1Q1: number;
  budgetPlanY1Q2: number;
  budgetPlanY1Q3: number;
  budgetPlanY1Q4: number;
  budgetPlanY2: number;
  budgetPlanY2Q1: number;
  budgetPlanY2Q2: number;
  budgetPlanY2Q3: number;
  budgetPlanY2Q4: number;
  budgetPlanY3: number;
  budgetPlanY3Q1: number;
  budgetPlanY3Q2: number;
  budgetPlanY3Q3: number;
  budgetPlanY3Q4: number;
  budgetKpiY1: number;
  budgetKpiY1Q1: number;
  budgetKpiY1Q2: number;
  budgetKpiY1Q3: number;
  budgetKpiY1Q4: number;
  budgetKpiY2: number;
  budgetKpiY2Q1: number;
  budgetKpiY2Q2: number;
  budgetKpiY2Q3: number;
  budgetKpiY2Q4: number;
  budgetKpiY3: number;
  budgetKpiY3Q1: number;
  budgetKpiY3Q2: number;
  budgetKpiY3Q3: number;
  budgetKpiY3Q4: number;
  budgetPlanKpiY1: number;
  budgetPlanKpiY1Q1: number;
  budgetPlanKpiY1Q2: number;
  budgetPlanKpiY1Q3: number;
  budgetPlanKpiY1Q4: number;
  budgetPlanKpiY2: number;
  budgetPlanKpiY2Q1: number;
  budgetPlanKpiY2Q2: number;
  budgetPlanKpiY2Q3: number;
  budgetPlanKpiY2Q4: number;
  budgetPlanKpiY3: number;
  budgetPlanKpiY3Q1: number;
  budgetPlanKpiY3Q2: number;
  budgetPlanKpiY3Q3: number;
  budgetPlanKpiY3Q4: number;
  budgetPlanInitiativeY1: number;
  budgetPlanInitiativeY1Q1: number;
  budgetPlanInitiativeY1Q2: number;
  budgetPlanInitiativeY1Q3: number;
  budgetPlanInitiativeY1Q4: number;
  budgetPlanInitiativeY2: number;
  budgetPlanInitiativeY2Q1: number;
  budgetPlanInitiativeY2Q2: number;
  budgetPlanInitiativeY2Q3: number;
  budgetPlanInitiativeY2Q4: number;
  budgetPlanInitiativeY3: number;
  budgetPlanInitiativeY3Q1: number;
  budgetPlanInitiativeY3Q2: number;
  budgetPlanInitiativeY3Q3: number;
  budgetPlanInitiativeY3Q4: number;
  costInitiativeY1: number;
  costInitiativeY1Q1: number;
  costInitiativeY1Q2: number;
  costInitiativeY1Q3: number;
  costInitiativeY1Q4: number;
  costInitiativeY2: number;
  costInitiativeY2Q1: number;
  costInitiativeY2Q2: number;
  costInitiativeY2Q3: number;
  costInitiativeY2Q4: number;
  costInitiativeY3: number;
  costInitiativeY3Q1: number;
  costInitiativeY3Q2: number;
  costInitiativeY3Q3: number;
  costInitiativeY3Q4: number;
  initiativeCostActual: string;
  initiativeCostBudget: string;
  initiativeCostRemaining: string;
  initiativeInvestActual: string;
  initiativeInvestBudget: string;
  initiativeInvestRemaining: string;
  kpiName: string;
  kpiDescription: string;
  kpiMeasure: string;
  kpiBudget: number;
  kpiRiskProfileCountermeasures: string;
  sizeType: number;
  priority: number;
  organizational: number;
  responsibleId: number;
  organizationalDescription: string;
  technical: number;
  riskProfileAssessmentStatus: number;
  costStatus: number;
  qualityStatus: number;
  timeStatus: number;
  complexityStatus: number;
  cost: number;
  costchildren: string;
  effectebitdadetail: number;
  effectebitdarough: number;
  effectinvestdetail: number;
  effectinvestrough: number;
  effecttotaldetail: number;
  effecttotalrough: number;
  quality: number;
  qualitychildren: string;
  riskProfileAssessment: number;
  riskProfileCountermeasures: string;
  risks: string;
  status: number;
  commercialStatus: number;
  uncertainty: number;
  riskProfileCategory: number;
  time: number;
  responsibledb: string;
  nodeType: number;
  relationships: NodeRelationships;
  origin: any;
  workFlowModel: number;
  workFlowOperation: string;
  workFlowIfClause: string;
  workFlowId: string;
  workFlowFormId: string;
  workFlowFormParams: string;
  workFlowLink: string;
  workFlowTabId: string;

  formId: string;
  formFieldId: string;
  formFieldType: string;
  formFieldControlType: string;
  formFieldDropdownValue: string;
  formFieldEditable: boolean;
  formFieldSortable: boolean;
  formFieldFilterable: boolean;
  formFieldFixed: boolean;
  formFieldSearchable: boolean;
  formFieldWidth: string;
  formFieldShape: string;
  formFieldBucketId: string;
  fullscreen: boolean;
  printable: boolean;
  hideWidget: boolean;
  dashboardCols: number;
  dashboardRows: number;
  dashboardX: number;
  dashboardY: number;
  fieldConversion: string;
  fieldCreateType: string;
  fieldSkipIfExists: boolean;
  colorLabelProvider: string;

  static fromAPI(node: any) {
    return RecordFactory.entity.fromAPI<Node>(Node, node);
  }
}

const nodeStateRecord = Record({
  entities: Map<string, Node>(),
  relationships: Map<string, NodeRelationships>(),
  secondaryCosts: Map<string, List<Node>>(),
  diff: new RequestDiffRecord(),
  isBusy: 0,
  isBusyIPC: 0
});

export class NodeState extends nodeStateRecord {
  entities: Map<string, Node>;
  relationships: Map<string, NodeRelationships>;
  secondaryCosts: Map<string, List<Node>>;
  diff: RequestDiffRecord;
  isBusy: number;
  isBusyIPC: number;
}
