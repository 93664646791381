import { Set, OrderedSet, OrderedMap, List, Map } from 'immutable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { FormGroup } from '@angular/forms';
import { EventEmitter } from '@angular/core';

import { Template } from './shared/api/templates/templates.models';
import { HumanResource } from './shared/api/humanresources/humanresources.models';
import { RadialMenuItem } from './working/shared/radial-menu/radial-menu-item';
import { Hierarchy } from './shared/api/hierarchy';
import { TreeNode } from './working/shared/tree/tree.node';
import { Subset } from './shared/api/subsets';
import { NotificationComponent } from './shared/notifications/notification.component';
import { RequestDiffRecord } from './shared/api/shared';
import { User } from './shared/api/user';
import { TranslationService } from './shared/services/translation/translation.service';
import { Grid } from './shared/utilities/grid';
import { Group } from './shared';


export class AppShared {

  /* Debug Mode */
  public debugMode = false;

  /* Passphrase */
  public phrase = 'AUFzK62l67C6Tv5FFKS7OMZm2TrVRT';

  /* Collapse */
  public sidebarCollapsed = false;
  public userSidebarCollapsed = false;
  public datasheetCollapsed = false;
  public datasheetHidden = true;
  public userDatasheetCollapsed = false;

  /* General settings */
  public isIE = /MSIE|rv:11/.test(navigator.userAgent);
  public isExport = false;
  public isEdit = false;

  /* Loaders */
  public loadAssigned = new BehaviorSubject(false);
  public loadHierarchy = new BehaviorSubject(false);

  /* Sizes */
  public windowHeight = new BehaviorSubject<number>(window.innerHeight);
  public windowWidth = new BehaviorSubject<number>(window.innerWidth);

  /* Router */
  public routerChanged = new BehaviorSubject<boolean>(true);
  public instanceId: string;
  public businessareaId: string;
  public modelId: string;
  public subModelId: string;
  public subSetId: string;
  public nodeId: string;
  public token: string;
  public selectNodes: string[];
  public working: boolean;
  public highlightNodes = new BehaviorSubject<string[]>([]);

  /* Events */
  public nodesSelected = new BehaviorSubject<Set<TreeNode>>(Set<TreeNode>());
  public directChainSelected = new BehaviorSubject<string[]>([]);

  /* Sidebar */
  public showCollapsed = '';
  public showUserProfile = new BehaviorSubject(true);
  public showFavoriteModels = new BehaviorSubject(false);
  public showHumanResources = new BehaviorSubject(false);
  public showSubsets = new BehaviorSubject(false);
  public showNotificationList = new BehaviorSubject(false);

  /* Global values */
  public datasheetVisible = new BehaviorSubject(false);
  public datasheetValues = new Subject<Set<any>>();
  public datasheetType = new Subject<string>();
  public datasheetCancel = new Subject<any>();
  public dataPopUpValues = new Subject<Set<any>>();
  public dataPopUpValuesToIgnore = new Subject<Set<any>>();
  public dataPopUpPreventDefault = new Subject<boolean>();
  public dataPopUpType = new Subject<string | any>();
  public dataPopUpCancel = new Subject<any>();
  public dataPopUpUpdate = new Subject<{ element: any, formGroup: FormGroup }>();
  public dataPopUpSuccess = new Subject<RequestDiffRecord>();
  public dataPopUpSource = new Subject<string>();
  public dataPopUpTranslation = new Subject<TranslationService>();
  public dataPopUpFormParams = new Subject<string>();

  public questionnaireTabs = new BehaviorSubject<OrderedMap<string, TreeNode>>(OrderedMap<string, TreeNode>());
  public questionnaireContent = new Subject<any>();
  public questionnaireUpdated = new Subject<any>();
  public questionnaireClose = new Subject<any>();

  /* Tree select */
  public treeSelect = new Subject<Function>();

  /* Radial menu */
  public radialMenuAddItem = new Subject<any[]>();
  public radialMenuRemoveItem = new Subject<string>();
  public radialMenuRemoveLevel = new Subject<string>();
  public radialMenuClicked = new Subject<RadialMenuItem>();

  /* User */
  public user = new BehaviorSubject<User>(new User());

  /* Template handling */
  public selectedTemplate = new BehaviorSubject<Template>(new Template());

  /* Language handling */
  public selectedLanguage = new BehaviorSubject<any>({});

  /* Human resource handling */
  public humanResources = new BehaviorSubject<OrderedSet<HumanResource>>(OrderedSet<HumanResource>());
  public humanResourceClicked = new Subject<HumanResource>();
  public humanResourceDoubleClicked = new Subject<HumanResource>();

  /* Group handling */
  public groups = new BehaviorSubject<OrderedSet<Group>>(OrderedSet<Group>());

  /* Subset handling */
  public subSets = new BehaviorSubject<OrderedSet<Subset>>(OrderedSet<Subset>());
  public subSetClicked = new BehaviorSubject<Subset>(null);
  public subsetDoubleClicked = new Subject<Subset>();

  /* Favorite models */
  public favoriteModels = OrderedMap<string, any>();

  /* Hierarchy */
  public hierarchy = new BehaviorSubject<Hierarchy>(new Hierarchy());

  /* Settings */
  public pointerMode = new BehaviorSubject(false);
  public editMode = new BehaviorSubject(false);
  public directChain = new BehaviorSubject(false);
  public subTree = new BehaviorSubject(false);

  /* Color Label Provider */
  public colorLabelProviderColor = '';
  public colorLabelProviderLabelTop = '';
  public colorLabelProviderLabelBottom = 'name';

  /* Notification */
  public notificationComponent: NotificationComponent;

  /* Dashboard */
  public dashboardEditMode = new BehaviorSubject(false);
  public dashboardRemoveFromSidebar = new Subject();
  public dashboardResized = new EventEmitter();
  public dashboardChanged = new EventEmitter<string>();
  public dashboardChangePreset = new EventEmitter();
  public dashboardCurrentGrid: Grid;

  /* Translation map */
  public translationMap = Map<string, any>();

  /* Entry Map for forms */
  public entryMap: Map<string, Map<string, any>>;
  public childrenMap: Map<string, Map<string, string>>;
}
