export const TemplateFieldsReportsStrategyOverview = [
  {
    type: 'table',
    settings: {
      /* Show top most header row (grouped heading) */
      showHeading: true,
      /* Show the footer */
      showFooter: true,
      /* Group the elements by levels */
      groupByLevels: true,
      /* Break the collapsing on level border */
      breakCollapseOnLevel: true,
      /* Exports */
      exports: ['excel', 'pdf'], //  excel, pdf
      /* The header background color */
      headerBackgroundColor: '#9ac93e',
      /* The header text color */
      headerTextColor: '#ffffff',
      /* Color label provider */
      colorLabelProvider: 'relatedstatus', // boolean, string
      /* Allow page to break vertically */
      verticalPageBreak: true, // boolean
    },
    configuration: {
      fields: [
        { entry: { key: 'structure', label: 'TABLE.STRUCTURE.TITLE', displayLabel: false, controlType: 'structure', shape: 'ellipsis-v', additional: { addchild: true, addsibling: true, edit: true, delete: true, create: true, unlink: true, changeparent: true, link: true } }},
        { entry: { key: 'wizard', label: '', controlType: 'wizard', source: 'email-todo', icon: 'envelope', editable: true, fixedWidth: 30 }},
        { entry: { key: 'wizard', label: '', controlType: 'wizard', source: 'email-token', icon: 'user-edit', editable: true, fixedWidth: 30 }},
        { entry: { key: 'wizard', label: '', controlType: 'wizard', source: 'hoshin-kanri-edit', icon: 'project-diagram', editable: true, fixedWidth: 30 }},
        { entry: { key: 'sublevel', label: 'TABLE.SUBLEVEL', displayLabel: false, controlType: 'sublevel' }},
        { entry: { key: 'general', label: 'REPORTS.STRATEGYOVERVIEW.GROUPS.GENERAL', controlType: 'group'}, children: [
          { entry: { key: 'reference', type: 'text', controlType: 'textbox', label: 'REPORTS.STRATEGYOVERVIEW.REFERENCE', fixedWidth: 75, sortable: true }},
          { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'REPORTS.STRATEGYOVERVIEW.NAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME', fixedWidth: 75, sortable: true }},
          { entry: { key: 'picture', controlType: 'picture', label: 'REPORTS.STRATEGYOVERVIEW.PICTURE', source: 'humanresources', calculated: true, editable: false, fixedWidth: 75 }},
          { entry: { key: 'responsibleId', controlType: 'dropdown', label: 'REPORTS.STRATEGYOVERVIEW.RESPONSIBLEID', hasNull: true, source: 'humanresources', sortable: true, filter: true, fixedWidth: 80 }},
          { entry: { key: 'description', controlType: 'text', label: 'REPORTS.STRATEGYOVERVIEW.DESCRIPTION', sortable: true, fixedWidth: 250 }},
          { entry: { key: 'costBudget', type: 'number', controlType: 'textbox', label: 'REPORTS.STRATEGYOVERVIEW.COSTBUDGET', sortable: true, fixedWidth: 75 }},
        ]},
        { entry: { key: 'space', label: '', controlType: 'space', editable: false, fixedWidth: 10 }},
        { entry: { key: '', label: 'REPORTS.STRATEGYOVERVIEW.GROUPS.RELATIVEBENEFIT', controlType: 'group'}, children: [
          { entry: { key: 'groupedmodelslist', controlType: 'text', editable: false, label: 'REPORTS.STRATEGYOVERVIEW.CONNECTIONS', calculated: true, fixedWidth: 90 }},
          { entry: { key: 'stategicClassification', controlType: 'textbox', label: 'REPORTS.STRATEGYOVERVIEW.STRATEGICCLASSIFICATION', sortable: true, editable: false, calculated: true, fixedWidth: 90 }},
          { entry: { key: 'challenge', controlType: 'textbox', label: 'REPORTS.STRATEGYOVERVIEW.CHALLENGE', editable: false, calculated: true, sortable: true, fixedWidth: 90 }},
        ]},
        { entry: { key: '', label: 'REPORTS.STRATEGYOVERVIEW.GROUPS.QSTATUS', controlType: 'group'}, children: [
          { entry: { key: 'executive', controlType: 'dropdown', label: 'REPORTS.STRATEGYOVERVIEW.EXECUTIVE.TITLE', addNull: false, fixedWidth: 75, options: [
            { key: 0, value: 'REPORTS.STRATEGYOVERVIEW.EXECUTIVE.NOTAPPLICABLE', color: '#999999' },
            { key: 1, value: 'REPORTS.STRATEGYOVERVIEW.EXECUTIVE.ONTRACK', color: '#9ac93e', shape: 'circle' },
            { key: 2, value: 'REPORTS.STRATEGYOVERVIEW.EXECUTIVE.BEHIND', color: '#EDD515', shape: 'triangle' },
            { key: 3, value: 'REPORTS.STRATEGYOVERVIEW.EXECUTIVE.CRITICAL', color: '#E50000', shape: 'rect' },
            { key: 4, value: 'REPORTS.STRATEGYOVERVIEW.EXECUTIVE.STOPPED', color: '#E50000', shape: 'circle' },
            { key: 5, value: 'REPORTS.STRATEGYOVERVIEW.EXECUTIVE.ROLLOUT', color: '#9ac93e', shape: 'rect' },
          ]}},
          { entry: { key: 'program', controlType: 'dropdown', label: 'REPORTS.STRATEGYOVERVIEW.PROGRAM.TITLE', addNull: false, fixedWidth: 75, options: [
            { key: 0, value: 'REPORTS.STRATEGYOVERVIEW.PROGRAM.NOTAPPLICABLE', color: '#999999' },
            { key: 1, value: 'REPORTS.STRATEGYOVERVIEW.PROGRAM.ONTRACK', color: '#9ac93e', shape: 'circle' },
            { key: 2, value: 'REPORTS.STRATEGYOVERVIEW.PROGRAM.BEHIND', color: '#EDD515', shape: 'triangle' },
            { key: 3, value: 'REPORTS.STRATEGYOVERVIEW.PROGRAM.CRITICAL', color: '#E50000', shape: 'rect' },
            { key: 4, value: 'REPORTS.STRATEGYOVERVIEW.PROGRAM.STOPPED', color: '#E50000', shape: 'circle' },
            { key: 5, value: 'REPORTS.STRATEGYOVERVIEW.PROGRAM.ROLLOUT', color: '#9ac93e', shape: 'rect' },
          ]}},
          { entry: { key: 'functional', controlType: 'dropdown', label: 'REPORTS.STRATEGYOVERVIEW.FUNCTIONAL.TITLE', addNull: false, fixedWidth: 75, options: [
            { key: 0, value: 'REPORTS.STRATEGYOVERVIEW.FUNCTIONAL.NOTAPPLICABLE', color: '#999999' },
            { key: 1, value: 'REPORTS.STRATEGYOVERVIEW.FUNCTIONAL.ONTRACK', color: '#9ac93e', shape: 'circle' },
            { key: 2, value: 'REPORTS.STRATEGYOVERVIEW.FUNCTIONAL.BEHIND', color: '#EDD515', shape: 'triangle' },
            { key: 3, value: 'REPORTS.STRATEGYOVERVIEW.FUNCTIONAL.CRITICAL', color: '#E50000', shape: 'rect' },
            { key: 4, value: 'REPORTS.STRATEGYOVERVIEW.FUNCTIONAL.STOPPED', color: '#E50000', shape: 'circle' },
            { key: 5, value: 'REPORTS.STRATEGYOVERVIEW.FUNCTIONAL.ROLLOUT', color: '#9ac93e', shape: 'rect' },
          ]}},
          { entry: { key: 'resource', controlType: 'dropdown', label: 'REPORTS.STRATEGYOVERVIEW.RESOURCE.TITLE', addNull: false, fixedWidth: 75, options: [
            { key: 0, value: 'REPORTS.STRATEGYOVERVIEW.RESOURCE.NOTAPPLICABLE', color: '#999999' },
            { key: 1, value: 'REPORTS.STRATEGYOVERVIEW.RESOURCE.ONTRACK', color: '#9ac93e', shape: 'circle' },
            { key: 2, value: 'REPORTS.STRATEGYOVERVIEW.RESOURCE.BEHIND', color: '#EDD515', shape: 'triangle' },
            { key: 3, value: 'REPORTS.STRATEGYOVERVIEW.RESOURCE.CRITICAL', color: '#E50000', shape: 'rect' },
            { key: 4, value: 'REPORTS.STRATEGYOVERVIEW.RESOURCE.STOPPED', color: '#E50000', shape: 'circle' },
            { key: 5, value: 'REPORTS.STRATEGYOVERVIEW.RESOURCE.ROLLOUT', color: '#9ac93e', shape: 'rect' },
          ]}},
        ]},
        { entry: { key: '', label: '', controlType: 'group'}, children: [
          { entry: { key: 'lastStatus', controlType: 'text', calculated: true, fixedWidth: 250, label: 'REPORTS.STRATEGYOVERVIEW.DESCRIPTION1'}}
        ]},
        { entry: { key: '', label: '', controlType: 'group'}, children: [
          { entry: { key: 'status', controlType: 'dropdown', label: 'REPORTS.STRATEGYOVERVIEW.ROLLOUTSTATUS.TITLE', fixedWidth: 100, sortable: true, filter: true, options: [
            { key: 0, value: 'REPORTS.STRATEGYOVERVIEW.ROLLOUTSTATUS.PENDING' },
            { key: 100, value: 'REPORTS.STRATEGYOVERVIEW.ROLLOUTSTATUS.ONGOING' },
            { key: 110, value: 'REPORTS.STRATEGYOVERVIEW.ROLLOUTSTATUS.ONGOING' },
            { key: 120, value: 'REPORTS.STRATEGYOVERVIEW.ROLLOUTSTATUS.ONGOING' },
            { key: 130, value: 'REPORTS.STRATEGYOVERVIEW.ROLLOUTSTATUS.ONGOING' },
            { key: 200, value: 'REPORTS.STRATEGYOVERVIEW.ROLLOUTSTATUS.COMPLETED' }
          ]}},
        ]},
        { entry: { key: 'space', label: '', controlType: 'space', editable: false, fixedWidth: 10}},
        { entry: { key: 'targetdate', label: 'REPORTS.STRATEGYOVERVIEW.GROUPS.TARGETDATE', controlType: 'group'}, children: [
            { entry: { key: 'startDate', type: 'datetime', controlType: 'date', fixedWidth: 75, label: 'NODE.DATASHEET.FIELDS.START' }},
            { entry: { key: 'targetDate', type: 'datetime', controlType: 'date', fixedWidth: 75, label: 'NODE.DATASHEET.FIELDS.TARGETDATE' }},
            { entry: { key: 'actualDate', type: 'datetime', controlType: 'date', fixedWidth: 75, label: 'NODE.DATASHEET.FIELDS.ACTUALDATE' }}
          ]},
        { entry: { key: 'space', label: '', controlType: 'space', editable: false, fixedWidth: 10 }},
        { entry: { key: 'roadmap', label: 'REPORTS.STRATEGYOVERVIEW.ROADMAP', controlType: 'group', colspan: 48 }, children: [
            { entry: { key: 'roadmapy', controlType: 'roadmap', label: 'TABLE.YEAR', params: 'Y', colspan: 12, fixedWidth: 75, editable: false }},
            { entry: { key: 'roadmapy1', controlType: 'roadmap', label: 'TABLE.YEAR', params: 'Y+1', colspan: 12, fixedWidth: 75, editable: false }},
            { entry: { key: 'roadmapy2', controlType: 'roadmap', label: 'TABLE.YEAR', params: 'Y+2', colspan: 12, fixedWidth: 75, editable: false }},
            { entry: { key: 'roadmapy3', controlType: 'roadmap', label: 'TABLE.YEAR', params: 'Y+3', colspan: 12, fixedWidth: 75, editable: false }}
          ]},
      ]
    }
  }
];
