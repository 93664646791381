import { Store } from '@ngrx/store';
import { Set, Map } from 'immutable';

import { JSONAPIDeserializerService } from '../shared';
import { Utils } from '../shared/utils';
import { Action } from '../shared/common.action';

import { ModelState, Model, ModelRelationships } from './models.models';
import { ModelAction } from './models.actions';

export default function reducer(state = new ModelState(), action: Action): ModelState {
  const deserializer = new JSONAPIDeserializerService<Model, ModelRelationships>(Model.fromJSONAPI, ModelRelationships.fromJSONAPI);

  switch (action.type) {

    case ModelAction.LOAD_FAVORITES:
      return <ModelState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case ModelAction.LOAD_FAVORITES_SUCCESS: {
      return <ModelState>state
        .updateIn(['favorites'], (favorites: Map<string, any>) => {
          favorites = favorites.clear();
          action.payload.response.forEach(fav => favorites = favorites.set(fav.id, { name: fav.attributes.name, url: ('/working' + fav.attributes.url.substring(0, fav.attributes.url.length - 1)) }));
          return favorites;
        })
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case ModelAction.ADD_FAVORITE:
      return <ModelState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case ModelAction.ADD_FAVORITE_SUCCESS:
      return <ModelState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['favorites'], (favorites: Map<string, any>) => {
          favorites = favorites.clear();
          action.payload.response.forEach(fav => favorites = favorites.set(fav.id, { name: fav.attributes.name, url: ('/working' + fav.attributes.url.slice(0, -1)) }));
          return favorites;
        })
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );

    case ModelAction.ADD_FAVORITE_FAIL:
      return <ModelState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );

    case ModelAction.REMOVE_FAVORITE:
      return <ModelState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case ModelAction.REMOVE_FAVORITE_SUCCESS:
      return <ModelState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['favorites'], (favorites: Map<string, any>) => {
          favorites = favorites.clear();
          action.payload.response.forEach(fav => favorites = favorites.set(fav.id, { name: fav.attributes.name, url: ('/working' + fav.attributes.url.slice(0, -1)) }));
          return favorites;
        })
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );

    case ModelAction.REMOVE_FAVORITE_FAIL:
      return <ModelState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => --isBusy)
      );

    case ModelAction.MASSLOAD_SUCCESS:
      return Utils.updateDiff(state, action);

    case ModelAction.MASSLOAD:
      return <ModelState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => ++isBusy)
      );

    case ModelAction.INITIATIVESLOAD:
      return <ModelState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case ModelAction.INITIATIVESLOAD_SUCCESS:
      action.payload.response = action.payload.data;
      return <ModelState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => --isBusy)
      );

    case ModelAction.INITIATIVESLOAD_FAIL:
      return <ModelState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => --isBusy)
      );

    case ModelAction.LOAD:
      return <ModelState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case ModelAction.LOAD_SUCCESS: {
      const { entities, relationships } = deserializer.deserialize(action.payload.response);
      return <ModelState>Utils.updateState<ModelState>(state, entities, relationships)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case ModelAction.LOAD_FAIL: {
      return <ModelState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case ModelAction.DUPLICATE_ON_BUSINESSAREA:
    case ModelAction.CREATE_ON_BUSINESSAREA: {
      return <ModelState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case ModelAction.DUPLICATE_ON_MODEL:
    case ModelAction.CREATE_ON_MODEL: {
      return <ModelState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case ModelAction.DUPLICATE_SUCCESS:
    case ModelAction.CREATE_SUCCESS: {
      const { entities, relationships } = deserializer.deserialize(action.payload.response);
      return <ModelState>Utils.updateState<ModelState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case ModelAction.DUPLICATE_FAIL:
    case ModelAction.CREATE_FAIL: {
      return <ModelState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case ModelAction.UPDATE: {
      return <ModelState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case ModelAction.UPDATE_SUCCESS: {
      const { entities, relationships } = deserializer.deserialize(action.payload.response);

      return <ModelState>Utils.updateState<ModelState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case ModelAction.UPDATE_FAIL: {
      return <ModelState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case ModelAction.REMOVE: {
      return <ModelState>state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case ModelAction.REMOVE_SUCCESS: {
      const { entities, relationships } = deserializer.deserialize(action.payload.response);
      return <ModelState>Utils.deleteFromState<ModelState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case ModelAction.REMOVE_FAIL: {
      return <ModelState>Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case ModelAction.ADD_VERSION: {
      const id = action.payload.id;
      let relationships = state.relationships;
      if (relationships.has(id)) {
        let rel = relationships.get(id);
        rel = <ModelRelationships>rel.set('versions', rel.versions.add(action.payload.version.id));
        relationships = relationships.set(id, rel);
      }
      return <ModelState>Utils.updateState<ModelState>(state, state.entities, relationships, action, true);
    }

    default: {
      return state;
    }
  }
}

export function getFavorites() {
  return (state$: Store<ModelState>) => state$
    .select((s: ModelState) => s.favorites);
}
