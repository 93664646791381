import { IInstanceAttributes, IInstance } from '../connector';
import { Record, Set, Map } from 'immutable';
import { RecordFactory } from '../shared/record-factory';
import { DataModelState, RequestDiffRecord } from '../shared';

export const INSTANCE_TYPE_DEFAULT = 0;
export const INSTANCE_TYPE_LIBRARY = 1;

const instanceStateRecord = Record({
  ids: Set<string>(),
  entities: Map<string, Instance>(),
  relationships: Map<string, InstanceRelationships>(),
  diff: new RequestDiffRecord(),
  isBusy: 0
});

export class InstanceState extends instanceStateRecord implements DataModelState {
  ids: Set<string>;
  entities: Map<string, Instance>;
  relationships: Map<string, InstanceRelationships>;
  diff: RequestDiffRecord;
  isBusy: number;
}

const instanceRecord = Record(<IInstanceAttributes>{
  id: undefined,
  name: undefined,
  type: 0,
  relationships: undefined
});

export class Instance extends instanceRecord implements IInstanceAttributes {
  id: string;
  name: string;
  type: number;

  relationships: InstanceRelationships;

  static fromJSONAPI(instance: IInstance) {
    return RecordFactory.entity.fromJSONAPI<Instance>(Instance, instance);
  }
}

const instanceRelationshipsRecord = Record({
  businessareas: Set<string>(),
  humanresources: Set<string>(),
  activities: Set<string>(),
  groups: Set<string>()
});

export class InstanceRelationships extends instanceRelationshipsRecord {
  businessareas: Set<string>;
  humanresources: Set<string>;
  activities: Set<string>;
  groups: Set<string>;

  static fromJSONAPI(instance: IInstance) {
    return RecordFactory.relationships.fromJSONAPI<InstanceRelationships>(InstanceRelationships, instance);
  }
}
