import { IImportScheme, IImportSchemeAttributes, IImportSchemeRelationships } from '../connector';
import { Record, Set, Map } from 'immutable';
import { RecordFactory } from '../shared/record-factory';
import { RequestDiffRecord } from '../shared';

const importSchemeMappingRecord = Record({
  nodeField: undefined,
  csvHeader: '',
  dropdownOptions: undefined
});

export class ImportSchemeMapping extends importSchemeMappingRecord {
  nodeField: string;
  csvHeader: string;
  dropdownOptions: string;
}

const importschemeStateRecord = Record({
  ids: Set<string>(),
  entities: Map<string, ImportScheme>(),
  relationships: Map<string, ImportSchemeRelationships>(),
  isBusy: 0,
  diff: new RequestDiffRecord(),
});

export class ImportSchemeState extends importschemeStateRecord {
  ids: Set<string>;
  entities: Map<string, ImportScheme>;
  relationships: Map<string, ImportSchemeRelationships>;
  isBusy: number;
}

const importschemeRecord = Record(<IImportScheme>{
  id: undefined,
  type: 'importscheme',
  importName: '',
  businessarea_id: undefined,
  updatedAt: undefined,

  mappings: [],

  attributes: {},
  relationships: {}
});

export class ImportScheme extends importschemeRecord implements IImportScheme {
  id: string;
  type: string;
  importName: string;
  businessarea_id: number;
  updatedAt: number;

  mappings: ImportSchemeMapping[];

  attributes?: IImportSchemeAttributes;
  relationships?: IImportSchemeRelationships;

  static fromJSONAPI(importScheme: ImportScheme) {
    return RecordFactory.entity.fromJSONAPI<ImportScheme>(ImportScheme, importScheme);
  }
}

const importSchemeCreateRecord = Record({
  importName: ''
});

export class ImportSchemeCreate extends importSchemeCreateRecord {
  importName: string;
}

const importSchemeRelationshipsRecord = Record({});

export class ImportSchemeRelationships extends importSchemeRelationshipsRecord {
  static fromJSONAPI(importScheme: IImportScheme) {
    return RecordFactory.relationships.fromJSONAPI<ImportSchemeRelationships>(ImportSchemeRelationships, importScheme);
  }
}
