import { ISubset } from '../connector';

import { IPayload } from '../shared/common.models';
import { Action } from '../shared/common.action';

export class SubsetAction {

  static key = 'Subset';

  static LOAD = `[${SubsetAction.key}] Load`;
  static LOAD_SUCCESS = `[${SubsetAction.key}] Load success`;
  static LOAD_FAIL = `[${SubsetAction.key}] Load fail`;

  static CREATE = `[${SubsetAction.key}] Create`;
  static CREATE_SUCCESS = `[${SubsetAction.key}] Create success`;
  static CREATE_FAIL = `[${SubsetAction.key}] Create fail`;

  static UPDATE = `[${SubsetAction.key}] Update`;
  static UPDATE_SUCCESS = `[${SubsetAction.key}] Update success`;
  static UPDATE_FAIL = `[${SubsetAction.key}] Update fail`;

  static REMOVE = `[${SubsetAction.key}] Remove`;
  static REMOVE_SUCCESS = `[${SubsetAction.key}] Remove success`;
  static REMOVE_FAIL = `[${SubsetAction.key}] Remove fail`;

  public load(): Action {
    return {
      type: SubsetAction.LOAD,
    };
  }

  public loadSuccess(data: ISubset[]): Action {
    return {
      type: SubsetAction.LOAD_SUCCESS,
      payload: data
    };
  }

  public loadFail(error: any): Action {
    return {
      type: SubsetAction.LOAD_FAIL,
      payload: error
    };
  }

  public create(modelId: string, data: IPayload | IPayload[]): Action {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: SubsetAction.CREATE,
      payload: {
        modelId: modelId,
        data: data
      }
    };
  }

  public createSuccess(payload: IPayload, response: ISubset): Action {
    return {
      type: SubsetAction.CREATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public createFail(payload: IPayload, error: any): Action {
    return {
      type: SubsetAction.CREATE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public update(data: IPayload | IPayload[]): Action {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: SubsetAction.UPDATE,
      payload: data
    };
  }

  public updateSuccess(payload: IPayload, response: ISubset): Action {
    return {
      type: SubsetAction.UPDATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public updateFail(payload: IPayload, error: any): Action {
    return {
      type: SubsetAction.UPDATE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public remove(ids: string | string[]): Action {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }
    return {
      type: SubsetAction.REMOVE,
      payload: {
        data: ids
      }
    };
  }

  public removeSuccess(payload: IPayload, response: ISubset): Action {
    return {
      type: SubsetAction.REMOVE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public removeFail(payload: IPayload, error: any): Action {
    return {
      type: SubsetAction.REMOVE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public autorefreshUpdate(data: any): Action {
    return {
      type: SubsetAction.UPDATE_SUCCESS,
      payload: data
    };
  }

  public autorefreshCreate(data: any): Action {
    return {
      type: SubsetAction.CREATE_SUCCESS,
      payload: data
    };
  }

  public autorefreshRemove(data: any): Action {
    return {
      type: SubsetAction.REMOVE_SUCCESS,
      payload: data
    };
  }
}
