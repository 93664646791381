import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';

import { GroupAction } from './groups.actions';
import { Action } from '../shared/common.action';
import { JSONAPIResponse } from '../connector/jsonapi';
import { API } from '../connector/shared';
import { IGroup } from '../connector/models';

@Injectable()
export class GroupEffects {

  private concurrency = 5;

  @Effect() findGroupByInstnace$ = this.actions$
    .pipe(ofType(GroupAction.LOAD))
    .mergeMap((action: Action) => this.api.instance(action.payload.instanceId).groups.findAll()
      .mergeMap((response: JSONAPIResponse<IGroup[]>) => {
        return Observable.from([
          this.groupAction.loadSuccess(response.toPayload())
        ]);
      })
    ).catch((error: any) => Observable.of(this.groupAction.loadFail(error)));

  @Effect() createGroup$ = this.actions$
    .pipe(ofType(GroupAction.CREATE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.data.map(payload => {
      const data = payload.data.toJS();
      delete data.id;
      return this.api.instance(action.payload.instanceId).groups.create(data)
        .mergeMap(response => Observable.from([
          this.groupAction.createSuccess(payload, response.toPayload())
        ]))
        .catch((error: any) => Observable.of(this.groupAction.createFail(payload, error)));
    })))
    .mergeAll(this.concurrency);

  @Effect() removeGroup$ = this.actions$
    .pipe(ofType(GroupAction.REMOVE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.data.map(payload => this.api.groups.remove(payload)
      .mergeMap(response => Observable.from([
        this.groupAction.removeSuccess(payload, response.toPayload())
      ]))
      .catch((error: any) => Observable.of(this.groupAction.removeFail(payload, error)))
    )))
    .mergeAll(this.concurrency);

  @Effect() updateGroup$ = this.actions$
    .pipe(ofType(GroupAction.UPDATE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.map(payload => this.api.groups.update(payload.id, payload.data)
      .mergeMap(response => Observable.from([
        this.groupAction.updateSuccess(payload, response.toPayload())
      ]))
      .catch((error: any) => Observable.of(this.groupAction.updateFail(payload, error)))
    )))
    .mergeAll(this.concurrency);

  @Effect() assignGroup$ = this.actions$
    .pipe(ofType(GroupAction.ASSIGN))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.map(payload => this.api.groups.assign(payload.id, payload.data)
      .mergeMap(response => Observable.from([
        this.groupAction.assignSuccess(payload, response.toPayload())
      ]))
      .catch((error: any) => Observable.of(this.groupAction.assignFail(payload, error)))
    )))
    .mergeAll(this.concurrency);

  @Effect() unassignGroup$ = this.actions$
    .pipe(ofType(GroupAction.UNASSIGN))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.map(payload => this.api.groups.unassign(payload.id, payload.data)
      .mergeMap(response => Observable.from([
        this.groupAction.unassignSuccess(payload, response.toPayload())
      ]))
      .catch((error: any) => Observable.of(this.groupAction.unassignFail(payload, error)))
    )))
    .mergeAll(this.concurrency);


  constructor(private api: API,
              private actions$: Actions,
              private groupAction: GroupAction) {
  }
}
