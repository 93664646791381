export const TemplateFieldsReportsKpi = [
  {
    type: 'table',
    settings: {
      /* Group the elements by model */
      groupByModels: false,
      /* Show top most header row (grouped heading) */
      showHeading: false,
      /* Show the footer */
      showFooter: true,
      /* The header text color */
      headerTextColor: '#000000',
      /* The header background color */
      headerBackgroundColor: '#ffffff',
      /* Show table fields select box */
      showFieldsSelectBox: false,
      /* Use a thousand seperator for numeric values */
      thousandsSeperator: '´',
      /* A filter function to filter by */
      filter: 'kpi',
      /* Default wizard flow */
      wizardFlow: 'add.node.level',
      /* Add button label */
      wizardButtonLabel: 'REPORTS.KPI.ADD',
      /* Add button level */
      wizardVariables: { 'select.level': [{ id: 3, answer: '' }] },
      /* Is Row Span being used */
      maxRowSpanUsed: 3,
      /* Exports */
      exports: [],
      /* Break the table vertically among pages */
      verticalPageBreak: true,
    },
    configuration: {
      fields: [
        { entry: { key: 'wizard', label: '', controlType: 'wizard', source: 'delete', icon: 'times', editable: true, fixedWidth: 30, rowspan: 4, wizardColor: '#ffffff', wizardBackground: '#d9534f', nodeSource: 'measurement', border: '0' }},
        { entry: { key: 'A', label: '', controlType: 'group', border: '0'}, children: [
          { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'REPORTS.KPI.NAME', required: true, pdfWidth: 50, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME', rowspan: 3, styles: ['border-bottom: 1px solid #e1e1e1'] }},
          { entry: { key: 'description', controlType: 'text', label: 'REPORTS.KPI.DESCRIPTION', pdfWidth: 100, rowspan: 3, styles: ['border-bottom: 1px solid #e1e1e1'] }},
          { entry: { key: 'massnahme', controlType: 'dropdown', label: 'REPORTS.KPI.MASSNAHME', calculated: true, pdfWidth: 70, rowspan: 3, hasNull: true, source: 'massnahme', styles: ['border-bottom: 1px solid #e1e1e1'] }}
        ]},
        { entry: { key: '', label: '', controlType: 'group', border: '0'}, children: [
          { entry: { key: 'string', label: '', controlType: 'string', editable: false, rowspan: 3, pdfWidth: 10, calculated: true, styles: ['border-top: 0']}}
        ]},
        { entry: { key: 'D', label: '', controlType: 'group', border: '0' }, children: [
          { entry: { key: 'budget#beschrieb', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.BESCHRIEB', pdfWidth: 70, rowspan: 3, styles: ['border-bottom: 1px solid #e1e1e1'] }},
          { entry: { key: 'riskProfileCountermeasures', type: 'text', controlType: 'textbox', pdfWidth: 70, label: 'REPORTS.KPI.EINHEIT', rowspan: 3, styles: ['border-bottom: 1px solid #e1e1e1'] }}
        ]},
        { entry: { key: '', label: '', controlType: 'group', border: '0'}, children: [
          { entry: { key: 'string', label: '', controlType: 'string', editable: false, rowspan: 3, pdfWidth: 10, fixedWidth: 10, calculated: true, styles: ['border-top: 0']}}
        ]},
        { entry: { key: '', label: 'REPORTS.KPI.SAVINGS', params: 'Y-2', controlType: 'group', colspan: 32, border: '0', background: '#d3e0b4' }, children: [
          { entry: { key: '', controlType: 'group', label: 'REPORTS.KPI.EINZELJAHR', params: 'Y-1', background: '#c5b8d5', colspan: 10, pdfWidth: 60 }, children: [
            { entry: { key: '', controlType: 'group', label: 'REPORTS.KPI.Q1', pdfWidth: 60, background: '#bdd4ef' }, children: [
              { entry: { key: 'budgetPlan#yq1', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.ZIEL', pdfWidth: 60, nodeBucket: true, nodeBucketId: 'Q1-Y-1', styles: ['border-bottom: 1px solid #e1e1e1'] }},
              { entry: { key: 'budget#yq1', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.ISTPROG', pdfWidth: 60, nodeBucket: true, nodeBucketId: 'Q1-Y-1', styles: ['border-bottom: 1px solid #e1e1e1'] }},
            ]},
            { entry: { key: '', controlType: 'group', label: 'REPORTS.KPI.Q2', pdfWidth: 60, background: '#bdd4ef' }, children: [
              { entry: { key: 'budgetPlan#yq2', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.ZIEL', pdfWidth: 60, nodeBucket: true, nodeBucketId: 'Q2-Y-1', styles: ['border-bottom: 1px solid #e1e1e1'] }},
              { entry: { key: 'budget#yq2', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.ISTPROG', pdfWidth: 60, nodeBucket: true, nodeBucketId: 'Q2-Y-1', styles: ['border-bottom: 1px solid #e1e1e1'] }},
            ]},
            { entry: { key: '', controlType: 'group', label: 'REPORTS.KPI.Q3', pdfWidth: 60, background: '#bdd4ef' }, children: [
              { entry: { key: 'budgetPlan#yq3', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.ZIEL', pdfWidth: 60, nodeBucket: true, nodeBucketId: 'Q3-Y-1', styles: ['border-bottom: 1px solid #e1e1e1'] }},
              { entry: { key: 'budget#yq3', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.ISTPROG', pdfWidth: 60, nodeBucket: true, nodeBucketId: 'Q3-Y-1', styles: ['border-bottom: 1px solid #e1e1e1'] }},
            ]},
            { entry: { key: '', controlType: 'group', label: 'REPORTS.KPI.Q4', pdfWidth: 60, background: '#bdd4ef' }, children: [
              { entry: { key: 'budgetPlan#yq4', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.ZIEL', pdfWidth: 60, nodeBucket: true, nodeBucketId: 'Q4-Y-1', styles: ['border-bottom: 1px solid #e1e1e1'] }},
              { entry: { key: 'budget#yq4', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.ISTPROG', pdfWidth: 60, nodeBucket: true, nodeBucketId: 'Q4-Y-1', styles: ['border-bottom: 1px solid #e1e1e1'] }},
            ]},
            { entry: { key: '', controlType: 'group', label: 'REPORTS.KPI.KUMULIERT', pdfWidth: 60, background: '#fccfab' }, children: [
              { entry: { key: 'benefitBudget#y', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.ZIEL', pdfWidth: 60, nodeBucketId: 'Y-1', nodeBucket: true, editable: true, styles: ['border-bottom: 1px solid #e1e1e1'] }},
              { entry: { key: 'benefitActual#y', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.ISTPROG', pdfWidth: 60, nodeBucketId: 'Y-1', nodeBucket: true, editable: true, styles: ['border-bottom: 1px solid #e1e1e1']  }}
            ]}
          ]},
          { entry: { key: '', label: '', controlType: '', editable: false, pdfWidth: 10, rowspan: 3, fixedWidth: 10 }},
          { entry: { key: '', controlType: 'group', label: 'REPORTS.KPI.EINZELJAHR', params: 'Y', background: '#c5b8d5', pdfWidth: 60, colspan: 10 }, children: [
            { entry: { key: '', controlType: 'group', label: 'REPORTS.KPI.Q1', pdfWidth: 60, background: '#bdd4ef' }, children: [
              { entry: { key: 'budgetPlan#y1q1', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.ZIEL', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q1-Y', styles: ['border-bottom: 1px solid #e1e1e1'] }},
              { entry: { key: 'budget#y1q1', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.ISTPROG', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q1-Y', styles: ['border-bottom: 1px solid #e1e1e1'] }},
            ]},
            { entry: { key: '', controlType: 'group', label: 'REPORTS.KPI.Q2', pdfWidth: 60, background: '#bdd4ef' }, children: [
              { entry: { key: 'budgetPlan#y1q2', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.ZIEL', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q2-Y', styles: ['border-bottom: 1px solid #e1e1e1'] }},
              { entry: { key: 'budget#y1q2', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.ISTPROG', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q2-Y', styles: ['border-bottom: 1px solid #e1e1e1'] }},
            ]},
            { entry: { key: '', controlType: 'group', label: 'REPORTS.KPI.Q3', pdfWidth: 60, background: '#bdd4ef' }, children: [
              { entry: { key: 'budgetPlan#y1q3', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.ZIEL', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q3-Y', styles: ['border-bottom: 1px solid #e1e1e1'] }},
              { entry: { key: 'budget#y1q3', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.ISTPROG', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q3-Y', styles: ['border-bottom: 1px solid #e1e1e1'] }},
            ]},
            { entry: { key: '', controlType: 'group', label: 'REPORTS.KPI.Q4', pdfWidth: 60, background: '#bdd4ef' }, children: [
              { entry: { key: 'budgetPlan#y1q4', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.ZIEL', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q4-Y', styles: ['border-bottom: 1px solid #e1e1e1'] }},
              { entry: { key: 'budget#y1q4', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.ISTPROG', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q4-Y', styles: ['border-bottom: 1px solid #e1e1e1'] }},
            ]},
            { entry: { key: '', controlType: 'group', label: 'REPORTS.KPI.KUMULIERT', pdfWidth: 60, background: '#fccfab' }, children: [
              { entry: { key: 'benefitBudget#y1', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.ZIEL', nodeBucketId: 'Y', nodeBucket: true, calculated: false, pdfWidth: 60, editable: true, styles: ['border-bottom: 1px solid #e1e1e1'] }},
              { entry: { key: 'benefitActual#y1', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.ISTPROG', nodeBucketId: 'Y', nodeBucket: true, calculated: false, pdfWidth: 60, editable: true, styles: ['border-bottom: 1px solid #e1e1e1']  }}
            ]}
          ]},
          { entry: { key: '', label: '', controlType: '', editable: false, pdfWidth: 10, rowspan: 3, fixedWidth: 10 }},
          { entry: { key: '', controlType: 'group', label: 'REPORTS.KPI.EINZELJAHR', params: 'Y+1', background: '#c5b8d5', pdfWidth: 60, colspan: 10 }, children: [
            { entry: { key: '', controlType: 'group', label: 'REPORTS.KPI.Q1', pdfWidth: 60, background: '#bdd4ef' }, children: [
              { entry: { key: 'budgetPlan#y2q1', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.ZIEL', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q1-Y+1', styles: ['border-bottom: 1px solid #e1e1e1'] }},
              { entry: { key: 'budget#y2q1', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.ISTPROG', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q1-Y+1', styles: ['border-bottom: 1px solid #e1e1e1'] }},
            ]},
            { entry: { key: '', controlType: 'group', label: 'REPORTS.KPI.Q2', pdfWidth: 60, background: '#bdd4ef' }, children: [
              { entry: { key: 'budgetPlan#y2q2', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.ZIEL', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q2-Y+1', styles: ['border-bottom: 1px solid #e1e1e1'] }},
              { entry: { key: 'budget#y2q2', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.ISTPROG', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q2-Y+1', styles: ['border-bottom: 1px solid #e1e1e1'] }},
            ]},
            { entry: { key: '', controlType: 'group', label: 'REPORTS.KPI.Q3', pdfWidth: 60, background: '#bdd4ef' }, children: [
              { entry: { key: 'budgetPlan#y2q3', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.ZIEL', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q3-Y+1', styles: ['border-bottom: 1px solid #e1e1e1'] }},
              { entry: { key: 'budget#y2q3', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.ISTPROG', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q3-Y+1', styles: ['border-bottom: 1px solid #e1e1e1'] }},
            ]},
            { entry: { key: '', controlType: 'group', label: 'REPORTS.KPI.Q4', pdfWidth: 60, background: '#bdd4ef' }, children: [
              { entry: { key: 'budgetPlan#y2q4', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.ZIEL', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q4-Y+1', styles: ['border-bottom: 1px solid #e1e1e1'] }},
              { entry: { key: 'budget#y2q4', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.ISTPROG', nodeBucket: true, pdfWidth: 60, nodeBucketId: 'Q4-Y+1', styles: ['border-bottom: 1px solid #e1e1e1'] }},
            ]},
            { entry: { key: '', controlType: 'group', label: 'REPORTS.KPI.KUMULIERT', pdfWidth: 60, background: '#fccfab' }, children: [
              { entry: { key: 'benefitBudget#y2', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.ZIEL', nodeBucketId: 'Y+1', nodeBucket: true, pdfWidth: 60, calculated: false, editable: true, styles: ['border-bottom: 1px solid #e1e1e1'] }},
              { entry: { key: 'benefitActual#y2', type: 'number', controlType: 'textbox', label: 'REPORTS.KPI.ISTPROG', nodeBucketId: 'Y+1', nodeBucket: true, pdfWidth: 60, calculated: false, editable: true, styles: ['border-bottom: 1px solid #e1e1e1']  }}
            ]}
          ]},
        ]}
      ]
    }
  }
];
