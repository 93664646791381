import { BehaviorSubject } from 'rxjs';
import { OrderedMap, Map, List, Set } from 'immutable';
import { isNullOrUndefined } from 'util';

import { TreeNode } from '../../../working/shared/tree/tree.node';
import { TreeRelationship } from '../../../working/shared/tree/tree.relationship';
import { Relationship } from '../../../shared/api/relationships';
import { Node } from '../../../shared/api/nodes';
import { RELATIONSHIP_TYPE_DEFAULT } from '../../../shared/api/relationships/relationships.models';

export class TreeService {

  /* Output Emitters */
  public nodesEmitter = new BehaviorSubject<OrderedMap<string, TreeNode>>(OrderedMap<string, TreeNode>());
  public relationshipsEmitter = new BehaviorSubject<Map<string, TreeRelationship>>(Map<string, TreeRelationship>());

  /* Output objects */
  public nodes = OrderedMap<string, TreeNode>();
  public relationships = Map<string, TreeRelationship>();

  public _subLevelMap = Map<string, number>();

  public build(nodes = List<Node>(), relationships = List<Relationship>()): TreeService {

    /* Results */
    let treeNodes = OrderedMap<string, TreeNode>();
    let treeRelationships = Map<string, TreeRelationship>();

    /* Nodes */
    nodes.forEach((node: Node) => {
      treeNodes = treeNodes.set(node.id, new TreeNode(node));
    });

    /* Relationships */
    relationships.forEach(relationship => {
      treeRelationships = treeRelationships.set(relationship.id, new TreeRelationship(relationship));
    });

    /* Set the values */
    this.relationships = treeRelationships;
    this.nodes = this.setHierarchy(treeNodes, treeRelationships);

      /* Update */
    this.relationshipsEmitter.next(this.relationships);
    this.nodesEmitter.next(this.nodes);

    /* Return itself */
    return this;
  }

  protected setHierarchy(treeNodes: OrderedMap<string, TreeNode>, treeRelationships: Map<string, TreeRelationship>) {
    this._subLevelMap = this._subLevelMap.clear();
    let parentChildMap = Map<string, Map<string, string>>();
    let childParentMap = Map<string, Map<string, string>>();
    treeRelationships.filter(treeRelationship => treeRelationship.relationship.type === RELATIONSHIP_TYPE_DEFAULT).forEach(treeRelationship => {
      const parentId = '' + treeRelationship.parent;
      const childId = '' + treeRelationship.child;
      const parent = parentChildMap.has(parentId) ? parentChildMap.get(parentId) : Map<string, string>();
      parentChildMap = parentChildMap.set(parentId, parent.set(childId, childId));
      const child = childParentMap.has(childId) ? childParentMap.get(childId) : Map<string, string>();
      childParentMap = childParentMap.set(childId, child.set(parentId, parentId));
    });

    treeNodes.filter(node => !childParentMap.has(node.id)).forEach(node => {
      treeNodes = this.setChildren(node, treeNodes, parentChildMap).nodes;
    });
    return treeNodes;
  }

  protected setChildren(treeNode: TreeNode, nodes: OrderedMap<string, TreeNode>, parentChildMap: Map<string, OrderedMap<string, string>>, parentNode?: TreeNode, ids = Set<string>()): { nodes: OrderedMap<string, TreeNode>, treeNode: TreeNode } {
    if (!isNullOrUndefined(parentNode)) {
      treeNode = <TreeNode> treeNode.set('parents', treeNode.parents.set(parentNode.id, parentNode));
    }
    let subLevel = 0;
    if (!isNullOrUndefined(parentNode)) {
      let _subLevel = parentNode.subLevel + 1;
      const storedSubLevel = this._subLevelMap.get(treeNode.id);
      if (!isNullOrUndefined(storedSubLevel) && storedSubLevel > _subLevel) {
        _subLevel = storedSubLevel;
      }
      subLevel = _subLevel;
    }
    this._subLevelMap = this._subLevelMap.set(treeNode.id, subLevel);
    if (!treeNode.phantom) {
      treeNode = <TreeNode> treeNode.set('subLevel', subLevel);
    }
    if (parentChildMap.has(treeNode.id)) {
      treeNode = <TreeNode> treeNode.set('children', parentChildMap.get(treeNode.id).filter(id => nodes.has(id) && !ids.has(id)).map(id => {
        ids.add(treeNode.id);
        const data = this.setChildren(nodes.get(id), nodes, parentChildMap, treeNode, ids);
        nodes = data.nodes;
        return data.treeNode;
      }));
    }

    nodes = nodes.set(treeNode.id, treeNode);
    return { nodes: nodes, treeNode: treeNode };
  }

}
