import { Inject, forwardRef, ErrorHandler } from '@angular/core';
import { isNullOrUndefined } from 'util';

import { ActivityService } from './shared/api/activities/activities.service';
import { BusinessareaService } from './shared/api/businessareas/businessareas.service';
import { HumanResourceService } from './shared/api/humanresources/humanresources.service';
import { ImportSchemeService } from './shared/api/importschemes/importschemes.service';
import { InstanceService } from './shared/api/instances/instances.service';
import { ModelService } from './shared/api/models/models.service';
import { NodeDataService } from './shared/api/nodedata/nodedata.service';
import { NodeStructureService } from './shared/api/nodestructures/nodestructures.service';
import { RelationshipService } from './shared/api/relationships/relationships.service';
import { SubsetService } from './shared/api/subsets/subsets.service';
import { RequestDiffRecord } from './shared/api/shared/common.models';
import { ActivityAction } from './shared/api/activities/activities.actions';
import { BusinessareaAction } from './shared/api/businessareas/businessareas.actions';
import { HumanResourceAction } from './shared/api/humanresources/humanresources.actions';
import { ImportSchemeAction } from './shared/api/importschemes/importschemes.actions';
import { InstanceAction } from './shared/api/instances/instances.actions';
import { ModelAction } from './shared/api/models/models.actions';
import { NodeDataAction } from './shared/api/nodedata/nodedata.actions';
import { NodeStructureAction } from './shared/api/nodestructures/nodestructures.actions';
import { RelationshipAction } from './shared/api/relationships/relationships.actions';
import { SubsetAction } from './shared/api/subsets/subsets.actions';
import { environment } from '../environments/environment';
import { UserAction, UserService } from './shared/api/user';
import { NotifyService } from './shared/notifications/notification.service';
import { AuditAction } from './shared/api/audits';
import { GroupAction } from './shared/api/groups';
import { HierarchyAction } from './shared/api/hierarchy';
import { NotificationAction } from './shared/api/notifications';
import { VersionAction } from './shared/api/versions';
import { EmailAction } from './shared/api/email';

export class ErrorReporter {

  public constructor(
    @Inject(forwardRef(() => ActivityService)) private activityService: ActivityService,
    @Inject(forwardRef(() => BusinessareaService)) private businessareaService: BusinessareaService,
    @Inject(forwardRef(() => HumanResourceService)) private humanresourceService: HumanResourceService,
    @Inject(forwardRef(() => ImportSchemeService)) private importschemeService: ImportSchemeService,
    @Inject(forwardRef(() => InstanceService)) private instanceService: InstanceService,
    @Inject(forwardRef(() => ModelService)) private modelService: ModelService,
    @Inject(forwardRef(() => NodeDataService)) private nodedataService: NodeDataService,
    @Inject(forwardRef(() => NodeStructureService)) private nodestructureService: NodeStructureService,
    @Inject(forwardRef(() => RelationshipService)) private relationshipService: RelationshipService,
    @Inject(forwardRef(() => SubsetService)) private subsetService: SubsetService,
    @Inject(forwardRef(() => SubsetService)) private userService: UserService,
    private notify: NotifyService
  ) {

    activityService.diff.subscribe(diff => this.onDiff(diff));
    businessareaService.diff.subscribe(diff => this.onDiff(diff));
    humanresourceService.diff.subscribe(diff => this.onDiff(diff));
    importschemeService.diff.subscribe(diff => this.onDiff(diff));
    instanceService.diff.subscribe(diff => this.onDiff(diff));
    modelService.diff.subscribe(diff => this.onDiff(diff));
    nodedataService.diff.subscribe(diff => this.onDiff(diff));
    nodestructureService.diff.subscribe(diff => this.onDiff(diff));
    relationshipService.diff.subscribe(diff => this.onDiff(diff));
    subsetService.diff.subscribe(diff => this.onDiff(diff));
    userService.diff.subscribe(diff => this.onDiff(diff));

  }

  public handleError(error: any) {
    this.storeError(error);
    if (environment.debug) {
      this.printError(error);
    }
    if (!isNullOrUndefined(error.message)) {
      this.showError(500, error.message, error);
    } else {
      this.showError(500, undefined, error);
    }
  }

  private storeError(error: any) {}

  private printError(error: any) {
    new ErrorHandler().handleError(error);
    console.error(error.originalStack);
  }

  private showError(status_code: number, message?: string, error?: any) {
    let status = status_code;
    if (status_code !== 401 && status_code !== 403 && status_code !== 404) {
      status = 500;
    }
    this.notify.error('GENERAL.ERROR.' + status + '.TITLE', (!isNullOrUndefined(message) ? message : 'GENERAL.ERROR.' + status + '.MESSAGE'), !!error ? error.stack : '');
  }

  private onDiff(diff: RequestDiffRecord) {

    switch (diff.action) {

      case ActivityAction.CREATE_FAIL:
      case ActivityAction.UPDATE_FAIL:
      case ActivityAction.REMOVE_FAIL:
      case ActivityAction.LOAD_FAIL:

      case AuditAction.LOAD_FAIL:

      case BusinessareaAction.CREATE_FAIL:
      case BusinessareaAction.UPDATE_FAIL:
      case BusinessareaAction.REMOVE_FAIL:
      case BusinessareaAction.LOAD_FAIL:

      case GroupAction.CREATE_FAIL:
      case GroupAction.UPDATE_FAIL:
      case GroupAction.REMOVE_FAIL:
      case GroupAction.LOAD_FAIL:

      case HierarchyAction.LOAD_FAIL:

      case HumanResourceAction.CREATE_FAIL:
      case HumanResourceAction.UPDATE_FAIL:
      case HumanResourceAction.REMOVE_FAIL:
      // case HumanResourceAction.LOAD_FAIL:

      case ImportSchemeAction.CREATE_FAIL:
      case ImportSchemeAction.UPDATE_FAIL:
      case ImportSchemeAction.REMOVE_FAIL:
      case ImportSchemeAction.LOAD_FAIL:

      case InstanceAction.CREATE_FAIL:
      case InstanceAction.UPDATE_FAIL:
      case InstanceAction.REMOVE_FAIL:
      case InstanceAction.LOAD_FAIL:

      case ModelAction.CREATE_FAIL:
      case ModelAction.UPDATE_FAIL:
      case ModelAction.REMOVE_FAIL:
      case ModelAction.DUPLICATE_FAIL:
      case ModelAction.LOAD_FAIL:

      case NodeDataAction.UPDATE_FAIL:
      case NodeDataAction.LOAD_FAIL:
      case NodeDataAction.LOAD_AUDITS_FAIL:

      case NodeStructureAction.CREATE_FAIL:
      case NodeStructureAction.UPDATE_FAIL:
      case NodeStructureAction.REMOVE_FAIL:
      case NodeStructureAction.LOAD_FAIL:

      case NotificationAction.LOAD_FAIL:
      case NotificationAction.REMOVE_FAIL:
      case NotificationAction.ROLLBACK_FAIL:
      case NotificationAction.UPDATE_FAIL:

      case RelationshipAction.CREATE_FAIL:
      case RelationshipAction.UPDATE_FAIL:
      case RelationshipAction.REMOVE_FAIL:
      case RelationshipAction.LOAD_FAIL:

      case SubsetAction.CREATE_FAIL:
      case SubsetAction.UPDATE_FAIL:
      case SubsetAction.REMOVE_FAIL:
      case SubsetAction.LOAD_FAIL:

      case UserAction.LOAD_FAIL:

      case VersionAction.CREATE_FAIL:
      case VersionAction.REMOVE_FAIL:
      case VersionAction.LOAD_FAIL:

      case EmailAction.SEND_EMAIL_FAIL:

        const message = isNullOrUndefined(diff.response) ? '' : (diff.response.error ? diff.response.error.message : diff.response.message);
        const status = isNullOrUndefined(diff.response) ? '500' : diff.response.status;
        this.showError(status, environment.debug ? message : undefined);
        break;

    }

  }

}
