import { List } from 'immutable';

import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { LegendItem } from '../../../../shared/legend/legend.item';

export class ColorLabelProviderServiceStatus extends ColorLabelProviderAbstract {

  private static PENDING = '#999999';
  private static INDEFINE = '#000000';
  private static INDEVELOP = '#1099D6';
  private static INVERIFY = '#AA008F';
  private static INACCEPT = '#EDB922';
  private static COMPLETED = '#9ac93e';

  public color(element: any) {

    switch (element.status) {
      case 0:
        return [ColorLabelProviderServiceStatus.PENDING];
      case 100:
        return [ColorLabelProviderServiceStatus.INDEFINE];
      case 110:
        return [ColorLabelProviderServiceStatus.INDEVELOP];
      case 120:
        return [ColorLabelProviderServiceStatus.INVERIFY];
      case 130:
        return [ColorLabelProviderServiceStatus.INACCEPT];
      case 200:
        return [ColorLabelProviderServiceStatus.COMPLETED];
      default:
        return super.color();
    }

  }

  public label(element: any, value: any) {
    const pre = this.translate('RADIAL.DEFAULT.DISPLAY.LABEL.TOP.STATUS') + ': ';
    switch (element.status) {
      case 0:
        return pre + this.translate('STATUS.PENDING');
      case 100:
        return pre + this.translate('STATUS.INDEFINE');
      case 110:
        return pre + this.translate('STATUS.INDEVELOP');
      case 120:
        return pre + this.translate('STATUS.INVERIFY');
      case 130:
        return pre + this.translate('STATUS.INACCEPT');
      case 200:
        return pre + this.translate('STATUS.COMPLETED');
      default:
        return super.label();
    }
  }

  public legend() {
    return List<LegendItem>([
      new LegendItem({ key: 'status.pending', color: ColorLabelProviderServiceStatus.PENDING, label: 'STATUS.PENDING', field: 'status', value: 0 }),
      new LegendItem({ key: 'status.indefine', color: ColorLabelProviderServiceStatus.INDEFINE, label: 'STATUS.INDEFINE', field: 'status', value: 100 }),
      new LegendItem({ key: 'status.indevelop', color: ColorLabelProviderServiceStatus.INDEVELOP, label: 'STATUS.INDEVELOP', field: 'status', value: 110 }),
      new LegendItem({ key: 'status.inverify', color: ColorLabelProviderServiceStatus.INVERIFY, label: 'STATUS.INVERIFY', field: 'status', value: 120 }),
      new LegendItem({ key: 'status.inaccept', color: ColorLabelProviderServiceStatus.INACCEPT, label: 'STATUS.INACCEPT', field: 'status', value: 130 }),
      new LegendItem({ key: 'status.completed', color: ColorLabelProviderServiceStatus.COMPLETED, label: 'STATUS.COMPLETED', field: 'status', value: 200 })
    ]);
  }

}
