import { Map } from 'immutable';
import * as d3 from 'd3';

import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { LegendItem } from '../../../../shared/legend/legend.item';
import { Node } from '../../../../shared/api/nodes';
import { NODES_TYPE_CLIENT, NODES_TYPE_COMPETITOR, NODES_TYPE_IDEA, NODES_TYPE_IPCCLASS, NODES_TYPE_LIBRARY, NODES_TYPE_MARKET, NODES_TYPE_MICROTREND, NODES_TYPE_PATENT } from '../../../../shared/api/nodes/nodes.models';

export class ColorLabelProviderNodeTypes extends ColorLabelProviderAbstract {

  protected params = Map<number, any>();

  constructor(params: any[]) {
    super();
    params.forEach((param, i) => {
      switch (param.key) {
        case NODES_TYPE_IPCCLASS:
          param.color = '#ffe699';
          break;
        case NODES_TYPE_PATENT:
          param.color = '#bf9000';
          break;
        case NODES_TYPE_CLIENT:
          param.color = '#000000';
          break;
        case NODES_TYPE_COMPETITOR:
          param.color = '#c00000';
          break;
        case NODES_TYPE_LIBRARY:
          param.color = '#ff6e00';
          break;
        case NODES_TYPE_MARKET:
          param.color = '#0041ff';
          break;
        case NODES_TYPE_IDEA:
          param.color = '#B906D6';
          break;
        case NODES_TYPE_MICROTREND:
          param.color = '#31D6A5';
          break;
        default:
          param.color = '#999999';
      }
      this.params = this.params.set(param.key, param);
    });
  }

  public color(element: Node) {
    if (this.params.has(element.nodeType)) {
      return [this.params.get(element.nodeType).color];
    } else {
      return super.color();
    }
  }

  public legend() {
    return this.params.map(param => new LegendItem({ key: 'nodetype.' + param.key, color: param.color, label: param.label, field: 'nodeType', value: param.key })).toList();
  }

}
