import { Record } from 'immutable';

const legendItemRecord = Record({
  key: undefined,
  color: undefined,
  label: undefined,
  field: undefined,
  value: undefined
});

export class LegendItem extends legendItemRecord {
  key: string;
  color: string;
  label: string;
  field: string;
  value: any;
}
