export const TemplateFieldsReportsObjectiveBusinessareaMeasures = [
  {
    type: 'table',
    settings: {
      /* Virtual scrolling */
      virtualScrolling: true,
      /* Group the elements by model */
      groupByModels: true,
      /* Show the footer */
      showFooter: true,
      /* Use a thousand seperator for numeric values */
      thousandsSeperator: '´',
      /* A filter function to filter by */
      filter: 'objectivesnooverview',
      /* Show legend dropup */
      showLegend: true,
      /* Exports */
      exports: ['excel', 'pdf'],
      /* Trigger to load the Businessarea with that table */
      loadBusinessarea: true,
      /* Break the table vertically among pages */
      verticalPageBreak: false
    },
    configuration: {
      fields: [
        { entry: { key: '', label: '', controlType: 'group'}, children: [
            { entry: { key: 'measures', controlType: 'measures', label: 'REPORTS.OBJECTIVEBUSINESSAREAMEASURES.FORMAT', fixedWidth: 70 }},
            { entry: { key: 'number', controlType: 'primary', label: 'REPORTS.OBJECTIVEBUSINESSAREAMEASURES.NUMBER', calculated: true, pdfWidth: 30, editable: false }},
            { entry: { key: 'stategicClassification', controlType: 'textbox', label: 'REPORTS.OBJECTIVEBUSINESSAREAMEASURES.STRATEGICCLASSIFICATION', editable: false, pdfWidth: 100, calculated: true }},
            { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'REPORTS.OBJECTIVEBUSINESSAREAMEASURES.NAME', required: true, pdfWidth: 100, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME' }},
            { entry: { key: 'description2', controlType: 'text', pdfWidth: 75, label: 'REPORTS.OBJECTIVEBUSINESSAREAMEASURES.DESCRIPTION' }},
            { entry: { key: 'riskProfileCategory', controlType: 'dropdown', pdfWidth: 50,  label: 'REPORTS.OBJECTIVEBUSINESSAREAMEASURES.RISKPROFILECATEGORY.TITLE', options: [
                  { key: 0, value: 'REPORTS.OBJECTIVEBUSINESSAREAMEASURES.RISKPROFILECATEGORY.NOTAPPLICABLE' },
                  { key: 1, value: 'REPORTS.OBJECTIVEBUSINESSAREAMEASURES.RISKPROFILECATEGORY.GMAA' },
                  { key: 2, value: 'REPORTS.OBJECTIVEBUSINESSAREAMEASURES.RISKPROFILECATEGORY.CUSTOM' }
                ]}},
            { entry: { key: 'technicalDescription', controlType: 'text', pdfWidth: 75, label: 'REPORTS.OBJECTIVEBUSINESSAREAMEASURES.TECHNICALDESCRIPTION' }},
            { entry: { key: 'executive', controlType: 'dropdown', label: 'Q1', addNull: false, pdfWidth: 30, options: [
                  { key: 0, value: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE', color: '#999999' },
                  { key: 1, value: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW', color: '#9ac93e', shape: 'circle' },
                  { key: 2, value: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM', color: '#EDD515', shape: 'triangle' },
                  { key: 3, value: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH', color: '#E50000', shape: 'rect' }
                ] }},
            { entry: { key: 'program', controlType: 'dropdown', label: 'Q2', addNull: false, pdfWidth: 30, options: [
                  { key: 0, value: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE', color: '#999999' },
                  { key: 1, value: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW', color: '#9ac93e', shape: 'circle' },
                  { key: 2, value: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM', color: '#EDD515', shape: 'triangle' },
                  { key: 3, value: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH', color: '#E50000', shape: 'rect' }
                ] }},
            { entry: { key: 'functional', controlType: 'dropdown', label: 'Q3', addNull: false, pdfWidth: 30, options: [
                  { key: 0, value: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE', color: '#999999' },
                  { key: 1, value: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW', color: '#9ac93e', shape: 'circle' },
                  { key: 2, value: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM', color: '#EDD515', shape: 'triangle' },
                  { key: 3, value: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH', color: '#E50000', shape: 'rect' }
                ] }},
            { entry: { key: 'resource', controlType: 'dropdown', label: 'Q4', addNull: false, pdfWidth: 30, options: [
                  { key: 0, value: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE', color: '#999999' },
                  { key: 1, value: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW', color: '#9ac93e', shape: 'circle' },
                  { key: 2, value: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM', color: '#EDD515', shape: 'triangle' },
                  { key: 3, value: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH', color: '#E50000', shape: 'rect' }
                ] }},
            { entry: { key: 'lastStatus', controlType: 'text', calculated: true, minWidth: 300, pdfWidth: 150, label: 'REPORTS.OBJECTIVEBUSINESSAREAMEASURES.LASTSTATUS'}}
          ]}
      ]
    }
  }
];
