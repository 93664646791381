export const TemplateFieldsWizard = {
  'add': [
    { key: 'add.node', type: 'flow' }
  ],
  'delete': [
    { key: 'delete', type: 'question', question: 'WIZARD.DELETE.QUESTION', params: { name: 'name' }, answers: [
      { id: 'yes', answer: 'GENERAL.YES' },
      { id: 'no', answer: 'GENERAL.NO' }
    ]},
    /* Delete the node */
    { key: 'delete.node', type: 'delete', source: 'node', active: true, close: true, if: [{ key: 'delete', id: 'id', value: 'yes' }]}
  ],
  'duplicate': [
    /* Duplicate the node */
    { key: 'duplicate.node', type: 'duplicate', source: 'node', active: true, close: true }
  ],
  'add.node.level': [
    /* Set the name of the new node */
    { key: 'add.name', type: 'form', entry: {
      tabs: [{ entry: { key: 'header1', label: '' }, children: [
        { entry: { key: 'name', type: 'text', controlType: 'textbox', label: '[[ select.level ]]', maxLength: 255, validations: [ 'required' ], validationMessages: { required: 'DATASHEET.VALIDATION.REQUIRED.NAME' } }}
      ]}]
    }},
    { key: 'create', type: 'create', active: true, text: 'WIZARD.DEFAULT.CREATE.ACTIVE', done: 'WIZARD.DEFAULT.CREATE.DONE', ifOr: [{ key: 'select.parent', isNull: true }, { key: 'select.parent', id: 'id', isUndefined: false }]}
  ],
  'add.node': [
    /* Select level */
    { key: 'select.level', type: 'question', question: 'WIZARD.DEFAULT.SELECTLEVEL.QUESTION', answers: [
      { id: '0', answer: 'LEVEL.OBJECTIVE' },
      { id: '1', answer: 'LEVEL.CHALLENGE' },
      { id: '2', answer: 'LEVEL.SOLUTION' },
      { id: '3', answer: 'LEVEL.DELIVERABLE' }
    ], filter: 'selected.level'},
    /* Set the name of the new node */
    { key: 'add.name', type: 'form', entry: {
      tabs: [{ entry: { key: 'header1', label: '' }, children: [
        { entry: { key: 'name', type: 'text', controlType: 'textbox', label: '[[ select.level ]]', maxLength: 255, validations: [ 'required' ], validationMessages: { required: 'DATASHEET.VALIDATION.REQUIRED.NAME' } }}
      ]}]
    }},
    /* Select a potetial parent */
    { key: 'select.parent', type: 'question', question: 'WIZARD.DEFAULT.SELECTPARENT.QUESTION', filter: 'parent.level', source: 'all', add: true, skip: true, multiple: true },
    /* Add parent node */
    { key: 'add.node', type: 'flow', if: [{ key: 'select.parent', id: 'id', isUndefined: true }]},
    { key: 'create', type: 'create', active: true, text: 'WIZARD.DEFAULT.CREATE.ACTIVE', done: 'WIZARD.DEFAULT.CREATE.DONE', ifOr: [{ key: 'select.parent', isNull: true }, { key: 'select.parent', id: 'id', isUndefined: false }]}
  ],
  'hoshin-kanri-edit': [
    /* Select the step */
    { key: 'select.step', type: 'question', question: 'WIZARD.HKEDIT.SELECTSTEP.QUESTION', answers: [
      { id: 'minus', answer: 'WIZARD.HKEDIT.SELECTSTEP.LINK', params: { level1: 'level', level2: 'level-1' }},
      { id: 'plus', answer: 'WIZARD.HKEDIT.SELECTSTEP.LINK', params: { level1: 'level', level2: 'level+1' } },
      { id: 'sidestep', answer: 'WIZARD.HKEDIT.SELECTSTEP.SIDESTEP', params: { level1: 'level' } }
    ], filter: 'selected.hke'},
    /* Select the minus level parent */
    { key: 'select.parent', type: 'question', question: 'WIZARD.HKEDIT.SELECTPARENT.QUESTION', params: { node: 'name' }, source: 'minus', add: false, multiple: true, if: [{ key: 'select.step', id: 'id', value: 'minus' }] },
    /* Select the stay level parent */
    { key: 'select.parent', type: 'question', question: 'WIZARD.HKEDIT.SELECTPARENT.QUESTION', params: { node: 'name' }, source: 'stay', add: false, multiple: true, if: [{ key: 'select.step', id: 'id', value: 'stay' }] },
    /* Select the plus level parent */
    { key: 'select.child', type: 'question', question: 'WIZARD.HKEDIT.SELECTPARENT.QUESTION', params: { node: 'name' }, source: 'plus', add: false, multiple: true, if: [{ key: 'select.step', id: 'id', value: 'plus' }] },
    /* Create the relationships between selected node and initial node */
    { key: 'create.relationships', type: 'create.relationships', active: true, text: 'WIZARD.DEFAULT.CREATE.ACTIVE', close: true, ifOr: [{ key: 'select.parent', id: 'id', isUndefined: false }, { key: 'select.child', id: 'id', isUndefined: false }]},
    /* If sidestep option is selected load ba */
    { key: 'load.ba', type: 'load', source: 'businessarea', if: [{ key: 'select.step', id: 'id', value: 'sidestep' }]},
    /* Get the interesting data from store */
    { key: 'models.template', type: 'read', source: 'models.template', filter: 'hoshin', if: [{ key: 'select.step', id: 'id', value: 'sidestep' }]},
    /* Now display all relevant models */
    { key: 'select.model', type: 'question', question: 'WIZARD.HKEDIT.SELECTMODEL.QUESTION', params: { node: 'name' }, source: 'hoshin', add: false, multiple: false, if: [{ key: 'select.step', id: 'id', value: 'sidestep' }] },
    /* Select the level to add */
    { key: 'select.level', type: 'question', question: 'WIZARD.HKEDIT.SELECTLEVEL.QUESTION', translation: 'select.model', answers: [
      { id: '0', answer: 'LEVEL.OBJECTIVE' },
      { id: '1', answer: 'LEVEL.CHALLENGE' },
      { id: '2', answer: 'LEVEL.SOLUTION' },
      { id: '3', answer: 'LEVEL.DELIVERABLE' }
    ], filter: 'selected.level', if: [{ key: 'select.step', id: 'id', value: 'sidestep' }]},
    /* Group node */
    { key: 'grouping.addrel', type: 'question', question: 'GENERAL.ADDRELATIONSHIPS', answers: [
        { id: 'yes', answer: 'GENERAL.YES' },
        { id: 'no', answer: 'GENERAL.NO' }
      ], filter: 'selected.hke'},
    { key: 'group', type: 'grouping', variable: 'grouping.addrel', active: true, text: 'WIZARD.DEFAULT.CREATE.ACTIVE', close: true, if: [{ key: 'select.level', id: 'id', isUndefined: false }]}
  ],
  'strategy-overview-edit': [
    /* Select the step */
    { key: 'select.step', type: 'question', question: 'WIZARD.STROVWEDIT.SELECTSTEP.QUESTION', answers: [
        { id: 'minus', answer: 'WIZARD.STROVWEDIT.SELECTSTEP.LINK', params: { level1: 'level', level2: 'level-1' }},
        { id: 'plus', answer: 'WIZARD.STROVWEDIT.SELECTSTEP.LINK', params: { level1: 'level', level2: 'level+1' } },
        { id: 'sidestep', answer: 'WIZARD.STROVWEDIT.SELECTSTEP.SIDESTEP', params: { level1: 'level' } }
      ], filter: 'selected.hke'},
    /* Select the minus level parent */
    { key: 'select.parent', type: 'question', question: 'WIZARD.STROVWEDIT.SELECTPARENT.QUESTION', params: { node: 'name' }, source: 'minus', add: false, multiple: true, if: [{ key: 'select.step', id: 'id', value: 'minus' }] },
    /* Select the stay level parent */
    { key: 'select.parent', type: 'question', question: 'WIZARD.STROVWEDIT.SELECTPARENT.QUESTION', params: { node: 'name' }, source: 'stay', add: false, multiple: true, if: [{ key: 'select.step', id: 'id', value: 'stay' }] },
    /* Select the plus level parent */
    { key: 'select.child', type: 'question', question: 'WIZARD.STROVWEDIT.SELECTPARENT.QUESTION', params: { node: 'name' }, source: 'plus', add: false, multiple: true, if: [{ key: 'select.step', id: 'id', value: 'plus' }] },
    /* Create the relationships between selected node and initial node */
    { key: 'create.relationships', type: 'create.relationships', active: true, text: 'WIZARD.DEFAULT.CREATE.ACTIVE', close: true, ifOr: [{ key: 'select.parent', id: 'id', isUndefined: false }, { key: 'select.child', id: 'id', isUndefined: false }]},
    /* If sidestep option is selected load ba */
    { key: 'load.ba', type: 'load', source: 'businessarea', if: [{ key: 'select.step', id: 'id', value: 'sidestep' }]},
    /* Get the interesting data from store */
    { key: 'models.template', type: 'read', source: 'models.template', filter: 'hoshin', if: [{ key: 'select.step', id: 'id', value: 'sidestep' }]},
    /* Now display all relevant models */
    { key: 'select.model', type: 'question', question: 'WIZARD.STROVWEDIT.SELECTMODEL.QUESTION', params: { node: 'name' }, source: 'models', add: false, multiple: false, if: [{ key: 'select.step', id: 'id', value: 'sidestep' }] },
    /* Select the level to add */
    { key: 'select.level', type: 'question', question: 'WIZARD.STROVWEDIT.SELECTLEVEL.QUESTION', translation: 'select.model', answers: [
        { id: '0', answer: 'LEVEL.OBJECTIVE' },
        { id: '1', answer: 'LEVEL.CHALLENGE' },
        { id: '2', answer: 'LEVEL.SOLUTION' },
        { id: '3', answer: 'LEVEL.DELIVERABLE' }
      ], filter: 'selected.level', if: [{ key: 'select.step', id: 'id', value: 'sidestep' }]},
    /* Group node */
    { key: 'grouping.addrel', type: 'question', question: 'GENERAL.ADDRELATIONSHIPS', answers: [
        { id: 'yes', answer: 'GENERAL.YES' },
        { id: 'no', answer: 'GENERAL.NO' }
      ], filter: 'selected.hke'},
    { key: 'group', type: 'grouping', variable: 'grouping.addrel', active: true, text: 'WIZARD.DEFAULT.CREATE.ACTIVE', close: true, if: [{ key: 'select.level', id: 'id', isUndefined: false }]}
  ],
  'email-todo': [
    /* Load the Human resources */
    { key: 'load.humanresources', type: 'load', source: 'humanresources', active: true},
    /* Load the BA */
    { key: 'load.data', type: 'load', source: 'nodedata.related', active: true},
    /* Read the related nodes */
    { key: 'related.data', type: 'read', source: 'related.data'},
    /* Read the current user */
    { key: 'current.user', type: 'read', source: 'current.user'},
    /* Select the already sent emails */
    { key: 'select.email', type: 'question', question: 'WIZARD.EMAILTODO.SELECTMAIL.QUESTION', params: { node: 'name' }, source: 'email', add: true, multiple: false, theme: 'related' },
    /* Select a recipient */
    { key: 'select.recipient', type: 'question', question: 'WIZARD.EMAILTODO.SELECTRECIPIENT.QUESTION', source: 'humanresources', add: true, if: [{ key: 'select.email', id: 'id', isUndefined: true }] },
    /* Add the recipient */
    { key: 'add.recipient', type: 'form', entry: {
      tabs: [{ entry: { key: 'header1', label: '' }, children: [
        { entry: { key: 'first_name', type: 'text', controlType: 'textbox', label: 'HUMANRESOURCE.DATASHEET.FIELDS.FIRSTNAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.FIRSTNAME' }},
        { entry: { key: 'last_name', type: 'text', controlType: 'textbox', label: 'HUMANRESOURCE.DATASHEET.FIELDS.LASTNAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.LASTNAME' }},
        { entry: { key: 'email', type: 'text', controlType: 'textbox', label: 'HUMANRESOURCE.DATASHEET.FIELDS.EMAIL', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.EMAIL' }},
        { entry: { key: 'color', type: 'text', controlType: 'color', label: 'HUMANRESOURCE.DATASHEET.FIELDS.COLOR' }},
      ]}]
    }, if: [{ key: 'select.email', id: 'id', isUndefined: true }, { key: 'select.recipient', id: 'id', isUndefined: true }]},
    /* Create recipient */
    { key: 'create.recipient', type: 'create', source: 'humanresource', active: true, variable: 'add.recipient', text: '', if: [{ key: 'select.email', id: 'id', isUndefined: true }, { key: 'select.recipient', id: 'id', isUndefined: true }]},
    /* Set the values for a new mail */
    { key: 'add.email', type: 'form', entry: {
      tabs: [{ entry: { key: 'header1', label: '' }, children: [
        { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'WIZARD.EMAILTODO.ADD.NAME', maxLength: 255, validations: [ 'required' ], validationMessages: { required: 'DATASHEET.VALIDATION.REQUIRED.NAME' } }},
        { entry: { key: 'targetDate', type: 'datetime', controlType: 'date', label: 'WIZARD.EMAILTODO.ADD.DUEDATE' }},
        { entry: { key: 'description', controlType: 'text', label: 'WIZARD.EMAILTODO.ADD.MESSAGE' }}
      ]}]
    }, if: [{ key: 'select.email', id: 'id', isUndefined: true }]},
    /* Read the models and templates */
    { key: 'model.template', type: 'read', source: 'model.template', filter: ['todo'], if: [{ key: 'select.email', id: 'id', isUndefined: true }]},
    /* Create the to do model if there is none */
    { key: 'create.model', type: 'create', source: 'model', variable: { name: 'WIZARD.EMAILTODO.CREATE.MODEL', template: 'todo' }, active: true, text: '', done: '', if: [{ key: 'select.email', id: 'id', isUndefined: true }, { key: 'model.template', id: 'models', eq: 0 }]},
    /* Read and combine the data for to do node and email */
    { key: 'todo.data', type: 'read', source: 'todo.data', variable: { name: 'WIZARD.EMAILTODO.EMAIL.SUBJECT', description: 'WIZARD.EMAILTODO.EMAIL.MESSAGE' }, if: [{ key: 'select.email', id: 'id', isUndefined: true }]},
    /* Create the to do node */
    { key: 'create.todo', type: 'create', source: 'todo', active: true, text: '', done: '', if: [{ key: 'select.email', id: 'id', isUndefined: true }]},
    /* Prepare the data for email send */
    { key: 'email.send', type: 'read', source: 'email.send', if: [{ key: 'select.email', id: 'id', isUndefined: true }]},
    /* Update to-do's description */
    { key: 'todo.update', type: 'update', source: 'todo', active: true, if: [{ key: 'select.email', id: 'id', isUndefined: true }]},
    /* Create and send out email */
    { key: 'email', type: 'email', source: 'email.send', active: true, close: true, text: '', done: '', if: [{ key: 'select.email', id: 'id', isUndefined: true }, { key: 'create.todo', id: 'structure', isUndefined: false }, { key: 'create.todo', id: 'data', isUndefined: false }]},
    /* Resend email */
    { key: 'resend.email', type: 'question', question: 'WIZARD.EMAILTODO.RESEND', answers: [
      { id: 'yes', answer: 'GENERAL.YES' },
      { id: 'no', answer: 'GENERAL.NO' }
    ], if: [{ key: 'select.email', id: 'id', isUndefined: false }]},
    /* Read and combine the data for to do node and email */
    { key: 'email.resend', type: 'read', source: 'email.resend', if: [{ key: 'select.email', id: 'id', isUndefined: false }, { key: 'resend.email', id: 'id', value: 'yes' }]},
    /* Create and send out email */
    { key: 'email', type: 'email', source: 'email.resend', active: true, close: true, text: '', done: '', if: [{ key: 'select.email', id: 'id', isUndefined: false }, { key: 'resend.email', id: 'id', value: 'yes' }]},
  ],
  'email-token': [
    /* Enter a recipient
    { key: 'enter.recipient', type: 'question', question: 'WIZARD.EMAILTODO.ENTERRECIPIENT.QUESTION', entry: {
        tabs: [{ entry: { key: 'header1', label: '' }, children: [
            { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'WIZARD.EMAILTODO.ADD.NAME', maxLength: 255, validations: [ 'required' ], validationMessages: { required: 'DATASHEET.VALIDATION.REQUIRED.NAME' } }},
          ]}]
      }, }, */
    /* Set the values for a new mail */
    { key: 'add.email', type: 'form', entry: {
        tabs: [{ entry: { key: 'header1', label: '' }, children: [
            { entry: { key: 'email', type: 'text', controlType: 'textbox', label: 'WIZARD.EMAILONETIMEACCESS.ADD.EMAIL', maxLength: 255, validations: [ 'required' ], validationMessages: { required: 'DATASHEET.VALIDATION.REQUIRED.EMAIL' } }},
            { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'WIZARD.EMAILONETIMEACCESS.ADD.NAME' }},
            { entry: { key: 'message', controlType: 'text', label: 'WIZARD.EMAILONETIMEACCESS.ADD.MESSAGE' }}
          ]}]
      }},
    /* Load the BA */
    { key: 'load.token', type: 'load', active: true, source: 'node.token', text: 'WIZARD.EMAILONETIMEACCESS.LOAD.TOKEN'},
    /* Read the loaded token */
    { key: 'token.data', type: 'read', source: 'token.data'},
    /* Prepare the data for email send */
    { key: 'email.token.send', type: 'read', variable: { subject: 'WIZARD.EMAILONETIMEACCESS.EMAIL.SUBJECT', message: 'WIZARD.EMAILONETIMEACCESS.EMAIL.MESSAGE' }, source: 'email.token.send' },
    /* Create and send out email */
    { key: 'email', type: 'email', source: 'email.token.send', active: true, close: true, text: '', done: ''}
  ],
  'eisenhower-todo': [
    /* Set the values for the to do */
    { key: 'todo', type: 'form', entry: {
      tabs: [{ entry: { key: 'header1', label: '' }, children: [
        { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'DASHBOARD.ASSIGNMENTOFTASKS.MYTODO.NAME', maxLength: 255, validations: [ 'required' ], validationMessages: { required: 'DATASHEET.VALIDATION.REQUIRED.NAME' } }},
        { entry: { key: 'targetDate', type: 'datetime', controlType: 'date', label: 'DASHBOARD.ASSIGNMENTOFTASKS.MYTODO.TARGETDATE' }},
        { entry: { key: 'description', controlType: 'text', label: 'DASHBOARD.ASSIGNMENTOFTASKS.MYTODO.DESCRIPTION'}},
      ]}]
    }},
    /* Read the current user */
    { key: 'current.human.resource', type: 'read', source: 'current.human.resource'},
    /* Create the to do node */
    { key: 'create.todo', type: 'create', text: 'DASHBOARD.ASSIGNMENTOFTASKS.MYTODO.ACTIVE', done: 'DASHBOARD.ASSIGNMENTOFTASKS.MYTODO.DONE', source: 'eisenhower-todo', active: true },
  ],
  'assign-eisenhower-todo': [
    /* Set the values for the to do */
    { key: 'todo', type: 'form', entry: {
      tabs: [{ entry: { key: 'header1', label: '' }, children: [
        { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'DASHBOARD.ASSIGNMENTOFTASKS.ASSIGNTODOS.NAME', maxLength: 255, validations: [ 'required' ], validationMessages: { required: 'DASHBOARD.ASSIGNMENTOFTASKS.ASSIGNTODOS.REQUIRED.NAME' } }},
        { entry: { key: 'firstname', type: 'text', controlType: 'textbox', label: 'DASHBOARD.ASSIGNMENTOFTASKS.ASSIGNTODOS.FIRSTNAME', maxLength: 255, validations: [ 'required' ], validationMessages: { required: 'DASHBOARD.ASSIGNMENTOFTASKS.ASSIGNTODOS.REQUIRED.FIRSTNAME' } }},
        { entry: { key: 'lastname', type: 'text', controlType: 'textbox', label: 'DASHBOARD.ASSIGNMENTOFTASKS.ASSIGNTODOS.LASTNAME', maxLength: 255, validations: [ 'required' ], validationMessages: { required: 'DASHBOARD.ASSIGNMENTOFTASKS.ASSIGNTODOS.REQUIRED.LASTNAME' } }},
        { entry: { key: 'email', type: 'email', controlType: 'textbox', label: 'DASHBOARD.ASSIGNMENTOFTASKS.ASSIGNTODOS.EMAIL', maxLength: 255, validations: [ 'required' ], validationMessages: { required: 'DASHBOARD.ASSIGNMENTOFTASKS.ASSIGNTODOS.REQUIRED.EMAIL' } }},
        { entry: { key: 'targetDate', type: 'datetime', controlType: 'date', label: 'DASHBOARD.ASSIGNMENTOFTASKS.ASSIGNTODOS.TARGETDATE' }},
        { entry: { key: 'description', controlType: 'text', label: 'DASHBOARD.ASSIGNMENTOFTASKS.ASSIGNTODOS.DESCRIPTION'}},
      ]}]
    }},
    /* Read the current user */
    { key: 'current.user', type: 'read', source: 'current.user'},
    /* Check if the human resource is known if not create it */
    { key: 'current.human.resource', type: 'read', source: 'humanresource'},
    /* Create the to do node */
    { key: 'create.todo', type: 'create', text: 'DASHBOARD.ASSIGNMENTOFTASKS.ASSIGNTODOS.ACTIVE', done: 'DASHBOARD.ASSIGNMENTOFTASKS.ASSIGNTODOS.DONE', source: 'eisenhower-todo', active: true },
    /* Prepare the data for email send */
    { key: 'email.eisenhower.todo.send', type: 'read', variable: { subject: 'DASHBOARD.ASSIGNMENTOFTASKS.ASSIGNTODOS.EMAILS.SUBJECT', message: 'DASHBOARD.ASSIGNMENTOFTASKS.ASSIGNTODOS.EMAILS.MESSAGE' }, source: 'email.eisenhower.todo.send' },
    /* Create and send out email */
    { key: 'email', type: 'email', source: 'email.eisenhower.todo.send', active: true, close: true, text: 'DASHBOARD.ASSIGNMENTOFTASKS.ASSIGNTODOS.SEND', done: 'DASHBOARD.ASSIGNMENTOFTASKS.ASSIGNTODOS.SENT'}
  ],
  'eisenhower-project': [
    /* Read the current user */
    { key: 'current.user', type: 'read', source: 'current.user'},
    /* Set the values for the to do */
    { key: 'todo', type: 'form', entry: {
      tabs: [{ entry: { key: 'header1', label: '' }, children: [
        { entry: { key: 'firstname', type: 'text', controlType: 'textbox', label: 'DASHBOARD.ASSIGNMENTOFTASKS.PROJECT.FIRSTNAME', maxLength: 255, validations: [ 'required' ], validationMessages: { required: 'DASHBOARD.ASSIGNMENTOFTASKS.PROJECT.REQUIRED.FIRSTNAME' } }},
        { entry: { key: 'lastname', type: 'text', controlType: 'textbox', label: 'DASHBOARD.ASSIGNMENTOFTASKS.PROJECT.LASTNAME', maxLength: 255, validations: [ 'required' ], validationMessages: { required: 'DASHBOARD.ASSIGNMENTOFTASKS.PROJECT.REQUIRED.LASTNAME' } }},
        { entry: { key: 'email', type: 'email', controlType: 'textbox', label: 'DASHBOARD.ASSIGNMENTOFTASKS.PROJECT.EMAIL', maxLength: 255, validations: [ 'required' ], validationMessages: { required: 'DASHBOARD.ASSIGNMENTOFTASKS.PROJECT.REQUIRED.EMAIL' } }},
        { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'DASHBOARD.ASSIGNMENTOFTASKS.PROJECT.NAME', maxLength: 255, validations: [ 'required' ], validationMessages: { required: 'DASHBOARD.ASSIGNMENTOFTASKS.PROJECT.REQUIRED.NAME' } }}
      ]}]
    }},
    /* Check if the human resource is known if not create it */
    { key: 'current.human.resource', type: 'read', source: 'humanresource'},
    /* Create the to do node */
    { key: 'create.todo', type: 'create', text: 'DASHBOARD.ASSIGNMENTOFTASKS.PROJECT.ACTIVE', done: 'DASHBOARD.ASSIGNMENTOFTASKS.PROJECT.DONE', source: 'eisenhower-project', active: true },
    /* Prepare the data for email send */
    { key: 'email.eisenhower.project.send', type: 'read', variable: { subject: 'DASHBOARD.ASSIGNMENTOFTASKS.PROJECT.EMAILS.SUBJECT', message: 'DASHBOARD.ASSIGNMENTOFTASKS.PROJECT.EMAILS.MESSAGE' }, source: 'email.eisenhower.project.send' },
    /* Create and send out email */
    { key: 'email', type: 'email', source: 'email.eisenhower.project.send', active: true, close: true, text: 'DASHBOARD.ASSIGNMENTOFTASKS.PROJECT.SEND', done: 'DASHBOARD.ASSIGNMENTOFTASKS.PROJECT.SENT'}
  ],
  'eisenhower-support': [
    /* The links */
    { key: 'links', type: 'links', header: 'DASHBOARD.ASSIGNMENTOFTASKS.SUPPORTWIZ.HEADER', links: [
      { title: 'DASHBOARD.ASSIGNMENTOFTASKS.SUPPORTWIZ.LINK1', url: 'https://google.de'},
      { title: 'DASHBOARD.ASSIGNMENTOFTASKS.SUPPORTWIZ.LINK2', url: 'https://google.de'},
      { title: 'DASHBOARD.ASSIGNMENTOFTASKS.SUPPORTWIZ.LINK3', url: 'https://google.de'},
    ]},
  ]
};
