import { Utils } from '../shared/utils';
import { Action } from '../shared/common.action';
import { ImportAction } from './import.actions';
import { ImportState } from './import.models';

export default function reducer(state = new ImportState(), action: Action): ImportState {

  switch (action.type) {

    case ImportAction.IMPORT: {
      return <ImportState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case ImportAction.IMPORT_SUCCESS: {
      return <ImportState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case ImportAction.IMPORT_FAIL: {
      return <ImportState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    default:
      return state;
  }
}
