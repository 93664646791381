import { TemplateFieldsNodes } from '../../../environments/template/fields/default/template.fields.nodes';
import {TemplateFieldsActivities} from '../../../environments/template/fields/default/template.fields.activities';
import {TemplateFieldsBusinessareas} from '../../../environments/template/fields/default/template.fields.businessareas';
import {TemplateFieldsModels} from '../../../environments/template/fields/default/template.fields.models';

export class LanguageMap {
    /**
     * This returns an object containing various language file keys
     * for the properties of an object. As specified by the default template
     * eg:
     *  nodedata.budget = 'NODE.DATASHEET.FIELDS.KPI'
     */
    public static getDefaultTranslations() {
        return {
            nodedata: LanguageMap.getTranslation(TemplateFieldsNodes.tabs),
            activities: LanguageMap.getTranslation(TemplateFieldsActivities.tabs),
            businessareas: LanguageMap.getTranslation(TemplateFieldsBusinessareas.tabs),
            models: LanguageMap.getTranslation(TemplateFieldsModels.tabs)
        };
    }

    /**
     * Writing this to combine objects a and b
     * into a single one. This is done because,
     * Spread Operator(...) - unsupported on Opera
     * Array.assign() - unsupported on edge/IE
     *
     * If a = {1: a, 2: b} and b = {3: c}
     * the function returns {1: a, 2: b, 3: c}
     * If a and b have the same keys, value in b
     * will overwrite that in a.
     *
     * @param a Object
     * @param b Object
     *
     * @returns Object that is a combination of a and b
     */
    private static combineObjects(a: Object, b: Object) {
        let c = {};
        for (const i in a) {
            if (a.hasOwnProperty(i)) {
                c[i] = a[i];
            }
        }
        for (const i in b) {
            if (b.hasOwnProperty(i)) {
                c[i] = b[i];
            }
        }
        return c;
    }

    /**
     * Function to recursively read through the template object
     * and find mappings between keys and labels
     *
     * @param templateObject An array of tabs OR children
     * retrieved from the template file
     *
     * @returns array containing all keys to label mappings
     */
    public static getTranslation(templateObject: any[]) {
        let map = {};
        for (const obj of templateObject) {
            if (obj.hasOwnProperty('children') && obj.entry.key !== 'unused') {
                // combine the results from this entry and it's children
                map = this.combineObjects(map, this.getTranslation(obj.children));
            } else if (obj.hasOwnProperty('entry') &&
                obj.entry.hasOwnProperty('controlType') &&
                obj.entry.hasOwnProperty('key') &&
                obj.entry.hasOwnProperty('label')) {
                map[obj.entry.key] = obj.entry.label;
            }
        }
        return map;
    }
}
