export const TemplateFieldsDashboard = {
  nodes: {
    dashboard: {
      type: 'table',
      settings: {
        /* Group the elements by levels */
        groupByLevels: false,
        /* Show total row after level group */
        showTotalsAfterLevel: false,
        /* Show total row at the end of the table */
        showTotals: false,
        /* Group the elements by model */
        groupByModels: false,
        /* Use a thousand seperator for numeric values */
        thousandsSeperator: '.',
        /* How many decimals should be shown on numeric values */
        decimalnumbers: 2,
        /* Show top most header row (grouped heading) */
        showHeading: false,
        /* Show table fields */
        showFields: true,
        /* Different source then nodes */
        source: '', // '', models, activities
        /* Caption element above the table */
        caption: null,
        /* The header text color */
        headerTextColor: '#ffffff',
        /* The header background color */
        headerBackgroundColor: '#9ac93e',
        /* The border */
        border: false,
        /* A filter function to filter by */
        filter: null,
        /* Show the assigned activities */
        activities: false,
        /* Trigger to load the Businessarea with that table */
        loadBusinessarea: false,
        /* Show sidestep top most / tree below related node */
        showSidestep: false, // false, tree, topmost
        /* Exports */
        exports: [], //  excel, pdf
      },
      configuration: {
        fields: [
          {
            entry: {key: 'general', label: '', controlType: 'group', sortable: true}, children: [
              {entry: {key: 'name', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.NAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME', sortable: true}},
              {entry: {key: 'budget', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.KPI', sortable: true}},
              {entry: {key: 'targetDate', type: 'datetime', controlType: 'date', label: 'NODE.DATASHEET.FIELDS.TARGETDATE', sortable: true}},
              {
                entry: {
                  key: 'status', controlType: 'dropdown', label: 'STATUS.TITLE', sortable: true, addNull: false, options: [
                    {key: 0, value: 'STATUS.PENDING', color: '#999999'},
                    {key: 100, value: 'STATUS.INDEFINE', color: '#000000'},
                    {key: 110, value: 'STATUS.INDEVELOP', color: '#1099D6'},
                    {key: 120, value: 'STATUS.INVERIFY', color: '#AA008F'},
                    {key: 130, value: 'STATUS.INACCEPT', color: '#EDB922'},
                    {key: 200, value: 'STATUS.COMPLETED', color: '#9ac93e'}
                  ]
                }
              },
              {entry: {key: 'percentageComplete', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.PERCENTAGECOMPLETE', sortable: true}},
              {
                entry: {
                  key: 'risk', type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.RISK', addNull: false, sortable: true, options: [
                    {key: 0, value: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE'},
                    {key: 1, value: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW'},
                    {key: 2, value: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM'},
                    {key: 3, value: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH'},
                  ]
                }
              },
              {
                entry: {
                  key: 'complexity', type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.COMPLEXITY', addNull: false, sortable: true, options: [
                    {key: 0, value: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE'},
                    {key: 1, value: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW'},
                    {key: 2, value: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM'},
                    {key: 3, value: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH'},
                  ]
                }
              }
            ]
          },
          { entry: {key: 'gotoModel', type: 'link', controlType: 'link', label: '' }}
        ]
      }
    },
    sidebar: {
      type: 'table',
      settings: {
        /* Group the elements by levels */
        groupByLevels: false,
        /* Show total row after level group */
        showTotalsAfterLevel: false,
        /* Show total row at the end of the table */
        showTotals: false,
        /* Group the elements by model */
        groupByModels: false,
        /* Use a thousand seperator for numeric values */
        thousandsSeperator: '.',
        /* How many decimals should be shown on numeric values */
        decimalnumbers: 2,
        /* Show top most header row (grouped heading) */
        showHeading: false,
        /* Show table fields */
        showFields: true,
        /* Different source then nodes */
        source: '', // '', models, activities
        /* Caption element above the table */
        caption: null,
        /* The header text color */
        headerTextColor: '#ffffff',
        /* The header background color */
        headerBackgroundColor: '#9ac93e',
        /* The border */
        border: false,
        /* A filter function to filter by */
        filter: null,
        /* Show the assigned activities */
        activities: false,
        /* Trigger to load the Businessarea with that table */
        loadBusinessarea: false,
        /* Show sidestep top most / tree below related node */
        showSidestep: false, // false, tree, topmost
        /* Exports */
        exports: [], //  excel, pdf
      },
      configuration: {
        fields: [
          {
            entry: {key: 'general', label: '', controlType: 'group', sortable: true}, children: [
              {entry: {key: 'name', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.NAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME', sortable: true}},
            ]
          },
          { entry: {key: 'gotoModel', type: 'link', controlType: 'link', label: '' }}
        ]
      }
    }
  },
  benefit: {
    dashboard: {
      type: 'table',
      settings: {
        /* Group the elements by levels */
        groupByLevels: false,
        /* Show total row after level group */
        showTotalsAfterLevel: false,
        /* Show total row at the end of the table */
        showTotals: false,
        /* Group the elements by model */
        groupByModels: false,
        /* Use a thousand seperator for numeric values */
        thousandsSeperator: '.',
        /* How many decimals should be shown on numeric values */
        decimalnumbers: 2,
        /* Show top most header row (grouped heading) */
        showHeading: false,
        /* Show table fields */
        showFields: true,
        /* Different source then nodes */
        source: '', // '', models, activities
        /* Caption element above the table */
        caption: null,
        /* The header text color */
        headerTextColor: '#ffffff',
        /* The header background color */
        headerBackgroundColor: '#9ac93e',
        /* The border */
        border: false,
        /* A filter function to filter by */
        filter: null,
        /* Show the assigned activities */
        activities: false,
        /* Trigger to load the Businessarea with that table */
        loadBusinessarea: false,
        /* Show sidestep top most / tree below related node */
        showSidestep: false, // false, tree, topmost
        /* Exports */
        exports: [], //  excel, pdf
      },
      configuration: {
        fields: [
          {
            entry: {key: 'general', label: '', controlType: 'group', sortable: true}, children: [
              {entry: {key: 'name', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.NAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME', sortable: true}},
              {entry: {key: 'benefitBudget', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.BENEFITBUDGET', sortable: true}},
              {entry: {key: 'benefitActual', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.BENEFITACTUAL', sortable: true}},
            ]
          },
          {entry: {key: 'gotoModel', type: 'link', controlType: 'link', label: ''}}
        ]
      }
    },
    sidebar: {
      type: 'table',
      settings: {
        /* Group the elements by levels */
        groupByLevels: false,
        /* Show total row after level group */
        showTotalsAfterLevel: false,
        /* Show total row at the end of the table */
        showTotals: false,
        /* Group the elements by model */
        groupByModels: false,
        /* Use a thousand seperator for numeric values */
        thousandsSeperator: '.',
        /* How many decimals should be shown on numeric values */
        decimalnumbers: 2,
        /* Show top most header row (grouped heading) */
        showHeading: false,
        /* Show table fields */
        showFields: true,
        /* Different source then nodes */
        source: '', // '', models, activities
        /* Caption element above the table */
        caption: null,
        /* The header text color */
        headerTextColor: '#ffffff',
        /* The header background color */
        headerBackgroundColor: '#9ac93e',
        /* The border */
        border: false,
        /* A filter function to filter by */
        filter: null,
        /* Show the assigned activities */
        activities: false,
        /* Trigger to load the Businessarea with that table */
        loadBusinessarea: false,
        /* Show sidestep top most / tree below related node */
        showSidestep: false, // false, tree, topmost
        /* Exports */
        exports: [], //  excel, pdf
      },
      configuration: {
        fields: [
          {
            entry: {key: 'general', label: '', controlType: 'group', sortable: true}, children: [
              {entry: {key: 'name', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.NAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME', sortable: true}},
              {entry: {key: 'benefitBudget', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.BENEFITBUDGET', sortable: true}},
              {entry: {key: 'benefitActual', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.BENEFITACTUAL', sortable: true}},
            ]
          },
          {entry: {key: 'gotoModel', type: 'link', controlType: 'link', label: ''}}
        ]
      }
    }
  },
  strategyanchorage: {
    dashboard: {
      type: 'table',
      settings: {
        /* Group the elements by levels */
        groupByLevels: false,
        /* Show total row after level group */
        showTotalsAfterLevel: false,
        /* Show total row at the end of the table */
        showTotals: false,
        /* Group the elements by model */
        groupByModels: false,
        /* Use a thousand seperator for numeric values */
        thousandsSeperator: '.',
        /* How many decimals should be shown on numeric values */
        decimalnumbers: 2,
        /* Show top most header row (grouped heading) */
        showHeading: false,
        /* Show table fields */
        showFields: true,
        /* Different source then nodes */
        source: '', // '', models, activities
        /* Caption element above the table */
        caption: null,
        /* The header text color */
        headerTextColor: '#ffffff',
        /* The header background color */
        headerBackgroundColor: '#9ac93e',
        /* The border */
        border: false,
        /* A filter function to filter by */
        filter: 'strategyanchorage',
        /* Show the assigned activities */
        activities: false,
        /* Trigger to load the Businessarea with that table */
        loadBusinessarea: false,
        /* Show sidestep top most / tree below related node */
        showSidestep: false, // false, tree, topmost
        /* Exports */
        exports: [], //  excel, pdf
      },
      configuration: {
        fields: [
          {
            entry: {key: 'general', label: '', controlType: 'group', sortable: true }, children: [
              { entry: { key: 'measures', controlType: 'measures', label: '', fixedWidth: 70, editable: true }},
              { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'DASHBOARD.STRATEGYANCHORAGE.NAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME', editable: false }},
              { entry: { key: 'abbreviation', type: 'text', controlType: 'textbox', label: 'DASHBOARD.STRATEGYANCHORAGE.ABBREVIATION', editable: false, calculated: true }},
              { entry: { key: 'riskProfileCategory', controlType: 'dropdown',  label: 'DASHBOARD.STRATEGYANCHORAGE.RISKPROFILECATEGORY.TITLE', options: [
                { key: 0, value: 'DASHBOARD.STRATEGYANCHORAGE.RISKPROFILECATEGORY.NOTAPPLICABLE' },
                { key: 1, value: 'DASHBOARD.STRATEGYANCHORAGE.RISKPROFILECATEGORY.GMAA' },
                { key: 2, value: 'DASHBOARD.STRATEGYANCHORAGE.RISKPROFILECATEGORY.CUSTOM' }
              ]}},
              { entry: { key: 'description', controlType: 'text', label: 'DASHBOARD.STRATEGYANCHORAGE.TARGETVALUE' }},
              { entry: { key: 'lastStatusColor', controlType: 'dropdown', label: 'DASHBOARD.STRATEGYANCHORAGE.STATUS', calculated: true, editable: false, addNull: false, options: [
                { key: 0, value: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE', color: '#999999' },
                { key: 1, value: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW', color: '#9ac93e', shape: 'circle' },
                { key: 2, value: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM', color: '#EDD515', shape: 'triangle' },
                { key: 3, value: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH', color: '#E50000', shape: 'rect' }
              ]}},
            ]
          },
          { entry: {key: 'gotoModel', type: 'link', controlType: 'link', label: '' }}
        ]
      }
    },
    sidebar: {
      type: 'table',
      settings: {
        /* Group the elements by levels */
        groupByLevels: false,
        /* Show total row after level group */
        showTotalsAfterLevel: false,
        /* Show total row at the end of the table */
        showTotals: false,
        /* Group the elements by model */
        groupByModels: false,
        /* Use a thousand seperator for numeric values */
        thousandsSeperator: '.',
        /* How many decimals should be shown on numeric values */
        decimalnumbers: 2,
        /* Show top most header row (grouped heading) */
        showHeading: false,
        /* Show table fields */
        showFields: true,
        /* Different source then nodes */
        source: '', // '', models, activities
        /* Caption element above the table */
        caption: null,
        /* The header text color */
        headerTextColor: '#ffffff',
        /* The header background color */
        headerBackgroundColor: '#9ac93e',
        /* The border */
        border: false,
        /* A filter function to filter by */
        filter: 'strategyanchorage',
        /* Show the assigned activities */
        activities: false,
        /* Trigger to load the Businessarea with that table */
        loadBusinessarea: false,
        /* Show sidestep top most / tree below related node */
        showSidestep: false, // false, tree, topmost
        /* Exports */
        exports: [], //  excel, pdf
      },
      configuration: {
        fields: [
          {
            entry: {key: 'general', label: '', controlType: 'group', sortable: true}, children: [
              {entry: {key: 'name', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.NAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME', sortable: true, editable: false}}
            ]
          },
          { entry: {key: 'gotoModel', type: 'link', controlType: 'link', label: ''}}
        ]
      }
    }
  },
  cost: {
    dashboard: {
      type: 'table',
      settings: {
        /* Group the elements by levels */
        groupByLevels: false,
        /* Show total row after level group */
        showTotalsAfterLevel: false,
        /* Show total row at the end of the table */
        showTotals: false,
        /* Group the elements by model */
        groupByModels: false,
        /* Use a thousand seperator for numeric values */
        thousandsSeperator: '.',
        /* How many decimals should be shown on numeric values */
        decimalnumbers: 2,
        /* Show top most header row (grouped heading) */
        showHeading: false,
        /* Show table fields */
        showFields: true,
        /* Different source then nodes */
        source: '', // '', models, activities
        /* Caption element above the table */
        caption: null,
        /* The header text color */
        headerTextColor: '#ffffff',
        /* The header background color */
        headerBackgroundColor: '#9ac93e',
        /* The border */
        border: false,
        /* A filter function to filter by */
        filter: null,
        /* Show the assigned activities */
        activities: false,
        /* Trigger to load the Businessarea with that table */
        loadBusinessarea: false,
        /* Show sidestep top most / tree below related node */
        showSidestep: false, // false, tree, topmost
        /* Exports */
        exports: [], //  excel, pdf
      },
      configuration: {
        fields: [
          {
            entry: {key: 'general', label: '', controlType: 'group', sortable: true}, children: [
              {entry: {key: 'name', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.NAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME', sortable: true}},
              { entry: { key: 'costActual', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.COSTACTUAL', sortable: true }},
              { entry: { key: 'costBudget', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.COSTBUDGET', sortable: true }},
            ]
          },
          {entry: {key: 'gotoModel', type: 'link', controlType: 'link', label: ''}}
        ]
      }
    },
    sidebar: {
      type: 'table',
      settings: {
        /* Group the elements by levels */
        groupByLevels: false,
        /* Show total row after level group */
        showTotalsAfterLevel: false,
        /* Show total row at the end of the table */
        showTotals: false,
        /* Group the elements by model */
        groupByModels: false,
        /* Use a thousand seperator for numeric values */
        thousandsSeperator: '.',
        /* How many decimals should be shown on numeric values */
        decimalnumbers: 2,
        /* Show top most header row (grouped heading) */
        showHeading: false,
        /* Show table fields */
        showFields: true,
        /* Different source then nodes */
        source: '', // '', models, activities
        /* Caption element above the table */
        caption: null,
        /* The header text color */
        headerTextColor: '#ffffff',
        /* The header background color */
        headerBackgroundColor: '#9ac93e',
        /* The border */
        border: false,
        /* A filter function to filter by */
        filter: null,
        /* Show the assigned activities */
        activities: false,
        /* Trigger to load the Businessarea with that table */
        loadBusinessarea: false,
        /* Show sidestep top most / tree below related node */
        showSidestep: false, // false, tree, topmost
        /* Exports */
        exports: [], //  excel, pdf
      },
      configuration: {
        fields: [
          {
            entry: {key: 'general', label: '', controlType: 'group', sortable: true}, children: [
              {entry: {key: 'name', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.NAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME', sortable: true}},
              { entry: { key: 'costActual', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.COSTACTUAL', sortable: true }},
              { entry: { key: 'costBudget', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.COSTBUDGET', sortable: true }},
            ]
          },
          {entry: {key: 'gotoModel', type: 'link', controlType: 'link', label: ''}}
        ]
      }
    }
  },
  nodesdelayed: {
    dashboard: {
      type: 'table',
      settings: {
        /* Group the elements by levels */
        groupByLevels: false,
        /* Show total row after level group */
        showTotalsAfterLevel: false,
        /* Show total row at the end of the table */
        showTotals: false,
        /* Group the elements by model */
        groupByModels: false,
        /* Use a thousand seperator for numeric values */
        thousandsSeperator: '.',
        /* How many decimals should be shown on numeric values */
        decimalnumbers: 2,
        /* Show top most header row (grouped heading) */
        showHeading: false,
        /* Show table fields */
        showFields: true,
        /* Different source then nodes */
        source: '', // '', models, activities
        /* Caption element above the table */
        caption: null,
        /* The header text color */
        headerTextColor: '#ffffff',
        /* The header background color */
        headerBackgroundColor: '#9ac93e',
        /* The border */
        border: false,
        /* A filter function to filter by */
        filter: null,
        /* Show the assigned activities */
        activities: false,
        /* Trigger to load the Businessarea with that table */
        loadBusinessarea: false,
        /* Show sidestep top most / tree below related node */
        showSidestep: false, // false, tree, topmost
        /* Exports */
        exports: [], //  excel, pdf
      },
      configuration: {
        fields: [
          {
            entry: {key: 'general', label: '', controlType: 'group', sortable: true}, children: [
              {entry: {key: 'name', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.NAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME', sortable: true}},
              { entry: { key: 'targetDate', type: 'datetime', controlType: 'date', label: 'NODE.DATASHEET.FIELDS.TARGETDATE' }},
              {
                entry: {
                  key: 'status', controlType: 'dropdown', label: 'STATUS.TITLE', sortable: true, addNull: false, options: [
                    {key: 0, value: 'STATUS.PENDING', color: '#999999'},
                    {key: 100, value: 'STATUS.INDEFINE', color: '#000000'},
                    {key: 110, value: 'STATUS.INDEVELOP', color: '#1099D6'},
                    {key: 120, value: 'STATUS.INVERIFY', color: '#AA008F'},
                    {key: 130, value: 'STATUS.INACCEPT', color: '#EDB922'},
                    {key: 200, value: 'STATUS.COMPLETED', color: '#9ac93e'}
                  ]
                }
              }
            ]
          },
          {entry: {key: 'gotoModel', type: 'link', controlType: 'link', label: ''}}
        ]
      }
    },
    sidebar: {
      type: 'table',
      settings: {
        /* Group the elements by levels */
        groupByLevels: false,
        /* Show total row after level group */
        showTotalsAfterLevel: false,
        /* Show total row at the end of the table */
        showTotals: false,
        /* Group the elements by model */
        groupByModels: false,
        /* Use a thousand seperator for numeric values */
        thousandsSeperator: '.',
        /* How many decimals should be shown on numeric values */
        decimalnumbers: 2,
        /* Show top most header row (grouped heading) */
        showHeading: false,
        /* Show table fields */
        showFields: true,
        /* Different source then nodes */
        source: '', // '', models, activities
        /* Caption element above the table */
        caption: null,
        /* The header text color */
        headerTextColor: '#ffffff',
        /* The header background color */
        headerBackgroundColor: '#9ac93e',
        /* The border */
        border: false,
        /* A filter function to filter by */
        filter: null,
        /* Show the assigned activities */
        activities: false,
        /* Trigger to load the Businessarea with that table */
        loadBusinessarea: false,
        /* Show sidestep top most / tree below related node */
        showSidestep: false, // false, tree, topmost
        /* Exports */
        exports: [], //  excel, pdf
      },
      configuration: {
        fields: [
          {
            entry: {key: 'general', label: '', controlType: 'group', sortable: true}, children: [
              {entry: {key: 'name', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.NAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME', sortable: true}},
              { entry: { key: 'targetDate', type: 'datetime', controlType: 'date', label: 'NODE.DATASHEET.FIELDS.TARGETDATE' }},
              {
                entry: {
                  key: 'status', controlType: 'dropdown', label: 'STATUS.TITLE', sortable: true, addNull: false, options: [
                    {key: 0, value: 'STATUS.PENDING', color: '#999999'},
                    {key: 100, value: 'STATUS.INDEFINE', color: '#000000'},
                    {key: 110, value: 'STATUS.INDEVELOP', color: '#1099D6'},
                    {key: 120, value: 'STATUS.INVERIFY', color: '#AA008F'},
                    {key: 130, value: 'STATUS.INACCEPT', color: '#EDB922'},
                    {key: 200, value: 'STATUS.COMPLETED', color: '#9ac93e'}
                  ]
                }
              }
            ]
          },
          {entry: {key: 'gotoModel', type: 'link', controlType: 'link', label: ''}}
        ]
      }
    }
  },
  nodeshighrisk: {
    dashboard: {
      type: 'table',
      settings: {
        /* Group the elements by levels */
        groupByLevels: false,
        /* Show total row after level group */
        showTotalsAfterLevel: false,
        /* Show total row at the end of the table */
        showTotals: false,
        /* Group the elements by model */
        groupByModels: false,
        /* Use a thousand seperator for numeric values */
        thousandsSeperator: '.',
        /* How many decimals should be shown on numeric values */
        decimalnumbers: 2,
        /* Show top most header row (grouped heading) */
        showHeading: false,
        /* Show table fields */
        showFields: true,
        /* Different source then nodes */
        source: '', // '', models, activities
        /* Caption element above the table */
        caption: null,
        /* The header text color */
        headerTextColor: '#ffffff',
        /* The header background color */
        headerBackgroundColor: '#9ac93e',
        /* The border */
        border: false,
        /* A filter function to filter by */
        filter: null,
        /* Show the assigned activities */
        activities: false,
        /* Trigger to load the Businessarea with that table */
        loadBusinessarea: false,
        /* Show sidestep top most / tree below related node */
        showSidestep: false, // false, tree, topmost
        /* Exports */
        exports: [], //  excel, pdf
      },
      configuration: {
        fields: [
          {
            entry: {key: 'general', label: '', controlType: 'group', sortable: true}, children: [
              {entry: {key: 'name', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.NAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME', sortable: true}},
              { entry: { key: 'risk', type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.RISK', options: [
                { key: 0, value: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE' },
                { key: 1, value: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW' },
                { key: 2, value: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM' },
                { key: 3, value: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH' },
              ]}}
            ]
          },
          {entry: {key: 'gotoModel', type: 'link', controlType: 'link', label: ''}}
        ]
      }
    },
    sidebar: {
      type: 'table',
      settings: {
        /* Group the elements by levels */
        groupByLevels: false,
        /* Show total row after level group */
        showTotalsAfterLevel: false,
        /* Show total row at the end of the table */
        showTotals: false,
        /* Group the elements by model */
        groupByModels: false,
        /* Use a thousand seperator for numeric values */
        thousandsSeperator: '.',
        /* How many decimals should be shown on numeric values */
        decimalnumbers: 2,
        /* Show top most header row (grouped heading) */
        showHeading: false,
        /* Show table fields */
        showFields: true,
        /* Different source then nodes */
        source: '', // '', models, activities
        /* Caption element above the table */
        caption: null,
        /* The header text color */
        headerTextColor: '#ffffff',
        /* The header background color */
        headerBackgroundColor: '#9ac93e',
        /* The border */
        border: false,
        /* A filter function to filter by */
        filter: null,
        /* Show the assigned activities */
        activities: false,
        /* Trigger to load the Businessarea with that table */
        loadBusinessarea: false,
        /* Show sidestep top most / tree below related node */
        showSidestep: false, // false, tree, topmost
        /* Exports */
        exports: [], //  excel, pdf
      },
      configuration: {
        fields: [
          {
            entry: {key: 'general', label: '', controlType: 'group', sortable: true}, children: [
              {entry: {key: 'name', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.NAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME', sortable: true}},
              { entry: { key: 'risk', type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.RISK', options: [
                    { key: 0, value: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE' },
                    { key: 1, value: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW' },
                    { key: 2, value: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM' },
                    { key: 3, value: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH' },
                  ]}}
            ]
          },
          {entry: {key: 'gotoModel', type: 'link', controlType: 'link', label: ''}}
        ]
      }
    }
  },
  accomplishments: {
    dashboard: {
      type: 'table',
      settings: {
        /* Group the elements by levels */
        groupByLevels: false,
        /* Show total row after level group */
        showTotalsAfterLevel: false,
        /* Show total row at the end of the table */
        showTotals: false,
        /* Group the elements by model */
        groupByModels: false,
        /* Use a thousand seperator for numeric values */
        thousandsSeperator: '.',
        /* How many decimals should be shown on numeric values */
        decimalnumbers: 2,
        /* Show top most header row (grouped heading) */
        showHeading: false,
        /* Show table fields */
        showFields: true,
        /* Different source then nodes */
        source: '', // '', models, activities
        /* Caption element above the table */
        caption: null,
        /* The header text color */
        headerTextColor: '#ffffff',
        /* The header background color */
        headerBackgroundColor: '#9ac93e',
        /* The border */
        border: false,
        /* A filter function to filter by */
        filter: null,
        /* Show the assigned activities */
        activities: false,
        /* Trigger to load the Businessarea with that table */
        loadBusinessarea: false,
        /* Show sidestep top most / tree below related node */
        showSidestep: false, // false, tree, topmost
        /* Exports */
        exports: [], //  excel, pdf
      },
      configuration: {
        fields: [
          {
            entry: {key: 'general', label: '', controlType: 'group', sortable: true}, children: [
              {entry: {key: 'name', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.NAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME', sortable: true}}
            ]
          },
          {entry: {key: 'gotoModel', type: 'link', controlType: 'link', label: ''}}
        ]
      }
    },
    sidebar: {
      type: 'table',
      settings: {
        /* Group the elements by levels */
        groupByLevels: false,
        /* Show total row after level group */
        showTotalsAfterLevel: false,
        /* Show total row at the end of the table */
        showTotals: false,
        /* Group the elements by model */
        groupByModels: false,
        /* Use a thousand seperator for numeric values */
        thousandsSeperator: '.',
        /* How many decimals should be shown on numeric values */
        decimalnumbers: 2,
        /* Show top most header row (grouped heading) */
        showHeading: false,
        /* Show table fields */
        showFields: true,
        /* Different source then nodes */
        source: '', // '', models, activities
        /* Caption element above the table */
        caption: null,
        /* The header text color */
        headerTextColor: '#ffffff',
        /* The header background color */
        headerBackgroundColor: '#9ac93e',
        /* The border */
        border: false,
        /* A filter function to filter by */
        filter: null,
        /* Show the assigned activities */
        activities: false,
        /* Trigger to load the Businessarea with that table */
        loadBusinessarea: false,
        /* Show sidestep top most / tree below related node */
        showSidestep: false, // false, tree, topmost
        /* Exports */
        exports: [], //  excel, pdf
      },
      configuration: {
        fields: [
          {
            entry: {key: 'general', label: '', controlType: 'group', sortable: true}, children: [
              {entry: {key: 'name', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.NAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME', sortable: true}}
            ]
          },
          {entry: {key: 'gotoModel', type: 'link', controlType: 'link', label: ''}}
        ]
      }
    }
  },
  emailtodos: {
    dashboard: {
      type: 'table',
      settings: {
        /* Group the elements by levels */
        groupByLevels: false,
        /* Show total row after level group */
        showTotalsAfterLevel: false,
        /* Show total row at the end of the table */
        showTotals: false,
        /* Group the elements by model */
        groupByModels: false,
        /* Use a thousand seperator for numeric values */
        thousandsSeperator: '.',
        /* How many decimals should be shown on numeric values */
        decimalnumbers: 2,
        /* Show top most header row (grouped heading) */
        showHeading: false,
        /* Show table fields */
        showFields: true,
        /* Different source then nodes */
        source: '', // '', models, activities
        /* Caption element above the table */
        caption: null,
        /* The header text color */
        headerTextColor: '#ffffff',
        /* The header background color */
        headerBackgroundColor: '#9ac93e',
        /* The border */
        border: false,
        /* A filter function to filter by */
        filter: null,
        /* Show the assigned activities */
        activities: false,
        /* Trigger to load the Businessarea with that table */
        loadBusinessarea: false,
        /* Show sidestep top most / tree below related node */
        showSidestep: false, // false, tree, topmost
        /* Exports */
        exports: [], //  excel, pdf
      },
      configuration: {
        fields: [
          {
            entry: {key: 'general', label: '', controlType: 'group' }, children: [
              { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'WIZARD.EMAILTODO.ADD.NAME', maxLength: 255, validations: [ 'required' ], validationMessages: { required: 'DATASHEET.VALIDATION.REQUIRED.NAME' } }},
              { entry: { key: 'targetDate', type: 'datetime', controlType: 'date', label: 'WIZARD.EMAILTODO.ADD.DUEDATE' }},
              { entry: { key: 'description', controlType: 'text', label: 'WIZARD.EMAILTODO.ADD.MESSAGE' }}
            ]
          }
        ]
      }
    },
    sidebar: {
      type: 'table',
      settings: {
        /* Group the elements by levels */
        groupByLevels: false,
        /* Show total row after level group */
        showTotalsAfterLevel: false,
        /* Show total row at the end of the table */
        showTotals: false,
        /* Group the elements by model */
        groupByModels: false,
        /* Use a thousand seperator for numeric values */
        thousandsSeperator: '.',
        /* How many decimals should be shown on numeric values */
        decimalnumbers: 2,
        /* Show top most header row (grouped heading) */
        showHeading: false,
        /* Show table fields */
        showFields: true,
        /* Different source then nodes */
        source: '', // '', models, activities
        /* Caption element above the table */
        caption: null,
        /* The header text color */
        headerTextColor: '#ffffff',
        /* The header background color */
        headerBackgroundColor: '#9ac93e',
        /* The border */
        border: false,
        /* A filter function to filter by */
        filter: null,
        /* Show the assigned activities */
        activities: false,
        /* Trigger to load the Businessarea with that table */
        loadBusinessarea: false,
        /* Show sidestep top most / tree below related node */
        showSidestep: false, // false, tree, topmost
        /* Exports */
        exports: [], //  excel, pdf
      },
      configuration: {
        fields: [
          {
            entry: {key: 'general', label: '', controlType: 'group' }, children: [
              { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'WIZARD.EMAILTODO.ADD.NAME', maxLength: 255, validations: [ 'required' ], validationMessages: { required: 'DATASHEET.VALIDATION.REQUIRED.NAME' } }},
              { entry: { key: 'targetDate', type: 'datetime', controlType: 'date', label: 'WIZARD.EMAILTODO.ADD.DUEDATE' }},
              { entry: { key: 'description', controlType: 'text', label: 'WIZARD.EMAILTODO.ADD.MESSAGE' }}
            ]
          }
        ]
      }
    }
  },
};
