import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { TreeNode } from '../../../core/interface/core.interface';

import { isNullOrUndefined } from 'util';
import { Datum } from '../../../shared/utilities/datum';

export class ColorLabelProviderServiceBasic extends ColorLabelProviderAbstract {

  public label(element: TreeNode, key: any) {
    let upper = key.toUpperCase();
    if (upper === 'BUDGET') {
      upper = 'KPI';
    }
    const pre = this.ignore.indexOf(key) === -1 ? this.translateService.instant('RADIAL.DEFAULT.DISPLAY.LABEL.TOP.' + upper) + ': ' : '';
    if (!isNullOrUndefined(element[key])) {
      let result = element[key];
      if (typeof result === 'number') {
        result = result.toFixed(2);
      }
      if (!isNullOrUndefined(result) && key === 'targetDate') {
        result = new Datum(element[key]).toDateString();
      }
      if (isNullOrUndefined(result)) {
        result = '';
      }
      return pre + result;
    } else {
      return pre + super.label(element, key);
    }
  }

}
