import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { SubsetAction } from './subsets.actions';
import { API, JSONAPIResponse, ISubset } from '../connector';
import { Action } from '../shared/common.action';

@Injectable()
export class SubsetEffects {

  private concurrency = 5;

  @Effect() findSubsetsByModel$ = this.actions$
    .pipe(ofType(SubsetAction.LOAD))
    .mergeMap((action: Action) => this.api.model(action.payload.modelId).subsets.findAll()
      .mergeMap((response: JSONAPIResponse<ISubset[]>) => {
        return Observable.from([
          this.subsetAction.loadSuccess(response.toPayload())
        ]);
      })
    ).catch((error: any) => Observable.of(this.subsetAction.loadFail(error)));

  @Effect() createSubset$ = this.actions$
    .pipe(ofType(SubsetAction.CREATE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.data.map(payload => {
        const data = payload.data.toJS();
        delete data.id;
        return this.api.model(action.payload.modelId).subsets.create(data)
          .mergeMap(response => Observable.from([
            this.subsetAction.createSuccess(payload, response.toPayload())
          ]))
          .catch((error: any) => Observable.of(this.subsetAction.createFail(payload, error)));
    })))
    .mergeAll(this.concurrency);

  @Effect() removeSubset$ = this.actions$
    .pipe(ofType(SubsetAction.REMOVE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.data.map(payload => this.api.subsets.remove(payload)
        .mergeMap(response => Observable.from([
          this.subsetAction.removeSuccess(payload, response.toPayload())
        ]))
        .catch((error: any) => Observable.of(this.subsetAction.removeFail(payload, error)))
    )))
    .mergeAll(this.concurrency);

  @Effect() updateSubset$ = this.actions$
    .pipe(ofType(SubsetAction.UPDATE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.map(payload => this.api.subsets.update(payload.id, payload.data)
      .mergeMap(response => Observable.from([
        this.subsetAction.updateSuccess(payload, response.toPayload())
      ]))
      .catch((error: any) => Observable.of(this.subsetAction.updateFail(payload, error)))
    )))
    .mergeAll(this.concurrency);

  constructor(private api: API,
              private actions$: Actions,
              private subsetAction: SubsetAction) {
  }
}
