import { Injectable, OnDestroy } from '@angular/core';
import { List, Map, OrderedMap } from 'immutable';
import { isNullOrUndefined, isNumber } from 'util';
import { UUID } from 'angular2-uuid';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { SubscriptionService } from '../../utilities/subscription';
import { Instance, InstanceService } from '../../api/instances';
import { INSTANCE_TYPE_LIBRARY } from '../../api/instances/instances.models';
import { TreeNode } from '../../../working/shared/tree/tree.node';
import { TreeRelationship } from '../../../working/shared/tree/tree.relationship';
import { Businessarea, BusinessareaAction, BusinessareaService } from '../../api/businessareas';
import { IPayload } from '../../api/shared';
import { Utilities } from '../../utilities/utilities';
import { Model, ModelAction, ModelService } from '../../api/models';
import { Node, NodeCreate, NodeService } from '../../api/nodes';
import { NODES_TYPE_LIBRARY } from '../../api/nodes/nodes.models';
import { RelationshipCreate, RelationshipService } from '../../api/relationships';
import { RELATIONSHIP_TYPE_DEFAULT } from '../../api/relationships/relationships.models';
import { ApiService } from '../../utilities/api.service';
import { Tree } from '../../../working/shared/tree/tree';
import { ColorLabelProvider } from '../../../working/shared/colorlabelprovider/colorlabelprovider.service';
import { ApiService as Api } from '../../api/api.service';
import { AppShared } from '../../../app.shared';

@Injectable()
export class LibraryService implements OnDestroy {

  public ready = new BehaviorSubject(false);

  private subscriptionService = new SubscriptionService();
  private library: Instance;

  public constructor(public api: Api,
                     private instanceService: InstanceService,
                     private businessareaService: BusinessareaService,
                     private modelService: ModelService,
                     private nodeService: NodeService,
                     private relationshipService: RelationshipService,
                     private apiService: ApiService,
                     private appShared: AppShared,
                     private translateService: TranslateService) {
    this.subscriptionService.add('instances', this.instanceService.all().subscribe(instances => this.onInstancesLoaded(instances)));
    this.instanceService.loadAll();
  }

  public ngOnDestroy(): void {
    this.subscriptionService.remove();
  }

  public getWorkflow(businessAreaType, modelType): Tree {
    return new Tree()
      .appShared(this.appShared)
      .subLevel(true)
      .colorLabelProvider(new ColorLabelProvider(this.translateService))
      .addListener('data', this.getBusinessArea('type', businessAreaType).filter(_ => !!_).mergeMap(businessArea => {
      return this.getModel(businessArea, 'type', modelType).map(model => ({ businessArea: businessArea, model: model })).mergeMap(data => {
        this.modelService.mass(data.model.id);
        return this.api.getModelNodeRelationshipData(data.model.id);
      });
    }));
  }

  public add(area: string, category: string, nodeData: Map<string, any>, treeNodes: TreeNode[], treeRelationships: TreeRelationship[]) {
    this.getBusinessArea('crossReference', area).filter(_ => !!_).take(1).mergeMap(businessArea => {
      return this.getModel(businessArea, 'crossReference', category).take(1).map(model => ({ businessArea: businessArea, model: model }));
    }).subscribe(data => {
      /* The relationship array */
      const relationships = [];
      /* First create the node to be the top most node */
      const libraryNode = new NodeCreate({
        id: UUID.UUID(),
        level: 0,
        positionX: 100,
        nodeType: NODES_TYPE_LIBRARY,
        name: nodeData.get('name'),
        description: nodeData.get('description'),
        modelId: data.model.id
      });
      /* Add the node to the array */
      const nodes: any[] = [libraryNode];
      /* Store a map between UUID and original id */
      let idMap = Map<string, string>();
      /* Copy the other nodes */
      treeNodes.forEach(treeNode => {
        let node = treeNode.node;
        node = <Node> node
          .set('id', UUID.UUID())
          .set('structure_duplicate_original_id', parseInt(treeNode.id))
          .set('data_duplicate_original_id', parseInt(treeNode.node.relationships.nodedata));
        /* Add to map */
        idMap = idMap.set(treeNode.id, node.id);
        /* Figure out if the node is a standalone node */
        if (treeRelationships.filter(treeRelationship => treeRelationship.child === treeNode.id).length === 0) {
          relationships.push(new RelationshipCreate({
            id: UUID.UUID(),
            weight: 1,
            type: RELATIONSHIP_TYPE_DEFAULT,
            parent: libraryNode.id,
            child: node.id,
            model: data.model.id
          }));
        }
        /* Add the node to array */
        nodes.push(node);
      });
      /* Now create all the relationships */
      treeRelationships.forEach(treeRelationship => {
        if (idMap.has(treeRelationship.parent) && idMap.has(treeRelationship.child)) {
          relationships.push(new RelationshipCreate({
            id: UUID.UUID(),
            weight: isNumber(treeRelationship.weight) ? treeRelationship.weight : treeRelationship.weight.value,
            parent: idMap.get(treeRelationship.parent),
            child: idMap.get(treeRelationship.child),
            model: data.model.id
          }));
        }
      });
      /* Now send everything to the api service */
      this.apiService.createTree(nodes, relationships, data.model.id);
    });
  }

  private getBusinessArea(type: string, value: any): Observable<Businessarea> {
    return this.businessareaService.findByInstance(this.library.id).filter(_ => !!_ && _.size > 0).take(1).mergeMap(businessAreas => {
      const existing = businessAreas.filter(businessArea => businessArea[type] === value);
      if (existing.size > 0) {
        return new BehaviorSubject(existing.first());
      }
      const d = { name: value, color: Utilities.randomHexGenerator() };
      d[type] = value;
      this.businessareaService.create(this.library.id, <IPayload> {
        id: UUID.UUID(),
        data: new Businessarea(d)
      });
      return this.businessareaService.diff.filter(diff => !!diff.action && diff.action === BusinessareaAction.CREATE_SUCCESS).mergeMap(diff => {
        return <Observable<Businessarea>> this.businessareaService.find(diff.response[0].id);
      });
    });
  }

  private getModel(businessArea: Businessarea, type: string, value: any): Observable<Model> {
    return this.modelService.findByBusinessarea(businessArea.id).filter(d => d.size > 0).take(1).mergeMap(models => {
      const existing = models.filter(model => model[type] === value);
      if (existing.size > 0) {
        return new BehaviorSubject(existing.first());
      }
      const d = { name: value, color: Utilities.randomHexGenerator() };
      d[type] = value;
      this.modelService.createOnBusinessarea(businessArea.id, <IPayload> {
        id: UUID.UUID(),
        data: new Model(d)
      });
      return this.modelService.diff.filter(diff => !!diff.action && diff.action === ModelAction.CREATE_SUCCESS).mergeMap(diff => {
        return <Observable<Model>> this.modelService.find(diff.response.id);
      });
    });
  }

  private onInstancesLoaded(instances: List<Instance>) {
    this.ready.next(false);
    instances.forEach(instance => {
      if (instance.type === INSTANCE_TYPE_LIBRARY) {
        this.library = instance;
        this.instanceService.load(instance.id, ['businessareas', 'models']);
        this.ready.next(true);
      }
    });
  }

}
