import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { TreeNode } from '../../../core/interface/core.interface';
import { Datum } from '../../../shared/utilities/datum';
import { isNullOrUndefined } from 'util';

export class ColorLabelProviderServicePlanned extends ColorLabelProviderAbstract {

  private static UNPLANNED = '#e50000';
  private static PLANNED = '#9ac93e';

  public color(element: TreeNode) {
    return (!isNullOrUndefined(element.startDate) && !isNullOrUndefined(element.targetDate)) ? [ColorLabelProviderServicePlanned.PLANNED] : [ColorLabelProviderServicePlanned.UNPLANNED];
  }

  public legend() {
    return [
      { key: 'planned.unplanned', color: ColorLabelProviderServicePlanned.UNPLANNED, label: 'LEGEND.PLANNED.UNPLANNED', field: 'planned', value: 0 },
      { key: 'planned.planned', color: ColorLabelProviderServicePlanned.PLANNED, label: 'LEGEND.PLANNED.PLANNED', field: 'planned', value: 1 }
    ];
  }

}
