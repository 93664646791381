import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as selectors from './my.selectors';
import { MyAction } from './my.actions';
import { SharedService } from '../shared/shared.service';
import { AppState } from '../../app.reducer';

@Injectable()
export class MyService extends SharedService {

  public constructor(protected store: Store<AppState>, protected action: MyAction) {
    super(store, action, selectors);
  }

  public findMyNodes(businessAreaId: string): void {
    this.store.dispatch(this.action.find(businessAreaId));
  }

}
