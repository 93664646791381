import { IAudit } from '../connector';
import { isNullOrUndefined } from 'util';
import { IPayload } from '../shared';

export class AuditAction {
  static key = 'Audit';

  static LOAD = `[${AuditAction.key}] Load`;
  static LOAD_SUCCESS = `[${AuditAction.key}] Load success`;
  static LOAD_FAIL = `[${AuditAction.key}] Load fail`;

  public load(type: string, ids: string[], fromDate?: number, toDate?: number, limit?: number) {
    const payload = { query: {} };
    /* Set the query */
    payload.query[type] = ids;
    if (!isNullOrUndefined(fromDate)) {
      payload['fromDate'] = fromDate;
    }
    if (!isNullOrUndefined(toDate)) {
      payload['toDate'] = toDate;
    }
    if (!isNullOrUndefined(limit)) {
      payload['limit'] = limit;
    }
    return {
      type: AuditAction.LOAD,
      payload: payload
    };
  }

  public loadSuccess(request: IPayload, data: IAudit | IAudit[]) {
    return {
      type: AuditAction.LOAD_SUCCESS,
      payload: {
        request: request,
        response: data
      }
    };
  }

  public loadFail(error: any) {
    return {
      type: AuditAction.LOAD_FAIL,
      payload: {
        request: undefined,
        response: error
      }
    };
  }
}
