import { Record, Set, Map } from 'immutable';
import { RecordFactory, RequestDiffRecord } from '../shared';
import { IVersion, IVersionAttributes } from '../connector';

const versionStateRecord = Record({
  ids: Set<string>(),
  tempIdsMap: Map<string, string>(),
  entities: Map<string, Version>(),
  isBusy: 0,
  diff: new RequestDiffRecord()
});

export class VersionState extends versionStateRecord {
  ids: Set<string>;
  entities: Map<string, Version>;
  relationships: undefined;
  isBusy: number;
  diff: RequestDiffRecord;
}

const versionRecord = Record(<IVersionAttributes>{
  id: undefined,
  modelId: undefined,
  description: undefined,
  createdAt: undefined,
  updatedAt: undefined,
});

export class Version extends versionRecord implements IVersionAttributes {
  id: number;
  modelId: string;
  description: string;
  createdAt: string;
  updatedAt: string;

  static fromJSONAPI(version: IVersion) {
    return RecordFactory.entity.fromJSONAPI<Version>(Version, version);
  }
}
