import { Injectable } from '@angular/core';

import { Action } from '../shared/common.action';

@Injectable()
export class WorkflowAction {

  static key = 'Workflow';

  static LOAD = `[${WorkflowAction.key}] Load`;
  static LOAD_SUCCESS = `[${WorkflowAction.key}] Load success`;
  static LOAD_FAIL = `[${WorkflowAction.key}] Load fail`;

  static LOAD_ALL = `[${WorkflowAction.key}] Load all`;
  static LOAD_ALL_SUCCESS = `[${WorkflowAction.key}] Load all success`;
  static LOAD_ALL_FAIL = `[${WorkflowAction.key}] Load all fail`;

  public load(id: string): Action {
    return {
      type: WorkflowAction.LOAD,
      payload: {
        id: id
      }
    };
  }

  public loadSuccess(data: any[]) {
    return {
      type: WorkflowAction.LOAD_SUCCESS,
      payload: {
        response: data
      }
    };
  }

  public loadFail(error: any) {
    return {
      type: WorkflowAction.LOAD_FAIL,
      payload: {
        request: undefined,
        response: error
      }
    };
  }

  public loadAll(id: string): Action {
    return {
      type: WorkflowAction.LOAD_ALL,
      payload: {
        id: id
      }
    };
  }

  public loadAllSuccess(data: any[]) {
    return {
      type: WorkflowAction.LOAD_ALL_SUCCESS,
      payload: {
        response: data
      }
    };
  }

  public loadAllFail(error: any) {
    return {
      type: WorkflowAction.LOAD_ALL_FAIL,
      payload: {
        request: undefined,
        response: error
      }
    };
  }
}
