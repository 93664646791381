import { TranslateService } from '@ngx-translate/core';
import { List, OrderedMap } from 'immutable';

import { HumanResource } from '../../../shared/api/humanresources';
import { Model } from '../../../shared/api/models/models.models';
import { Subset } from '../../../shared/api/subsets';
import { Activity } from '../../../shared/api/activities';

import { ColorLabelProviderServiceBasic } from './providers/colorlabelprovider.service.basic';
import { ColorLabelProviderServiceStatus } from './providers/colorlabelprovider.service.status';
import { ColorLabelProviderServiceDeviation } from './providers/colorlabelprovider.service.deviation';
import { ColorLabelProviderServiceProfit } from './providers/colorlabelprovider.service.profit';
import { ColorLabelProviderServiceTargetDate } from './providers/colorlabelprovider.service.targetdate';
import { ColorLabelProviderServiceImportance } from './providers/colorlabelprovider.service.importance';
import { ColorLabelProviderServiceResponsible } from './providers/colorlabelprovider.service.responsible';
import { ColorLabelProviderServiceActivityStatus } from './providers/colorlabelprovider.service.activity.status';
import { ColorLabelProviderServiceActivityType } from './providers/colorlabelprovider.service.activity.type';
import { ColorLabelProviderServiceActivityDefault } from './providers/colorlableprovider.service.activity.default';
import { ColorLabelProviderServiceModels } from './providers/colorlabelprovider.service.models';
import { ColorLabelProviderServiceSubModels } from './providers/colorlabelprovider.service.submodels';
import { ColorLabelProviderServiceSubSets } from './providers/colorlabelprovider.service.subsets';
import { ColorLabelProviderServiceSidestep } from './providers/colorlabelprovider.service.sidestep';
import { ColorLabelProviderServiceTodoStatus } from './providers/colorlabelprovider.service.todo.status';
import { ColorLabelProviderServiceDifference } from './providers/colorlabelprovider.service.difference';
import { ColorLabelProviderServiceRisk } from './providers/colorlabelprovider.service.risk';
import { ColorLabelProviderServiceQStatus } from './providers/colorlabelprovider.service.qstatus';
import { ColorlabelproviderServiceRelatedstatus } from './providers/colorlabelprovider.service.relatedstatus';
import { NodeData } from '../../../shared/api/nodedata';
import { ColorLabelProviderNodesType } from './providers/colorlabelprovider.service.nodestype';
import { TreeNode } from '../tree/tree.node';
import { ColorLabelProviderNodeTypes } from './providers/colorlabelprovider.service.nodetypes';
import { ColorLabelProviderLevels } from './providers/colorlabelprovider.service.levels';

export class ColorLabelProvider {

  public constructor(private translateService: TranslateService) {}

  public basic() {
    return new ColorLabelProviderServiceBasic(this.translateService);
  }

  public status() {
    return new ColorLabelProviderServiceStatus(this.translateService);
  }

  public deviation() {
    return new ColorLabelProviderServiceDeviation(this.translateService);
  }

  public profit() {
    return new ColorLabelProviderServiceProfit(this.translateService);
  }

  public sidestep() {
    return new ColorLabelProviderServiceSidestep();
  }

  public targetDate() {
    return new ColorLabelProviderServiceTargetDate();
  }

  public models(models: List<Model>) {
    return new ColorLabelProviderServiceModels(models);
  }

  public submodels(subModels: List<Model>) {
   return new ColorLabelProviderServiceSubModels(subModels);
  }

  public subsets(subSets: List<Subset>) {
    return new ColorLabelProviderServiceSubSets(subSets);
  }

  public responsible(responsible: List<HumanResource>) {
    return new ColorLabelProviderServiceResponsible(responsible);
  }

  public difference() {
    return new ColorLabelProviderServiceDifference();
  }

  public todostatus(activities: List<Activity>) {
    return new ColorLabelProviderServiceTodoStatus(activities);
  }

  public importance() {
    return new ColorLabelProviderServiceImportance();
  }

  public risk() {
    return new ColorLabelProviderServiceRisk();
  }

  public qstatus() {
    return new ColorLabelProviderServiceQStatus();
  }

  public relatedstatus(nodeData: OrderedMap<string, NodeData>) {
    return new ColorlabelproviderServiceRelatedstatus(nodeData);
  }

  public nodestype(params: any, models: List<Model>, subModels: List<Model>, subSets: List<Subset>, responsible: List<HumanResource>, activities: List<Activity>, nodeData: OrderedMap<string, NodeData>, nodes: OrderedMap<string, TreeNode>) {
    return new ColorLabelProviderNodesType(params, models, subModels, subSets, responsible, activities, nodeData, nodes, this);
  }

  public nodetypes(params) {
    return new ColorLabelProviderNodeTypes(params);
  }

  public levels() {
    return new ColorLabelProviderLevels();
  }

  public activitystatus() {
    return new ColorLabelProviderServiceActivityStatus();
  }

  public activitytype() {
    return new ColorLabelProviderServiceActivityType();
  }

  public activitydefault() {
    return new ColorLabelProviderServiceActivityDefault();
  }

}
