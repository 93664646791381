export const TemplateFieldsReportsChangeIT = [
  {
    type: 'table',
    settings: {
      /* Group the elements by levels */
      groupByLevels: true,
      /* Show the footer */
      showFooter: true,
      /* Break the collapsing on level border */
      breakCollapseOnLevel: false,
      /* Show top most header row (grouped heading) */
      showHeading: true,
      /* Exports */
      exports: ['excel', 'pdf'], //  excel, pdf
      /* The header background color */
      headerBackgroundColor: '#9ac93e',
      /* The header text color */
      headerTextColor: '#ffffff',
      /* Color label provider */
      colorLabelProvider: 'relatedstatus' // boolean, string
    },
    configuration: {
      fields: [
        { entry: { key: 'structure', label: 'TABLE.STRUCTURE.TITLE', displayLabel: false, controlType: 'structure', shape: 'ellipsis-v', additional: { addchild: true, addsibling: true, edit: true, delete: true, create: true, unlink: true, changeparent: true, link: true } }},
        { entry: { key: 'wizard', label: '', controlType: 'wizard', source: 'email-todo', icon: 'envelope', editable: true, fixedWidth: 30 }},
        { entry: { key: 'wizard', label: '', controlType: 'wizard', source: 'hoshin-kanri-edit', icon: 'project-diagram', editable: true, fixedWidth: 30 }},
        { entry: { key: 'levels', label: 'TABLE.LEVELS', controlType: 'levels', source: 'name' }},
        { entry: { key: 'general', label: 'REPORTS.GENERAL.GROUP', controlType: 'group'}, children: [
          { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'REPORTS.CHANGEIT.NAME', maxLength: 255, validations: [ 'required' ], sortable: true, validationMessages: { required: 'DATASHEET.VALIDATION.REQUIRED.NAME' } }},
          { entry: { key: 'creationDate', type: 'datetime', controlType: 'date', label: 'REPORTS.CHANGEIT.CREATIONDATE', sortable: true }},
          { entry: { key: 'status', controlType: 'dropdown', label: 'REPORTS.CHANGEIT.STATUS.TITLE', sortable: true, filter: true, options: [
            { key: 0, value: 'REPORTS.CHANGEIT.STATUS.PENDING' },
            { key: 100, value: 'REPORTS.CHANGEIT.STATUS.INDEFINE' },
            { key: 110, value: 'REPORTS.CHANGEIT.STATUS.INDEVELOP' },
            { key: 120, value: 'REPORTS.CHANGEIT.STATUS.INVERIFY' },
            { key: 130, value: 'REPORTS.CHANGEIT.STATUS.INACCEPT' },
            { key: 200, value: 'REPORTS.CHANGEIT.STATUS.COMPLETED' },
            { key: 6, value: 'REPORTS.CHANGEIT.STATUS.SIX' },
            { key: 7, value: 'REPORTS.CHANGEIT.STATUS.SEVEN' },
            { key: 8, value: 'REPORTS.CHANGEIT.STATUS.EIGHT' },
            { key: 9, value: 'REPORTS.CHANGEIT.STATUS.NINE' },
            { key: 10, value: 'REPORTS.CHANGEIT.STATUS.TEN' },
            { key: 11, value: 'REPORTS.CHANGEIT.STATUS.ELEVEN' },
            { key: 12, value: 'REPORTS.CHANGEIT.STATUS.TWELFE' },
            { key: 13, value: 'REPORTS.CHANGEIT.STATUS.THIRTEEN' },
            { key: 14, value: 'REPORTS.CHANGEIT.STATUS.FOURTEEN' },
            { key: 15, value: 'REPORTS.CHANGEIT.STATUS.FIFTEEN' },
            { key: 16, value: 'REPORTS.CHANGEIT.STATUS.SIXTEEN' },
            { key: 17, value: 'REPORTS.CHANGEIT.STATUS.SEVENTEEN' },
            { key: 18, value: 'REPORTS.CHANGEIT.STATUS.EIGHTEEN' },
            { key: 19, value: 'REPORTS.CHANGEIT.STATUS.NINETEEN' },
            { key: 20, value: 'REPORTS.CHANGEIT.STATUS.TWENTY' },
            { key: 21, value: 'REPORTS.CHANGEIT.STATUS.TWENTYONE' },
            { key: 22, value: 'REPORTS.CHANGEIT.STATUS.TWENTYTWO' },
            { key: 23, value: 'REPORTS.CHANGEIT.STATUS.TWENTYTHREE' },
            { key: 24, value: 'REPORTS.CHANGEIT.STATUS.TWENTYFOUR' },
            { key: 25, value: 'REPORTS.CHANGEIT.STATUS.TWENTYFIVE' },
            { key: 26, value: 'REPORTS.CHANGEIT.STATUS.TWENTYSIX' },
            { key: 27, value: 'REPORTS.CHANGEIT.STATUS.TWENTYSEVEN' },
            { key: 28, value: 'REPORTS.CHANGEIT.STATUS.TWENTYEIGHT' },
            { key: 29, value: 'REPORTS.CHANGEIT.STATUS.TWENTYNINE' },
            { key: 30, value: 'REPORTS.CHANGEIT.STATUS.THIRTY' },
            { key: 31, value: 'REPORTS.CHANGEIT.STATUS.THIRTYONE' },
            { key: 32, value: 'REPORTS.CHANGEIT.STATUS.THIRTYTWO' },
            { key: 33, value: 'REPORTS.CHANGEIT.STATUS.THIRTYTHREE' },
            { key: 34, value: 'REPORTS.CHANGEIT.STATUS.THIRTYFOUR' },
            { key: 35, value: 'REPORTS.CHANGEIT.STATUS.THIRTYFIVE' },
            { key: 36, value: 'REPORTS.CHANGEIT.STATUS.THIRTYSIX' },
            { key: 37, value: 'REPORTS.CHANGEIT.STATUS.THIRTYSEVEN' },
            { key: 38, value: 'REPORTS.CHANGEIT.STATUS.THIRTYEIGHT' },
            { key: 39, value: 'REPORTS.CHANGEIT.STATUS.THIRTYNINE' },
          ]}},
          { entry: { key: 'executiveDescription', type: 'text', controlType: 'textbox', label: 'REPORTS.CHANGEIT.EXECUTIVEDESCRIPTION', maxLength: 255, sortable: true, filter: true }},
          { entry: { key: 'description', type: 'text', controlType: 'textbox', label: 'REPORTS.CHANGEIT.DESCRIPTION', maxLength: 255, sortable: true, filter: true }},
          { entry: { key: 'description1', controlType: 'text', label: 'REPORTS.CHANGEIT.DESCRIPTION1'}},
          { entry: { key: 'description2', controlType: 'text', label: 'REPORTS.CHANGEIT.DESCRIPTION2'}},
          { entry: { key: 'description3', controlType: 'text', label: 'REPORTS.CHANGEIT.DESCRIPTION3'}},
          { entry: { key: 'description4', controlType: 'text', label: 'REPORTS.CHANGEIT.DESCRIPTION4'}},
          { entry: { key: 'businessBenefit', type: 'number', controlType: 'dropdown', label: 'REPORTS.CHANGEIT.BUSINESSBENEFIT.TITLE', sortable: true, filter: true, options: [
            { key: 0, value: 'REPORTS.CHANGEIT.BUSINESSBENEFIT.NOTAPPLICABLE' },
            { key: 1, value: 'REPORTS.CHANGEIT.BUSINESSBENEFIT.LOW' },
            { key: 2, value: 'REPORTS.CHANGEIT.BUSINESSBENEFIT.MEDIUM' },
            { key: 3, value: 'REPORTS.CHANGEIT.BUSINESSBENEFIT.HIGH' },
          ]}},
          { entry: { key: 'sizeType', type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.SIZETYPE', sortable: true, filter: true, options: [
            { key: 0, value: 'REPORTS.CHANGEIT.BUSINESSBENEFIT.NOTAPPLICABLE' },
            { key: 1, value: 'REPORTS.CHANGEIT.BUSINESSBENEFIT.LOW' },
            { key: 2, value: 'REPORTS.CHANGEIT.BUSINESSBENEFIT.MEDIUM' },
            { key: 3, value: 'REPORTS.CHANGEIT.BUSINESSBENEFIT.HIGH' },
          ]}},
          { entry: { key: 'time', controlType: 'dropdown', label: 'REPORTS.CHANGEIT.TIME.TITLE', sortable: true, filter: true, options: [
            { key: 0, value: 'REPORTS.CHANGEIT.TIME.APPROACH' },
            { key: 1, value: 'REPORTS.CHANGEIT.TIME.PROJECT' }
          ]}},
          { entry: { key: 'priority', controlType: 'dropdown', label: 'REPORTS.CHANGEIT.PRIORITY', sortable: true, filter: true, options: [
            { key: 0, value: 'REPORTS.CHANGEIT.CALCULATED.NOTAPPLICABLE' },
            { key: 1, value: 'REPORTS.CHANGEIT.CALCULATED.LOW' },
            { key: 2, value: 'REPORTS.CHANGEIT.CALCULATED.MEDIUM' },
            { key: 3, value: 'REPORTS.CHANGEIT.CALCULATED.HIGH' },
            { key: 4, value: 'REPORTS.CHANGEIT.CALCULATED.POSTPONED' }
          ]}},
          { entry: { key: 'benefitBudget', type: 'number', controlType: 'textbox', defaultValue: 0, label: 'REPORTS.CHANGEIT.BENEFITBUDGET', sortable: true }},
          { entry: { key: 'currency', type: 'text', controlType: 'textbox', label: 'REPORTS.CHANGEIT.CURRENCY', maxLength: 255 }},
          { entry: { key: 'costBudget', type: 'number', controlType: 'textbox', defaultValue: 0, label: 'REPORTS.CHANGEIT.COSTBUDGET', sortable: true }},
          { entry: { key: 'currency', type: 'text', controlType: 'textbox', label: 'REPORTS.CHANGEIT.CURRENCY', maxLength: 255 }},
          { entry: { key: 'investBudget', type: 'number', controlType: 'textbox', defaultValue: 0, label: 'REPORTS.CHANGEIT.INVESTBUDGET', sortable: true }},
          { entry: { key: 'currency', type: 'text', controlType: 'textbox', label: 'REPORTS.CHANGEIT.CURRENCY', maxLength: 255 }},
          { entry: { key: 'program', controlType: 'dropdown', label: 'REPORTS.CHANGEIT.PROGRAM.TITLE', sortable: true, filter: true, options: [
            { key: 0, value: 'REPORTS.CHANGEIT.PROGRAM.NOTAPPLICABLE' },
            { key: 1, value: 'REPORTS.CHANGEIT.PROGRAM.ONE' },
            { key: 2, value: 'REPORTS.CHANGEIT.PROGRAM.TWO' },
            { key: 3, value: 'REPORTS.CHANGEIT.PROGRAM.THREE' },
            { key: 4, value: 'REPORTS.CHANGEIT.PROGRAM.FOUR' },
            { key: 5, value: 'REPORTS.CHANGEIT.PROGRAM.FIVE' },
            { key: 6, value: 'REPORTS.CHANGEIT.PROGRAM.SIX' },
            { key: 7, value: 'REPORTS.CHANGEIT.PROGRAM.SEVEN' },
            { key: 8, value: 'REPORTS.CHANGEIT.PROGRAM.EIGHT' },
            { key: 9, value: 'REPORTS.CHANGEIT.PROGRAM.NINE' },
            { key: 10, value: 'REPORTS.CHANGEIT.PROGRAM.TEN' },
            { key: 11, value: 'REPORTS.CHANGEIT.PROGRAM.ELEVEN' },
            { key: 12, value: 'REPORTS.CHANGEIT.PROGRAM.TWELFE' },
            { key: 13, value: 'REPORTS.CHANGEIT.PROGRAM.THIRTEEN' },
            { key: 14, value: 'REPORTS.CHANGEIT.PROGRAM.FOURTEEN' },
            { key: 15, value: 'REPORTS.CHANGEIT.PROGRAM.FIFTEEN' },
            { key: 16, value: 'REPORTS.CHANGEIT.PROGRAM.SIXTEEN' },
            { key: 17, value: 'REPORTS.CHANGEIT.PROGRAM.SEVENTEEN' },
            { key: 18, value: 'REPORTS.CHANGEIT.PROGRAM.EIGHTEEN' },
            { key: 19, value: 'REPORTS.CHANGEIT.PROGRAM.NINETEEN' }
              ]}},
          { entry: { key: 'organizational', type: 'number', controlType: 'dropdown', label: 'REPORTS.CHANGEIT.ORGANIZATIONAL.TITLE', sortable: true, filter: true, options: [
            { key: 0, value: 'REPORTS.CHANGEIT.ORGANIZATIONAL.NOTAPPLICABLE' },
            { key: 1, value: 'REPORTS.CHANGEIT.ORGANIZATIONAL.LOW' },
            { key: 2, value: 'REPORTS.CHANGEIT.ORGANIZATIONAL.MEDIUM' },
            { key: 3, value: 'REPORTS.CHANGEIT.ORGANIZATIONAL.HIGH' },
          ]}},
          { entry: { key: 'technicalDescription', type: 'text', controlType: 'textbox', label: 'REPORTS.CHANGEIT.TECHNICALDESCRIPTION', maxLength: 255, sortable: true, filter: true }},
          { entry: { key: 'lastUpdated', type: 'datetime', controlType: 'date', label: 'REPORTS.CHANGEIT.LASTUPDATED', sortable: true }},
          { entry: { key: 'startDate', type: 'datetime', controlType: 'date', label: 'REPORTS.CHANGEIT.START', sortable: true}},
          { entry: { key: 'actualStartDate', type: 'datetime', controlType: 'date', label: 'REPORTS.CHANGEIT.STARTACTUAL', sortable: true }},
          { entry: { key: 'costDescription', controlType: 'text', label: 'REPORTS.CHANGEIT.COSTDESCRIPTION' }},
          { entry: { key: 'timeDescription', controlType: 'text', label: 'REPORTS.CHANGEIT.TIMEDESCRIPTION' }},
          { entry: { key: 'targetDate', type: 'datetime', controlType: 'date', label: 'REPORTS.CHANGEIT.TARGETDATE', sortable: true }},
          { entry: { key: 'actualDate', type: 'datetime', controlType: 'date', label: 'REPORTS.CHANGEIT.ACTUALDATE', sortable: true }},
          { entry: { key: 'documentUri', type: 'text', controlType: 'textbox-button', buttonTitle: 'REPORTS.CHANGEIT.OPENDOCUMENTURI', label: 'REPORTS.CHANGEIT.DOCUMENTURI', sortable: true }},
          { entry: { key: 'upload_uri', type: 'text', controlType: 'textbox-button', buttonTitle: 'REPORTS.CHANGEIT.OPENUPLOADURI', label: 'REPORTS.CHANGEIT.UPLOADURI', sortable: true }},
          { entry: { key: 'programDescription', controlType: 'text', label: 'REPORTS.CHANGEIT.PROGRAMDESCRIPTION' }},
          { entry: { key: 'functional', type: 'number', controlType: 'dropdown', label: 'REPORTS.CHANGEIT.FUNCTIONAL.TITLE', sortable: true, filter: true, options: [
            { key: 0, value: 'REPORTS.CHANGEIT.FUNCTIONAL.NOTAPPLICABLE' },
            { key: 1, value: 'REPORTS.CHANGEIT.FUNCTIONAL.LOW' },
            { key: 2, value: 'REPORTS.CHANGEIT.FUNCTIONAL.MEDIUM' },
            { key: 3, value: 'REPORTS.CHANGEIT.FUNCTIONAL.HIGH' },
          ]}}
        ]},
      ]
    }
  }
];
