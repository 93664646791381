import { Utils } from '../shared/utils';
import { Action } from '../shared/common.action';
import { MyState } from './my.models';
import { MyAction } from './my.actions';

export default function reducer(state = new MyState(), action: Action): MyState {

  switch (action.type) {

    case MyAction.FIND: {
      return <MyState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case MyAction.FIND_SUCCESS: {
      return <MyState>Utils.updateDiff<MyState>(state, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case MyAction.FIND_FAIL: {
      return <MyState>Utils.updateDiff<MyState>(state, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    default:
      return state;
  }
}
