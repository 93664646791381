import { EventEmitter, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { isArray, isNullOrUndefined } from 'util';
import { List, Map, Set } from 'immutable';
import { UUID } from 'angular2-uuid';
import { Observable } from 'rxjs/Observable';

import { Node, NodeCreate, NodeService } from '../api/nodes';
import { Relationship, RelationshipAction, RelationshipCreate, RelationshipService } from '../api/relationships';
import { IPayload } from '../api/shared';
import { Utilities } from './utilities';
import { NodeData, NodeDataService } from '../api/nodedata';
import { NodeDataRelationships } from '../api/nodedata/nodedata.models';
import { NodeStructure, NodeStructureAction, NodeStructureRelationships, NodeStructureService } from '../api/nodestructures';
import { AppShared } from '../../app.shared';
import { Subset, SubsetService } from '../api/subsets';
import { Businessarea, BusinessareaAction, BusinessareaService } from '../api/businessareas';
import { Model, ModelAction, ModelService } from '../api/models';
import { Activity, ActivityService } from '../api/activities';
import { HumanResource, HumanResourceAction, HumanResourceService } from '../api/humanresources';
import { PayloadFactory } from '../api/shared/payload-factory';
import { SubscriptionService } from './subscription';
import { TreeNode } from '../../working/shared/tree/tree.node';
import { Screen } from '../../working/splitscreen/screen';
import { ModelCreate } from '../api/models/models.models';
import { Group, GroupAction, GroupService } from '../api';

@Injectable()
export class ApiService {

  readonly MAX_ITEMS = 1000;

  public success = new EventEmitter();
  private subscriptionService = new SubscriptionService();
  private createTreeCalls = 0;

  private nodeDataMap = Map<string, string>();
  private nodeDataNodeStructureMap = Map<string, string>();

  public constructor(protected appShared: AppShared,
    private nodeDataService: NodeDataService,
    private nodeStructureService: NodeStructureService,
    private nodeService: NodeService,
    private relationshipService: RelationshipService,
    private subSetService: SubsetService,
    private businessareaService: BusinessareaService,
    private modelService: ModelService,
    private activityService: ActivityService,
    private groupService: GroupService,
    private humanResourceService: HumanResourceService) {}

  public submit(element: Node | Relationship | Model | Subset | NodeCreate, formGroup: FormGroup, parentElements?: Node[] | Relationship[] | Model[] | Subset[]) {
    if (element instanceof Node) {
      this.handleNode(element, formGroup, <Node[]>parentElements);
    } else if (element instanceof NodeCreate) {
      this.handleNodeCreate(element, <Node[]>parentElements);
    }
  }

  public getBestPositionByTreeNode(treeNode: TreeNode, screen: Screen) {
    const xPosition = treeNode.node.positionX;
    const childrenXPositions = treeNode.children.map(child => child.node.positionX);
    if (childrenXPositions.size === 0) {
      return xPosition;
    }
    return childrenXPositions.sort().last() + screen.nodeHitboxWidth;
  }

  public listen(callback): ApiService {
    this.success.take(1).subscribe(d => callback(d));
    return this;
  }

  private onSuccess(createdNodes: any) {
    this.subscriptionService.remove();
    this.success.emit(createdNodes);
  }

  public createTreeByBusinessarea(nodes: NodeCreate[], relationships: RelationshipCreate[], models: ModelCreate[], businessareas: Businessarea[], instanceId: string) {
    /* actually only first ba is created */
    const businessarea = businessareas[0];
    /* Business area */
    this.subscriptionService.add('businessarea', this.businessareaService.diff.filter(diff => diff.action === BusinessareaAction.CREATE_SUCCESS).subscribe(diff => {
      if (!isNullOrUndefined(diff.payload.id) && !isNullOrUndefined(diff.response.id)) {
        this.createTreeByModels(nodes, relationships, models, diff.response.id);
      }
    }));
    this.businessareaService.create(instanceId, <IPayload>{
      id: businessarea.id,
      data: businessarea
    });
  }

  public createTreeByModels(nodes: NodeCreate[], relationships: RelationshipCreate[], models: ModelCreate[], businessareaId: string) {
    if (models.length === 0) {
      if (nodes.length === 0 && relationships.length === 0) {
        this.onSuccess([]);
      }
      let _models = Map<string, { nodes: NodeCreate[], relationships: RelationshipCreate[] }>();
      nodes.forEach(node => {
        const d = _models.has(node.modelId) ? _models.get(node.modelId) : { nodes: [], relationships: [] };
        d.nodes.push(node);
        _models = _models.set(node.modelId, d);
      });
      relationships.forEach(relationship => {
        const d = _models.has(relationship.model) ? _models.get(relationship.model) : { nodes: [], relationships: [] };
        d.relationships.push(relationship);
        _models = _models.set(relationship.model, d);
      });
      _models.forEach((d: any, id: string) => {
        this.createTree(d.nodes, d.relationships, id);
      });
    } else {
      this.subscriptionService.add('model', this.modelService.diff.filter(diff => diff.action === ModelAction.CREATE_SUCCESS).subscribe(diff => {
        if (!isNullOrUndefined(diff.payload.id) && !isNullOrUndefined(diff.response.id)) {
          const ids = [];
          const _nodes = nodes.filter(node => node.modelId === diff.payload.id).map(node => {
            ids.push(node.id);
            return <NodeCreate>node.set('modelId', diff.response.id);
          });
          const _relationships = relationships.filter(relationship => ids.indexOf(relationship.parent) !== -1 && ids.indexOf(relationship.child) !== -1);
          if (_nodes.length > 0) {
            this.createTreeCalls++;
            this.createTree(_nodes, _relationships, diff.response.id);
          }
        }
      }));
      this.modelService.createOnBusinessarea(businessareaId, PayloadFactory.fromArray(models));
    }
  }

  public createTreeWithHumanResources(data: { nodes: NodeCreate[], relationships: RelationshipCreate[], activities: Activity[], humanResources: HumanResource[], groups: Group[] }, modelId: string) {
    /* Create human resources first */
    this.createResources(data.humanResources.map(h => {
      if (isNullOrUndefined(h.permissions)) {
        h.permissions = [];
      }
      return h;
    }), this.humanResourceService.findByInstanceId(this.appShared.instanceId), this.humanResourceService.diff, HumanResourceAction.CREATE_SUCCESS, 'humanResource', true).then((humanResourceMap: Map<string, string>) => {
      /* Create group */
      this.createResources(data.groups.map(h => {
        if (isNullOrUndefined(h.permissions)) {
          h.permissions = [];
        }
        return h;
      }), this.groupService.all(), this.groupService.diff, GroupAction.CREATE_SUCCESS, 'group', true).then((groupMap: Map<string, string>) => {
        /* Update nodes */
        data.nodes = data.nodes.map(node => {
          /* Set ids */
          this.nodeDataNodeStructureMap = this.nodeDataNodeStructureMap.set(node.id, node.dataId);
          /* Human resources */
          if (node.responsibleId !== 0 && humanResourceMap.has('' + node.responsibleId)) {
            node.responsibleId = parseInt(humanResourceMap.get('' + node.responsibleId));
          }
          /* Wrong default values fix for older versions */
          if (isNullOrUndefined(node.fieldSkipIfExists)) {
            node.fieldSkipIfExists = false;
          }
          /* Groups */
          if (node.groupId !== 0 && groupMap.has('' + node.groupId)) {
            node.groupId = parseInt(groupMap.get('' + node.groupId));
          }
          node.data_duplicate_original_id = null;
          node.structure_duplicate_original_id = null;
          return node;
        });
        /* Create nodes */
        this.createResources(data.nodes, this.nodeService.all(), this.nodeStructureService.diff, NodeStructureAction.CREATE_SUCCESS, 'node', false, modelId).then((nodeStructuresMap: Map<string, string>) => {
          /* Update relationships */
          data.relationships = data.relationships.map(relationship => {
            /* Parent */
            if (nodeStructuresMap.has('' + relationship.parent)) {
              relationship.parent = nodeStructuresMap.get('' + relationship.parent);
            }
            /* Child */
            if (nodeStructuresMap.has('' + relationship.child)) {
              relationship.child = nodeStructuresMap.get('' + relationship.child);
            }
            return relationship;
          });
          /* Create relationships */
          this.createResources(data.relationships, this.relationshipService.all(), this.relationshipService.diff, RelationshipAction.CREATE_SUCCESS, 'relationship', false, modelId).then(() => {
            if (isNullOrUndefined(data.activities)) {
              data.activities = [];
            }
            /* Activities */
            let activities = Map<string, Activity[]>();
            const count = data.activities.length;
            for (let i = 0; i < count; i++) {
              const activity = data.activities[i];
              const arr = activities.has(activity.node) ? activities.get(activity.node) : [];
              arr.push(new Activity(activity));
              if (this.nodeDataMap.has(activity.node)) {
                activities = activities.set(this.nodeDataMap.get(activity.node), arr);
              }
            }
            /* Create activities */
            activities.forEach((activitiesArray, nodeDataId) => {
              this.activityService.createOnNodeData(nodeDataId, activitiesArray.map(a => <IPayload> { id: a.id, data: a.remove('id').remove('duplicate_original_id') }));
            });
          });
        });
      });
    });
  }

  public createTreeWithoutHumanResources(data: { nodes: NodeCreate[], relationships: RelationshipCreate[], activities: Activity[], humanResources: HumanResource[], groups: Group[] }, modelId: string) {
    /* Update nodes */
    data.nodes = data.nodes.map(node => {
      /* Set ids */
      this.nodeDataNodeStructureMap = this.nodeDataNodeStructureMap.set(node.id, node.dataId);
      /* Human resources */
      if (node.responsibleId !== 0) {
        node.responsibleId = null;
      }
      /* Groups */
      if (node.groupId !== 0) {
        node.groupId = null;
      }
      /* Wrong default values fix for older versions */
      if (isNullOrUndefined(node.fieldSkipIfExists)) {
        node.fieldSkipIfExists = false;
      }
      node.data_duplicate_original_id = null;
      node.structure_duplicate_original_id = null;
      return node;
    });
    /* Create nodes */
    this.createResources(data.nodes, this.nodeService.all(), this.nodeStructureService.diff, NodeStructureAction.CREATE_SUCCESS, 'node', false, modelId).then((nodeStructuresMap: Map<string, string>) => {
      /* Update relationships */
      data.relationships = data.relationships.map(relationship => {
        /* Parent */
        if (nodeStructuresMap.has('' + relationship.parent)) {
          relationship.parent = nodeStructuresMap.get('' + relationship.parent);
        }
        /* Child */
        if (nodeStructuresMap.has('' + relationship.child)) {
          relationship.child = nodeStructuresMap.get('' + relationship.child);
        }
        return relationship;
      });
      /* Create relationships */
      this.createResources(data.relationships, this.relationshipService.all(), this.relationshipService.diff, RelationshipAction.CREATE_SUCCESS, 'relationship', false, modelId).then(() => {
        if (isNullOrUndefined(data.activities)) {
          data.activities = [];
        }
        /* Activities */
        let activities = Map<string, Activity[]>();
        const count = data.activities.length;
        for (let i = 0; i < count; i++) {
          const activity = data.activities[i];
          const arr = activities.has(activity.node) ? activities.get(activity.node) : [];
          arr.push(new Activity(activity));
          if (this.nodeDataMap.has(activity.node)) {
            activities = activities.set(this.nodeDataMap.get(activity.node), arr);
          }
        }
        /* Create activities */
        activities.forEach((activitiesArray, nodeDataId) => {
          this.activityService.createOnNodeData(nodeDataId, activitiesArray.map(a => <IPayload> { id: a.id, data: a.remove('id').remove('duplicate_original_id') }));
        });
      });
    });
  }

  public createResources(resources: any[], load: Observable<any>, diffObservable: Observable<any>, action: string, type: string, checkExisting = false, modelId?: string, maxItems = this.MAX_ITEMS, currentPosition = 0) {
    return new Promise(resolve => {
      if (isNullOrUndefined(resources)) {
        resources = [];
      }
      /* Set the map */
      let map = Map<string, string>();
      this.prepareCreateExisting(resources, load, type, checkExisting).then((result: { resources: any[], map: Map<string, string> }) => {
        /* Merge map */
        map = map.merge(result.map);
        /* Skip if empty */
        if (result.resources.length === 0) {
          resolve(map);
          return;
        }

        /* Set the ids */
        let ids = Set(result.resources.map(c => c.id));

        /* Subscribe to success */
        this.subscriptionService.add(type, diffObservable.filter(diff => diff.action === action).subscribe(diff => {
          if (isArray(diff.response)) {
            const count = diff.response.length;
            for (let i = 0; i < count; i++) {
              const response = diff.response[i];
              if (type === 'humanResource') {
                map = map.set(diff.payload.id, response.id);
              } else {
                map = map.set(response.creationId, response.id);
              }
              if (type === 'node' && this.nodeDataNodeStructureMap.has(response.creationId)) {
                this.nodeDataMap = this.nodeDataMap.set(this.nodeDataNodeStructureMap.get(response.creationId), response.relationships.nodedata.data.id);
              }
            }
            currentPosition += maxItems;
            if (result.resources.length - (currentPosition + maxItems) > -maxItems) {
              this.doResourceCall(type, result, modelId, currentPosition, maxItems);
            } else {
              resolve(map);
            }
          } else {
            if (ids.has(diff.payload.id)) {
              map = map.set(diff.payload.id, diff.response.id);
            }
            ids = ids.remove(diff.payload.id);
            if (ids.size === 0) {
              resolve(map);
            }
          }
        }));

        this.doResourceCall(type, result, modelId, currentPosition, result.resources.length > maxItems ? maxItems : 0);
      });
    });
  }

  private doResourceCall(type, result, modelId, currentPosition, maxItems) {
    /* Do the call */
    switch (type) {
      case 'humanResource':
        if (maxItems > 0) {
          this.humanResourceService.createOnInstance(this.appShared.instanceId, result.resources.map(d => <IPayload> { id: d.id, data: Map(d).remove('relationships').remove('updatedAt').remove('name').set('original', d.id) }).slice(currentPosition, currentPosition + maxItems));
        } else {
          this.humanResourceService.createOnInstance(this.appShared.instanceId, result.resources.map(d => <IPayload> { id: d.id, data: Map(d).remove('relationships').remove('updatedAt').remove('name').set('original', d.id) }));
        }
        break;
      case 'group':
        if (maxItems > 0) {
          this.groupService.createOnInstance(this.appShared.instanceId, result.resources.map(d => <IPayload> { id: d.id, data: Map(d).remove('relationships').remove('updatedAt').set('original', d.id) }).slice(currentPosition, currentPosition + maxItems));
        } else {
          this.groupService.createOnInstance(this.appShared.instanceId, result.resources.map(d => <IPayload> { id: d.id, data: Map(d).remove('relationships').remove('updatedAt').set('original', d.id) }));
        }
        break;
      case 'node':
        if (maxItems > 0) {
          this.nodeService.create(modelId, result.resources.map(d => <IPayload> { id: d.id, data: d }).slice(currentPosition, currentPosition + maxItems));
        } else {
          this.nodeService.create(modelId, result.resources.map(d => <IPayload> { id: d.id, data: d }));
        }
        break;
      case 'relationship':
        if (maxItems > 0) {
          this.relationshipService.create(modelId, result.resources.map(d => <IPayload> { id: d.id, data: d }).slice(currentPosition, currentPosition + maxItems));
        } else {
          this.relationshipService.create(modelId, result.resources.map(d => <IPayload> { id: d.id, data: d }));
        }
        break;
    }
  }

  public prepareCreateExisting(resources: any[], load: Observable<any>, type: string, checkExisting = false) {
    return new Promise<any>(resolve => {
      /* Existing map */
      let map = Map<string, string>();
      /* Check existing */
      if (checkExisting) {
        /* Check for existing */
        load.take(1).subscribe(existing => {
          /* Build ids */
          let existingIds = Set<string>();
          existing.forEach(exist => {
            existingIds = existingIds.add(exist.id);
            map = map.set(exist.id, exist.id);
            if (!isNullOrUndefined(exist.original)) {
              existingIds = existingIds.add(exist.original);
              map = map.set(exist.original, exist.id);
            }
          });

          /* Build delta */
          const toCreate = [];
          const count = resources.length;
          for (let i = 0; i < count; i++) {
            const resource = resources[i];
            if (!existingIds.has(resource.id)) {
              toCreate.push(resource);
            }
          }

          resolve({ resources: toCreate, map: map });
        });
      } else {
        resolve({ resources: resources, map: map });
      }
    });
  }

  public createTree(nodes: NodeCreate[], relationships: RelationshipCreate[], model: string) {
    /* Only valid uuids */
    const validIds = nodes.map(node => node.id);
    /* Relationship ids */
    const rels = [];
    /* Created nodes */
    const createdNodes = [];
    /* Register the listener for relationships */
    this.subscriptionService.add('rel-' + model, this.relationshipService.diff.filter(diff => diff.action === RelationshipAction.CREATE_SUCCESS && diff.payload.id === model).subscribe(diff => {
      this.onSuccess(createdNodes);
      this.relationshipService.cancel();
    }));
    /* Register the listener for nodes to be created */
    this.subscriptionService.add('node-' + model, this.nodeStructureService.diff.filter(diff => diff.action === NodeStructureAction.CREATE_SUCCESS && diff.payload.id === model).subscribe(diff => {
      /* The nodes are created now replace the temp ids with propper ones */
      let validIdTouched = false;
      diff.response.forEach(response => {
        if (validIds.indexOf(response.creationId) !== -1) {
          validIdTouched = true;
          createdNodes.push(response.id);
          relationships = relationships.map(relationship => {
            if (relationship.parent === response.creationId) {
              relationship = <RelationshipCreate>relationship.set('parent', response.id);
            }
            if (relationship.child === response.creationId) {
              relationship = <RelationshipCreate>relationship.set('child', response.id);
            }
            return relationship;
          });
        }
      });
      if (validIdTouched) {
        /* send the updated relationships to api */
        const _relationships = relationships.filter(relationship => relationship.parent.indexOf('-') === -1 && relationship.child.indexOf('-') === -1);
        this.createTreeCalls--;
        if (_relationships.length === 0) {
          if (this.createTreeCalls <= 0) {
            this.onSuccess(createdNodes);
          }
        } else {
          this.relationshipService.create(model, PayloadFactory.fromArray(_relationships));
        }
      }
    }));
    if (nodes.length === 0 && relationships.length > 0) {
      this.relationshipService.create(model, PayloadFactory.fromArray(relationships));
    } else {
      this.nodeService.create(model, PayloadFactory.fromArray(nodes));
    }
  }

  private setupTree(child: Node | NodeCreate, parents?: Node[]): { nodes: NodeCreate[], relationships: RelationshipCreate[], model: string } {
    const nodes = [];
    const relationships = [];

    /* Set the node */
    const node = child instanceof NodeCreate ? child : this.generateNodeCreate(child);
    nodes.push(node);

    /* Check if there should be a parent connection */
    if (!isNullOrUndefined(parents)) {
      parents.forEach(parent => {
        let last = node;
        for (let i = (Number(node.level) - 1); i > parent.level; i--) {
          const p = this.generateNodeCreate(<Node>Map(child).set('name', '?').toJS(), i);
          nodes.push(p);
          relationships.push(this.generateRelationshipCreate(p.id, last.id, child.modelId));
          last = p;
        }
        /* Now generate the connection between last and parent */
        relationships.push(this.generateRelationshipCreate(parent.id, last.id, child.modelId));
      });
    }
    return { nodes: nodes, relationships: relationships, model: child.modelId };
  }

  private generateNodeCreate(node: Node, level?: number): NodeCreate {
    if (isNullOrUndefined(level)) {
      level = node.level;
    }
    node['id'] = UUID.UUID();
    node['level'] = level;
    return new NodeCreate(node);
  }

  private generateRelationshipCreate(parent: string, child: string, modelId: string) {
    return new RelationshipCreate({
      id: UUID.UUID(),
      weight: 1,
      parent: parent,
      child: child,
      model: modelId
    });
  }

  private handleNode(element: Node, formGroup: FormGroup, parentElements?: Node[]) {

    /* Measure handling */
    if (!isNullOrUndefined(formGroup.value.header1.massnahme)) {

      const parent = formGroup.value.header1.massnahme;
      const child = element.id;
      const model = element.relationships.model;

      let existingRelationship: Relationship;
      this.relationshipService.all().subscribe((relationships: List<Relationship>) => {
        existingRelationship = relationships.filter(relationship => '' + relationship.relationships.child === '' + child).first();
      });

      if (isNullOrUndefined(existingRelationship)) {
        const relUUID = UUID.UUID();
        this.relationshipService.create(model, <IPayload>{
          id: relUUID,
          data: new RelationshipCreate({ model: model, parent: parent, child: child })
        });
      } else {
        this.relationshipService.update([<IPayload>{
          id: existingRelationship.id,
          data: { parent: parent }
        }]);
      }
    }

    /* Check for NodeData Delta */
    const nodeDataDelta = Utilities.setDefaultValues(Utilities.getDelta(Utilities.flattenFormGroup(new NodeData(element), new NodeDataRelationships(element.relationships), Map<string, any>(formGroup.value)), element));
    if (nodeDataDelta.size > 0) {
      if (!isNullOrUndefined(element.relationships)) {
        this.nodeDataService.update([<IPayload>{
          id: element.relationships.nodedata,
          data: nodeDataDelta
        }]);
      }
    }

    /* Check for NodeStructure Delta */
    const nodeStructureDelta = Utilities.getDelta(Utilities.flattenFormGroup(new NodeStructure(element), new NodeStructureRelationships(element.relationships), Map<string, any>(formGroup.value)), element);
    if (nodeStructureDelta.has('level')) {
      if (!isNullOrUndefined(element.relationships)) {
        this.nodeStructureService.update([<IPayload>{
          id: element.id,
          data: { level: nodeStructureDelta.get('level') }
        }]);
      }
    }

    /* Check for creating the node */
    if (isNullOrUndefined(element.relationships) && !isNullOrUndefined(element.modelId)) {
      const formGroupNode = Utilities.flattenFormGroup(new Node(element), new NodeStructureRelationships(), Map<string, any>(formGroup.value)).toJS();
      const tree = this.setupTree(formGroupNode, parentElements);
      this.createTree(tree.nodes, tree.relationships, tree.model);
    }

  }

  private handleNodeCreate(element: NodeCreate, parentElements?: Node[]) {
    const tree = this.setupTree(element, parentElements);
    this.createTree(tree.nodes, tree.relationships, tree.model);
  }

}
