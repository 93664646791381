export const TemplateFieldsActivitiesList = {
  tabs: [
    {
      entry: { key: 'header', label: '' },
      children: [
        { entry: { key: 'space', label: '', controlType: 'space', editable: false, width: 30 }},
        { entry: { key: 'complete', controlType: 'checkbox', label: '', width: 50 }},
        { entry: { key: 'number', controlType: 'number', label: 'ACTIVITY.LIST.NUMBER', editable: false, width: 50 }},
        { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'ACTIVITY.DATASHEET.FIELDS.NAME', required: true, validationMessage: 'VALIDATION.REQUIRED.NAME' }},
        { entry: { key: 'end', type: 'datetime', controlType: 'date', label: 'ACTIVITY.DATASHEET.FIELDS.END', required: true, validationMessage: 'VALIDATION.REQUIRED.END' }},
        { entry: { key: 'status', controlType: 'dropdown', label: 'ACTIVITY.DATASHEET.FIELDS.STATUS.TITLE', options: [
          { key: 0, value: 'ACTIVITY.DATASHEET.FIELDS.STATUS.PENDING' },
          { key: 1, value: 'ACTIVITY.DATASHEET.FIELDS.STATUS.PLANNED' },
          { key: 2, value: 'ACTIVITY.DATASHEET.FIELDS.STATUS.INEXECUTION' },
          { key: 3, value: 'ACTIVITY.DATASHEET.FIELDS.STATUS.SUCCCOMP' },
          { key: 4, value: 'ACTIVITY.DATASHEET.FIELDS.STATUS.UNSUCC' },
          { key: 5, value: 'ACTIVITY.DATASHEET.FIELDS.STATUS.CONDSUCC' }
        ]}}
      ]
    }
  ]
};
