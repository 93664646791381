import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { List, Map, Set } from 'immutable';
import { Store } from '@ngrx/store';

import { AppState } from '../../app.reducer';
import { IPayload } from '../shared';

import { NodeStructure, NodeStructureService } from '../nodestructures/index';
import { NodeDataService } from '../nodedata/index';
import { Node } from './nodes.models';
import { NodesAction } from './nodes.actions';
import { NodeData } from '../nodedata/nodedata.models';
import { Relationship, RelationshipService } from '../relationships';
import { isNullOrUndefined } from 'util';

@Injectable()
export class NodeService {

  public constructor(private store: Store<AppState>,
    private nodeStructureService: NodeStructureService,
    private nodeDataService: NodeDataService,
    private relationshipService: RelationshipService,
    private action: NodesAction) { }

  public find(id: string): Observable<List<Node>> {
    return Observable.combineLatest(this.nodeStructureService.find(id), this.nodeDataService.all()).map(values => this.mapStructureAndData(values));
  }

  public all(subsetId?: string): Observable<List<Node>> {
    return Observable.combineLatest(this.nodeStructureService.all(), this.nodeDataService.all()).map(values => this.mapStructureAndData(values, subsetId));
  }

  public byModelIds(ids: string[]) {
    return Observable.combineLatest(this.nodeStructureService.all(), this.nodeDataService.all()).map(values => this.mapStructureAndData(values, undefined, ids));
  }

  public create(modelId: string, data: IPayload | IPayload[]) {
    this.store.dispatch(this.action.create(modelId, data));
  }

  public createGo(modelId: string, data: IPayload | IPayload[]) {
    this.store.dispatch(this.action.createGo(modelId, data));
  }

  public getChildren(node: Node) {
    return Observable.combineLatest(this.all(), this.relationshipService.all()).map(data => {
      const ids = (<List<Relationship>>data[1]).filter(relationship => '' + relationship.relationships.parent === '' + node.id).map(relationship => '' + relationship.relationships.child);
      return (<List<Node>>data[0]).filter(n => ids.includes('' + n.id));
    });
  }

  private mapStructureAndData(values: Array<any>, subsetId?: string, modelIds?: string[]): List<Node> {
    const nodestructures = values[0];
    let nodedata = Map<string, NodeData>();
    values[1].filter(nodedatum => !!nodedatum).forEach(nodedatum => nodedata = nodedata.set('' + nodedatum.id, nodedatum));
    return nodestructures.map(nodestructure => {
      let nodedatum = nodedata.get('' + nodestructure.relationships.nodedata);
      if (!nodedatum) {
        return undefined;
      }
      if (nodedatum.reference === '') {
        nodedatum = <NodeData>nodedatum.set('reference', nodestructure.id);
      }
      return new Node(Object.assign(nodedatum.toJS(), nodestructure.toJS(), {
        data_duplicate_original_id: nodedatum.duplicate_original_id,
        structure_duplicate_original_id: nodestructure.duplicate_original_id,
        updatedAt: (nodestructure.updatedAt > nodedatum.updatedAt ? nodestructure.updatedAt : nodedatum.updatedAt),
        relationships: Object.assign(nodestructure.relationships.toJS(), nodedatum.relationships.toJS())
      }));
    }).filter((node: Node) => {
      if (!node) {
        return false;
      }
      if (!!subsetId) {
        if (!node.relationships.subsets) {
          return false;
        }
        return Set<string>(node.relationships.subsets).has(subsetId);
      }
      if (!isNullOrUndefined(modelIds)) {
        return modelIds.indexOf(node.relationships.model) !== -1;
      }
      return true;
    }).toList();
  }
}
