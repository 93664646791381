export const TemplateFieldsReportsROI = [
  {
    type: 'waterfall',
    settings: {
      targetLevel: 0,
      projectLevel: 2,
      loadBusinessarea: true,
      eva: 'REPORTS.ROI.EVA',
      budget: 'REPORTS.ROI.BUDGET',
      baseline: 'REPORTS.ROI.BASELINE',
      plus: 'REPORTS.ROI.PLUS',
      minus: 'REPORTS.ROI.MINUS'
    },
    fields: {
      eva: ['benefitBudget'],
      budget: ['costBudget', 'investBudget']
    }
  }
];
