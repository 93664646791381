export const TemplateFieldsNodesEisenhower1 = {
  tabs: [
    {
      entry: { key: 'header1', label: '' },
      children: [
        { entry: { key: 'description', controlType: 'text', label: 'DASHBOARD.CHECKLIST.DESCRIPTION1'}},
        { entry: { key: 'description1', controlType: 'text', label: 'DASHBOARD.CHECKLIST.DESCRIPTION2'}},
        { entry: { key: 'documentUri', type: 'text', controlType: 'textbox-button', fileupload: true, buttonTitle: '', label: 'DASHBOARD.CHECKLIST.DOCUMENTURI' }},
        { entry: { key: 'upload_uri', type: 'text', controlType: 'textbox-button', fileupload: true, buttonTitle: '', label: 'DASHBOARD.CHECKLIST.UPLOADURI' }}
      ]
    }
  ]
};

export const TemplateFieldsNodesEisenhower2 = {
  tabs: [
    {
      entry: { key: 'header1', label: '' },
      children: [
        { entry: { key: 'description', controlType: 'text', label: 'DASHBOARD.CHECKLIST.DESCRIPTION1'}}
      ]
    }
  ]
};

export const TemplateFieldsNodesEisenhower3 = {
  tabs: [
    {
      entry: { key: 'header1', label: '' },
      children: [
        { entry: { key: 'description', controlType: 'text', label: 'DASHBOARD.CHECKLIST.DESCRIPTION1', readonly: true }}
      ]
    }
  ]
};
