import { OrderedMap, Set } from 'immutable';

import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { Model } from '../../../shared/api/models';
import { TreeNode } from '../../../core/interface/core.interface';
import { isNullOrUndefined } from 'util';

export class ColorLabelProviderServiceSubModels extends ColorLabelProviderAbstract {

  public constructor(private submodels: OrderedMap<string, Model>) {
    super();
  }

  public color(element: TreeNode) {
    const subModel = !isNullOrUndefined(this.submodels) ? this.submodels.get(element.modelId) : undefined;
    return !!subModel ? [subModel.color] : super.color();
  }

  public legend(treeNodes: OrderedMap<string, TreeNode>) {
    if (isNullOrUndefined(this.submodels)) {
      return [];
    }
    let modelSet = Set<string>();
    treeNodes.forEach(treeNode => {
      treeNode.models.forEach(id => modelSet = modelSet.add(id));
    });
    return this.submodels.filter(model => modelSet.has(model.id)).map(submodel => ({
      key: 'submodels.' + submodel.id,
      color: submodel.color,
      label: submodel.name,
      field: 'subModel',
      value: submodel.id
    })).toArray();
  }

}
