export const TemplateFieldsReportsStrategicfitness = [
  {
    settings: {
      /* Show the footer */
      showFooter: true,
    },
    configuration: {
      fields: [
        { entry: { key: '', label: '', controlType: '' }},
        {
          entry: { key: 'group3', label: 'NODE.DATASHEET.GROUPS.GROUP3', controlType: 'group' },
          children: [
            { entry: { key: 'benefitBudget', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.BENEFITBUDGET' }},
            { entry: { key: 'benefitActual', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.BENEFITACTUAL' }},
            { entry: { key: 'benefitRemaining', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.BENEFITREMAINING' }}
          ]
        },
        {
          entry: { key: 'group4', label: 'NODE.DATASHEET.GROUPS.GROUP4', controlType: 'group' },
          children: [
            { entry: { key: 'costBudget', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.COSTBUDGET' }},
            { entry: { key: 'costActual', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.COSTACTUAL' }},
            { entry: { key: 'costRemaining', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.COSTREMAINING' }}
          ]
        },
        {
          entry: { key: 'group5', label: 'NODE.DATASHEET.GROUPS.GROUP5', controlType: 'group' },
          children: [
            { entry: { key: 'investBudget', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.INVESTBUDGET' }},
            { entry: { key: 'investActual', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.INVESTACTUAL' }},
            { entry: { key: 'investRemaining', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.INVESTREMAINING' }}
          ]
        }
      ]
    }
  }
];
