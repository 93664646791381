import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { List } from 'immutable';
import { NaturalSort } from 'angular2-natural-sort';

import { AppState } from '../../app.reducer';

import { Version} from './versions.models';
import { VersionAction } from './versions.actions';
import * as selectors from './versions.selectors';
import { ModelService } from '../models/models.service';
import { SharedService } from '../shared/shared.service';
import { Model, ModelRelationships } from '../models';
import { Datum } from '../../utilities/datum';

@Injectable()
export class VersionService extends SharedService {

  public constructor(protected store: Store<AppState>,
                     protected action: VersionAction,
                     protected modelService: ModelService) {
    super(store, action, selectors);
  }

  public findByModel(modelId: string): Observable<any> {
    return this.modelService.find(modelId).filter((model: Model) => !!model && !!model.relationships)
      .flatMap((model: Model) => Observable.combineLatest(model.relationships.versions.map(id => this.find(id)).toArray()))
      .map(versions => List<Version>(versions.filter(version => !!version).sort((a, b) => NaturalSort.SORT(a.createdAt, b.createdAt)).map((version: Version) => version.set('createdAt', new Datum(version.createdAt).toEuropeanString()))));
  }

  public rollback(id: string | number) {
    id = id.toString();
    this.store.dispatch(this.action.rollback(id));
  }

}
