import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { StoreModule } from './shared';
import { CoreModule } from './core/core.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [ HttpClient ]
      }
    }),
    StoreModule,
    CoreModule,
  ],
  exports: [
    RouterModule
  ]
})
export class BaseModule {
  constructor(@Optional() @SkipSelf() parentModule: BaseModule) {
    if (parentModule) {
      throw new Error('BaseModule already loaded; Import in root module only.');
    }
  }
}
