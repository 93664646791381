export const TemplateFieldsReportsProjectOverview = [
  {
    type: 'table',
    settings: {
      toggleField: true,
      /* Group the elements by levels */
      groupByLevels: true,
      /* Show total row after level group */
      showTotalsAfterLevel: true,
      /* Show total row at the end of the table */
      showTotals: true,
      /* Group the elements by model */
      groupByModels: false,
      /* Use a thousand seperator for numeric values */
      thousandsSeperator: false,
      /* How many decimals should be shown on numeric values */
      decimalnumbers: 2,
      /* Show top most header row (grouped heading) */
      showHeading: true,
      /* Show table fields */
      showFields: true,
      /* Show the footer */
      showFooter: true,
      /* Different source then nodes */
      source: '', // '', models, activities
      /* Caption element above the table */
      caption: null,
      /* The header text color */
      headerTextColor: '#ffffff',
      /* The header background color */
      headerBackgroundColor: '#9ac93e',
      /* The border */
      border: false,
      /* A filter function to filter by */
      filter: 'projectoverview',
      /* Show the assigned activities */
      activities: false,
      /* Trigger to load the Businessarea with that table */
      loadBusinessarea: false,
      /* Show sidestep top most / tree below related node */
      showSidestep: false, // false, tree, topmost
      /* Exports */
      exports: ['excel', 'pdf'], //  excel, pdf
      /* Allow page to break vertically */
      verticalPageBreak: true, // boolean
    },
    configuration: {
      fields: [
        { entry: { key: 'sublevel', label: '', controlType: 'sublevel', editable: false }},
        { entry: { key: 'general', label: 'REPORTS.PROJECTOVERVIEW.GROUPS.GENERAL', controlType: 'group'}, children: [
          { entry: { key: 'reference', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.REFERENCE', pdfWidth: 70 }},
          { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.NAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME', pdfWidth: 70 }},
          { entry: { key: 'responsibleId', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.RESPONSIBLEID', hasNull: true, source: 'humanresources', pdfWidth: 70}}
        ]},
        { entry: { key: 'space', label: '', controlType: 'space', editable: false }},
        { entry: { key: 'relativebenefit', label: 'REPORTS.PROJECTOVERVIEW.GROUPS.RELATIVEBENEFIT', controlType: 'group' }, children: [
          { entry: { key: 'complexity', type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.COMPLEXITY', pdfWidth: 50, options: [
            { key: 0, value: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE'},
            { key: 1, value: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW'},
            { key: 2, value: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM'},
            { key: 3, value: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH'},
          ]}},
          { entry: { key: 'risk', type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.RISK', pdfWidth: 50, options: [
            { key: 0, value: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE'},
            { key: 1, value: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW'},
            { key: 2, value: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM'},
            { key: 3, value: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH'},
          ]}},
          { entry: { key: 'sizeType', type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.SIZETYPE', pdfWidth: 50, options: [
            { key: 0, value: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE'},
            { key: 1, value: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW'},
            { key: 2, value: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM'},
            { key: 3, value: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH'},
          ]}}
        ]},
        { entry: { key: 'space', label: '', controlType: 'space', editable: false }},
        { entry: { key: 'absolutebenefit', label: 'REPORTS.PROJECTOVERVIEW.GROUPS.ABSOLUTEBENEFIT', controlType: 'group' }, children: [
          { entry: { key: 'investBudget', type: 'number', controlType: 'textbox', pdfWidth: 70, label: 'NODE.DATASHEET.FIELDS.INVESTBUDGET' }},
          { entry: { key: 'costRemaining', type: 'number', controlType: 'textbox', pdfWidth: 70, label: 'NODE.DATASHEET.FIELDS.COSTREMAINING' }},
          { entry: { key: 'costBudget', type: 'number', controlType: 'textbox', pdfWidth: 70, label: 'NODE.DATASHEET.FIELDS.COSTBUDGET'}}
        ]},
        { entry: { key: 'space', label: '', controlType: 'space', editable: false}},
        { entry: { key: 'targetdate', label: 'REPORTS.PROJECTOVERVIEW.GROUPS.TARGETDATE', controlType: 'group'}, children: [
          { entry: { key: 'startDate', type: 'datetime', controlType: 'date', pdfWidth: 50, label: 'NODE.DATASHEET.FIELDS.START' }},
          { entry: { key: 'targetDate', type: 'datetime', controlType: 'date', pdfWidth: 50, label: 'NODE.DATASHEET.FIELDS.TARGETDATE' }},
          { entry: { key: 'actualDate', type: 'datetime', controlType: 'date', pdfWidth: 50, label: 'NODE.DATASHEET.FIELDS.ACTUALDATE' }}
        ]},
        { entry: { key: 'space', label: '', controlType: 'space', editable: false }},
        { entry: { key: 'roadmap', label: 'REPORTS.PROJECTOVERVIEW.ROADMAP', controlType: 'group', colspan: 48 }, children: [
          { entry: { key: 'roadmapy', controlType: 'roadmap', label: 'TABLE.YEAR', params: 'Y', colspan: 12, pdfWidth: 10, editable: false }},
          { entry: { key: 'roadmapy1', controlType: 'roadmap', label: 'TABLE.YEAR', params: 'Y+1', colspan: 12, pdfWidth: 10, editable: false }},
          { entry: { key: 'roadmapy2', controlType: 'roadmap', label: 'TABLE.YEAR', params: 'Y+2', colspan: 12, pdfWidth: 10, editable: false }},
          { entry: { key: 'roadmapy3', controlType: 'roadmap', label: 'TABLE.YEAR', params: 'Y+3', colspan: 12, pdfWidth: 10, editable: false }}
        ]}
      ]
    }
  }
];
