import { List, Map } from 'immutable';
import { isUndefined } from 'util';

import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { Activity } from '../../../../shared/api/activities';
import { LegendItem } from '../../../../shared/legend/legend.item';
import { Node } from '../../../../shared/api/nodes';

export class ColorLabelProviderServiceTodoStatus extends ColorLabelProviderAbstract {

  private static NO = '#999999';
  private static LOW = '#E50000';
  private static MEDIUM = '#EDD515';
  private static HIGH = '#9ac93e';

  private activitiesMap = Map<string, Activity>();

  public constructor(activities: List<Activity>) {
    super();
    activities.forEach(activity => this.activitiesMap = this.activitiesMap.set(activity.id, activity));
  }

  public color(element: any) {
    switch (this.calculate(element)) {
      case 1:
        return [ColorLabelProviderServiceTodoStatus.LOW];
      case 2:
        return [ColorLabelProviderServiceTodoStatus.MEDIUM];
      case 3:
        return [ColorLabelProviderServiceTodoStatus.HIGH];
      default:
        return super.color();
    }
  }

  public legend() {
    return List<LegendItem>([
      new LegendItem({ key: 'todostatus.no', color: super.color(), label: 'LEGEND.ACTIVITYSTATUS.NO', field: 'todostatus', value: 0 }),
      new LegendItem({ key: 'todostatus.low', color: ColorLabelProviderServiceTodoStatus.LOW, label: 'LEGEND.ACTIVITYSTATUS.LOW', field: 'todostatus', value: 1 }),
      new LegendItem({ key: 'todostatus.medium', color: ColorLabelProviderServiceTodoStatus.MEDIUM, label: 'LEGEND.ACTIVITYSTATUS.MEDIUM', field: 'todostatus', value: 2 }),
      new LegendItem({ key: 'todostatus.high', color: ColorLabelProviderServiceTodoStatus.HIGH, label: 'LEGEND.ACTIVITYSTATUS.HIGH', field: 'todostatus', value: 3 })
    ]);
  }

  public calculate(node: Node) {
    if (isUndefined(node.relationships)) {
      return 0;
    }
    let activityStatus = 0;
    const activities = List(node.relationships.activities).map(id => this.activitiesMap.get(id)).filter(activity => {
      if (activity) {
        activityStatus += activity.percentageComplete;
      }
      return !!activity;
    }).toList();
    if (activities.size === 0) {
      return 0;
    }
    activityStatus = Math.round(activityStatus / activities.size);
    if (activityStatus === -1) {
      return 0;
    }
    if (activityStatus < 30) {
      return 1;
    }
    if (activityStatus < 100) {
      return 2;
    }
    return 3;
  }

}
