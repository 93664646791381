import { List, Map, Set } from 'immutable';
import { NaturalSort } from 'angular2-natural-sort';

import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { isNullOrUndefined } from 'util';
import { Model } from '../../../../shared/api/models';
import { Node } from '../../../../shared/api/nodes';
import { TreeNode } from '../../tree/tree.node';
import { LegendItem } from '../../../../shared/legend/legend.item';

export class ColorLabelProviderServiceSubModels extends ColorLabelProviderAbstract {

  private subModelMap = Map<string, Model>();

  public constructor(private submodels: List<Model>) {
    super();
    this.submodels.forEach(submodel => this.subModelMap = this.subModelMap.set(submodel.id, submodel));
  }

  public color(element: Node) {
    if (element.relationships === undefined) { return super.color(); }
    const subModel = this.subModelMap.get(element.relationships.model);
    if (!!subModel) {
      return [subModel.color];
    }
    return super.color();
  }

  public legend(extNodes: Map<string, TreeNode>) {
    let modelSet = Set<string>();
    extNodes.forEach(extNode => {
      if (!isNullOrUndefined(extNode.node.relationships)) {
        extNode.node.relationships.models.forEach(id => modelSet = modelSet.add(id));
      }
    });
    return List<LegendItem>(this.submodels.filter(model => modelSet.has(model.id)).map(submodel => new LegendItem({
      key: 'submodels.' + submodel.id,
      color: submodel.color,
      label: submodel.name,
      field: 'subModel',
      value: submodel.id
    })).sort((a: LegendItem, b: LegendItem) => NaturalSort.SORT(a.label, b.label)).toArray());
  }

}
