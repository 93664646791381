import { OrderedMap, Set } from 'immutable';

import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { CoreHumanResource, TreeNode } from '../../../core/interface/core.interface';
import { ColorLabelProviderLegend } from '../colorlabelprovider.interface';
import { isNullOrUndefined } from 'util';

export class ColorLabelProviderServiceResponsible extends ColorLabelProviderAbstract {

  public constructor(private humanResources: OrderedMap<string, CoreHumanResource>) {
    super();
  }

  public color(element: TreeNode) {
    if (!isNullOrUndefined(this.humanResources) && this.humanResources.has('' + element.responsibleId)) {
      return [this.humanResources.get('' + element.responsibleId).color];
    }
    return super.color();
  }

  public legend(treeNodes: OrderedMap<string, TreeNode>) {
    let responsibleSet = Set<string>();
    treeNodes.forEach(treeNode => responsibleSet = responsibleSet.add('' + treeNode.responsibleId));
    return isNullOrUndefined(this.humanResources) ? [] : <ColorLabelProviderLegend[]> (this.humanResources
      .filter(humanResource => responsibleSet.has('' + humanResource.id))
      .map(humanResource => ({ key: 'responsible.' + humanResource.id, color: humanResource.color, label: humanResource.name, field: 'responsibleId', value: humanResource.id }))
      .toArray());
  }

}
