export const TemplateFieldsInstances = {
  tabs: [
    {
      entry: { key: 'header1', label: '' },
      children: [
        { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'INSTANCE.DATASHEET.FIELDS.NAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME' }}
      ]
    }
  ]
};
