import { OneTimeTokenAction } from './onetimetoken.actions';
import { Utils } from '../shared/utils';
import { Action } from '../shared/common.action';
import { OneTimeToken, OneTimeTokenState } from './onetimetoken.model';
import { JSONAPIDeserializerServiceNoRel } from '../shared';
import { FormState } from '../form';

export default function reducer(state = new OneTimeTokenState(), action: Action): OneTimeTokenState {
  const deserializer = new JSONAPIDeserializerServiceNoRel<OneTimeToken>(OneTimeToken.fromJSONAPI);
  switch (action.type) {

    case OneTimeTokenAction.LOAD_SUCCESS: {
      const {entities} = deserializer.deserialize(action.payload.response);
      return <OneTimeTokenState> Utils.updateStateNoRel(state, entities, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case OneTimeTokenAction.UPDATE_BY_TOKEN_SUCCESS:
    case OneTimeTokenAction.UPDATE_BY_TOKEN_FAIL: {
      return <FormState> Utils.updateDiff(state, action);
    }

    default:
      return state;
  }
}
