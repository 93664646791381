import { Record, List, Map, Set } from 'immutable';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { isNullOrUndefined } from 'util';

import { Node, NodeService } from './nodes';
import { Relationship, RelationshipService } from './relationships';
import { Activity, ActivityService } from './activities';
import { NodeStructure, NodeStructureService } from './nodestructures';
import { NodeData, NodeDataService } from './nodedata';
import { AppShared } from '../../app.shared';

const apiDataRecord = Record({
  nodes: List<Node>(),
  relationships: List<Relationship>(),
  activities: List<Activity>(),
  checksum: '',
  forced: false
});

export class ApiData extends apiDataRecord {
  nodes: List<Node>;
  relationships: List<Relationship>;
  activities: List<Activity>;
  checksum: string;

  public constructor(data) {
    let checksum = '';
    data.nodes.forEach(node => checksum += 'node-' + node.id + '-' + node.updatedAt);
    data.relationships.forEach(relationship => checksum += 'relationship-' + relationship.id + '-' + relationship.updatedAt);
    data.activities.forEach(activity => checksum += 'activity-' + activity.id + '-' + activity.updatedAt);
    data.checksum = checksum;
    super(data);
  }
}

@Injectable()
export class ApiService {

  public nodeChecksum = '';
  public relationshipChecksum = '';
  public subSetCheckSum = '';

  public constructor(private appShared: AppShared,
                     private nodeStructureService: NodeStructureService,
                     private nodeDataService: NodeDataService,
                     private nodeService: NodeService,
                     private relationshipService: RelationshipService,
                     private activityService: ActivityService) {}

  public getModelData(id: string): Observable<any> {
    return this.nodeStructureService.findByModelId(id).switchMap((nodeStructures: List<NodeStructure>) => {
      let forced = false;
      let subSetCheckSum = '';
      nodeStructures = <List<NodeStructure>> nodeStructures.filter(nodeStructure => {
        subSetCheckSum += nodeStructure.id + ':' + nodeStructure.relationships.subsets.join(',') + ';';
        if (!isNullOrUndefined(this.appShared.subSetId)) {
          return (<any> nodeStructure.relationships.subsets).has(this.appShared.subSetId);
        }
        return true;
      });
      if (subSetCheckSum !== this.subSetCheckSum) {
        forced = true;
        this.subSetCheckSum = subSetCheckSum;
      }

      let nodeDataIds = Set<string>();
      const nodeStructureIds = nodeStructures.map(nodeStructure => nodeStructure.id).toSet();

      /* Node */
      const nodeObservable = nodeStructures.size === 0 ? Observable.of(nodeStructures) : Observable.combineLatest(nodeStructures
        .map(nodeStructure => this.nodeDataService.find(nodeStructure.relationships.nodedata)
          .map(nodeDatum => {
            nodeDataIds = nodeDataIds.add(nodeDatum.id);
            if (nodeDatum.reference === '') {
              nodeDatum = <NodeData> nodeDatum.set('reference', nodeStructure.id);
            }
            let node = new Node(Object.assign(nodeDatum.toJS(), nodeStructure.toJS(), {
              data_duplicate_original_id: nodeDatum.duplicate_original_id,
              structure_duplicate_original_id: nodeStructure.duplicate_original_id,
              updatedAt: (nodeStructure.updatedAt > nodeDatum.updatedAt ? nodeStructure.updatedAt : nodeDatum.updatedAt),
              relationships: Object.assign(nodeStructure.relationships.toJS(), nodeDatum.relationships.toJS())
            }));
            if (forced) {
              node = <Node> node.set('updatedAt', new Date().valueOf());
            }
            return node;
          })
        ).toArray());

      /* Relationship */
      const relationshipsObservable = this.relationshipService.findByModelId(id).map((relationships: List<Relationship>) => {
        return relationships.filter(relationship => nodeStructureIds.has(relationship.relationships.parent) && nodeStructureIds.has(relationship.relationships.child)).toList();
      });

      /* Activities */
      const activitiesObservable = this.activityService.all().map((activities: List<Activity>) => {
        return activities.filter(activitiy => nodeDataIds.has(activitiy.relationships.nodedata)).toList();
      });

      /* Combined */
      return Observable.combineLatest(nodeObservable, relationshipsObservable, activitiesObservable).map(data => new ApiData({
        nodes: List<Node>(data[0]),
        relationships: data[1],
        activities: data[2]
      }));
    });
  }

  public getModelNodeRelationshipData(id: string) {
    const nodesObservable = this.nodeService.all().filter(_ => !!_).map(nodes => nodes.filter(node => node.relationships.model === id));
    const relationshipsObservable = this.relationshipService.findByModelId(id);
    return Observable
      .combineLatest(nodesObservable, relationshipsObservable)
      .filter((data: any[]) => {
        /* Nodes */
        const nodesChecksum = this.checksum(data[0]);
        const nodesDiff = this.nodeChecksum !== nodesChecksum;
        /* Relationships */
        const relationshipsChecksum = this.checksum(data[1]);
        const relationshipsDiff = this.relationshipChecksum !== relationshipsChecksum;
        /* Filter */
        if (nodesDiff && relationshipsDiff) {
          this.nodeChecksum = nodesChecksum;
          this.relationshipChecksum = relationshipsChecksum;
        }
        return nodesDiff && relationshipsDiff;
      })
      .map(data => {
        /* Filter relationships only where nodes exist */
        const ids = data[0].map(node => node.id).toSet();
        data[1] = data[1].filter(relationship => ids.has(relationship.relationships.parent) && ids.has(relationship.relationships.child));
        return new ApiData({ nodes: data[0], relationships: data[1], activities: List<Activity>() });
      });
  }

  public getBusinessareaData(id: string): Observable<any> {
    return this.nodeDataService.findByBusinessareaId(id).switchMap((nodeData: List<NodeData>) => {
      let nodeDataMap = Map<string, NodeData>();
      nodeData.forEach(nodeDatum => nodeDataMap = nodeDataMap.set(nodeDatum.id, nodeDatum));

      let modelIds = Set<string>();
      let nodeStructureIds = Set<string>();

      /* Node */
      const nodeObservable = this.nodeStructureService.all().map((nodeStructures: List<NodeStructure>) => {
        return nodeStructures.filter(nodeStructure => nodeDataMap.has(nodeStructure.relationships.nodedata)).map(nodeStructure => {
          modelIds = modelIds.add(nodeStructure.relationships.model);
          nodeStructureIds = nodeStructureIds.add(nodeStructure.id);
          let nodeDatum = nodeDataMap.get(nodeStructure.relationships.nodedata);
          if (nodeDatum.reference === '') {
            nodeDatum = <NodeData> nodeDatum.set('reference', nodeStructure.id);
          }
          return new Node(Object.assign(nodeDatum.toJS(), nodeStructure.toJS(), {
            data_duplicate_original_id: nodeDatum.duplicate_original_id,
            structure_duplicate_original_id: nodeStructure.duplicate_original_id,
            updatedAt: (nodeStructure.updatedAt > nodeDatum.updatedAt ? nodeStructure.updatedAt : nodeDatum.updatedAt),
            relationships: Object.assign(nodeStructure.relationships.toJS(), nodeDatum.relationships.toJS())
          }));
        });
      });

      /* Relationship */
      const relationshipsObservable = this.relationshipService.all().map((relationships: List<Relationship>) => {
        return relationships.filter(relationship => nodeStructureIds.has(relationship.relationships.parent) && nodeStructureIds.has(relationship.relationships.child)).toList();
      });

      /* Activities */
      const activitiesObservable = this.activityService.all().map((activities: List<Activity>) => {
        return activities.filter(activitiy => nodeDataMap.has(activitiy.relationships.nodedata)).toList();
      });

      /* Combined */
      return Observable.combineLatest(nodeObservable, relationshipsObservable, activitiesObservable).map(data => new ApiData({
        nodes: List<Node>(data[0]),
        relationships: data[1],
        activities: data[2]
      }));
    });
  }

  protected checksum(items: List<Node | Relationship>) {
    return items.map(item => item.id + '-' + item.updatedAt).join('+');
  }

}
