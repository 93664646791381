export const TemplateFieldsBusinessCalculations = [
  {
    id: 'DIFFERENCE',
    name: 'BUSINESSCALCULATIONS.DIFFERENCE.TITLE',
    active: false,
    criterias: [
      {
        title: 'BUSINESSCALCULATIONS.DIFFERENCE.LOW',
        color: '#9ac93e',
        conditions: [
          {
            calculations: '((budget - budgetCalculated) / budget) * 100',
            operator: '>=',
            value: 0
          }
        ]
      },
      {
        title: 'BUSINESSCALCULATIONS.DIFFERENCE.MEDIUM',
        color: '#EDD515',
        conditions: [
          {
            calculations: '((budget - budgetCalculated) / budget) * 100',
            operator: '>',
            value: -30
          }
        ]
      },
      {
        title: 'BUSINESSCALCULATIONS.DIFFERENCE.HIGH',
        color: '#E3387B',
        conditions: [
          {
            calculations: '((budget - budgetCalculated) / budget) * 100',
            operator: '<=',
            value: -30
          }
        ]
      }
    ]
  },
  {
    id: 'WORSTOF',
    name: 'BUSINESSCALCULATIONS.WORSTOF.TITLE',
    active: false,
    criterias: [
      {
        title: 'BUSINESSCALCULATIONS.WORSTOF.LOW',
        color: '#9ac93e',
        conditions: [
          {
            calculations: '((benefitBudget - benefitActual) / benefitBudget) * 100',
            operator: '>=',
            value: 0
          }
        ]
      },
      {
        title: 'BUSINESSCALCULATIONS.WORSTOF.LOW',
        color: '#9ac93e',
        conditions: [
          {
            calculations: '((costBudget - costActual) / costBudget) * 100',
            operator: '>=',
            value: 0
          }
        ]
      },
      {
        title: 'BUSINESSCALCULATIONS.WORSTOF.LOW',
        color: '#9ac93e',
        conditions: [
          {
            calculations: '((investBudget - investActual) / investBudget) * 100',
            operator: '>=',
            value: 0
          }
        ]
      },
      {
        title: 'BUSINESSCALCULATIONS.WORSTOF.MEDIUM',
        color: '#EDD515',
        conditions: [
          {
            calculations: '((benefitBudget - benefitActual) / benefitBudget) * 100',
            operator: '>',
            value: -30
          }
        ]
      },
      {
        title: 'BUSINESSCALCULATIONS.WORSTOF.MEDIUM',
        color: '#EDD515',
        conditions: [
          {
            calculations: '((costBudget - costActual) / costBudget) * 100',
            operator: '>',
            value: -30
          }
        ]
      },
      {
        title: 'BUSINESSCALCULATIONS.WORSTOF.MEDIUM',
        color: '#EDD515',
        conditions: [
          {
            calculations: '((investBudget - investActual) / investBudget) * 100',
            operator: '>',
            value: -30
          }
        ]
      },
      {
        title: 'BUSINESSCALCULATIONS.WORSTOF.HIGH',
        color: '#E3387B',
        conditions: [
          {
            calculations: '((benefitBudget - benefitActual) / benefitBudget) * 100',
            operator: '<=',
            value: -30
          }
        ]
      },
      {
        title: 'BUSINESSCALCULATIONS.WORSTOF.HIGH',
        color: '#E3387B',
        conditions: [
          {
            calculations: '((costBudget - costActual) / costBudget) * 100',
            operator: '<=',
            value: -30
          }
        ]
      },
      {
        title: 'BUSINESSCALCULATIONS.WORSTOF.HIGH',
        color: '#E3387B',
        conditions: [
          {
            calculations: '((investBudget - investActual) / investBudget) * 100',
            operator: '<=',
            value: -30
          }
        ]
      }
    ]
  }
];
