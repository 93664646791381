import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';

import { API, GoAPI, IRelationship, JSONAPIResponse } from '../connector';
import { RelationshipAction } from './relationships.actions';
import { Action } from '../shared/common.action';

@Injectable()
export class RelationshipEffects {

  private concurrency = 5;

  @Effect() findRelationshipByModel$ = this.actions$
    .pipe(ofType(RelationshipAction.LOAD))
    .mergeMap((action: Action) => this.api.model(action.payload.modelId).relationships.findAll()
      .mergeMap((response: JSONAPIResponse<IRelationship[]>) => {
        return Observable.from([
          this.relationshipAction.loadSuccess(response.toPayload())
        ]);
      })
    ).catch((error: any) => Observable.of(this.relationshipAction.loadFail(error)));

  @Effect() createRelationshipGo$ = this.actions$
    .pipe(ofType(RelationshipAction.CREATE_GO))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.apiGo.model(action.payload.id).relationships.bulkCreate(action.payload.data).mergeMap(response =>
      Observable.from([
        this.relationshipAction.createSuccess(action.payload, response.toPayload())
      ])).catch((error: any) => Observable.of(this.relationshipAction.createFail(action.payload, error))));


  @Effect() createRelationship$ = this.actions$
    .pipe(ofType(RelationshipAction.CREATE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.api.model(action.payload.id).relationships.bulkCreate(action.payload.data).mergeMap(response =>
      Observable.from([
        this.relationshipAction.createSuccess(action.payload, response.toPayload())
      ])).catch((error: any) => Observable.of(this.relationshipAction.createFail(action.payload, error))));


  @Effect() removeRelationship$ = this.actions$
    .pipe(ofType(RelationshipAction.REMOVE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.api.relationships.bulkRemove(action.payload.data).mergeMap(response =>
      Observable.from([
        this.relationshipAction.removeSuccess(action.payload, response.toPayload())
      ])).catch((error: any) => Observable.of(this.relationshipAction.removeFail(action.payload, error))));

  @Effect() removeRelationshipGo$ = this.actions$
    .pipe(ofType(RelationshipAction.REMOVE_GO))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.apiGo.relationships.bulkRemove(action.payload.data).mergeMap(response =>
      Observable.from([
        this.relationshipAction.removeSuccess(action.payload, response.toPayload())
      ])).catch((error: any) => Observable.of(this.relationshipAction.removeFail(action.payload, error))));


  @Effect() updateRelationship$ = this.actions$
    .pipe(ofType(RelationshipAction.UPDATE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.api.relationships.bulkUpdate(action.payload.data).mergeMap(response =>
      Observable.from([
        this.relationshipAction.updateSuccess(action.payload, response.toPayload())
      ])).catch((error: any) => Observable.of(this.relationshipAction.updateFail(action.payload, error))));

  @Effect() updateRelationshipGo$ = this.actions$
    .pipe(ofType(RelationshipAction.UPDATE_GO))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.apiGo.relationships.bulkUpdate(action.payload.data).mergeMap(response =>
      Observable.from([
        this.relationshipAction.updateSuccess(action.payload, response.toPayload())
      ])).catch((error: any) => Observable.of(this.relationshipAction.updateFail(action.payload, error))));

  constructor(private api: API,
    private apiGo: GoAPI,
    private actions$: Actions,
    private relationshipAction: RelationshipAction) {
  }
}
