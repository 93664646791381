import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '../shared/common.action';
import { Observable } from 'rxjs/Observable';
import { API, JSONAPIResponse } from '../connector';
import {IVersion} from '../connector';

import { VersionAction } from './versions.actions';
import { ModelAction } from '../models';


@Injectable()
export class VersionEffects {

  private concurrency = 5;

  @Effect() findVersionsByModel$ = this.actions$
  .pipe(ofType(VersionAction.LOAD))
  .mergeMap((action: Action) => this.api.model(action.payload.modelId).versions.findAll()
    .mergeMap((response: JSONAPIResponse<IVersion[]>) => {
      return Observable.from([
        this.versionAction.loadSuccess(response.toPayload())
      ]);
    })
  ).catch((error: any) => Observable.of(this.versionAction.loadFail(error)));

  @Effect() createVersion$ = this.actions$
    .pipe(ofType(VersionAction.CREATE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.data.map(payload => {
      const data = payload.data.toJS();
      delete data.id;
      return this.api.model(action.payload.modelId).versions.create(data)
        .mergeMap(response => Observable.from([
          this.versionAction.createSuccess(payload, response.toPayload()),
          this.modelAction.addVersion(payload, response.toPayload())
        ]))
        .catch((error: any) => Observable.of(this.versionAction.createFail(payload, error)));
    })))
    .mergeAll(this.concurrency);

  @Effect() removeVersion$ = this.actions$
    .pipe(ofType(VersionAction.REMOVE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.data.map(payload => this.api.versions.remove(payload)
      .mergeMap(response => Observable.from([
        this.versionAction.removeSuccess(payload, response.toPayload())
      ]))
      .catch((error: any) => Observable.of(this.versionAction.removeFail(payload, error)))
    )))
    .mergeAll(this.concurrency);

  @Effect() rollback$ = this.actions$
    .pipe(ofType(VersionAction.ROLLBACK))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(this.api.versions.rollback(action.payload.version)
      .mergeMap(response => Observable.from([
        this.versionAction.rollbackSuccess(action.payload, response.toPayload())
      ]))
      .catch((error: any) => Observable.of(this.versionAction.rollbackFail(action.payload, error)))
    ))
    .mergeAll(this.concurrency);

  constructor(private api: API,
              private actions$: Actions,
              private modelAction: ModelAction,
              private versionAction: VersionAction) {
  }
}
