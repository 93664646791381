import { List } from 'immutable';
import { Node } from '../api/nodes';
import { Relationship } from '../api/relationships';
import { Injectable } from '@angular/core';
import { TreeService } from '../../start/shared/services/tree.service';
import { isNullOrUndefined } from 'util';
import { NODES_TYPE_FORM_OPTION, NODES_TYPE_FORM_TAB } from '../api/nodes/nodes.models';
import { TreeNode } from '../../working/shared/tree/tree.node';
import { NaturalSort } from 'angular2-natural-sort';

@Injectable()
export class FormHelper {

  public constructor(private treeService: TreeService) {}

  public toJSON(items: List<any>) {
    let nodes = List<Node>();
    let relationships = List<Relationship>();

    items.forEach(item => {
      if (item instanceof Node) {
        nodes = nodes.push(item);
      } else if (item instanceof Relationship) {
        relationships = relationships.push(item);
      }
    });
    const startNode = this.treeService.build(nodes, relationships).nodes.filter(node => node.parents.size === 0).first();
    const form = { tabs: [] };
    if (!isNullOrUndefined(startNode)) {
      let i = 1;
      startNode.children.sort((a, b) => NaturalSort.SORT(a.node.positionX, b.node.positionX)).forEach((tabTreeNode) => {
        if (tabTreeNode.node.nodeType === NODES_TYPE_FORM_TAB) {
          form.tabs.push({ entry: { key: 'header-' + i++, label: tabTreeNode.node.name }, children: this.buildEntries(tabTreeNode) });
        }
      });
    }
    return form;
  }

  private buildEntries(treeNode: TreeNode) {
    return treeNode.children.sort((a, b) => NaturalSort.SORT(a.node.positionX, b.node.positionX)).map(childTreeNode => {
      const entry: any = { entry: { key: childTreeNode.node.formFieldId, type: childTreeNode.node.formFieldType, controlType: childTreeNode.node.formFieldControlType, label: childTreeNode.node.name }};
      if (childTreeNode.node.formFieldControlType === 'dropdown') {
        if (childTreeNode.node.formFieldDropdownValue === '') {
          entry.entry.options = childTreeNode.children.filter(optionNode => optionNode.node.nodeType === NODES_TYPE_FORM_OPTION).map(optionNode => {
            return { key: optionNode.node.formId, value: optionNode.node.name };
          }).toArray();
          if (entry.entry.options.length > 0) {
            entry.entry.options.unshift({ key: null, value: '' });
          }
        } else {
          entry.entry.source = childTreeNode.node.formFieldDropdownValue;
        }
      }
      if (entry.entry.controlType === 'children') {
        if (isNullOrUndefined(entry.entry.source)) {
          entry.entry.source = { tabs: [{ entry: { key: 'header-1', label: '' }, children: this.buildEntries(childTreeNode) }] };
        }
      }
      return entry;
    }).toArray();
  }



}
