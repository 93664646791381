import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';

import { UserAction } from './user.actions';
import { Action } from '../shared/common.action';
import {CommonEffects} from '../shared';
import { JSONAPIResponse } from '../connector/jsonapi';
import { IUser } from '../connector/models';
import { API } from '../connector/shared';

@Injectable()
export class UserEffects {

  @Effect() loadUser$ = this.actions$
    .pipe(ofType(UserAction.LOAD))
    .switchMap((action: Action) => this.api.me.include(action.payload.includes).find()
      .mergeMap((response: JSONAPIResponse<IUser>) =>
        this.commonEffects.getObservables(this.userAction.loadSuccess, action.payload.includes, response)
      )
    ).catch((error: any) => Observable.of(this.userAction.loadFail(error)));

  @Effect() loadUserWithIncludes$ = this.actions$
  .pipe(ofType(UserAction.LOAD_WITH_INCLUDES))
  .switchMap((action: Action) => this.api.me.assigned(action.payload)
    .mergeMap((response: JSONAPIResponse<IUser>) =>
      this.commonEffects.getUserObservableWithResponsibleElements(this.userAction.loadSuccess, response)
    )
  ).catch((error: any) => Observable.of(this.userAction.loadFail(error)));


  constructor(private api: API,
              private actions$: Actions,
              private commonEffects: CommonEffects,
              private userAction: UserAction) {
  }
}
