import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';

import { SyncAction } from './syncs.actions';
import { Action } from '../shared/common.action';
import { JSONAPIResponse } from '../connector/jsonapi';
import { ISync } from '../connector/models';
import { MessagingAPI } from '../connector/shared';

@Injectable()
export class SyncEffects {

  private concurrency = 5;

  @Effect() fetchSyncs$ = this.actions$
    .pipe(ofType(SyncAction.LOAD))
    .mergeMap(() => this.api.syncs.findAll())
    .map((response: JSONAPIResponse<ISync[]>) => this.syncAction.loadSuccess(response.toPayload()))
    .catch((error: any) => Observable.of(this.syncAction.loadFail(error)));

  @Effect() updateSync$ = this.actions$
    .pipe(ofType(SyncAction.UPDATE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.map(payload => this.api.syncs.update(payload.id, payload.data)
      .mergeMap(response => Observable.from([
        this.syncAction.updateSuccess(payload, response.toPayload())
      ]))
      .catch((error: any) => Observable.of(this.syncAction.updateFail(payload, error)))
    )))
    .mergeAll(this.concurrency);

  @Effect() createSync$ = this.actions$
    .pipe(ofType(SyncAction.CREATE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.data.map(payload => {
      const data = payload.data.toJS();
      delete data.id;
      delete data.fields;
      delete data.formGroup;
      delete data.formErrors;
      return this.api.syncs.create(data)
        .mergeMap(response => Observable.from([
          this.syncAction.createSuccess(payload, response.toPayload())
        ]))
        .catch((error: any) => Observable.of(this.syncAction.createFail(payload, error)));
    })))
    .mergeAll(this.concurrency);

  @Effect() extOAuth$ = this.actions$
    .pipe(ofType(SyncAction.EXTOAUTH))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.api.extoauth.create(action.payload))
    .map((response: JSONAPIResponse<any>) => this.syncAction.extOAuthSuccess(response.toPayload()))
    .catch((error: any) => Observable.of(this.syncAction.extOAuthFail(error)));

  @Effect() sqs$ = this.actions$
    .pipe(ofType(SyncAction.SQS))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.api.sqs.create(action.payload)
      .map((response: JSONAPIResponse<any>) => this.syncAction.sqsSuccess(action.payload, response.toPayload()))
      .catch((error: any) => Observable.of(this.syncAction.sqsFail(action.payload, error)))
    );

  constructor(private api: MessagingAPI,
              private actions$: Actions,
              private syncAction: SyncAction) {
  }
}
