import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { TreeNode } from '../../../core/interface/core.interface';
import { isArray, isNullOrUndefined } from 'util';
import { Map, Set } from 'immutable';
import { ColorLabelProviderLegend } from '../colorlabelprovider.interface';

export class ColorLabelProviderServiceHeatmap extends ColorLabelProviderAbstract {

  private field = '';
  private fields = Map<string, { label: string, color: string }>();
  private fieldMatrix: string[] = [];

  public constructor(protected configurationNode?: TreeNode) {
    super();
    /* Configuration node */
    if (!isNullOrUndefined(configurationNode)) {
      this.configure();
    }
  }

  public color(elements: TreeNode[]) {
    if (!isArray(elements)) {
      return this.fields.has('' + elements[this.field]) ? [this.fields.get('' + elements[this.field]).color] : super.color();
    }
    if (elements.length === 0) {
      return '#ffffff';
    }
    if (elements.length === 1) {
      return this.fields.has('' + elements[0][this.field]) ? [this.fields.get('' + elements[0][this.field]).color] : super.color();
    }
    if (elements.length > 1) {
      const values = Set(elements.map(element => element[this.field])).toArray();
      if (values.length === 1) {
        return this.fields.has('' + values[0]) ? [this.fields.get('' + values[0]).color] : super.color();
      }
      let merge: number = undefined;
      const count = values.length;
      for (let i = 0; i < count; i++) {
        const value = values[i];
        const position = this.fieldMatrix.indexOf('' + value);
        if (position !== -1 && (isNullOrUndefined(merge) || position < merge)) {
          merge = position;
        }
      }
      const value = this.fieldMatrix[merge];
      return this.fields.has('' + value) ? [this.fields.get('' + value).color] : super.color();
    }
    return super.color();
  }

  public legend(): any {
    return <ColorLabelProviderLegend[]> this.fields.map((field, key) => (<ColorLabelProviderLegend> {
      key: key,
      label: field.label,
      color: field.color,
      field: this.field,
      value: key
    })).toArray();
  }

  public modifyConfiguration(configurationNode: TreeNode) {
    this.configurationNode = configurationNode;
    this.configure();
  }

  protected configure() {
    if (this.configurationNode === null || this.configurationNode === undefined) {
      return;
    }
    /* Set the field */
    this.field = this.configurationNode.formFieldId;
    /* Set the fields */
    this.fields = this.fields.clear();
    const children = this.configurationNode.children.sort((a, b) => a.positionX - b.positionX);
    const count = children.length;
    for (let i = 0; i < count; i++) {
      const child = children[i];
      this.fields = this.fields.set('' + child.formId, { label: child.name, color: child.color });
      /* Matrix */
      this.fieldMatrix.push('' + child.formId);
    }
  }

}
